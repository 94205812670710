import { Link, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Service from "../../../Services/Service";
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import SessionManage from '../../../SessionManagement/SessionManage';
import Cookies from 'js-cookie';

function Header(props) {

    <script>
        $(function () {
            console.log("service hdnisDelete", $('#hdnisDelete').val())
        });
        $(function () {
            $('#hdnisDelete').val() == 'true' ? $("#dropdownNotificationul").addClass('dropdown-menu show') : ""
        });
        $(function () {
            $('#hdnisDelete').val() == 'true' ? $("#dropdownNotificationli").addClass('dropdown  show') : ""
        });

        $(function () {
            $('#hdnisDelete').val() == 'true' ? $("#dropdowntoggleNotification").attr("aria-expanded", "true") : ""
        });
        $(function () {
            $('#hdnisDelete').val() == 'true' ? $("#hdnisDelete").val('false') : ""
        });



    </script>
    // let id = sessionStorage.getItem('id');
    // let name = sessionStorage.getItem('name');
    let id = SessionManage.getuserid();
    let name = SessionManage.getusername();

    const signout = () => {
        Service.getdata("LockedDocuments/UnlockAllRecordByUser?userId=" + SessionManage.getuserid()).then((res) => {
            if (typeof res !== 'undefined') {
                sessionStorage.clear();
                Cookies.remove('id');
                Cookies.remove('name');
                Cookies.remove('AccrualCalc');
                Cookies.remove('ComparisonPeriod');
                Cookies.remove('FiscalEndDate');
                Cookies.remove('Languages');
                Cookies.remove('Locale');
                Cookies.remove('UsersType');
                Cookies.remove('accrualThresholdCriteria');
                Cookies.remove('dateformat');
                Cookies.remove('defaultCurrency');
                Cookies.remove('fiscalendmonth');
                Cookies.remove('fiscalstartmonth');
                Cookies.remove('groupids');
                Cookies.remove('idletimeout');
                Cookies.remove('includeOutstandingAmount');
                Cookies.remove('includeSubDivideTarget');
                Cookies.remove('roleid');
                Cookies.remove('roundingPrecision');
                Cookies.remove('token');
                Cookies.remove('navData');
                window.location = '/';
            }
        });
        //  sessionStorage.clear();
        // window.location.href = "/";
    }

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })

    const [showspinner, setshowspinner] = useState('');
    const [isDelete, setIsDelete] = useState(false);

    const [filteredArray, setFilteredArray] = useState([]);

    const [className, setClassName] = useState('dropdown-menu');
    const [classliName, setClassliName] = useState('dropdown');
    useEffect(() => {
        setshowspinner(true);
        getData("Notifications/GetAllByPage", paging.pageSize, paging.pageNumber);
    }, [0])

    const getData = (method, pageSize, pageNumber) => {
        Service.getdataWithPagingByUser(method, pageSize, pageNumber).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== undefined && res.data !== '') {
                    setFilteredArray(res.data.DataList)
                    setshowspinner(false);

                }
            }
        });

    }
    const submitButton = (e) => {
        getData("Notifications/GetAllByPage", paging.pageSize, paging.pageNumber);
        setIsDelete(false);

    };
    const submitClearAll = (e) => {

        Service.UpdateNotifications("Notifications/UpdateNotifications", true, 0, paging.pageSize, paging.pageNumber).then((res) => {
            if (typeof res !== undefined) {
                if (typeof res.data !== undefined && res.data !== '') {
                    setFilteredArray(res.data.DataList)
                    setshowspinner(false);
                }
            }
        });

        // setClassName("dropdown-menu show");
        //    setClassName("dropdown-menu show");
        //   setClassliName("dropdown show");
    };
    const submitDelete = (event, id) => {
        setIsDelete(true);

        Service.UpdateNotifications("Notifications/UpdateNotifications", false, id, paging.pageSize, paging.pageNumber).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== '') {
                    setFilteredArray(res.data.DataList)
                    setshowspinner(false);
                }
            }
        });

        //   setClassName("dropdown-menu show");
        //    setClassliName("dropdown show");

        //$('.dropdown-toggle').dropdown();

    };

    const OpenUrl = (event, url) => {
        console.log("Event:", event);
        console.log("URL before cleaning:", url);
        const cleanedUrl = url.replace(/([^:]\/)\/+/g, "$1");
        console.log("URL after cleaning:", cleanedUrl);
        debugger
        if (event.detail === 1) {
            window.location = cleanedUrl;
        }
    }

    const submitClose = (event) => {

        setClassName("dropdown-menu");
        setClassliName("dropdown");
    }
    let initials =''
    if(name && name != undefined){
        const firstName = name.split(' ')[0]; // Getting first word
        const secondName = name.split(' ')[1]; // Getting second word
        initials = firstName.charAt(0) + (secondName ? secondName.charAt(0) : ''); // Concatenating in intials
    }
    //UpdateNotifications

    return (

        <>
            <div>
                <input type="hidden" id="hdnisDelete" value={isDelete} ></input>
                <div className="header sticky-header">

                    <div className="menu-right">

                        <div className="navbar user-panel-top">
                            <div className="menu-left">
                                <div className="">
                                    <a href="/" title="logo"><img src="/NewAssets/images/logo.png" style={{ height: "48px" }} alt="logo-icon" /> </a>
                                </div>
                            </div>
                            <div className="search-box">
                                <form action="#search-results.html" method="get">

                                </form>
                            </div>
                            <div className="user-dropdown-details d-flex">
                                <div className="profile_details_left">

                                    <ul className="nofitications-dropdown">
                                        <li className={classliName} id="dropdownNotificationli">

                                            <a id="dropdowntoggleNotification" onMouseDown={submitButton} href='javascript:void(0)' className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                                                className="fa fa-bell-o"></i><span className="badge blue">{filteredArray.length}</span></a>


                                            <ul className={className} id="dropdownNotificationul">
                                                <li>
                                                    <div className="notification_header" style={{ overflow: "scroll", height: filteredArray.length === 0 ? "100px" : "250px" }}>

                                                        <table className='table'>

                                                            {
                                                                showspinner === true ?

                                                                    <Spinner animation="border" size="sm" />
                                                                    :
                                                                    filteredArray.length === 0 ?
                                                                        <>
                                                                            <tr><td colSpan={2} >No notifications available</td></tr></>
                                                                        : <>

                                                                            {filteredArray.map((item, index) => (
                                                                                <tr key={item.Id}>
                                                                                    <td onClick={(event) => OpenUrl(event, item.Url)} >{item.NotifyDesc}</td>


                                                                                    <td style={{ float: "right" }}>  <i id="trigger_button" onClick={(event) => submitDelete(event, item.Id)} className='fa fa-times' style={{ color: "red", cursor: "pointer" }} ></i></td>

                                                                                </tr>))}

                                                                        </>}

                                                        </table>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="notification_bottom">
                                                        {filteredArray.length === 0 ? "" : <a href="javascript:void(0)" className="bg-primary" onClick={submitClearAll} >Clear all notifications</a>}
                                                    </div>
                                                </li>
                                                {/* <li><a href="#" className="grid">
                                                <div className="user_img"><img src="/NewAssets/images/avatar1.jpg" alt="" /></div>
                                                <div className="notification_desc">
                                                    <p>Johnson purchased template</p>
                                                    <span>Just Now</span>
                                                </div>
                                            </a></li>
                                            <li className="odd"><a href="#" className="grid">
                                                <div className="user_img"><img src="/NewAssets/images/avatar2.jpg" alt="" /></div>
                                                <div className="notification_desc">
                                                    <p>New customer registered </p>
                                                    <span>1 hour ago</span>
                                                </div>
                                            </a></li>
                                            <li><a href="#" className="grid">
                                                <div className="user_img"><img src="/NewAssets/images/avatar3.jpg" alt="" /></div>
                                                <div className="notification_desc">
                                                    <p>Lorem ipsum dolor sit amet </p>
                                                    <span>2 hours ago</span>
                                                </div> <div className="notification_bottom">
                                                    <a href="#" className="bg-primary">See all notifications</a>
                                                </div>
                                            </a></li> */}
                                                <li>

                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="profile_details">
                                    <ul>
                                        <li className="dropdown profile_details_drop">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" id="dropdownMenu3" aria-haspopup="true"
                                                aria-expanded="false">
                                                <div className="profile_img">
                                                    <img src="/NewAssets/images/profileimg.jpg" className="rounded-circle" alt="" />
                                                    <div className="user-active">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </a>
                                            <ul className="dropdown-menu drp-mnu" aria-labelledby="dropdownMenu3">
                                                <li className="user-info">
                                                    <h5 className="user-name">{name}</h5>
                                                </li>
                                                <li> <a href={"/users/edit/" + id}><i className="lnr lnr-user"></i>My Profile</a> </li>
                                                <li className="logout"> <a href="#" onClick={signout}>
                                                    <i className="fa fa-power-off"></i>
                                                    <span>Logout</span>
                                                </a> </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="profile_details">
                                    <ul>
                                        <li className="dropdown profile_details_drop">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" id="dropdownMenu3" aria-haspopup="true" aria-expanded="false">
                                                <div className="profile_img">
                                                    <span className="rounded-circle" style={{ background: '#4755AB', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {initials}
                                                        {/* used initials instead of image */}
                                                    </span>
                                                    <div className="user-active">
                                                        <span className='user-active'></span>
                                                    </div>
                                                </div>
                                            </a>
                                            <ul className="dropdown-menu drp-mnu" aria-labelledby="dropdownMenu3">
                                                <li className="user-info">
                                                    <h5 className="user-name">{name}</h5>
                                                </li>
                                                <li> <Link to={"/users/edit/" + id}><i className="lnr lnr-user"></i>My Profile</Link> </li>
                                                <li className="logout"> <a href="#" onClick={signout}>
                                                    <i className="fa fa-power-off"></i>
                                                    <span>Logout</span>
                                                </a> </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>);
}
export default Header