import Login from "../Pages/Login/Login"
// import RebateListing from "../RebatePrograms/RebateListing"
import RebateListing from "../Pages/RebateProgram/RebateListing/RebateListing"
import CreateRebate from "../Pages/RebateProgram/CreateRebate/CreateRebate"
import CreateEngineOil from "../Pages/RebateProgram/CreateEngineOil/CreateEngineOil"
import CreateClaim from "../Pages/RebateProgram/CreateClaimAgreement/CreateClaimAgreement"
import Dashboard from "../Pages/Dashboard/Dashboard"
import AccuralList from "../Pages/Accurals/AccuralList"
import CreateAccurals from "../Pages/Accurals/CreateAccurals"
import ExecutiveDashboard from "../Pages/Dashboard/ExecutiveDashboard"
import UserList from "../Pages/UserManagement/User/UserList"
import CreateUser from "../Pages/UserManagement/User/CreateUser"
import RoleList from "../Pages/UserManagement/Role/RoleList"
import CreateRole from "../Pages/UserManagement/Role/CreateRole"
import EditRole from "../Pages/UserManagement/Role/EditRole"
import PendingApprovalRebate from "../Pages/Approvals/Rebate/PendingApprovalRebate"
import ArchiveRebate from "../Pages/Approvals/Rebate/ArchiveRebate"
import PendingApprovalAccrual from "../Pages/Approvals/Accrual/PendingApprovalAccrual"
import ArchiveAccrual from "../Pages/Approvals/Accrual/ArchiveAccrual"
import Organization from "../Pages/Organization/Organization"
import Workflow from "../Pages/Workflow/Workflow"
import NewWorkflow from "../Pages/Workflow/NewWorkflow"

import DataImport from "../Pages/DataAdministration/DataImport"
import DataExport from "../Pages/DataAdministration/DataExport"
import Reports from "../Pages/DataAdministration/Reports"
import EditUser from "../Pages/UserManagement/User/EditUser"
import RebateTypePolicies from "../Pages/Policies/RebateTypePolicy/RebateTypePolicies"
import NewRebateTypePolicy from "../Pages/Policies/RebateTypePolicy/NewRebateTypePolicy"
import RebateMeasurePolicies from "../Pages/Policies/RebateMeasurePolicy/RebateMeasurePolicies"
import NewRebateMeasurePolicy from "../Pages/Policies/RebateMeasurePolicy/NewRebateMeasurePolicy"
import RebatePayoutCondPolicies from "../Pages/Policies/RebatePayoutCondPolicy/RebatePayoutCondPolicies"
import NewRebatePayoutCondPolicy from "../Pages/Policies/RebatePayoutCondPolicy/NewRebatePayoutCondPolicy"
import SettlementMethodPolicies from "../Pages/Policies/SettlementMethodPolicy/SettlementMethodPolicies"
import NewSettlementMethodPolicy from "../Pages/Policies/SettlementMethodPolicy/NewSettlementMethodPolicy"
import SettlementFreqPolicies from "../Pages/Policies/SettlementfreqPolicy/SettlementFreqPolicies"
import NewSettlementFreqPolicy from "../Pages/Policies/SettlementfreqPolicy/NewSettlementFreqPolicy"
import Groups from "../Pages/Groups/Groups"
import NewGroup from "../Pages/Groups/NewGroup"

import PermissionSetList from "../Pages/PermissionSet/PermissionSetList"
import CreatePermissionSet from "../Pages/PermissionSet/CreatePermissionSet"
import EditPermissionSet from "../Pages/PermissionSet/EditPermissionSet"
import NotFound from "../Pages/Notfound/Notfound"

import CongurationPropertyList from "../Pages/ConfigureProperty/ConfigurationProperty"
import NewConfigurationProperty from "../Pages/ConfigureProperty/NewConfigurationProperty"
import NewEmailTemplate from "../Pages/Workflow/NewEmailTemplate"
import EmailTemplateList from "../Pages/Workflow/EmailTemplateList"
import AccrualsPosting from "../Pages/Accurals/AccuralsPosting"
import RebateDefaultValues from "../Pages/RebateDefaultValues/RebateDefaultValues"

import CompanyProfile from "../Pages/CompanyProfile/CompanyProfile"
import PartnerProfile from "../Pages/PartnerProfile/PartnerProfile"
import LockedDocuments from "../Pages/LockedDocuments/LockedDocuments"


import DistributorCustomerListing from "../Pages/DistributorCustomerData/DistributorCustomerData"
import DistributorCustomerListView from "../Components/DistributorCustomerComponent/DistributorCustomerListView"



import CreateScheduler from "../Pages/Scheduler/CreateScheduler"


import PaymentTermsPolicy from "../Pages/Policies/PaymentTermsPolicies/PaymentTermsPolicies"
import CreateNewPaymentTermPolicy from "../Pages/Policies/PaymentTermsPolicies/CreateNewPaymentTermPolicy"

import CategoryExceptionPolicy from "../Pages/Policies/CategoryExceptionPolicy/CategoryExceptionPolicies"
import CreateNewCategoryExceptionPolicy from "../Pages/Policies/CategoryExceptionPolicy/NewCategoryExceptionPolicy"


import SchedulerList from "../Pages/Scheduler/SchedulerList"


import SubDivideTargetPolicy from "../Pages/Policies/SubDivideTargetPolicies/SubDivideTargetPolicy"
import CreateNewSubDivideTargetPolicy from "../Pages/Policies/SubDivideTargetPolicies/CreateNewSubDivideTargetPolicy"

import RebateCategoryPolicy from "../Pages/Policies/RebateCategoryPolicies/RebateCategoryPolicy"
import CreateNewRebateCategoryPolicy from "../Pages/Policies/RebateCategoryPolicies/CreateNewRebateCategoryPolicy"

import SubDivideAccrualThresholdPolicy from "../Pages/Policies/SubDivideAccrualThresholdPolicy/SubDivideAccrualThresholdPolicy"
import CreateNewSubDivideAccrualThresholdPolicy from "../Pages/Policies/SubDivideAccrualThresholdPolicy/CreateNewSubDivideAccrualThresholdPolicy"


import ClaimReconcilationList from "../Pages/ClaimReconcilation/ClaimReconcilationList"
import CreateClaimReconcilation from "../Pages/ClaimReconcilation/CreateClaimReconcilation"
import ClaimReconcilationPosting from "../Pages/ClaimReconcilation/ClaimReconcilationPosting"
import AuditLogList from "../Pages/AuditLog/AuditLogList"
import AuditLogDetails from "../Pages/AuditLog/AuditLogDetails"
import Schedulerhistory from "../Pages/Scheduler/Schedulerhistory"

import MassActionSubmitListReconcilation from "../Pages/ClaimReconcilation/MassAction/Submit/MassActionSubmitListReconcilation"
import CreateNewMassActionSubmitReconcilationClaim from "../Pages/ClaimReconcilation/MassAction/Submit/CreateNewMassActionSubmitReconcilationClaim"
import CreateNewMassActionSubmitReconcilationShipAndDebit from "../Pages/ClaimReconcilation/MassAction/Submit/CreateNewMassActionSubmitReconcilationShipAndDebit"

import CustomerGroupsList from "../Pages/CustomerGroups/CustomerGroupsList"
import CreateCustomerGroups from "../Pages/CustomerGroups/CreateCustomerGroups"

import ExceptionConfigListView from "../Pages/ExceptionConfiguration/ExceptionConfigList"
import CreateExceptionConfigView from "../Pages/ExceptionConfiguration/CreateExceptionConfig"

import ExceptionPolicylist from "../Pages/ExceptionPolicy/ExceptionPolicylist"
import CreateNewExceptionPolicy from "../Pages/ExceptionPolicy/CreateNewExceptionPolicy"

import PoliciesList from "../Pages/Policy/Policies"
import Admin from "../Pages/Menu/Admin"
import GenericPolicy from "../Pages/Policy/GenericPolicy"

import ExceptionPolicyPendingAction from "../Pages/ExceptionPolicy/Approvals/PendingAction"
import ExceptionPolicyArchive from "../Pages/ExceptionPolicy/Approvals/Archive"

import MassActionSubmitAccrualListView from "../Pages/Accurals/MassActionSubmit/MassActionSubmitAccrualsListView"
import CreateMassActionSubmitAccrualView from "../Pages/Accurals/MassActionSubmit/CreateMassActionSubmitAccrualView"

import AccrualGenerateView from "../Pages/Accurals/MassActionGenerate/AccrualGenerateView"
import CreateMassActionGenerateAccrualView from "../Pages/Accurals/MassActionGenerate/CreateMassActionGenerateAccrualView"

import DistributorBudgetedAmountPolicylist from "../Pages/DistributorBudgetedAmountPolicy/DistributorBudgetedAmountPolicylist"
import CreateNewDistributorBudgetedAmountPolicy from "../Pages/DistributorBudgetedAmountPolicy/CreateNewDistributorBudgetedAmountPolicy"

import DistributorBudgetedAmountPolicyPendingAction from "../Pages/DistributorBudgetedAmountPolicy/Approvals/PendingAction"
import DistributorBudgetedAmountPolicyArchive from "../Pages/DistributorBudgetedAmountPolicy/Approvals/Archive"

import PendingApprovalClaimReconcilation from "../Pages/ClaimReconcilation/Approvals/PendingAction"
import ArchiveClaimReconcilation from "../Pages/ClaimReconcilation/Approvals/Archive"
import SDDashboard from "../Pages/Dashboard/SDDashboard"

import CashDiscountPolicylist from "../Pages/CashDiscountPolicy/CashDiscountPolicyList"
import CreateNewCashDiscountPolicy from "../Pages/CashDiscountPolicy/CreateNewCashDiscountPolicy"

import PaymentPending from "../Pages/InvoicePayment/PaymentPending"
import PaymentReceived from "../Pages/InvoicePayment/PaymentReceived"
import PaymentHistory from "../Pages/InvoicePayment/PaymentHistory"


import ChangeHistory from "../Pages/ChangeHistory/ChangeHistory"
import CreateClaimAgreement from "../Pages/RebateProgram/CreateClaimAgreement/CreateClaimAgreement"




// import AnnualPolicyList from "../Pages/EndurancePolicies/AnnualPolicy/AnnualPolicylist"
import CreateNewAnnualPolicy from "../Pages/EndurancePolicies/AnnualPolicy/CreateNewAnnualPolicy"
import AnnualArchive from "../Pages/EndurancePolicies/AnnualPolicy/AnnualArchive"
import PendingAnnualPolicy from "../Pages/EndurancePolicies/AnnualPolicy/AnnualPendingAction"
import AnnualPolicy from "../Pages/EndurancePolicies/AnnualPolicy/AnnualPolicylist"


import SchemeListing from "../Pages/Scheme/SchemeListing/SchemeListing"
import CreateScheme from "../Pages/Scheme/CreateScheme/CreateScheme"
import CreateProductScheme from "../Pages/Scheme/CreateProductScheme/CreateProductScheme"
import PendingScheme from "../Pages/Scheme/Approvals/PendingActionScheme"
import ArchiveScheme from "../Pages/Scheme/Approvals/ArchiveScheme"


import CreateNewTargetSettingPolicy from "../Pages/EndurancePolicies/TargetSettingPolicy/CreateNewTargetSettingPolicy"
import TargetSettingArchive from "../Pages/EndurancePolicies/TargetSettingPolicy/TargetSettingArchive"
import PendingTargetSettingPolicy from "../Pages/EndurancePolicies/TargetSettingPolicy/TargetSettingPendingAction"
import TargetSettingPolicy from "../Pages/EndurancePolicies/TargetSettingPolicy/TargetSettingPolicylist"


import CreateNewSlabBasedPolicy from "../Pages/EndurancePolicies/SlabBasedPolicy/CreateNewSlabBasedPolicy"
import SlabBasedArchive from "../Pages/EndurancePolicies/SlabBasedPolicy/SlabBasedArchive"
import PendingSlabBasedPolicy from "../Pages/EndurancePolicies/SlabBasedPolicy/SlabBasedPendingAction"
import SlabBasedPolicy from "../Pages/EndurancePolicies/SlabBasedPolicy/SlabBasedPolicylist"

import CreateNewMinThresholdPolicy from "../Pages/EndurancePolicies/MinThresholdPolicy/CreateNewMinThresholdPolicy"
import MinThresholdArchive from "../Pages/EndurancePolicies/MinThresholdPolicy/MinThresholdArchive"
import PendingMinThresholdPolicy from "../Pages/EndurancePolicies/MinThresholdPolicy/MinThresholdPendingAction"
import MinThresholdPolicy from "../Pages/EndurancePolicies/MinThresholdPolicy/MinThresholdPolicylist"

import CreateNewECCPolicy from "../Pages/EndurancePolicies/ECCPolicy/CreateNewECCPolicy"
import ECCArchive from "../Pages/EndurancePolicies/ECCPolicy/ECCArchive"
import PendingECCPolicy from "../Pages/EndurancePolicies/ECCPolicy/ECCPendingAction"
import ECCPolicy from "../Pages/EndurancePolicies/ECCPolicy/ECCPolicylist"

import CreateNewEarlyBirdPolicy from "../Pages/EndurancePolicies/EarlyBirdPolicy/CreateNewEarlyBirdPolicy"
import EarlyBirdArchive from "../Pages/EndurancePolicies/EarlyBirdPolicy/EarlyBirdArchive"
import PendingEarlyBirdPolicy from "../Pages/EndurancePolicies/EarlyBirdPolicy/EarlyBirdPendingAction"
import EarlyBirdPolicy from "../Pages/EndurancePolicies/EarlyBirdPolicy/EarlyBirdPolicylist"


// Category Minimum Achievement Policy Pages 
import CreateNewCategoryMinimumAchievementPolicy from "../Pages/EndurancePolicies/CategoryMinimumAchievementPolicy/CreateNewCategoryMinimumAchievementPolicy"
import CategoryMinimumAchievementArchive from "../Pages/EndurancePolicies/CategoryMinimumAchievementPolicy/CategoryMinimumAchievementPolicyArchive"
import PendingCategoryMinimumAchievementPolicy from "../Pages/EndurancePolicies/CategoryMinimumAchievementPolicy/CategoryMinimumAchievementPolicyPendingAction"
import CategoryMinimumAchievementPolicy from "../Pages/EndurancePolicies/CategoryMinimumAchievementPolicy/CategoryMinimumAchievementPolicyList"

// QuarterFixedValue Policy Pages 
import CreateNewFixedValuePolicy from "../Pages/EndurancePolicies/FixedValuePolicy/CreateNewFixedValuePolicy"
import FixedValueArchive from "../Pages/EndurancePolicies/FixedValuePolicy/FixedValuePolicyArchive"
import PendingFixedValuePolicy from "../Pages/EndurancePolicies/FixedValuePolicy/FixedValuePolicyPendingAction"
import FixedValuePolicy from "../Pages/EndurancePolicies/FixedValuePolicy/FixedValuePolicyList"

// Spares Target Policy Pages
import CreateNewSparesTargetPolicy from "../Pages/EndurancePolicies/SparesTargetPolicy/CreateNewSparesTargetPolicy"
import SparesTargetPolicyArchive from "../Pages/EndurancePolicies/SparesTargetPolicy/SparesTargetPolicyArchive"
import PendingSparesTargetPolicy from "../Pages/EndurancePolicies/SparesTargetPolicy/SparesTargetPolicyPendingAction"
import SparesTargetPolicy from "../Pages/EndurancePolicies/SparesTargetPolicy/SparesTargetPolicyList"

// Material Pricing Group Mapping Policy Pages // Segment Mapping Policy

import CreateSegmentMappingPolicy from "../Pages/EndurancePolicies/MaterialPricingGroupMappingPolicy/CreateNewMaterialPricingGroupMappingPolicy"
import SegmentMappingPolicyArchive from "../Pages/EndurancePolicies/MaterialPricingGroupMappingPolicy/MaterialPricingGroupMappingArchive"
import PendingSegmentMappingPolicy from "../Pages/EndurancePolicies/MaterialPricingGroupMappingPolicy/MaterialPricingGroupMappingPendingAction"
import SegmentMappingPolicy from "../Pages/EndurancePolicies/MaterialPricingGroupMappingPolicy/MaterialPricingGroupMappingPolicylist"


// Target Achievement Policy Pages 
import CreateNewTargetAchievementPolicy from "../Pages/EndurancePolicies/TargetAchievementPolicy/CreateNewTargetAchievementPolicy"
import TargetAchievementPolicyArchive from "../Pages/EndurancePolicies/TargetAchievementPolicy/TargetAchievementPolicyArchive"
import PendingTargetAchievementPolicy from "../Pages/EndurancePolicies/TargetAchievementPolicy/TargetAchievementPolicyPendingAction"
import TargetAchievementPolicy from "../Pages/EndurancePolicies/TargetAchievementPolicy/TargetAchievementPolicyList"


// Exception Target Policy 

// import CreateNewExceptionTargetPolicy from "../Pages/EndurancePolicies/ExceptionTargetPolicy/CreateNewExceptionTargetPolicy"
// import ExceptionTargetArchive from "../Pages/EndurancePolicies/ExceptionTargetPolicy/ExceptionTargetArchive"
// import PendingExceptionTargetPolicy from "../Pages/EndurancePolicies/ExceptionTargetPolicy/ExceptionTargetPendingAction"
// import ExceptionTargetPolicy from "../Pages/EndurancePolicies/ExceptionTargetPolicy/ExceptionTargetPolicylist"


import CreatePolicyConfigPage from "../Pages/PolicyConfiguration/PolicyConfig"
import PendingActionScheme from "../Pages/Scheme/Approvals/PendingActionScheme"

import SegmentPolicies from "../Pages/Policies/SegmentPolicy/SegmentPolicies"
import NewSegmentPolicy from "../Pages/Policies/SegmentPolicy/NewSegmentPolicy"

import DistributorGroupMappingPolicy from "../Pages/EndurancePolicies/DistributorGroupMappingPolicy/DistributorGroupMappingPolicylist"
import CreateNewDistributorGroupMappingPolicy from "../Pages/EndurancePolicies/DistributorGroupMappingPolicy/CreateNewDistributorGroupMappingPolicy"
import PendingDistributorGroupMappingPolicy from "../Pages/EndurancePolicies/DistributorGroupMappingPolicy/DistributorGroupMappingPendingAction"
import DistributorGroupMappingPolicyArchive from "../Pages/EndurancePolicies/DistributorGroupMappingPolicy/DistributorGroupMappingArchive"

import EngineOilVolumeMappingPolicies from "../Pages/Policies/EngineOilVolumeMappingPolicy/EngineOilVolumeMappingPolicies"
import NewEngineOilVolumeMappingPolicy from "../Pages/Policies/EngineOilVolumeMappingPolicy/NewEngineOilVolumeMappingPolicy"
import CreateReports from "../Pages/DataAdministration/CreateReports"


const route = [

   // { path: '/', name: 'Rebate', component: Dashboard, element: Dashboard },    
   { path: '/', name: 'ExecutiveDashboard', component: ExecutiveDashboard, element: ExecutiveDashboard },

   { path: '/rebate-program/:val', name: 'Rebate', component: RebateListing, element: RebateListing },
   { path: '/new-rebate/:id/:name/:value', name: 'CreateRebate', component: CreateRebate, element: CreateRebate },
   { path: '/new-claimagreement/:id/:name/:value', name: 'CreateClaimAgreement', component: CreateClaimAgreement, element: CreateClaimAgreement },

   { path: '/new-engineoil/:id/:name/:value', name: 'CreateEngineOil', component: CreateEngineOil, element: CreateEngineOil },

   { path: '/accruals', name: 'AccuralList', component: AccuralList, element: AccuralList },
   { path: 'accruals/new/:id/:name/', name: 'CreateAccurals', component: CreateAccurals, element: CreateAccurals },
   { path: 'accruals/new/:id/:name/:option', name: 'CreateAccurals', component: CreateAccurals, element: CreateAccurals },





   { path: '/scheduler', name: 'SchedulerList', component: SchedulerList, element: SchedulerList },
   { path: '/Createscheduler', name: 'NewSchedulerList', component: CreateScheduler, element: CreateScheduler },
   { path: '/users', name: 'UserList', component: UserList, element: UserList },
   { path: '/users/add', name: 'CreateUser', component: CreateUser, element: CreateUser },
   { path: '/users/edit/:id', name: 'CreateUser', component: EditUser, element: EditUser },

   { path: '/roles', name: 'RoleList', component: RoleList, element: RoleList },
   { path: '/roles/edit/:id', name: 'EditRole', component: EditRole, element: EditRole },
   { path: '/roles/add', name: 'CreateRole', component: CreateRole, element: CreateRole },

   { path: '/rebateapprovals/pendingaction/:id', name: 'PendingApprovalRebate', component: PendingApprovalRebate, element: PendingApprovalRebate },
   { path: '/rebateapprovals/archive/:id', name: 'ArchiveRebate', component: ArchiveRebate, element: ArchiveRebate },

   { path: '/accrualapprovals/pendingaction/:id', name: 'PendingApprovalAccrual', component: PendingApprovalAccrual, element: PendingApprovalAccrual },
   { path: '/accrualapprovals/archive/:id', name: 'ArchiveAccrual', component: ArchiveAccrual, element: ArchiveAccrual },

   { path: '/Organization', name: 'Organization', component: Organization, element: Organization },

   { path: '/workflow/:id', name: 'Workflow', component: Workflow, element: Workflow },
   { path: '/workflow/add/:id', name: 'NewWorkflow', component: NewWorkflow, element: NewWorkflow },
   { path: '/workflow/edit/:id', name: 'WorkflowEdit', component: NewWorkflow, element: NewWorkflow },

   { path: '/emailtemplates/add/:id', name: 'NewEmailTemplate', component: NewEmailTemplate, element: NewEmailTemplate },
   { path: '/emailtemplates/edit/:id', name: 'NepwEmailTemplate', component: NewEmailTemplate, element: NewEmailTemplate },
   { path: '/emailtemplates', name: 'EmailTemplateList', component: EmailTemplateList, element: EmailTemplateList },

   { path: '/dataimport', name: 'DataImport', component: DataImport, element: DataImport },
   { path: '/dataexport', name: 'DataExport', component: DataExport, element: DataExport },

   // Reports Section
   { path: '/reports', name: 'Reports', component: Reports, element: Reports },
   { path: '/reports/byCustomer', name: 'CreateReports', component: CreateReports, element: CreateReports },

   { path: '/policies/rebatetype', name: 'RebateTypePolicies', component: RebateTypePolicies, element: RebateTypePolicies },
   { path: '/policies/rebatetype/add/:id', name: 'NewRebateTypePolicy', component: NewRebateTypePolicy, element: NewRebateTypePolicy },
   { path: '/policies/rebatetype/edit/:id', name: 'NewRebateTypePolicy', component: NewRebateTypePolicy, element: NewRebateTypePolicy },


   { path: '/policies/rebatemeasure', name: 'RebateMeasurePolicies', component: RebateMeasurePolicies, element: RebateMeasurePolicies },
   { path: '/policies/rebatemeasure/add/:id', name: 'NewRebateMeasurePolicy', component: NewRebateMeasurePolicy, element: NewRebateMeasurePolicy },
   { path: '/policies/rebatemeasure/edit/:id', name: 'NewRebateMeasurePolicy', component: NewRebateMeasurePolicy, element: NewRebateMeasurePolicy },


   { path: '/policies/rebatepayoutcond', name: 'RebatePayoutCondPolicies', component: RebatePayoutCondPolicies, element: RebatePayoutCondPolicies },
   { path: '/policies/rebatepayoutcond/add/:id', name: 'New RebatePayoutCondPolicy', component: NewRebatePayoutCondPolicy, element: NewRebatePayoutCondPolicy },
   { path: '/policies/rebatepayoutcond/edit/:id', name: 'Edit RebatePayoutCondPolicy', component: NewRebatePayoutCondPolicy, element: NewRebatePayoutCondPolicy },

   { path: '/policies/settlementmethod', name: 'SettlementMethodPolicies', component: SettlementMethodPolicies, element: SettlementMethodPolicies },
   { path: '/policies/settlementmethod/add/:id', name: 'NewSettlementMethodPolicy', component: NewSettlementMethodPolicy, element: NewSettlementMethodPolicy },
   { path: '/policies/settlementmethod/edit/:id', name: 'NewSettlementMethodPolicy', component: NewSettlementMethodPolicy, element: NewSettlementMethodPolicy },

   { path: '/policies/settlementfreq', name: 'SettlementFreqPolicies', component: SettlementFreqPolicies, element: SettlementFreqPolicies },
   { path: '/policies/settlementfreq/add/:id', name: 'NewSettlementFreqPolicy', component: NewSettlementFreqPolicy, element: NewSettlementFreqPolicy },
   { path: '/policies/settlementfreq/edit/:id', name: 'NewSettlementFreqPolicy', component: NewSettlementFreqPolicy, element: NewSettlementFreqPolicy },


   { path: '/dataentitlement', name: 'Groups', component: Groups, element: Groups },
   { path: '/dataentitlement/edit/:id', name: 'NewGroups', component: NewGroup, element: NewGroup },

   { path: '/permissionset', name: 'PermissionSetList', component: PermissionSetList, element: PermissionSetList, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/permissionset/add', name: 'CreatePermissionSet', component: CreatePermissionSet, element: CreatePermissionSet, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/permissionset/edit/:id', name: 'EditPermissionSet', component: EditPermissionSet, element: EditPermissionSet, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/*', name: 'notfound', component: NotFound, element: NotFound },

   { path: '/congurationprop', name: 'CongurationPropertyList', component: CongurationPropertyList, element: CongurationPropertyList },
   { path: '/congurationprop/add/:id', name: 'NewConfigurationProperty', component: NewConfigurationProperty, element: NewConfigurationProperty },
   { path: '/congurationprop/edit/:id', name: 'NewConfigurationProperty', component: NewConfigurationProperty, element: NewConfigurationProperty },
   { path: '/accrualsposting', name: 'AccrualsPosting', component: AccrualsPosting, element: AccrualsPosting },

   { path: '/rebatedefaultvalues', name: 'RebateDefaultValues', component: RebateDefaultValues, element: RebateDefaultValues },

   { path: '/companyprofile', name: 'CompanyProfile', component: CompanyProfile, element: CompanyProfile },
   { path: '/partnerprofile', name: 'PartnerProfile', component: PartnerProfile, element: PartnerProfile },
   { path: '/partnerprofile/:id', name: 'PartnerProfile', component: PartnerProfile, element: PartnerProfile },
   { path: '/forecast-dashboard', name: 'forecastdashboard', component: Dashboard, element: Dashboard },
   { path: '/lockedDocuments', name: 'LockedDocuments', component: LockedDocuments, element: LockedDocuments },

   { path: '/PaymentTermsPolicy', name: 'PaymentTermsPolicy', component: PaymentTermsPolicy, element: PaymentTermsPolicy },
   { path: '/PaymentTermsPolicy/new/:id', name: 'NewPaymentTermsPolicy', component: CreateNewPaymentTermPolicy, element: CreateNewPaymentTermPolicy },
   { path: '/PaymentTermsPolicy/edit/:id', name: 'NewPaymentTermsPolicy', component: CreateNewPaymentTermPolicy, element: CreateNewPaymentTermPolicy },


   { path: '/CategoryExceptionPolicy', name: 'CategoryExceptionPolicy', component: CategoryExceptionPolicy, element: CategoryExceptionPolicy },
   { path: '/CategoryExceptionPolicy/new/:id', name: 'NewCategoryExceptionPolicy', component: CreateNewCategoryExceptionPolicy, element: CreateNewCategoryExceptionPolicy },
   { path: '/CategoryExceptionPolicy/edit/:id', name: 'NewCategoryExceptionPolicy', component: CreateNewCategoryExceptionPolicy, element: CreateNewCategoryExceptionPolicy },

   { path: '/SubDivideTargetPolicy', name: 'SubDivideTargetPolicy', component: SubDivideTargetPolicy, element: SubDivideTargetPolicy },
   { path: '/SubDivideTargetPolicy/new/:id', name: 'NewSubDivideTargetPolicy', component: CreateNewSubDivideTargetPolicy, element: CreateNewSubDivideTargetPolicy },
   { path: '/SubDivideTargetPolicy/edit/:id', name: 'NewSubDivideTargetPolicy', component: CreateNewSubDivideTargetPolicy, element: CreateNewSubDivideTargetPolicy },

   { path: '/SubDivideAccrualThresholdPolicy', name: 'SubDivideAccrualThresholdPolicy', component: SubDivideAccrualThresholdPolicy, element: SubDivideAccrualThresholdPolicy },
   { path: '/SubDivideAccrualThresholdPolicy/new/:id', name: 'NewSubDivideAccrualThresholdPolicy', component: CreateNewSubDivideAccrualThresholdPolicy, element: CreateNewSubDivideAccrualThresholdPolicy },
   { path: '/SubDivideAccrualThresholdPolicy/edit/:id', name: 'NewSubDivideAccrualThresholdPolicy', component: CreateNewSubDivideAccrualThresholdPolicy, element: CreateNewSubDivideAccrualThresholdPolicy },

   { path: '/RebateCategoryPolicy', name: 'RebateCategoryPolicy', component: RebateCategoryPolicy, element: RebateCategoryPolicy },
   { path: '/RebateCategoryPolicy/new/:id', name: 'NewRebateCategoryPolicy', component: CreateNewRebateCategoryPolicy, element: CreateNewRebateCategoryPolicy },
   { path: '/RebateCategoryPolicy/edit/:id', name: 'NewRebateCategoryPolicy', component: CreateNewRebateCategoryPolicy, element: CreateNewRebateCategoryPolicy },

   { path: '/DistributorCustomerData', name: 'DistributorCustomerData', component: DistributorCustomerListView, element: DistributorCustomerListView },

   { path: '/auditlog', name: 'auditlog', component: AuditLogList, element: AuditLogList },
   { path: '/auditlog/details/:id', name: 'auditlogD', component: AuditLogDetails, element: AuditLogDetails },
   { path: '/Schedulerhistory/:id', name: 'Schedulerhistory', component: Schedulerhistory, element: Schedulerhistory },


   { path: '/policies/customergroups', name: 'customergroups', component: CustomerGroupsList, element: CustomerGroupsList },
   { path: '/policies/customergroups/add', name: 'customergroupsadd', component: CreateCustomerGroups, element: CreateCustomerGroups },
   { path: '/policies/customergroups/edit/:id', name: 'customergroupsadd', component: CreateCustomerGroups, element: CreateCustomerGroups },

   { path: '/ExceptionConfigList', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView },
   // {path: '/ExceptionConfigList/new/:id', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView},
   // {path: '/ExceptionConfigList/edit/:id', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView},

   { path: '/Exception-Policy/List', name: 'ExceptionPolicyList', component: ExceptionPolicylist, element: ExceptionPolicylist },
   { path: '/ExceptionPolicylist/new/:id/:name', name: 'NewExceptionPolicy', component: CreateNewExceptionPolicy, element: CreateNewExceptionPolicy },

   { path: '/policies', name: 'Policies', component: PoliciesList, element: PoliciesList },
   { path: '/admin', name: 'Admin', component: Admin, element: Admin },

   { path: '/ExceptionPolicylist/approvals/pending', name: 'pendingaction', component: ExceptionPolicyPendingAction, element: ExceptionPolicyPendingAction },
   { path: '/ExceptionPolicylist/approvals/archive', name: 'archive', component: ExceptionPolicyArchive, element: ExceptionPolicyArchive },

   { path: '/MassActionSubmitAccrualList', name: 'MassActionSubmitAccrual', component: MassActionSubmitAccrualListView, element: MassActionSubmitAccrualListView },
   { path: '/MassActionSubmitAccrualList/new/:id', name: 'NewMassActionSubmitAccrualList', component: CreateMassActionSubmitAccrualView, element: CreateMassActionSubmitAccrualView },
   { path: '/MassActionSubmitAccrualList/edit/:id', name: 'EditMassActionSubmitAccrualList', component: CreateMassActionSubmitAccrualView, element: CreateMassActionSubmitAccrualView },


   { path: '/generateaccruals', name: 'GenerateAccruals', component: AccrualGenerateView, element: AccrualGenerateView },
   { path: '/MassActionGenerateAccrualList/new/:id', name: 'NewMassActionGenerateAccrualList', component: CreateMassActionGenerateAccrualView, element: CreateMassActionGenerateAccrualView },

   { path: '/DistributorBudgetedAmountPolicylist', name: 'DistributorBudgetedAmountPolicylist', component: DistributorBudgetedAmountPolicylist, element: DistributorBudgetedAmountPolicylist },
   { path: '/DistributorBudgetedAmountPolicylist/new/:id', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },
   { path: '/DistributorBudgetedAmountPolicylist/edit/:id', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },

   { path: '/DistributorBudgetedAmountPolicylist/approvals/pending', name: 'pendingaction', component: DistributorBudgetedAmountPolicyPendingAction, element: DistributorBudgetedAmountPolicyPendingAction },
   { path: '/DistributorBudgetedAmountPolicylist/approvals/archive', name: 'archive', component: DistributorBudgetedAmountPolicyArchive, element: DistributorBudgetedAmountPolicyArchive },

   { path: '/DistributorBudgetedAmountPolicylist/new/:id/:name', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },

   { path: '/reconcilation/pendingaction/0', name: 'pendingaction', component: PendingApprovalClaimReconcilation, element: PendingApprovalClaimReconcilation },
   { path: '/reconcilation/archive/0', name: 'archive', component: ArchiveClaimReconcilation, element: ArchiveClaimReconcilation },
   { path: '/sd-dashboard', name: 'SDDashboard', component: SDDashboard, element: SDDashboard },

   { path: '/CashDiscountPolicylist', name: 'CashDiscountPolicylist', component: CashDiscountPolicylist, element: CashDiscountPolicylist },
   { path: '/CashDiscountPolicylist/new/:id', name: 'CreateNewCashDiscountPolicy', component: CreateNewCashDiscountPolicy, element: CreateNewCashDiscountPolicy },

   { path: '/paymentpending', name: 'paymentpendinglist', component: PaymentPending, element: PaymentPending },
   { path: '/paymentreceived', name: 'paymentreceivedlist', component: PaymentReceived, element: PaymentReceived },
   { path: '/paymenthistory', name: 'paymenthistorylist', component: PaymentHistory, element: PaymentHistory },

   { path: '/ChangeHistoryList', name: 'ChangeHistoryList', component: ChangeHistory, element: ChangeHistory },

   // Endurance Policy Navigation

   //----------------------------------------------------------------------------------------------//

   // Annual Policy Navigation

   { path: '/Annual-Policy/List', name: 'AnnualPolicyList', component: AnnualPolicy, element: AnnualPolicy },
   { path: '/AnnualPolicy/new/:id/:name', name: 'NewAnnualPolicy', component: CreateNewAnnualPolicy, element: CreateNewAnnualPolicy },
   { path: '/AnnualPolicylist/edit/:id/:name', name: 'NewAnnualPolicy', component: CreateNewAnnualPolicy, element: CreateNewAnnualPolicy },
   { path: '/AnnualPolicylist/approvals/pending', name: 'AnnualPendingAction', component: PendingAnnualPolicy, element: PendingAnnualPolicy },
   { path: '/AnnualPolicylist/approvals/archive', name: 'AnnualArchive', component: AnnualArchive, element: AnnualArchive },


   // Target Setting Policy Navigation

   { path: '/TargetSetting-Policy/List', name: 'TargetSettingPolicyList', component: TargetSettingPolicy, element: TargetSettingPolicy },
   { path: '/TargetSettingPolicylist/new/:id/:name', name: 'NewTargetSettingPolicy', component: CreateNewTargetSettingPolicy, element: CreateNewTargetSettingPolicy },
   { path: '/TargetSettingPolicylist/edit/:id/:name', name: 'NewTargetSettingPolicy', component: CreateNewTargetSettingPolicy, element: CreateNewTargetSettingPolicy },
   { path: '/TargetSettingPolicylist/approvals/pending', name: 'TargetSettingPendingAction', component: PendingTargetSettingPolicy, element: PendingTargetSettingPolicy },
   { path: '/TargetSettingPolicylist/approvals/archive', name: 'TargetSettingArchive', component: TargetSettingArchive, element: TargetSettingArchive },

   // SlabBased Policy Navigation

   { path: '/SlabBased-Policy/List', name: 'SlabBasedPolicyList', component: SlabBasedPolicy, element: SlabBasedPolicy },
   { path: '/SlabBasedPolicylist/new/:id/:name', name: 'NewSlabBasedPolicy', component: CreateNewSlabBasedPolicy, element: CreateNewSlabBasedPolicy },
   { path: '/SlabBasedPolicylist/edit/:id/:name', name: 'NewSlabBasedPolicy', component: CreateNewSlabBasedPolicy, element: CreateNewSlabBasedPolicy },
   { path: '/SlabBasedPolicylist/approvals/pending', name: 'SlabBasedPendingAction', component: PendingSlabBasedPolicy, element: PendingSlabBasedPolicy },
   { path: '/SlabBasedPolicylist/approvals/archive', name: 'SlabBasedArchive', component: SlabBasedArchive, element: SlabBasedArchive },

   // MinThreshold Policy Navigation

   { path: '/MinThreshold-Policy/List', name: 'MinThresholdPolicyList', component: MinThresholdPolicy, element: MinThresholdPolicy },
   { path: '/MinThresholdPolicylist/new/:id/:name', name: 'NewMinThresholdPolicy', component: CreateNewMinThresholdPolicy, element: CreateNewMinThresholdPolicy },
   { path: '/MinThresholdPolicylist/edit/:id/:name', name: 'NewMinThresholdPolicy', component: CreateNewMinThresholdPolicy, element: CreateNewMinThresholdPolicy },
   { path: '/MinThresholdPolicylist/approvals/pending', name: 'MinThresholdPendingAction', component: PendingMinThresholdPolicy, element: PendingMinThresholdPolicy },
   { path: '/MinThresholdPolicylist/approvals/archive', name: 'MinThresholdArchive', component: MinThresholdArchive, element: MinThresholdArchive },

   // ECC Policy Navigation

   { path: '/ECC-Policy/List', name: 'ECCPolicyList', component: ECCPolicy, element: ECCPolicy },
   { path: '/ECCPolicylist/new/:id/:name', name: 'NewECCPolicy', component: CreateNewECCPolicy, element: CreateNewECCPolicy },
   { path: '/ECCPolicylist/edit/:id/:name', name: 'NewECCPolicy', component: CreateNewECCPolicy, element: CreateNewECCPolicy },
   { path: '/ECCPolicylist/approvals/pending', name: 'ECCPendingAction', component: PendingECCPolicy, element: PendingECCPolicy },
   { path: '/ECCPolicylist/approvals/archive', name: 'ECCArchive', component: ECCArchive, element: ECCArchive },


   //Material Pricing Group Mapping Policy Navigation

   { path: '/SegmentMapping-Policy/List', name: 'SegmentMappingPolicyList', component: SegmentMappingPolicy, element: SegmentMappingPolicy },
   { path: '/SegmentMappingPolicyList/new/:id/:name', name: 'NewSegmentMappingPolicy', component: CreateSegmentMappingPolicy, element: CreateSegmentMappingPolicy },
   { path: '/SegmentMappingPolicyList/edit/:id/:name', name: 'NewSegmentMappingPolicy', component: CreateSegmentMappingPolicy, element: CreateSegmentMappingPolicy },
   { path: '/SegmentMappingPolicyList/pending', name: 'SegmentMappingPendingAction', component: PendingSegmentMappingPolicy, element: PendingSegmentMappingPolicy },
   { path: '/SegmentMappingPolicyList/archive', name: 'SegmentMappingArchive', component: SegmentMappingPolicyArchive, element: SegmentMappingPolicyArchive },


   //EarlyBird Navigation

   { path: '/EarlyBird-Policylist', name: 'EarlyBirdPolicyList', component: EarlyBirdPolicy, element: EarlyBirdPolicy },
   { path: '/EarlyBirdPolicylist/new/:id/:name', name: 'NewEarlyBirdPolicy', component: CreateNewEarlyBirdPolicy, element: CreateNewEarlyBirdPolicy },
   { path: '/EarlyBirdPolicylist/edit/:id/:name', name: 'NewEarlyBirdPolicy', component: CreateNewEarlyBirdPolicy, element: CreateNewEarlyBirdPolicy },
   { path: '/EarlyBirdPolicylist/approvals/pending', name: 'EarlyBirdPendingAction', component: PendingEarlyBirdPolicy, element: PendingEarlyBirdPolicy },
   { path: '/EarlyBirdPolicylist/approvals/archive', name: 'EarlyBirdArchive', component: EarlyBirdArchive, element: EarlyBirdArchive },


   //Category Minimum Achievement Policy Navigation

   { path: '/CategoryMinimumAchievement-Policy/List', name: 'CategoryMinimumAchievementPolicyList', component: CategoryMinimumAchievementPolicy, element: CategoryMinimumAchievementPolicy },
   { path: '/CategoryMinimumAchievementPolicyList/new/:id/:name', name: 'NewCategoryMinimumAchievementPolicy', component: CreateNewCategoryMinimumAchievementPolicy, element: CreateNewCategoryMinimumAchievementPolicy },
   { path: '/CategoryMinimumAchievementPolicyList/edit/:id/:name', name: 'NewCategoryMinimumAchievementPolicy', component: CreateNewCategoryMinimumAchievementPolicy, element: CreateNewCategoryMinimumAchievementPolicy },
   { path: '/CategoryMinimumAchievementPolicyList/pending', name: 'CategoryMinimumAchievementPendingAction', component: PendingCategoryMinimumAchievementPolicy, element: PendingCategoryMinimumAchievementPolicy },
   { path: '/CategoryMinimumAchievementPolicyList/archive', name: 'CategoryMinimumAchievementArchive', component: CategoryMinimumAchievementArchive, element: CategoryMinimumAchievementArchive },

   //Quarter Fixed Value Policy 

   { path: '/FixedValue-Policy/List', name: 'FixedValuePolicyList', component: FixedValuePolicy, element: FixedValuePolicy },
   { path: '/FixedValuePolicyList/new/:id/:name', name: 'NewFixedValuePolicy', component: CreateNewFixedValuePolicy, element: CreateNewFixedValuePolicy },
   { path: '/FixedValuePolicyList/edit/:id/:name', name: 'NewFixedValuePolicy', component: CreateNewFixedValuePolicy, element: CreateNewFixedValuePolicy },
   { path: '/FixedValuePolicyList/pending', name: 'FixedValuePendingAction', component: PendingFixedValuePolicy, element: PendingFixedValuePolicy },
   { path: '/FixedValuePolicyList/archive', name: 'FixedValueArchive', component: FixedValueArchive, element: FixedValueArchive },


   //Spares Target Policy Navigation

   { path: '/SparesTarget-Policy/List', name: 'SparesTargetPolicyList', component: SparesTargetPolicy, element: SparesTargetPolicy },
   { path: '/SparesTargetPolicyList/new/:id/:name', name: 'NewSparesTargetPolicy', component: CreateNewSparesTargetPolicy, element: CreateNewSparesTargetPolicy },
   { path: '/SparesTargetPolicyList/edit/:id/:name', name: 'NewSparesTargetPolicy', component: CreateNewSparesTargetPolicy, element: CreateNewSparesTargetPolicy },
   { path: '/SparesTargetPolicyList/pending', name: 'SparesTargetPendingAction', component: PendingSparesTargetPolicy, element: PendingSparesTargetPolicy },
   { path: '/SparesTargetPolicyList/archive', name: 'SparesTargetArchive', component: SparesTargetPolicyArchive, element: SparesTargetPolicyArchive },


   //Target Achievement Policy Navigation

   { path: '/TargetAchievement-Policy/List', name: 'TargetAchievementPolicyList', component: TargetAchievementPolicy, element: TargetAchievementPolicy },
   { path: '/TargetAchievementPolicyList/new/:id/:name', name: 'NewTargetAchievementPolicy', component: CreateNewTargetAchievementPolicy, element: CreateNewTargetAchievementPolicy },
   { path: '/TargetAchievementPolicyList/edit/:id/:name', name: 'NewTargetAchievementPolicy', component: CreateNewTargetAchievementPolicy, element: CreateNewTargetAchievementPolicy },
   { path: '/TargetAchievementPolicyList/pending', name: 'TargetAchievementPendingAction', component: PendingTargetAchievementPolicy, element: PendingTargetAchievementPolicy },
   { path: '/TargetAchievementPolicyList/archive', name: 'TargetAchievementArchive', component: TargetAchievementPolicyArchive, element: TargetAchievementPolicyArchive },

   // Policy Data Config Navigation

   { path: '/CreatePolicyConfigList', name: 'NewPolicyconfigurationList', component: CreatePolicyConfigPage, element: CreatePolicyConfigPage },


   // Scheme Navigation

   { path: '/scheme-program/:val', name: 'Scheme', component: SchemeListing, element: SchemeListing },
   { path: '/scheme-list/:val', name: 'Scheme', component: SchemeListing, element: SchemeListing },
   { path: '/new-scheme/:id/:name/:value', name: 'CreateScheme', component: CreateScheme, element: CreateScheme },
   { path: '/new-product-scheme/:id/:name/:value', name: 'CreateProductScheme', component: CreateProductScheme, element: CreateProductScheme },
   { path: '/SchemeList/pending', name: 'PendingActionScheme', component: PendingScheme, element: PendingActionScheme },
   { path: '/SchemeList/archive', name: 'SchemeArchive', component: ArchiveScheme, element: ArchiveScheme },


   // Exception Target Policy 

   // { path: '/ExceptionTargetPolicylist', name: 'ExceptionTargetPolicyList', component: ExceptionTargetPolicy, element: ExceptionTargetPolicy },
   // { path: '/ExceptionTargetPolicylist/new/:id/:name', name: 'NewExceptionTargetPolicy', component: CreateNewExceptionTargetPolicy, element: CreateNewExceptionTargetPolicy },
   // { path: '/ExceptionTargetPolicylist/edit/:id/:name', name: 'NewExceptionTargetPolicy', component: CreateNewExceptionTargetPolicy, element: CreateNewExceptionTargetPolicy },
   // { path: '/ExceptionTargetPolicylist/approvals/pending', name: 'ExceptionTargetPendingAction', component: PendingExceptionTargetPolicy, element: PendingExceptionTargetPolicy },
   // { path: '/ExceptionTargetPolicylist/approvals/archive', name: 'ExceptionTargetArchive', component: ExceptionTargetArchive, element: ExceptionTargetArchive },

   //Generic Policy

   { path: '/genericPolicies', name: 'GenericPolicies', component: GenericPolicy, element: GenericPolicy },

   // Segment Policy Navigation

   { path: '/policies/segment', name: 'SegmentPolicies', component: SegmentPolicies, element: SegmentPolicies },
   { path: '/policies/segment/add/:id', name: 'NewSegmentPolicy', component: NewSegmentPolicy, element: NewSegmentPolicy },
   { path: '/policies/segment/edit/:id', name: 'NewSegmentPolicy', component: NewSegmentPolicy, element: NewSegmentPolicy },
   //DistributorGroupPolicies
   { path: '/DistributorGroupMapping-Policy/List', name: 'DistributorGroupMappingPolicyList', component: DistributorGroupMappingPolicy, element: DistributorGroupMappingPolicy },
   { path: '/DistributorGroupMappingPolicyList/edit/:id/:name', name: 'NewDistributorGroupMappingPolicy', component: CreateNewDistributorGroupMappingPolicy, element: CreateNewDistributorGroupMappingPolicy },
   { path: '/DistributorGroupMappingPolicyList/approvals/pending', name: 'DistributorGroupMappingPendingAction', component: PendingDistributorGroupMappingPolicy, element: PendingDistributorGroupMappingPolicy },
   { path: '/DistributorGroupMappingPolicyList/approvals/archive', name: 'DistributorGroupMappingArchive', component: DistributorGroupMappingPolicyArchive, element: DistributorGroupMappingPolicyArchive },
   
   //EngineOilVolumeMappingPolicy   NewEngineOilVolumeMappingPolicy
   { path: '/policies/engineoilvolumemapping', name: 'EngineOilVolumeMappingPolicies', component: EngineOilVolumeMappingPolicies, element: EngineOilVolumeMappingPolicies },
   { path: '/policies/engineoilvolumemapping/add/:id', name: 'NewEngineOilVolumeMappingPolicy', component: NewEngineOilVolumeMappingPolicy, element: NewEngineOilVolumeMappingPolicy },
   { path: '/policies/engineoilvolumemapping/edit/:id', name: 'NewEngineOilVolumeMappingPolicy', component: NewEngineOilVolumeMappingPolicy, element: NewEngineOilVolumeMappingPolicy },
]

export default route

