import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'

import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import usercss from "../UserManagement/users.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import SessionManage from "../../../SessionManagement/SessionManage";



function CreateUserComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setselected] = useState([]);
    const [useravailable, setuseravailable] = useState('');
    const [invalidemail, setInvalidemail] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [selectedRole, setselectedRole] = useState(true);
    const [file, setFile] = useState([]);
   

    const [Roles, setRole] = useState([]);
    const [Groups, setGroup] = useState([]);

    const [id, setId] = useState(0);
    useEffect(() => {
        var id = SessionManage.getuserid();
        setId(id)
        getUserPermission(location.pathname);
        getData("Users/GetRoleAndGroup");
    }, [0])
  
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    

	          
    
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && typeof res.data.DataList !== 'undefined') {

                    if (typeof res.data.DataList.lstRoles !== 'undefined')
                    console.log(res.data.DataList.lstRoles, "roles");
                        setRole(res.data.DataList.lstRoles);

                    if (typeof res.data.DataList.lstGroups !== 'undefined')
                        setGroup(res.data.DataList.lstGroups);
                }
            }
        });
    }

    const emailinputhandler = (e) => {
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value, 'Username': value,
        }));
        if (value !== null && value != '') {
            Service.getdatabyId("Users/CheckUserExist", value.toLowerCase()).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setuseravailable(res.data);
                        }
                    }
                }


            });
        }
        if(value === ''){
            setInvalidemail(false);
            setuseravailable('User available')    
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setInvalidemail(true);
        }
        else {
            setInvalidemail(false);
        }
    }

    const [inputField, setInputField] = useState({
        FirstName: '',
        LastName: '',
        Username: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        lstRole: [],
        lstGroup: [],
        Active: false,
        IsSuperuser: false,
        IsActive: false,
        LastModifiedUser: id,
        CreatedUser: id,
        dateformat: '',
        Locale: '',
        Languages: '',
        UploadPic:null
    })

    const inputsHandler = (e) => {

        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const saveFileSelected = (e) => {
        const selectedFile = e.target.files[0];
        setFile([...file, ...e.target.files]);     
    };
    const checkValidation = () => {
        var hasError = false;
        setIsValid(true)

        if (inputField.FirstName == null || inputField.FirstName == '') {
            setErrorMessage('Please Enter First Name')
            setIsValid(false)
            hasError = true;
            setShowErrorModal(true)
        }
        else if (inputField.LastName == '' || inputField.LastName == null) {
            setErrorMessage('Please Enter Last Name')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Email == '' || inputField.Email == null) {
            setErrorMessage('Please Enter Email')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputField.Email)) {
            setErrorMessage('Please Enter Valid Email')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }else if(useravailable === 'User already exists'){
            setErrorMessage('This email is already associated with an existing user. Please use a different email.');
            //setErrorMessage('Email already in use. Please use a different one.');
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Username == '' || inputField.Username == null) {
            setErrorMessage('Please Enter UserName')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstRole == '' || inputField.lstRole == null) {
            setErrorMessage('Please Select Role')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstGroup == '' || inputField.lstGroup == null) {
            setErrorMessage('Please Select Group')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Password == '' || inputField.Password == null) {
            setErrorMessage('Please Enter Password')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.ConfirmPassword == '' || inputField.ConfirmPassword == null) {
            setErrorMessage('Please Enter Confirm Password')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Locale == '' || inputField.Locale == 'select' || inputField.Locale == null) {
            setErrorMessage('Please Select Locale')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.dateformat == '' || inputField.dateformat == 'select' || inputField.dateformat == null) {
            setErrorMessage('Please Select Date Format')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.ConfirmPassword != inputField.Password) {
            setErrorMessage('Your Password and Confirm Password do not match')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }       
        return hasError;
    }


    let selectedData = [];
    const submitButton = (e) => {
        e.preventDefault();
        setLoading(true);
        inputField.CreatedUser = id;
        inputField.LastModifiedUser = id;
        let Users = inputField;
        let SendData = new FormData();
        SendData.append("data", JSON.stringify(inputField));
        SendData.append('ProfileFile', file[0]);
        var hasError = checkValidation()
        if (hasError === false) {
            if (useravailable == "User available") {
                debugger
                Service.getdatabySdData("Users/CreateOrUpdate", SendData).then(res => {
                    if (typeof res !== 'undefined') {
                        setLoading(false);
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            props.navigate('/Users');
                        }
                    }

                });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }



    const handleChange = (selectedList, selectedItem) => {

        setHandleChangeValue(true)

        const selected = [];
        const selectedId = [];
        inputField.lstRole = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstRole.push(getallData);
        }



    }
    const handleChangeGroup = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstGroup = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstGroup.push(getallData);
        }


    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            props.navigate('/Users')
        }
        if (flag == "Close") {
            props.navigate('/Users')
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" },
            maxWidth :"250px"

        },


            control: base => ({
                ...base,
                minHeight: 15,
                maxHeight: 100,
                borderRadius: 6
            }),

            multiValue: base => ({
                ...base,
                fontSize: "0.75rem",
            }),
            valueContainer: base => ({
                ...base,
                padding: '0px 2px',
                fontSize: "0.75rem",
            }),
            input: base => ({
                ...base,
                margin: 0,
                padding: 0,

            })


    }

    console.log("14/5", inputField)

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/Users">User List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Create User</li>
                </ol>
             
            </nav>


            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    Create User
                                </div>
                                <div className="col-md-5">
                                    {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">Submit</button>}
                                    <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "40px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >Close   </button>
                                   
                                
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>

                            {loading ? <>
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            </> : ""}

                            <div className="container">
                                <div className="row">
                                    <label className="col-md-2 text-left l_width">{t('FirstName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.FirstName} autoComplete="no" name="FirstName" type="text" className="col-md-3 textbox-padding form-control m_width" />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 text-left l_width">{t('LastName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.LastName} autoComplete='no' name="LastName" type="text" className=" col-md-3 textbox-padding form-control m_width" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Email')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={emailinputhandler} value={inputField.Email} autoComplete='no' name="Email" type="email" className="col-md-3 mt-2 textbox-padding form-control m_width" />
                                    <div className="col-md-2 mt-2 text-left">{
                                        invalidemail === true ? <span className='errormessage'>{invalidemail === true ? "Invalid Email" : ""}</span>
                                        :
                                        <span className='errormessage'>{useravailable === "User available" ? "" : useravailable}</span>
                                        }
                                        
                                    </div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Password')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Password} autoComplete='no' name="Password" type="password" className="col-md-3 mt-2 textbox-padding form-control m_width" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('UserName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Username} autoComplete='no' name="Username" type="text" className="col-md-3 mt-2 textbox-padding form-control m_width" disabled />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('ConfirmPassword')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.ConfirmPassword} autoComplete='no' name="ConfirmPassword" type="password" className="col-md-3 mt-2 textbox-padding form-control m_width" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('DateFormat')}<span className={classes.mandatory}>*</span></label>
                                    <select name="dateformat" id="dateformat" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.dateformat} className="col-md-3 textbox-padding mt-2 form-control m_width">
                                        <option >Select</option>
                                        <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                                        <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Locale')}<span className={classes.mandatory}>*</span></label>
                                    <select name="Locale" id="Locale" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Locale} className="col-md-3 textbox-padding mt-2 form-control m_width">
                                        <option >Select</option>
                                        <option value="Indian">Indian</option>
                                        <option value="International">International</option>
                                    </select>
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Language')}<span className={classes.mandatory}>*</span></label>
                                    <select name="Languages" id="Languages" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Languages} className="col-md-3 textbox-padding mt-2 form-control m_width">
                                        <option >Select</option>
                                        <option value="en">English</option>
                                        <option value="it">Italian</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                </div>

                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Roles')}<span className={classes.mandatory}>*</span></label>
                                    {<Multiselect
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Roles}
                                        displayValue="Name"

                                        style={regionStyles}
                                        className="col-md-3  textbox-padding mt-2 fontsizedropdown m_width"
                                    />

                                     }
                                    <div className="col-md-2"></div>
                                </div>

                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Groups')}<span className={classes.mandatory}>*</span></label>
                                    <Multiselect
                                        onSelect={handleChangeGroup}
                                        onRemove={handleChangeGroup}
                                        options={Groups}
                                        displayValue="Label"
                                        style={regionStyles}
                                        className="col-md-3 textbox-padding mt-2 fontsizedropdown m_width"


                                    />
                                </div>
                                <div className="row mt-2">                                    
                                    <label className="col-md-2 mt-2 text-left l_width">{t('UserList.UploadPicture')}<span className={classes.mandatory}>*</span></label>
                                    < Form.Control type="file" onChange={saveFileSelected} name="UploadPic" className="mt-2 col-md-2 mt-2 text-left m_width"/>                                 

                                    
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    </>)
}
export default CreateUserComponent