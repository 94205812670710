import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DistributorGroupMappingPolicylist from '../../../Components/EndurancePolicies/DistributorGroupMappingPolicy/DistributorGroupMappingPolicyList';

function DistributorGroupMappingPolicy(props){
    let navigate = useNavigate();
    const params = useParams();

     return (  
         <div> 
             <DistributorGroupMappingPolicylist {...props}  navigate={navigate} id={params.id} />
         </div>
     );  

}

export default DistributorGroupMappingPolicy