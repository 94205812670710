import axios from 'axios';
import $ from 'jquery';
const REBATE_API_BASE_URL = "https://localhost:7096/api/";

class Validation {
  NewShipAndDebit(inputField, shipList) {
    let errorMsg = ''
    let isValid = true;
    let productList = []
    let startDate = new Date(inputField.ValidityFrom)
    let endDate = new Date(inputField.ValidityTo)

   
    if (inputField.DistributorId == 0 || inputField.DistributorId == null) {
      errorMsg = 'Please select distributor in the header';
      isValid = false;
    }
    else if (inputField.RegionId == 0 || inputField.RegionId == null) {
      errorMsg = 'Please select Region in the header';
      isValid = false;
    }
    else if (endDate.getTime() < startDate.getTime()) {
      errorMsg = 'Header Validity from is greater than the validity to';
      isValid = false;
    }
    else if (inputField.SettlementMethodId == 0 || inputField.SettlementMethodId == null) {
      errorMsg = 'Please select Settlement Method in the header';
      isValid = false;
    }
    else if (inputField.CurrencyId == 0 || inputField.CurrencyId == null) {
      errorMsg = 'Please select Currency in the header';
      isValid = false;
    }
    else if (inputField.Justification == 0 || inputField.Justification == null) {
      errorMsg = 'Please provide Justification in the header';
      isValid = false;
    }

    else if (shipList.length == 0) {
      errorMsg = 'Please enter item details ';
      isValid = false;
    }
    else {
      shipList.forEach(item => {
        let idx = parseInt(shipList.indexOf(item)) + 1
        if (item.ProductId === '') {
          errorMsg = 'Please select product in Row ' + idx;
          isValid = false;
        }
        else if (item.rebateTypeId === '' || item.rebateTypeId === 0) {
          errorMsg = 'Please select Rebate Type in row ' + idx
          isValid = false;
        }

     
        else if (item.ContractPrice === '') {
          errorMsg = 'Please enter contract value in row ' + idx
          isValid = false;
        }
        
        else if (item.ContractQtyCondId === '') {
          errorMsg = 'Please select Threshold  cond in row ' + idx
          isValid = false;
        }
        
        else if (item.rebateTypeId !== 0 && item.selectedRebateType.toLowerCase() == 'volume') {
          if (item.ContractQty === '' || item.ContractQty === '0') {
            errorMsg = 'Please enter Threshold qty in row ' + idx
            isValid = false;
          }
        }
        else if (item.rebateTypeId !== 0 && item.selectedRebateType.toLowerCase() == 'revenue') {
          if (item.ContractAmount === '' || item.ContractAmount === '0') {
            errorMsg = 'Please enter Threshold Amount  in row ' + idx
            isValid = false;
          }
        }
      })
    }
    return errorMsg
  }
  NewSpecialPrice(inputField, shipList) {
    let errorMsg = ''
    let isValid = true;
    let productList = []
    let startDate = new Date(inputField.ValidityFrom)
    let endDate = new Date(inputField.ValidityTo)

    if (inputField.AgreementDesc == '' || inputField.AgreementDesc == null) {
      errorMsg = 'Please select agreement name in the header';
      

      isValid = false;
    }
    else if (inputField.DistributorId == 0 || inputField.DistributorId == null) {
      errorMsg = 'Please select distributor in the header';
      isValid = false;
    }
    else if (inputField.RegionId == 0 || inputField.RegionId == null) {
      errorMsg = 'Please select Region in the header';
      isValid = false;
    }
    else if (endDate.getTime() < startDate.getTime()) {
      errorMsg = 'Header Validity from is greater than the validity to';
      isValid = false;
    }
    else if (inputField.SettlementMethodId == 0 || inputField.SettlementMethodId == null) {
      errorMsg = 'Please select Settlement Method in the header';
      isValid = false;
    }
    else if (inputField.CurrencyId == 0 || inputField.CurrencyId == null) {
      errorMsg = 'Please select Currency in the header';
      isValid = false;
    }
    else if (shipList.length == 0) {
      errorMsg = 'Please enter item details ';
      isValid = false;
    }
    else {
      shipList.forEach(item => {
        let idx = parseInt(shipList.indexOf(item)) + 1
        if (item.ProductId === '') {
          errorMsg = 'Please select product in Row ' + idx;
          isValid = false;
        }
        else if (item.Listprice === '') {
          errorMsg = 'Please enter list price in row ' + idx
          isValid = false;
        }
        else if (item.ContractTypeId === '') {
          errorMsg = 'Please select contract type in row ' + idx
          isValid = false;
        }
        else if (item.ContractPrice === '') {
          errorMsg = 'Please enter contract price in row ' + idx
          isValid = false;
        }
        else if (item.ContractListPrice === '') {
          errorMsg = 'Please enter contract list price in row ' + idx
          isValid = false;
        }
        else if (item.ContractQtyCondId === '') {
          errorMsg = 'Please select Threshold cond in row ' + idx
          isValid = false;
        }
        else if (item.rebateTypeId === '' || item.rebateTypeId === 0) {
          errorMsg = 'Please select Rebate Type in row ' + idx
          isValid = false;
        }
        else if (item.rebateTypeId !== '' || item.rebateTypeId !== 0 && item.selectedRebateType.toLowerCase() == 'volume') {
          if (item.ContractQty === '' || item.ContractQty === '0') {
            errorMsg = 'Please enter Threshold qty in row ' + idx
            isValid = false;
          }
        }
        else if (item.rebateTypeId !== '' || item.rebateTypeId !== 0 && item.selectedRebateType.toLowerCase() == 'revenue') {
          if (item.ContractAmount === '' || item.ContractAmount === '0') {
            errorMsg = 'Please enter Threshold Amount  in row ' + idx
            isValid = false;
          }
        }

      })
    }
    return errorMsg
  }


  NewClaim(inputField, shipList) {

    let isValid = true;
    let errorMsg = ''
    
    let startDate = new Date(inputField.StartDate)
    let endDate = new Date(inputField.StartDate)
   

    if (inputField.AgreementType == "" || inputField.AgreementType == null) {
      errorMsg = ('Please select Agreement Type in the header')
      isValid = false;
    }
    else if (inputField.AgreementId_Id == "" || inputField.AgreementId_Id == null) {
      errorMsg = ('Please select Agreement ID in the header')
      isValid = false;
    }
    else if (inputField.Justification == "" || inputField.Justification == null) {
      errorMsg = ('Please provide Justification  in the header')
      isValid = false;
    }
    else if (inputField.Justification.length > 1000) {
      errorMsg = ('Justification cannot be more than 1000 characters')
      isValid = false;
    }

   

    else if (shipList.length == 0) {
      errorMsg = ('Please enter item details')
      isValid = false;
    }
    else {
      shipList.forEach(item => {
     
        const transactionDate = new Date(item.TransactionDate);
     
        let idx = parseInt(shipList.indexOf(item)) + 1
        if (item.ProductId == '' || item.ProductId == 0) {
          errorMsg = ('Please select product in Row ' + idx)
          isValid = false;
        }

        else if (item.InvoiceNumber == "" || item.InvoiceNumber == null) {
          errorMsg = ('Please enter Invoice number in Row ' + idx)
          isValid = false;
        }

        else if (item.FilePath == '' || item.FilePath == null) {
          errorMsg = ('Please select attachment in Row ' + idx)
          isValid = false;
        }
        else if (item.TransactionDate == "" || item.TransactionDate == null) {
          errorMsg = ('Please select transaction date in row ' + idx)
          isValid = false;
        }
        else if (item.TransactionDate > endDate || item.TransactionDate < startDate) {
          errorMsg = ('Please select date within validity date in row ' + idx)
          isValid = false;
        }
        
        if (inputField.AgreementType != "ClaimAgreement") {
          if (item.RebateType.toLowerCase() == 'volume') {
            if (item.ClaimQty == '' || item.ClaimQty == 0 || item.ClaimQty == null) {
              errorMsg = 'Please enter Claim qty in row ' + idx
              isValid = false;
            }
          }
          else if (item.RebateType.toLowerCase() == 'revenue') {
            if (item.ClaimRevenue == '' || item.ClaimRevenue == 0 || item.ClaimRevenue == null) {
              errorMsg = 'Please enter Claim Revenue  in row ' + idx
              isValid = false;
            }
          }
        }
      })
    }
    return errorMsg
  }
  NewException(exceptionPolicyData, ExceptionValueList, subdivideList, fyThresholdList) {

    let errorMsg = ''
    let isValid = true;
    let productList = []

    if (exceptionPolicyData.ExceptionTypeId == 0 || exceptionPolicyData.ExceptionTypeId == null) {
      errorMsg = 'Please select ExceptionType name in the header';
      isValid = false;
    }

    ExceptionValueList.forEach(i => {


      if (i.ValueListString.length == 0 || i.ValueListString == null) {
        errorMsg = 'Please select Value';
        isValid = false;
      }
      if (i.Operator == '' || i.Operator == null) {
        errorMsg = 'Please select Operator';
        isValid = false;
      }
      if (i.SelectedItemSubCategory == '' || i.SelectedItemSubCategory == null) {
        errorMsg = 'Please select Sub Category';
        isValid = false;
      }
      if (i.Category == '' || i.Category == null) {
        errorMsg = 'Please select Category';
        isValid = false;
      }

    });



    if (exceptionPolicyData.ExceptionTypeName == "SubDivideThreshold") {

      if (subdivideList.Value == '' || subdivideList.Value == null) {
        errorMsg = 'Please select Value';
        isValid = false;
      }
      if (subdivideList.Operator == '' || subdivideList.Operator == null) {
        errorMsg = 'Please select Operator';
        isValid = false;
      }
      if (subdivideList.Period == '' || subdivideList.Period == null) {
        errorMsg = 'Please select Period';
        isValid = false;
      }
      if (exceptionPolicyData.ExceptionTypeName == "SubDivideThreshold" && (exceptionPolicyData.SubDivideTargetType == 0 || exceptionPolicyData.SubDivideTargetType == null)) {
        errorMsg = 'Please select SubDivide Target Type name in the header';
        isValid = false;
      }

    }


    if (exceptionPolicyData.ExceptionTypeName == "FYThreshold") {

      if (fyThresholdList.PayoutValue == '' || fyThresholdList.PayoutValue == null) {
        errorMsg = 'Please enter Payout Value';
        isValid = false;
      }
      if (fyThresholdList.ThresholdValue == '' || fyThresholdList.ThresholdValue == null) {
        errorMsg = 'Please enter Threshold Value';
        isValid = false;
      }
      if (fyThresholdList.Operator == '' || fyThresholdList.Operator == null) {
        errorMsg = 'Please select the Operator';
        isValid = false;
      }
      if (exceptionPolicyData.ExceptionTypeName == "FYThreshold" && (exceptionPolicyData.RebateFor == '' || exceptionPolicyData.RebateFor == null)) {
        errorMsg = 'Please select Rebate ₹/%/LS in the header';
        isValid = false;
      }

    }


    return errorMsg
  }
}
export default new Validation()