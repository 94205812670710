import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

function CustomerGroupsListComponent(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Customer Group Label", key: "label" },
        { label: "Customers", key: "Customers" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Is Active", key: "IsActive" },
    ]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [t, i18n] = useTranslation('translation');

    const [sidebarwidth, setsidebarwidth] = useState('63vw');
    const [customerDataSelectedLst, setCustomerDataSelectedLst] = useState([]);

    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    const [filterValueDict, setFilterValueDict] = useState({
        name: '',
        label: '',
        LastModifiedDate: null,
        NoOfDays: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        LastModifiedUser: '',
        ISActive: ''
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [props.id])
    }
    const clickCloseCustomer = () => {

        setShowSelectedCustomerpopup(false);
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }


    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        Service.getdataWithPagingByGroupSearch("CustomerGroups/GetALL_Policy", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            console.log(res, 'api-resp');
            if (res !== undefined && res.data !== null && res.data !== undefined) {
                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }

                let filteredCsvList = operatedList.map(item => {
                    let newItem = {};
                    Object.keys(item).forEach(key => {
                        if (!hideColumn.includes(key)) {
                            if (key === 'LastModifiedDate') {
                                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
                            } else if (key === 'IsActiveValue') {
                                // Transform the IsActiveValue field
                                newItem[key] = item[key] === 'active' ? 'Active' : (item[key] === 'in-active' ? 'In-Active' : item[key]);
                            } else {
                                newItem[key] = item[key];
                            }
                        }
                    });
                    return newItem;
                });

                let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                console.log(filteredCsvList, "filtered");

                setCsvList(filteredCsvList);
                setHeaders(filteredHeaders);

                setTimeout(() => {
                    toast.success('Exported Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    }

    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]




    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {
                //debugger;
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList.filter(item => item.Name === 'Customer Groups'))
                        console.log(res.data.DataList,"19-2",res.data.DataList.filter(item => item.Name === 'Customer Groups'))
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            //	filterHandler('Reset');
            setSearchValue(String(event.target.value).toLowerCase())
            getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getAllPolicydataSearch("CustomerGroups", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = data.length;

                                setdata(data => [...data, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                                LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };


    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, status) => {
        const isChecked = e.target.checked;
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');

        let count = totalselectedcount;

        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - Active status
            setCheckedValueid(name); // check box value - RA-0000
            setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
            count += 1

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - Active status
            setCheckedValueid([]); // check box value - RA-0000
            setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
            count -= 1

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }

        if (checkedBoxes.length == 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
        }
    };

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/policies/customergroups/edit/" + id);
        }
    }



    const DeleteHandler = (id, status) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        var chk = false
        if (status === false) {
            chk = true
        }
        if (id > 0 && chk === true) {
            Service.deleteDataByPolicyID("CustomerGroups/Delete", id, loggedInUser).then(res => {
                if (res.status === 200) {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        alert('Deleted Successfully')
                        getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, "Ascending", "Id", [], searchValue);
                        getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                    }
                }
            })
        }
        else {
            setShowErrorModal(true)
            setErrorMessage("Active Policy cannot be deleted")
        }
    }

    const getCustomerGroupsListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        console.log('26/12', method, pageSize, pageNumber, sortType, SortProperty, filterValue, search)
        Service.getAllPolicydataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        // debugger;
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }

    console.log('9/1', listViewData)
    console.log('9/1', filteredArray)


    const TogglePolicyActiveStatus = (id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.TogglePolicyActiveStatus('CustomerGroups/ToggleActiveStatus', toggleId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                    }
                }

            });
            setToggleStatusModal(false)
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('CustomerGroups/GetFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            //setDropdownData(res.data)
                            addnewfield(res.data, key)
                            console.log(res.data, "Multi-Data");
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'NoOfDays') {
            setfiltertext("No Of Days")
            var testDiv = document.getElementById("NoOfDays");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'LastModifiedUser') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'name') {
            setfiltertext(t('Policies.Name'))
            var testDiv = document.getElementById("name");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'label') {
            setfiltertext(t('Policies.CustomerGroupLabel'))
            var testDiv = document.getElementById("label");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'ISActive') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("ISActive");
            setdivleft(((testDiv.offsetLeft + 30) - 150) + "px");
            addnewfield(activeOption, name)
        }
    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
            navigate("/policies/customergroups/edit/" + id);
        }
    }
    const SortColumn = (colName) => {

        // getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, sortType, colName, []);
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        // let pagesize = 20;
        let pagenumber = 1;
        // paging.pageSize = pagesize;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {
        console.log('9/7', e.target.value)
        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

        if (key != 'status') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            // checkboxes.forEach(function (checkbox) {
            //   checkbox.checked = true;
            // });
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            console.log('20/3-dropdown', dropdownData)
            dropdownData.map((each) => {
                console.log('20/3-eacch', each)
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            // checkboxes.forEach(function (checkbox) {
            //     checkbox.checked = false;
            // });
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

    console.log("30/3-td", dropdownData)

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            console.log("20/3", dropdownData)
            console.log("Add New Column", currentdata)
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue.filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {
        // debugger;
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            //   console.log("Testing Filtervalue", filterValue);
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    console.log("26-12", multiFilterValue)
    const filterHandler = (flag) => {
        //debugger;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

            let tempDropdownData = dropdownData;
            tempDropdownData[0]["ischecked"] = true;
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            // getCustomerGroupsListViewData("CustomerGroups", paging.pageSize, paging.pageNumber, "Ascending", "Id", list , )
            setFilterCol('')
            setFilterValue([])
            // setDropdownChange("")
            setFilterSearch("")
            setDropdownData([])
            setFilterDropdownData([])
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const clickSelectedShowCustomerPopup = (e, lst) => {
        setCustomerDataSelectedLst([])
        getGroupCustomerData("CustomerGroups/GetCustomerBasedOnId", lst);
        // var lst=filteredArray.filter((e) => e.Id === id);
        // setCustomerDataSelectedLst(lst)
        setShowSelectedCustomerpopup(true);
    }


    const getGroupCustomerData = (method, Id) => {

        Service.getdatabyId(method, Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    setCustomerDataSelectedLst(res.data.DataList)
                    console.log(res.data)
                }
            }
        });
    }

    const CreateNew = () => {
        navigate("/policies/customergroups/add")
    }

    // -----------------------------------------------------------------

    const columnList = [
        { "label": "Customer Group Label", "key": "label", "value": true },
        { "label": "Customers", "key": "Customers", "value": true },
        { "label": "LastModifiedDate", "key": "LastModifiedDate", "value": true },
        { "label": "LastModifiedBy", "key": "LastModifiedUser", "value": true },
        { "label": "ExceptionPolicy.IsActive", "key": "IsActive", "value": true },
    ]

    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);

    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 136)
        setdivleft((divtop1.offsetLeft) + 115);
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'CustomerGroupsList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }

    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'CustomerGroupsList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'CustomerGroupsList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        console.log("columnList", res.data)
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }
                            console.log("columnlist1", data)
                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }

    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'CustomerGroupsList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                            console.log("columnlist", res.data)
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                // alert('Deleted Successfully')
            }
        })
    }

    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm A";

    // ------------------------------------------------------------


    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/genericPolicies">{t('Menu.GenericPolicy')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Policies.CustomerGroupsList')}</li>
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                {<CSVLink data={csvList} headers={headers} ref={csvInstance} filename="Customer_Groups.csv"   > </CSVLink>}
            </div>
            <ToastContainer autoClose={1000} />
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1">
                                    <div className="col-md-6">
                                        <div className='d-flex align-items-center mb-1'>
                                            <input type="text" className="form-control form-control-user col-md-10" style={{marginLeft:"-18px"}} id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Customer Group Label & Last Modified By')} onChange={(event) => searchHandler(event)} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2  pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='row'>
                                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    {totalselectedcount === 0 && (
                                                        <a className="btn btn-update btn-sm" type="submit" style={{ width: "75px", backgroundColor: "buttonface" }} onClick={() => CreateNew()}>{t('CreateNew')}</a>
                                                    )}
                                                    {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                                                    )}
                                                    {totalselectedcount === 1 && (
                                                        <>
                                                            <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</a>

                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='scrollbar-class mb-1'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                            <th className="tableheader" style={{ width: '30px' }}>
                                                <input
                                                    type="checkbox"
                                                    onChange={checkAll}
                                                    checked={checkAllChecked}
                                                    id="option-all"
                                                />
                                            </th>                                            {/* <th>No</th> */}
                                            {/* <th id="name" style={{ backgroundColor: (filterValueDict.name ? '#6cae55' : ''), width: '330px' }}>
                                                Name <span className='fa fa-sort' onClick={() => SortColumn('Name')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('name')}></span>
                                            </th> */}
                                            <th id="label" style={{ backgroundColor: (filterValueDict.label ? '#6cae55' : ''), width: '330px' }}
                                                hidden={hideColumn.includes('label') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.CustomerGroupLabel')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('label')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('label')}></span>
                                                </div>
                                            </th>
                                            <th id="NoOfDays" style={{ backgroundColor: (filterValueDict.NoOfDays ? '#6cae55' : ''), minWidth: '200px' }}
                                                hidden={hideColumn.includes('Customers') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.Customers')}</span>
                                                </div>
                                            </th>
                                            <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: '250px' }}
                                                hidden={hideColumn.includes('LastModifiedDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedDate')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                </div>
                                            </th>
                                            <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : ''), width: '300px' }} hidden={hideColumn.includes('LastModifiedUser') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                </div>
                                            </th>
                                            <th id="ISActive" style={{ backgroundColor: (multiFilterValue.ISActive ? '#6cae55' : ''), width: '200px' }}
                                                hidden={hideColumn.includes('ISActive') ? true : false} >
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('IsActive')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ISActive')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ISActive')}></span>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr><td></td>
                                                        <td colSpan="9" style={{ textAlign: 'center' }}>
                                                            {t('NoDataAvailable')}
                                                        </td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>
                                                            <td ><input id={listVal.Id} type="checkbox" value={`${listVal.Name} , ${listVal.ISActive}`} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive)} name="chkBox" /></td>
                                                            {/* <td>{index + 1}</td> */}
                                                            {/* <td>{listVal.Name}</td> */}
                                                            <td hidden={hideColumn.includes('label') ? true : false}>{listVal.label}</td>
                                                            <td hidden={hideColumn.includes('Customers') ? true : false}>
                                                                <a href='javascript:void(0)' onClick={(event) => clickSelectedShowCustomerPopup(event, listVal.Id)}>
                                                                    <label> Customers Added</label>
                                                                </a>
                                                            </td>
                                                            <td hidden={hideColumn.includes('LastModifiedDate') ? true : false}>{`${moment(listVal.LastModifiedDate).format(dateFormat)} ${moment(listVal.LastModifiedDate).format(timeFormat)}`}</td>
                                                            <td hidden={hideColumn.includes('LastModifiedUser') ? true : false}>{listVal.LastModifiedUser}</td>
                                                            <td hidden={hideColumn.includes('ISActive') ? true : false}>
                                                                <div className="btn-group">
                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        {Service.editPerms(userPerms) == false ? (listVal.ISActive === true ? "Active" : "In-Active") : listVal.ISActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-A")}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-D")}>In-Active</a>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                        }

                                    </tbody>
                                </table>
                                </div>
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                            </div>
                                    }



                                    <div style={{flex: "0 0 64%",maxWidth:"64%"}}></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                        {/* <div className='row'>
                                            <div className='col-md-1'><span className='fa fa-filter'></span></div>
                                            <div className='col-md-10'>Clear Filter</div>
                                        </div> */}
                                        {filterCol === 'name' || filterCol === 'label' || filterCol === 'NoOfDays' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>

                                        }

                                        {filterCol === 'LastModifiedDate' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'LastModifiedUser' || filterCol === 'ISActive' ?
                                            // <>
                                            //     <div className="row">
                                            //         <span className='col-md-1 fa fa-filter' />
                                            //         <Multiselect
                                            //             onSelect={(Event) => OndropdownChange(Event)}
                                            //             onRemove={(Event) => OndropdownChange(Event)}
                                            //             options={dropdownData}
                                            //             displayValue="Name"
                                            //             // name="dropdown"
                                            //             showCheckbox={true}
                                            //             selectedValues={multiFilterValue[filterCol]}
                                            //             className='col-md-9 textbox-padding fontsizedropdown'
                                            //             style={customMultiStyles}
                                            //         />
                                            //     </div>
                                            // </>
                                            // :
                                            // <></>
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} s value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr> */}
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {/* {filterCol === 'status' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={activeOption}
                                                        displayValue="Name"
                                                        // name="dropdown"
                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        } */}
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div >


            {showSelectedCustomerpopup === true ?
                <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                    <div className="container" style={{ padding: "0" }}>
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">
                                        <div className="row">
                                            <div className="col-md-10 text-left">
                                                {t('Rebates.CustomerListing')}
                                            </div>
                                            <div className="col-md-2">

                                                <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseCustomer} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">

                                    </div>
                                    <div className='scrollbar-class'>

                                        <div className="container">
                                            <div>

                                                {/* <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder="Search Distributor" />
                        */}
                                            </div>
                                            <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                    <tr>
                                                        <th>

                                                        </th>
                                                        <th>{t('Rebates.CustomerCode')}</th>
                                                        <th>{t('Rebates.CustomerName')}</th>
                                                        <th>{t('Rebates.Station')}</th>
                                                        <th>{t('Rebates.State')}</th>
                                                        <th>{t('Rebates.Zone')}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        customerDataSelectedLst.map((cslist, index) => (
                                                            //  <>   {index% 3 == 0?"<tr>":""}
                                                            <tr>

                                                                <td style={{ width: "30px" }}>
                                                                    <input type="checkbox" disabled={true}
                                                                        checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />
                                                                </td>

                                                                <td style={{ width: "90px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Name}>{cslist.Name}</div>


                                                                </td>
                                                                <td style={{ width: "260px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.label}>{cslist.label}</div>


                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Station}>{cslist.Station}</div>

                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.State}>{cslist.State}</div>

                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Zone}>{cslist.Zone}</div>

                                                                </td>



                                                            </tr>


                                                        ))


                                                    }

                                                </tbody> </table>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>

                        </div>
                    </div>
                </div>
                :
                ""
            }

            {showAddColumnPopup === true ?
                <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                    <div className="container">
                        <div className="row">
                            <Select
                                className='col-md-10 textbox-padding fontsizedropdown'
                                options={colVisibilityOption}
                                placeholder="Select"
                                value={colvisiOptionVal}
                                onChange={OnChangeColVisibilityList}
                                styles={customStyles}
                            />
                            <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                            </div>
                        </div>
                        <div className="row">
                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                {
                                    addRemoveCol.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                            /> {t(item.label)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
            }

            {showCreateColumnListPopup === true ?
                <div className='pop-up' style={{ left: divleft, top: divtop, width: "190px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                            </div>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                            </div>
                        </div>
                        <div className="row">
                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                {
                                    createColumnListOption.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                            /> {t(item.label)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                            </div>
                        </div>
                    </div>


                </div>
                : <></>
            }

            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div >

    )

}
export default CustomerGroupsListComponent