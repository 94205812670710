import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

function TargetSettingPolicylist(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Policy ID", key: "TargetSettingPolicyId" },
        { label: "Policy Name", key: "TargetSettingPolicyName" },
        { label: "Rebate Type", key: "RebateTypeName" },
        { label: "Valid From", key: "StartDate" },
        { label: "Valid To", key: "EndDate" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Status", key: "StatusLabel" },
        { label: "Is Active", key: "IsActiveValue" },
        // { label: "Created By", key: "CreatedUser" },
        // { label: "Created Date", key: "CreatedDate" },
    ]);
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [showCustomerPopup, setShowCustomerpopup] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [showDeleteModal, toggleModal] = useState(false);
    const[showLockedModal,setLockedModal]=useState(false);
    const [lockedDocumentsId,setLockedDocumentsId] = useState([]);
    const [matchingObject,SetMatchingObjectsLength] = useState(false)

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterValueDict, setFilterValueDict] = useState({
        TargetSettingPolicyId: '',
        TargetSettingPolicyName: '',
        LastModifiedDate: null,
        StartDate: '',
        EndDate: ''
        // CreatedDate: null,
        // ExceptionTypeLabel: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        LastModifiedUser: '',
        StatusLabel: '',
        IsActiveValue: '',
        RebateTypeName: ''
        // exception_type: '',
        // CreatedUser: '',

    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getColumnVisibilityList()
            getColVisibilityListData()

        }, [props.id])
    }

    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }
        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const getExceptionPolicyListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        setLockedDocumentsId([]);
        Service.getAllPolicydataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            console.log(rowData, "8/2");
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            setLockedDocumentsId(rowData.filter(each => each.Locked === true).map(each => each.Id));
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }

    const ExporttoCSV = () => {

        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        Service.getDatawithMultiFilterSearch("TargetSettingPolicy/GetALL_Policy", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            console.log(res, 'api-resp');
            if (res !== undefined && res.data !== null && res.data !== undefined) {
                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }

                let filteredCsvList = operatedList.map(item => {
                    let newItem = {};
                    Object.keys(item).forEach(key => {
                        if (!hideColumn.includes(key)) {
                            if (key === 'LastModifiedDate') {
                                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
                            } else if (key === 'StartDate' || key === 'EndDate') {
                                newItem[key] = moment(item[key]).format(dateFormat);
                            } else {
                                newItem[key] = item[key];
                            }
                        }
                        if (key === 'StatusLabel') {
                            newItem['IsActiveValue'] = item[key] !== 'Approved' ? '' : newItem['IsActive'] ? "Active" : "In-Active";
                        }
                    });
                    return newItem;
                });

                let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                console.log(filteredCsvList, "filtered");

                setCsvList(filteredCsvList);
                setHeaders(filteredHeaders);

                setTimeout(() => {
                    toast.success('Exported Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    }

    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]



    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getAllPolicydataSearch("TargetSettingPolicy", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = data.length;
                                setdata(data => [...data, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                                LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };


    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const handleChange = (e, id, name, status) => {
        const isChecked = e.target.checked;
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - Active status
            setCheckedValueid(name); // check box value - RA-0000
            setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - Active status
            setCheckedValueid([]); // check box value - RA-0000
            setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
            count -= 1;

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }
        if (checkedBoxes.length == 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
        }
    };




    const OpenHandler = (id) => {

        if (id == null || id == 0) {
            alert('Please select a Policy to view')
        }
        else {
            navigate("/TargetSettingPolicylist/edit/" + id + "/fromPolicy");
        }
    }

 

    const checkDelete = () => {
        debugger
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {

            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {
             if(lockedDocumentsId.includes(Number(checkedValue))){
                    showDeleteDialog('locked');
                }else{
                    setMultiValueIds(checkedValue);
                    showDeleteDialog('open');
                }
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.id)
            }

            //const matchingObjects = filteredArray.filter(obj => checkedMultiValueid.includes(obj.Id.toString()));
            const lockedString = lockedDocumentsId.map(id => id.toString());
            const checkedMultiValueidString = checkedMultiValueid.map(id => id.toString());
            const commonIds = checkedMultiValueidString.filter(id => lockedString.includes(id));
            const result1 = checkedMultiValueidString.filter(id => !commonIds.includes(id))
            const matchingObjects = filteredArray.filter(obj => result1.includes(obj.Id.toString()));
            if(matchingObjects.length === 0){
                SetMatchingObjectsLength(true)
                showDeleteDialog('locked');    
            }
            let hasApprovedInReviewOrDenied = false;

            matchingObjects.forEach(i => {
                if (i.StatusLabel?.includes('Approved') || i.StatusLabel?.includes('InReview') || i.StatusLabel?.includes('Denied')) {
                    hasApprovedInReviewOrDenied = true;
                } else {
                    var idAsString = i.Id.toString();
                    multiValueids.push(idAsString);
                    showDeleteDialog('open');
                }
            });

            if (hasApprovedInReviewOrDenied) {
                setErrorDeleteModal(true);
            }
        }
    }

    // delete modal handler
    const showDeleteDialog = (flag) => {
        if (flag === 'open') {
            toggleModal(true);
        }
        if (flag === 'close') {
            toggleModal(false);
        }
        if (flag === 'proceed') {
            // Handle deletion logic here
            handleDelete(multiValueids);

            // Update state after deletion is confirmed
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }

            // Clear the multi-value ids and reset the total selected count and visibility
            setMultiValueIds([]);
            setCheckedMultiValueid([]);
            settotalselectedcount(0);
            settotalselectedshow(false);
            setDeleteshowbutton(false);
            toggleModal(false);
        }

        if(flag === 'locked'){
            setLockedModal(true)
        }

        if(flag === 'closelocked'){
            setLockedModal(false)
            SetMatchingObjectsLength(false)
        }
    };

    const handleDelete = (id) => {
        debugger;
        let pagenumber = 1;
        Service.deleteDataByPolicyID('TargetSettingPolicy/Delete', id, loggedInUser).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    showDeleteDialog('close')
                    toast.error('Deleted Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });


                    getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, pagenumber, "Descending", "Id", filterLst(), null);
                    setMultiValueIds([])
                }
            }
        });
    }



    const TogglePolicyActiveStatus = (id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.TogglePolicyActiveStatus('TargetSettingPolicy/ToggleActiveStatus', toggleId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data.Code === 200) {
                            if (res.data.Status === "Success") {
                                getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, 1, "Ascending", "Id", filterLst(), null)
                            }
                            else {
                                setShowErrorModal(true)
                                setErrorMessage(res.data.Status)
                            }
                        }
                    }
                }

            });
            setToggleStatusModal(false)
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('TargetSettingPolicy/GetTargetSettingFilterValues', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'TargetSettingPolicyId') {
            setfiltertext(t('Policy ID'))
            var testDiv = document.getElementById("TargetSettingPolicyId");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'TargetSettingPolicyName') {
            setfiltertext(t('Polcicy Name'))
            var testDiv = document.getElementById("TargetSettingPolicyName");
            setdivleft(testDiv.offsetLeft + 30 + "px");
        }
        if (name === 'RebateTypeName') {
            setfiltertext(t('Rebate Type'))
            var testDiv = document.getElementById("RebateTypeName");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'StartDate') {
            setfiltertext(t('Valid From'))
            var testDiv = document.getElementById("StartDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'EndDate') {
            setfiltertext(t('Valid To'))
            var testDiv = document.getElementById("EndDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedUser') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'StatusLabel') {
            setfiltertext(t('Rebates.Status'))
            var testDiv = document.getElementById("StatusLabel");
            setdivleft(((testDiv.offsetLeft + 20) - 150) + "px");

            DropdownHandler(name)
        }
        if (name === 'IsActiveValue') {
            setfiltertext(t('ExceptionPolicy.IsActive'))
            var testDiv = document.getElementById("IsActiveValue");
            setdivleft(((testDiv.offsetLeft + 30) - 150) + "px");

            addnewfield(activeOption, name)
        }
        // if (name === 'CreatedDate') {
        //     setfiltertext(t('CreatedDate'))
        //     var testDiv = document.getElementById("CreatedDate");
        //     setdivleft(testDiv.offsetLeft + 20 + "px");
        // }
        // if (name === 'CreatedUser') {
        //     setfiltertext(t('CreatedBy'))
        //     var testDiv = document.getElementById("CreatedUser");
        //     setdivleft(testDiv.offsetLeft + 20 + "px");
        //     DropdownHandler(name)
        // }

    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
            navigate("/TargetSettingPolicylist/edit/" + id + "/fromPolicy");
        }
    }
    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));

    }

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];
        console.log(selectedKeys, "1020")
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
        if (key != 'StatusLabel') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                if (filterCol == 'StatusLabel') {
                    let filteredData = currentdata.filter((x) => x.Name !== 'Overridden' && x.Name !== 'Rejected' && x.Name !== 'Pending Reconcilation' && x.Name !== 'Expired')

                    console.log(filteredData, "xy2")
                    let result = addSeletAll(filteredData, alreadySelected);
                    setDropdownData(result)
                    setFilterDropdownData(result)
                }
                else {
                    let result = addSeletAll(currentdata, alreadySelected);
                    console.log(result, "xy3")
                    setDropdownData(result)
                    setFilterDropdownData(result)
                }
            }
            else {
                let filteredData = currentdata.filter((x) => x.Name !== 'Overridden' && x.Name !== 'Rejected' && x.Name !== 'Pending Reconcilation' && x.Name !== 'Expired')

                let result = addSeletAll(filteredData, alreadySelected);
                console.log(result, "xy4")
                setDropdownData(result)
                setFilterDropdownData(result)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }



    const OndropdownChange = (e, id, name, filterCol) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue[filterCol].filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            }

            let tempDropdownData = dropdownData;
            tempDropdownData[0]["ischecked"] = true;
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue([])
            setFilterSearch("")
            setDropdownData([])
            setFilterDropdownData([])
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };


    const CreateNew = () => {
        navigate("/TargetSettingPolicylist/new/0/fromPolicy")
    }

    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Policy ID", "key": "TargetSettingPolicyId", "value": true },
        { "label": "Policy Name", "key": "TargetSettingPolicyName", "value": true },
        { "label": "Rebate Type", "key": "RebateTypeName", "value": true },
        { "label": "Valid From", "key": "StartDate", "value": true },
        { "label": "Valid To ", "key": "EndDate", "value": true },
        { "label": "LastModifiedDate", "key": "LastModifiedDate", "value": true },
        { "label": "LastModifiedBy", "key": "LastModifiedUser", "value": true },
        { "label": "Rebates.Status", "key": "StatusLabel", "value": true },
        { "label": "ExceptionPolicy.IsActive", "key": "IsActiveValue", "value": true }
        // { "label": "CreatedBy", "key": "CreatedUser", "value": true },
        // { "label": "ExceptionPolicy.CreatedDate", "key": "CreatedDate", "value": true },
    ]

    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'TargetSettingPolicyList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'TargetSettingPolicyList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'TargetSettingPolicyList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'TargetSettingPolicyList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });

            }
        })
    }

    const CopyHandler = (id) => {
        setLoading(true)
        let rowData = {}
        let pagesize = 20 * paging.pageNumber
        let pagenumber = 1
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        Service.PostWithIdAndUser('TargetSettingPolicy/Copy', id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    // copyID = res.data
                    paging.pageSize = 20
                    paging.pageNumber = pagenumber
                    setpaging(paging);
                    getExceptionPolicyListViewData("TargetSettingPolicy", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
                    navigate('/TargetSetting-Policy/List')
                    setLoading(false)
                    toast.success('Copied Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    checkedBoxes.forEach(box => box.checked = false);
                    settotalselectedcount(0)
                    settotalselectedshow(false)
                    setshowbutton(false)
                }
            }
            else
                setLoading(false);
        });
    }



    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";


    // ------------------------------------------------------------


    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    {/* <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Policies.Policy')}</Link></li> */}
                    <li className="breadcrumb-item active" aria-current="page">{t('TargetSetting.TargetSettingPolicyList')}</li>
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="TargetSettingPolicy.csv"  > </CSVLink>
            </div>
            <ToastContainer autoClose={1000} />
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='d-flex align-items-center mb-1'>
                                            <input type="text" className="form-control form-control-user col-md-11" id="firstName" name="firstName" autoComplete='off' placeholder={t('TargetSetting.SearchHeader')} onChange={(event) => searchHandler(event)} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2  pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='row'>
                                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    {totalselectedcount === 0 &&  !userPerms.some(permission => permission.ActionName === "View Only") && Service.exportPerms(userPerms) &&(
                                                        <a className="btn btn-update btn-sm" type="submit" style={{ width: "75px", backgroundColor: "buttonface" }} onClick={() => CreateNew()}>{t('CreateNew')}</a>
                                                    )}
                                                    {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                                                    )}
                                                    {totalselectedcount === 1 && (
                                                        <>
                                                            <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</a>
                                                            {/* {Service.editPerms(userPerms) !== false && (
                                                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>
                                                            )} */}
                                                        </>
                                                    )}
                                                    {totalselectedcount > 0 &&  !userPerms.some(permission => permission.ActionName === "View Only") && Service.exportPerms(userPerms) &&(
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px", backgroundColor: "buttonface" }} onClick={() => checkDelete()}>{t('Delete')}</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className='scrollbar-class mb-1'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                            <th style={{ width: '30px' }}>
                                                <input
                                                    type="checkbox"
                                                    onChange={checkAll}
                                                    checked={checkAllChecked}
                                                    id="option-all"
                                                />
                                            </th>
                                            <th id="TargetSettingPolicyId" style={{ backgroundColor: (filterValueDict.TargetSettingPolicyId ? '#6cae55' : ''), width: '150px' }} hidden={hideColumn.includes('TargetSettingPolicyId') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policy ID')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('TargetSettingPolicyId')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('TargetSettingPolicyId')}></span>
                                                </div>
                                            </th>
                                            <th id='TargetSettingPolicyName' style={{ backgroundColor: (filterValueDict.TargetSettingPolicyName ? '#6cae55' : ''), maxWidth: "210px" }} hidden={hideColumn.includes('TargetSettingPolicyName') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policy Name')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('TargetSettingPolicyName')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('TargetSettingPolicyName')}></span>
                                                </div>
                                            </th>
                                            <th id='RebateTypeName' style={{ backgroundColor: (multiFilterValue.RebateTypeName ? '#6cae55' : ''), width: "180px" }} hidden={hideColumn.includes('RebateTypeName') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.RebateType')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateTypeName')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateTypeName')}></span>
                                                </div>
                                            </th>
                                            {/* <th id='defn' style={{ backgroundColor: (multiFilterValue.defn ? '#6cae55' : ''), width: "250px" }} hidden={hideColumn.includes('defn') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.RebateDefinition')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_Definition')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('defn')}></span>
                                                </div>
                                            </th> */}
                                            {/* <th id='payout_cond' style={{ backgroundColor: (multiFilterValue.defn ? '#6cae55' : ''), width: "250px" }} hidden={hideColumn.includes('defn') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.PayoutCond')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_Payout')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('defn')}></span>
                                                </div>
                                            </th> */}
                                            <th id='StartDate' style={{ backgroundColor: (filterValueDict.StartDate ? '#6cae55' : ''), width: "150px" }} hidden={hideColumn.includes('StartDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span> {t('Rebates.ValidFrom')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('StartDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('StartDate')}></span>
                                                </div>
                                            </th>
                                            <th id='EndDate' style={{ backgroundColor: (filterValueDict.EndDate ? '#6cae55' : ''), width: "150px" }} hidden={hideColumn.includes('EndDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.ValidTo')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('EndDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('EndDate')}></span>
                                                </div>
                                            </th>
                                            <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: '150px' }} hidden={hideColumn.includes('LastModifiedDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedDate')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>

                                                </div>
                                            </th>
                                            <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : ''), width: '150px' }} hidden={hideColumn.includes('LastModifiedUser') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span> {t('LastModifiedBy')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                </div>
                                            </th>
                                            {/* <th id="CreatedUser" style={{ backgroundColor: (multiFilterValue.CreatedUser ? '#6cae55' : ''), width: '250px' }} hidden={hideColumn.includes('CreatedUser') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('CreatedBy')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUser')}></span>
                                                </div>
                                            </th>
                                            <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), width: '300px' }} hidden={hideColumn.includes('CreatedDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span> {t('ExceptionPolicy.CreatedDate')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                </div>
                                            </th> */}
                                            <th id="StatusLabel" style={{ backgroundColor: (multiFilterValue.StatusLabel ? '#6cae55' : ''), width: '150px' }} hidden={hideColumn.includes('StatusLabel') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.Status')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('StatusLabel')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('StatusLabel')}></span>
                                                </div>
                                            </th>
                                            <th id="IsActiveValue" style={{ backgroundColor: (multiFilterValue.IsActiveValue ? '#6cae55' : ''), width: '150px' }} hidden={hideColumn.includes('IsActiveValue') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ExceptionPolicy.IsActive')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActiveValue')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('IsActiveValue')}></span>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr><td></td>
                                                        <td colSpan="9" style={{ textAlign: 'center' }}>
                                                            {t('NoDataAvailable')}
                                                        </td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)} className='' >
                                                            <td><input id={listVal.Id} type="checkbox" value={`${listVal.TargetSettingPolicyIdPolicyId},${listVal.StatusLabel}`} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.StatusLabel)} name="chkBox" /></td>
                                                            <td hidden={hideColumn.includes('TargetSettingPolicyId') ? true : false}><div style={{maxWidth:"100px",minWidth:"100px", maxHeight: "20px"}}>{listVal.TargetSettingPolicyId}</div></td>
                                                            <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('TargetSettingPolicyName') ? true : false}><div  style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "20px",minWidth:"190px",maxWidth:"240px" }} data-toggle="tooltip" data-placement="right" title={listVal.TargetSettingPolicyName}>{listVal.TargetSettingPolicyName}</div></td>
                                                            <td hidden={hideColumn.includes('RebateTypeName') ? true : false}><div style={{maxWidth:"100px",minWidth:"100px", maxHeight: "20px"}}>{listVal.RebateTypeName}</div></td>
                                                            {/* <td hidden={hideColumn.includes('ExceptionTypeLabel') ? true : false}>{listVal.RebateDefn}</td> */}
                                                            {/* <td hidden={hideColumn.includes('PayoutCond')}> {listVal.RebatePayoutCond === "Targeted" ? "Tiered" : ""}</td> */}
                                                            <td hidden={hideColumn.includes('StartDate') ? true : false}><div style={{maxWidth:"100px",minWidth:"100px", maxHeight: "20px"}}>{moment(listVal.StartDate).format(SessionManage.extractDateFromTimestamp(listVal.StartDate))}</div></td>
                                                            <td hidden={hideColumn.includes('EndDate') ? true : false}><div style={{maxWidth:"100px",minWidth:"100px", maxHeight: "20px"}}>{moment(listVal.EndDate).format(SessionManage.extractDateFromTimestamp(listVal.EndDate))}</div></td>
                                                            <td hidden={hideColumn.includes('LastModifiedDate') ? true : false}><div style={{maxWidth:"150px",minWidth:"100px", maxHeight: "20px"}}>{`${moment(listVal.LastModifiedDate).format(dateFormat)} ${moment(listVal.LastModifiedDate).format(timeFormat)}`}</div></td>

                                                            <td hidden={hideColumn.includes('LastModifiedUser') ? true : false}><div style={{maxWidth:"100px",minWidth:"100px", maxHeight: "20px"}}>{listVal.LastModifiedUser}</div></td>

                                                            <td hidden={hideColumn.includes('StatusLabel') ? true : false}><div style={{maxWidth:"90px",minWidth:"80px", maxHeight: "20px"}}>{listVal.StatusLabel}</div></td>
                                                            <td align="left" hidden={hideColumn.includes('IsActiveValue') ? true : false}>
                                                              <div style={{maxWidth:"90px",minWidth:"70px", maxHeight: "20px"}}>
                                                                {listVal.StatusLabel === "Approved"
                                                                    ?
                                                                    <>
                                                                        {Service.editPerms(userPerms) == false ? (listVal.IsActive === true ? "Active" : "In-Active") : listVal.IsActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-A")}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-D")}>In-Active</a>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                                    
                                                                }
                                                              </div>
                                                            </td>

                                                        </tr>
                                                    )

                                        }

                                    </tbody>
                                </table>
                                </div>
                                {console.log("2/2", filteredArray)}
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>
                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                            </div>
                                    }

                                    <div style={{ flex: "0 0 64%", maxWidth: "64%" }}></div>
                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                        {filterCol === 'TargetSettingPolicyId' || filterCol === 'TargetSettingPolicyName' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>
                                        }

                                        {filterCol === 'LastModifiedDate' || filterCol === 'CreatedDate' || filterCol === 'StartDate' || filterCol === 'EndDate' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'LastModifiedUser' || filterCol === 'StatusLabel' || filterCol === 'CreatedUser' || filterCol === 'IsActiveValue' || filterCol === 'exception_type' || filterCol === 'RebateTypeName' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr> */}
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }


                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "55px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }


                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                </div>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    : <></>
                                }

                                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                            </div>

                        </div>
                    </div>

                </div >
            </div >

            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Cancel")}>
                        {('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                    Policy in New status only can be Deleted
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                        Cancel
                    </Button>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showLockedModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    {matchingObject === true ? <Modal.Body style={{ textAlign: 'center' }}>These records are locked!<br></br> You can't delete it.</Modal.Body> :
                <Modal.Body style={{ textAlign: 'center' }}>This record is locked!<br></br> You can't delete it.</Modal.Body>}
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('closelocked')}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div >
    )

}
export default TargetSettingPolicylist