import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'

import Service from '../../../Services/Service';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';

import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import SessionManage from "../../../SessionManagement/SessionManage";


function CreateRoleComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const [roleavailable, setRolesavailable] = useState('');
    const [selected, setselected] = useState([]);
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [Permissions, setPermissions] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);
    let loggedInUser = SessionManage.getuserid();
    useEffect(() => {
        getUserPermission(location.pathname);
        getData("Permission/GetAll");
        var id = SessionManage.getuserid();
        setId(id)
    }, [0])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
  
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setPermissions(res.data);
                        }
                    }
                }
            }

        });
    }
    const checkRolename = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== null && value != '') {
            Service.getdatabyname("Roles/CheckRoleName", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setRolesavailable(res.data);

                        }
                    }
                }


            });
        }

    }
    const [inputField, setInputField] = useState({
        id: 0,
        label: '',
        name: '',
        description: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser,
        lstPermission: []
    })

    const inputsHandler = (e) => {
        setHandleChangeValue(true)
       
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    const handleChange = (selectedList, selectedItem) => {
      
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstPermission = [];

        let selectedOption = (selectedList);
    
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,
               
            }
            inputField.lstPermission.push(getallData);

        }

      
    }


    const checkValidation = () => {
        var hasError = false;
       
        if (inputField.name == null || inputField.name == '') {
            setErrorMessage(`Please Enter ${t('RoleList.RoleName')}`)
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.label == '' || inputField.label == null) {
            setErrorMessage(`Please Enter ${t('RoleList.RoleLabel')}`)
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstPermission.length == 0) {
            setErrorMessage(`Please Enter ${t('RoleList.Permissions')}`)
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        return hasError;
    }

    const submitButton = (e) => {
        e.preventDefault();
        setLoading(true);
        let roles = inputField;
        var hasError = checkValidation()
        if (hasError == false) {
            if (roleavailable == 'Role available') {
                Service.createorupdate("Roles/CreateOrUpdate", roles).then(res => {
                    setLoading(false);
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            props.navigate('/Roles');
                        }
                    }

                });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            props.navigate("/roles")
        }
        if (flag == "Close") {
            props.navigate("/roles")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    return (<>



        <div class="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page"><Link to="/Roles">{t('RoleList.RoleList')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('RoleList.RoleUser')}</li>
                </ol>
            </nav>

            <div class="row mt-3" id="rebateProgramdiv">
                <div class="col-lg-12 pr-lg-2">
                    <div class="card text-center card_border">
                        <div class="card-header chart-grid__header">
                            <div class="row">
                                <div class="col-md-2">
                                    {t('RoleList.CreateRole')}
                                </div>
                            
                                <div class="col-md-10">
                                    {Service.editPerms(userPerms) == false ? "" : <button class="btn  btn-update btn-sm ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button>}
                                    <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")}  >{t('Close')}</button>
                                 
                                </div>

                            </div>
                        </div>
                        <div class="card-body" style={{ minHeight: "60vh" }}>

                            {loading ? <>
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            </> : ""}
                            <div class="container">
                                <div class="row">
                                    <label class="col-md-2 text-left l_width">{t('RoleList.RoleName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={checkRolename} value={inputField.name} name="name" autoComplete='off' type="text" class="col-md-3 textbox-padding form-control m_width" />
                                    <div className="col-md-6 mt-2 text-left">
                                        <span className='errormessage'>{roleavailable === "Role available" ? "" : roleavailable}</span>                                    </div>



                                </div>
                                <div class="row mt-3">
                                    <label class="col-md-2 text-left l_width">{t('RoleList.RoleLabel')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} type="text" autoComplete='off' value={inputField.label} name="label" class=" col-md-3 textbox-padding form-control m_width" />
                                </div>
                                
                                <div class="row mt-3">
                                    <label class="col-md-2 mt-2 text-left l_width">{t('RoleList.Permissions')}<span className={classes.mandatory}>*</span></label>
                                    <Multiselect class="textbox-padding"
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Permissions}
                                       
                                        displayValue="Name"
                                        showCheckbox={true}
                                        style={regionStyles}
                                        className="col-md-3  textbox-padding mt-2 fontsizedropdown m_width"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    </>)
}
export default CreateRoleComponent