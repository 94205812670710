import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import EngineOilVolumeMappingPoliciesList from '../../../Components/PoliciesComponent/EngineOilVolumeMappingPolicy/EngineOilVolumeMappingPoliciesList';

function EngineOilVolumeMappingPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <EngineOilVolumeMappingPoliciesList {...props}  navigate={navigate} id={params.id} />
         </div>
     );  

}

export default EngineOilVolumeMappingPolicy