import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import { data } from "jquery";
import SessionManage from "../../SessionManagement/SessionManage";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from "react-i18next";

function CompanyProfileComponent(props) {
    const [editPerms, setEditPerms] = useState([]);
    const location = useLocation();
    const [t, i18n] = useTranslation('translation');
    const [userPerms, setuserPermission] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [selectedOptioncountry, setselectedOptioncountry] = useState();
    const [countrydata, setCountryData] = useState([])
    const [selectedOptionCurrency, setselectedOptionCurrency] = useState();
    const [currencydata, setCurrencyData] = useState([])
    const [fiscalStartMonth, setFiscalStartMonth] = useState();
    const [fiscalEndMonth, setFiscalEndMonth] = useState();
    const [baseImage, setBaseImage] = useState([]);
    const navigate = useNavigate();
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [invalidemail, setInvalidemail] = useState(false);
    const [invalidwebsite, setInvalidwebsite] = useState(false);
    const [file, setFile] = useState([]);
    const monthListData = [{ value: 'January', 'label': 'January', nomNo: 0 },
    { value: 'February', label: 'February', nomNo: 1 },
    { value: 'March', label: 'March', nomNo: 2 },
    { value: 'April', label: 'April', nomNo: 3 },
    { value: 'May', label: 'May', nomNo: 4 },
    { value: 'June', label: 'June', nomNo: 4 },
    { value: 'July', label: 'July', nomNo: 6 },
    { value: 'August', label: 'August', nomNo: 7 },
    { value: 'September', label: 'September', nomNo: 8 },
    { value: 'October', label: 'October', nomNo: 9 },
    { value: 'November', label: 'November', nomNo: 10 },
    { value: 'December', label: 'December', nomNo: 11 }]

    let loggedInUser = SessionManage.getuserid();
    const [inputField, setInputField] = useState({
        CreatedDate: new Date(),
        Name: "",
        Abbreviation: "",
        DefaultCurrency: null,
        Country: 1,
        FiscalStartMonth: "",
        FiscalEndMonth: "",
        Phone: "",
        Fax: "",
        PrimayContactEmail: "",
        Website: "",
        Code: '',
        Description: "",
        logo: '',
        ProfileCreatedByUserId: loggedInUser,
        ProfileLastModifiedUserId: loggedInUser,
        ProfileCreatedByUserName: '',
        ProfileLastModifiedUserName: '',
        Code: '',
        LastModifiedDate: new Date(),
        logoFile: null,
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0) {
            setIsValid(true)
            getData('CompanyProfile')
            getCurrencyData('currency')
            getCountryData("CompanyProfile/GetAllGeographylevel5")

        }
    }, [])

    const getData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res && res.status === 200) {
                        if (res.data.length > 0) {
                            let data = res.data[0]
                            setInputField(data)
                            setBaseImage(data.logo)
                            setselectedOptionCurrency({ value: data.DefaultCurrency, label: data.CurrencyName });
                            setselectedOptioncountry({ value: data.Country, label: data.CountryName });
                            setFiscalStartMonth({ value: data.FiscalStartMonth, label: data.FiscalStartMonth });
                            setFiscalEndMonth({ value: data.FiscalEndMonth, label: data.FiscalEndMonth });
                        }
                    }
                }
            }

        });
    }

    const getCountryData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            console.log("Country", res.data)
                            setCountryData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getCurrencyData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res && res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setCurrencyData(res.data)
                            console.log("Country Currency", res.data)
                        }
                    }
                }
            }

        });
    }

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }

    const inputsHandler = (e) => {
        setHandleChange(true)
        const { name, value } = e.target;
        setInputField(() => ({
            ...inputField, [name]: value,
        }));
        if (name === 'PrimayContactEmail') {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                setInvalidemail(true);
            }
            else {
                setInvalidemail(false);
            }
        }
        if (name === 'Website') {
            if (!/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/i.test(value)) {
                setInvalidwebsite(true);
            }
            else {
                setInvalidwebsite(false);
            }
        }
    }
    const OnChangeCountry = (data) => {
        setHandleChange(true)
        setInputField({ ...inputField, ['Country']: data.Id });
        setselectedOptioncountry({ value: data.Id, label: data.label });
    }

    const OnChangeCurrency = (data) => {
        setHandleChange(true)
        setInputField({ ...inputField, ['DefaultCurrency']: data.Id });
        setselectedOptionCurrency({ value: data.Id, label: data.label });
    }

    const OnChangeFiscalStartMonth = (data) => {
        setHandleChange(true)
        setInputField({ ...inputField, });
        setFiscalStartMonth({ value: data.value, label: data.label, nomNo: data.nomNo });
        if (data.nomNo == 0) {

            setInputField({ ...inputField, ['FiscalStartMonth']: data.value, ['FiscalEndMonth']: 'December' });
            setFiscalEndMonth({ value: 'December', label: 'December', nomNo: 11 });
        }
        else {
            monthListData.forEach(function (d) {
                if (data.nomNo - 1 == d.nomNo) {
                    setInputField({ ...inputField, ['FiscalStartMonth']: data.value, ['FiscalEndMonth']: d.value });
                    setFiscalEndMonth({ value: d.value, label: d.label, nomNo: d.nomNo });
                }
            })
        }
    }

    const OnChangeFiscalEndMonth = (data) => {
        setHandleChange(true)
        setInputField({ ...inputField, ['FiscalEndMonth']: data.value });
        setFiscalEndMonth({ value: data.Id, label: data.label, nomNo: data.nomNo });
    }

    const checkValidation = () => {
        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.CompanyName')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.Name.length < 5) {
            setErrorMessage('Minimum company name length is 5.')
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.Abbreviation == '' || inputField.Abbreviation == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.Abbreviation')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.DefaultCurrency == '' || inputField.DefaultCurrency == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Currency')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.Country == '' || inputField.Country == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.Country')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.FiscalStartMonth == '' || inputField.FiscalStartMonth == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.FiscalStartMonth')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.FiscalEndMonth == '' || inputField.FiscalEndMonth == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.FiscalEndMonth')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.Phone == '' || inputField.Phone == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.Phone')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.PrimayContactEmail == '' || inputField.PrimayContactEmail == null) {
            setErrorMessage(`${t('Please Enter')} ${t('CompanyProfile.Company')} ${t('CompanyProfile.PrimaryContactEmail')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputField.PrimayContactEmail)) {
            setErrorMessage(t('CompanyProfile.PleaseEnterValidCompanyPrimaryEmail'))
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.Website && !/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/i.test(inputField.Website)) {
            setErrorMessage(t('CompanyProfile.PleaseEnterValidWebsiteName'))
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {
            saveHandler(true)
        }
    }

    const saveHandler = (isValid) => {
        let savedata = inputField
         //data.logo = baseImage;
      debugger
      savedata.ProfileCreatedByUserId = SessionManage.getuserid();
      savedata.ProfileLastModifiedUserId = SessionManage.getuserid();
        let SendData = new FormData();
      
        SendData.append("data", JSON.stringify(savedata));
          for (let f of file) {
             SendData.append('logoFile', f);
           }
        if (isValid === true) {
            Service.getdatabySdData("CompanyProfile/CreateOrUpdate", SendData).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            props.navigate('/companyprofile');
                            setErrorMessage(t('CompanyProfile.SuccessfullySavedProfile'))
                            getData('CompanyProfile')
                            setShowErrorModal(true)
                            setHandleChange(false)
                        }
                        else {
                            setErrorMessage(t('CompanyProfile.ErrorInSavingProfile'))
                            setShowErrorModal(true)
                        }
                    }
                }

            });
        }
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const uploadImage = async (e) => {
       // logoFile = e.target.files[0];

        // const list = [...inputField];
        
        setFile([...file, ...e.target.files]);
       // const base64 = await convertBase64(file);
       // setBaseImage(base64);
    };


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            navigate("/")
        }
        if (flag == "Close") {
            navigate("/")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    const class_textbox = `col-md-2 textbox-padding form-control m_width  ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` col-md-2 textbox-padding fontsizedropdown m_width ${editPerms == false ? "disabledClass" : ""}`;
    const class_textarea = `col-md-8 textbox-padding form-control  ${editPerms == false ? "disabledClass" : ""}`;

    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('CompanyProfile.CompanyProfile')}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-8 text-left">
                                        {t('CompanyProfile.CompanyProfile')}
                                    </div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "45px" }} onClick={(event) => closeHandler("Open")}>{t('Close')}</button>
                                        {editPerms == false ? "" : <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} type="submit" onClick={checkValidation}>{t('Save')}</button>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }} >
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2  text-left l_width">{t('CompanyProfile.CompanyName')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Name} />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.CompanyCode')}</label>
                                        <input name="PrimayContactEmail" disabled type="text" className={class_textbox} value={inputField.Code} />


                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.Abbreviation')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Abbreviation" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Abbreviation} />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">Default Currency<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={"custom-multiselect" + class_multiselect}
                                            options={currencydata}
                                            placeholder="Select"
                                            value={selectedOptionCurrency}
                                            onChange={OnChangeCurrency}
                                            isSearchable={true}
                                            styles={customStyles}
                                        />
                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.Country')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={countrydata}
                                            placeholder="Select"
                                            value={selectedOptioncountry}
                                            onChange={OnChangeCountry}
                                            isSearchable={true}
                                            styles={customStyles}
                                        />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.FiscalStartMonth')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={monthListData}
                                            placeholder="Select"
                                            value={fiscalStartMonth}
                                            onChange={OnChangeFiscalStartMonth}
                                            isSearchable={true}
                                            styles={customStyles}
                                        />

                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.Phone')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Phone" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Phone} />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.FiscalEndMonth')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={ class_multiselect}
                                            options={monthListData}
                                            placeholder="Select"
                                            value={fiscalEndMonth}
                                            onChange={OnChangeFiscalEndMonth}
                                            isSearchable={true}
                                            styles={customStyles}
                                            isDisabled
                                        />

                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.PrimaryContactEmail')}<span className={classes.mandatory}>*</span></label>
                                        <input name="PrimayContactEmail" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.PrimayContactEmail} placeholder="john@gmail.com" />

                                        <div className="col-md-2"><span className='errormessage'>{inputField.PrimayContactEmail && invalidemail === true ? "Invalid Email" : ""}</span></div>

                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.Website')}</label>
                                        <input name="Website" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Website} placeholder="www.qss.com" />
                                        &nbsp;&nbsp;<span className='errormessage'>{inputField.Website && invalidwebsite === true ? "Invalid Website Name" : ""}</span>
                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.Fax')}</label>
                                        <input name="Fax" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Fax} />

                                        <div className="col-md-2"></div>

                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.CompanyDescription')}</label>
                                        <textarea name="Description" onChange={inputsHandler} className={class_textarea} value={inputField.Description} />
                                    </div>

                                    <div className="row mt-2">
                                        <label className='col-sm-2 text-left l_width'>{t('CompanyProfile.CompanyLogo')}<span className={classes.mandatory}>*</span></label>
                                        <div className='col-sm-2 '>
                                            <Form.Control type="file" className={editPerms == false ? "disabledClass" : ""} onChange={(e) => { uploadImage(e); }} />
                                        </div>
                                        <div className="col-md-2">
                                        </div>
                                        {/* <label className="col-md-2 text-left">Uploaded Logo</label> */}
                                        <div className="col-md-4">
                                            <img className='img-thumbnail img-fluid form-control m_width' src={baseImage} style={{ height: 200 + 'px' }} />
                                        </div>


                                    </div>
                                    <div className="row mt-2">
                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.CreatedDate')}</label>
                                        <input name="PrimayContactEmail" disabled type="text" className="col-md-2 textbox-padding form-control m_width" value={moment(inputField.CreatedDate).format(SessionManage.getcurrentdateformat())} />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">{t('CompanyProfile.CreatedBy')}</label>
                                        <input name="Website" type="text" disabled className="col-md-2 textbox-padding form-control m_width" value={inputField.ProfileCreatedByUserName} />
                                    </div>
                                    <div className="row mt-2"> 
                                        <label className="col-md-2 text-left l_width">{t('LastModifiedDate')}</label>
                                        <input name="PrimayContactEmail" disabled type="text" className="col-md-2 textbox-padding form-control m_width" value={moment(inputField.LastModifiedDate).format(SessionManage.getcurrentdateformat())} />

                                        <div className="col-md-2"></div>

                                        <label className="col-md-2 text-left l_width">{t('LastModifiedBy')}</label>
                                        <input name="Website" type="text" disabled className="col-md-2 textbox-padding form-control m_width" value={inputField.ProfileLastModifiedUserName} />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
             
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('CompanyProfile.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}
export default CompanyProfileComponent