import CreateSchemeComponent from "../../../Components/SchemeComponent/CreateSchemeComponent/CreateScheme"
import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
// import '../index.css';



function CreateScheme(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <CreateSchemeComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}
export default CreateScheme