import React, { useEffect, useState, forwardRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import SessionManage from "../../SessionManagement/SessionManage";

function ListCongurationProperty(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState('')
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [columnAscending, setColumnAscending] = useState(true);
    const [isValid, setIsValid] = useState(true);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            getCongurationPropertyList("ConfigurationProperties")
        }, [props.id])
    }
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res !== undefined) {
                        debugger;
                        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                            if (res.data.DataList !== undefined) {
                                setuserPermission(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }
    const searchHandler = (event) => {
        if (event.target.value != '') {
            let search = String(event.target.value)
            setSearchValue(search.toLowerCase())
            let searchResults = listViewData.filter((each) => {
                let name = String(each.Name)
                let label = String(each.label)
                return name.toLowerCase().includes(searchValue) || label.toLowerCase().includes(searchValue)
            })
            setFilteredArray(searchResults)
        }
        else {
            setFilteredArray(listViewData)
        }
    }

    const handleLoadMore = () => {
        // let pagesize = 20;
        // let pagenumber = paging.pageNumber + 1;
        // ("WorkflowRule/GetAll", pagesize, pagenumber)
        // paging.pageSize = pagesize;
        // paging.pageNumber = pagenumber
        // setpaging(paging);
    };


    const handleChange = (e, id, name, status) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
        }
        else {

            setCheckedValue([])  // check box value - id 
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
        }
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length === 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select a Policy to view')
        }
        else {
            navigate("/congurationprop/edit/" + id);
        }
    }



    const DeleteHandler = (id) => {
        // if (id > 0) {
        //     Service.deleteDataByconfigurationPropertyID("congurationprop/delete", id, loggedInUser).then(res => {
        //         if (res.status === 200) {
        //             if (res.data !== 'null' && res.data !== 'undefined') {
        //                 alert('Deleted Successfully')
        //                 getCongurationPropertyList("ConfigurationProperties")
        //             }
        //         }
        //     })
        // }
    }
    const getCongurationPropertyList = (method) => {
        let rowData = [{
            "Id": 1,
            "CreatedDate": "2022-05-16T16:03:37.470356+05:30",
            "LastModifiedDate": "2022-05-16T16:03:37.470356+05:30",
            "Deleted": false,
            "Name": "saleslevelfilter",
            "Label": "SalesOffice",
            "CreatedUserId": null,
            "LastModifiedUserId": null,
            "DefaultGroupId": null
        },
        {
            "Id": 2,
            "CreatedDate": "2022-05-16T16:03:37.470356+05:30",
            "LastModifiedDate": "2022-05-16T16:03:37.470356+05:30",
            "Deleted": false,
            "Name": "url",
            "Label": "http://localhost:8000/api/",
            "CreatedUserId": null,
            "LastModifiedUserId": null,
            "DefaultGroupId": null
        }
        ]
        setListViewData(rowData)
        setFilteredArray(rowData)
        setshowspinner(false)

        // Service.getAllConfigurationProperty(method).then((res) => {
        //     if (res.status === 200) {
        //         if (res.data !== 'null' && res.data !== 'undefined') {
        //             rowData = res.data
        //             setListViewData(rowData)
        //             setFilteredArray(rowData)
        //             setshowspinner(false)
        //             // LoadMore(rowData.length, res.data.Totalcount)
        //         }
        //     }
        // })
    }
    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
            navigate("/congurationprop/edit/" + id);
        }
    }
    const SortColumn = (colName) => {
        setColumnAscending(!columnAscending);
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        console.log("Ascending", columnAscending);
        // getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, paging.pageNumber, sortType, colName, null, null);
    }
    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Configuration Properties List</li>
                </ol>
            </nav>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">

                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" id="firstName" name="firstName" placeholder="Search for Name or Label" onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <Link className="btn btn-sm btn-update ml-5" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/congurationprop/add/0" >Create New</Link>}
                                        {showbutton === true ?
                                            <>
                                                <a className="btn btn-update  btn-sm ml-2" type="submit" style={{ width: "90px", backgroundColor: "buttonface", width: "40px" }} onClick={() => OpenHandler(checkedValue)} >Open</a>

                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" onClick={() => DeleteHandler(checkedValue)} style={{width: "50px"}}>Delete</button>}

                                            </>
                                            :
                                            ''
                                        }

                                        {/* {Service.editPerms(userPerms)==false?"":
                                            <>

                                                <button className="btn btn-update  btn-sm ml-2" type="submit" onClick={() => DeleteHandler(checkedValue , checkedValueStatus)}>Delete</button>

                                            </>
                                        }  */}
                                    </div>
                                </div>


                                <table className="table table-bordered text-left mt-2" id="tableA">
                                    <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                            {/* <th>No</th> */}
                                            <th>Name <span className='fa fa-sort' onClick={() => SortColumn('Name')}></span></th>
                                            <th>Label <span className='fa fa-sort' onClick={() => SortColumn('Label')}></span></th>
                                            <th>Last Modified Date <span className='fa fa-sort' onClick={() => SortColumn('LastModifiedDate')}></span></th>
                                            <th>Last Modified By <span className='fa fa-sort' onClick={() => SortColumn('LastModifiedUser')}></span></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td>
                                                            No Data Available
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)} key={index}>
                                                            <td ><input type="checkbox" value={`${listVal.Name}, ${listVal.ISActive}`} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive)} name="chkBox" /></td>
                                                            {/* <td>{index + 1}</td> */}
                                                            <td>{listVal.Name}</td>
                                                            <td>{listVal.Label}</td>
                                                            <td>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                            <td>{listVal.LastModifiedUser}</td>
                                                            {/* <td >
                                                                <div className="btn-group">
                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        {Service.editPerms(userPerms)==false?(listVal.IsActive===true?"Active":"In-Active"):listVal.ISActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id)}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id)}>In-Active</a>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    )

                                        }

                                    </tbody>
                                </table>
                                {/* <div className='text-center container row'>
                                    <div className='col-md-5'></div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore}>Load More</button>
                                    </div>
                                    <div className='col-md-5'></div>

                                </div> */}

                            </div>

                        </div>
                    </div>

                </div>
            </div >

            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div >


    )

}
export default ListCongurationProperty