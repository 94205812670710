import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
// import '../index.css';
import DistributorCustomerListView from '../../Components/DistributorCustomerComponent/DistributorCustomerListView';



function DistributorCustomerListing(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <DistributorCustomerListView {...props}  navigate={navigate} id={params.val} />
        </div>
    );  
}
export default DistributorCustomerListing