import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Link, useLocation } from "react-router-dom"
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Service from '../../../Services/Service';
import classes from '../CreateRebateComponent/Form.module.css'
import dateFormat from 'dateformat';
import $, { type } from 'jquery';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomListbox from '../CreateRebateComponent/CustomListbox';
import ProductTreeDialog from '../CreateRebateComponent/ProductTreeDialog';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../Helper/utils.js';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Color } from 'ag-grid-community';
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 28,
        maxHeight: 100,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2,
        fontSize: "0.75rem",
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        fontSize: "0.75rem",
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 2px',
        fontSize: "0.75rem",
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
    })
};







function CreateClaimAgreementComponent(props) {
    const [userPerms, setuserPermission] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [regioncheckAll, setRegioncheckAll] = useState(false);
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Rebate Program ID", key: "RebateProgramLineId" },
        { label: "Rebate Program Desc", key: "RebateProgram_Desc" },
        { label: "Rebate Definition", key: "Rebate_Definition" },
        { label: "Rebate Category", key: "RebateCategory" },
        { label: "Rebate Type", key: "Rebate_Type" },
        { label: "Valid From", key: "Validity_From" },
        { label: "Valid To", key: "Validity_To" },
        { label: "Accrual Frequency", key: "Settlement_Freq" },
        { label: "Status", key: "Status" },
        { label: "Is Active", key: "Active" },
    ]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
    const [custChngModal, setCustChngModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('')
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [showProdModal, setShowProdModal] = useState(true);
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)
    const [filtertext, setfiltertext] = useState('');
    const [currentFilterKey, setcurrentFilterKey] = useState('');
    const [updateNav, setUpdateNav] = useState("/rebate-program/0")


    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [rpl_ID, setRPL_ID] = useState(false);
    const [salesOrgData, setSalesOrgData] = useState({ id: 0, name: "" });
    const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
    const [currencyData, setCurrencyData] = useState([]);
    const [setlFreqData, setSetlFreqData] = useState([]);
    const [setlMethodData, setSetlMethodData] = useState([]);
    const [rebateMeasureData, setRebateMeasureData] = useState([]);
    const [rebatePayoutCondData, setRebatePayoutCondData] = useState([]);
    const [rebateCategoryData, setRebateCategoryData] = useState([]);

    const [selectedRebateCategory, setSelectedRebateCategory] = useState('');
    const [dropdownChange, setDropdownChange] = useState([]);
    const [totalCountlst, settotalCountlst] = useState(0);
    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    const [showSelectedRegionpopup, setShowSelectedRegionpopup] = useState(false);
    const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
    const [regionDataSelected, setRegionDataSelected] = useState([]);
    const [productDataSelected, setProductDataSelected] = useState([]);


    const [state, setState] = useState({ id: 0, rebateTypeID: 0, salesOrgID: 0, agreementName: "", currencyID: 0, setlMethodID: 0, setlFreqID: 4, startDate: new Date(), endDate: new Date(SessionManage.getFiscalEndDate()), autoPostAccruals: false, analysis: false, status: 'draft', agreementId: '0', created_by: '', is_active: false, createdDate: moment(new Date()).format(SessionManage.getcurrentdateformat()), CurrencySymbol: '$', LockedByUserName: '', DistributorId: 0, RegionId: 0 })
    const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0, PaymentTermsId: '', rebateProgramLineId: 0, rebateProgramDesc: "", rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, validityFrom: defaultDate.from_date, validityTo: defaultDate.to_date, settlementFreqId: 0, settlementFreq: "", active: false, deleted: false, statusId: 0, status: "", geography: [], Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", comparisonEndDate: '', comparisonStartDate: '', comparisondateformatId: 0 })
    const navigate = useNavigate();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [commentsForSubmit, setCommentsForSubmit] = useState("")
    const [definitionData, setDefinitionData] = useState({})
    let wData = [0]
    const [workflowData, setWorkflowData] = useState([]);
    const [woData, setWoData] = useState('');
    const [Products, SetProducts] = React.useState([]);
    const [ProductList, SetProductList] = useState([]);
    const [OldProductList, SetOldProductList] = useState([]);
    const [FilteredProductList, SetFilteredProductList] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [showDeleteModal, toggleModal] = useState(false)
    const [rebatePayoutCond, setRebatePayoutCond] = useState('')
    const [targetmeasureData, setTargetMeasureData] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [productsModal, setProductsModal] = useState(false);
    const [targetedPayCond, setTargetPayCond] = useState(false);
    const [showrebateProgramLine, setshowrebateProgramLine] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [clickProduct, setclickProduct] = useState('');
    const [filterValue, setFilterValue] = useState([]);
    const [showProductLoadSpinner, setShowProductLoadSpinner] = useState(false)

    const [hasPaymentTerms, setHasPaymentTerms] = useState(false);

    const [workflowchart, setworkflowchart] = useState([]);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [closeModal, setCloseModal] = useState(false);
    const [closeHeaderModal, setCloseHeaderModal] = useState(false);
    const [changeHandle, setChangeHandle] = useState(false);
    const [changeHandleHeader, setChangeHandleHeader] = useState(false);
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [ToggleStatusModal1, setToggleStatusModal1] = useState(false);
    const [ToggleStatusModal2, setToggleStatusModal2] = useState(false);
    const [activeData, setActiveData] = useState([]);
    const [message, setMessage] = useState("");
    const [activeStatus, setactiveStatus] = useState('');
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();

    const [showtargettable, setShowTargetTable] = useState(false);
    const [showtargetselection, setShowTargetSelection] = useState('');
    const [open, setOpen] = useState(true);
    const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [SelectedProducts, SetSelectedProducts] = useState([]);
    const [ExcludedSelectedProducts, SetExcludedSelectedProducts] = useState([]);
    const [products, setProducts] = useState([0])
    const [color, setColor] = useState('green');
    const [icon, setIcon] = useState('fa fa-plus');
    const [expandAccurals, setExpandAccruals] = useState('')
    const [filteringvalue, setfilteringvalue] = useState('');
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const [currencyList, setCurrencyList] = useState([]);
    const [settlementMethodsList, setSettlementMethodsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [statusList, setStatusList] = useState([]);


    const [accrualPeriodValue, setAccrualPeriodValue] = useState([]);
    const [accrualCustomerValue, setAccrualCustomerValue] = useState([]);
    const [customerInvoiceValue, setCustomerInvoiceValue] = useState([]);
    const [invoicePeriodValue, setInvoicePeriodValue] = useState([]);

    const [data, setData] = useState([]);
    let includeSubDivideTarget = SessionManage.getIncludeSubDivideTarget();
    let includeOutstandingAmount = SessionManage.getIncludeOutstandingAmount();
    const [selectedSettlementFreq, setSelectedSettlementFreq] = useState('');
    const [rebateIcon, setRebateIcon] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [jsonList, setJsonList] = useState({});
    const [displayDownloadError, setDisplayDownloadError] = useState(false);
    const [filePath, setFilePath] = useState('');
    const location = useLocation();
    const [revised,setRevised] = useState(false);
      const [validityFromEditable,setValidityFromEditable] = useState(false);
      const [validityToEditable,setValidityToEditable] = useState(false);
      const [showClaimDialogForRevise,setShowClaimDialogForRevise] = useState(false);
    const [filterValueDict, setFilterValueDict] = useState({
        rebateprogramline_id: '',
        desc: '',
        status: '',
        valid_from: null,
        filterCol: null,

    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        defn: '',
        cat: '',
        frequency: '',
        type: '',
        active: '',
    });

    // const [filterValueCustomer, setFilterValueCustomer] = useState({
    //     Name: '',
    //     Label: '',
    //     Station: '',
    //     State: '',
    //     Zone: '',
    // });
    // const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
    //     label: '',
    // });

    const activeOption = [
        { Name: "Active", Id: "1", ischecked: false },
        { Name: "In-Active", Id: "0", ischecked: false },

    ]
    const [qtPayoutList, setQtPayoutList] = useState([])
    const [hfPayoutList, setHfPayoutList] = useState([])
    const [monthlyPayoutList, setMonthlyPayoutList] = useState([])

    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [VolumeRebateList, setVolumeRebateList] = useState([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "",
        Rebate: "",
        Rebate_Value: ""
    },]);
    const [isNew, setIsNew] = useState(true)
    const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
    const [selectedType, setSelectedType] = useState('Attribute');
    const [ExcludedProducts, SetExcludedProducts] = useState([]);
    const [ExcludedProductList, SetExcludedProductList] = useState([]);
    const [FiltedProducts, SetFiltedProducts] = useState([]);
    const [selectedVolumeRebateProduct, setSelectedVolumeRebateProduct] = useState([]);
    const [FetchedProd, setFetchedProd] = useState([]);
    const [accrualHistoryData, setAccrualHistoryData] = useState([])
    const [showspinner, setshowspinner] = useState('');
    const [showspinneraccural, setshowspinneraccural] = useState('');
    const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [disableSubDivideTarget, setdisableSubDivideTarget] = useState(false);
    let rebateDefinitionDatail = []
    let rebateApproved = 0;
    let disableHeaderField = false;
    let disableProgramLineField = false;
    let copyID = '';
    let initial_value = [
        { id: 0, product: "", Targetcondition: "", Measure_Value: "", Target_Measure: "", Rebate: "", Rebate_Value: "" },
    ]
    const [showExcludeModal, setShowExcludeModal] = useState(false);
    const [inputField, setInputField] = useState({
        addproductdata: true,
        excludeproductdata: false,
        RegionId: '',
        DistributorId: ''
    })
    const inputsHandler = (e) => {
        setChangeHandle(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        var f = inputField;
    }

    const [showpopupcontnent, setshowpopupcontnent] = useState({
        value: 1
    })
    const [showCreateEditRebatePgm, setShowCreateEditRebatePgm] = useState(true)
    const [date, setDate] = useState([new Date(), new Date(year + 1, month, day)]);
    const [activeRebate, setActiveRebate] = useState(true)
    const [rebateNumber, setRebateNumber] = useState(0)
    const [rebateProgramLine, setrebateProgramLine] = useState([])
    const [hasConfirmProgram, setHasConfirmProgram] = useState([])
    const [isProgramLineConfirmed, setIsProgramLineConfirmed] = useState(0)
    const [showApprovedModal, setShowApprovedModal] = useState(false)
    const [commentsForApproved, setCommentsForApproved] = useState("")
    const [showDeniedModal, setShowDeniedModal] = useState(false)
    const [commentsForDenied, setCommentsForDenied] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [SalesLevelData, setSalesLevelData] = useState([])
    let loggedInUser = SessionManage.getuserid();
    const [activerebateProgramLine, setActiveRebateProgramLine] = useState([])
    const [testdata, setTestData] = useState([]);
    const [testdataAccruals, setTestDataAccruals] = useState([]);
    const [loadedFrom, setLoadedFrom] = useState('');
    const [showrebateprogram, setshowrebateprogram] = useState('');
    const [showproductpopup, setshowproductpopup] = useState('');
    const [showWorkflow, setshowWorkflow] = useState('');
    const [showAccuralWorkflow, setshowAccuralWorkflow] = useState('');
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showAccuralHistory, setshowAccuralHistory] = useState('');
    const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
    const [showLoadMore, setshowLoadMore] = useState('');
    const [productname, setProductName] = useState('');
    const [workflowApprovers, setWorkflowApprovers] = useState([])
    const [workflowLevelCount, setWorkflowLevelCount] = useState('');
    const [firstApprover, setFirstApprover] = useState([]);
    const [secondApprover, setSecondApprover] = useState([]);
    const [thirdApprover, setThirdApprover] = useState([]);
    const [CurrentWorkflowLevel, setCurrentWorkflowLevel] = useState('');
    const [currentLevel1, setCurrentLevel1] = useState("{`${classes['square-levels']} `}");
    const [currentLevel2, setCurrentLevel2] = useState("{`${classes['square-levels']}  `}");
    const [currentLevel3, setCurrentLevel3] = useState("{`${classes['square-levels']} `}");
    const [saveProgramLine, setSaveProgramLine] = useState(false);
    const [accrualCalcTypeData, setAccrualCalcTypeData] = useState([]);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [regionchangesvalue, setregionchangesvalue] = useState([]);
    const [whoCalled, setWhoCalled] = useState("");



    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedOptionsSetlMethod, setSelectedOptionsSetlMethod] = useState('');
    const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState('4');
    const [selectedOptionsCurrency, setSelectedOptionsCurrency] = useState('');
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
    const [selectedOptionsSettlFreq, setSelectedOptionsSettlFreq] = useState('');
    const [selectedOptionsRebateMeasure, setSelectedOptionsRebateMeasure] = useState('');
    const [selectedOptionsRebateMeasureDisabled, setSelectedOptionsRebateMeasureDisabled] = useState('');
    const [selectedOptionsAccrualCal, setSelectedOptionsAccrualCal] = useState('');
    const [selectedOptionsPayout, setSelectedOptionsPayout] = useState();
    const [selectedOptionsRegion, setSelectedOptionsRegion] = useState([]);

    const [paymentTermsId, setPaymentTermsId] = useState()
    const [option, setOption] = useState([]);
    const [rebateTypeValue, setRebateTypeValue] = useState('')
    const [comparisonStartDate, setComparisonStartDate] = useState('')
    const [comparisonEndDate, setComparisonEndDate] = useState('')
    const [comparisonPeriod, setComparisonPeriod] = useState('')
    const [comparisonFormatData, setComparisonFormatData] = useState([])
    const [comparisonDateEditable, setComparisonDateEditable] = useState(false)
    const [selectedOptionsCompDateFormat, setSelectedOptionsCompDateFormat] = useState();
    const [comparisonDateFormatName, setComparisonDateFormatName] = useState();
    const [measureValueLabel, setmeasureValueLabel] = useState("");

    const [defineMinThreshold, setDefineMinThreshold] = useState(false);
    const [subdivideTargetid, setSubdivideTargetid] = useState();
    const [hasSubdivideTarget, setHasSubdivideTarget] = useState(false);
    const [minThresholdValue, setMinThresholdValue] = useState(0);
    const [minThresholdPayout, setMinThresholdPayout] = useState(0);
    const [showregionpopup, setshowregionpopup] = useState('');
    const [paymentTermsList, sePaymentTermsList] = useState([]);
    const [checkCustomerGroup, setcheckCustomerGroup] = useState(false);
    const disabledClass_dropdown = "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown"
    const [countryData, setCountryData] = useState([]);

    const [RegionId, setRegionId] = useState([]);
    const [DistributorId, setDistributorId] = useState([]);


    const columnList = [
        { "label": "Rebates.RebateProgramId", "key": "rebateprogramline_id", "value": true },
        { "label": "Rebates.RebateProgramDesc", "key": "desc", "value": true },
        { "label": "Rebates.RebateType", "key": "type", "value": true },
        { "label": "Rebate Definition", "key": "Rebate_Definition", "value": true },
        { "label": "Rebate Category", "key": "RebateCategory", "value": true },
        { "label": "Rebates.RebateDefinition", "key": "defn", "value": true },
        { "label": "Rebates.RebateCat", "key": "cat", "value": true },
        { "label": "Rebates.ValidFrom", "key": "valid_from", "value": true },
        { "label": "Rebates.ValidTo", "key": "valid_to", "value": true },
        { "label": "Rebates.AccrualFreq", "key": "frequency", "value": true },
        { "label": "Rebates.Status", "key": "status", "value": true },
        { "label": "Rebates.IsActive", "key": "active", "value": true }
    ]
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const fileReader = new FileReader();
    {
        useEffect(() => {
            sessionStorage.setItem('ids', props.id);
            getCountryData("Country")
            if (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted') {
                getUserPermission('/rebateapprovals/pendingaction/0');

            }
            else {
                getUserPermission('/new-claimagreement/0/fromRebate/0');
            }
            GetSubdivideTargetType();
            setshowrebateprogram(false);
            setshowproductpopup(false);
            setshowAccuralWorkflow(false);
            setshowWorkflow(false);
            setshowAccuralHistory(false);
            setshowspinner(true)
            setclickProduct(false);
            setshowspinneraccural(true)
            setshowspinnerworkflow(true)
            setshowrebateProgramLine(false)
            getRebateTypeData("rebatetype")
            getCurrencyData("currency")
            getSetlFreqData("settlementfrequency")
            getSetlMethodData("settlementmethod")
            getAccrualCalcType("AccrualCalcType")
            AddProductHandler()
            getComparisonFormatData("ComparisonDateFormat")
            getColumnVisibilityList()
            getColVisibilityListData()
            getdataByGroup("SDCommon/GetAllDistCustCurrencySettlementProduct");


            setColumnAscending(false);
            setSortColName("Id");

            if (props.name == "fromApproval" || props.name == "fromApprovalCompleted") {
                setLoadedFrom(props.name);
                setShowCreateEditRebatePgm(false)
                getRebateProgramLineDetails("GetRebateProgramLinesDetails", props.value)
                getdataProductSelectorByAttribute(props.value)
                setshowrebateprogram(true);
            }
            if (props.id != '0') {
                getRebateHeaderDetails("Rebate/GetRebateHeader", props.id)
                getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, paging.pageNumber, "Descending", "Id", [], null, "")

                getActiveRebateProgramlines('Rebate/GetApprovedActiveRebateProgramLine', props.id)
                getTotalAccrualAmount("Rebate/GetAccrualAmountByRebateId", props.id);
                setLoadedFrom(props.name);
            }
            else {
                setState({
                    id: 0,
                    rebateTypeID: 0,
                    agreementName: "",
                    agreementId: '0',
                    currencyID: 0,
                    setlMethodID: 0,
                    setlFreqID: 0,
                    autoPostAccruals: false,
                    analysis: false,
                    status: 'draft',
                    created_by: SessionManage.getusername(),
                    createdDate: moment(new Date()).format(SessionManage.getdateformat()),
                    CurrencySymbol: '$',

                    DistributorId: 0,
                    RegionId: 0
                })
                setWorkflowData([0])
                VolumeRebateList.length = 0
                setVolumeRebateList(initial_value);
                SetProducts([])
                SetProductList([])
                setshowspinner(false);
                setLockedBy(loggedInUser);
                setIsLocked(true);
            }
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };

            window.addEventListener('beforeunload', alertUser)
            window.addEventListener('unload', handleEndConcert)
            return () => {
                window.removeEventListener('beforeunload', alertUser)
                window.removeEventListener('unload', handleEndConcert)
            }

        }, [props.id])
    }


    const getdataProductSelectorByAttribute = (programId) => {
        Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], programId).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
            }
        });
    };

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }
    const handleEndConcert = async () => {
    }
    const fetchAllData = () => {
        getRebateHeaderDetails("Rebate/GetRebateHeader", props.id)
    }
    const GetSubdivideTargetType = () => {
        Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setSubdivideTargetTypeList(res.data);
                }
            }
        });

        Service.getdata("PaymentTerms/GetALLPaymentTerms").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    sePaymentTermsList(res.data);
                }
            }
        });
    }

    const getUserPermission = (pathname) => {

        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        debugger
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const handleregionChanges = (e, label, id) => {
        let selected = [];
        let updateregion = [];
        const getallData = {
            Id: id,
            label: label
        }

        let olddata = [...SalesLevelData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.selected = true;
                }
                updateregion.push(i);
            })

            selected.push(getallData);
            setregionchangesvalue(selected);
            setSelectedOptionsRegion(selected);
            setSalesLevelData(updateregion);
            const geography1 = [...rebateprogrmlinedetail.geography];
            geography1.push(getallData);
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['geography']: geography1 })

        }
        else {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.selected = false;
                }
                updateregion.push(i);
            })

            selected.push(getallData);
            var index = regionchangesvalue.indexOf(selected);
            setregionchangesvalue(regionchangesvalue.filter((_, i) => i !== index))

            setSalesLevelData(updateregion);

            const geography = [...rebateprogrmlinedetail.geography];

            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['geography']: geography.filter(i => i.Id !== id) });

        }

    }


    const EligibleInvoicePopup = (e, flag) => {

        let value = e
        setRPL_ID(value);
        getRebateProgramLineDetails("GetRebateProgramLinesDetails", value)
        setShowInvoiceModal(true)
        if (flag === "EligibleInvoice") {
            setWhoCalled("EligibleInvoice")
        }
        if (flag === "AnalyzeRebate") {
            setWhoCalled("AnalyzeRebate")
        }
    }


    if (hasConfirmProgram.length === 0) {
        if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
            disableHeaderField = false;
        }

        else {
            disableHeaderField = true;
        }
    }

    else {
        disableHeaderField = true;
    }

    const okregion = () => {
        setshowregionpopup(false);
    }



    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const isSticky = () => {
        try {
            const scrollTop = window.scrollY;
            const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
            setSticky(stickyClass);
            const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
            var divtop1 = document.getElementById("table-RPL");
            setdivtop(divtop1.offsetTop + scrollTopActual)
        } catch { }
    };

    const class_thead = `text-white rt-thead  ${sticky}`;
    const ExporttoCSV = () => {

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id.split(',')[0];
            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getPostdatabyRebateIdSearch("getrebateprogramlines/rebateid", props.id, 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    console.log(res.data.DataList, 'data-list');
                    console.log(hideColumn, 'hide');
                    console.log(typeof hideColumn);

                    console.log(res.data.DataList, 'data-list');

                    let csvLst = res.data.DataList;

                    let filteredCsvList = csvLst.map(item => {
                        let newItem = {};
                        Object.keys(item).forEach(key => {
                            if (!hideColumn.includes(key)) {
                                newItem[key] = item[key];
                            }
                        });
                        return newItem;
                    });

                    let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                    setCsvList(filteredCsvList);
                    setHeaders(filteredHeaders);

                    console.log(csvLst, 'final-list');
                    console.log(headers, 'headers');

                    setTimeout(() => {
                        toast.success('Exported Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }



    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getCustomerData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setCustomerData(res.data)
                            setData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getSalesOfficeDataNew = (method) => {
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data

                            if (rowData.length > 0) {
                                for (var i = 0; i < rowData.length; i++) {
                                    for (var j = 0; j < customerData.length; j++) {
                                        try {
                                            if (rowData[i].label.toLowerCase() == customerData[j].State.toLowerCase()) {
                                                if (customerData[j].Selected == true) {
                                                    rowData[i].selected = true;
                                                }
                                            }
                                        } catch { }
                                    }
                                }
                                rowData.map((each) => {
                                    if (each.selected == true) {
                                        each['avalRegion'] = true
                                    }
                                    else {
                                        each['avalRegion'] = false
                                    }
                                })

                                setSalesLevelData(rowData)
                            }

                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }

    const getSalesOfficeDatabyGroup = (method, id) => {
        let rowData = [];
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                        }
                    }
                }
            }
        })
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let sData = res.data

                            if (sData.length > 0) {
                                for (var i = 0; i < sData.length; i++) {
                                    for (var j = 0; j < rowData.length; j++) {
                                        try {
                                            if (sData[i].label.toLowerCase() == rowData[j].State.toLowerCase())
                                                if (rowData[j].Selected == true) {
                                                    sData[i].selected = true;
                                                }
                                        } catch { }
                                    }
                                }
                                sData.map((each) => {
                                    if (each.selected == true) {
                                        each['avalRegion'] = true
                                    }
                                    else {
                                        each['avalRegion'] = false
                                    }
                                })
                                setSalesLevelData(sData)
                            }
                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }


    const getCurrencyData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setCurrencyData(res.data)
                            const firstdata = res.data[0]

                            var defCurrency = JSON.parse(SessionManage.getdefaultCurrency());
                            if (defCurrency !== undefined && defCurrency !== null) {

                                setSelectedOptionsCurrency(defCurrency.id);
                                setState({ ...state, ['currencyID']: defCurrency.id })
                            }

                        }
                    }
                }
            }

        });
    }
    const getSetlMethodData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setSetlMethodData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getSetlFreqData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setSetlFreqData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getRebateMeasureData = (method, Id, flag) => {
        Service.getdatabyId(method, Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateMeasureData(res.data)

                            if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Revenue') {
                                setSelectedOptionsRebateMeasure(res.data[0].Id)
                            }
                            if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Growth in Qty (Abs)') {
                                setmeasureValueLabel('Unit')
                            }
                            else if (res.data[0].Name === 'Revenue' || res.data[0].Name === 'Growth in Rev (Abs)') {
                                setmeasureValueLabel('Amount')

                            }
                            else if (res.data[0].Name === 'Growth in Qty (%)' || res.data[0].Name === 'Growth in Rev (%)') {
                                setmeasureValueLabel('%')
                            }
                            if (flag == 'RebateType') {
                                res.data.map((i) => {
                                    if (i.Name === 'Growth in Qty (%)' || i.Name === 'Growth in Qty (Abs)') {
                                        if (i.Name === 'Growth in Qty (%)') {
                                            setSelectedOptionsRebateMeasure(i.Id)
                                            setmeasureValueLabel("%")
                                        }
                                    }
                                    if (i.Name === 'Growth in Rev (%)' || i.Name === 'Growth in Rev (Abs)') {
                                        if (i.Name === 'Growth in Rev (%)') {
                                            setSelectedOptionsRebateMeasure(i.Id)
                                            setmeasureValueLabel("%")
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            }
        });
    }
    const getRebatePayoutData = (method, Id) => {
        Service.getdatabyId(method, Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebatePayoutCondData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getrebateCategoryData = () => {

        Service.getdatabyId('Rebate/GetRebateCategory', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    if (res.data !== 'null' && res.data !== 'undefined') {
                        setRebateCategoryData(res.data)
                    }

                }
            }

        });
    }
    const getRebateHeaderDetails = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {

                            let rData = res.data[0];

                            setState({
                                id: rData.Id,

                                agreementId: rData.Rebate_id,
                                agreementName: rData.RebateName,
                                currencyID: rData.Currency_Id,
                                setlMethodID: rData.Settlement_method_Id,
                                setlFreqID: rData.Settlement_Frequency_Id,
                                startDate: moment(rData.Validity_From).toDate(),
                                endDate: moment(rData.Validity_To).toDate(),
                                autoPostAccruals: rData.Auto_Post_Accruals,
                                analysis: rData.analysis,
                                status: rData.Status,
                                created_by: rData.CreatedBy,
                                createdDate: moment(rData.CreatedDate).format(SessionManage.getcurrentdateformat()),
                                CurrencySymbol: rData.CurrencySymbol,
                                LockedByUserName: rData.LockedByUserName,
                                Agreementtype: 'ClaimAgreement',

                                DistributorId: rData.DistributorId,
                                RegionId: rData.RegionId

                            })
                            setInputField({
                                DistributorId: rData.DistributorId,
                                RegionId: rData.RegionId
                            })

                            if ((rData.IsLocked === true && rData.LockedBy.toString() !== loggedInUser)) {
                                $("#libread").html(rData.Rebate_id + " " + `Locked By ${rData.LockedByUserName}`);
                            }
                            else {
                                $("#libread").html(rData.Rebate_id);
                            }
                            setSalesOrgData({
                                id: rData.Sales_org_Id,
                                name: rData.Sales_org_Label
                            })

                            if (props.name != '' && props.name != undefined) {
                                if (props.name.toLocaleLowerCase() == 'fromapproval') {
                                    $("#headertxt").html('Rebate Approval- Pending Action');
                                    setUpdateNav("/rebateapprovals/pendingaction/0")
                                }
                                else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                                    setUpdateNav("/rebateapprovals/archive/0")
                                    $("#headertxt").html('Rebate Approval- Workflow Completed');
                                }
                            }
                            else {
                                $("#headertxt").html(t('Rebates.RebateAgreementList'));
                                setUpdateNav("/rebate-program/0")
                            }

                            setSelectedOptionsSetlMethod(rData.Settlement_method_Id);
                            setSelectedOptionsSetlFreq(rData.Settlement_Frequency_Id);
                            setSelectedOptionsCurrency(rData.Currency_Id);

                            const inputString = rData.Status;
                            const regex = /[0-9]+-([A-Za-z]+)/g;

                            const matches = [...inputString.matchAll(regex)];
                            const result = matches.map(match => match[1]);


                            let statusCheck = false

                            if (result.includes("New")) {
                                statusCheck = true
                            }



                            if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && rData.StatusName == 'InReview' && rData.IsLocked == false) || (statusCheck == true && rData.IsLocked == false)) {
                                Service.LockUnLockEntity('Configurationprop/LockEntity', "Rebate", id, loggedInUser).then((res1) => {
                                    if (typeof res1 !== 'undefined') {
                                        setLockedBy(loggedInUser);
                                        setIsLocked(true);
                                    }
                                })
                            }
                            else {
                                setLockedBy(rData.LockedBy);
                                setIsLocked(rData.IsLocked);
                            }
                            setSelectedSettlementFreq(rData.SettlementFreqName)
                        }
                    }
                }
            }

        });
    }
    const getRebatePrgramLines = (method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag) => {

        Service.getPostdatabyRebateIdSearch(method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data.DataList;

                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            setrebateProgramLine(res.data.DataList)
                            setshowspinner(false);
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                        }
                    }
                    rData.map((item) => {
                        if (item.Status == "Approved" || item.Status == "InReview" || item.Status == "Denied") {
                            setHasConfirmProgram([item])
                        }
                    })
                }
            }

        });

    }

    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getPostdatabyRebateIdSearch("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {


                    let rData = res.data.DataList

                    if (res.data.DataList.length === 0) {
                        alert(`${t('NoMoreDataAvailable')}`);
                    }
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            setrebateProgramLine(rebateProgramLine => [...rebateProgramLine, ...res.data.DataList])
                            const currentlength = rebateProgramLine.length;
                            LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                        }

                    }
                    rData.map((item) => {
                        if (item.Status == "Approved" || item.Status == "InReview" || item.Status == "Denied") {
                            setHasConfirmProgram(hasConfirmProgram => [...hasConfirmProgram, ...[item]])
                        }
                    })
                }
            }
        });
        paging.pageNumber = pagenumber
        setpaging(paging);
    };



    const getRebateWorkflowDetails = (id, pagesize, pagenumber) => {
        Service.getdatabyId('Rebate/GetRebateWorkflowById', id, pagesize, pagenumber).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            wData = res.data.DataList;

                            setWorkflowData(wData);
                            setshowspinnerworkflow(false)
                            setWoData('get data');
                        }
                    }
                }
            }
        });
    }


    const insertWorkflowDetails = (rplId, userId,) => {
        Service.workflowDataRebate('Rebate/InsertWorkflow', rplId, userId).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    if (res.status == 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                        }
                    }
                }
            }
        })
    }





    if (state.status == 'approved') {
        rebateApproved = 1
    } else if (state.status == 'submitted') {
        rebateApproved = 2
    } else {
        rebateApproved = 0
    }


    const OnChangeHandler = (e) => {

        let { name, value } = e.target;

        if (e.target.type === "checkbox") {
            value = e.target.checked
        }
        setChangeHandleHeader(true)
        setState({ ...state, [name]: value });

        if (e.target.name == "autoPostAccruals") {
            if (e.target.value == "true") {
                setState({ ...state, ["autoPostAccruals"]: true });
            } else {
                setState({ ...state, ["autoPostAccruals"]: false });
            }
        }



        // get salesOrg by customer ID
        if (name == "customerID") {
            Service.getSalesOrgbyCustId("GetSalesOrg", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {

                            let rData = res.data

                            if (rData !== 'null' && rData !== 'undefined') {
                                setSalesOrgData({ id: rData[0]['Id'], name: rData[0]['Name'] });
                            }
                        }
                    }
                }

            })
        }
    };



    const OnRegionChange = (e) => {
        setChangeHandle(true)
        let selected = [];
        const selectedId = [];
        let selectedOption = (e);
        for (let i = 0; i < selectedOption.length; i++) {
            const getallData = {
                id: 0,
                Id: selectedOption[i].Id,
                Name: selectedOption[i].Name,
                label: selectedOption[i].label
            }
            selected.push(getallData);
        }
        if (selected.length > 0) {
            selected = selected.filter((e) => e.label !== 'All')
        }
        if (selected.length == 0) {
            selected = [{ 'value': 'All', 'label': "All" }]
        }


        setSelectedOptionsRegion(selected)
    }

    const OnChangeProgramDetail = (e) => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked
        }
        setChangeHandle(true)


        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, [name]: value });


    }

    const OnSelectHandler = (e) => {
        setShowCreateEditRebatePgm(false)
        if (e.length <= 0) {

            setErrorMessage(`${t('PleaseSelect')} ${t('Rebates.Validations.ProgramLineYouWantToOpen')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)


        }
        else {
            setshowrebateProgramLine(true);
            navigate("/new-claimagreement/" + state.id + "/fromRebate/" + e)
            setshowrebateprogram(true);
            getRebateProgramLineDetails("GetRebateProgramLinesDetails", e)
        }
    };

    const OnChangeHandlerStartDate = (e) => {

        setChangeHandleHeader(true)
        let today = moment(new Date()).format(SessionManage.getdateformat());
        setState({ ...state, ['startDate']: e });


        if (moment(e).isBefore(today)) {

            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityFrom: today })
        } else {
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityFrom: e })
        }
    };

    const OnChangeHandlerEndDate = (e) => {
        let today = moment(new Date()).format(SessionManage.getdateformat());
        setState({ ...state, ['endDate']: e });
        setChangeHandleHeader(true)

        if (e) {
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityTo: e })
        }
    };

    const getRebateDefinitionDetails = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        rebateDefinitionDatail = res.data
                        if (rebateDefinitionDatail['is_new'] == true) {
                            setIsNew(true)
                            setVolumeRebateList(initial_value);
                            SetProducts([])
                            SetProductList([])
                        } else {
                            setIsNew(false)
                            SetProducts(rebateDefinitionDatail['product_list'])
                            SetOldProductList(rebateDefinitionDatail['product_list'])
                            SetFilteredProductList(FiltedProducts)
                            selectedVolumeRebateProduct.length = 0; // clear local stored value
                            Products.length = 0
                            UpdateProducts(rebateDefinitionDatail['product_list'])
                            setVolumeRebateList(rebateDefinitionDatail['volume_formula'])
                            FilterProductList();
                        }

                    }
                }
            }

        });
    }
    const setregionbyId = (selectedregion) => {

        let updateregion = [];
        let olddata = [...SalesLevelData];
        olddata.map((i) => {
            selectedregion.map((j) => {
                if (j.label === i.label) {
                    i.selected = true;
                }

            })

            updateregion.push(i);
        })

        setSalesLevelData(updateregion);
    }

    const getCountryData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setCountryData(res.data)
                        }
                    }
                }
            }

        });
    }


    const getRebateProgramLineDetails = (method, id) => {
        Service.getdatabyRebateProgramId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rData = res.data[0];

                            setrebateProgramLinedetail({
                                id: rData.Id,
                                rebateProgramLineId: rData.RebateProgramLineId,
                                rebateProgramDesc: rData.RebateProgram_Desc,
                                rebateTypeId: rData.Rebate_Type_Id,
                                rebateType: rData.Rebate_Type,
                                rebateDefinition: rData.Rebate_Definition,
                                rebateMeasureId: rData.Rebate_Measure_Id,
                                validityFrom: moment(rData.Validity_From).toDate(),
                                validityTo: moment(rData.Validity_To).toDate(),
                                settlementFreqId: rData.Settlement_Freq_Id,
                                settlementFreq: rData.Settlement_Freq,
                                active: rData.Active,
                                deleted: rData.Deleted,
                                statusId: rData.status_Id,
                                status: rData.Status,
                                geography: rData.salesOfficeView,
                                Rebate_Payout_Cond_Id: rData.Rebate_Payout_Cond_Id,
                                Rebate_Payout_Cond: rData.Rebate_Payout_Cond,
                                AccrualCalcTypeId: rData.AccrualCalcTypeId,
                                comparisondateformatId: rData.ComparisondateformatId,
                                comparisonStartDate: moment(rData.ComparisonStartDate).toDate(),
                                comparisonEndDate: moment(rData.ComparisonEndDate).toDate(),
                                AccrualCalcType: rData.AccrualCalcType,
                                RebateMeasureLabel: rData.RebateMeasureLabel,
                            })
                            setSubdivideTargetid(rData.SubdivideTargetid);
                            setHasSubdivideTarget(rData.HasSubdivideTarget);
                            setDefineMinThreshold(rData.DefineMinThreshold);
                            setMinThresholdValue(rData.MinThresholdValue);
                            setMinThresholdPayout(rData.MinThresholdPayout);
                            setHasPaymentTerms(rData.HasPaymentTerms);
                            setPaymentTermsId(rData.PaymentTermsId);
                            setShowTargetSelection(rData.SubdivideTargetName);
                            setRegionId(rData.RegionId);
                            setDistributorId(rData.DistributorId)
                            let selectedCustomers = customerData.filter((x) => x.Selected == true)
                            let regionData = [...rData.salesOfficeView]
                            for (var i = 0; i < regionData.length; i++) {
                                for (var j = 0; j < selectedCustomers.length; j++) {
                                    try {
                                        if (regionData[i].label.toLowerCase() == selectedCustomers[j].State.toLowerCase()) {
                                            if (customerData[j].Selected == true) {
                                                regionData[i]['avalRegion'] = true;
                                                break;
                                            }
                                        }
                                    }
                                    catch { }
                                }
                            }
                            regionData.map((each) => {
                                if (!(each.avalRegion)) {
                                    each['avalRegion'] = false
                                }
                            })

                            setSalesLevelData(regionData);
                            const ds = rData.apiSubdivideTargetTieritem;
                            setHfPayoutList(rData.apiSubdivideTargetTieritem);
                            setShowTargetTable(rData.HasSubdivideTarget);
                            let invoicePeriod = [];

                            if (rData.HasSubdivideTarget) {
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "FY", "RebateValue": "FY", });
                                setInvoicePeriodValue(invoicePeriodValue => [...invoicePeriod, ...rData.apiSubdivideTargetTieritem])
                            } else if (selectedSettlementFreq == "Annual") {
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "FY", "RebateValue": "FY", });
                                setInvoicePeriodValue(invoicePeriod)
                            }
                            else if (selectedSettlementFreq == "Halfyearly") {
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "H1", "RebateValue": "H1", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "H2", "RebateValue": "H2", });
                                setInvoicePeriodValue(invoicePeriod)
                            }
                            else if (selectedSettlementFreq == "Quarterly") {
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "Q1", "RebateValue": "Q1", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "Q2", "RebateValue": "Q2", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "Q3", "RebateValue": "Q3", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "Q4", "RebateValue": "Q4", });
                                setInvoicePeriodValue(invoicePeriod)
                            }
                            else if (selectedSettlementFreq == "Monthly") {
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M1", "RebateValue": "M1", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M2", "RebateValue": "M2", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M3", "RebateValue": "M3", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M4", "RebateValue": "M4", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M5", "RebateValue": "M5", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M6", "RebateValue": "M6", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M7", "RebateValue": "M7", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M8", "RebateValue": "M8", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M9", "RebateValue": "M9", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M10", "RebateValue": "M10", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M11", "RebateValue": "M11", });
                                invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "M12", "RebateValue": "M12", });
                                setInvoicePeriodValue(invoicePeriod)
                            }
                            setrebatelinestatus(rData.Status);
                            SetProducts(rData.Product_list);
                            SetFiltedProducts(rData.Product_list);
                            setclickProduct(false);
                            setVolumeRebateList(rData.Volume_Formula)
                            setRebateIcon(rData.Volume_Formula[0].Rebate_Value)
                            setCurrentWorkflowLevel(rData.CurrentWorkflowLevel)
                            setRebateTypeValue(rData.Rebate_Type_Name);
                            setSelectedOptionsRebateType(rData.Rebate_Type_Id)
                            setSelectedOptionsSettlFreq(rData.Settlement_Freq_Id)
                            setSelectedOptionsRebateMeasure(rData.Rebate_Measure_Id)
                            setSelectedOptionsRebateMeasureDisabled(rData.Rebate_Measure_Id)
                            setSelectedOptionsPayout(rData.Rebate_Payout_Cond_Id)
                            setSelectedOptionsAccrualCal(rData.AccrualCalcTypeId)
                            setSelectedOptionsRegion(rData.Geography)
                            setComparisonDateFormatName(rData.Comparisondateformat);
                            setSelectedOptionsCompDateFormat(rData.ComparisondateformatId)
                            if (rData.Status == 'New') {
                                insertWorkflowDetails(id, loggedInUser)
                            }
                            setTimeout(() => {
                                getRebateWorkflowDetails(id, paging.pageSize, paging.pageNumber)
                                getRebateWorkflowApprovers('Rebate/GetRebateWorkflowApprovers', id)
                            })

                            if (rData.Status == 'Revising')
                            {
                                setRevised(true);
                                let todayDate = new Date();
                                let vFrom = new Date(rData.Validity_From) ;
                                let vTo = new Date(rData.Validity_To);
                                if (vFrom>todayDate)
                                  {
                                     setValidityFromEditable(true);
                                  }
                                  if (vTo >todayDate)
                                  {
                                    setValidityToEditable(true);
                                  }
                            }
                            else
                            {
                                setRevised(false);
                            }
                            setDate([rData.Validity_From, rData.Validity_To])
                            setComparisonEndDate(moment(rData.ComparisonEndDate).toDate())
                            setComparisonStartDate(moment(rData.ComparisonStartDate).toDate())
                            getRebateMeasureData("RebateMeasure/GetAll/", rData.Rebate_Type_Id, 'rpl');
                            getRebatePayoutData("RebatePayout/GetAll", rData.Rebate_Type_Id)
                            getrebateCategoryData();
                            setSelectedRebateCategory(rData.RebateCategoryId);
                            if (rData.Comparisondateformat == 'customperiod') {
                                setComparisonDateEditable(true)
                            }
                            if (rData.Status == "Approved" || rData.Status == "InReview" || rData.Status == "Denied"|| rData.Status == "Revising")
                                setIsProgramLineConfirmed(1);
                            else
                                setIsProgramLineConfirmed(0);


                            if (rData.Rebate_Payout_Cond_Name === 'Targeted' || 'Stepped') {
                                setRebatePayoutCond(rData.Rebate_Payout_Cond_Name)
                            }
                            if (rData.Rebate_Definition === 'Quantity' || rData.Rebate_Definition === 'Growth in Qty (Abs)') {
                                setmeasureValueLabel('Unit')
                            }
                            else if (rData.Rebate_Definition === 'Revenue' || rData.Rebate_Definition === 'Growth in Rev (Abs)') {
                                setmeasureValueLabel('Amount')
                            }
                            else if (rData.Rebate_Definition === 'Growth in Qty (%)' || rData.Rebate_Definition === 'Growth in Rev (%)') {
                                setmeasureValueLabel('%')
                            }
                            else {
                                setmeasureValueLabel('')
                            }
                            setSaveProgramLine(true);
                        }
                    }
                }
            }

        });
    }






    if (isProgramLineConfirmed == 0) {
        if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
            disableProgramLineField = false;
        }
        else
            disableProgramLineField = true
    }

    else
        disableProgramLineField = true

    const UpdateProducts = (_SelectedProducts) => {
        setChangeHandle(true)

        setPrevSelectedTypeState(selectedType);

        if (rebateprogrmlinedetail.status === "New" || rebateprogrmlinedetail.status === undefined || rebateprogrmlinedetail.status === "") {
            SetFiltedProducts(_SelectedProducts);
            FilterProductList(_SelectedProducts);

        }
        let temp = false;
        inputField.addproductdata = temp
        setInputField(inputField);
        showpopupcontnent.value = 2;
        setshowpopupcontnent(showpopupcontnent);
        setshowproductpopup(false);
    }
    const removeById = (arr, id) => {

        const requiredIndex = arr.findIndex(el => {

            return el.key === id;
        });
        if (requiredIndex === -1) {
            return false;
        };
        return !!arr.splice(requiredIndex, 1);
    };

    const FilterProductList = (selectedProducts) => {

        let res = []
        let res2 = []
        let temp_prodList = []
        let temp_excludeProdList = []


        if (ProductList == undefined) {
            ProductList = [];
        } else {
            ProductList.length = 0;
        }
        ExcludedProductList.length = 0;
        temp_prodList.length = 0
        temp_excludeProdList.length = 0

        if (selectedProducts) {
            Service.postDataWithValue("Rebate/ProductSelectorByhierarchyUpdated", selectedProducts).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            let rData = res.data

                            let result = []
                            if (rData !== 'null' && rData !== 'undefined') {

                                rData.forEach((item) => {
                                    if (item) {
                                        temp_prodList.push(item)
                                    }
                                })
                            }
                            SetFiltedProducts(rData)


                        }
                    }
                }

            })
        }
    }





    const FetchProductList = (SelectedItem) => {
        let prod_list = []
        let temp = []
        SelectedItem.forEach((item) => {

            prod_list.push({ "title": item['title'], key: item['id'] })
        })
        return temp
    }



    const DeleteRebateProduct = (index, id, product) => {

        let data = {
            'proudct': product,
            'index': index,
            'header': state.id
        }

        if (id !== 0) {
            setLoading(true);
            Service.deletewithType("deleterebateproduct", id, data).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setLoading(false);
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else
                        setLoading(false);
                }

            });
        }

    }

    const DeleteVolumeTierItems = (id, type) => {

        let data = {
            'type': type,
            'header': state.id
        }
        if (id !== 0) {
            setLoading(true);
            Service.deletewithType("deletevolumetieritems", id, data).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setLoading(false);
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,

                        });

                    }
                } else
                    setLoading(false);

            });
        }

    }

    const RemoveProductFromListHandler = (index) => {
        if (disableProgramLineField == false) {
            let new_arr = Products.filter((i, idx) => idx !== index)
            DeleteRebateProduct(index, Products[index]['key'], Products[index]['title'])
            Products.length = 0
            UpdateProducts(new_arr)
            if (index == 0) {
                VolumeRebateList.length = 0
                setVolumeRebateList(initial_value);
            }
        } else {
            setErrorMessage(`${t('Rebates.Validations.CannotRemoveTheProductFromSubmittedDocument')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
    }


    const handleInputChange = (e, index) => {
        const {
            name,
            value
        } = e.target;
        setChangeHandle(true)

        const list = [...VolumeRebateList];
        if (name === 'Rebate_Value') {
            if (value === '%') {
                setRebateIcon(value)
                list[index]['Target_Measure'] = 'Invoice Price';
            }
            if (value === 'Select') {
                setRebateIcon('')
                list[index]['Target_Measure'] = ''
            }
            if (value === '$' || value === '₹') {
                setRebateIcon(value)
                list[index]['Target_Measure'] = 'Quantity';
            }
            if (value === 'LS') {
                setRebateIcon(value)
                list[index]['Target_Measure'] = 'Lumpsum';
            }
            list[index][name] = value;
            setVolumeRebateList(list);

            if (VolumeRebateList.length > 1) {
                let currentRebateValue = ''
                let currentTargetMeasure = ''
                currentRebateValue = VolumeRebateList[0].Rebate_Value
                currentTargetMeasure = VolumeRebateList[0].Target_Measure
                VolumeRebateList.forEach(i => {
                    if (i['Rebate_Value'] !== currentRebateValue) {
                        i['Rebate_Value'] = currentRebateValue
                    }
                    if (i['Target_Measure'] !== currentTargetMeasure) {
                        i['Target_Measure'] = currentTargetMeasure
                    }
                })

            }
        }
        else {
            list[index][name] = value;
            setVolumeRebateList(list);

        }
        if (name === 'Targetcondition') {
            const list = [...VolumeRebateList];
            for (var i = 0; i <= list.length - 1; i++) {
                list[i].Targetcondition = e.target.value
            }
        }
    }
    const AddprodHandler = () => {
        setOpen(!open);
        setShowModal(true);
    }

    const AddProductHandler = () => {

        if (dualListBoxOptionsByHeirarchy.length == 0) {
            Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {

                                setdualListBoxOptionsByHeirarchy(res.data)

                            }
                        }
                    }
                }

            });
        }


        setOpen(!open);
        setShowModal(true);
    }


    const AddVolume = () => {
        if (VolumeRebateList[0].Targetcondition == null || VolumeRebateList[0].Targetcondition == '' || VolumeRebateList[0].Targetcondition === 'Select') {
            setErrorMessage(`${t('Please Select')}${t('Rebates.TargetCond')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        else if (VolumeRebateList[0].Target_Measure && VolumeRebateList[0].Rebate_Value != null && (VolumeRebateList[0].Targetcondition != null && VolumeRebateList[0].Targetcondition != '' && VolumeRebateList[0].Targetcondition != 'Select')) {
            const _item = {
                id: 0,
                product: "",
                Targetcondition: VolumeRebateList[0].Targetcondition,
                Measure_Value: "",
                Target_Measure: VolumeRebateList[0].Target_Measure,
                Rebate: "",
                Rebate_Value: VolumeRebateList[0].Rebate_Value
            };
            setVolumeRebateList([...VolumeRebateList, _item]);
        }
        else {
            setErrorMessage(`${t('PleaseSelect')}${t('Rebates.Rebate')} ${'₹ / % / LS'}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
    }

    const RemoveVolume = (idx) => {
        setVolumeRebateList(VolumeRebateList.filter((item, index) => index !== idx));
        setChangeHandle(true)
    }
    const headerHeight = 50;
    const defaultColDef = {
        width: 130,
        editable: true
    };

    const SaveAndCloseHandler = () => {
        SaveRebateHeaderHandler('save');
        CloseHandler();
    }

    const CloseHandler = () => {
        navigate('/rebate-program/0')
    }
    const validateField = () => {
        let isValid = true;
        let startDate = new Date(state.startDate)
        let endDate = new Date(state.endDate)


        if (state.RegionId == 0 || state.RegionId == null) {
            setErrorMessage((`${t('Please Select')}  ${t('Rebates.Region')}  ${t('Rebates.Validations.InTheHeader')}`))
            isValid = false;
        }
        if (state.DistributorId == 0 || state.DistributorId == null) {
            setErrorMessage((`${t('Please Select')}  ${t('Rebates.DistributorName')}  ${t('Rebates.Validations.InTheHeader')}`))
            isValid = false;
        }
        if (state.currencyID == 0 || state.currencyID == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.Currency')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (state.setlMethodID == 0 || state.setlMethodID == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.SettlementMethod')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (state.setlFreqID == 0 || state.setlFreqID == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.Settlement-Freq')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (endDate.getTime() < startDate.getTime()) {
            setErrorMessage(`${t('Rebates.Validations.HeaderValidityFromIsGreaterThanTheValidityTo')}`)
            isValid = false;
        }

        return isValid
    }

    //save and submit function
    const SaveRebateHeaderHandler = (val) => {


        let isValid = validateField()

        if (isValid == true) {

            if (state.analysis == false)
                state.analysis = 'False';
            else
                state.analysis = 'True';

            let validFrom = moment(state.startDate).toDate()
            let validTo = moment(state.endDate).toDate()

            if (state.currencyID == '0')
                state.currencyID = null;
            if (state.setlFreqID == '0')
                state.setlFrID = null;
            if (state.RegionId == '0')
                state.RegionId = null;
            if (state.DistributorId == '0')
                state.DistributorId = null;
            let rebateData = {
                'Id': state.id,
                'Rebate_id': state.agreementId,
                'RebateName': state.agreementName,

                'Settlement_method_Id': state.setlMethodID,
                'Settlement_Frequency_Id': state.setlFreqID,
                'Status': state.status,
                'Validity_From': moment(validFrom).format("YYYY-MM-DD"),
                'Validity_To': moment(validTo).format("YYYY-MM-DD"),
                'Auto_Post_Accruals': state.autoPostAccruals,
                'Currency_Id': state.currencyID,
                'CreatedUserId': parseInt(loggedInUser),
                "LastModifiedUserId": parseInt(loggedInUser),

                'Agreementtype': 'ClaimAgreement',


                'RegionId': inputField.RegionId,
                'DistributorId': inputField.DistributorId

            }


            setLoading(true);
            Service.createorupdate("Rebate/CreateorUpdateClaimHeader", rebateData).then((res) => {

                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let rData = res.data
                        setLoading(false);

                        if (res.data.Status == 'Warning') {
                            setErrorStatus(res.data.Status)
                            setShowErrorModal(true);
                            setErrorMessage(res.data.Message);
                        } else {
                            if (saveProgramLine === false) {
                                toast.success('Rebate Header saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        }
                        getRebateHeaderDetails("Rebate/GetRebateHeader", rData.Id)
                    }
                } else
                    setLoading(false);
            });
            return true;
        }
        else {
            setErrorStatus("Error")
            setShowErrorModal(true);
            return false;
        }
    }


    const CopyHandler = () => {
        let rowData = {}

        Service.PostWithIdAndUser('Rebate/CopyRebate', props.id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && res.data !== 'undefined') {
                            copyID = res.data
                            navigate('/new-claimagreement/' + copyID + '/fromRebate/0')
                        }
                    }
                }
            }


        });
    }
    const CloseProductPicker = () => {

        setOpen(false);
        setShowProdModal(false);
        setShowExcludeModal(false)
    }

    const SelectedProductTypeHandler = (event) => {
        setChangeHandle(true)
        setSelectedType(event.target.value);

        if (event.target.value === 'Heirarchy') {
            setPrevSelectedTypeState("Attribute");
        } else {
            setPrevSelectedTypeState("Heirarchy");
        }
    }





    const showDeleteDialog = (flag) => {
        if (props.id == 0) {
            setErrorMessage(`${t('Rebates.Validations.PleaseSaveTheRecordBeforeDeleting')}`)
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        else {
            if (flag === 'open') {
                toggleModal(true)
            }
            if (flag === 'close') {
                toggleModal(false)
                let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
                for (let checkbox of checkedBoxes) {
                    checkbox.checked = false;
                }
                setMultiValueIds([])
                setDeleteshowbutton(false)
            }
            if (flag === 'proceed') {

                OnDeleteHandler(multiValueids)
                let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
                for (let checkbox of checkedBoxes) {
                    checkbox.checked = false;
                }
                setDeleteshowbutton(false)
            }
        }
    }




    const AccrualEligibleInvoice = (RebateProgramLineId) => {
        let RebateId = state.id;
        let CreatedUserId = loggedInUser;
        let createdFromRebate = true;
        let values = []

        if (accrualCustomerValue.length == 0) {
            for (let i = 0; i < customerInvoiceValue.length; i++) {
                const getallData = {
                    CustomerId: customerInvoiceValue[i].Id,
                    CustomerName: customerInvoiceValue[i].label,
                }
                values.push(getallData)
            }
        }
        else {
            values = accrualCustomerValue;
        }
        let selectedCustomers = values;
        let accrualPeriod = accrualPeriodValue;
        setLoading(true);
        setLoadingMsg("EligibleInvoice");
        let data = { RebateId, RebateProgramLineId, CreatedUserId, createdFromRebate, selectedCustomers, accrualPeriod }
        Service.createorupdate('Accurals/GenerateEligibleInvoice', data).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                setLoadingMsg("");
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data.status !== "Failure") {
                        toast.success('Generate eligible invoice successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate('/accruals/new/' + res.data.AccuralId + '/fromRebate')
                        });

                    }
                    else {
                        setErrorStatus("Error")
                        setErrorMessage(t('Rebates.Validations.FailedToCreateaccruals'))
                        setShowErrorModal(true)
                    }
                }
            }
            else {
                setLoading(false);
                setLoadingMsg("");
                setErrorStatus("Error")
                setErrorMessage(`${t('Rebates.Validations.FailedToCreateaccruals')}`)
                setShowErrorModal(true)
            }
        });

    }

    const AccrualAnalyzeRebate = (RebateProgramLineId) => {
        let RebateId = state.id;
        let CreatedUserId = loggedInUser;
        let createdFromRebate = true;
        let values = []
        if (accrualCustomerValue.length == 0) {
            for (let i = 0; i < customerInvoiceValue.length; i++) {
                const getallData = {
                    CustomerId: customerInvoiceValue[i].Id,
                    CustomerName: customerInvoiceValue[i].label,
                }
                values.push(getallData)
            }
        }
        else {
            values = accrualCustomerValue;
        }
        let selectedCustomers = values;
        let accrualPeriod = accrualPeriodValue;
        let IsAnalyzeRebate = true;
        setLoading(true);
        let data = { RebateId, RebateProgramLineId, CreatedUserId, createdFromRebate, selectedCustomers, accrualPeriod, IsAnalyzeRebate }

        Service.createorupdate('Accurals/GenerateEligibleInvoice', data).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data.status !== "Failure") {

                        toast.success('Analyze Rebate created successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate('/accruals/new/' + res.data.AccuralId + '/fromRebate/Analyze')
                        });


                    }
                    else {
                        setErrorStatus("Error")
                        setErrorMessage(t('Rebates.Validations.FailedToCreateaccruals'))
                        setShowErrorModal(true)
                    }
                }
                else {
                    setLoading(false);
                    setErrorStatus("Error")
                    setErrorMessage(`${t('Rebates.Validations.FailedToCreateaccruals')}`)
                    setShowErrorModal(true)
                }
            } else {
                setLoading(false);
            }


        });

    }

    const workflowAlert = (flag) => {
        if (flag == 'open') {
            if (workflowApprovers.length > 0) {
                setShowWorkflowAlert(true)
            }
            else {
                showSubmitDialog('showSubmitDialog')
            }
        }
        else if (flag == 'close') {
            setShowWorkflowAlert(false)
            showSubmitDialog('showSubmitDialog')
        }
    }



    const showSubmitDialog = (flag) => {
        if (flag === 'showSubmitDialog') {
            setShowSubmitModal(!showSubmitModal)
            let optList = SalesLevelData
        }
        else if (flag === 'proceed') {
            setShowSubmitModal(false)
            setshowrebateProgramLine(false);
            setChangeHandle(false)
            submitRebateProgramLine()

        }
    }

    const getAccrualHistoryDetails = (method, id) => {

        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let historyData = res.data
                    setAccrualHistoryData(historyData);
                }
                else {
                    setAccrualHistoryData([0])
                }
            }


        });

    }

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
        settotalselectedshow(true);
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length)
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - status
            setCheckedValueid(name) //check box value - RA-0000
        }
        else {
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }

            settotalselectedcount(checkedBoxes.length);
            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - status
            setCheckedValueid([]) //check box value - RA-0000
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
            setDeleteshowbutton(false)
        }
        else if (checkedBoxes.length > 1) {
            setshowbutton(false);
            setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
            setDeleteshowbutton(false)
        }
    }


    const saveRebateProgramLine = () => {

        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        let validFrom = ""
        let validTo = ""
        let programlineId = "0"

        if (rebateprogrmlinedetail.id == 0) {
            validFrom = valid_from
            validTo = valid_to
        } else {

            validFrom = valid_from;
            validTo = valid_to;
            programlineId = rebateprogrmlinedetail.rebateProgramLineId
        }
        if (rebateprogrmlinedetail.AccrualCalcTypeId == 0 || rebateprogrmlinedetail.AccrualCalcTypeId == null || rebateprogrmlinedetail.AccrualCalcTypeId == undefined) {
            rebateprogrmlinedetail.AccrualCalcTypeId = selectedOptionsAccrualCal;
        }
        let isValidProgramLine = ValidateProgramLine();
        if (isValidProgramLine == true) {

            let programLinedata = {
                "RebateProgramLinesViewDetails": {
                    "Id": rebateprogrmlinedetail.id,
                    "RebateProgramLineId": programlineId,
                    "RebateProgram_Desc": rebateprogrmlinedetail.rebateProgramDesc,
                    "Rebate_Type_Id": rebateprogrmlinedetail.rebateTypeId,
                    "Rebate_Type": rebateprogrmlinedetail.rebateType,
                    "Rebate_Definition": rebateprogrmlinedetail.rebateDefinition,
                    "Rebate_Measure_Id": selectedOptionsRebateMeasure,
                    "Validity_From": moment(validFrom).format("YYYY-MM-DD"),
                    "Validity_To": moment(validTo).format("YYYY-MM-DD"),
                    "Settlement_Freq_Id": state.setlFreqID,
                    "Settlement_Freq": rebateprogrmlinedetail.settlementFreq,
                    "Active": rebateprogrmlinedetail.active,
                    "Deleted": false,
                    "status_Id": rebateprogrmlinedetail.statusId,
                    "Status": rebateprogrmlinedetail.status,
                    "Rebate_Payout_Cond_Id": rebateprogrmlinedetail.Rebate_Payout_Cond_Id,
                    "Rebate_Payout_Cond": rebateprogrmlinedetail.Rebate_Payout_Cond,
                    "SalesLevel": "",
                    "CreatedBy": loggedInUser,
                    "LastModifiedUserId": loggedInUser,
                    "AccrualCalcTypeId": 1,
                    "MinThresholdPayout": minThresholdPayout === "" ? null : minThresholdPayout,
                    "RebateCategoryId": selectedRebateCategory == "" ? null : selectedRebateCategory,

                    "RegionId": RegionId,
                    "DisributorId": DistributorId
                },
                "VolumeFormula": VolumeRebateList,
                "Products": FiltedProducts,
                "Excludedproducts": ExcludedProductList,
                "Geography": SalesLevelData.filter((e) => e.selected === true),
                "apiSubdivideTargetTieritem": hfPayoutList

            }



            if (rebateprogrmlinedetail.id == 0) {
                setLoading(true);
                Service.create_or_update("CreateProgramLine", state.id, programLinedata).then((res) => {

                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {
                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                setLoading(false);
                                let rData = res.data
                                toast.success('Record saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                setLoading(false);
                                getRebateProgramLineDetails("GetRebateProgramLinesDetails", rData.Id)
                            } else {
                                setErrorMessage(res.data.ErrorMessage || res.data.ErrorProductList)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setLoading(false);
                            }
                        }
                        else {
                            setLoading(false);
                            setErrorMessage(`${t('Rebates.validations.ErrorWhileSavingTheRecord')}`)
                            setErrorStatus("Error")
                            setShowErrorModal(true)
                        }
                    } else {
                        setLoading(false);
                    }
                });
            } else {
                setLoading(true);
                Service.create_or_update("UpdateProgramLine", state.id, programLinedata).then((res) => {
                    if (typeof res !== 'undefined') {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {
                                toast.success('Record saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                setLoading(false);
                                getRebateProgramLineDetails("GetRebateProgramLinesDetails", rebateprogrmlinedetail.id);
                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                            } else {

                                setLoading(false);
                            }
                        } else {
                            toast.success('Record saved successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            setLoading(false);
                        }
                    } else
                        setLoading(false);

                });
            }
        }
        else {
            setErrorStatus("Error")
            setShowErrorModal(true)
            setLoading(false);
        }

    }



    const submitRebateProgramLine = () => {

        let isValid = validateField()

        if (isValid == true) {
            if (state.analysis == false)
                state.analysis = 'False';
            else
                state.analysis = 'True';


            if (state.currencyID == '0')
                state.currencyID = null;
            if (state.setlFreqID == '0')
                state.setlFreqID = null;
            if (state.RegionId == '0')
                state.RegionId = null;
            if (state.DistributorId == '0')
                state.DistributorId = null;
            let rebateData = {
                'Id': state.id,
                'Rebate_id': state.agreementId,
                'RebateName': state.agreementName,

                'Settlement_method_Id': state.setlMethodID,
                'Settlement_Frequency_Id': state.setlFreqID,
                'Status': state.status,
                'Validity_From': moment(state.startDate).format("YYYY-MM-DD"),
                'Validity_To': moment(state.endDate).format("YYYY-MM-DD"),
                'Auto_Post_Accruals': state.autoPostAccruals,
                'Currency_Id': state.currencyID,
                'CreatedUserId': parseInt(loggedInUser),
                "LastModifiedUserId": parseInt(loggedInUser),


                "CustomerGroup": state.CustomerGroup,

                'Agreementtype': 'ClaimAgreement',

                'RegionId': state.RegionId,
                'DistributorId': state.DistributorId

            }

            let valid_from = rebateprogrmlinedetail.validityFrom
            let valid_to = rebateprogrmlinedetail.validityTo
            let validFrom = ""
            let validTo = ""
            let programlineId = "0"
            if (rebateprogrmlinedetail.id == 0) {
                validFrom = valid_from
                validTo = valid_to
            } else {
                validFrom = valid_from;
                validTo = valid_to;
                programlineId = rebateprogrmlinedetail.rebateProgramLineId
            }

            if (rebateprogrmlinedetail.comparisondateformatId == 0 || rebateprogrmlinedetail.comparisondateformatId == null || rebateprogrmlinedetail.comparisondateformatId == undefined) {
                rebateprogrmlinedetail.comparisondateformatId = selectedOptionsCompDateFormat === null ? 0 : selectedOptionsCompDateFormat;
            }
            if (rebateprogrmlinedetail.AccrualCalcTypeId == 0 || rebateprogrmlinedetail.AccrualCalcTypeId == null || rebateprogrmlinedetail.AccrualCalcTypeId == undefined) {
                rebateprogrmlinedetail.AccrualCalcTypeId = selectedOptionsAccrualCal;
            }
            let isValidProgramLine = ValidateProgramLine();
            if (isValidProgramLine == true) {
                if (rebateprogrmlinedetail.comparisondateformatId == 0) {
                    rebateprogrmlinedetail.comparisondateformatId = null;
                }
                let csd = null;
                let ced = null;
                if (comparisonStartDate == null || comparisonStartDate == "" || comparisonStartDate == undefined) {
                    csd = null;
                }
                else {
                    csd = comparisonStartDate;
                }
                if (comparisonEndDate == null || comparisonEndDate == "" || comparisonEndDate == undefined || isNaN(comparisonEndDate)) {
                    ced = new Date();
                }
                else {
                    ced = comparisonEndDate;
                }


                let headerAndProgramLineData = {

                    "RebateProgramLinesViewDetails": {
                        "Id": rebateprogrmlinedetail.id,
                        "RebateProgramLineId": programlineId,
                        "RebateProgram_Desc": rebateprogrmlinedetail.rebateProgramDesc,
                        "Rebate_Type_Id": rebateprogrmlinedetail.rebateTypeId,
                        "Rebate_Type": rebateprogrmlinedetail.rebateType,
                        "Rebate_Definition": rebateprogrmlinedetail.rebateDefinition,
                        "Rebate_Measure_Id": selectedOptionsRebateMeasure,
                        "Validity_From": moment(validFrom).format("YYYY-MM-DD"),
                        "Validity_To": moment(validTo).format("YYYY-MM-DD"),
                        "Settlement_Freq_Id": rebateprogrmlinedetail.settlementFreqId,
                        "Settlement_Freq": rebateprogrmlinedetail.settlementFreq,
                        "Active": rebateprogrmlinedetail.active,
                        "Deleted": false,
                        "status_Id": rebateprogrmlinedetail.statusId,
                        "Status": rebateprogrmlinedetail.status,

                        "Rebate_Payout_Cond_Id": rebateprogrmlinedetail.Rebate_Payout_Cond_Id,
                        "Rebate_Payout_Cond": rebateprogrmlinedetail.Rebate_Payout_Cond,
                        "CreatedBy": loggedInUser,
                        "LastModifiedUserId": loggedInUser,
                        "Excluded_product_list": [],
                        "AccrualCalcTypeId": 1,
                        "ComparisondateformatId": rebateprogrmlinedetail.comparisondateformatId,
                        "ComparisonStartDate": moment(csd).format("YYYY-MM-DD"),
                        "ComparisonEndDate": moment(ced).format("YYYY-MM-DD"),
                        "DefineMinThreshold": defineMinThreshold,
                        "SubdivideTargetid": subdivideTargetid,
                        "HasSubdivideTarget": hasSubdivideTarget,
                        "MinThresholdValue": minThresholdValue === "" ? null : minThresholdValue,
                        "MinThresholdPayout": minThresholdPayout === "" ? null : minThresholdPayout,
                        "HasPaymentTerms": hasPaymentTerms,
                        "PaymentTermsId": paymentTermsId,
                        "RebateCategoryId": selectedRebateCategory,

                        "Agreementtype": 'ClaimAgreement',


                    },
                    "VolumeFormula": VolumeRebateList,
                    "Products": FiltedProducts,
                    "Excluded_product_list": ExcludedProductList,
                    "RebateHeader": rebateData,
                    "Geography": SalesLevelData.filter((e) => e.selected === true),
                    "apiSubdivideTargetTieritem": hfPayoutList

                }
                setLoading(true);
                Service.SubmitRebateProgramLine('Rebate/SubmitRebateProgramLine', rebateData.Id, "inreview", loggedInUser, commentsForSubmit, headerAndProgramLineData).then((res) => {

                    if (typeof res !== 'undefined') {
                        setLoading(false)
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {

                                setShowSubmitModal(!showSubmitModal)

                                toast.success('Submitted Successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,

                                });

                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")


                                setShowCreateEditRebatePgm(true)
                                setshowrebateprogram(false);
                                setCommentsForSubmit('')
                                setSaveProgramLine(false);
                            } else {
                                setShowSubmitModal(!showSubmitModal)
                                setErrorStatus("Error")
                                setErrorMessage(res.data.ErrorMessage || res.data.ErrorProductList)
                                setShowErrorModal(true)
                                setCommentsForSubmit('')
                            }

                        }
                    }
                    else {
                        setLoading(false)
                        setShowSubmitModal(!showSubmitModal)
                        setErrorStatus("Error")
                        setErrorMessage(`${t('Rebates.Validations.ErrorWhileSavingTheRecord')}`)
                        setShowErrorModal(true)
                        setCommentsForSubmit('')
                    }
                });
            }
            else {
                setLoading(false)
                setErrorStatus("Error")
                setShowSubmitModal(!showSubmitModal)
                setShowErrorModal(true);
                setCommentsForSubmit('')
            }

        }
        else {
            setLoading(false)
            setErrorStatus("Error")
            setShowSubmitModal(!showSubmitModal)
            setShowErrorModal(true);
        }


    }


    const ApproveRebateProgramLine = () => {
        let programlineid = rebateprogrmlinedetail.id;
        let userid = loggedInUser;// hardcoded right now
        setLoading(true);
        Service.UpdateProgramlineStatus('Rebate/UpdateProgramlineStatus', programlineid, "approved", userid, commentsForApproved).then((res) => {
            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {


                    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", props.id, loggedInUser).then((res) => {
                    });
                    toast.success('Approved Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/rebateapprovals/pendingaction/1")
                    });
                } else
                    setLoading(false);
            }
            else {
                setLoading(false);

            }

        });


    }

    const DenyRebateProgramLine = () => {
        let programlineid = rebateprogrmlinedetail.id;
        let userid = loggedInUser;// hardcoded right now
        setLoading(true);
        Service.UpdateProgramlineStatus('Rebate/UpdateProgramlineStatus', programlineid, "denied", userid, commentsForDenied).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", props.id, loggedInUser).then((res) => {
                    });


                    toast.success('Denied Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/rebateapprovals/pendingaction/2")
                    });
                } else
                    setLoading(false);
            } else {
                setLoading(false);
            }

        });

    }
    const showApprovedDialog = (flag) => {
        if (flag === 'showApprovedDialog') {
            setCommentsForApproved("")
            setShowApprovedModal(!showApprovedModal)
        }
        else if (flag === 'proceed') {
            ApproveRebateProgramLine()
            setShowApprovedModal(!showApprovedModal)

        }
    }


    const showDeniedDialog = (flag) => {
        if (flag === 'showDeniedDialog') {
            setCommentsForDenied("")
            setShowDeniedModal(!showDeniedModal)
        }
        else if (flag === 'proceed') {
            DenyRebateProgramLine()
            setShowDeniedModal(!showDeniedModal)

        }
    }


    const DeleteGeneratedClaims = (id, user,actionType) => {

        setToggleStatusModal1(false)
        setLoading(true)
        Service.postWithlineIdanduserId('Rebate/deleteGeneratedClaims', id, user).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data == 'Success') {
                            setLoading(false)
                            if (actionType == "Revise")
                              {
                                  setShowClaimDialogForRevise(false)
                                  ReviseProgramLine();
                              }
                              else
                              {
                            ToggleProgramLineActiveStatus(activeData, activeStatus)
                        }
                    }
                }
            }
            }
        })
    };

    const checkActiveStatus = (val, flag) => {
        setActiveData(val);
        setactiveStatus(flag)
        if (flag == 'Open-A') {
            Service.postWithData('Rebate/CheckClaimActiveStatus', val.Id).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {

                            if (res.data == "Delete Claim") {
                                setMessage(`${t('Rebates.Delete Claim?')}`)
                                setToggleStatusModal1(true)
                            }

                            else if (res.data == "Success") {
                                ToggleProgramLineActiveStatus(val, flag)
                            }
                            else if (res.data == "Failed") {
                                setMessage(`${t('ActivationError')}`)
                                setToggleStatusModal1(true)
                            }
                        }
                    }
                }
            });
        }
        else {
            ToggleProgramLineActiveStatus(val, flag)
        }
    }

    const ToggleProgramLineActiveStatus = (val, flag) => {

        setToggleId(val)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            if (val.Status != "New") {
                Service.activateRebateProgram('Rebate/ToggleProgramLineActiveStatus', state.id, toggleId.Id, loggedInUser).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (res.data.Status == "Success") {
                                    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                    getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")


                                    setToggleStatusModal(false)
                                }
                                else {
                                    setErrorMessage(t('Rebates.Validations.CannotActivateTheProgramLine.ActiveProgramLineForTheSameSalesLevel,ProductAndValidityAlreadyExists'));
                                    setShowErrorModal(true)
                                }
                            }
                        }
                    }

                });
            }
            else {
                setErrorMessage(`${t('Rebates.Validations.YouCannotActivateThisProgramLine')}`)
                setErrorStatus("Error")
                setShowErrorModal(true)

            }
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }

    }


    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {
            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {
                setMultiValueIds(checkedValue)
                showDeleteDialog('open')
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.defaultValue)

            }
            checkedMultiValueid.map(i => {
                if (i.includes('Approved') || i.includes('InReview') || i.includes('Denied')) {
                    setErrorDeleteModal(true)
                }
                else {
                    var z = i.split(",");
                    multiValueids.push(z[0])
                    showDeleteDialog('open')
                }
            })
        }
    }

    const OnDeleteHandler = (id) => {
        setLoading(true);
        Service.updatedatabyRebateProgramId('Rebate/DeleteProgramLine', id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        showDeleteDialog('close')
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,

                        });

                        setMultiValueIds([])
                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                        getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                    }
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }

        });
    }

    const setAccuralCaldefaultValue = (id) => {
        Service.getdatabyId("AccrualCalcType/GetById", id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    setSelectedOptionsAccrualCal(res.data.value)
                }
            }

        });
    }

    const setComparisiondefaultValue = (id) => {
        Service.getdatabyId("ComparisonDateFormat/GetById", id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    setSelectedOptionsCompDateFormat(res.data.value)

                    setChangeHandle(true)
                    let label = res.data.Name;
                    let value = res.data.Id;

                    setComparisonDateFormatName(label);
                    let valid_from = rebateprogrmlinedetail.validityFrom
                    let valid_to = rebateprogrmlinedetail.validityTo
                    if (label === 'lastfiscalyear' || label === 'last12months') {
                        getComparisonDate(label, valid_from);
                    }
                    else {
                        setComparisonStartDate('')
                        setComparisonEndDate('')
                        setComparisonDateEditable(true)
                    }
                }
            }

        });
    }

    const OnClickCreateRebateProgram = () => {
        getdataProductSelectorByAttribute(0);
        let valid = SaveRebateHeaderHandler('save');
        if (valid === true) {

            let today = moment(new Date());
            setshowrebateprogram(true);
            setShowCreateEditRebatePgm(false)
            setVolumeRebateList(initial_value);
            SetProducts([]);
            SetFiltedProducts([]);
            setIsProgramLineConfirmed(0);
            getrebateCategoryData();
            setAccuralCaldefaultValue(SessionManage.getAccrualCalc())
            setComparisiondefaultValue(SessionManage.getComparisonPeriod())
            setSelectedOptionsPayout();
            setSelectedOptionsRebateMeasure();
            setSelectedOptionsRebateMeasureDisabled();
            setSelectedOptionsRebateType();
            setmeasureValueLabel("");
            if (selectedOptionsSetlFreq.length !== 0) {
                setlFreqData.map((i) => {
                    if (selectedOptionsSetlFreq.label == i.label) {
                        setSelectedOptionsSettlFreq(i);
                    }
                })
            }

            if (moment(state.startDate).toDate() > moment(today).toDate()) {
                setDate([state.startDate, state.endDate])
                setrebateProgramLinedetail({
                    id: 0, rebateProgramLineId: 0, rebateProgramDesc: "",
                    rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, settlementFreqId: state.setlFreqID,
                    settlementFreq: "", active: true, deleted: false, statusId: 0, status: "", geography: [],
                    Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", validityFrom: moment(state.startDate).toDate(), validityTo: moment(state.endDate).toDate(),
                    comparisondateformatId: 0
                })
            }
            else {
                setDate([new Date(), state.endDate])
                setrebateProgramLinedetail({
                    id: 0, rebateProgramLineId: 0, rebateProgramDesc: "",
                    rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, settlementFreqId: state.setlFreqID,
                    settlementFreq: "", active: true, deleted: false, statusId: 0, status: "", geography: [],
                    Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", validityFrom: moment(today).toDate(), validityTo: moment(state.endDate).toDate(),
                    comparisondateformatId: 0

                })
            }
        }
        setrebatelinestatus('New');
        setSaveProgramLine(true);

        setSubdivideTargetid(0);
        setDefineMinThreshold(false);
        setHasPaymentTerms(false);
        setMinThresholdValue('');
        setMinThresholdPayout('');
        setPaymentTermsId(0);
        setShowTargetSelection('');

        setShowTargetTable(false)
        setHfPayoutList([])
        setSalesLevelData([])

        if (state.CustomerGroup == false) {
            getSalesOfficeDataNew("SalesOffice/GetBySalesOrgID")
        }
    }



    const CloseRebateProgrmLine = (flag) => {
        if (flag === "RPLOpen" && disableProgramLineField == false && changeHandle == true) {
            setCloseModal(true)
        }
        if (flag === "RPLClose" && disableProgramLineField == false && changeHandle == true) {
            paging.pageSize = 20
            paging.pageNumber = 1
            setpaging(paging)

            setshowrebateProgramLine(false);
            navigate("/new-claimagreement/" + state.id + "/fromRebate/0")
            getRebateHeaderDetails("Rebate/GetRebateHeader", state.id)
            var sortType = columnAscending === true ? 'Ascending' : 'Descending';
            getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

            setshowrebateprogram(false);
            setShowCreateEditRebatePgm(true);
            setLoadedFrom('fromRebate');
            setProductName('');
            setshowbutton(false);
            setCloseModal(false)
            setChangeHandle(false)

        }
        if (flag === "RPLCancel" && disableProgramLineField == false && changeHandle == true) {
            setCloseModal(false)
        }
        if (flag === "RPLOpen" && changeHandle == false) {
            paging.pageSize = 20
            paging.pageNumber = 1
            setpaging(paging)
            setshowrebateProgramLine(false);
            navigate("/new-claimagreement/" + state.id + "/fromRebate/0")
            getRebateHeaderDetails("Rebate/GetRebateHeader", state.id)

            var sortType = columnAscending === true ? 'Ascending' : 'Descending';
            getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

            setshowrebateprogram(false);
            setShowCreateEditRebatePgm(true);
            setLoadedFrom('fromRebate');
            setProductName('');
            setshowbutton(false);
            setCloseModal(false)
            setChangeHandle(false)
        }
    }
    const CloseLoadedFromApproval = () => {
        setshowrebateProgramLine(false);
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", props.id, loggedInUser).then((res) => {

        });
        if (loadedFrom === 'fromApproval') {
            navigate("/rebateapprovals/pendingaction/1");
        }
        else {
            navigate("/rebateapprovals/archive/1");
        }
    }
    const ValidateProgramLine = () => {

        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        let validFrom = ""
        let validTo = ""

        if (rebateprogrmlinedetail.id == 0) {
            validFrom = valid_from
            validTo = valid_to
        } else {
            validFrom = valid_from;
            validTo = valid_to;
        }



        let isValidProgramLine = true;

        let optList = SalesLevelData.filter((e) => e.selected === true)

        if (moment(validFrom).isBefore(state.startDate)) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
            isValidProgramLine = false;
        }
        else if (moment(validTo).isAfter(state.endDate)) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
            isValidProgramLine = false;
        }
        else if (moment(validTo).isBefore(moment(validFrom))) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityToShouldBeGreaterThanProgramValidityFrom'))
            isValidProgramLine = false;
        }

        else if (VolumeRebateList.length == 0) {
            setErrorMessage(`${t('Please Enter')} ${t('Rebates.Validations.VolumeRebateDetails')}`)
            isValidProgramLine = false;
        }
        else if (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') {
            if (VolumeRebateList.length == 1) {
                setErrorMessage(t('Rebates.Validations.TargettedPayoutConditionShouldHaveMoreThan1Tier'))
                isValidProgramLine = false;
            }
        }
        VolumeRebateList.forEach(item => {
            let idx = parseInt(VolumeRebateList.indexOf(item)) + 1
            if (item.Targetcondition == '' || item.Targetcondition == null || item.Targetcondition == 'Select') {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.TargetCond')} ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }
            else if (item.Measure_Value == '' || item.Measure_Value == null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.MeasureValue')} ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }
            else if (item.Rebate == '' || item.Rebate == 'USD' || item.Rebate == null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.Rebate')} ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }
            else if (item.Rebate_Value == '' || item.Rebate_Value == null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.RebateValue')} ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }
            else if (item.Target_Measure == '' || item.Target_Measure == null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.TargetMeasure')} ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }
            else if (item.Rebate_Value == "%" && item.Rebate > 100) {
                setErrorMessage(`${t('Rebates.Validations.RebateValueCannotExceed100')}  ${t('Rebates.Validations.InRow')} ${idx}`)
                isValidProgramLine = false;
            }


        })
        if (FiltedProducts.length == 0) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.Products')}`)
            isValidProgramLine = false;
        }

        if (rebateprogrmlinedetail.rebateProgramDesc == null || rebateprogrmlinedetail.rebateProgramDesc == '') {
            setErrorMessage(`${t('Please Enter')} ${t('Rebates.RebateProgramDesc')}`)
            isValidProgramLine = false;
        }
        else if (selectedOptionsRebateMeasure == 0 || selectedOptionsRebateMeasure == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.RebateMeasure')}`)
            isValidProgramLine = false;
        }
        else if (rebateprogrmlinedetail.settlementFreqId == 0 || rebateprogrmlinedetail.settlementFreqId == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.SettlementFreq')}`)
            isValidProgramLine = false;
        }

        else if (rebateprogrmlinedetail.rebateTypeId == 0 || rebateprogrmlinedetail.rebateTypeId == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.RebateType')}${t(rebateprogrmlinedetail.rebateTypeId)}`)
            isValidProgramLine = false;
        }
        else if (rebateprogrmlinedetail.Rebate_Payout_Cond_Id == 0 || rebateprogrmlinedetail.Rebate_Payout_Cond_Id == null) {
            setErrorMessage(`${t('Please Select')} ${t('Rebates.Rebate')} ${t('Rebates.PayoutCond')}`)
            isValidProgramLine = false;
        }

        else if (hasSubdivideTarget === true) {
            if (showtargetselection === '' || showtargetselection === null) {
                setErrorMessage(`${t('Please Select')} ${t('Rebates.DefineTargetBy')}`)
                isValidProgramLine = false;
            } else {
                hfPayoutList.forEach(item => {
                    var Targets = item.MeasureValue;
                    var Payout = item.RebateValue;
                    if (item.MeasureValue === '' || item.MeasureValue === null) {
                        setErrorMessage(`${t('Please Enter')} ${showtargetselection} ${t('Rebates.TargetValue')}`)
                        isValidProgramLine = false;
                    }

                    else if (item.RebateValue === '' || item.RebateValue === null) {
                        setErrorMessage(`${t('Please Enter')} ${showtargetselection} ${'%'} ${t('Rebates.PayoutValue')}`)
                        isValidProgramLine = false;
                    } else if (item.RebateValue !== '' && item.RebateValue !== null) {
                        if (VolumeRebateList.length > 0) {
                            if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                                if (parseFloat(item.RebateValue) >= parseFloat(VolumeRebateList[0].Rebate)) {
                                    setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${showtargetselection} ${t('Rebates.Validations.Payout%SetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                                    isValidProgramLine = false;
                                }
                            }
                        }
                    }

                });

                var total = 0;
                for (var i = 0; i < hfPayoutList.length; i++) {
                    if (hfPayoutList[i]['MeasureValue'] != '' && hfPayoutList[i]['MeasureValue'] != null)
                        total += parseFloat(hfPayoutList[i]['MeasureValue'])
                }
                if (total != 100) {
                    setErrorMessage(t('Rebates.Validations.SubdivideTargetValueCannotExceedOrBeLessThan100%'));
                    setErrorStatus("Error")
                    setShowErrorModal(true)
                    isValidProgramLine = false;
                }
            }
        }
        else if (hasPaymentTerms === true) {
            if (paymentTermsId === '' || paymentTermsId === null || paymentTermsId == '0') {
                setErrorMessage(`${t('Please Select')} ${t('Rebates.PaymentTerms')}`)
                isValidProgramLine = false;
            }
        }
        else if (defineMinThreshold === true) {
            if (minThresholdValue === '' || minThresholdValue === null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.ThresholdValue')}`)
                isValidProgramLine = false;
            }
            else if (minThresholdValue !== '' && parseFloat(minThresholdValue) > 100) {

                setErrorMessage(t('Rebates.Validations.PleaseMakeSureThatThresholdValueSetShouldAlwaysBeLessThan100'))
                isValidProgramLine = false;
            }
            else if (minThresholdPayout === '' || minThresholdPayout === null) {
                setErrorMessage(`${t('Please Enter')} ${t('Rebates.PayoutPercent')}`)
                isValidProgramLine = false;
            }

            else if (minThresholdPayout !== '' && minThresholdPayout !== null) {
                if (VolumeRebateList.length > 0) {
                    if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                        if (parseFloat(minThresholdPayout) >= parseFloat(VolumeRebateList[0].Rebate)) {
                            setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutPercentSetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                            isValidProgramLine = false;
                        }
                    }
                }
            }
        }

        return isValidProgramLine
    }
    const getActiveRebateProgramlines = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    setActiveRebateProgramLine(res.data)
                    setshowspinneraccural(false);
                }
            }

        });
    }
    const changeIcon = (e, id) => {
        setExpandAccruals(id)
        if (icon == 'fa fa-plus') {
            setIcon('fa fa-minus');
        }
        if (icon == 'fa fa-minus') {
            setIcon('fa fa-plus');
        }

    }
    const getTotalAccrualAmount = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    let rData = res.data;
                    setTotalAccrualMoney(res.data);
                }
            }
        });
    }

    const clickShowAccuralWorkflow = (val) => {
        let pageSize = 20
        let pageNumber = 1
        setshowproductpopup(false);
        setshowAccuralWorkflow(true);
        setsidebarwidth("70vw");
        if (val === "Accural") {
            setshowWorkflow(true);
        }
        else {
            setshowWorkflow(false);
        }
    }

    const clickShowWorkflow = (val, id) => {
        setshowproductpopup(false);
        setsidebarwidth("70vw");
        getRebateProgramLineDetails("GetRebateProgramLinesDetails", id);
        setTimeout(() => {
            getRebateWorkflowApprovers('Rebate/GetRebateWorkflowApprovers', id);
        }, 500);
        if (val === "Accural") {
            setshowWorkflow(true);
        }
        else if (val === "Workflow")
            setshowAccuralWorkflow(true);
        setshowWorkflow(false);
        getRebateWorkflowDetails(id, paging.pageSize, paging.pageNumber)
    }


    const clickCloseAccuralWorkflow = () => {
        setshowproductpopup(false);
        setshowAccuralWorkflow(false);
        setsidebarwidth("0vw");
        setshowregionpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
    }
    const clickShowregionPopup = (val) => {
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(true);
        setsidebarwidth("60vw");
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);

    }
    const clickShowProductPopup = (val) => {
        inputField.addproductdata = true;
        setshowAccuralWorkflow(false);
        setshowproductpopup(true);
        setsidebarwidth("70vw");

    }


    const clickSelectedShowRegionPopup = (val) => {
        let lstcopy = SalesLevelData.filter((e) => e.selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setRegionDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setShowSelectedRegionpopup(true);
        setShowSelectedProductpopup(false);
        setsidebarwidth("55vw");
    }
    const clickSelectedShowProductPopup = (val) => {

        let lstcopy = FiltedProducts

        let lst1 = [];
        setProductDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(true);
        setsidebarwidth("55vw");
    }




    const showproducts = (e, status) => {
        setShowProductLoadSpinner(true)
        getdataProductSelectorByAttribute(e);
        getRebateProgramLineDetails("GetRebateProgramLinesDetails", e)
        setrebatelinestatus(status);
        setsidebarwidth("55vw");
        setclickProduct(true);
        setTimeout(() => {
            setshowproductpopup(true);
            setShowProductLoadSpinner(false)
        }, 1000);
    }

    const OnCopyHandler = (id, status) => {
        setLoading(true);
        let pagesize = 20 * paging.pageNumber
        let pagenumber = 1
        if (id != 0 && status != 0) {
            Service.copybyRebateProgramId('Rebate/CopyProgramLine', state.id, id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    setLoading(false);
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        toast.success('Copy Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                        getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                    }
                    else {
                        setLoading(false);
                    }

                } else {
                    setLoading(false);
                }

            });

            paging.pageSize = 20
            paging.pageNumber = pagenumber
            setpaging(paging)
        }
        else {
            setErrorMessage(t('Rebates.Validations.PleaseSelectTheProgramLineYouWantToCopy'))
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
    }

    const OnChangeHandlerLineStartDate = (e) => {

        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['validityFrom']: e });
        if (rebateTypeValue == 'Growth in Rev' || rebateTypeValue == 'Growth in Vol') {
            if (comparisonDateFormatName == 'last12months' || comparisonDateFormatName === 'lastfiscalyear') {
                getComparisonDate(comparisonDateFormatName, e);
            }
        }
    };
    const OnChangeHandlerLineEndDate = (e) => {
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['validityTo']: e });
        let validFrom = rebateprogrmlinedetail.validityFrom;

    };
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value, "");

        }
        else {
            setSearchValue(null);
            getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null, "");
        }
    }


    const CloseAgreement = (flag) => {

        if (flag == "CloseHeader" && changeHandleHeader == true) {
            setCloseHeaderModal(true)
        }
        if (flag == "HeaderClose" && changeHandleHeader == true) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", state.id, loggedInUser).then((res) => {

            });
            navigate("/rebate-program/0")
            setCloseHeaderModal(false)
        }
        if (flag == "HeaderCancel" && changeHandleHeader == true) {
            setCloseHeaderModal(false)
        }
        if (flag == "CloseHeader" && changeHandleHeader == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", state.id, loggedInUser).then((res) => {
            });
            if (props.name.toLowerCase() == 'frommassactionrenew') {
                navigate('/MassActionList/')
            }
            else if (props.name.toLowerCase() == 'frommassactionsubmit') {
                navigate('/MassActionSubmitList/')
            }
            else {
                navigate("/rebate-program/0")
            }
        }
    }




    const getRebateWorkflowApprovers = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setWorkflowApprovers(res.data)
                    setWorkflowLevelCount(res.data.length)
                    setworkflowchart(res.data);

                }

            }

        });
    }
    const getAccrualCalcType = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setAccrualCalcTypeData(res.data)
                }
            }
        });
    }



    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue, "");

    }

    const OnChangeHandlerSetlMethod = (data) => {

        setChangeHandleHeader(true)
        setSelectedOptionsSetlMethod(data.Id)
        setState({ ...state, ['setlMethodID']: data.Id })

    }

    const OnChangeHandlerSetlFreq = (data) => {
        if (data.Name == 'SubDivideTarget') {
            setHasSubdivideTarget(true)
            setdisableSubDivideTarget(true)
        }
        setSelectedOptionsSetlFreq(data.Id)
        setState({ ...state, ['setlFreqID']: data.Id })
    }

    const OnChangeHandlerCurrency = (data) => {
        setSelectedOptionsCurrency(data.Id)
        setState({ ...state, ['currencyID']: data.Id })
        setChangeHandleHeader(true)
    }


    const OnChangeHandlerRebateType = (data) => {
        setChangeHandle(true)
        setRebateIcon("")
        setmeasureValueLabel("");
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateTypeId']: data.Id, ['rebateMeasureId']: 0, ['Rebate_Payout_Cond_Id']: 0, ['comparisondateformatId']: 0 })
        setRebateTypeValue(data.Name);
        setSelectedOptionsRebateType(data.Id)
        setSelectedOptionsRebateMeasure('')
        setSelectedOptionsRebateMeasureDisabled('')
        setSelectedOptionsPayout('')
        getRebateMeasureData("RebateMeasure/GetAll/", data.Id, 'RebateType');
        getRebatePayoutData("RebatePayout/GetAll", data.Id)
    }

    const OnChangeHandlerSettlFreqmethod = (data) => {
        setChangeHandle(true)
        setSelectedOptionsSettlFreq(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['settlementFreqId']: data.Id })
    }

    const OnChangeHandlerRebateMeasure = (data) => {

        setChangeHandle(true)
        setSelectedOptionsRebateMeasure(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateMeasureId']: data.Id })

        if (data.Name === 'Quantity' || data.Name === 'Growth in Qty (Abs)') {
            setmeasureValueLabel('Unit')
        }
        else if (data.Name === 'Revenue' || data.Name === 'Growth in Rev (Abs)') {
            setmeasureValueLabel('Amount')
        }
        else if (data.Name === 'Growth in Qty (%)' || data.Name === 'Growth in Rev (%)') {
            setmeasureValueLabel('%')
        }
        else {
            setmeasureValueLabel('')
        }
    }
    const OnChangeHandlerCategory = (data) => {

        setSelectedRebateCategory(data.Id);
    }
    const OnChangeHandlerPayout = (data) => {


        setSelectedOptionsPayout(data.Id)
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['Rebate_Payout_Cond_Id']: data.Id })

        setRebatePayoutCond(data.Name)
        if (data.Name === 'Stepped' || 'Targeted') {
            setTargetPayCond(false)
            setVolumeRebateList([{
                id: 0,
                product: "",
                Targetcondition: "",
                Measure_Value: "",
                Target_Measure: "",
                Rebate: "",
                Rebate_Value: ""
            }])
            setRebateIcon("")
        }
        if (data.label === 'Lumpsum') {
            setTargetPayCond(true)
            setVolumeRebateList([{
                id: 0,
                product: "",
                Targetcondition: "",
                Measure_Value: "",
                Target_Measure: "Lumpsum",
                Rebate: "",
                Rebate_Value: "LS"
            }])
            setRebateIcon("LS")
        }
        if (data.label === 'Unit') {
            setTargetPayCond(true)
            setVolumeRebateList([{
                id: 0,
                product: "",
                Targetcondition: "",
                Measure_Value: "",
                Target_Measure: "Quantity",
                Rebate: "",
                Rebate_Value: "₹"
            }])
            setRebateIcon("₹")
        }
        if (data.label === 'Percent') {
            setTargetPayCond(true)
            setVolumeRebateList([{
                id: 0,
                product: "",
                Targetcondition: "",
                Measure_Value: "",
                Target_Measure: "Invoice Price",
                Rebate: "",
                Rebate_Value: "%"
            }])
            setRebateIcon("%")
        }

        if (data.label === 'Select') {
            setTargetPayCond(true)
            setVolumeRebateList([{
                id: 0,
                product: "",
                Targetcondition: "",
                Measure_Value: "",
                Target_Measure: "",
                Rebate: "",
                Rebate_Value: ""
            }])
            setRebateIcon("")
        }

    }


    const OnChangeHandlerAccrualCal = (data) => {
        setChangeHandle(true)
        setSelectedOptionsAccrualCal(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['AccrualCalcTypeId']: data.Id })

    }


    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const InputDropDown = (e, name) => {
        setChangeHandle(true)
        setInputField({ ...inputField, [name]: e.value });
        if (name === 'RegionId') {
            Service.getdatabyCountryId('SDCommon/GetDistributorByCountry', e.value).then((res) => {
                if (typeof res !== 'undefined') {

                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let data = res.data

                        setInputField({ ...inputField, ['RegionId']: e.value })
                        setDistributorList(data)
                        setState({ ...state, ['RegionId']: e.value })
                    }
                }
            });
        }
        if (name === 'DistributorId') {

            Service.getdatabyId('SDCommon/GetCountryByDistributor', e.value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let data = res.data

                        setInputField({ ...inputField, ['RegionId']: data.value, ['DistributorId']: e.value })

                        setState({ ...state, ['DistributorId']: e.value, ['RegionId']: data.value })

                    }
                }
            });
        }
    };



    const OnPopupPeriodChange = (e) => {
        let selectedOption = (e);

        let data = ""
        for (let i = 0; i < selectedOption.length; i++) {

            data = selectedOption[i].RebatePeriod
        }
        setAccrualPeriodValue(data)
    }



    const OnPopupCustomerChange = (e) => {
        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {

            const getallData = {
                CustomerId: selectedOption[i].Id,
                CustomerName: selectedOption[i].Cust_label,
            }
            data.push(getallData)
        }

        setAccrualCustomerValue(data)
    }



    const filterInput = (e) => {
        let inputValue = []
        setfilteringvalue(e.target.value)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const FilterColumn = (name) => {

        var divtop1 = document.getElementById("table-RPL");
        var cardtop1 = document.getElementById("card-RPL");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setFilterCol(name)
        setshowfilterpopup(true)

        if (name === 'defn') {
            var testDiv = document.getElementById("defn");
            setfiltertext(t('Rebates.RebateDefinition'))
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }
        if (name === 'cat') {
            var testDiv = document.getElementById("cat");
            setfiltertext(t('Rebates.RebateCat'))
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }

        if (name === 'frequency') {
            setfiltertext(t('Rebates.AccrualFreq'))
            var testDiv = document.getElementById("frequency");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }

        if (name === 'rebateprogramline_id') {
            setfiltertext(t('Rebates.RebateProgramId'))
            var testDiv = document.getElementById("rebateprogramline_id");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");

        }
        if (name === 'desc') {
            setfiltertext(t('Rebates.RebateProgramDesc'))
            var testDiv = document.getElementById("desc");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");

        }
        if (name === 'valid_from') {
            setfiltertext(t('Rebates.ValidFrom'))
            var testDiv = document.getElementById("valid_from");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
        }

        if (name === 'valid_to') {
            setfiltertext(t('Rebates.ValidTo'))
            var testDiv = document.getElementById("valid_to");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
        }
        if (name === 'type') {
            setfiltertext(t('Rebates.RebateType'))
            var testDiv = document.getElementById("type");
            setdivleft(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px"));
            DropdownHandler(name)
        }
        if (name === 'status') {
            setfiltertext(t('Rebates.Status'))
            var testDiv = document.getElementById("status");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
        }
        if (name === 'active') {
            setfiltertext(t('Rebates.IsActive'))
            var testDiv = document.getElementById("active");
            setdivleft(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
            addnewfield(activeOption, name)
        }
        setshowfilterpopup(true)
    }



    const DropdownHandler = (key) => {
        setcurrentFilterKey(key)
        Service.getfilterDropdown('Rebate/GetRebateProgramlineFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }


    const addnewfield = (data, key) => {
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key];
        if (key != 'active') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }


    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, "");
            }

        }
        else {
            setshowfilterpopup(false)

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setfilteringvalue([])
            setDropdownData([])
            setDropdownChange([])
            setFilterDropdownData([])
            setFilterSearch("")
            setcurrentFilterKey("")
        }
    }


    const OpenRebate = (event, id, AgreementType) => {
        if (event.detail === 2) {
            navigate("/new-claimagreement/" + props.id + "/fromRebate/" + id)
            setshowrebateprogram(true);
            setSubdivideTargetid(0);
            setHasSubdivideTarget(false);
            setDefineMinThreshold(false);
            setHasPaymentTerms(false);
            setPaymentTermsId(0);
            setShowTargetSelection('');
            setRegioncheckAll(false);
            setShowTargetTable(false)
            setVolumeRebateList([])
            getRebateProgramLineDetails("GetRebateProgramLinesDetails", id)
            getdataProductSelectorByAttribute(id);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    const getComparisonFormatData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setComparisonFormatData(res.data)
                        }
                    }
                }
            }

        });
    }

    const OnChangeComparisonPeriod = (e) => {
        setChangeHandle(true)
        let label = e.Name;
        let value = e.Id;
        setSelectedOptionsCompDateFormat(value);
        setComparisonDateFormatName(label);
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, comparisondateformatId: value });
        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        if (label === 'lastfiscalyear' || label === 'last12months') {
            getComparisonDate(label, valid_from);
        }
        else {
            setComparisonStartDate('')
            setComparisonEndDate('')
            setComparisonDateEditable(true)
        }
    }


    const getComparisonDate = (label, valid_from) => {
        Service.getComparisonDate("Rebate/GetComparisonPeriod", label, moment(valid_from).format("yyyy-MM-DD")).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setComparisonPeriod(res.data);
                            let a = res.data.split("&&");
                            setComparisonStartDate(moment(a[0]).toDate());
                            setComparisonEndDate(moment(a[1]).toDate());
                            setComparisonDateEditable(false);
                        }
                    }
                }
            }

        });
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const CustomerStyles = {

        searchBox: {
            height: "150px",
            overflowY: "scroll"
        },

        chips: {
            fontSize: "12px",
            lineHeight: "13px",
            display: "block",
            marginTop: "5px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        },

    }

    const getdataByGroup = (method) => {
        Service.getdataByGroupAndType(method, '').then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    if (typeof res.data.settlementMethodsList !== 'undefined')
                        setSettlementMethodsList(res.data.settlementMethodsList);
                    if (typeof res.data.distributorList !== 'undefined')
                        setDistributorList(res.data.distributorList);
                    if (typeof res.data.currencyList !== 'undefined')
                        setCurrencyList(res.data.currencyList);
                    if (typeof res.data.productList !== 'undefined')
                        setProductList(res.data.productList);
                    if (typeof res.data.statusList !== 'undefined')
                        setStatusList(res.data.statusList);
                    // if (typeof props.id != 'undefined' && props.id != 0)
                    //     getUserDatabyId("SDAgreement/GetById", props.id);
                    else {

                        var NewItem = res.data.statusList.filter((item) => item.label === 'New');
                        if (NewItem.length > 0) {
                            setInputField((prevState) => ({
                                ...prevState,
                                ["StatusId"]: NewItem[0].value,
                            }));
                            setInputField((prevState) => ({
                                ...prevState,
                                ["Status"]: NewItem[0].label,
                            }));

                        }
                    }
                }
            }
        });
    }

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", state.id, loggedInUser).then((res) => {

        });

    }


    const disabledClass = false

    const onTargetChecked = (mycheckbox) => {
        setShowTargetSelection('');
        setHfPayoutList([]);
        setHasSubdivideTarget(mycheckbox.target.checked);
        if (mycheckbox.target.checked == true) {

        }
        else {
            setShowTargetTable(false)
        }
    }

    const onDefineMinThresholdChecked = (mycheckbox) => {
        setDefineMinThreshold(mycheckbox.target.checked);
        setMinThresholdValue('');
        setMinThresholdPayout('');
    }
    const onEnforcePaymentTermsChecked = (mycheckbox) => {
        setHasPaymentTerms(mycheckbox.target.checked);
        setPaymentTermsId(0);
        if (mycheckbox.target.checked == true) {

        }


    }

    const inputsMinimumThresholdHandler = (e, name) => {
        if (name === 'minThresholdValue')
            setMinThresholdValue(e.target.value)
        else
            setMinThresholdPayout(e.target.value)
    }


    const inputTargetHandler = (e, index, name, type) => {
        var newIndex = (index) + 1;
        let list = [];
        list = [...hfPayoutList];

        if (list.length >= newIndex) {
            list[index][name] = e.target.value;
        } else {
            let NewList = { MeasureValue: "", RebateValue: "" };
            list.push(NewList);
            list[index][name] = e.target.value;
        }

        if (name === 'RebateValue') {
            for (var i = 0; i < list.length; i++) {
                list[i][name] = e.target.value;
            }
        }
        setHfPayoutList(list);


    }

    const calculateMeasureValue = (e, index) => {
        const list = [...hfPayoutList];
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            if (list[i]['MeasureValue'] != '' && list[i]['MeasureValue'] != null)
                total += parseFloat(list[i]['MeasureValue'])
        }
        if (total > 100) {
            list[index]['MeasureValue'] = 0;
            setErrorMessage(t('Rebates.Validations.SubdivideTargetValueCannotExceedOrBeLessThan100%'));
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        setHfPayoutList(list);
    }

    const calculatePayout = (e, index) => {
        const list = [...hfPayoutList];

        if (list.length > 0) {
            var RebateValue = list[0]['RebateValue']
            if (VolumeRebateList.length > 0 && (RebateValue !== '' && RebateValue !== null)) {
                if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                    if (parseFloat(RebateValue) >= parseFloat(VolumeRebateList[0].Rebate)) {
                        setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${showtargetselection} ${t('Rebates.Validations.PayoutSetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                        setShowErrorModal(true)
                        for (var i = 0; i < list.length; i++) {
                            list[i]['RebateValue'] = 0;
                        }
                        setHfPayoutList(list);
                    }
                    if (VolumeRebateList[0].Rebate_Value === 'LS') {
                        let totalsum = 0
                        for (var i = 0; i < list.length; i++) {
                            totalsum += parseFloat(list[i]['RebateValue'])
                        }

                        if (parseFloat(totalsum) >= parseFloat(VolumeRebateList[0].Rebate)) {
                            setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${showtargetselection} ${t('Rebates.Validations.SumOfPayoutSetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                            setShowErrorModal(true)
                            for (var i = 0; i < list.length; i++) {
                                list[i]['RebateValue'] = 0;
                            }
                            setHfPayoutList(list);
                        }
                    }
                }
            }
        }
    }
    const calculateThreshold = (e, name) => {

        if (name === 'Value') {
            if (minThresholdValue !== '' && minThresholdValue !== null && parseFloat(minThresholdValue) > 100) {
                setErrorMessage(t('Rebates.Validations.PleaseMakeSureThatThresholdValueSetShouldAlwaysBeLessThan100') + '%')
                setErrorStatus("Error")
                setShowErrorModal(true)
                setMinThresholdValue(0)
            }
        } else if (name === 'Payout') {
            if (minThresholdPayout !== '' && minThresholdPayout !== null) {
                if (VolumeRebateList.length > 0) {
                    if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                        if (parseFloat(minThresholdPayout) >= parseFloat(VolumeRebateList[0].Rebate)) {
                            if (rebateIcon == '%') {
                                setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutPercentSetShouldAlwaysBeLessThanTheRebateValue')} ${t('Rebates.Validations.setAgainstTier')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setMinThresholdPayout(0);
                            }
                            else {
                                setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutSetShouldAlwaysBeLessThanTheRebateValue')} ${t('Rebates.Validations.setAgainstTier')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setMinThresholdPayout(0);
                            }
                        }
                    }
                }
            }
        }

    }

    const onChangeTargetSelection = (e) => {
        setHfPayoutList(e.subdivideTargetTieritemnew);
        setShowTargetSelection(e.name)
        setSubdivideTargetid(e.value);

        if (e.value === "Select") {
            setShowTargetTable(false)
        } else {
            setShowTargetTable(true)
            setShowTargetSelection(e.name)
        }
    }
    const onChangePaymentTermSelection = (e) => {
        setPaymentTermsId(e.value);
    };
    const checkAllRegionchange = (mycheckbox) => {
        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'region-check']");
        if (mycheckbox.target.checked == true) {
            setRegioncheckAll(true);

            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                if (i.avalRegion == true) {
                    i.selected = true;
                }

            })
            setSalesLevelData(olddata);

        }
        else {
            setRegioncheckAll(false);


            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                i.selected = false;
            })
            setSalesLevelData(olddata);
        }
    }

    const SaveHandler = (event) => {
        SaveRebateHeaderHandler('save');
        if (state.id !== '0') {
            if (saveProgramLine === true) {
                saveRebateProgramLine();
            }

        }
    }

    const UserfilterHandler = (e) => {
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }



    // -----------------------------------------------------------------


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)

        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 300)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 360)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'rebateAgreementProgramLine', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'rebateAgreementProgramLine', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'rebateAgreementProgramLine', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'rebateAgreementProgramLine', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }

    const ImportProgramLine = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        setLoading(true)
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const result = {};

                workbook.SheetNames.forEach((sheetName) => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { raw: false });
                    result[sheetName] = sheetData;
                });

                setJsonList(result);

                try {
                    Service.create_or_update("ImportProgramLine", state.id, result).then((res) => {

                        if (typeof res !== 'undefined') {
                            if (typeof res.data !== 'undefined' && res.data !== 'null') {


                                if (res.data.Status != 'Failure') {
                                    if (res.data.Status != 'FailureFile') {
                                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                        getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                        setLoading(false);
                                        let rData = res.data
                                        toast.success('Record imported successfully !', {
                                            position: toast.POSITION.TOP_RIGHT,
                                        });
                                        getRebateProgramLineDetails("GetRebateProgramLinesDetails", rData.Id)
                                    }
                                    else {
                                        setErrorMessage(res.data.ErrorMessage);
                                        setErrorStatus("Error")
                                        setShowErrorModal(true)
                                        setLoading(false);
                                    }
                                } else {
                                    setErrorMessage("Error while import");
                                    setErrorStatus("Error")
                                    setShowErrorModal(true)
                                    setLoading(false);

                                    var path = res.data.ErrorMessage;
                                    window.location = Service.getBaseUrl() + path;
                                }
                            }
                            else {
                                setLoading(false);
                                setErrorMessage(`${t('Rebates.validations.ErrorWhileSavingTheRecord')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                            }
                        } else {
                            setLoading(false);
                        }
                    });
                    navigate("/new-claimagreement/" + state.id + "/fromRebate/0")
                }
                catch (error) {

                }
            };

            reader.readAsBinaryString(file);


        }


    };
    const ValidateRevise = () => {
    Service.postWithData('Rebate/CheckClaimActiveStatus', rebateprogrmlinedetail.id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data == "Claim generated is in new state. Do you want to delete generated claims for this rebate ?") {
              setMessage(res.data)
              setShowClaimDialogForRevise(true)
            }
            else if (res.data == "Claim generated is in submitted state.Unable to deactivate rebate") {
              setMessage("")
              setToggleStatusModal2(true)
            }
            else if (res.data == "Success") {
              ReviseProgramLine()
            }
            else if (res.data == "") {
              ReviseProgramLine()
            }
          }
        }
      }
    });
  }

      const ReviseProgramLine = () => {
        Service.UpdateProgramlineStatus('Rebate/UpdateProgramlineStatus', rebateprogrmlinedetail.id, "revising", loggedInUser, 'Revised').then((res) => {
              if (res.status === 200) {
                toast.error('Revised Successfully !', {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
        getRebateProgramLineDetails("GetRebateProgramLinesDetails", rebateprogrmlinedetail.id);
    }




    return (

        <div className="container-fluid content-top-gap">
            {state.autoPostAccruals}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to={updateNav} id="headertxt">{t('Rebates.RebateAgreementList')}</Link></li>
                    <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Rebates.CreateNewClaimAgreement')}</li>
                </ol>
            </nav>
            {loading && loadingMsg == "EligibleInvoice" ? <><div className="loader-container"><div className="spinner"></div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "20vh", width: "100vw", fontWeight: "bold" }} className="spinnerMessage">{t('Rebates.Validations.AccrualCalculationInProgressPleaseWait')}</div>
            </div> </> : loading ? <><div className="loader-container"><div className="spinner"></div>

            </div> </> : ''}
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="new-claimagreement.csv" > </CSVLink>
            </div>
            <ToastContainer autoClose={1000} />
            {
                showAccuralWorkflow === true ?
                    <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

                        {showWorkflow === false ?
                            <div className="container" id="workflow">
                                <div className="row mt-3">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Workflow')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body" style={{ minHeight: "55vh" }}>

                                                {workflowchart.length > 0 ?
                                                    <div className='col-md-12'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                                                    <th className='font-center workflowchart-font'>Users</th>
                                                                    <th className='font-center workflowchart-font'>Approval</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    workflowchart.map((i) =>
                                                                        <tr key={i.Id}>
                                                                            <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                                                            <td className='font-center'>
                                                                                <ul >
                                                                                    {
                                                                                        i.lstworkflowapprover.map((j, index) => {
                                                                                            return <>

                                                                                                {
                                                                                                    index < 2 ?
                                                                                                        j.IsApproved === true ?
                                                                                                            <li className='workflowchartperson'>
                                                                                                                <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                    <br></br> {j.FullName}</span>
                                                                                                            </li>
                                                                                                            :
                                                                                                            <>
                                                                                                                <li className='workflowchartperson'>
                                                                                                                    <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                    <br></br> {j.FullName}
                                                                                                                </li>
                                                                                                            </>
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </>

                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        i.lstworkflowapprover.length > 2 ?
                                                                                            <li className='workflowchartperson tooltip_workflow'>
                                                                                                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                <br></br> {t('MoreUsers')}
                                                                                                <span class="tooltiptext">

                                                                                                    {
                                                                                                        i.lstworkflowapprover.map((k, index) => {
                                                                                                            return <span>
                                                                                                                {
                                                                                                                    index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                        &nbsp; {k.FullName}
                                                                                                                        <hr></hr></> : null
                                                                                                                }


                                                                                                            </span>
                                                                                                        })
                                                                                                    }

                                                                                                </span>
                                                                                            </li>
                                                                                            :
                                                                                            ''
                                                                                    }


                                                                                </ul>

                                                                            </td>

                                                                            <td className='font-center'>
                                                                                {
                                                                                    i.IsApproved === true ?
                                                                                        <span className='workflowrightcolor'>
                                                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                                                        </span>
                                                                                        : i.IsDenied == true ?
                                                                                            <span className='workflowrightcolor'>
                                                                                                <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                                                            </span>
                                                                                            : ''
                                                                                }
                                                                            </td>
                                                                        </tr>)

                                                                }
                                                            </tbody>

                                                        </table>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table table-bordered text-left mt-2">
                                                            <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>

                                                                    <th>{t('Rebates.Event')}</th>
                                                                    <th>{t('Rebates.CreatedDate')}</th>
                                                                    <th>{t('Rebates.User')}</th>
                                                                    <th>{t('Rebates.Comments')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    showspinnerworkflow === true ?

                                                                        <>
                                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                        </>
                                                                        :
                                                                        workflowData.length === 0 ?
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    {t('NoDataAvailable')}
                                                                                </td> <td></td></tr>
                                                                            :
                                                                            woData === '' || woData === 'undefined' ?
                                                                                <tr></tr> :
                                                                                workflowData.map((i) =>
                                                                                    <tr key={i.id}>
                                                                                        <td>{i.EventName}</td>
                                                                                        {i.CreatedDate === null ? <td></td> : <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                                                                        <td>{i.CreateUserName}</td>
                                                                                        <td>{i.Comments}</td>
                                                                                    </tr>)

                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            :
                            <div className="container" id="history">
                                <div className="row mt-3">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-8 text-left">
                                                        {t('Rebates.AccrualHistory')}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button className="btn btn-outline-dark  btn-sm" style={{ float: "right", width: "45px" }} onClick={clickCloseAccuralWorkflow} type="submit">{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ minHeight: "50vh" }}>
                                                <div className="container">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <span className="rebateheaderstyle"><i className="fa fa-globe globe"></i><span style={{ marginLeft: "12px" }}><label>{state.agreementId}</label></span></span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <span className="accuredamountlabel"><label>{t('Rebates.TotalAccruedAmount')}</label></span>
                                                            <span className="accuredamount"><label>{state.CurrencySymbol} {parseFloat(totalAccrualMoney).toFixed(2)}</label></span>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <table className="table table-bordered text-left" >
                                                            <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{t('Rebates.RebateProgram')}</th>
                                                                    <th>{t('Rebates.RebateProgramDesc')}</th>
                                                                    <th>{t('Rebates.AccruedAmount')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="table-body">

                                                                {
                                                                    showspinneraccural === true ?

                                                                        <>
                                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                        </>
                                                                        :
                                                                        activerebateProgramLine.length === 0 ?
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    {t('NoDataAvailable')}
                                                                                </td> <td></td></tr>
                                                                            :
                                                                            activerebateProgramLine.map((i, index) =>
                                                                                <>

                                                                                    <tr className="cell-1" key={index} data-toggle="collapse" data-target="#demo">
                                                                                        <td data-toggle="collapse" data-target="#demo">
                                                                                            {expandAccurals === index ?
                                                                                                <i className={icon} id={index} aria-controls="demo" aria-hidden="true" onClick={(event) => changeIcon(event, (index))}></i>
                                                                                                :
                                                                                                <i className="fa fa-plus" id={index} aria-controls="demo" aria-hidden="true" onClick={(event) => changeIcon(event, (index))}></i>
                                                                                            }
                                                                                        </td>
                                                                                        <td>{i.RebateProgramLineIdStr}</td>
                                                                                        <td>{i.RebateProgramLineDesc}</td>
                                                                                        <td>{i.Currency} {parseFloat(i.AccrualMoney).toFixed(2)}</td>
                                                                                    </tr>

                                                                                    {expandAccurals === index
                                                                                        ?
                                                                                        <>
                                                                                            <tr id="demo" key={index} className="collapse cell-1 row-child" style={{ backgroundColor: "light blue" }}>
                                                                                                <th></th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.AccrualId')}</th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.ApprovedDate')}</th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.AccruedAmount')}</th>
                                                                                            </tr>
                                                                                            {i.AccrualHistory.length !== 0 ?
                                                                                                i.AccrualHistory.map((item) =>
                                                                                                    <tr id="demo" key={index} className="collapse cell-1 row-child">
                                                                                                        <td></td>

                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}><Link to={`/accruals/new/${item.AccrualsId}/fromRebate`}>{item.AccrualsIdStr}&nbsp;</Link></td>
                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}>{moment(item.ApprovedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}>{i.Currency} {parseFloat(item.AccrualMoney).toFixed(2)}</td>

                                                                                                    </tr>) :
                                                                                                <tr></tr>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </>)

                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    :
                    ""
            }

            {
                showproductpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>
                                                <div style={{ minHeight: "66vh" }}>
                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            {clickProduct === false ?
                                                                <ProductTreeDialog
                                                                    show={showModal}

                                                                    onOkClick={UpdateProducts}
                                                                    onHide={() => CloseProductPicker}
                                                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                                                    rebatelinestatus={rebatelinestatus}
                                                                    dialogType="Product"
                                                                    SelectedData={FiltedProducts}
                                                                    dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                                                    selectedType={selectedType}
                                                                    programLineStatus={rebateprogrmlinedetail.status}
                                                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                >
                                                                    <div className={classes['Radiobutton-header']}>
                                                                        <div className={classes['Radiobutton-internal-div']} >
                                                                            <input type="radio" checked={selectedType === 'Attribute'}
                                                                                value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                            <input type="radio" checked={selectedType === 'Heirarchy'}
                                                                                onChange={SelectedProductTypeHandler}
                                                                                className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                                        </div>
                                                                    </div>
                                                                </ProductTreeDialog>
                                                                : ''
                                                            }

                                                        </div>

                                                    </div>




                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedProductpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>

                                                <div className='scrollbar-class'>

                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                    <tr>
                                                                        <th className="tableheader">

                                                                        </th>
                                                                        <th id="ProductId" >
                                                                            {t('Rebates.MaterialNo')}
                                                                        </th>
                                                                        <th id="Product" >
                                                                            {t('Rebates.ItemName')}
                                                                        </th>
                                                                        <th id="Attribute1" >
                                                                            Cat I
                                                                        </th>
                                                                        <th id="Attribute2" >
                                                                            Cat II
                                                                        </th>
                                                                        <th id="Attribute3" >
                                                                            Cat III
                                                                        </th>
                                                                        <th id="Attribute4" >
                                                                            Cat IV
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {dualListBoxOptionsByAttribute.filter((e) => e.Selected === true).map((listVal) =>
                                                                        <tr key={listVal.Id} >

                                                                            <td style={{ width: "30px" }}>

                                                                                {

                                                                                    <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                                                                }

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                                                            </td>
                                                                            <td style={{ width: "200px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute1Name}>{listVal.ProductAttribute1Name}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>

                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute2Name}>{listVal.ProductAttribute2Name}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>

                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute3Name}>{listVal.ProductAttribute3Name}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }} >

                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute4Name}>{listVal.ProductAttribute4Name}</div>


                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }

            <div className="row">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('Rebates.RebateHeader')}
                                </div>
                                <div className="col-md-5">


                                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }} onClick={OnClickCreateRebateProgram} type="submit">{t('NewProgram')}</button>
                                    {loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ?
                                        <button style={{ float: "right", width: "45px" }} className="btn  btn-update btn-sm ml-1" onClick={CloseLoadedFromApproval} type="submit">{t('Close')}</button>
                                        :
                                        <button className="btn  btn-update btn-sm ml-1" onClick={() => { CloseAgreement("CloseHeader") }} style={{ float: "right", width: "45px" }} type="submit">{t('Close')}</button>
                                    }
                                     {Service.editPerms(userPerms) == false ? "" : (disableProgramLineField === false || revised == true) ? (isLocked === true && lockedBy == loggedInUser) ? <button className="btn  btn-update btn-sm ml-1" onClick={SaveHandler} style={{ float: "right", width: "40px" }} type="submit">{t('Save')}</button>:  '' : ''}

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row mt-1">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">


                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left">{t('Rebates.AgreementId')}</label>
                                            <input disabled={true} value={state.agreementId} name="rebateProgramLineId" type="text" className="col-md-6 textbox-padding form-control" />
                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.AgreementName')}</label>
                                            <input name="agreementName" disabled={disableHeaderField} type="text" onChange={(event) => OnChangeHandler(event)} value={state.agreementName} autoComplete="off" className={`col-md-6 textbox-padding mt-2 form-control ${disabledClass}`} />

                                            <label className="col-md-6 mt-2 text-left" >{t('Rebates.Region')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={countryData}
                                                placeholder={t('Select')}
                                                value={countryData.filter(function (option) {
                                                    return option.value == inputField.RegionId;
                                                })}
                                                onChange={(event) => InputDropDown(event, "RegionId")}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                isDisabled={disableHeaderField}
                                            />
                                            {checkCustomerGroup === false ?
                                                <>
                                                    <label className="col-md-6 mt-2 text-left">{t('Rebates.DistributorName')}<span className={classes.mandatory}>*</span></label>
                                                    <Select
                                                        id="DistributorId"
                                                        className={disabledClass_dropdown}
                                                        options={distributorList}
                                                        placeholder={t('Select')}
                                                        value={distributorList.filter(function (option) {
                                                            return option.value === inputField.DistributorId;
                                                        })}
                                                        onChange={(event) => InputDropDown(event, 'DistributorId')}
                                                        isSearchable={true}
                                                        styles={Service.getSelectstyle()}
                                                        isDisabled={disableHeaderField}
                                                    />
                                                </>
                                                :
                                                <></>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">

                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left">{t('Rebates.StartDate')}</label>
                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="startDate" className={`drodownwidth form-control ${disabledClass}`} selected={state.startDate} disabled={disableHeaderField} onChange={(event) => OnChangeHandlerStartDate(event)}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.EndDate')}</label>

                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="endDate" className={`drodownwidth form-control mt-2 ${disabledClass}`} selected={state.endDate} disabled={disableHeaderField} onChange={(event) => OnChangeHandlerEndDate(event)}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.SettlementMethod')}<span className={classes.mandatory}>*</span></label>



                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                                                options={setlMethodData}
                                                placeholder={t('Select')}

                                                value={setlMethodData.filter(function (option) {
                                                    return option.value === `${selectedOptionsSetlMethod}`;
                                                })}
                                                onChange={OnChangeHandlerSetlMethod}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            />
                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.SettlementFreq')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                                                options={setlFreqData.filter(option => option.Name !== "SubDivideTarget")}
                                                value={setlFreqData.filter(function (option) {
                                                    return option.value === `${selectedOptionsSetlFreq}`;
                                                })}
                                                placeholder={t('Select')}
                                                onChange={OnChangeHandlerSetlFreq}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">

                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left">{t('Rebates.Currency')}<span className={classes.mandatory}>*</span></label>
                                            {

                                                state.currencyID === 0 || state.currencyID === null || state.currencyID === undefined ?
                                                    state.currencyID = defaultCurrency.Id
                                                    :
                                                    ''
                                            }

                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown fontsizedropdown ${disabledClass}`}
                                                options={currencyData}
                                                placeholder="Select"

                                                value={currencyData.filter(function (option) {
                                                    return option.value === `${selectedOptionsCurrency}`;
                                                })}
                                                onChange={OnChangeHandlerCurrency}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            />

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.AutoPostAccrual')}</label>

                                            <select className={`col-md-6 textbox-padding mt-2 form-control ${disabledClass}`} disabled={disableHeaderField} style={{ fontSize: "0.75rem" }} name="autoPostAccruals" onChange={(event) => OnChangeHandler(event)} value={state.autoPostAccruals}>
                                                <option key='0' value="true">Yes</option>
                                                <option key='1' value="false" selected="selected">No</option>
                                            </select>
                                            <input type="text" className={`col-md-6 textbox-padding mt-2 ${disabledClass}`} style={{ display: 'none' }} disabled={true} value={state.status} />


                                            <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                                            <input type="text" disabled={true} value={state.created_by} className="col-md-6 textbox-padding mt-2 form-control" />

                                            <label className="col-md-6 mt-2 text-left">{t('CreatedDate')}</label>
                                            <input type="text" className="col-md-6 textbox-padding mt-2 datetimepicker-input form-control" data-target="#reservationdate" disabled={true}
                                                name="createdDate" placeholder="CreatedDate" value={state.createdDate} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showrebateprogram === true ?
                    <div className="row mt-3" id="rebateProgramdiv">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                    <div className="row">
                                        <div className="col-md-7 text-left">
                                            {t('Rebates.RebateProgram')}
                                        </div>
                                        <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : (disableProgramLineField === false || revised == true )?
                                            <button type="button" style={{ float: "right", width: "50px" }} className="btn btn-sm btn-update ml-1" onClick={(event) => workflowAlert('open')}>{t('Submit')}</button>
                                            : ''
                                         }
                                         {Service.editPerms(userPerms) == false ? "" : (rebateprogrmlinedetail.status == 'Approved' || rebateprogrmlinedetail.status == 'Denied')?
                                           <button type="button" style={{ float: "right", width: "50px" }} className="btn btn-sm btn-update ml-1" onClick={(event) => ValidateRevise()}>{t('Revise')}</button>
                                                    :''
                                         }
                                            {loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ?
                                                <button type="button" style={{ float: "right", width: "45px" }} className="btn btn-sm btn-update ml-1" onClick={CloseLoadedFromApproval}>{t('Close')}</button>
                                                :
                                                <button type="button" style={{ float: "right", width: "45px" }} className="btn btn-sm btn-update ml-1" onClick={() => CloseRebateProgrmLine("RPLOpen")}>{t('Close')}</button>}
                                            {Service.editPerms(userPerms) == false ? "" :
                                                (loadedFrom === 'fromApproval' && rebateprogrmlinedetail.status === 'InReview') ?
                                                    (isLocked === true && lockedBy.toString() === loggedInUser) ?
                                                        <>
                                                            <button type="button" style={{ float: "right", width: "55px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                                                            <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                                                        </>
                                                        : ""
                                                    : <></>}
                                            <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "65px" }} onClick={() => { clickShowAccuralWorkflow("Workflow") }} type="submit">{t('Workflow')}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-2 text-left" style={{ paddingTop: "5px" }}>{t('Rebates.ProgramId')}</label>
                                            <input id="aid" disabled={true} value={rebateprogrmlinedetail.rebateProgramLineId} type="text" name="rebateProgramLineId" className="col-md-2 textbox-padding mt-2" />


                                            <label className="col-md-2 mt-2 text-left" style={{ paddingBottom: "10px" }}>{t('Rebates.RebateType')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                options={rebateTypeData.filter(option => option.label !== "Growth in Volume" && option.label !== "Growth in Revenue")}
                                                placeholder="Select"
                                                value={rebateTypeData.filter(function (option) {
                                                    return option.value === `${selectedOptionsRebateType}`;
                                                })}

                                                onChange={OnChangeHandlerRebateType}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}

                                            />
                                            <label className="col-md-2  mt-2 text-left">{t('Rebates.StartDate')}</label>

                                            <div className='col-md-2 mt-2 text-left textbox-padding'>
                                                <DatePicker name="validityFrom" className={`drodownwidth ${disabledClass}`} selected={rebateprogrmlinedetail.validityFrom} disabled={disableProgramLineField  && (validityFromEditable == false)} onChange={(event) => OnChangeHandlerLineStartDate(event)}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>


                                            <label className="col-md-2  text-left" style={{ paddingTop: "5px" }}>{t('Rebates.Description')}<span className={classes.mandatory}>*</span></label>
                                            <input value={rebateprogrmlinedetail.rebateProgramDesc} autoComplete="no" name="rebateProgramDesc" disabled={disableProgramLineField} type="text"
                                                className={`col-md-2 textbox-padding mt-2 ${disabledClass}`} onChange={(event) => OnChangeProgramDetail(event)} />

                                            <div style={{ display: "none" }}>
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                    options={accrualCalcTypeData}
                                                    placeholder="Select"

                                                    value={accrualCalcTypeData.filter(function (option) {
                                                        return option.value === `${selectedOptionsAccrualCal}`
                                                    })}
                                                    onChange={OnChangeHandlerAccrualCal}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableProgramLineField}

                                                />
                                            </div>

                                            <label className="col-md-2 mt-2 text-left" style={{ paddingBottom: '7px' }}>{t('Rebates.RebateMeasure')}<span className={classes.mandatory}>*</span></label>
                                            {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ?
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                    options={rebateMeasureData}
                                                    placeholder="Select"

                                                    value={rebateMeasureData.filter(function (option) {
                                                        return option.value === `${selectedOptionsRebateMeasure}`
                                                    })}
                                                    onChange={OnChangeHandlerRebateMeasure}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableProgramLineField}

                                                />
                                                :
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                    options={rebateMeasureData}
                                                    placeholder="Select"
                                                    value={rebateMeasureData.filter(function (option) {
                                                        return option.value === `${selectedOptionsRebateMeasure}`
                                                    })}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={true}

                                                />
                                            }

                                            <label className="col-md-2  mt-2 text-left">{t('Rebates.EndDate')}</label>
                                            <div className='col-md-2 mt-2 text-left textbox-padding'>
                                                <DatePicker name="validityTo" className={`drodownwidth ${disabledClass}`} selected={rebateprogrmlinedetail.validityTo} disabled={disableProgramLineField && (validityToEditable==false)} onChange={(event) => OnChangeHandlerLineEndDate(event)}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>


                                            {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ? <>
                                            </> : ''}


                                            <label className="col-md-2 mt-2 text-left" >{t('Rebates.PayoutCond')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                options={rebatePayoutCondData}
                                                placeholder="Select"


                                                value={rebatePayoutCondData.filter(function (option) {
                                                    return option.value === `${selectedOptionsPayout}`
                                                })}
                                                onChange={OnChangeHandlerPayout}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}

                                                name="Rebate_Payout_Cond_Id"
                                            />

                                            <div className="col-md-2 mt-2 text-left">
                                                <label>{t('Rebates.Products')}<span className={classes.mandatory}>*</span></label>
                                            </div>
                                            <div className="col-md-2 mt-2 text-left">
                                                <button className="btn btn-outline-dark  btn-sm ml-2" onClick={clickShowProductPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                <br />

                                                <a href='javascript:void(0)' onClick={clickSelectedShowProductPopup}><label style={{ paddingTop: "5px" }}>({FiltedProducts.length}) {t('Rebates.ProductsAdded')}</label></a>
                                            </div>
                                            <label className="col-md-2 mt-2 text-left" >{t('Rebates.RebateCat')}</label>
                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                options={rebateCategoryData}
                                                placeholder="Select"


                                                value={rebateCategoryData.filter(function (option) {
                                                    return option.value === `${selectedRebateCategory}`
                                                })}
                                                onChange={OnChangeHandlerCategory}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}

                                                name="Rebate_Payout_Cond_Id"
                                            />
                                        </div>

                                        <div className="row">
                                        </div>
                                    </div>

                                    <div className="col-lg-12 pr-lg-2 mt-3">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-8 text-left">
                                                        {t('Rebates.Rebate-Definition')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ minHeight: "27vh" }}>
                                                <div className="container">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "700px" }}>
                                                            <thead className="thead-light">
                                                                <tr className='text-left'>
                                                                    <th>{t('Rebates.TargetCond')}</th>
                                                                    <th>{t('Rebates.MeasureValue')}</th>
                                                                    <th>{t('Rebates.Rebate')} ₹/%/LS</th>
                                                                    <th>{t('Rebates.RebateValue')}</th>
                                                                    <th>{t('Rebates.TargetMeasure')}</th>
                                                                    {disableProgramLineField == false && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                                                                        <th></th>
                                                                        :
                                                                        ''
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    VolumeRebateList.map((item, index) => (
                                                                        <tr >
                                                                            <td><Form.Control style={{ width: "100px" }} onChange={(Event) => handleInputChange(Event, index)} className={`${classes['Form-Input-control']}  ${disabledClass}`} as="select" disabled={index !== 0 || (disableProgramLineField && revised == false)} value={item.Targetcondition} name="Targetcondition">
                                                                                <option>Select</option>
                                                                                <option>&gt;</option>
                                                                                <option>&ge;</option>
                                                                            </Form.Control></td>
                                                                            <td style={{ width: "180px" }}>

                                                                                <NumericFormat type="text"
                                                                                    className={`${classes['Form-Input-control']}  ${disabledClass} form-control`}
                                                                                    value={item.Measure_Value}
                                                                                    style={{ width: "100px", display: 'inline-block' }}
                                                                                    thousandsGroupStyle={SessionManage.getLocale() == 'Indian' ? "lakh" : "thousand"}
                                                                                    thousandSeparator=","
                                                                                    onChange={(Event) => handleInputChange(Event, index)}
                                                                                    disabled={disableProgramLineField && revised == false}
                                                                                    name="Measure_Value"
                                                                                /> {measureValueLabel}
                                                                            </td>
                                                                            <td><Form.Control style={{ width: "100px" }} className={`${classes['Form-Input-control']}  ${disabledClass}`} as="select" disabled={index !== 0 || (disableProgramLineField && revised == false) || targetedPayCond} value={item.Rebate_Value} defaultValue={item.money} name="Rebate_Value"
                                                                                onChange={(Event) => handleInputChange(Event, index)}>
                                                                                <option>Select</option>
                                                                                <option>%</option>
                                                                                {rebateTypeValue == 'Growth in Rev' || rebateTypeValue == 'Revenue' ? '' :
                                                                                    <option>₹</option>}
                                                                                <option>LS</option>

                                                                            </Form.Control></td>
                                                                            <td>
                                                                                <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled={disableProgramLineField && revised == false} value={item.Rebate} name="Rebate"
                                                                                    onChange={(Event) => handleInputChange(Event, index)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} /></td>
                                                                            <td>    <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled={true || (disableProgramLineField && revised == false)} value={item.Target_Measure} name="Target_Measure"
                                                                                onChange={(Event) => handleInputChange(Event, index)} /></td>

                                                                            {(disableProgramLineField == false && revised == false) && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                                                                                <td>
                                                                                    <div className={classes['gridwidth']} style={{ margin: "5px" }}>

                                                                                        {Service.editPerms(userPerms) == false ? "" : VolumeRebateList.length - 1 === index ? <a onClick={() => AddVolume()} className="btn btn-outline-dark  btn-sm">Add</a> : null}
                                                                                        {Service.editPerms(userPerms) == false ? "" : index !== 0 && VolumeRebateList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-outline-dark  btn-sm">Remove</a> : null}

                                                                                    </div>
                                                                                </td>
                                                                                : null}
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {selectedSettlementFreq == 'SubDivideTarget' && (includeSubDivideTarget == true || includeSubDivideTarget == "true") ?
                                                        <>
                                                            <div class="row">
                                                                <div className="col-lg-3 ">
                                                                    {hasSubdivideTarget == true ?
                                                                        <>

                                                                            <label className=" mt-2 text-right">{t('Rebates.DefineTargetBy')}<span className={classes.mandatory}>*</span></label>&nbsp;&nbsp;&nbsp;
                                                                            <Select className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" + disabledClass}

                                                                                id="subdivideTargetTypeId"
                                                                                style={{ display: "inline-block" }}
                                                                                options={subdivideTargetTypeList} isDisabled={disableProgramLineField}
                                                                                placeholder="Select"
                                                                                onChange={(event) => onChangeTargetSelection(event)}
                                                                                isSearchable={true}
                                                                                styles={customStyles}
                                                                                value={subdivideTargetTypeList.filter(function (option) {
                                                                                    return option.name === `${showtargetselection}`;
                                                                                })}

                                                                            />

                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-12 mt-2 ">
                                                                    {showtargettable === true ?
                                                                        <div className="table-responsive">
                                                                            <table style={{ width: "63%" }} className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped">
                                                                                <thead className="thead-light">
                                                                                    {showtargetselection === "Quarterly%" ? <>
                                                                                        <tr className='text-left'>
                                                                                            <th>{t('Rebates.Quarterly')}</th><th>Q1</th><th>Q2</th><th>Q3</th><th>Q4</th></tr>

                                                                                        {hfPayoutList.length > 3 ?
                                                                                            <>
                                                                                                <tr className='text-left'>
                                                                                                    <th>
                                                                                                        {`${t('Rebates.QuarterlyTargets')} ${"%"}`}
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 0, 'MeasureValue', 'qtr')} value={hfPayoutList[0].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 1, 'MeasureValue', 'qtr')} value={hfPayoutList[1].MeasureValue}></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 2)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 2, 'MeasureValue', 'qtr')} value={hfPayoutList[2].MeasureValue}></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 3)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 3, 'MeasureValue', 'qtr')} value={hfPayoutList[3].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className='text-left'>
                                                                                                    <th>
                                                                                                        {`${t('Rebates.QuarterlyPayouts')} ${(rebateIcon)}`}
                                                                                                    </th>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 0, 'RebateValue', 'qtr')} value={hfPayoutList[0].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 1, 'RebateValue', 'qtr')} value={hfPayoutList[1].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 2)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 2, 'RebateValue', 'qtr')} value={hfPayoutList[2].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 3)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => inputTargetHandler(event, 3, 'RebateValue', 'qtr')} value={hfPayoutList[3].RebateValue}  ></Form.Control></td>

                                                                                                </tr> </> : ""}

                                                                                    </> : ""}
                                                                                    {showtargetselection === "HalfYearly%" ? <>
                                                                                        <tr className='text-left'>
                                                                                            <th style={{ width: "20%" }}>{t('Rebates.HalfYearly')}</th><th>H1</th><th>H2</th>
                                                                                        </tr>
                                                                                        {hfPayoutList.length > 1 ?
                                                                                            <>
                                                                                                <tr className='text-left'>
                                                                                                    <th>
                                                                                                        {`${t('Rebates.HalfYearlyTargets')} ${"%"}`}
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px", margin: "auto" }} value={hfPayoutList[0].MeasureValue} onChange={(event) => inputTargetHandler(event, 0, 'MeasureValue', 'hqtr')} name="MeasureValue" ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px", margin: "auto" }} value={hfPayoutList[1].MeasureValue} onChange={(event) => inputTargetHandler(event, 1, 'MeasureValue', 'hqtr')} name="MeasureValue"></Form.Control>
                                                                                                    </td>
                                                                                                </tr>

                                                                                                <tr className='text-left'>
                                                                                                    <th>
                                                                                                        {`${t('Rebates.HalfYearlyPayouts')} ${(rebateIcon)}`}
                                                                                                    </th>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px", margin: "auto" }} value={hfPayoutList[0].RebateValue} onChange={(event) => inputTargetHandler(event, 0, 'RebateValue', 'hqtr')} name="RebateValue"  ></Form.Control></td>
                                                                                                    <td><Form.Control disabled={true} onBlur={(event) => calculatePayout(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px", margin: "auto" }} value={hfPayoutList[1].RebateValue} onChange={(event) => inputTargetHandler(event, 1, 'RebateValue', 'hqtr')} name="RebateValue"   ></Form.Control></td>

                                                                                                </tr>
                                                                                            </>

                                                                                            : ""} </> : ""}


                                                                                    {showtargetselection === "Monthly%" ? <>
                                                                                        <tr className='text-left'>
                                                                                            <th>{t('Rebates.Monthly')}</th><th>M1</th><th>M2</th><th>M3</th><th>M4</th> <th>M5</th><th>M6</th><th>M7</th><th>M8</th> <th>M9</th><th>M10</th><th>M11</th><th>M12</th>
                                                                                        </tr>
                                                                                        {hfPayoutList.length > 11 ?
                                                                                            <>
                                                                                                <tr className='text-left'>

                                                                                                    <th>
                                                                                                        {`${t('Rebates.MonthlyTargets')} ${"%"}`}
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 0, 'MeasureValue', 'month')} value={hfPayoutList[0].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 1, 'MeasureValue', 'month')} value={hfPayoutList[1].MeasureValue}></Form.Control>
                                                                                                    </td> <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 2)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 2, 'MeasureValue', 'month')} value={hfPayoutList[2].MeasureValue}></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 3)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 3, 'MeasureValue', 'month')} value={hfPayoutList[3].MeasureValue} ></Form.Control>
                                                                                                    </td> <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 4)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 4, 'MeasureValue', 'month')} value={hfPayoutList[4].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 5)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 5, 'MeasureValue', 'month')} value={hfPayoutList[5].MeasureValue}></Form.Control>
                                                                                                    </td> <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 6)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 6, 'MeasureValue', 'month')} value={hfPayoutList[6].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 7)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 7, 'MeasureValue', 'month')} value={hfPayoutList[7].MeasureValue}></Form.Control>
                                                                                                    </td> <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 8)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 8, 'MeasureValue', 'month')} value={hfPayoutList[8].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 9)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 9, 'MeasureValue', 'month')} value={hfPayoutList[9].MeasureValue}></Form.Control>
                                                                                                    </td> <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 10)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 10, 'MeasureValue', 'month')} value={hfPayoutList[10].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control onBlur={(event) => calculateMeasureValue(event, 11)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 11, 'MeasureValue', 'month')} value={hfPayoutList[11].MeasureValue} ></Form.Control>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className='text-left'>
                                                                                                    <th>
                                                                                                        {`${t('Rebates.MonthlyPayouts')} ${(rebateIcon)}`}
                                                                                                    </th>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 0)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={disableProgramLineField} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 0, 'RebateValue', 'month')} value={hfPayoutList[0].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 1)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 1, 'RebateValue', 'month')} value={hfPayoutList[1].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 2)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 2, 'RebateValue', 'month')} value={hfPayoutList[2].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 3)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 3, 'RebateValue', 'month')} value={hfPayoutList[3].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 4)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 4, 'RebateValue', 'month')} value={hfPayoutList[4].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 5)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 5, 'RebateValue', 'month')} value={hfPayoutList[5].RebateValue} ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 6)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 6, 'RebateValue', 'month')} value={hfPayoutList[6].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 7)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 7, 'RebateValue', 'month')} value={hfPayoutList[7].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 8)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 8, 'RebateValue', 'month')} value={hfPayoutList[8].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 9)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 9, 'RebateValue', 'month')} value={hfPayoutList[9].RebateValue}   ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 10)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 10, 'RebateValue', 'month')} value={hfPayoutList[10].RebateValue}  ></Form.Control></td>
                                                                                                    <td><Form.Control onBlur={(event) => calculatePayout(event, 11)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} disabled={true} className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "60px" }} onChange={(event) => inputTargetHandler(event, 11, 'RebateValue', 'month')} value={hfPayoutList[11].RebateValue}  ></Form.Control></td>

                                                                                                </tr>
                                                                                            </> : ""}
                                                                                    </> : ""}

                                                                                </thead>
                                                                                <tbody>

                                                                                </tbody>

                                                                            </table>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }</div>
                                                            </div>
                                                        </>

                                                        : <></>}
                                                    {(includeOutstandingAmount === true || includeOutstandingAmount === "true") && rebateTypeValue == 'Revenue' ?
                                                        <div class="row">
                                                            {hasPaymentTerms === true ?
                                                                <>
                                                                    <div className="col-lg-3 ">
                                                                        <label className=" text-left">{t('Rebates.PaymentTerms')}<span className={classes.mandatory}>*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <Select className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" + disabledClass}
                                                                            isDisabled={disableProgramLineField}
                                                                            id="subdivideTargetTypeId"
                                                                            style={{ display: "inline-block" }}
                                                                            options={paymentTermsList}
                                                                            placeholder="Select"
                                                                            onChange={(event) => onChangePaymentTermSelection(event)}
                                                                            isSearchable={true}
                                                                            styles={customStyles}
                                                                            value={paymentTermsList.filter(function (option) {
                                                                                return option.value === `${paymentTermsId}`;
                                                                            })}

                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                ""
                                                            }

                                                        </div>
                                                        : ''}

                                                    {selectedSettlementFreq == 'SubDivideTarget' || selectedSettlementFreq == 'Annual' ?
                                                        <>
                                                            <div class="row">
                                                                {defineMinThreshold === true ?
                                                                    <>
                                                                        <div className="col-lg-3 ">
                                                                            <label className=" text-left">{t('Rebates.ThresholdValue')}<span className={classes.mandatory}>*</span></label>&nbsp;&nbsp;&nbsp;
                                                                            <input onBlur={(event) => calculateThreshold(event, 'Value')} style={{ textAlign: "center" }} disabled={disableProgramLineField} onChange={(event) => inputsMinimumThresholdHandler(event, 'minThresholdValue')} className={"col-md-2 textbox-padding mt-2" + disabledClass} type="text" value={minThresholdValue} />

                                                                        </div><div className="col-lg-4 ">
                                                                            <label className=" text-left">{`${t('Rebates.PayoutPercent')} ${(rebateIcon)}`}<span className={classes.mandatory}>*</span></label>&nbsp;&nbsp;&nbsp;
                                                                            <input onBlur={(event) => calculateThreshold(event, 'Payout')} style={{ textAlign: "center" }} disabled={disableProgramLineField} onChange={(event) => inputsMinimumThresholdHandler(event, 'minThresholdPayout')} className={"col-md-2 textbox-padding mt-2" + disabledClass} type="text" value={minThresholdPayout} />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    ""
                                                                }

                                                            </div>
                                                        </>
                                                        : ''}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }


            {
                showrebateProgramLine === false ?
                    <div className="row mt-3" id="card-RPL">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                    <div className="row">
                                        <div className="col-md-7 text-left">
                                            {t('Rebates.RebateProgramLine')}
                                        </div>

                                        <div class="col-md-5">
                                            {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-1" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                                            {showbutton === true ?
                                                <>
                                                    {loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" type="submit" style={{ float: "right", width: "40px" }} onClick={(event) => OnSelectHandler(checkedValue, event)}>{t('Open')}</button>}
                                                    {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "40px" }} type="submit" onClick={(event) => OnCopyHandler(checkedValue)}>{t('Copy')}</button>}
                                                    {loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "65px" }} onClick={() => { clickShowWorkflow("Workflow", checkedValue) }} type="submit">{t('Workflow')}</button>}
                                                    {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>}
                                                </>
                                                :
                                                deleteshowbutton === true ?
                                                    loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 ml-3" style={{ marginTop: '10px' }}>
                                    <div className="row">
                                        <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={`${t('SearchFor')} ${t('Rebates.ProgramLinesByProduct')}`} onChange={(event) => searchHandler(event)} />
                                        <button id="addRemoveCol" icon className="btn btn-update ml-2 pa-0" type="submit"
                                            style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                            onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div className="card-body" style={{ minHeight: "35vh" }}>
                                    <div className="table-responsive-lg">
                                        <table className="table table-bordered text-left mt-2 table-responsive-sm" id="table-RPL">
                                            <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                                <tr>
                                                    <th className="tableheader"><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                                    <th id='rebateprogramline_id' style={{ backgroundColor: (filterValueDict.rebateprogramline_id ? '#6cae55' : ''), minWidth: "145px" }}
                                                        hidden={hideColumn.includes('rebateprogramline_id') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.RebateProgramId')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineId')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('rebateprogramline_id')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='desc' style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), minWidth: "160px" }}
                                                        hidden={hideColumn.includes('desc') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.RebateProgramDesc')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgram_Desc')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('desc')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='type' style={{ backgroundColor: (multiFilterValue.type ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('type') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.RebateType')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_Type')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('type')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='defn' style={{ backgroundColor: (multiFilterValue.defn ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('defn') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.RebateDefinition')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_Definition')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('defn')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='cat' style={{ backgroundColor: (multiFilterValue.cat ? '#6cae55' : ''), minWidth: "135px" }} hidden={hideColumn.includes('cat') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.RebateCat')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateCategory')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('cat')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='valid_from' style={{ backgroundColor: (filterValueDict.valid_from ? '#6cae55' : ''), minWidth: "100px" }} hidden={hideColumn.includes('valid_from') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.ValidFrom')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_From')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('valid_from')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='valid_to' style={{ backgroundColor: (filterValueDict.valid_to ? '#6cae55' : ''), minWidth: "85px" }} hidden={hideColumn.includes('valid_to') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.ValidTo')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_To')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('valid_to')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='frequency' style={{ backgroundColor: (multiFilterValue.frequency ? '#6cae55' : ''), minWidth: "140px" }} hidden={hideColumn.includes('frequency') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.AccrualFreq')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Settlement_Freq')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('frequency')}></span>
                                                        </div>
                                                    </th>
                                                    <th id="status" style={{ backgroundColor: (filterValueDict.status ? '#6cae55' : ''), minWidth: "75px" }} hidden={hideColumn.includes('status') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span> {t('Rebates.Status')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('status')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')}></span>
                                                        </div>
                                                    </th>
                                                    <th id="active" className="tableheader" style={{ backgroundColor: (multiFilterValue.active ? '#6cae55' : ''), minWidth: "85px" }} hidden={hideColumn.includes('active') ? true : false}>
                                                        <div className="d-flex text-nowrap">
                                                            <span>{t('Rebates.IsActive')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('active')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('active')}></span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div id="Actions" className="d-flex text-nowrap" style={{ backgroundColor: (multiFilterValue.Actions ? '#6cae55' : ''), minWidth: "85px" }} hidden={hideColumn.includes('active') ? true : false}>
                                                            <span>{t('Rebates.Actions')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Actions')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Actions')}></span>
                                                        </div>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    showspinner === true ?

                                                        <>
                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        </>
                                                        :
                                                        rebateProgramLine.length === 0 ?
                                                            <tr className='text-center'>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{t('NoDataAvailable')}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>  <td></td>
                                                                <td></td>
                                                            </tr>
                                                            :
                                                            rebateProgramLine.map((line, index) =>
                                                                <tr onDoubleClick={(event) => OpenRebate(event, line.Id, line.Agreementtype)}>
                                                                    <td >

                                                                        <input id={line.Id} type="checkbox" name='chkBox' value={[line.Id, line.Status]} onChange={(event) => handleChange(event, line.Id, line.Status, line.RebateProgramLineId)} /></td>
                                                                    <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('rebateprogramline_id') ? true : false}>{line.RebateProgramLineId}</td>
                                                                    <td style={{ maxWidth: "170px" }} hidden={hideColumn.includes('desc') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={line.RebateProgram_Desc}>{line.RebateProgram_Desc}</div></td>
                                                                    <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('type') ? true : false}>{line.Rebate_Type}</td>
                                                                    <td style={{ maxWidth: "140px" }} hidden={hideColumn.includes('defn') ? true : false}>{line.Rebate_Definition}</td>
                                                                    <td style={{ maxWidth: "100px" }} hidden={hideColumn.includes('cat') ? true : false}>{line.RebateCategory}</td>
                                                                    <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('valid_from') ? true : false}>{moment(line.Validity_From).format(SessionManage.getcurrentdateformat())}</td>
                                                                    <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('valid_to') ? true : false}>{moment(line.Validity_To).format(SessionManage.getcurrentdateformat())}</td>
                                                                    <td hidden={hideColumn.includes('frequency') ? true : false}>{line.Settlement_Freq}</td>
                                                                    <td hidden={hideColumn.includes('status') ? true : false}>{line.Status}</td>
                                                                    <td align="center" hidden={hideColumn.includes('active') ? true : false}>
                                                                        {line.Status === "Approved"
                                                                            ?
                                                                            <>
                                                                                {Service.submitRebatePerms(userPerms) == false ? (line.Active === true ? "Active" : "In-Active") : line.Active === true ?
                                                                                    <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => checkActiveStatus(line, "Open-A")}>Active</a>
                                                                                    :
                                                                                    <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => checkActiveStatus(line, "Open-D")}>In-Active</a>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </td>

                                                                    <td>

                                                                        <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                            <i className='fa fa-product-hunt' onClick={(event) => showproducts(line.Id, line.Status)} title="Show Products"></i>

                                                                        </div>

                                                                        <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>

                                                                            {/* {Service.submitRebatePerms(userPerms) == false ? "" : (line.Status === "New" || line.Status === "InReview") ? <img src='/NewAssets/images/analysis.png' style={{ width: '14px', height: "14px", cursor: "pointer" }} title="Analyze Rebate" onClick={(event) => EligibleInvoicePopup(line.Id, "AnalyzeRebate")}></img> : <></>}
                                                                            {Service.submitRebatePerms(userPerms) == false ? "" : line.Status === "Approved" && line.Active === true ? <img src='/NewAssets/images/invoice.ico' style={{ width: '19px', cursor: "pointer" }} title="Eligible Invoice" onClick={(event) => EligibleInvoicePopup(line.Id, "EligibleInvoice")}></img> : <></>} */}
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )

                                                }


                                            </tbody>


                                        </table>
                                        {
                                            showLoadMore === true ?
                                                <div className='text-center container row'>
                                                    <div className='col-md-5'></div>
                                                    <div className='col-md-2'>
                                                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                                    </div>
                                                    <div className='col-md-5'></div>

                                                </div>
                                                :
                                                ""
                                        }

                                    </div>

                                    <div className='row'>
                                        {
                                            totalselectedshow === true ?

                                                <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                                    {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                                </div>

                                                :
                                                <div className='col-lg-3  col-sm-12' style={{ textAlign: "center" }}>

                                                </div>
                                        }
                                        <div className='col-lg-8 col-sm-12'></div>


                                        <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.625rem" }}>
                                            {t('TotalRecords')} - {totalCountlst}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    :
                    ''
            }

            {showAddColumnPopup === true ?
                <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                    <div className="container">
                        <div className="row">
                            <Select
                                className='col-md-10 textbox-padding fontsizedropdown'
                                options={colVisibilityOption}
                                placeholder="Select"
                                value={colvisiOptionVal}
                                onChange={OnChangeColVisibilityList}
                                styles={customStyles}
                            />
                            <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                            </div>
                        </div>
                        <div className="row">
                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                {
                                    addRemoveCol.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                            /> {t(item.label)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
            }


            {showCreateColumnListPopup === true ?
                <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                            </div>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                            </div>
                        </div>
                        <div className="row">
                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                {
                                    createColumnListOption.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                            /> {t(item.label)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                            </div>
                        </div>
                    </div>


                </div>
                : <></>
            }

            {
                showregionpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.RegionListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>
                                                                <input checked={regioncheckAll} disabled={disableProgramLineField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Region')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer">
                                                    {
                                                        SalesLevelData.map((regionlist) =>
                                                            <div className='col-md-3' style={{ padding: "5px;" }}>
                                                                <input type="checkbox"
                                                                    disabled={(disableProgramLineField) || (regionlist.avalRegion == false)}
                                                                    className={disabledClass}
                                                                    checked={regionlist.selected} name="region-check" value={regionlist.Id} id={regionlist.Id} onChange={(event) => handleregionChanges(event, regionlist.label, regionlist.Id)} />
                                                                &nbsp;<label>{regionlist.label}</label>
                                                            </div>
                                                        )
                                                    }
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okregion} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedRegionpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.RegionListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input disabled="true" type="checkbox" name="option-all-region" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Region')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            regionDataSelected.map((i) =>
                                                                <tr key={i.Id}>
                                                                    <td><input type="checkbox"
                                                                        disabled={true}
                                                                        className={disabledClass}
                                                                        checked={i.selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                                                    <td>{i.label}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

            <Modal show={showSubmitModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                        onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('showSubmitDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showInvoiceModal}
                centered size="bg">
                <div class="modal-body">
                    <div class="container-fluid">
                        <h6 style={{ "textAlign": 'center', marginBottom: "10px" }}>{t('Rebates.SelectAccrualPeriod&Customer')}</h6>
                        <hr></hr>
                        <div style={{ marginTop: "5px" }}>
                            <div className="row">
                                <label className="col-md-3 text-left">{t('Rebates.AccrualPeriod')}</label>
                                <Multiselect
                                    onSelect={(Event) => OnPopupPeriodChange(Event)}
                                    onRemove={(Event) => OnPopupPeriodChange(Event)}
                                    options={invoicePeriodValue}
                                    displayValue="RebatePeriod"
                                    className='col-md-9 textbox-padding fontsizedropdown'
                                    style={regionStyles}
                                    singleSelect="true"
                                />
                            </div>
                            <br></br>
                            <div className="row">
                                <label className="col-md-3 text-left">{t('Rebates.Customer')}</label>
                                <Multiselect
                                    onSelect={(Event) => OnPopupCustomerChange(Event)}
                                    onRemove={(Event) => OnPopupCustomerChange(Event)}
                                    options={customerInvoiceValue}
                                    displayValue="label"
                                    selectedValues={customerInvoiceValue}
                                    className='col-md-9 textbox-padding fontsizedropdown'
                                    style={CustomerStyles}
                                />
                            </div>
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button size='sm' onClick={(event) => setShowInvoiceModal(false)} style={{ width: "45px" }}>
                                {t('Close')}
                            </Button>
                            {whoCalled === "EligibleInvoice" ?
                                <Button size='sm' style={{ marginLeft: "5px", width: "30px" }} onClick={(event) => AccrualEligibleInvoice(rpl_ID)}>
                                    {t('Ok')}
                                </Button>
                                :
                                ""
                            }
                            {whoCalled === "AnalyzeRebate" ?
                                <Button size='sm' style={{ marginLeft: "5px", width: "30px" }} onClick={(event) => AccrualAnalyzeRebate(rpl_ID)}>
                                    {t('Ok')}
                                </Button>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                        {t('Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprovedModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
                        onChange={(e) => { setCommentsForApproved(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeniedModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
                        onChange={(e) => { setCommentsForDenied(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.OnlyRebateProgramsNotSubmittedCanBeDeleted')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => CloseRebateProgrmLine("RPLClose")}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => CloseRebateProgrmLine("RPLCancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={closeHeaderModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => CloseAgreement("HeaderClose")}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => CloseAgreement("HeaderCancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Rebates.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleProgramLineActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleProgramLineActiveStatus(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal1}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={() => DeleteGeneratedClaims(activeData.Id, loggedInUser)}>
                        {t('Proceed')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => setToggleStatusModal1(false)}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal2}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => setToggleStatusModal2(false)}>
                        {t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={custChngModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.ChangingTheCustomerWillChangeTheExistingRegionData')}</Modal.Body>
            </Modal>
            <Modal show={productsModal}
                centered size="sm">
                <div class="modal-body">
                    <div class="container-fluid">
                        <h5 style={{ "textAlign": 'center' }}>{t('Rebates.SelectedProducts')}</h5>
                        <hr></hr>
                        <div style={{ "textAlign": 'center', marginTop: "5px" }}>
                            <label></label>
                        </div>
                        <div style={{ marginLeft: "160px", marginTop: "8px" }}>
                            <Button size='sm' onClick={() => setProductsModal(false)} style={{ width: "45px" }}>
                                {t('Close')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={showWorkflowAlert}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => workflowAlert('close')}>
                        {t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showClaimDialogForRevise}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
              <Modal.Footer>
                <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={() =>  DeleteGeneratedClaims(rebateprogrmlinedetail.id, loggedInUser,'Revise')}>
                  {t('Proceed')}
                </Button>
                <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => setShowClaimDialogForRevise(false)}>
                  {t('Cancel')}
                </Button>
              </Modal.Footer>
            </Modal>
            {
                showfilterpopup === true ?
                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                        {filterCol === 'rebateprogramline_id' || filterCol === 'desc' || filterCol === 'status' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">
                                        <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        {filterCol === 'valid_from' || filterCol === 'valid_to' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">
                                        <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        {filterCol === 'defn' || filterCol === 'frequency' || filterCol === 'type' || filterCol === 'cat' || filterCol === 'active' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                    </div>

                                </div>

                                <div className="container mt-2">
                                    <div className="row">
                                        <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                    </div>

                                    <div className="row contentoverflow mt-2">
                                        <table className="filtertable">
                                            <tbody>
                                                <tr>
                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                    <td>Select All</td>
                                                </tr>
                                                {
                                                    dropdownData.map((item, index) => (

                                                        <tr key={index}>

                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                            <td>{item.Name}</td>

                                                        </tr>


                                                    ))
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                showProductLoadSpinner === true ?
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{
                        position: 'absolute',
                        left: '50%', top: '55%', zIndex: 1, width: '60px', height: '60px', margin: '-75px 0 0 -75px',
                        border: '7px solid #f3f3f3', borderRadius: '50%', borderTop: '7px solid #3498db',
                        animation: 'spin 1s linear infinite', borderColor: 'rgb(10 173 216) rgb(92 187 72) rgb(10 173 216)'
                    }}></span>
                    : <></>
            }

        </div >

    )

} export default CreateClaimAgreementComponent




















