import Cookies from 'js-cookie';

class SessionManage{
    // Userid set or get
    async setuserid(value) {
        Cookies.set('id', value, { expires: 1 });
    }
    getuserid() {
        const userid =  Cookies.get("id");
        return userid || null;
    }
    // Username set or get
    async setusername(value) {
        Cookies.set('name', value, { expires: 1 });
    }
    getusername() {
        const username =  Cookies.get("name");
        return username || null;
    }
    //token id
    async settokenid(value) {
        Cookies.set('token', value, { expires: 1 });
    }
    gettokenid() {
        const username = Cookies.get("token");
        return username || null;
    }
    // ROle id
    async setroleid(value) {
        Cookies.set('roleid', value, { expires: 1 });
    }
    getroleid() {
        const username = Cookies.get("roleid");
        return username || null;
    }
    async setgroupid(value) {
        Cookies.set('groupids', value, { expires: 1 });
    }
    getgroupid() {
        const username = Cookies.get("groupids");
        return username || null;
    }
    // roundingPrecision set or get
    async setroundingPrecision(value) {
        Cookies.set('roundingPrecision', value, { expires: 1 });
    }
    getroundingPrecision() {
        const username = Cookies.get("roundingPrecision");
        return username || null;
    }
    async setdateformat(value) {
        Cookies.set('dateformat', value, { expires: 1 });
    }
    getdateformat() {
        let username = Cookies.get("dateformat")
        if(username == null || username == 'Select'){
            username =  "DD/MM/YYYY";
        }
        return username || null;
    }
    async setFiscalEndDate(value) {
        Cookies.set('FiscalEndDate', value, { expires: 1 });
    }
    getFiscalEndDate() {
        const FiscalEndDate = Cookies.get("FiscalEndDate");
        return FiscalEndDate || null;
    }
    async setLocale(value) {
        Cookies.set('Locale', value, { expires: 1 });
    }
    getLocale() {
        const Locale = Cookies.get("Locale");
        return Locale || null;
    }
    async setLanguages(value) {
        Cookies.set('Languages', value, { expires: 1 });
    }
    getLanguages() {
        const Languages = Cookies.get("Languages");
        return Languages || null;
    }
    getcurrentdateformat() {
        let username = Cookies.get("dateformat").toUpperCase();
        if(username == null || username == 'Select'){
            username =  "DD/MM/YYYY";
        }
        return username || null;
    }
    extractDateFromTimestamp(timestamp) {
        const dateObj = new Date(timestamp);
        const year = dateObj.getUTCFullYear();
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getUTCDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
    }
    async setAccrualCalc(value) {
        Cookies.set('AccrualCalc', value, { expires: 1 });
    }
    getAccrualCalc() {
        const username = Cookies.get("AccrualCalc");
        return username || null;
    }

    async setComparisonPeriod(value) {
        Cookies.set('ComparisonPeriod', value, { expires: 1 });
    }
    getComparisonPeriod() {
        const username = Cookies.get("ComparisonPeriod");
        return username || null;
    }

    async setdefaultCurrency(value) {
        Cookies.set('defaultCurrency', value, { expires: 1 });
    }
    getdefaultCurrency() {
        const username = Cookies.get("defaultCurrency");
        return username || null;
    }

    async setfiscalstartmonth(value) {
        Cookies.set('fiscalstartmonth', value, { expires: 1 });
    }
    getfiscalstartmonth() {
        const username = Cookies.get("fiscalstartmonth");
        return username || null;
    }

    async setfiscalendmonth(value) {
        Cookies.set('fiscalendmonth', value, { expires: 1 });
    }
    getfiscalendmonth() {
        const username = Cookies.get("fiscalendmonth");
        return username || null;
    }

     async setidletimeout(value) {
        Cookies.set('idletimeout', value, { expires: 1 });
    }
    getidletimeout() {
        const username = Cookies.get("idletimeout")
        return username || null;
    }
    async setIncludeOutstandingAmount(value) {
        Cookies.set('includeOutstandingAmount', value, { expires: 1 });
    }
    getIncludeOutstandingAmount() {
        const includeOutstandingAmount = Cookies.get("includeOutstandingAmount");
        return includeOutstandingAmount || null;
    }
    async setIncludeSubDivideTarget(value) {
        Cookies.set('includeSubDivideTarget', value, { expires: 1 });

    }
    getIncludeSubDivideTarget() {
        const includeSubDivideTarget = Cookies.get("includeSubDivideTarget");
        return includeSubDivideTarget || null;
    }

    async setAccrualThresholdCriteria(value) {
        Cookies.set('accrualThresholdCriteria', value, { expires: 1 });

    }
    getAccrualThresholdCriteria() {
        const accrualThresholdCriteria = Cookies.get("accrualThresholdCriteria");
        return accrualThresholdCriteria || null;
    }
    async setUsersType(value) {
        Cookies.set('UsersType', value, { expires: 1 });
    }
    getUsersType() {
        const usersType = Cookies.get("UsersType");
        return usersType || null;
    }
    setNavBar(value){
         Cookies.set('navData', value, { expires: 1 });
    }
    getNavBar(){
        const navData = Cookies.get("navData");
        return navData || null;
    }
    setIds(value){
         Cookies.set('ids', value, { expires: 1 });
    }
    getIds(){
        const ids = Cookies.get("ids");
        return ids || null;
    }
}
export default new SessionManage();