import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import MinThresholdPolicyList from '../../../Components/EndurancePolicies/MinThresholdPolicy/MinThresholdPolicyList';

function MinThresholdPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <MinThresholdPolicyList {...props}  navigate={navigate} id={params.id} /> 
         </div>
     );  

}
export default MinThresholdPolicy