
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation, useSearchParams } from "react-router-dom"
import Service from '../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PaymentPendingComponent(props) {

    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([

        { label: "Dist. Code", key: "DistributorCode" },
        { label: "Distributor Name", key: "DistributorName" },
        { label: "Doc Number", key: "DocNumber" },
        { label: "Bill Number", key: "BillNumber" },
        { label: "Bill Date", key: "BillDate" },
        { label: "LRDate", key: "LRDate" },
        { label: "Bill Amount", key: "BillAmount" },
        { label: "GST", key: "Gst" },
        { label: "Outstanding", key: "OutstandingAmount" },
        { label: "Days", key: "DaysDue" },
        { label: "Eligible For CD", key: "EligibleForDiscount" },
        { label: "Amt. Being Paid", key: "AmountBeingPaid" },
        { label: "Amt. Eligible For CD", key: "AmountEligibleForCashDiscount" },
        { label: "CD Amt.", key: "CashDiscountAmount" },
        { label: "Total Due", key: "TotalDue" },
        { label: "Amt. Payable", key: "AmountPayable" },
    ]);
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [showPopup, setshowPopup] = useState('');
    const [PayData, setpayData] = useState([]);
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showspinnerPopup, setshowspinnerPopup] = useState('');

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState(true);
    const [totalselectedcount, settotalselectedcount] = useState(1);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [checkIndexValue, setcheckIndexValue] = useState([1]);
    const [maxIndex, setMaxIndex] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)
    const [canModify, setCanModify] = useState(false)
    const [disabledBelowIndex, setIsDisabledBelowIndex] = useState(1)
    const [totalOutstanding, setTotalOutstanding] = useState(0);
    const [totalAmountBeingPaid, setTotalAmountBeingPaid] = useState(0);
    const [totalCashDiscount, setTotalCashDiscount] = useState(0);
    const [totalAmountPayable, setTotalAmountPayable] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [Currency, setCurrency] = useState("");
    const [getDestributer, setdistributor] = useState([])
    const [getSelectedDistributor, setSelectedDistributor] = useState({ value: null, label: '----- Select Distributor -----' })

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const customStyles1 = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const [filterValueDict, setFilterValueDict] = useState({

        DistributorCode: "",
        DistributorName: "",
        DocNumber: "",
        BillNumber: "",
        BillDate: "",
        LRDate: "",
        BillAmount: "",
        Gst: "",
        DaysDue: "",
        EligibleForDiscount: "",
        FullPayment: "",
        AmountBeingPaid: "",
        CashDiscountAmount: "",
        TotalDue: "",
        AmountPayable: ""
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedBy: '',
        LastModifiedBy: '',
        status: ''
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null, null);
            getColumnVisibilityList()
            getColVisibilityListData()
            getDistributorOption();
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const clickClosePayPopup = () => {
        setshowPopup(false);
    }
    const clickPayPopup = () => {
        let showpop = true;
        let error = false;
        for (let i = 1; i <= maxIndex; i++) {
            if (!checkIndexValue.includes(i)) {
                setShowErrorModal(true)
                setErrorMessage(`Please clear the older invoice outstanding before selecting a newer invoice.`)
                setshowPopup(false)
                error = true;
                break
            }
        }
        setsidebarwidth("70vw");

        if (error == false) {
            calculateSummaryValues();
        }
    }
    const clickProceed = () => {
        Service.createorupdate("PaymentPending/MovePendingToReceived", selectedIds).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data
                    getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null, getSelectedDistributor.value);
                    navigate('/paymentpending')
                }
            }
        });

        setshowPopup(false);

        setcheckIndexValue([1])
        setMaxIndex(1)
    }


    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;
        let csvLst = filteredArray.filter(row => values.includes(';' + row.Id));

        let filteredCsvList = csvLst.map(item => {
            let newItem = {};
            Object.keys(item).forEach(key => {
                if (!hideColumn.includes(key)) {
                    newItem[key] = item[key];
                }
            });
            return newItem;
        });

        let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

        setCsvList(filteredCsvList);
        setHeaders(filteredHeaders);



        setTimeout(() => {
            toast.success('Exported Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
            });
            csvInstance.current.link.click();
        });

    }




    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
           
            let data = []
            filteredArray.map((listVal, index) => {
                data.push(index + 1)
               
            })
            settotalselectedcount(data.length)
            setTimeout(1000)
            setcheckIndexValue(data)
        }
        else {
           
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(1)
            setcheckIndexValue([1])
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value, getSelectedDistributor.value);
        }
        else {
            setSearchValue(null);
            getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null, getSelectedDistributor.value);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getDatawithMultiFilterSearchwithId("PaymentPending/GetAll_Policy", loggedInUser, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue, getSelectedDistributor.value).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    rowData = res.data.DataList;

                    rowData.map((i) => {
                        if (i.FullPayment == "true" || i.FullPayment == true) {
                            if (i.Firstpayment == true || i.Firstpayment == "true") {
                                if (!isNaN(i.AmountBeingPaid)) {
                                    i.AmountBeingPaid = parseFloat(i.AmountBeingPaid.toFixed(SessionManage.getroundingPrecision()));
                                }
                                i.CashDiscountAmount = (i.AmountBeingPaid - i.Gst) * i.CashDiscountPercent / 100;
                                i.TotalDue = i.AmountBeingPaid - i.CashDiscountAmount;
                                i.AmountPayable = i.AmountBeingPaid - i.CashDiscountAmount;
                            }
                            else {
                                if (!isNaN(i.AmountBeingPaid)) {
                                    i.AmountBeingPaid = parseFloat(i.AmountBeingPaid.toFixed(SessionManage.getroundingPrecision()));
                                }
                                i.CashDiscountAmount = (i.AmountBeingPaid) * i.CashDiscountPercent / 100;
                                i.TotalDue = i.AmountBeingPaid - i.CashDiscountAmount;
                                i.AmountPayable = i.AmountBeingPaid - i.CashDiscountAmount;
                            }

                        }
                    })
                    setdata(data => [...data, ...rowData])
                    setFilteredArray(filteredArray => [...filteredArray, ...rowData])
                    LoadMore(currentlength + rowData.length, res.data.Totalcount)
                }
            }
        });
       
        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, status, indexVal) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
            settotalselectedshow(true);

            let index_nos = [indexVal];
            for (let i = 0; i < checkIndexValue.length; i++) {
                index_nos.push(checkIndexValue[i])
            }
            settotalselectedcount(index_nos.length);
            setMaxIndex(Math.max(...index_nos))
            setcheckIndexValue([...checkIndexValue, indexVal])

        }
        else {
            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }

            const result = checkIndexValue.filter((idx) => idx != indexVal);
            setMaxIndex(Math.max(...result))
            setcheckIndexValue(result);
            settotalselectedcount(result.length);

        }


        if (checkedBoxes.length === 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/paymentpending");
        }
    }


    const DeleteHandler = (id, status) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        var chk = false
        if (status === false) {
            chk = true
        }
        if (id > 0 && chk === true) {
            Service.deleteDataByPolicyID("PaymentPending/Delete", id, loggedInUser).then(res => {
                if (res.status === 200) {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, getSelectedDistributor.value)
                    }
                }
            })
        }
        else {
            setShowErrorModal(true)
            setErrorMessage("Active Policy cannot be deleted")
        }
    }

    const getPaymentPendingViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, distributerCode) => {

        Service.getDatawithMultiFilterSearchwithId(method, loggedInUser, pageSize, pageNumber, sortType, SortProperty, filterValue, search, distributerCode).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            rowData.map((i) => {
                                if (i.FullPayment == "true" || i.FullPayment == true) {
                                    if (i.Firstpayment == true || i.Firstpayment == "true") {
                                        if (!isNaN(i.AmountBeingPaid)) {
                                            i.AmountBeingPaid = parseFloat(i.AmountBeingPaid.toFixed(SessionManage.getroundingPrecision()));
                                        }
                                        i.CashDiscountAmount = (i.AmountBeingPaid - i.Gst) * i.CashDiscountPercent / 100;
                                        i.TotalDue = i.AmountBeingPaid - i.CashDiscountAmount;
                                        i.AmountPayable = i.AmountBeingPaid - i.CashDiscountAmount;

                                    }
                                    else {
                                        if (!isNaN(i.AmountBeingPaid)) {
                                            i.AmountBeingPaid = parseFloat(i.AmountBeingPaid.toFixed(SessionManage.getroundingPrecision()));
                                        }
                                        i.CashDiscountAmount = (i.AmountBeingPaid) * i.CashDiscountPercent / 100;
                                        i.TotalDue = i.AmountBeingPaid - i.CashDiscountAmount;
                                        i.AmountPayable = i.AmountBeingPaid - i.CashDiscountAmount;
                                    }
                                }
                            })
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }





    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('PaymentPending/GetPaymentPendingFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)
        setshowfilterpopup(true)
        setFilterCol(name)
        var testDiv = document.getElementById(name);
        setdivleft((testDiv.offsetLeft + 30) + "px");
        if (name === 'DistributorCode') {
            setfiltertext(t('Policies.DistCode'))
        }
        if (name === 'DistributorName') {
            setfiltertext(t('Policies.DistributorName'))
        }
        if (name === 'DocNumber') {
            setfiltertext(t('Policies.DocNumber'))
        }
        if (name === 'BillNumber') {
            setfiltertext(t('Policies.BillNumber'))
        }
        if (name === 'BillDate') {
            setfiltertext(t('Policies.BillDate'))
        }
        if (name === 'LRDate') {
            setfiltertext(t('Policies.LRDate'))
        }
        if (name === 'BillAmount') {
            setfiltertext(t('Policies.BillAmount'))
        }
        if (name === 'Gst') {
            setfiltertext(t('Policies.GST'))
        }
        if (name === 'OutstandingAmount') {
            setfiltertext(t('Policies.Outstanding'))
        }
        if (name === 'DaysDue') {
            setfiltertext(t('Policies.DaysDue'))
        }
        if (name === 'EligibleForDiscount') {
            setfiltertext(t('Policies.EligibleForCD'))
        }
        if (name === 'FullPayment') {
            setfiltertext(t('Policies.FullPayment'))
        }
        if (name === 'AmountBeingPaid') {
            setfiltertext(t('Policies.AmtBeingPaid'))
        }
        if (name === 'AmountForCashDiscount') {
            setfiltertext(t('Policies.AmountForCD'))
        }
        if (name === 'CashDiscountAmount') {
            setfiltertext(t('Policies.CDAmount'))
        }
        if (name === 'TotalDue') {
            setfiltertext(t('Policies.TotalDue'))
        }
        if (name === 'AmountPayable') {
            setfiltertext(t('Policies.AmtPayable'))
        }
       
        setshowfilterpopup(true)

    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
         
            navigate("/paymentpending");
        }
    }
    const SortColumn = (colName) => {

       
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getPaymentPendingViewData("PaymentPending", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue, getSelectedDistributor.value);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
          
            if (list.length > 0) {
                setpaging(paging);
                getPaymentPendingViewData("PaymentPending", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, getSelectedDistributor.value);
            }

        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getPaymentPendingViewData("PaymentPending", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, getSelectedDistributor.value);
         
            setFilterCol('')
            setFilterValue([])
         
            setFilterSearch("")
            setDropdownData([])
            setFilterDropdownData([])
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const OnChangePayment = (e, listVal, index) => {
        const { value } = e.target;
        const updatedArray = [...filteredArray];
        setIsDisabled(value == 'false' ? true : false);
        setIsDisabledBelowIndex(value == 'false' ? index + 1 : 1);

        // Update the specific row's FP value
        updatedArray[index].FullPayment = value;

        if (value == "true" || value == true) {
            updatedArray[index].AmountBeingPaid = updatedArray[index].OutstandingAmount;
            updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid - updatedArray[index].Gst;
            updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
            updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
            updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
        } else {
            updatedArray[index].AmountBeingPaid = null;
            // Remove all checked values which are more than the current index
            const filteredCheckedIndexes = checkIndexValue.filter((checkedIndex) => checkedIndex <= index + 1);

            setcheckIndexValue(filteredCheckedIndexes);
            for (let i = index + 1; i < updatedArray.length; i++) {
                if (updatedArray[i].FullPayment == 'false') {
                    updatedArray[i].FullPayment = 'true';
                    updatedArray[i].AmountBeingPaid = updatedArray[i].OutstandingAmount;
                    updatedArray[i].AmountEligibleForCashDiscount = updatedArray[i].AmountBeingPaid - updatedArray[i].Gst;
                    updatedArray[i].CashDiscountAmount = updatedArray[i].AmountEligibleForCashDiscount * updatedArray[i].CashDiscountPercent / 100;
                    updatedArray[i].TotalDue = updatedArray[i].AmountBeingPaid - updatedArray[i].CashDiscountAmount;
                    updatedArray[i].AmountPayable = updatedArray[i].AmountBeingPaid - updatedArray[i].CashDiscountAmount;
                }
            }
            setMaxIndex(Math.max(index + 1))
        }

        // Update the state to trigger a re-render
        setFilteredArray(updatedArray);
    }
    const OnChangeAmountBeingPaid = (e, listVal, index) => {
        const { value } = e.target;
        const updatedArray = [...filteredArray];
        // Update the specific row's FP value
        let FPvalue = updatedArray[index].FullPayment;
        if (FPvalue == "true" || FPvalue == true) {
            updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].OutstandingAmount;
        } else {
            if (updatedArray[index].Firstpayment == true) {
                updatedArray[index].AmountBeingPaid = parseInt(value);
                updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid - updatedArray[index].Gst;
                updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
                updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
                updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid;
            }
            else {
                updatedArray[index].AmountBeingPaid = parseInt(value);
                updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid;
                updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
                updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
                updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid;
            }
        }

        // Update the state to trigger a re-render
        setFilteredArray(updatedArray);
    }
    const OnBlurAmountBeingPaid = async (e, listVal, index) => {
        const updatedArray = [...filteredArray];
        const BillAmount = updatedArray[index].BillAmount;
        const AmountBeingPaid = updatedArray[index].AmountBeingPaid;
        if (BillAmount > 0) {
            if (AmountBeingPaid > BillAmount) {
                let FPvalue = updatedArray[index].FullPayment
                if (FPvalue == "true" || FPvalue == true) {
                    updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].OutstandingAmount;
                } else {
                    if (updatedArray[index].Firstpayment == true) {
                        updatedArray[index].AmountBeingPaid = BillAmount;
                        updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid - updatedArray[index].Gst;
                        updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
                        updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
                        updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid;
                    }
                    else {
                        updatedArray[index].AmountBeingPaid = BillAmount;
                        updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid;
                        updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
                        updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
                        updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid;
                    }
                }
                setFilteredArray(updatedArray);
                var list = [index + 1]
                await updateNextLineAmt(AmountBeingPaid - BillAmount, index + 1, list)
                let indxes = [...checkIndexValue]
                for (let i = 0; i < indxes.length; i++) {
                    if (!list.includes(indxes[i])) {
                        list.push(indxes[i])
                    }
                }
                setcheckIndexValue(list)
                setMaxIndex(Math.max(...list))
                settotalselectedcount(list.length)

            }
        }
    }

    const updateNextLineAmt = (extraAmt, index, list) => {
        let data = list
        const updatedArray = [...filteredArray];
        let BillAmount = updatedArray[index].BillAmount;
        let amount = 0
        if (extraAmt > BillAmount) {
            amount = extraAmt - BillAmount;
        }
        else if (extraAmt < BillAmount) {
            BillAmount = parseInt(extraAmt)
        }
        updatedArray[index - 1].FullPayment = true
        updatedArray[index - 1].AmountEligibleForCashDiscount = updatedArray[index - 1].OutstandingAmount;

        updatedArray[index].FullPayment = false

        if (updatedArray[index].Firstpayment == true) {
            updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid - updatedArray[index].Gst;
        }
        else {
            updatedArray[index].AmountEligibleForCashDiscount = updatedArray[index].AmountBeingPaid;
        }

        updatedArray[index].AmountBeingPaid = BillAmount;
        updatedArray[index].CashDiscountAmount = updatedArray[index].AmountEligibleForCashDiscount * updatedArray[index].CashDiscountPercent / 100;
        updatedArray[index].TotalDue = updatedArray[index].AmountBeingPaid - updatedArray[index].CashDiscountAmount;
        updatedArray[index].AmountPayable = updatedArray[index].AmountBeingPaid;

        setFilteredArray(updatedArray);
        setIsDisabledBelowIndex(index + 1)
        data.push(index + 1)
        if (amount > 0) {
            index = index + 1
            updateNextLineAmt(amount, index, data)
        }
        else {
            return data
        }
    }


    const calculateSummaryValues = () => {

        let outstadingSum = 0;
        let amountBeingPaidSum = 0;
        let cashDiscountSum = 0;
        let amountPayableSum = 0;
        let newSelectedIds = [];
        checkIndexValue.forEach(index => {
            if (index >= 0 && index < filteredArray.length) {

                const item = filteredArray[index - 1];

                if (item.AmountBeingPaid == null || item.AmountBeingPaid == 0) {
                    setShowErrorModal(true)
                    setErrorMessage('Please enter amount to be paid for all selected rows');
                    return;
                }
                else {
                    outstadingSum += item.OutstandingAmount;
                    amountBeingPaidSum += item.AmountBeingPaid;
                    cashDiscountSum += item.CashDiscountAmount;
                    amountPayableSum += item.AmountPayable;
                    if (item.FullPayment == "true" || item.FullPayment == "false") {
                        item.FullPayment = JSON.parse(item.FullPayment.toLowerCase())
                    }
                    newSelectedIds.push(item)
                    setshowPopup(true)
                    setCurrency(item.Currency)
                }
            }
        });
        setSelectedIds(newSelectedIds);
        setTotalOutstanding(outstadingSum);
        setTotalAmountBeingPaid(amountBeingPaidSum);
        setTotalAmountPayable(amountPayableSum);
        setTotalCashDiscount(cashDiscountSum);
    }
    const getDistributorOption = () => {
        Service.getdataByGroup('PaymentPending/GetDistributorList').then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        let dataLst = [{ value: null, label: '----- Select Distributor -----' }]
                        res.data.map((data) => {
                            let dLabel = data.Name + "-" + data.label;
                            dataLst.push({ value: data.Name, label: dLabel });
                        })
                        setdistributor(dataLst);
                    }
                }
            }
        });
    }

    const onChnageDistributor = (data) => {
        setSelectedDistributor({ value: data.value, label: data.label });
        getPaymentPendingViewData("PaymentPending/GetAll_Policy", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null, data.value);
    }


    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Policies.SNo", "key": "SNo", "value": true },
        { "label": "Policies.DistCode", "key": "DistributorCode", "value": true },
        { "label": "Policies.DistributorName", "key": "DistributorName", "value": true },
        { "label": "Policies.DocNumber", "key": "DocNumber", "value": true },
        { "label": "Policies.BillNumber", "key": "BillNumber", "value": true },
        { "label": "Policies.BillDate", "key": "BillDate", "value": true },
        { "label": "Policies.LRDate", "key": "LRDate", "value": true },
        { "label": "Policies.BillAmount", "key": "BillAmount", "value": true },
        { "label": "Policies.GST", "key": "Gst", "value": true },
        { "label": "Policies.Outstanding", "key": "OutstandingAmount", "value": true },
        { "label": "Policies.DaysDue", "key": "DaysDue", "value": true },
        { "label": "Policies.EligibleForDiscount", "key": "EligibleForDiscount", "value": true },
        { "label": "Policies.AmountBeingPaid", "key": "AmountBeingPaid", "value": true },
        { "label": "Policies.AmountForCashDiscount", "key": "AmountEligibleForCashDiscount", "value": true },
        { "label": "Policies.CashDiscount", "key": "CashDiscountAmount", "value": true },
        { "label": "Policies.TotalDue", "key": "TotalDue", "value": true },
        { "label": "Policies.AmtPayable", "key": "AmountPayable", "value": true },
    ];

    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 110)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'PaymentPendingList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'paymentPengingList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'paymentPengingList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                  
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }
                          
                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'paymentPengingList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                         
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                
            }
        })
    }
    // ------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>

                    <li className="breadcrumb-item active" aria-current="page">{t('Policies.PaymentPending')}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="PaymentPending.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1" style={{ position: "sticky" }}>

                                    <div className="col-md-7">
                                        <div className='row'>
                                          
                                            <Select
                                                className='col-md-7 textbox-padding fontsizedropdown'
                                                options={getDestributer}
                                                placeholder="Select Distributor"
                                                value={getSelectedDistributor}
                                                onChange={onChnageDistributor}
                                                styles={customStyles}
                                            />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                        
                                        {Service.editPerms(userPerms) == false ? "" : <a className="btn btn-update  btn-sm ml-2" type="submit" style={{ width: "35px", backgroundColor: "buttonface", float: "right" }} onClick={clickPayPopup} >{t('Pay')}</a>}
                                        {showbutton === true ?
                                            <>
                                                



                                            </>
                                            :
                                            ''
                                        } </div>
                                </div>
                                <div className="scrollbar-class mb-1">
                                    <table className="table table-bordered text-left mt-2" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} disabled={isDisabled} /></th>
                                                <th id="SNo" style={{ backgroundColor: (filterValueDict.SNo ? '#6cae55' : '') }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.SNo')}</span>
                                                       
                                                    </div>
                                                </th>
                                                <th id="DistributorCode" style={{ backgroundColor: (filterValueDict.DistributorCode ? '#6cae55' : ''), width: '82px' }} title={t('Policies.DistributorCode')}
                                                    hidden={hideColumn.includes('DistributorCode') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.DistCode')}</span> &nbsp;&nbsp;
                                                     
                                                    </div>
                                                </th>
                                                <th id="DistributorName" style={{ backgroundColor: (filterValueDict.DistributorName ? '#6cae55' : ''), width: '143px' }} title={t('Policies.DistributorName')}
                                                    hidden={hideColumn.includes('DistributorName') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.DistributorName')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                                <th id="DocNumber" style={{ backgroundColor: (filterValueDict.DocNumber ? '#6cae55' : ''), width: '95px' }} title={t('Policies.DocNumber')}
                                                    hidden={hideColumn.includes('DocNumber') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.DocNumber')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                                <th id="BillNumber" style={{ backgroundColor: (filterValueDict.BillNumber ? '#6cae55' : ''), width: '95px' }} title={t('Policies.BillNumber')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.BillNumber')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="BillDate" style={{ backgroundColor: (filterValueDict.BillDate ? '#6cae55' : ''), width: '91px' }} title={t('Policies.BillDate')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.BillDate')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                                <th id="LRDate" style={{ backgroundColor: (filterValueDict.LRDate ? '#6cae55' : ''), minWidth: '85px' }} title={t('Policies.LRDate')}
                                                    hidden={hideColumn.includes('LRDate') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.LRDate')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                                <th id="BillAmount" style={{ backgroundColor: (filterValueDict.BillAmount ? '#6cae55' : ''), minWidth: '111px' }} title={t('Policies.BillAmount')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.BillAmount')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="Gst" style={{ backgroundColor: (filterValueDict.Gst ? '#6cae55' : ''), minWidth: '100px' }} title={t('Policies.Gst')}
                                                    hidden={hideColumn.includes('Gst') ? true : false}>
                                                    <div className="d-flex text-nowrap"></div>
                                                    <span>{t('Policies.GST')}</span> &nbsp;&nbsp;
                                                   
                                                </th>
                                                <th id="OutstandingAmount" style={{ backgroundColor: (filterValueDict.OutstandingAmount ? '#6cae55' : ''), width: '105px' }} title={t('Policies.Outstanding')}
                                                    hidden={hideColumn.includes('OutstandingAmount') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.Outstanding')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                                <th id="DaysDue" style={{ backgroundColor: (filterValueDict.DaysDue ? '#6cae55' : ''), width: '45px' }} title={t('Policies.DaysDue')}
                                                    hidden={hideColumn.includes('DaysDue') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.DaysDue')}</span>
                                                      
                                                    </div>
                                                </th>
                                                <th id="EligibleForDiscount" style={{ backgroundColor: (filterValueDict.EligibleForDiscount ? '#6cae55' : ''), width: "61px" }} title={t('Policies.EligibleForDiscount')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.EligibleForCD')}</span> &nbsp;&nbsp;
                                                     
                                                    </div>
                                                </th>
                                                <th id="FullPayment" style={{ backgroundColor: (filterValueDict.FullPayment ? '#6cae55' : '') }} title={t('Policies.FullPayment')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.FullPayment')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="AmountBeingPaid" style={{ backgroundColor: (filterValueDict.AmountBeingPaid ? '#6cae55' : ''), width: '118px' }} title={t('Policies.AmountBeingPaid')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.AmtBeingPaid')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="AmountForCashDiscount" style={{ backgroundColor: (filterValueDict.AmountForCashDiscount ? '#6cae55' : ''), width: '145px' }} title={t('Policies.AmountForCashDiscount')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.AmountForCD')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="CashDiscountAmount" style={{ backgroundColor: (filterValueDict.CashDiscountAmount ? '#6cae55' : ''), width: '82px' }} title={t('Policies.CashDiscountAmount')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.CDAmount')}</span> &nbsp;&nbsp;
                                                      
                                                    </div>
                                                </th>
                                                <th id="TotalDue" style={{ backgroundColor: (filterValueDict.TotalDue ? '#6cae55' : ''), width: '97px' }} title={t('Policies.TotalDue')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.TotalDue')}</span> &nbsp;&nbsp;
                                                     
                                                    </div>
                                                </th>
                                                <th id="AmountPayable" style={{ backgroundColor: (filterValueDict.AmountPayable ? '#6cae55' : ''), width: '97px' }} title={t('Policies.AmountPayable')}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Policies.AmtPayable')}</span> &nbsp;&nbsp;
                                                       
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead >
                                        <tbody>
                                            {
                                                showspinner === true ?
                                                    <tr>
                                                        <>
                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        </>
                                                    </tr>

                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>

                                                            <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>

                                                              
                                                                <td ><input id={listVal.Id} type="checkbox" value={`${listVal.Name},${listVal.ISActive}`} checked={checkIndexValue.includes(index + 1) ? true : false} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive, index + 1)} name="chkBox" disabled={(index + 1 > disabledBelowIndex && isDisabled) || index + 1 == 1 ? true : false} /></td>
                                                                <td>{index + 1}</td>
                                                                <td hidden={hideColumn.includes('DistributorCode') ? true : false}>{listVal.DistributorCode}</td>
                                                                <td hidden={hideColumn.includes('DistributorName') ? true : false}>{listVal.DistributorName}</td>
                                                                <td hidden={hideColumn.includes('DocNumber') ? true : false}>{listVal.DocNumber}</td>
                                                                <td hidden={hideColumn.includes('BillNumber') ? true : false}>{listVal.BillNumber}</td>
                                                                <td hidden={hideColumn.includes('BillDate') ? true : false}>{moment(listVal.BillDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('LRDate') ? true : false}>{moment(listVal.LRDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('BillAmount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.BillAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('Gst') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.Gst).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('OutstandingAmount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.OutstandingAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('DaysDue') ? true : false}>{listVal.DaysDue}</td>
                                                                <td hidden={hideColumn.includes('EligibleForDiscount') ? true : false} style={{ textAlign: 'center' }}> <input name="chkBox" type="checkbox" disabled={true} checked={listVal.EligibleForDiscount} ></input>
                                                                </td>
                                                                <td hidden={hideColumn.includes('FullPayment') ? true : false}>
                                                                    <select style={{ width: "60px", height: "30px", padding: "3px", borderColor: "gainsboro" }} placeholder="Select"
                                                                        value={listVal.FullPayment} onChange={(event) => OnChangePayment(event, listVal, index)} disabled={index + 1 > disabledBelowIndex && isDisabled && canModify}>
                                                                        <option value="true" key="true">Full</option>
                                                                        <option value="false" key="false">Partial</option>
                                                                    </select></td>
                                                                
                                                                <td>
                                                                    <span>{listVal.Currency}</span>
                                                                    <input name="AmountBeingPaid" type="text" value={listVal.AmountBeingPaid === null ? '' : + listVal.AmountBeingPaid} // Convert null to empty string for display
                                                                        disabled={listVal.FullPayment == true || listVal.FullPayment == "true"}
                                                                     
                                                                        style={{ width: '70px' }}
                                                                        onChange={(event) => OnChangeAmountBeingPaid(event, listVal, index)}
                                                                        onBlur={(event) => OnBlurAmountBeingPaid(event, listVal, index)}
                                                                    />
                                                                </td>

                                                                <td hidden={hideColumn.includes('AmountEligibleForCashDiscount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.AmountEligibleForCashDiscount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('CashDiscountAmount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.CashDiscountAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('TotalDue') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.TotalDue).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('AmountPayable') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.AmountPayable).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                            </tr>
                                                        )

                                            }



                                        </tbody>
                                    </table >
                                </div >

                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control mt-1' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control mt-1' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row mb-1'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""
                                }
                                {
                                    showfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                     
                                            {filterCol === 'DistributorCode' ||
                                                filterCol === 'DistributorName' ||
                                                filterCol === 'DocNumber' ||
                                                filterCol === 'BillNumber' ||
                                                filterCol === 'BillAmount' ||
                                                filterCol === 'Gst' ||
                                                filterCol === 'OutstandingAmount' ||
                                                filterCol === 'DaysDue' ||
                                                filterCol === 'EligibleForDiscount' ||
                                                filterCol === 'FullPayment' ||
                                                filterCol === 'AmountBeingPaid' ||
                                                filterCol === 'AmountForCashDiscount' ||
                                                filterCol === 'CashDiscountAmount' ||
                                                filterCol === 'TotalDue' ||
                                                filterCol === 'AmountPayable' ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                </>

                                            }

                                            {filterCol === 'LRDate' || filterCol === 'BillDate' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                            {filterCol === 'LastModifiedBy' || filterCol === 'status' || filterCol === 'CreatedBy' ?
                                                
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                        <td>{t('SelectAll')}</td>
                                                                    </tr>
                                                                    {
                                                                        dropdownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                                <td>{item.Name}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            
                                            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    showPopup === true ?
                                        <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

                                            {showPopup === true ?
                                                <div className="container" id="Popup">
                                                    <div className="row">
                                                        <div className="col-lg-12 pr-lg-2">
                                                            <div className="card text-center card_border">
                                                                <div className="card-header chart-grid__header">
                                                                    <div className="row">
                                                                        <div className="col-md-10 text-left">
                                                                            {t('Payment Summary')}
                                                                            <button className="btn btn-outline-dark  btn-sm" onClick={clickClosePayPopup} style={{ float: "right", width: "45px" }} type="submit">{t('Close')}</button>
                                                                            <button className="btn btn-outline-dark  btn-sm" onClick={clickProceed} style={{ float: "right", width: "55px" }} type="submit">{t('Procced')}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body" style={{ minHeight: "55vh" }}>
                                                                  
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <table className="table table-bordered text-left mt-2">
                                                                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                                                                    <tr>


                                                                                        <th>{t('Policies.TotalAmountBeingPaid')}</th>
                                                                                        <th>{t('Policies.TotalCashDiscount')}</th>
                                                                                        <th>{t('Policies.TotalAmountPayable')}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        showspinnerPopup === true ?

                                                                                            <>
                                                                                                <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                                                <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                                                <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                                            </>
                                                                                            :

                                                                                            <tr>
                                                                                                <td>{Currency + " " + Service.currencyFormat(parseFloat(totalAmountBeingPaid).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                                                <td>{Currency + " " + Service.currencyFormat(parseFloat(totalCashDiscount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                                                <td>{Currency + " " + Service.currencyFormat(parseFloat(totalAmountPayable).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                                            </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                ''
                                            }

                                        </div>
                                        :
                                        ""
                                }
                            </div >

                        </div >
                    </div >

                </div >
            </div >


            {
                showAddColumnPopup === true ?
                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                        <div className="container">
                            <div className="row">
                                <Select
                                    className='col-md-10 textbox-padding fontsizedropdown'
                                    options={colVisibilityOption}
                                    placeholder="Select"
                                    value={colvisiOptionVal}
                                    onChange={OnChangeColVisibilityList}
                                    styles={customStyles1}
                                />
                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                            </div>
                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                </div>
                            </div>
                            <div className="row">
                                <table className='ml-2' style={{ fontSize: '0.70rem' }}>
                                    {
                                        addRemoveCol.map((item, index) => (
                                            <tr>
                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                /> {t(item.label)}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                            <div className="row mr-2" style={{ float: "right" }}>
                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }


            {
                showCreateColumnListPopup === true ?
                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                </div>
                            </div>
                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                </div>
                            </div>
                            <div className="row">
                                <table className='ml-2' style={{ fontSize: '0.70rem' }}>
                                    {
                                        createColumnListOption.map((item, index) => (
                                            <tr>
                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                /> {t(item.label)}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                            <div className="row mr-2" style={{ float: "right" }}>
                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    : <></>
            }


            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            <ToastContainer autoClose={1000} />
        </div >

    )

}
export default PaymentPendingComponent