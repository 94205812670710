import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import EarlyBirdPolicyList from '../../../Components/EndurancePolicies/EarlyBirdPolicy/EarlyPolicyList';

function EarlyBirdPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <EarlyBirdPolicyList {...props}  navigate={navigate} id={params.id} /> 
         </div>
     );  

}
export default EarlyBirdPolicy