import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewSlabBasedPolicy from '../../../Components/EndurancePolicies/SlabBasedPolicy/CreateNewSlabBasedPolicy';

function CreateSlabBasedPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewSlabBasedPolicy {...props}  navigate={navigate} id={params.id} name={params.name}/>
         </div>
     );  

}
export default CreateSlabBasedPolicy