import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewFixedValuePolicy from '../../../Components/EndurancePolicies/FixedValuePolicy/CreateNewFixedValuePolicy';

function CreateFixedValuePolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewFixedValuePolicy {...props}  navigate={navigate} id={params.id} name={params.name}/>
         </div>
     );  

}
export default CreateFixedValuePolicy