import React, { useState, useEffect } from 'react';
import classes from './DataImport.module.css';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import CustomMessagePopup from '../../../UI/CustomMessagePopup';
import moment from 'moment/moment';
import $ from 'jquery';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Form, Row, Col } from 'react-bootstrap';
import Service from '../../../Services/Service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SessionManage from "../../../SessionManagement/SessionManage";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const customStyles = {
	control: base => ({
		...base,
		minHeight: 28,
		maxHeight: 100,
	}),
	dropdownIndicator: base => ({
		...base,
		padding: 2
	}),
	clearIndicator: base => ({
		...base,
		padding: 2
	}),
	multiValue: base => ({
		...base,

	}),
	valueContainer: base => ({
		...base,
		padding: '0px 2px'
	}),
	input: base => ({
		...base,
		margin: 0,
		padding: 0
	})
};

const bussiness = [{ "Name": "", "Label": "" }];
const oem = [{ "Name": "", "Label": "" }];
const segment = [{ "Name": "", "Label": "" }];
const mappingattribute = [{ "Name": "", "Label": "" }];
const productlinetemplate = [{ "Name": "", "Label": "", "BussinessUnit": "" }];
const productfamilytemplate = [{ "Name": "", "Label": "", "ProductLine": "" }];
const productsubfamilytemplate = [{ "Name": "", "Label": "", "ProductFamily": "" }];
const uomtemplate = [{ "Name": "", "Label": "", "IsoCode": "" }];
const producttemplate = [{
	"MaterialNo": "", "ItemName": "", "UOM": "", "SubCategory": "", "Vehicletype": "", "OEM": ""
}];
const salessubregiontemplate = [{ "Name": "", "Label": "", "SalesRegion": "" }];
const salesorgtemplate = [{ "Name": "", "Label": "", "SalesSubRegion": "" }];
const salesofficetemplate = [{ "Name": "", "Label": "", "SalesOrg": "" }];
const salesgrouptemplate = [{ "Name": "", "Label": "", "SalesOffice": "" }];
const customerlevel2template = [{ "Name": "", "Label": "", "CustomerLevel1": "" }];

const customertemplate = [{ "CustomerCode": "", "CustomerName": "", "Station": "", "State": "", "Zone": "", "Stockist": "","JoiningDate":"" }];


const currencytemplate = [{ "IsoCode": "", "Description": "", "Phone": "", "Prefix": "", "Suffix": "", "MathPrecision": "", "DisplayPrecision": "", "DisplayPerunitPrecision": "", "ExportPrecision": "" }];
const transactiontemplate = [{
	"Product": "", "Customer": "", "CustomerName": "", "Plant": "",
	"Quantity": "", "Netprice": "", "TransactionDate": "", "QuoteId": "", "Uom": "", "Currency": "", "InvoiceNumber": "",
	"LineItem": "", "Saleshierarchy": "", "PaymentReceivedDate": "", "Revenue": "", "TotalValue": "",
	"DocNumber": "", "Lrdate": "", "Bookeddate": ""
}];
const ExchnageRatetemplate = [{ "EffectiveDate": "", "FromCurrency": "", "ToCurrency": "", "Rate": "" }];

const PaymentsTerms = [{ "Name": "", "label": "", "NoOfDays": 0, "Isblocked": false, "BlockedReason1": "", "BlockedReason2": "", "BlockedReason3": "", "BlockedReason4": "", "Locked": false }];
const InvoicePaymentDetailsTemplate = [{ "Customer": "", "InvoiceNumber": "", "Paymentdate": "", "Amountreceived": "" }];
const LeadgerTemplate = [{ "Customer": "", "DocDate": "", "PostingDate": "", "Amountreceived": "", "Particulars": "" }];
const DistributorTemplate = [{ "Name": "", "Label": "", "City": "", "Phone": "", "State": "", "Country": "" }];
const DistributorTransactionTemplate = [{
	"Product": "", "Distributor": "", "Salesrep": "", "Plant": "",
	"Quantity": "", "InvoicePrice": "", "TransactionDate": "", "QuoteId": "", "Uom": "", "Currency": "", "InvoiceNumber": "",
	"Discount": "", "LineItem": "", "Saleshierarchy": "", "PaymentReceivedDate": "", "Revenue": "", "TotalValue": ""
}];
const CountryTemplate = [{ "Name": "", "Label": "" }];
const SubCategoryTemplate = [{ "Name": "", "Label": "", "Category": "" }];
const CountryTemplateFail = [{ "Name": "", "Label": "", "ErrorMessage": "" }];
const DistributorCountryTemplate = [{ "DistributorName": "", "DistributorLabel": "", "CountryName": "", "CountryLabel": "" }];
const InvoicePending = [{ "DistributorCode": "", "DistributorName": "", "DocNumber": "", "BillNumber": "", "BillAmount": 0.0, }];
const CashDiscountDaysDue = [{ "Name": "", "Label": "" }];
const SalesOrderTemplate = [{ "SalesOrderNo": "", "SalesOrderDate": "", "Customer": "", "Product": "", "OrderedQty": "", "OrderedValue": "" }];
const InvoicePaymentDetailTemplate = [{ "Customer": "", "InvoiceNumber": "", "InvoiceDate": "", "Paymentdate": "", "Amountreceived": "" }];
const DataImport = () => {
	//let FailedMessage=[];
	const location = useLocation();
	const [selectedFile, setSelectedFile] = useState();
	const [template, settemplate] = useState([]);
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showMessageModal, setShowMessageModal] = useState(false);
	const [showspinner, setshowspinner] = useState(false);
	const [selectedModule, setselectedModule] = React.useState("");
	const [array, setArray] = useState([]);
	const [data, setdata] = useState('');
	const [failedlist, setfailedlist] = useState([]);
	const [importhistory, setimporthistory] = useState([]);
	const [progressvalue, setprogressvalue] = useState('');
	const [selectedOptions, setSelectedOptions] = useState();
	const fileReader = new FileReader();
	const [userPerms, setuserPermission] = useState([]);
	const [editPerms, setEditPerms] = useState([]);
	const [t, i18n] = useTranslation('translation');
	const [totalRecordInserted, setTotalRecordInserted] = useState(0);
	const [inputField, setInputField] = useState({
		value: "",
	})
	const [filtertext, setfiltertext] = useState('');
	const [file, setfile] = useState();
	const [filterCol, setFilterCol] = useState('');
	const [showfilterpopup, setshowfilterpopup] = useState(false);
	const [filterValueDict, setFilterValueDict] = useState({
		Modulename: '',
		Status: '',
		Startdate: '',
		Enddate: '',
		ImportedBy: '',
	});
	const [paging, setpaging] = useState({
		pageSize: 10,
		pageNumber: 1
	})
	const [showLoadMore, setshowLoadMore] = useState('');
	const [columnAscending, setColumnAscending] = useState(true);
	const [sortColName, setSortColName] = useState("Modulename");

	const [totalselectedshow, settotalselectedshow] = useState('');
	const [totalselectedcount, settotalselectedcount] = useState(0);
	const [totalCountlst, settotalCountlst] = useState('');


	// New state to manage the Check All checkbox
	const [checkAllChecked, setCheckAllChecked] = useState(false);
	const [checkedValue, setCheckedValue] = useState([]);
	const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
	const [checkedValueid, setCheckedValueid] = useState([]);
	const [checkedValueStatus, setCheckedValueStatus] = useState([]);
	const [allCheckedHistory, setAllCheckedHistory] = useState(false);
	const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);


	const [modules, setmodules] = useState([]);
	let id = SessionManage.getuserid();
	useEffect(() => {
		getUserPermission(location.pathname);
		getData("Transaction/GetAllModules");
	}, [0])

	const getUserPermission = (pathname) => {
		Service.GetUserPermission(pathname).then((res) => {
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
					if (typeof res.data.DataList !== 'undefined') {
						var permission = res.data.DataList;
						if (typeof permission !== 'undefined') {
							if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
								setEditPerms(false);
							} else {
								setEditPerms(true);
							}
						}
						else {
							setEditPerms(false);
						}
					}
				}
			}
		});
	}

	const requiredLabels = [
		'Category',
		'SubCategory',
		'Product',
		'Customer',
		'SalesOrder',
		'TransactionRecord',
		'Invoicepaymentdetail',
		'PaymentsTerms',
		'BusinessUnit',
		'OEM',
		'Segment',
		'MappingAttribute'
	];

	const getData = (method) => {
		Service.getdata(method).then((res) => {
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					if (res.status === 200) {
						//setmodules(res.data);
						console.log(res.data, "Module-Data");
						const filteredModules = res.data.filter(module => requiredLabels.includes(module.ModelName));
                        setmodules(filteredModules);
					}
				}
			}

		});
	}

	const gethistoryData = (method, pageSize, pageNumber, sortType, SortProperty, list) => {
		Service.getdataWithPagingandfilter(method, pageSize, pageNumber, sortType, SortProperty, list).then((res) => {
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					if (res.status === 200) {
						//debugger
						const rowData = res.data.DataList;
						setimporthistory(res.data.DataList);

						//setshowLoadMore(true);
						LoadMore(rowData.length, res.data.Totalcount)
						setshowspinner(false)
						console.log("15/11", res.data.DataList);
					}
				}
			}
		});
	}
	console.log("15/11", importhistory)

	const changeHandler = (event) => {

		setfile(event.target.files[0]);
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	// const csvReport1 = {
	// 	data: template,
	// 	//headers: headers,
	// 	filename: selectedModule+'_Template.csv'
	// };

	const changeSelectOptionHandler = (data) => {
		//debugger
		setshowspinner(true)
		setSelectedOptions(data)
		setselectedModule(data.ModelName);
		console.log("21/10", data)
		console.log("21/10", data.ModelName)
		gethistoryData("Transaction/GetImportExportHistoryByModule?modulename=" + data.ModelName, paging.pageSize, paging.pageNumber, "Ascending", "Id", [])
		if (data.ModelName === "BusinessUnit") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "OEM") {
			settemplate(oem);
		}
		else if (data.ModelName === "Segment") {
			settemplate(segment);
		}
		else if (data.ModelName === "MappingAttribute") {
			settemplate(mappingattribute);
		}
		else if (data.ModelName === "ProductLine") {
			settemplate(productlinetemplate);
		}
		else if (data.ModelName === "ProductFamily") {
			settemplate(productfamilytemplate);
		}
		else if (data.ModelName === "ProductSubFamily") {
			settemplate(productsubfamilytemplate);
		}
		else if (data.ModelName === "CatI") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIV") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "ProductAttribute5") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "UOM") {
			settemplate(uomtemplate);
		}
		else if (data.ModelName === "Product") {
			settemplate(producttemplate);
		}
		else if (data.ModelName == "SalesRegion") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "SalesSubRegion") {
			settemplate(salessubregiontemplate);
		}
		else if (data.ModelName == "SalesOrg") {
			settemplate(salesorgtemplate);
		}
		else if (data.ModelName == "SalesOffice") {
			settemplate(salesofficetemplate);
		}
		else if (data.ModelName == "SalesGroup") {
			settemplate(salesgrouptemplate);
		}
		else if (data.ModelName == "CustomerLevel1") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "CustomerLevel2") {
			settemplate(customerlevel2template);
		}
		else if (data.ModelName == "Customer") {
			settemplate(customertemplate);
		}
		else if (data.ModelName == "TransactionRecord") {
			settemplate(transactiontemplate);
		}
		else if (data.ModelName == "Currency") {
			settemplate(currencytemplate);
		}
		else if (data.ModelName == "ExchangeRate") {
			settemplate(ExchnageRatetemplate);
		}
		else if (data.ModelName == "PaymentsTerms") {
			settemplate(PaymentsTerms);
		}
		else if (data.ModelName == "InvoicePaymentDetails") {
			settemplate(InvoicePaymentDetailsTemplate);
		}
		else if (data.ModelName == "Leadger") {
			settemplate(LeadgerTemplate);
		}
		else if (data.ModelName == "Distributor") {
			settemplate(DistributorTemplate);
		}
		else if (data.ModelName == "Country") {
			settemplate(CountryTemplate);
		}
		else if (data.ModelName == "DistributorTransaction") {
			settemplate(DistributorTransactionTemplate);
		}
		else if (data.ModelName == "DistributorCountry") {
			settemplate(DistributorCountryTemplate);
		}
		else if (data.ModelName == "InvoicePending") {
			settemplate(InvoicePending);
		}
		else if (data.ModelName == "CashDiscountDaysDue") {
			settemplate(CashDiscountDaysDue);
		}
		else if (data.ModelName == "Category") {
			settemplate(CountryTemplate);
		}
		else if (data.ModelName == "SubCategory") {
			settemplate(SubCategoryTemplate);
		}
		else if (data.ModelName == "SalesOrder") {
			settemplate(SalesOrderTemplate);
		}
		else if (data.ModelName == "Invoicepaymentdetail") {
			settemplate(InvoicePaymentDetailTemplate);
		}

		else {
			settemplate('');
			alert(`${t('DataImport.NoImportAvailable')}`);
		}

		console.log("75", template);
	};

	const csvReport1 = {
		data: template,
		//headers: headers,
		filename: selectedModule + '_Template.csv'
	};

	function CSVtoArray(text) {
		var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
		var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
		// Return NULL if input string is not well formed CSV string.
		if (!re_valid.test(text)) return null;
		var a = [];                     // Initialize array to receive values.
		text.replace(re_value, // "Walk" the string using replace with callback.
			function (m0, m1, m2, m3) {
				// Remove backslash from \' in single quoted values.
				if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
				// Remove backslash from \" in double quoted values.
				else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
				else if (m3 !== undefined) a.push(m3);
				return ''; // Return empty string.
			});
		// Handle special case of empty last value.
		if (/,\s*$/.test(text)) a.push('');
		return a;
	};

	function CSVtoArray1(text) {
		let ret = [''], i = 0, p = '', s = true;
		for (let l in text) {
			l = text[l];
			if ('"' === l) {
				s = !s;
				if ('"' === p) {
					ret[i] += '"';
					l = '-';
				} else if ('' === p)
					l = '-';
			} else if (s && ',' === l)
				l = ret[++i] = '';
			else
				ret[i] += l;
			p = l;
		}
		return ret;
	}

	const csvFileToArray = file => {

		var moduleactionname = "";
		if (selectedModule === "BusinessUnit") {
			moduleactionname = "Transaction/ImportBussinessUnit";
			
		}else if (selectedModule === "OEM") {
			moduleactionname = "Transaction/ImportOEM"

		}else if (selectedModule === "Segment") {
			moduleactionname = "Transaction/ImportSegment"

		}else if (selectedModule === "MappingAttribute") {
			moduleactionname = "Transaction/ImportMappingAttribute"

		} else if (selectedModule == "ProductLine") {
			moduleactionname = "Transaction/ImportProductLine";
		} else if (selectedModule == "ProductFamily") {
			moduleactionname = "Transaction/ImportProductFamily";
		} else if (selectedModule == "ProductSubFamily") {
			moduleactionname = "Transaction/ImportProductSubFamily";
		} else if (selectedModule == "CatI") {
			moduleactionname = "Transaction/ImportProductAttribute1";
		}
		else if (selectedModule == "CatII") {
			moduleactionname = "Transaction/ImportProductAttribute2";
		} else if (selectedModule == "ProductAttribute3") {
			moduleactionname = "Transaction/ImportProductAttribute3";
		} else if (selectedModule == "CatIII") {
			moduleactionname = "Transaction/ImportProductAttribute4";
		} else if (selectedModule == "CatIV") {
			moduleactionname = "Transaction/ImportProductAttribute5";
		} else if (selectedModule == "UOM") {
			moduleactionname = "Transaction/ImportUOM";
		}

		else if (selectedModule == "Product") {

			moduleactionname = "Transaction/ImportProduct"
		}
		else if (selectedModule == "SalesRegion") {
			moduleactionname = "Transaction/ImportSalesRegion";
		}
		else if (selectedModule == "SalesSubRegion") {
			moduleactionname = "Transaction/ImportSalesSubRegion";
		}
		else if (selectedModule == "SalesOrg") {
			moduleactionname = "Transaction/ImportSalesOrg";
		}
		else if (selectedModule == "SalesOffice") {
			moduleactionname = "Transaction/ImportSalesOffice";
		}
		else if (selectedModule == "SalesGroup") {
			moduleactionname = "Transaction/ImportSalesGroup";
		}
		else if (selectedModule == "CustomerLevel1") {
			moduleactionname = "Transaction/ImportCustomerLevel1";
		}
		else if (selectedModule == "CustomerLevel2") {
			moduleactionname = "Transaction/ImportCustomerLevel2";
		}
		else if (selectedModule == "Customer") {
			moduleactionname = "Transaction/ImportCustomer";
		}
		else if (selectedModule == "Currency") {
			moduleactionname = "Transaction/ImportCurrency";
		}
		else if (selectedModule == "TransactionRecord") {
			moduleactionname = "Transaction/ImportTransactionRecord";
		}
		else if (selectedModule == "ExchangeRate") {
			moduleactionname = "Transaction/ImportExchangeRate";
		}
		else if (selectedModule == "PaymentsTerms") {
			moduleactionname = "Transaction/ImportPaymentsTerms";
		}
		else if (selectedModule == "InvoicePaymentDetails") {
			moduleactionname = "Transaction/ImportInvoicePaymentDetails";
		}
		else if (selectedModule == "Leadger") {
			moduleactionname = "Transaction/ImportLeadger";
		}
		else if (selectedModule == "Distributor") {
			moduleactionname = "Transaction/ImportDistributor";
		}
		else if (selectedModule == "Country") {
			moduleactionname = "Transaction/ImportCountry";
		}
		else if (selectedModule == "DistributorCountry") {
			moduleactionname = "Transaction/ImportDistributorCountry";
		}
		else if (selectedModule == "DistributorTransaction") {
			moduleactionname = "Transaction/ImportDistributorTransaction";
		}

		else if (selectedModule == "InvoicePending") {
			moduleactionname = "Transaction/ImportInvoicePending";
		}
		else if (selectedModule == "Category") {
			moduleactionname = "Transaction/ImportCategory";
		}

		else if (selectedModule == "SubCategory") {
			moduleactionname = "Transaction/ImportSubCategory";
		}

		else if (selectedModule == "CashDiscountDaysDue") {
			moduleactionname = "Transaction/ImportCashDiscountDaysDue";
		}
		else if (selectedModule == "SalesOrder") {
			moduleactionname = "Transaction/ImportSalesOrder";
		}

		else if (selectedModule == "Invoicepaymentdetail") {
			moduleactionname = "Transaction/ImportInvoicePaymentDetail";
		}

		let lengthOfArray = array.length;
		let batchSize = 50000;
		const array1 = [];
		const array2 = [];
		const chunkedArray = [];
		let totalRecordProcessed = 0;
		let totalRecordFailed = 0;
		let totalRecord = 0;

		//debuger;
		// for (let i = 0; i < lengthOfArray; i += batchSize) {
		// 	const chunk = array.slice(i, i + batchSize);
		// 	chunkedArray.push(chunk);
		// }
		// for (let i = 0; i < chunkedArray.length; i++) {
		// 	const myObjStr = JSON.stringify(chunkedArray[i]);
		// 	const myObjStr1 = myObjStr.replace(/\\r/g, "");
		// 	const myObjStr2 = JSON.parse(myObjStr1);
		// 	inputField.value = myObjStr1;
		// 	let Users = inputField;


		// 			if (selectedModule !== "BusinessUnit") {

		// 				Service.createorupdateImport(moduleactionname, myObjStr2).then(res => {
		// 					if (typeof res !== 'undefined') {
		// 						if (typeof res.data !== 'undefined' && res.data !== 'null') {
		// 							if (res.status === 200) {

		// debugger
		// 								console.log(res.data)
		// 								totalRecord = totalRecord + res.data.totalRecord;
		// 								totalRecordProcessed = totalRecordProcessed + res.data.insertedRecord;
		// 								totalRecordFailed = totalRecordFailed + res.data.failedRecord;
		// 								setfailedlist(failedlist => [...failedlist, ...res.data.failedlist])

		// 							    setdata({ ...data, ['insertedRecord']: totalRecordProcessed, ['totalRecord']: totalRecord, ['failedRecord']: totalRecordFailed });
		// 								console.log(data);
		// 								if ((totalRecordFailed + totalRecordProcessed) == lengthOfArray) {
		// 									console.log("inside 459")
		// 									toast.success('Data Import Done !', {
		// 										position: toast.POSITION.TOP_RIGHT,
		// 									  });
		// 									// alert(`${t('DataImport.DataImportDone')}`);
		// 									if (selectedModule == "TransactionRecord") {
		// 										Service.postdata('Transaction/GenerateEligibleInvoice').then(res => {
		// 											if (typeof res !== 'undefined') {
		// 												if (typeof res.data !== 'undefined' && res.data !== 'null') {
		// 													if (res.status === 200) {

		// 													}
		// 												}
		// 											}
		// 										});
		// 									}
		// 								}
		// 							}
		// 							else {
		// 								//	alert(`${t('DataImport.FailedImport')}`)
		// 							}
		// 						}
		// 					}
		// 					//props.navigate('/Roles');
		// 					//alert(res)

		// 				});

		// 			}
		if (selectedModule != null) {
			debugger
			let SendData = new FormData();
			SendData.append('File', file);
			SendData.append('UserId', id);

			Service.createorupdateImportproduct(moduleactionname, SendData).then(res => {
				if (typeof res !== 'undefined') {
					if (typeof res.data !== 'undefined' && res.data !== 'null') {
						if (res.status === 200) {

							toast.success('Data Import Done !', {
								position: toast.POSITION.TOP_RIGHT,
							});

							totalRecord = totalRecord + res.data.totalRecord;
							totalRecordProcessed = totalRecordProcessed + res.data.insertedRecord;
							totalRecordFailed = totalRecordFailed + res.data.failedRecord;
							setfailedlist(failedlist => [...failedlist, ...res.data.failedlist])

							setdata({ ...data, ['insertedRecord']: totalRecordProcessed, ['totalRecord']: totalRecord, ['failedRecord']: totalRecordFailed });

							/*if ((totalRecordFailed + totalRecordProcessed) == lengthOfArray) {
							
								// toast.success('Data Import Done !', {
								// 	position: toast.POSITION.TOP_RIGHT,
								//   });
								 
								  changeSelectOptionHandler(moduleactionname)
								// alert(`${t('DataImport.DataImportDone')}`);
								if (selectedModule == "TransactionRecord") {
									Service.postdata('Transaction/GenerateEligibleInvoice').then(res => {
										if (typeof res !== 'undefined') {
											if (typeof res.data !== 'undefined' && res.data !== 'null') {
												if (res.status === 200) {

												}
											}
										}
									});
								}
							}*/

						}
						else {
							//	alert(`${t('DataImport.FailedImport')}`)
						}
					}
				}
				//props.navigate('/Roles');
				//alert(res)

			});

		}

		else {
			alert(`${t('DataImport.PleaseSelectModuleName')}`);
		}




	}

	const handleSubmission = (e) => {
		debugger
		e.preventDefault();

		if (selectedFile) {
			csvFileToArray(selectedFile);
			// fileReader.onload = function (event) {
			// 	const text = event.target.result;
			// 	csvFileToArray(text);
			// };

			//fileReader.readAsText(selectedFile);
		}
	};

	const csvReport = {
		data: failedlist,
		//headers: CountryTemplateFail,
		filename: 'Failed_Record.csv'
	};

	const headerKeys = Object.keys(Object.assign({}, ...array));

	const [divleft, setdivleft] = useState('');
	const [divtop, setdivtop] = useState('');
	const FilterColumn = (name) => {

		const scrollTop = window.scrollY - 120;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
		var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

		setshowfilterpopup(true)
		setFilterCol(name)

		if (name === 'Modulename') {
			var testDiv = document.getElementById("Modulename");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.DataTableName'))
		}
		if (name === 'Status') {
			var testDiv = document.getElementById("Status");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.Status'))
		}
		if (name === 'Startdate') {
			var testDiv = document.getElementById("Startdate");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.StartTime'))
		}
		if (name === 'Enddate') {
			var testDiv = document.getElementById("Enddate");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.EndTime'))
		}
		if (name === 'ImportedBy') {
			var testDiv = document.getElementById("ImportedBy");
			setdivleft(((testDiv.offsetLeft + 30) - 150) + "px");
			setfiltertext(t('DataImport.ImportedBy'))
		}
		if (name === 'Totalcount') {
			var testDiv = document.getElementById("Totalcount");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.TotalCount'))
		}
		if (name === 'RecordInserted') {
			var testDiv = document.getElementById("RecordInserted");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.RecordInserted'))
		}
		if (name === 'RecordFailed') {
			var testDiv = document.getElementById("RecordFailed");
			setdivleft(testDiv.offsetLeft + "px");
			setfiltertext(t('DataImport.RecordFailed'))
		}
	}

	const filterInput = (e) => {
		setFilterValueDict(() => ({
			...filterValueDict, [filterCol]: e.target.value,
		}));
	}
	const filterHandler = (flag) => {
		if (flag === 'Apply') {
			setshowfilterpopup(false)
			let list = [];
			for (var key in filterValueDict) {
				if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
					list.push({
						filterKey: key,
						FilterValue: filterValueDict[key]
					})
				}
			}
			if(selectedModule !== ""){
			    gethistoryData("Transaction/GetImportExportHistoryByModule?modulename=" + selectedModule, paging.pageSize, paging.pageNumber, "Ascending", "Id", list)
			}
			console.log("15/11", "Transaction/GetImportExportHistoryByModule?modulename=" + selectedModule, paging.pageSize, paging.pageNumber, "Ascending", "Id", list)
		}
		else {
			setshowfilterpopup(false)
			setFilterValueDict(() => ({
				...filterValueDict, [filterCol]: '',
			}));

			let list = [];
			for (var key in filterValueDict) {
				if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
					list.push({
						filterKey: key,
						FilterValue: filterValueDict[key]
					})
				}
			}
			if(selectedModule !== ""){
			    gethistoryData("Transaction/GetImportExportHistoryByModule?modulename=" + selectedModule, paging.pageSize, paging.pageNumber, "Ascending", "Id", list)
			}
			setFilterCol('')
		}
	}

	const LoadMore = (lst, totalcount) => {

		if (lst < totalcount) {
			setshowLoadMore(true);
		}
		else if (lst >= totalcount) {
			setshowLoadMore(false);
		} else {
			setshowLoadMore(false);
		}

	}

	const handleLoadMore = () => {
		let pagenumber = paging.pageNumber + 1;
		var sortType = columnAscending ? 'Ascending' : 'Descending';
		paging.pageNumber = pagenumber;
		setpaging(paging);
		debugger

		Service.getdataWithPagingandfilter(
			"Transaction/GetImportExportHistoryByModule?modulename=" + selectedModule,
			paging.pageSize,
			pagenumber,
			sortType,
			"Id",
			[]
		).then((res) => {
			 
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					if (res.status === 200) {
						if (res.data !== 'null' && res.data !== 'undefined') {
							let rowData = res.data.DataList;
							if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
								const currentlength = importhistory.length;
								if (res.status === 200) {
									setimporthistory((importhistory) => [...importhistory, ...res.data.DataList]);
									 
									console.log("65", res.data.DataList);
									LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount);

									const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
									console.log(allCheckBoxes, "21-6");

									if (!checkAllChecked && totalselectedcount != 0) {
										allCheckBoxes.forEach((each) => {
											const checkboxId = parseInt(each.id, 10);
											if (!isNaN(checkboxId)) {
												if (uncheckedBoxesHistory.includes(checkboxId)) {
													each.checked = false;
												} else {
													// each.checked = true;
													each.checked = checkedMultiValueid.includes(checkboxId);
												}
											}
										});
									} else {
										allCheckBoxes.forEach((each) => {
											each.checked = checkAllChecked;
										});
									}
								}
							}
						}
					}
				}
			}
		}).catch((error) => {
			console.error("Error during API call: ", error);
		  });
	};


	const SortColumn = (colName) => {
		var sortType = '';
		if (columnAscending === true) {
			setColumnAscending(false);
			sortType = "Descending";
		} else {
			setColumnAscending(true);
			sortType = "Ascending";
		}
		setSortColName(colName);
		// let pagesize = 20;
		let pagenumber = 1;
		// paging.pageSize = pagesize;
		paging.pageNumber = pagenumber;
		setpaging(paging);
		if(selectedModule !== ""){
		    gethistoryData("Transaction/GetImportExportHistoryByModule?modulename=" + selectedModule, paging.pageSize, paging.pageNumber, sortType, colName, [])
		}
	}


	const checkAll = (mycheckbox) => {
		const checkboxes = document.querySelectorAll("input[type='checkbox']");
		if (mycheckbox.target.checked) {
			settotalselectedshow(true);
			setAllCheckedHistory(true);
			checkboxes.forEach(function (checkbox) {
				checkbox.checked = true;
			});
			settotalselectedcount(totalCountlst);
			setCheckAllChecked(true); // Set state to manage Check All checkbox
		} else {
			settotalselectedshow(false);
			setAllCheckedHistory(false);
			checkboxes.forEach(function (checkbox) {
				checkbox.checked = false;
			});
			settotalselectedcount(0);
			setCheckAllChecked(false); // Set state to manage Check All checkbox
		}
	};


	const handleChange = (e, id, name, status) => {
		const isChecked = e.target.checked;
		const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
		const count = checkedBoxes.length;

		if (isChecked) {
			setCheckedValue(id);  // check box value - id
			setCheckedValueStatus(status); // check box value - Active status
			setCheckedValueid(name); // check box value - RA-0000
			setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
			count += 1;


			let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
			const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
			if (index !== -1) {
				tempUncheckedBoxesHistory.splice(index, 1);
			}
			setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

		} else {
			setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
			setCheckedValue([]);  // check box value - id
			setCheckedValueStatus([]); // check box value - Active status
			setCheckedValueid([]); // check box value - RA-0000
			setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
			count -= 1;

		}

		settotalselectedcount(count);
		settotalselectedshow(count > 0);

		// Uncheck the "Check All" checkbox if any individual checkbox is unchecked
		if (!isChecked) {
			setCheckAllChecked(false);
		} else if (count === totalCountlst) {
			setCheckAllChecked(true);
		}

		if (checkedBoxes.length == 1) {
			const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
			setCheckedValue(checkedBoxes[0].id)  // check box value - id
			setCheckedValueStatus(Recordstatus) // check box value - Active status
			setCheckedValueid(Recordname) //check box value - RA-0000
		}
	};

	const dateFormat = SessionManage.getcurrentdateformat();
	const timeFormat = "hh:mm A";

	//-----------------------------------------------------------------------

	return (
		<div class="container-fluid content-top-gap">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb my-breadcrumb">
					<li class="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
					<li class="breadcrumb-item active" aria-current="page">{t('DataImport.DataImport')}</li>
					{/* <li class="breadcrumb-item active" aria-current="page">Add Role</li> */}
				</ol>
			</nav>
			<ToastContainer autoClose={1000} />
			<div class="">
				<div class="row">
					<div class="col-lg-12 pr-lg-2">
						<form>
							<div class="card text-center card_border">

								<div class="card-body" style={{ minHeight: "70vh" }}>

									<div className='col-md-12  mb-4'>
										<div className='row'>
											<div className='col-md-3 l_width'>
												<p className={classes['Footer-Buttons']}>{t('DataImport.DataTableName')}</p>
											</div>
											<div className='col-md-4 m_width'>
												{/* <select onChange={changeSelectOptionHandler}>
												<option>Choose...</option>
												{modules.map((option) => <option value={option.ModelName}>{option.ModelName}</option>)}
											</select> */}
												<Select
													className='col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown'
													options={modules}
													placeholder={t('Choose')}
													value={selectedOptions}
													onChange={changeSelectOptionHandler}
													isSearchable={true}
													styles={customStyles}
												/>
											</div>
											{
												template.length !== 0 ?
													<div className='col-md-5'><p className={classes['Footer-Buttons']}> {editPerms == false ? "" : <>{t('DataImport.Template')} : <CSVLink  {...csvReport1}>{`${t('DataImport.Download')}${selectedModule} ${t('DataImport.Template')}`}</CSVLink></>}</p> </div>
													:
													''
											}
										</div>
										<br></br>
										<div className='row'>
											<div className='col-md-3'>
												<p className={classes['Footer-Buttons']}>{t('DataImport.SelectAFileToImportData')}</p>
											</div>
											<div className='col-md-3'>
												<input class="create-button" className={editPerms == false ? "disabledClass" : ""} type="file" name="file" onChange={changeHandler} />
											</div>
											<div className='col-md-3'>
												{isFilePicked ? (
													<div className={classes['Footer-Buttons']}>
														<p>{t('DataImport.FileType')}: {selectedFile.type}</p>
													</div>
												) : null}
											</div>
											<div className='col-md-3'>
												{isFilePicked ? (

													<button className='btn btn-sm btn-update ml-2' onClick={handleSubmission} style={{ width: '80px' }} >{t('DataImport.StartImport')}</button>

												) : null}
											</div>


										</div>



										<CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal}
											onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
										<CustomMessagePopup SuccessMessage={successMessage} show={showMessageModal}
											onHide={() => setShowMessageModal(false)}></CustomMessagePopup>
									</div>
									<br></br>
									<br></br>

									{

										data !== null || data !== undefined ?
											<div className='container m-10'>

												<table className='table table-bordered table-responsive'>
													<tr>
														<td>{t('DataImport.TotalRecordsRead')}</td>
														<td>{data.totalRecord}</td>
													</tr>
													<tr>
														<td>{t('DataImport.TotalRecordsInserted')}</td>
														<td>{data.insertedRecord}</td>
													</tr>
													<tr>
														<td>{t('DataImport.TotalRecordsFailed')}</td>
														<td>{data.failedRecord}</td>
													</tr>
													<tr>
														<td>{t('DataImport.ImportedFile')}</td>
														<td>
															<a href={`${data.filepath}`} download>Click to download</a>
														</td>
													</tr>
													{data.failedRecord > 0 ?
														<tr>
															<td>{t('DataImport.FailedRecordFile')}</td>
															<td>
																<a href={`${data.filepath}`} download>Click to download</a>
																{/* <CSVLink {...csvReport}>{t('DataImport.ExportToCSV')}</CSVLink> */}
															</td>
														</tr>
														:
														''
													}

												</table>
											</div>
											:
											''
									}
									<>
									<h5 className='mb-1'>{t('DataImport.ImportHistory')}</h5>
									<div className="scrollbar-class">
										{/* <h5>{t('DataImport.ImportHistory')}</h5>
										<br></br> */}

										<table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
											<thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
												<tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
													<th>No</th>
													<th id="Modulename" style={{ backgroundColor: (filterValueDict.Modulename ? '#6cae55' : ''), minWidth: "130px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.DataTableName')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('Modulename')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Modulename')}></span>
														</div>
													</th>
													<th id="Status" style={{ backgroundColor: (filterValueDict.Status ? '#6cae55' : ''), minWidth: "70px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.Status')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
														</div>
													</th>
													<th id="Startdate" style={{ backgroundColor: (filterValueDict.Startdate ? '#6cae55' : ''), minWidth: "90px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.StartTime')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('Startdate')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Startdate')}></span>
														</div>
													</th>
													<th id="Enddate" style={{ backgroundColor: (filterValueDict.Enddate ? '#6cae55' : ''), minWidth: "90px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.EndTime')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('Enddate')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Enddate')}></span>
														</div>
													</th>
													<th id="Totalcount" style={{ backgroundColor: (filterValueDict.Totalcount ? '#6cae55' : ''), minWidth: "100px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.TotalCount')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('Totalcount')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Totalcount')}></span>
														</div>
													</th>
													<th id="RecordInserted" style={{ backgroundColor: (filterValueDict.RecordInserted ? '#6cae55' : ''), minWidth: "120px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.RecordInserted')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('RecordInserted')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RecordInserted')}></span>
														</div>
													</th>
													<th id="RecordFailed" style={{ backgroundColor: (filterValueDict.RecordFailed ? '#6cae55' : ''), minWidth: "110px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.RecordFailed')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('RecordFailed')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RecordFailed')}></span>
														</div>
													</th>
													<th style={{ minWidth: "80px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.ErrorFile')}</span>
														</div>
													</th>
													<th style={{ minWidth: "80px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.ImportedFile')}</span>
														</div>
													</th>
													<th id="ImportedBy" style={{ backgroundColor: (filterValueDict.ImportedBy ? '#6cae55' : ''), minWidth: "100px" }}>
														<div className='d-flex text-nowrap'>
															<span>{t('DataImport.ImportedBy')}</span>
															<span className='fa fa-sort  ml-1 mt-1' onClick={() => SortColumn('ImportedBy')}></span>
															<span className='fa fa-filter  ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ImportedBy')}></span>
														</div>
													</th>
												</tr>
											</thead>
											{console.log("28-11", importhistory)}
											<tbody>
											{
                                            //   showspinner === true ?
                                            //     <tr>
                                            //       <>
                                            //         <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                            //         <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                            //         <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                            //       </>
                                            //     </tr>
                                            //     :
												importhistory.length === 0 ?
													<tr><td></td>
														<td colSpan="9" style={{ textAlign: 'center' }}>
															{t('NoDataAvailable')}
														</td>
													</tr>
													:
													importhistory.map((item, index) => (
														<tr key={index} >
															<td>{index + 1}</td>
															<td>{item.Modulename}</td>
															<td>{item.Status}</td>
															<td>{`${moment(item.Startdate).format(dateFormat)} ${moment(item.Startdate).format(timeFormat)}`}</td>
															<td>{`${moment(item.Enddate).format(dateFormat)} ${moment(item.Enddate).format(timeFormat)}`}</td>
															<td>{item.Totalcount}</td>
															<td>{item.RecordInserted}</td>
															<td>{item.RecordFailed}</td>
															<td>
																{item.Errorfile ? (
																	<a href={item.Errorfile} target='_blank' download>Download</a>
																) : (
																	<span>N/A</span>
																)}
															</td>
															<td> <a href={item.Filepath} target='_blank'> Download</a></td>

															<td>{item.ImportedBy}</td>
														</tr>

													))
												}

											</tbody>
										</table>
										{
											showLoadMore === true && importhistory.length>0 ?
												<div className='text-center container row'>
													<div className='col-md-5'></div>
													<div className='col-md-2'>
														<button className='btn btn-sm btn-update ml-1' type='button' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
													</div>
													<div className='col-md-5'></div>

												</div>
												:
												""
										}
									</div>
									</>
								</div>
								{showfilterpopup === true ?
									<div className='pop-up' style={{ left: divleft, top: divtop }}>
										{filterCol === 'Startdate' || filterCol === 'Enddate' ?
											<>
												<div className="container">
													<div className="row">
														<b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
													</div>
												</div>
												<div className="container mt-2">
													<div className="row">
														<input type='date' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
													</div>
												</div>
											</>

											:

											<>
												<div className="container">
													<div className="row">
														<b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
													</div>
												</div>
												<div className="container mt-2">
													<div className="row">
														<input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
													</div>
												</div>
											</>
										}
										<div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
											<button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
											<button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
										</div>
									</div>
									:
									<></>

								}
							</div>
						</form>

					</div>

				</div>
			</div>


		</div>
	)
}
export default DataImport;