import React from 'react';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import Service from '../../../Services/Service';
import classes from './CustomDialog.module.css';
import { useTranslation } from "react-i18next";

const CustomDialog = (props) => {
    const [t, i18n] = useTranslation('translation');
    return (

        <div className={props.size === 'lg' ? classes['Modal-div'] : classes['Modal-div-border']}

            {...props}
            size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className={classes.header} closeButton id="contained-modal-title-vcenter" >
                <h4 className={classes['title']} >{props.title}</h4>
            </div>
            <div className={classes['Modal-body']}>
                {props.children}
            </div>
            <div className={classes['Modal-Footar']}>

                <span className='row align-items-center'>
                    <span className='col-sm-3 border d-flex justify-content-center ml-1' style={{height:"22px"}}>
                        <label>
                           {t('Rebates.TotalProductsSelected')} - <span id="currentlength"></span> </label>
                    </span>
                    <span className='col-md-7'></span>

                    <span className='ml-5'>
                        {Service.editPerms(props.userPerms) == false ? "" : <button className="btn btn-outline-dark  btn-sm ml-1" onClick={props.onHide} style={{width: "80px"}}>{t('Save&Close')}</button>}
                    </span>
                </span>


                {/* <Button className={classes['Footer-Buttons']} onClick={props.onHide}>Close</Button> */}
            </div>
        </div>
    )
}
export default CustomDialog;