import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import Service from '../../Services/Service'
import SessionManage from '../../SessionManagement/SessionManage';
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Select from "react-select";
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import { useTranslation } from "react-i18next";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 28,
        maxHeight: 100,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2,
        fontSize: "0.75rem",
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        fontSize: "0.75rem",
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 2px',
        fontSize: "0.75rem",
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
    })
};
function CreateSchedulerComponent(props) {
    const [loading, setLoading] = useState(false);
    const [Schedulertype, setSchedulertype] = useState([]);
    const [valueSchedulertype, setvalueSchedulertype] = useState();

    const [SchedulerModule, setSchedulerModule] = useState([]);
    const [valueSchedulerModule, setvalueSchedulerModule] = useState();

    const [SetlFreqData, setSetlFreqData] = useState([]);
    const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");

    let loggedInUser = SessionManage.getuserid();
    const [t, i18n] = useTranslation('translation');
    const [inputField, setInputField] = useState({
        id: 0,
        SchedulerName: '',
        Label: '',
        CronExpression: '',
        ModuleId: 0,
        SchedulerTypeId: 0,
        FrequecyId: 0,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser
    })

    const inputsHandler = (e) => {

     
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const OnChangeSchedulerType = (data) => {

        setvalueSchedulertype(data)
        setInputField({ ...inputField, ['SchedulerTypeId']: data.Id })
        
        getDataModules("SchedulerJob/GetALLSchedulerModule?id=" + data.Id)
    }

    const OnChangeSchedulerModules = (data) => {

        setvalueSchedulerModule(data)
        setInputField({ ...inputField, ['ModuleId']: data.Id })
       
    }

    const OnChangeHandlerSetlFreq = (data) => {
        
        setSelectedOptionsSetlFreq(data)
        setInputField({ ...inputField, ['FrequecyId']: data.Id })
       
      }

    useEffect(() => {
     
        getData("SchedulerJob/GetALLSchedulerType");
        getSetlFreqData("settlementfrequency")
    }, [0])

        const isCronValid = (freq) => {
        var cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
        return cronregex.test(freq);
      }

    const checkValidation = () => {
        var hasError = false;
        if (inputField.SchedulerName == null || inputField.SchedulerName == '') {
            setErrorMessage('Please Enter Scheduler Name');
            hasError = true;
            setShowErrorModal(true)
        }
        else if (inputField.Label == null || inputField.Label == '') {
            setErrorMessage('Please Enter Scheduler Label');
            hasError = true;
            setShowErrorModal(true)
        }
        else if (inputField.SchedulerTypeId == '' || inputField.SchedulerTypeId == 'select' ||  inputField.SchedulerTypeId == null) {
            setErrorMessage('Please Enter Scheduler Label');
            hasError = true;
            setShowErrorModal(true)
        }
        else if (inputField.CronExpression == '' ||  inputField.CronExpression == null) {
            setErrorMessage('Please Enter CronExpression');
            hasError = true;
            setShowErrorModal(true)
            
        }else if(isCronValid(inputField.CronExpression)=== false){
            setErrorMessage('Please Enter Correct Expression');
            hasError = true;
            setShowErrorModal(true)
        }            
       
        return hasError;
    }

    const submitButton = (e) => {
    
        e.preventDefault();
        setLoading(true);
        let Schedulerfiled = inputField;
        var hasError = checkValidation()
        if (hasError == false) {
            
                Service.createorupdate("SchedulerJob/CreateOrUpdate", Schedulerfiled).then(res => {
                    setLoading(false);
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            alert("Schedule Created Successfully")
                            props.navigate('/scheduler');
                        }
                    }

                });
           
        }else {
            setLoading(false);
        }
    }

    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                         
                            setSchedulertype(res.data);
                        }
                    }
                }
            }

        });
    }

    const getDataModules = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            setSchedulerModule(res.data);
                        }
                    }
                }
            }

        });
    }

    const getSetlFreqData = (method) => {
        Service.getAlldata(method).then((res) => {
          if (typeof res !== 'undefined') {
            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              if (res.status === 200) {
                if (res.data !== 'null' && res.data !== 'undefined') {
                  setSetlFreqData(res.data)
                }
              }
            }
          }
    
        });
      }

    return (
        <>
            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li class="breadcrumb-item active" aria-current="page"><Link to="/Scheduler">{t('Schedular.SchedulerList')}</Link></li>
                    </ol>
                </nav>

                <div class="row mt-3" id="rebateProgramdiv">
                    <div class="col-lg-12 pr-lg-2">
                        <div class="card text-center card_border">
                            <div class="card-header chart-grid__header">
                                <div class="row">
                                    <div class="col-md-2">
                                        {t('Schedular.CreateScheduler')}
                                    </div>
                                    <div class="col-md-10">
                                    <button class="btn  btn-update btn-sm ml-2" style={{ float: "right",width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button>
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" style={{ minHeight: "60vh" }}>

                                {loading ? <>
                                    <div className="loader-container">
                                        <div className="spinner"></div>
                                    </div>
                                </> : ""}
                                <div class="container">
                                    <div class="row">
                                        <label class="col-md-2 text-left l_width">{t('Schedular.SchedulerName')}<span className={classes.mandatory}>*</span></label>                                     
                                        <input id="aid" onChange={inputsHandler} value={inputField.SchedulerName} autoComplete="no" name="SchedulerName" type="text" className="col-md-2 textbox-padding m_width" />

                                        <label class="col-md-2 text-left l_width">{t('Policies.Label')}<span className={classes.mandatory}>*</span></label>
                                        <input id="aid" onChange={inputsHandler} value={inputField.Label} autoComplete="no" name="Label" type="text" className="col-md-2 textbox-padding m_width" />


                                    </div>
                                    <div class="row mt-2">
                                        <label class="col-md-2 text-left l_width">{t('Schedular.CronExpression')}<span className={classes.mandatory}>*</span></label>
                                        <input id="aid" onChange={inputsHandler} value={inputField.CronExpression} name="CronExpression" type="text" class="col-md-2 textbox-padding m_width" />



                                    </div>

                                    <div class="row mt-2">
                                        <label class="col-md-2 mt-2 text-left l_width">{t('Schedular.SchedulerType')}<span className={classes.mandatory}>*</span></label>


                                        <Select
                                            className={`col-md-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width`}
                                            options={Schedulertype}
                                            placeholder={t('Select')}
                                            value={valueSchedulertype}
                                            onChange={OnChangeSchedulerType}
                                            isSearchable={true}
                                            styles={customStyles}
                                       
                                        />

                                        <label class="col-md-2 mt-2 text-left l_width">{t('Schedular.Modules')}<span className={classes.mandatory}>*</span></label>


                                        <Select
                                            className={`col-md-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width`}
                                            options={SchedulerModule}
                                            placeholder={t('Select')}
                                            value={valueSchedulerModule}
                                            onChange={OnChangeSchedulerModules}
                                            isSearchable={true}
                                            styles={customStyles}
                                      
                                        />
                                    </div>
                                    <div class="row mt-2">
                                        <label class="col-md-2 mt-2 text-left l_width">{t('Schedular.SchedulerFrequency')}<span className={classes.mandatory}>*</span></label>


                                        <Select
                                            className={`col-md-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width`}
                                            options={SetlFreqData}
                                            placeholder={t('Select')}
                                            value={selectedOptionsSetlFreq}
                                            onChange={OnChangeHandlerSetlFreq}
                                            isSearchable={true}
                                            styles={customStyles}
                                        
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}
export default CreateSchedulerComponent