import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../../Services/Service';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import SessionManage from "../../../SessionManagement/SessionManage";

function CreateNewSubDivideAccrualThresholdPolicy(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [customercheckAll, setCustomercheckAll] = useState(false);
    const [customergroupcheckAll, setCustomerGroupcheckAll] = useState(false);
    const [data, setData] = useState([]);
    const [groupdata, setGroupData] = useState([]);

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    let loggedInUser = SessionManage.getuserid();
    const [isLocked, setIsLocked] = useState(false);
    const [checkCustomerGroup, setcheckCustomerGroup] = useState(false);
    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    const [showCustomerCountpopup, setShowCustomerCountpopup] = useState("");
    const [showSelectedGroupCustomerpopup, setShowSelectedGroupCustomerpopup] = useState(false);
    const [showGroupCustomerCountpopup, setShowGroupCustomerCountpopup] = useState("");
    const [customerData, setCustomerData] = useState([]);
    const [groupCustomerData, setGroupCustomerData] = useState([]);
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [lockedBy, setLockedBy] = useState('');
    const [showtargetselection, setShowTargetSelection] = useState('');
  
    const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
    const [showtargettable, setShowTargetTable] = useState(false);
    let disable = false;
    const [showDeleteModal, SetShowDeleteModal] = useState(false);
    const [subDivideAccrualDetail, SetSubDivideAccrualDetail] = useState({
        "Id": 0,
        "targetTypeId": "",
        "lstCatCustExp": [],
        "CustomerGroup": false,
        "CreatedUserId": parseInt(loggedInUser),
        "LastModifiedUserId": parseInt(loggedInUser),
        "subdivideMinThresholdValue": []
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        getCustomerData("Customer/GetIndividualCustomersWithoutGroup")
        getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
        GetSubdivideTargetType();
        if (props.id !== 0) {
          
            getdata("SubDivideAccrualThreshold/GetById", props.id);
        }
    }, [])

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res !== undefined) {

                        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                            if (res.data.DataList !== undefined) {
                                setuserPermission(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }

    const getCustomerData = (method) => {
       
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            setCustomerData(res.data)
                            setData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
       
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                         
                            setGroupCustomerData(res.data.DataList)
                            setGroupData(res.data.DataList)
                        }
                    }
                }
            }

        });
    }

    const GetSubdivideTargetType = () => {
        Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setSubdivideTargetTypeList(res.data);
                }
            }
        });
    }

    const OnChangeHandlerGroupCustomer = (e, label, id, name) => {
        let update = [];
        const getallData = [...subDivideAccrualDetail.lstCatCustExp];
        let olddata = [...groupCustomerData];
        if (e.target.checked === true) {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            getallData.push({ "Id": id },);
            setGroupCustomerData(olddata);
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData.filter(i => i.Id !== id) })
            setGroupCustomerData(olddata);
        }
    }

    const OnChangeHandlerCustomer = (e, label, id, State) => {
        let update = [];
        const getallData = [...subDivideAccrualDetail.lstCatCustExp];
        let olddata = [...customerData];
        if (e.target.checked === true) {
         
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            getallData.push({ "Id": id },);
            setCustomerData(olddata);
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData })
        }
        else {
           
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })
            setCustomerData(olddata);
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData.filter(i => i.Id !== id) })
        }
    }

    const checkAllCustomer = (mycheckbox) => {

        const getallData = [...subDivideAccrualDetail.lstCatCustExp];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = true;
            
                getallData.push({ "Id": i.Id },);
            })
            setCustomerData(olddata);
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData })
        }
        else {
            setCustomercheckAll(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setCustomerData(olddata);
          
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: [] })
        }
    }
    const checkAllGroupCustomer = (mycheckbox) => {
   
        const getallData = [...subDivideAccrualDetail.lstCatCustExp];
        var checkboxes = document.querySelectorAll("input[name = 'customergroup-check']");
        if (mycheckbox.target.checked == true) {
            setCustomerGroupcheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = true;
       
                getallData.push({ "Id": i.Id },);
            })
            setGroupCustomerData(olddata);
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: getallData })
        }
        else {
            setCustomerGroupcheckAll(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setGroupCustomerData(olddata);
         
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['lstCatCustExp']: [] })
        }
    }
   

    const onChangeTargetSelection = (e) => {
       
        let id = e.value
       
        if (e.value === "Select") {
            setShowTargetTable(false)
        }
        else {
            setShowTargetTable(true)
            setShowTargetSelection(e.name)
            let temp = {}
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['subdivideMinThresholdValue']: temp, ['targetTypeId']: id })
        }
    }
   


    const customerSearch = (event) => {
        let list = customerData
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
                
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))
            });
            setCustomerData(searchResults)
        }
        else {
            setCustomerData(data)
        }
    }
    const groupcustomerSearch = (event) => {
        let list = groupCustomerData
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
                
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
            });
            setGroupCustomerData(searchResults)
        }
        else {
            setGroupCustomerData(groupdata)
        }
    }



    const getdata = (method, id) => {
       
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            setTimeout(() => {
                             
                                let tempCatData = subDivideAccrualDetail
                                tempCatData.targetTypeId = res.data.targetTypeId
                                setShowTargetSelection(res.data.targetType)
                                if (res.data.targetType == "Quarterly%") {
                               
                                }
                                if (res.data.targetType == "HalfYearly%") {
                                   
                                }
                                if (res.data.targetType == "Monthly%") {
                                  
                                }
                                tempCatData.CustomerGroup = res.data.CustomerGroup
                                tempCatData.Id = res.data.Id
                                let temp = res.data.lstCatCustExp.filter(e => e.Selected == true)
                               
                                tempCatData.subdivideMinThresholdValue = res.data.subdivideMinThresholdValue;
                                temp = temp.map((i) => {
                                    return { "Id": i.Id }
                                })
                                tempCatData.lstCatCustExp = temp
                                if (res.data.CustomerGroup == true) {
                                    setcheckCustomerGroup(true)
                                    setGroupCustomerData(res.data.lstCatCustExp)
                                    setGroupData(res.data.lstCatCustExp)
                                }
                                else {
                                    setCustomerData(res.data.lstCatCustExp)
                                    setData(res.data.lstCatCustExp)
                                }
                                SetSubDivideAccrualDetail(tempCatData)
                            }, 100);
                        }
                    }
                }
            }
        });
      
    }



    const handleGroupCust = (e) => {
        if (e.target.checked == true) {
            setcheckCustomerGroup(true)
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['CustomerGroup']: true, ['lstCatCustExp']: [] })
        }
        else {
            setcheckCustomerGroup(false)
            SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, ['CustomerGroup']: false, ['lstCatCustExp']: [] })
        }
    }


    const clickGroupCustomerPopup = (flag) => {
        if (flag === "Open") {
            setShowGroupCustomerCountpopup("Data")
            setShowSelectedGroupCustomerpopup(true)
            setShowSelectedCustomerpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setShowSelectedGroupCustomerpopup(false)
            setGroupCustomerData(groupdata)
        }
    }
    const clickGroupCustomerCountPopup = (flag) => {
        if (flag === "Open") {
            setShowGroupCustomerCountpopup("Count")
            setShowSelectedGroupCustomerpopup(true)
            setShowSelectedCustomerpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setShowSelectedGroupCustomerpopup(false)
        }
    }
    const clickCustomerCountPopup = (flag) => {
        if (flag === "Open") {
            setShowCustomerCountpopup("Count")
            setShowSelectedCustomerpopup(true)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setShowSelectedGroupCustomerpopup(false)
        }
    }

    const clickCustomerPopup = (flag) => {
        if (flag === "Open") {
            setShowCustomerCountpopup("Data")
            setShowSelectedCustomerpopup(true)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("80vw");
        }
        if (flag === "Close") {
            setShowSelectedCustomerpopup(false)
            setCustomerData(data)
        }
    }

    const handleSubDivideChange = (e) => {
  

        let temp = subDivideAccrualDetail
        let name = e.target.name
        var ddd = temp.subdivideMinThresholdValue;
        
        temp.subdivideMinThresholdValue[name] = e.target.value;
        SetSubDivideAccrualDetail(subDivideAccrualDetail, temp)

        SetSubDivideAccrualDetail({ ...subDivideAccrualDetail, temp })
      
    }


    const checkValidation = () => {
        let isValidPolicy = true;
        if (subDivideAccrualDetail.targetTypeId == null || subDivideAccrualDetail.targetTypeId == 0 || subDivideAccrualDetail.targetTypeId == '') {
            setShowErrorModal(true);
            setErrorMessage("Please Select SubDivide Target")
            isValidPolicy = false;
        }
        if (subDivideAccrualDetail.CustomerGroup == "false") {
            if (subDivideAccrualDetail.lstCatCustExp.length == 0 || subDivideAccrualDetail.lstCatCustExp.length < 0) {
                setShowErrorModal(true);
                setErrorMessage("Please Select Customer")
                isValidPolicy = false;
            }
        }
        if (subDivideAccrualDetail.CustomerGroup == "true") {
            if (subDivideAccrualDetail.lstCatCustExp.length == 0 || subDivideAccrualDetail.lstCatCustExp.length < 0) {
                setShowErrorModal(true);
                setErrorMessage("Please Select Group Customer")
                isValidPolicy = false;
            }
        }
      

        return isValidPolicy
    }


    const saveHandler = (flag) => {
        let isValidPolicy = checkValidation();
        let data = subDivideAccrualDetail
        if (isValidPolicy === true) {
            Service.createorupdate("SubDivideAccrualThreshold/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        

                        window.location.href = "/SubDivideAccrualThresholdPolicy";

                    }
                }

            });
        }
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
           
            props.navigate("/SubDivideAccrualThresholdPolicy")
        }
        if (flag == "Close") {
           
            props.navigate("/SubDivideAccrualThresholdPolicy")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
    }

    const unlockEntity = () => {
      

    }
   
    const disabledClass = false;
    const class_textbox = `col-md-2 mt-2 textbox-padding  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const class_select = ` col-md-2 textbox-padding mt-2 fontsizedropdown  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const DeleteHandler = () => {
        Service.DeleteCategoryException('SubDivideAccrualThreshold/Delete', props.id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                 
                    if (res.status === 200) {
                        if (res.data == "Success") {
                            alert('Deleted Successfully !')
                            props.navigate("/SubDivideAccrualThresholdPolicy");
                        }
                        else {
                            setShowErrorModal(true)
                            setErrorMessage("Active Policy cannot be deleted")
                        }
                    }
                }
                else {
                    setErrorMessage("Cannot Delete Policy")
                    setShowErrorModal(true)
                }
            }
            else {
                setErrorMessage("Cannot Delete Policy")
                setShowErrorModal(true)
            }

        });
    }
    const checkDelete = (flag) => {
        if (flag == "Open") {
            SetShowDeleteModal(true)
        }
        if (flag == "Close") {
            SetShowDeleteModal(false)
        }
        if (flag == "Proceed") {
            SetShowDeleteModal(false)
            DeleteHandler()
        }
    }


    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/SubDivideAccrualThresholdPolicy">{`${t('CreateNew')} ${t('Policies.SubDivideAccrualThreshold')} ${t('Policies.PolicyList')}`}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{`${t('CreateNew')} ${t('Policies.SubDivideAccrualThreshold')} ${t('Policies.PolicyList')}`}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {`${t('CreateNew')} ${t('Policies.SubDivideAccrualThreshold')} ${t('Policies.PolicyList')}`}
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} type="submit" onClick={(event) => saveHandler(event)}>{t('Save')}</button>}
                                        {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} type="submit" onClick={(event) => DeleteHandler()}>{t('Delete')}</button>}

                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left">{t('Policies.SubDivideTarget')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_select}
                                            id="subdivideTargetTypeId"
                                          
                                            options={subdivideTargetTypeList}
                                          
                                            placeholder="Select"
                                            onChange={(event) => onChangeTargetSelection(event)}
                                            isSearchable={true}
                                            styles={customStyles}
                                            value={subdivideTargetTypeList.filter(function (option) {
                                                return option.name === `${showtargetselection}`;
                                            })}
                                        />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left" style={{ marginTop: "12px", marginBottom: "12px" }}>{t('Rebates.CustomerGroup')}</label>
                                        <input type="checkbox" style={{ marginLeft: "5px" }} checked={checkCustomerGroup} onChange={(event) => handleGroupCust(event)}></input>
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    {checkCustomerGroup === true ?
                                        <div className="row">
                                            <label className="col-md-2 text-left">{t('Rebates.GroupCustomer')}</label>
                                            <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickGroupCustomerPopup('Open')}>{t('Select')}</button>
                                            <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickGroupCustomerCountPopup('Open')}>
                                                <label style={{ paddingTop: "5px" }}>({groupCustomerData.filter((e) => e.Selected === true).length})Selected Group Customers</label></a></span>
                                        </div>
                                        :
                                        ""
                                    }
                                    {checkCustomerGroup === false ?
                                        <div className="row">
                                            <label className="col-md-2 text-left">{t('Rebates.Customer')}Customer</label>
                                            <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickCustomerPopup('Open')}>{t('Select')}</button>
                                            <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickCustomerCountPopup('Open')}>
                                                <label style={{ paddingTop: "5px" }}>({customerData.filter((e) => e.Selected === true).length})Selected Customers</label></a></span>
                                        </div>
                                        :
                                        ""
                                    }
                                    <div style={{ paddingTop: "50px" }}>
                                    </div>
                                    <div className="col-lg-12 mt-2" style={{ paddingLeft: "5px" }}>
                                        <div className="table-responsive">
                                            <table style={{ width: "63%" }} className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm">
                                                <thead className="thead-light">
                                                    {showtargetselection === "Quarterly%" ?
                                                        <>
                                                            <tr className='text-left'>
                                                                <th>{t('Rebates.Quarterly')}</th><th>Q1</th><th>Q2</th><th>Q3</th><th>Q4</th></tr>
                                                         
                                                            <tr className='text-left'> <th>Threshold Target %</th>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key)} style={{ width: "80px", margin: "auto" }} name="Q1" value={subDivideAccrualDetail.subdivideMinThresholdValue.Q1} onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key)} style={{ width: "80px", margin: "auto" }} name="Q2" value={subDivideAccrualDetail.subdivideMinThresholdValue.Q2} onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key)} style={{ width: "80px", margin: "auto" }} name="Q3" value={subDivideAccrualDetail.subdivideMinThresholdValue.Q3} onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key)} style={{ width: "80px", margin: "auto" }} name="Q4" value={subDivideAccrualDetail.subdivideMinThresholdValue.Q4} onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                            </tr>
                                                           

                                                        </>
                                                        :
                                                        ""
                                                    }
                                                    {showtargetselection === "HalfYearly%" ?
                                                        <>
                                                            <tr className='text-left'>
                                                                <th style={{ width: "20%" }}>{t('Rebates.HalfYearly')}</th><th>H1</th><th>H2</th>
                                                            </tr>
                                                          
                                                            <tr className='text-left'>
                                                                <th>
                                                                    Threshold Target  %</th>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.H1} name="H1" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.H2} name="H2" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                            </tr>
                                                        </>

                                                        
                                                        :
                                                        ""
                                                    }


                                                    {showtargetselection === "Monthly%" ?
                                                        <>
                                                            <tr className='text-left'>
                                                                <th>{t('Rebates.Monthly')}</th><th>M1</th><th>M2</th><th>M3</th><th>M4</th> <th>M5</th><th>M6</th><th>M7</th><th>M8</th> <th>M9</th><th>M10</th><th>M11</th><th>M12</th>
                                                            </tr>
                                                            
                                                            <tr className='text-left'>

                                                                <th>Threshold Target  %</th>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M1} name="M1" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M2} name="M2" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M3} name="M3" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M4} name="M4" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M5} name="M5" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M6} name="M6" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M7} name="M7" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M8} name="M8" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M9} name="M9" onChange={(event) => handleSubDivideChange(event)}></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M10} name="M10" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td> <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M11} name="M11" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control className={`${classes['Form-Input-control']}  `} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={subDivideAccrualDetail.subdivideMinThresholdValue.M12} name="M12" onChange={(event) => handleSubDivideChange(event)} ></Form.Control>
                                                                </td>
                                                            </tr>
                                                           
                                                        </>
                                                        :
                                                        ""
                                                    }
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        showSelectedGroupCustomerpopup === true ?
                                            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                                <div className="container" style={{ padding: "0" }}>
                                                    <div className="row">
                                                        <div className="col-lg-12 pr-lg-2">
                                                            <div className="card text-center card_border">
                                                                <div className="card-header chart-grid__header">
                                                                    <div className="row">
                                                                        <div className="col-md-10 text-left">
                                                                            Group Customer Listing
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickGroupCustomerPopup('Close')} type="submit">{t('Close')}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-1" id="Customer">
                                                        <div className="col-lg-12 pr-lg-2">
                                                            <div className="card text-center card_border">
                                                                <div className="card-header chart-grid__header">

                                                                </div>

                                                                <div className='scrollbar-class'>
                                                                    <div className="container">
                                                                        {showGroupCustomerCountpopup == "Data" ?
                                                                            <div>
                                                                                <input type="text" className='form-control form-control-user' placeholder={`${t('Search')} ${"Group Customer"}`} onChange={(event) => groupcustomerSearch(event)} />
                                                                            </div>
                                                                            :
                                                                            ""
                                                                        }
                                                                        {showGroupCustomerCountpopup == "Data" ?
                                                                            <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                                    <tr>
                                                                                        <th style={{ width: "30px" }}>
                                                                                            <input checked={customergroupcheckAll} type="checkbox" name="customergroup-check" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                                                                        </th>
                                                                                        <th id="Name" style={{ width: "110px" }}>Name<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                        <th id="Label" style={{ maxWidth: "110px" }}>Label<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    {
                                                                                        groupCustomerData.map((cslist, index) => (

                                                                                            <tr>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input type="checkbox"
                                                                                                      
                                                                                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />
                                                                                                </td>
                                                                                                <td style={{ width: "90px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Name}>{cslist.Name}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "260px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.label}>{cslist.label}</div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            ""
                                                                        }
                                                                        {showGroupCustomerCountpopup == "Count" ?
                                                                            <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                                    <tr>
                                                                                        <th style={{ width: "30px" }}>
                                                                                            <input type="checkbox" id="option-all-customer" disabled="true" />
                                                                                        </th>
                                                                                        <th id="Name" style={{ width: "110px" }}>Name</th>
                                                                                        <th id="Label" style={{ maxWidth: "110px" }}>Label</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    {
                                                                                        groupCustomerData.filter((e) => e.Selected === true).map((cslist, index) => (

                                                                                            <tr>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input type="checkbox"
                                                                                                      
                                                                                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} disabled="true" />
                                                                                                </td>
                                                                                                <td style={{ width: "90px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Name}>{cslist.Name}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "260px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.label}>{cslist.label}</div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            ""
                                    }



                                    {
                                        showSelectedCustomerpopup === true ?
                                            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                                <div className="container" style={{ padding: "0" }}>
                                                    <div className="row">
                                                        <div className="col-lg-12 pr-lg-2">
                                                            <div className="card text-center card_border">
                                                                <div className="card-header chart-grid__header">
                                                                    <div className="row">
                                                                        <div className="col-md-10 text-left">
                                                                            {t('Rebates.CustomerListing')}
                                                                        </div>
                                                                        <div className="col-md-2">

                                                                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickCustomerPopup('Close')} type="submit">{t('Close')}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-1" id="Customer">
                                                        <div className="col-lg-12 pr-lg-2">
                                                            <div className="card text-center card_border">
                                                                <div className="card-header chart-grid__header">

                                                                </div>

                                                                <div className='scrollbar-class'>
                                                                    <div className="container">
                                                                        {showCustomerCountpopup == "Data" ?
                                                                            <div>
                                                                                <input type="text" className='form-control form-control-user' placeholder={`${t('Search')} ${t('Rebates.Customer')}`} onChange={(event) => customerSearch(event)} />
                                                                            </div>
                                                                            :
                                                                            ""
                                                                        }
                                                                        {showCustomerCountpopup == "Data" ?
                                                                            <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <input checked={customercheckAll} type="checkbox" name="customer-check" id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                                                                        </th>
                                                                                        <th id="Name" style={{ width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                        <th id="Label" style={{ maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                        <th id="Station" style={{ maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                        <th id="State" style={{ maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                        <th id="Zone" style={{ maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                < tbody >
                                                                                    {
                                                                                        customerData.map((cslist, index) => (

                                                                                            <tr>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input type="checkbox"
                                                                                                       
                                                                                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerCustomer(event, cslist.label, cslist.Id, cslist.State)} />
                                                                                                </td>
                                                                                                <td style={{ width: "90px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Name}>{cslist.Name}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "260px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.label}>{cslist.label}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Station}>{cslist.Station}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.State}>{cslist.State}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Zone}>{cslist.Zone}</div>

                                                                                                </td>

                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            ""
                                                                        }

                                                                        {showCustomerCountpopup == "Count" ?
                                                                            <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <input type="checkbox" id="option-all-customer" disabled="true" /><label></label>
                                                                                        </th>
                                                                                        <th id="Name" style={{ width: "110px" }}>{t('Rebates.CustomerCode')} </th>
                                                                                        <th id="Label" style={{ maxWidth: "110px" }}>{t('Rebates.CustomerName')} </th>
                                                                                        <th id="Station" style={{ maxWidth: "110px" }}>{t('Rebates.Station')}	 </th>
                                                                                        <th id="State" style={{ maxWidth: "110px" }}>{t('Rebates.State')}	 </th>
                                                                                        <th id="Zone" style={{ maxWidth: "110px" }}>{t('Rebates.Zone')}  </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    {
                                                                                        customerData.filter((e) => e.Selected === true).map((cslist) => (
                                                                                            <tr>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input type="checkbox"
                                                                                                       
                                                                                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} disabled="true" />
                                                                                                </td>
                                                                                                <td style={{ width: "90px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Name}>{cslist.Name}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "260px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.label}>{cslist.label}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Station}>{cslist.Station}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.State}>{cslist.State}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                        title={cslist.Zone}>{cslist.Zone}</div>

                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDeleteModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => checkDelete('Close')}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => checkDelete('Proceed')}>
                            {t('Delete')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}
export default CreateNewSubDivideAccrualThresholdPolicy