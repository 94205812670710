import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewDistributorGroupMappingPolicy from '../../../Components/EndurancePolicies/DistributorGroupMappingPolicy/CreateNewDistributorGroupMappingPolicy';

function CreateDistributorGroupMappingPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewDistributorGroupMappingPolicy {...props}  navigate={navigate} id={params.id} name={params.name}/>
         </div>
     );  

}
export default CreateDistributorGroupMappingPolicy