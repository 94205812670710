import React, { useState, useEffect } from 'react';
import classes from './DataExport.module.css';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import CustomMessagePopup from '../../../UI/CustomMessagePopup';
import moment from 'moment/moment';
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import axios from 'axios';
import { Form, Row, Col } from 'react-bootstrap';
import Service from '../../../Services/Service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SessionManage from "../../../SessionManagement/SessionManage";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const bussiness = [{ "Name": "", "Label": "" }];
const oem = [{ "Name": "", "Label": "" }];
const segment = [{ "Name": "", "Label": "" }];
const mappingAttribute=[{ "Name": "", "Label": "" }];
const category = [{ "Name": "", "Label": "" }];
const subcategory = [{ "Name": "", "Label": "" ,"Category":""}];
const productlinetemplate = [{ "Name": "", "Label": "", "BussinessUnit": "" }];
const productfamilytemplate = [{ "Name": "", "Label": "", "ProductLine": "" }];
const productsubfamilytemplate = [{ "Name": "", "Label": "", "ProductFamily": "" }];
const uomtemplate = [{ "Name": "", "Label": "", "IsoCode": "" }];
const producttemplate = [{
	"Name": "", "Label": "", "UOM": "", "ProductSubFamily": "", "ProductAttribute1": "",
	"ProductAttribute2": "", "ProductAttribute3": "", "ProductAttribute4": "", "ProductAttribute5": "", "VehicleType": "","OEM": ""
}];
const salessubregiontemplate = [{ "Name": "", "Label": "", "SalesRegion": "" }];
const salesorgtemplate = [{ "Name": "", "Label": "", "SalesSubRegion": "" }];
const salesofficetemplate = [{ "Name": "", "Label": "", "SalesOrg": "" }];
const salesgrouptemplate = [{ "Name": "", "Label": "", "SalesOffice": "" }];
const customerlevel2template = [{ "Name": "", "Label": "", "CustomerLevel1": "" }];
const customertemplate = [{ "Name": "", "Label": "", "Phone": "", "City": "", "State": "", "Country": "", "CustomerLevel2": "", "SalesRegion": "", "Stockist": "","JoiningDate":"" }];
const currencytemplate = [{ "IsoCode": "", "Description": "", "Phone": "", "Prefix": "", "Suffix": "", "MathPrecision": "", "DisplayPrecision": "", "DisplayPerunitPrecision": "", "ExportPrecision": "" }];
const transactiontemplate = [{
	"Id": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "", "Product": "", "Customer": "", "Plant": "", "Exclude": "", "SalesRep": "",
	"Quantity": "", "Netprice": "", "PostedAccruals": "", "UnpostedAccruals": "", "TransactionDate": "", "QuoteId": "", "Uom": "", "Currency": "", "InvoiceNumber": "",
	"Discount": "", "LineItem": "", "CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": "", "Saleshierarchy": "", "TotalValue": ""
}];
const ExchnageRatetemplate = [{ "EffectiveDate": "", "FromCurrency": "", "ToCurrency": "", "Rate": "" }];
const PaymentsTerms = [{ "Name": "", "label": "", "NoOfDays": 0, "Isblocked": false, "BlockedReason1": "", "BlockedReason2": "", "BlockedReason3": "", "BlockedReason4": "", "Locked": false }];
const Invoicepaymentdetail = [{
	"Id": "", "Customer": "", "InvoiceNumber": "", "Paymentdate": "", "Amountreceived": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "",
	"CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": "", "InvoiceDate": "", "DateDifference": ""
}];
const Leadger = [{ "Id": "", "CreatedDate": "", "Customer": "", "DocDate": "", "PostingDate": "", "PaymentReceived": "", "Particulars": "" }];
const Customerinvoiceoutstandingdetails = [{
	"Id": "", "Customer": "", "InvoiceNumber": "", "RebatePeriod": "", "DueDate": "", "OutstandingAmount": "", "CreatedDate": "", "LastModifiedDate": "",
	"Deleted": "", "CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": ""
}];
const DistributorTemplate = [{ "Name": "", "Label": "", "City": "", "Phone": "", "State": "", "Country": "" }];
const DistributorTransactionTemplate = [{ "Product": "", "Distributor": "", "Salesrep": "", "Plant": "", "Exclude": "", "Quantity": "", "InvoicePrice": "", "TransactionDate": "", "QuoteId": "", "UOM": "", "Currency": "", "InvoiceNumber": "", "Discount": "", "PaymentReceivedDate": "", "Saleshierarchy": "", "TotalValue": "", "Revenue": "" }];
const CountryTemplate = [{ "Name": "", "Label": "" }];
const DistributorCountryTemplate = [{ "DistributorName": "", "DistributorLabel": "", "CountryName": "", "CountryLabel": "" }];
const InvoicePending = [{ "Id": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "", "DistributorCode": "", "DistributorName": "", "DocNumber": "", "BillNumber": "", "BillAmount": 0.0, "CashdiscountAmount": 0.0, "PendingAmount": 0.0, "BillDate": "", "LrDate": "" }];

const CashDiscountDaysDue = [{ "Name": "", "Label": "" }];
const SalesOrderTemplate = [{ "Id": "", "CreatedDate": "", "SalesOrderNo": "", "SalesOrderDate": "", "Customer": "", "Product": "", "OrderedQty": "", "OrderedValue": "" }];
const DataExport = () => {

	const [selectedFile, setSelectedFile] = useState();
	const [t, i18n] = useTranslation('translation');
	const [template, settemplate] = useState([]);
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showMessageModal, setShowMessageModal] = useState(false);
	const [selectedModule, setselectedModule] = React.useState("");
	const [array, setArray] = useState([]);
	const [data, setdata] = useState('');
	const [failedlist, setfailedlist] = useState([]);
	const [importhistory, setimporthistory] = useState([]);
	const [progressvalue, setprogressvalue] = useState('');
	const fileReader = new FileReader();
	const [showLoadMore, setshowLoadMore] = useState('');
	const [filteredArray, setFilteredArray] = useState(data);
	const [filterCol, setFilterCol] = useState('');
	const [showspinner, setshowspinner] = useState(true);
	const [filterValue, setFilterValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [showfilterpopup, setshowfilterpopup] = useState(false);
	const [divleft, setdivleft] = useState('');
	const [divtop, setdivtop] = useState('');
	const [columnAscending, setColumnAscending] = useState(true);
	const [sortValue, setSortValue] = useState('');
	const [columnValue, setColumnValue] = useState('');
	const [selectedOptions, setSelectedOptions] = useState();
	const [editPerms, setEditPerms] = useState([]);
	const location = useLocation();
	const [filterValueDict, setFilterValueDict] = useState({
		Modulename: '',

	});

	const [totalselectedshow, settotalselectedshow] = useState('');
	const [totalselectedcount, settotalselectedcount] = useState(0);
	const [totalCountlst, settotalCountlst] = useState('');


	// New state to manage the Check All checkbox
	const [checkAllChecked, setCheckAllChecked] = useState(false);
	const [checkedValue, setCheckedValue] = useState([]);
	const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
	const [checkedValueid, setCheckedValueid] = useState([]);
	const [checkedValueStatus, setCheckedValueStatus] = useState([]);
	const [allCheckedHistory, setAllCheckedHistory] = useState(false);
	const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);


	const customStyles = {
		control: base => ({
			...base,
			minHeight: 28,
			maxHeight: 100,

		}),
		dropdownIndicator: base => ({
			...base,
			padding: 2
		}),
		clearIndicator: base => ({
			...base,
			padding: 2
		}),
		multiValue: base => ({
			...base,

		}),
		valueContainer: base => ({
			...base,
			padding: '0px 2px'
		}),
		input: base => ({
			...base,
			margin: 0,
			padding: 0,

		})
	};

	const [paging, setpaging] = useState({
		pageSize: 20,
		pageNumber: 1
	})
	const [modules, setmodules] = useState([]);
	let id = SessionManage.getuserid();
	useEffect(() => {
		getUserPermission(location.pathname);
		setSortValue("Descending");
		setColumnValue("Id");
		getModules("Transaction/GetAllModules");
		setLoading(false);
	}, [0])

	const getUserPermission = (pathname) => {
		Service.GetUserPermission(pathname).then((res) => {

			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
					if (typeof res.data.DataList !== 'undefined') {
						var permission = res.data.DataList;
						if (typeof permission !== 'undefined') {
							if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
								setEditPerms(false);
							} else {
								setEditPerms(true);
							}
						}
						else {
							setEditPerms(false);
						}
					}
				}
			}
		});
	}

	const requiredLabels = [
		'Category',
		'SubCategory',
		'Product',
		'Customer',
		'SalesOrder',
		'TransactionRecord',
		'Invoicepaymentdetail',
		'PaymentsTerms',
		'BusinessUnit',
		'OEM',
		'Segment',
		'MappingAttribute'
	];

	const getModules = (method) => {

		Service.getdata(method).then((res) => {
			if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
				const filteredModules = res.data.filter(module => requiredLabels.includes(module.ModelName));
                setmodules(filteredModules);
			}
		});
	}
	const LoadMore = (lst, totalcount) => {
		if (lst < totalcount) {
			setshowLoadMore(true);
		}
		else if (lst >= totalcount) {
			setshowLoadMore(false);
		} else {
			setshowLoadMore(false);
		}
	}

	const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue) => {
		Service.getDataExport(method, pageSize, pageNumber, sortType, SortProperty, filterValue).then((res) => {
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					setdata(res.data.DataList);
					setFilteredArray(res.data.DataList)
					settotalCountlst(res.data.Totalcount)
					LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
				}
			}
		});
	}

	const changeSelectOptionHandler = (data) => {

		paging.pageSize = 20;
		paging.pageNumber = 1
		setpaging(paging);
		setSortValue("Descending");
		setColumnValue("Id");

		setselectedModule(data.ModelName);
		setFilterValue(data.ModelName)
		setFilterCol("Modulename")
		setSelectedOptions(data)
		// alert(inputValue)
		setFilterValueDict(() => ({
			...filterValueDict, [filterCol]: data.ModelName,
		}));
		getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + data.ModelName, paging.pageSize, paging.pageNumber, "Descending", "Id", []);

		if (data.ModelName === "BusinessUnit") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "OEM") {
			settemplate(oem);
		}
		else if (data.ModelName === "Segment") {
			settemplate(segment);
		}
		else if (data.ModelName === "ImportMappingAttribute") {
			settemplate(mappingAttribute);
		}
		else if (data.ModelName === "ProductLine") {
			settemplate(productlinetemplate);
		}
		else if (data.ModelName === "ProductFamily") {
			settemplate(productfamilytemplate);
		}
		else if (data.ModelName === "ProductSubFamily") {
			settemplate(productsubfamilytemplate);
		}
		else if (data.ModelName === "CatI") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIV") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "ProductAttribute5") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "UOM") {
			settemplate(uomtemplate);
		}
		else if (data.ModelName === "Product") {
			settemplate(producttemplate);
		}
		else if (data.ModelName == "SalesRegion") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "SalesSubRegion") {
			settemplate(salessubregiontemplate);
		}
		else if (data.ModelName == "SalesOrg") {
			settemplate(salesorgtemplate);
		}
		else if (data.ModelName == "SalesOffice") {
			settemplate(salesofficetemplate);
		}
		else if (data.ModelName == "SalesGroup") {
			settemplate(salesgrouptemplate);
		}
		else if (data.ModelName == "CustomerLevel1") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "CustomerLevel2") {
			settemplate(customerlevel2template);
		}
		else if (data.ModelName == "Customer") {
			settemplate(customertemplate);
		}
		else if (data.ModelName == "TransactionRecord") {
			settemplate(transactiontemplate);
		}
		else if (data.ModelName == "Currency") {
			settemplate(currencytemplate);
		}
		else if (data.ModelName == "ExchangeRate") {
			settemplate(ExchnageRatetemplate);
		}
		else if (data.ModelName == "ExchangeRate") {
			settemplate(ExchnageRatetemplate);
		}
		else if (data.ModelName == "PaymentsTerms") {
			settemplate(PaymentsTerms);
		}
		else if (data.ModelName == "Leadger") {
			settemplate(Leadger);
		}
		else if (data.ModelName == "Invoicepaymentdetail") {
			settemplate(Invoicepaymentdetail);
		}
		else if (data.ModelName == "Customerinvoiceoutstandingdetails") {
			settemplate(Customerinvoiceoutstandingdetails);
		}
		else if (data.ModelName == "Distributor") {
			settemplate(DistributorTemplate);
		}
		else if (data.ModelName == "Country") {
			settemplate(CountryTemplate);
		}
		else if (data.ModelName == "DistributorCountry") {
			settemplate(DistributorCountryTemplate);
		}
		else if (data.ModelName == "DistributorTransaction") {
			settemplate(DistributorTransactionTemplate);
		}
		else if (data.ModelName == "InvoicePending") {
			settemplate(InvoicePending);
		}
		else if (data.ModelName == "CashDiscountDaysDue") {
			settemplate(CashDiscountDaysDue);
		}
		else if (data.ModelName == "SalesOrder") {
			settemplate(SalesOrderTemplate);
		}else if(data.ModelName == "Category"){
            settemplate(category);
		}else if(data.ModelName == "SubCategory"){
            settemplate(subcategory);
		}
		else {
			settemplate('');
			toast.warning('No Export Available !!', {
				position: toast.POSITION.TOP_RIGHT,
			});

		}

	};

	const csvReport1 = {
		data: template,

		filename: selectedModule + '_Template.csv'
	};

	const csvReport = {
		data: failedlist,

		filename: 'Failed_Record.csv'
	};
	const submitButton = (e) => {
		//alert(1312)
		if (selectedModule != "") {
			e.preventDefault();
			setLoading(true);
			setTimeout(() => {
				toast.success('Exported Successfully !', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}, 3000);			
			Service.createorupdate("ExportFile/Create?Createdby=" + Service.getUserId() + "&Modulename=" + selectedModule, data).then(res => {

				if (typeof res != 'undefined') {
					var path = res.data.Path
					setLoading(false);

					window.location = path;
				}
				paging.pageSize = 20;
				paging.pageNumber = 1
				setpaging(paging);
				setSortValue("Descending");
				setColumnValue("Id");

				getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, paging.pageSize, paging.pageNumber, "Descending", "Id", []);
			});
		}
	}
	const SortColumn = (colName) => {
		paging.pageSize = 20;
		paging.pageNumber = 1
		setpaging(paging);

		setColumnAscending(!columnAscending);
		var sortType = columnAscending === true ? 'Ascending' : 'Descending';
		setSortValue(sortType);
		setColumnValue(colName);

		getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, 20, 1, sortType, colName, []);

	}
	const handleLoadMore = () => {

		let pagenumber = paging.pageNumber + 1;
		var sortType = columnAscending === true ? 'Ascending' : 'Descending';
		paging.pageNumber = pagenumber
		setpaging(paging);

		Service.getDataExport("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, paging.pageSize, pagenumber, sortValue, columnValue, []).then((res) => {

			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					if (res.status === 200) {
						if (res.data !== 'null' && res.data !== 'undefined') {
							let rowData = res.data.DataList;

							if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
								const currentlength = data.length;
								setdata(data => [...data, ...res.data.DataList])

								setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
								LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

								const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
								console.log(allCheckBoxes, "21-6");

								if (!checkAllChecked && totalselectedcount != 0) {
									allCheckBoxes.forEach((each) => {
										const checkboxId = parseInt(each.id, 10);
										if (!isNaN(checkboxId)) {
											if (uncheckedBoxesHistory.includes(checkboxId)) {
												each.checked = false;
											} else {
												// each.checked = true;
												each.checked = checkedMultiValueid.includes(checkboxId);
											}
										}
									});
								} else {
									allCheckBoxes.forEach((each) => {
										each.checked = checkAllChecked;
									});
								}
							}
						}
					}
				}
			}
		});
	};


	const checkAll = (mycheckbox) => {
		const checkboxes = document.querySelectorAll("input[type='checkbox']");
		if (mycheckbox.target.checked) {
			settotalselectedshow(true);
			setAllCheckedHistory(true);
			checkboxes.forEach(function (checkbox) {
				checkbox.checked = true;
			});
			settotalselectedcount(totalCountlst);
			setCheckAllChecked(true); // Set state to manage Check All checkbox
		} else {
			settotalselectedshow(false);
			setAllCheckedHistory(false);
			checkboxes.forEach(function (checkbox) {
				checkbox.checked = false;
			});
			settotalselectedcount(0);
			setCheckAllChecked(false); // Set state to manage Check All checkbox
		}
	};


	const handleChange = (e, id, name, status) => {
		const isChecked = e.target.checked;
		const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
		const count = checkedBoxes.length;

		if (isChecked) {
			setCheckedValue(id);  // check box value - id
			setCheckedValueStatus(status); // check box value - Active status
			setCheckedValueid(name); // check box value - RA-0000
			setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
			count += 1;


			let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
			const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
			if (index !== -1) {
				tempUncheckedBoxesHistory.splice(index, 1);
			}
			setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

		} else {
			setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
			setCheckedValue([]);  // check box value - id
			setCheckedValueStatus([]); // check box value - Active status
			setCheckedValueid([]); // check box value - RA-0000
			setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
			count -= 1;

		}

		settotalselectedcount(count);
		settotalselectedshow(count > 0);

		// Uncheck the "Check All" checkbox if any individual checkbox is unchecked
		if (!isChecked) {
			setCheckAllChecked(false);
		} else if (count === totalCountlst) {
			setCheckAllChecked(true);
		}

		if (checkedBoxes.length == 1) {
			const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
			setCheckedValue(checkedBoxes[0].id)  // check box value - id
			setCheckedValueStatus(Recordstatus) // check box value - Active status
			setCheckedValueid(Recordname) //check box value - RA-0000
		}
	};




	const headerKeys = Object.keys(Object.assign({}, ...array));

	const dateFormat = SessionManage.getcurrentdateformat();
	const timeFormat = "hh:mm A";

	//-----------------------------------------------------------------

	return (
		<div class="container-fluid content-top-gap">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb my-breadcrumb">
					<li class="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
					<li class="breadcrumb-item active" aria-current="page">{t('DataImport.DataExport')}</li>

				</ol>
			</nav>

			<div class="">
				<div class="row">
					<div class="col-lg-12 pr-lg-2">
						<div class="card text-center card_border">

							<div class="card-body" style={{ minHeight: "70vh" }}>
								{loading ? <>
									<div className="loader-container">
										<div className="spinner"></div>
									</div>
								</> : ""}
								<div className='col-md-12  mb-4'>
									<div className='row'>
										<div className='col-md-3 l_width'>
											<p className={classes['Footer-Buttons']}>{t('DataImport.DataTableName')}</p>
										</div>
										<div className='col-md-3 m_width'>

											<Select
												className='col-md-12 textbox-padding-dropdown mt-2 fontsizedropdown'
												options={modules}
												placeholder="Choose"
												value={selectedOptions}
												onChange={changeSelectOptionHandler}
												isSearchable={true}
												styles={customStyles}
											/>


										</div>
										<div className='col-md-4'>	{editPerms == false ? "" : <button style={{ margin: "10px", float: "right", width: "50px" }} class="btn btn-sm btn-update" onClick={submitButton} type="submit">{t('Export')}</button>}</div>
										{
											template.length === 1000 ?
												<div className='col-md-5'><p className={classes['Footer-Buttons']}>{t('DataImport.Template')} : <CSVLink {...csvReport1}>{`${t('DataImport.Download')}${selectedModule} ${t('DataImport.Template')}`}</CSVLink></p> </div>
												:
												''
										}
                                        <ToastContainer autoClose={1000} />

									</div>
									<div className='row'>
									</div>


									<CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal}
										onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
									<CustomMessagePopup SuccessMessage={successMessage} show={showMessageModal}
										onHide={() => setShowMessageModal(false)}></CustomMessagePopup>
								</div>
								<br></br>
								<br></br>

								{selectedModule === "" || selectedModule === "Choose..." ? "" :
								<>
								    <h5 className='mb-1'>{t('DataImport.ExportHistory')}</h5>
									<div className="scrollbar-class">
										<table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
											<thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
												<tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>

													<th id="rolename" style={{ backgroundColor: (filterValueDict.rolename ? '#6cae55' : ''), minWidth: "130px" }}>
														{t('DataImport.ModuleName')} <span className='fa fa-sort' onClick={() => SortColumn('Modulename')}></span>

													</th>
													<th id="rolelabel" style={{ backgroundColor: (filterValueDict.rolelabel ? '#6cae55' : ''), minWidth: "130px" }}>
														{t('DataImport.Status')} <span className='fa fa-sort' onClick={() => SortColumn('Status')}></span>
													</th>

													<th style={{ minWidth: "130px" }}>{t('DataImport.CreatedBy')} <span className='fa fa-sort' onClick={() => SortColumn('Startdate')}></span></th>
													<th style={{ minWidth: "130px" }}>{t('DataImport.CreatedDate')} <span className='fa fa-sort' onClick={() => SortColumn('CreatedbyUser')}></span></th>
													<th></th>
												</tr>
											</thead>
											{
                                              showspinner === true ?
                                                <tr>
                                                  <>
                                                    <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                  </>
                                                </tr>
                                                :
												filteredArray.length === 0 ?
													<>
														<tr>
															<td colSpan="9" style={{ textAlign: 'center' }}>
																{t('NoDataAvailable')}
															</td>
														</tr>													</>
													:
													<tbody>
														{filteredArray.map((item, index) => (
															<tr key={index} >

																<td>{item.Modulename}</td>
																<td>{item.Status}</td>
																<td>{item.CreatedbyUser}</td>
																<td>{`${moment(item.Startdate).format(dateFormat)} ${moment(item.Startdate).format(timeFormat)}`}</td>
																<td >
																	{item.Filepath == "" || item.Filepath == null ? "" : <a target={'_blank'} className={editPerms == false ? "disabledClass" : ""} href={item.Filepath} download > Download File </a>}
																</td>
															</tr>
														))}

													</tbody>
											}
										</table>
										{
											showLoadMore === true && filteredArray.length > 0 ?
												<div className='text-center container row'>
													<div className='col-md-5'></div>
													<div className='col-md-2'>
														<button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
													</div>
													<div className='col-md-5'></div>

												</div>
												:
												""
										}
									</div>
								</>
								}

							</div>

						</div>


					</div>

				</div>
			</div>


		</div>
	)
}
export default DataExport;