import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import SessionManage from "../../SessionManagement/SessionManage";
import { useNavigate } from 'react-router-dom';
import { t } from "i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const currentdata = [
    {
        id: 0,
        label: "0",
        value: "0",
    },
    {
        id: 1,
        label: "1",
        value: "1",
    },
    {
        id: 2,
        label: "2",
        value: "2",
    },
    {
        id: 3,
        label: "3",
        value: "3",
    },
    {
        id: 4,
        label: "4",
        value: "4",
    }
];

const paymenttermdate = [
    {
        id: 1,
        label: "From Invoice Date",
        value: "From Invoice Date",
    },
    // {
    //     id: 1,
    //     label: "From Subdivide Period End Date",
    //     value: "From Subdivide Period End Date",
    // },
]

function RebateDefaultValuesComponent(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    let rowData = [];
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    const [isfiscalyear, setFiscalYear] = useState(false);
    const [selectedOptionsAccrualCal, setSelectedOptionsAccrualCal] = useState();
    const [accrualCalcTypeData, setAccrualCalcTypeData] = useState([]);
    const [comparisonFormatData, setComparisonFormatData] = useState([])
    const [dataroundingprecision, setdataroundingprecision] = useState(currentdata)
    const [selectedOptionsCompDateFormat, setSelectedOptionsCompDateFormat] = useState();
    const [selectedOptionsroundingPrecision, setSelectedOptionsroundingPrecision] = useState();
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState();
    const [paymentterms, setpaymentterms] = useState(paymenttermdate);
    const [setlFreqData, setSetlFreqData] = useState([]);
    const [selectedpaymentterm, setselectedpaymentterm] = useState([]);

    const navigate = useNavigate();
    let loggedInUser = SessionManage.getuserid();
    const [editPerms, setEditPerms] = useState([]);
    const [inputField, setInputField] = useState({
        AccrualCalc: "",
        ComparisonPeriod: '',
        roundingprecision: '',
        SessionIdleTimeout: '',
        IncludeOutStandingAmount: false,
        IncludeSubDivideTarget: false,
        AccrualThresholdCriteria: false,
        SettlementFrequencyId: '',
        PaymentTerm: '',
        SelectedImageSource: 'Cloud',
        LocalPath: '',
        Cloud: '',
        Path: '',
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0) {
            setPolicyStatus(true)

            getAccrualCalcType("AccrualCalcType")
            getComparisonFormatData("ComparisonDateFormat")
            getconfigurationdata("Configurationprop")
            getSetlFreqData("settlementfrequency")



        }
    }, [])

    const OnChangeHandlerSetlFreq = (data) => {

        setSelectedOptionsSetlFreq(data.label)
        setInputField({ ...inputField, ['SettlementFrequencyId']: data.label })

    }
    const getSetlFreqData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setSetlFreqData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getAccrualCalcType = (method) => {

        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setAccrualCalcTypeData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getComparisonFormatData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'null' && res.data !== 'undefined') {
                    setComparisonFormatData(res.data)

                }
            }
        });
    }

    const getconfigurationdata = (method) => {
        Service.getAlldata(method).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'null' && res.data !== 'undefined') {

                    var currentdata = res.data;

                    currentdata.map(i => {
                        if (i.Name === "roundingprecision") {

                            inputField.roundingprecision = i.value;
                            setSelectedOptionsroundingPrecision(i.value);
                        } else if (i.Name === "AccrualCalc") {
                            inputField.AccrualCalc = i.value;
                            setSelectedOptionsAccrualCal(i.value)

                        } else if (i.Name === "ComparisonPeriod") {
                            inputField.ComparisonPeriod = i.value;
                            setSelectedOptionsCompDateFormat(i.value
                            )
                        }
                        else if (i.Name === "Session-Idle-Timeout") {

                            inputField.SessionIdleTimeout = i.value;
                            setInputField({ ...inputField, ['SessionIdleTimeout']: i.value })
                        }
                        else if (i.Name === "IncludeSubDivideTarget") {
                            inputField.IncludeSubDivideTarget = i.value;
                            setInputField({ ...inputField, ['IncludeSubDivideTarget']: i.value })
                        }
                        else if (i.Name === "IncludeOutStandingAmount") {
                            inputField.IncludeOutStandingAmount = i.value;
                            setInputField({ ...inputField, ['IncludeOutStandingAmount']: i.value })
                        }
                        else if (i.Name === "AccrualThresholdCriteria") {
                            inputField.AccrualThresholdCriteria = i.value;
                            setInputField({ ...inputField, ['AccrualThresholdCriteria']: i.value })
                        }

                        else if (i.Name === "SettlementFrequency") {

                            inputField.SettlementFrequencyId = i.value;
                            setSelectedOptionsSetlFreq(i.value
                            )
                            setInputField({ ...inputField, ['SettlementFrequencyId']: i.value })
                        }
                        else if (i.Name === "PaymentTerm") {
                            inputField.PaymentTerm = i.value;

                            setselectedpaymentterm(i.value
                            )
                            setInputField({ ...inputField, ['PaymentTerm']: i.value })
                        }
                        else if (i.Name === "Cloud") {
                            inputField.Cloud = i.value;
                            setInputField((prevInputField) => ({
                                ...prevInputField,
                                Cloud: i.value,
                                SelectedImageSource: 'Cloud'
                            }));
                        }
                        else if (i.Name === "Path") {
                            inputField.Path = i.value;
                            setInputField({ ...inputField, ['Path']: i.value })
                        }
                        else if (i.Name === "LocalPath") {
                            inputField.LocalPath = i.value;
                            setInputField((prevInputField) => ({
                                ...prevInputField,
                                LocalPath: i.value,
                                SelectedImageSource: 'Path'
                            }));
                        }
                    });


                }
            }
        });
    }
    console.log("12-8", inputField)

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const class_textbox = `col-md-2 textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` col-md-2 textbox-padding mt-2 fontsizedropdown m_width  ${editPerms == false ? "disabledClass" : ""}`;

    const getdata = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setInputField(res.data);
                    setPolicyStatus(true)
                }
            }
        });
    }

    const inputsHandler = (e) => {

        setInputField({ ...inputField, ['SessionIdleTimeout']: e.target.value })
    }

    const handleImageSource = (e, sourceType) => {
        if (sourceType === 'Path' || sourceType === 'Cloud') {
            setInputField((prevInputField) => ({
                ...prevInputField,
                SelectedImageSource: sourceType,
                Path: sourceType === 'Path' ? "true" : "false",
                Cloud: sourceType === 'Cloud' ? "true" : "false",
            }));
        } else {
            setInputField((prevInputField) => ({
                ...prevInputField,
                [sourceType]: e.target.value,
            }));
        }
    };



    const OnChangeHandlerAccrualCal = (data) => {
        setHandleChange(true)
        setSelectedOptionsAccrualCal(data.Id)
        setInputField({ ...inputField, ['AccrualCalc']: data.Id })
    }

    const OnChangeComparisonPeriod = (data) => {
        setHandleChange(true)
        setSelectedOptionsCompDateFormat(data.Id);
        setInputField({ ...inputField, ['ComparisonPeriod']: data.Id });
    }

    const OnChangeroundingprecision = (data) => {
        setHandleChange(true)
        setSelectedOptionsroundingPrecision(data.id);
        setInputField({ ...inputField, ['roundingprecision']: data.id });
    }
    const OnChangesetselectedpaymentterm = (data) => {
        setHandleChange(true)
        setselectedpaymentterm(data.label);
        setInputField({ ...inputField, ['PaymentTerm']: data.label });
    }

    const checkValidation = () => {

        setIsValid(true)
        if (inputField.AccrualCalc == null || inputField.AccrualCalc == '') {
            setErrorMessage(`${t('Please Enter ')}${t('AccrualCalculation')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.ComparisonPeriod == '' || inputField.ComparisonPeriod == null) {
            setErrorMessage(`${t('Please Enter ')}${t('ComparisonPeriod')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }

        else if (inputField.SettlementFrequencyId == '' || inputField.SettlementFrequencyId == null) {

            setErrorMessage(`${t('Please Enter Settlement Frequency ')}
           // ${t('SettlementFrequencyId')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {

            saveHandler(true)
        }
    }

    const saveHandler = (isValid) => {

        if (inputField.IncludeOutStandingAmount == 'False' || inputField.IncludeOutStandingAmount == false) {
            inputField.IncludeOutStandingAmount = false;
        }
        else {
            inputField.IncludeOutStandingAmount = true;
        }
        if (inputField.IncludeSubDivideTarget == 'False' || inputField.IncludeSubDivideTarget == false) {
            inputField.IncludeSubDivideTarget = false;
        }
        else {
            inputField.IncludeSubDivideTarget = true;
        }
        if (inputField.AccrualThresholdCriteria == 'False' || inputField.AccrualThresholdCriteria == false) {
            inputField.AccrualThresholdCriteria = false;
        }
        else {
            inputField.AccrualThresholdCriteria = true;
        }
        let data = inputField

        data['Id'] = loggedInUser
        if (isValid === true) {

            Service.createorupdate("Configurationprop/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setHandleChange(false)

                            toast.success("Configuration Updated Successfully", {
                                position: toast.POSITION.TOP_RIGHT,
                            });


                            SessionManage.setroundingPrecision(selectedOptionsroundingPrecision.id);
                            getconfigurationdata("Configurationprop")
                        }
                        else {
                            setErrorMessage(t('DefaultValue.SameConfigurationPropertyAlreadyExists'))
                            setShowErrorModal(true)
                        }
                    }
                }


            });
        }
    }

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            navigate("/")
        }
        if (flag == "Close") {
            navigate("/")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    const checkIncludeSubDivideTarget = (e) => {

        if (e.target.checked == true) {
            setInputField({ ...inputField, ['IncludeSubDivideTarget']: true });
        }
        else {
            setInputField({ ...inputField, ['IncludeSubDivideTarget']: false });
        }
    }
    const checkIncludeOutStandingAmount = (e) => {
        if (e.target.checked == true) {
            setInputField({ ...inputField, ['IncludeOutStandingAmount']: true });
        }
        else {
            setInputField({ ...inputField, ['IncludeOutStandingAmount']: false });
        }
    }
    const checkAccrualThresholdCriteria = (e) => {
        if (e.target.checked == true) {
            setInputField({ ...inputField, ['AccrualThresholdCriteria']: true });
        }
        else {
            setInputField({ ...inputField, ['AccrualThresholdCriteria']: false });
        }
    }

    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";

    //----------------------------------------------------------

    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('DefaultValue.RebateDefaultValue')}</li>
                    </ol>
                </nav>
                <ToastContainer autoClose={1000} />
                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {t('DefaultValue.RebateDefaultValue')}
                                    </div>
                                    <div className="col-md-5">

                                        <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "45px" }} onClick={(event) => closeHandler("Open")}>{t('Close')}</button>
                                        {editPerms == false ? "" : <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} type="submit" onClick={checkValidation}>{t('Save')}</button>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">

                                   {/* <div className="row">
                                        <label className="col-md-4 text-left">{t('DefaultValue.AccrualCalculation')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={accrualCalcTypeData}
                                            placeholder="Select"


                                            value={accrualCalcTypeData.filter(function (option) {
                                                return option.value === `${selectedOptionsAccrualCal}`;
                                            })}
                                            onChange={OnChangeHandlerAccrualCal}
                                            isSearchable={true}

                                            styles={Service.getSelectstyle()}
                                        />
                                    </div> 


                                    <div className="row">
                                        <label className="col-md-4 text-left">{t('DefaultValue.ComparisonPeriod')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={comparisonFormatData}
                                            placeholder="Select"
                                            value={comparisonFormatData.filter(function (option) {
                                                return option.value === `${selectedOptionsCompDateFormat}`;
                                            })}


                                            onChange={OnChangeComparisonPeriod}
                                            isSearchable={true}
                                            styles={Service.getSelectstyle()}
                                        />
                                    </div>
                                    <div className="row">
                                        <label className="col-md-4 text-left">{t('DefaultValue.IncludeSubDivideTarget')}</label>
                                        <input type="checkbox" style={{ marginLeft: "5px" }} checked={inputField.IncludeSubDivideTarget == null || inputField.IncludeSubDivideTarget == 'False' || inputField.IncludeSubDivideTarget == false ? false : true} onChange={(event) => checkIncludeSubDivideTarget(event)}></input>
                                    </div>
                                    <br /> */}
                                    <div className="row">
                                        <label className="col-md-4 text-left l_width">{t('DefaultValue.IncludeOutStandingAmount')}
                                        <span className={classes.mandatory}>*</span>
                                        </label>
                                        <div className="col-md-8 m_width">
                                            <input type="checkbox" style={{ marginLeft: "5px" }} checked={inputField.IncludeOutStandingAmount == null || inputField.IncludeOutStandingAmount == 'False' || inputField.IncludeOutStandingAmount == false ? false : true} onChange={(event) => checkIncludeOutStandingAmount(event)}></input>
                                        </div> 
                                    </div>
                                    {/* <br />
                                    <div className="row">
                                        <label className="col-md-4 text-left" >{t('DefaultValue.AccrualThresholdCriteria')}</label>
                                        <input type="checkbox" style={{ marginLeft: "5px" }} checked={inputField.AccrualThresholdCriteria == null || inputField.AccrualThresholdCriteria == 'False' || inputField.AccrualThresholdCriteria == false ? false : true} onChange={(event) => checkAccrualThresholdCriteria(event)}></input>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-4 text-left">{t('Rebates.SettlementFreq')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={setlFreqData}

                                            value={setlFreqData.filter(function (option) {
                                                return option.label === `${selectedOptionsSetlFreq}`;
                                            })}
                                            onChange={OnChangeHandlerSetlFreq}
                                            isSearchable={true}
                                            styles={Service.getSelectstyle()}


                                        />

                                    </div>  */}
                                    <div className="row mt-1">
                                        <label className="col-md-4 text-left l_width">Enforce Payment Terms<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={paymentterms}
                                            placeholder="Select"
                                            value={paymentterms.filter(function (option) {
                                                return option.value === `${selectedpaymentterm}`;
                                            })}

                                            onChange={OnChangesetselectedpaymentterm}
                                            isSearchable={true}
                                            styles={Service.getSelectstyle()}
                                        />
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-md-4 text-left l_width">{t('DefaultValue.SystemRounding')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_multiselect}
                                            options={dataroundingprecision}
                                            placeholder="Select"
                                            value={dataroundingprecision.filter(function (option) {
                                                return option.value === `${inputField.roundingprecision}`;
                                            })}

                                            onChange={OnChangeroundingprecision}
                                            isSearchable={true}
                                            styles={Service.getSelectstyle()}
                                        />
                                    </div>

                                    <div className="row mt-1">
                                        <label className="col-md-4 text-left l_width" >{t('DefaultValue.SystemTimeout')}
                                            <span className={classes.mandatory}>*</span>
                                            </label>
                                        <input onChange={inputsHandler} value={inputField.SessionIdleTimeout} type="number" placeholder="Minutes" className={"col-md-2 textbox-padding mt-2 y_width"} style={{marginLeft:"0.20rem"}}/>
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-md-4 text-left l_width">{t('DefaultValue.UploadImageSource')}<span className={classes.mandatory}>*</span> </label>
                                        <div className="col-md-8 m_width">
                                        {/* <div className="col-md-8" style={{marginLeft:"-10px"}}> */}
                                            <div className="form-check-inline">
                                                <input onChange={(e) => handleImageSource(e, 'Cloud')} checked={inputField.SelectedImageSource === 'Cloud' ? true : false} type="radio" className="form-check-input m_width" id="cloudOption" />
                                                <label className="form-check-label text-left" htmlFor="cloudOption">{t('DefaultValue.Cloud')}</label>
                                            </div>
                                            <div className="form-check-inline">
                                                <input onChange={(e) => handleImageSource(e, 'Path')} checked={inputField.SelectedImageSource === 'Path' ? true : false} type="radio" className="form-check-input" id="localOption" />
                                                <label className="form-check-label text-left" htmlFor="localOption"> {t('DefaultValue.Local')}  </label>
                                            </div>
                                        </div>
                                    </div>
                                    {inputField.SelectedImageSource === 'Path' ?
                                        <div className="row">
                                            <label className="col-md-4 text-left l_width" >{t('DefaultValue.LocalPath')}</label>
                                            <input onChange={(e) => handleImageSource(e, 'LocalPath')} type="text" value={inputField.LocalPath} className={"col-md-2 textbox-padding mt-2 m_width"} />
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('DefaultValue.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div >
        </>
    )
}
export default RebateDefaultValuesComponent