import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';

import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $, { event } from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';
import { NumericFormat } from "react-number-format";
import ProductSelectorAttribute from "../../RebateProgramComponent/CreateRebateComponent/ProductSelectorAttribute";



function NewCategoryMinimumAchievementPolicy(props) {
  const navigate = useNavigate();
  let loggedInUser = SessionManage.getuserid();
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);
  const [changeHandle, setChangeHandle] = useState(false);
  const [showAccruals, setshowAccruals] = useState(false);
  const [showtargetselection, setShowTargetSelection] = useState('');
  const [showExceptionTypeListselection, setshowExceptionTypeListselection] = useState('');
  const [subdivideList, setsubdivideList] = useState({ 'Period': "", 'Operator': "", Value: "" })
  const [fyThresholdList, setfyThresholdList] = useState({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" })
  const [showtargettable, setShowTargetTable] = useState(true);
  const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
  const [subdivideTargetid, setSubdivideTargetid] = useState();
  const [ExceptionTypeid, setExceptionTypeid] = useState();
  const [addCategory, setaddCategory] = useState(false);
  const [CategoryValues, setCategoryValues] = useState([]);
  const [SubCategoryValues, setSubCategoryValues] = useState([]);
  const [ProductValues, setProductValues] = useState([]);
  const [CustomerValues, setCustomerValues] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [CustomerGroupValues, setCustomerGroupValues] = useState([]);
  const [attribute1Values, setAttribute1Values] = useState([]);
  const [attribute2Values, setAttribute2Values] = useState([]);
  const [attribute3Values, setAttribute3Values] = useState([]);
  const [attribute4Values, setAttribute4Values] = useState([]);
  const [attribute5Values, setAttribute5Values] = useState([]);
  const [UpdatedCategoryValues, setUpdatedCategoryValues] = useState([]);
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  const [attribute1ValuesHold, setAttribute1ValuesHold] = useState([]);
  const [attribute2ValuesHold, setAttribute2ValuesHold] = useState([]);
  const [attribute3ValuesHold, setAttribute3ValuesHold] = useState([]);
  const [attribute4ValuesHold, setAttribute4ValuesHold] = useState([]);
  const [attribute5ValuesHold, setAttribute5ValuesHold] = useState([]);


  const [showSubCategoryPopup, setShowSubCategoryPopup] = useState(false);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [SubCategoryIndex, setSubCategoryIndex] = useState(false);

  const [CategoryPopup, setCategoryPopup] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  const [CategoryIndex, setCategoryIndex] = useState(false);


  const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
  const [selectedOptionsRebateMeasure, setSelectedOptionsRebateMeasure] = useState('');
  const [rebateTypeValue, setRebateTypeValue] = useState('')
  const [measureValueLabel, setmeasureValueLabel] = useState("");
  const [selectedOptionsPayout, setSelectedOptionsPayout] = useState();
  const [rebateMeasureData, setRebateMeasureData] = useState([]);
  const [rebatePayoutCondData, setRebatePayoutCondData] = useState([]);
  const [rebateTypeData, setRebateTypeData] = useState([]);
  const [targetedPayCond, setTargetPayCond] = useState(false);
  const [rebatePayoutCond, setRebatePayoutCond] = useState('')
  const [rebateIcon, setRebateIcon] = useState('');
  const [VolumeRebateList, setVolumeRebateList] = useState([{
    SegmentId: "",
    MpgId: "",
    VehicletypeId: "",
    Productvalue: [],
    Eligiblity: 0,
    EligiblityGrowthRevenue: 0,
    EligiblityGrowthVolume: 0,
    TargetMeasure: null,
    Vehicletypetm: null,
    Effectedtod: null,
    Effectedsegments: "",
    Addredincentive: "",
    Incenentivevaluepercent: 0,
    Incenentivevalue: "",
    Lumpsumvalue: 0,

  },]);


  const [dateRange, setDateRange] = useState([]);
  const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();
  const [minDate, setminDate] = useState(null);
  const [maxDate, setmaxDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);


  const OnChangeHandlerStartDate = (e) => {

    setSelectedDate(true)
    let today = moment(new Date()).format(SessionManage.getdateformat());
    setState({ ...state, ['StartDate']: e });
    setExceptionPolicyData({ ...exceptionPolicyData, StartDate: e })

  };

  const OnChangeHandlerEndDate = (e) => {
    setSelectedDate(true)
    let today = moment(new Date()).format(SessionManage.getdateformat());
    setState({ ...state, ['EndDate']: e });
    setExceptionPolicyData({ ...exceptionPolicyData, EndDate: e })

  };

  const [updateNav, setUpdateNav] = useState("/CategoryMinimumAchievement-Policy/List")




  const [settlFreqValues, setSettlFreqValues] = useState([]);
  const [rebateTypValues, setRebateTypeValues] = useState([]);
  const [SCDropDownValues, setSCDropDownValues] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [showProductPopup, setShowProductPopup] = useState("");
  const [showCategoryPopup, setshowCategoryPopup] = useState("");
  const [showCustomerPopup, setshowCustomerPopup] = useState("");
  const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState("");
  const [showSetlFreqPopup, setshowSetlFreqPopup] = useState("");
  const [showRebateTypePopup, setshowRebateTypePopup] = useState("");
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [clickProduct, setclickProduct] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
  const [selectedType, setSelectedType] = useState('Attribute');
  const [rebatelinestatus, setrebatelinestatus] = useState('New');
  const [FilteredProducts, SetFilteredProducts] = useState([]);
  const [attbCheckAll, setattbCheckAll] = useState(false);
  const [keyValue, SetKeyValue] = useState("");
  const [itemNo, setItemNo] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [datagroupCust, setDataGroupCust] = useState([]);
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [loading, setLoading] = useState(false);
  let disableField = false;
  let catValues = [];
  const [dropDownValues, setDropDownValues] = useState([{
    id: 0,
    Category: [],
    SubCategory: [],
  }])
  let initial_value = [
    { id: 0, Category: "", Operator: "", Value: "", Condition: "", dropDownValues: [], ValueListString: [], SelectedItemSubCategory: "", SubCategory: [] },
  ]
  let initExceptionValues = [
    { id: 0, Operator: "", ValueListString: "", Condition: "", DataId: 0 },
  ]
  const [ExceptionValueList, setExceptionValueList] = useState(
    [{
      id: 0,
      Category: "",
      SubCategory: "",
      Operator: "",
      Value: "",
      Condition: "",
      dropDownValues: [],
      ValueListString: [],
      DataId: 0,
      DataCategoryLabel: "",
      DataSubCategoryLabel: "",
      SelectedItemSubCategory: "",
      ValueString: "",
      StatusLabel: ""
    },
    ]
  );
  const [exceptionValues, setExceptionValues] = useState([])

  console.log(userPerms, "permi");

  const [attbSearch, SetAttbSearch] = useState({
    "Category1": [],
    "Category2": [],
    "Category3": [],
    "Category4": [],
    "Category5": [],
  })
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  let [exceptionPolicyData, setExceptionPolicyData] = useState({
    Id: 0,
    PolicyId: "",
    PolicyName: "",
    CreatedUserId: parseInt(loggedInUser),
    LastModifiedUserId: parseInt(loggedInUser),
    RebatePayoutCondId: 4,
    FiscalYear: "",
    RebateTypeId: 0,
    RebateMeasureId: 0,
    StartDate: "",
    EndDate: "",
    CreatedDate: moment(new Date()).format(SessionManage.getcurrentdateformat())
  });
  let disabled = false;

  const [showExceptionWorkflow, setshowExceptionWorkflow] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [workflowchart, setworkflowchart] = useState([]);
  const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
  const [workflowData, setWorkflowData] = useState([]);
  const [woData, setWoData] = useState('');
  const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
  const [showspinneraccural, setshowspinneraccural] = useState('');
  const [activepolicyProgramLine, setActivePolicyProgramLine] = useState([])
  const [expandAccurals, setExpandAccruals] = useState('')
  const [icon, setIcon] = useState('fa fa-plus');
  const [loadedFrom, setLoadedFrom] = useState('');
  const [checkedValue, setCheckedValue] = useState([])
  const [state, setState] = useState({})
  const clickCloseAccuralWorkflow = () => {
    setshowExceptionWorkflow(false);
  }
  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  let wData = [0]
  const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0 })
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
  const [saveProgramLine, setSaveProgramLine] = useState(false);



  const [AllCategoryValues, setAllCategoryValues] = useState([])
  const [AllSubCategoryValues, setAllSubcategoryValues] = useState([])
  const [regioncheckAll, setRegioncheckAll] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [stockistOptions, setStockistOptions] = useState([]);
  const [showZonePopup, setShowZonePopup] = useState(false);
  const [showStatePopup, setShowStatePopup] = useState(false);
  const [showStationPopup, setShowStationPopup] = useState(false);
  const [showStockistPopup, setShowStockistPopup] = useState(false);
  const [showSelectedZonepopup, setShowSelectedZonepopup] = useState(false);
  const [showSelectedStatepopup, setShowSelectedStatepopup] = useState(false);
  const [showSelectedStationpopup, setShowSelectedStationpopup] = useState(false);
  const [showSelectedStockistpopup, setShowSelectedStockistpopup] = useState(false);
  const [SalesLevelData, setSalesLevelData] = useState([])
  const [regionDataSelected, setRegionDataSelected] = useState([]);

  const [customerDataFilter, setCustomerDataFilter] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [data, setData] = useState([]);
  const [filtertextCust, setfiltertextCust] = useState('');
  const [custFilterCol, setCustomerFilterCol] = useState('');
  const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
  const [filterValueCust, setFilterValueCust] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [customercheckAll, setCustomercheckAll] = useState(false);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [customerChanged, setCustomerChanged] = useState(false);
  const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
  const [filterSearchCust, setFilterSearchCust] = useState("");
  const [filtercustData, setfilterCustData] = useState([]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState('');
  const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
  const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
  const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
  const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
  const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
  const [filtercustGroupData, setfilterCustGroupData] = useState([]);
  const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);
  const [columnAscending, setColumnAscending] = useState(true);
  const [sortColName, setSortColName] = useState('Id');
  const [searchValue, setSearchValue] = useState("")
  const [filterSearch, setFilterSearch] = useState("");
  const [settlFreqcheckAll, setsettlFreqcheckAll] = useState(false);
  const [RebateTypecheckAll, setRebateTypecheckAll] = useState(false);
  const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
  const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
  const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
    label: '',
  });
  const [filterValueCustomer, setFilterValueCustomer] = useState({
    Name: '',
    Label: '',
    Station: '',
    State: '',
    Zone: '',

  });

  const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
  const [showSelectedCustomerGrouppopup, setShowSelectedCustomerGrouppopup] = useState(false);
  const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
  const [showSelectedCategorypopup, setShowSelectedCategorypopup] = useState(false);
  const [showSelectedRebateType, setShowSelectedRebateType] = useState(false);
  const [showSelectedSettlFreq, setShowSelectedSettlFreq] = useState(false);
  const [countKeyValue, SetCountKeyValue] = useState("");
  const [SettlFreqDataSelected, setSettlFreqDataSelected] = useState([])
  const [RebateTypeDataSelected, setRebateTypeDataSelected] = useState([])
  const [customerDataSelected, setCustomerDataSelected] = useState([]);
  const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [showAddButton, setShowAddButton] = useState(false)
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [modifiedLst, setModifiedLst] = useState([])

  const [incentiveList, setIncentiveList] = useState(["Additional", "Deduction"])
  const [incenticevalue, setIncentiveValue] = useState(["Annual Sales", "Eligible Incentive"])
  const [showProductPopupTable, setShowProductPopupTable] = useState(false);
  const [productIndex, setProductIndex] = useState(null)
  const [productValueTable, setProductValueTable] = useState([])
  const [selecteProductValuetable, setSelectedProductValuableTable] = useState([])
  const [showSelectedProductPopupTable, setShowSelectedProductPopupTable] = useState(false);
  const [effectedTod, setEffectedTod] = useState([]);
  const [effectedSegement, setEffectedSegment] = useState([])
  const [productDataFilter, setProductDataFilter] = useState([])
  const [productSearch, setProductSearch] = useState();
  const [segmentList, setSegmentList] = useState([])
  const [productCheckAll, setProductCheckAll] = useState(false)
  const [showProductFilterPopup, setshowProductfilterpopup] = useState(false)
  const [productFilterPopuptext, setProductfilterPopuptext] = useState()
  const [productFilterCol, setProductFilterCol] = useState()
  const [MPGData, setMPGData] = useState([])
  const [showVehicleTypeValidation, setshowVehicleTypeValidation] = useState(false)
  const [productDataSelected, setProductDataSelected] = useState([]);
  const [selectedPart, setSelectedParts] = useState([])
  const [vehicleTypeChange, setVehicleTypeChange] = useState();

  {
    useEffect(() => {
      setLoadedFrom(props.name);

      // Conditionally set the permission URL based on the value of props.name
      const permissionUrl = props.name === "fromApproval"
        ? "/CategoryMinimumAchievementPolicyList/pending"
        : "/CategoryMinimumAchievement-Policy/List";

      // Call the function with the conditional URL
      getUserPermission(permissionUrl);

      ExceptionValueList.length = 0
      console.log("24/1", props)
      GetSubdivideTargetType();
      GetExceptionType();
      GetProductPopUpValues('rebate/ProductSelectorByAttributeNew');
      GetProductAtrribute1("SDCommon/GetAllCategories")
      GetProductAtrribute2("SDCommon/GetAllSubCategories")
      GetCustomerData("Customer/GetIndividualCustomersWithoutGroup")
      GetSettlFreqData("settlementfrequency")
      // GetRebateTypeData("RebateType/GetAll")
      getRebateTypeData("rebatetype")
      loadCategoryData();
      loadVehicle();
      loadTargetMeasure();
      loadEffectedTod();
       
      // loadSegment();
      loadMPG();
      // console.log(VolumeRebateList, 'vol-r-list');
      // loadSubCategory(
      //   VolumeRebateList[0].Category
      // );
      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
      GetDateRangeAndProducts("DashBoard/GetDateRangeAndProducts");
      if (props.id !== '0') {
        getExceptionPolicyById("Categoryminimumachievementpolicy/GetById", props.id);
      }
      else {
        setLockedBy(loggedInUser);
        setIsLocked(true);
        GetExceptionCategorySubCategoryValues("TargetSettingPolicy/GetDropdownValues");
      }
      getZoneData()
    }, [props.id])
  }




  const getZoneData = () => {
    let data1 = []
    let data2 = []
    let data3 = []
    let data4 = []
    Service.getAllZones().then(result => {
      if (typeof result !== 'undefined') { // Fixed variable name from 'res' to 'result'
        console.log(result, 'zoneszzz');
        data1 = result.map((each) => ({
          ...each,
          selected: false
        }));
        setZoneOptions(data1);
      }
    })



    Service.getAllStates().then(res => {
      if (typeof res !== 'undefined') {
        data2 = res.map((each) => ({
          ...each,
          selected: false
        }));
        setStateOptions(data2);
      }
    })


    Service.getAllStations().then(response => {
      if (typeof response !== 'undefined') {
        data3 = response.map((each) => ({
          ...each,
          selected: false
        }));
        setStationOptions(data3);
      }
    })

    Service.getdata("SDCommon/GetAllStockists").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          data4 = res.data.map((each) => ({
            ...each,
            selected: false
          }));
          setStockistOptions(data4);
        }
      }
    })
  }

  const loadEffectedTod = (event) => {
    // let vehicleTypeId = event.target.value;
    // setSelectedVehicle(vehicleTypeId);


    Service.getAllDropdownData("SDCommon/GetAllTodtypes")
      .then((data) => {
        // console.log(data, "tod types");
        setEffectedTod(data)
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching category data:", error);
      });



  };

  const loadSegment = (event) => {

    Service.getAllDropdownData("Segment/GetAll")
      .then((data) => {

        console.log("Segment Data", data)

        // const labels = data.map((each) => each.label).concat("Entire TOD");

        // setEffectedSegment(labels);
      })
      .catch((error) => {

        console.error("Error fetching category data:", error);
      });

  };
  const loadMPG = () => {
    Service.getdata('MaterialPricingPolicy/GetSubCategory').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
                each['hidden'] = false
              })
              console.log(res.data, "MPG Data")
              setMPGData(res.data)

            }
          }
        }
      }
    });
  }

  const [ExceptionTypeList, setExceptionTypeList] = useState([]);
  const GetSubdivideTargetType = () => {
    Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setSubdivideTargetTypeList(res.data);
        }
      }
    });
  }

  const GetExceptionType = () => {
    Service.getdata("ExceptionPolicy/GetAllExceptionType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          setExceptionTypeList(res.data);
        }
      }
    });
  }
  const GetExceptionCategorySubCategoryValues = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          // res.data.DataList.map((each) => {
          //   each.SubCategory.map((eachsub) => {
          //     eachsub["dropdownState"] = {
          //       'selected': false,
          //       'row': -1
          //     }
          //   })
          // })

          setDropDownValues(res.data.DataList);

          let CatData = [];
          let SubCatData = [];
          res.data.DataList.map((i) => {
            const data = {
              id: i.Id,
              Id: i.Id,
              Name: i.Category,
              label: i.Category,
              value: i.Category,
            }

            CatData.push(data);
            i.SubCategory.map((s) => {
              const subdata = {
                id: s.Id,
                Id: s.value,
                Name: s.label,
                label: s.label,
                value: s.value,
              }
              SubCatData.push(subdata);
            })

          });

          setCategoryValues(CatData);
          setSubCategoryValues(SubCatData);

          setUpdatedCategoryValues(list => ([...CatData]));
          catValues = CatData;

          setModifiedLst(res.data.DataList)
        }
      }
    });
  }

  const GetProductPopUpValues = (programId) => {

    Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
        console.log(res.data.DataList, "Product Datalist")
        setProductValues(ProductValues => ([...ProductValues, ...res.data.DataList]));
        setdualListBoxOptionsByAttribute(res.data.DataList)
      }
    });
  };
  const GetProductPopUpSegemtValues = (mpgId,mappingType,value,index,updatedList) => {
    
    Service.productSelectorBySegment('Categoryminimumachievementpolicy/ProductSelectorBySegment', '', 200000, 1, 0, [], 0,mpgId,mappingType,value).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
        console.log(res.data.DataList, "Product Segment Datalist")
        
       
        const newList = [...updatedList];
        newList[index] = {
          ...newList[index],
          Productvalue: res.data.DataList.map(item => item.Id),
        };

        // Set the updated VolumeRebateList once after both updates
        setVolumeRebateList(newList);
        
       
       
      }
    });
  };
  const GetCustomerData = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setCustomerValues(res.data)

              setCustomerData(res.data)
            }
          }
        }
      }

    });
  }
  const GetGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

    Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setCustomerGroupValues(res.data.DataList)

            }
          }
        }
      }

    });
  }
  const GetProductAtrribute1 = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          // if (res.status === 200) {
          if (res.data !== 'null' && res.data !== 'undefined') {
            let data1 = res.data.map((each) => ({
              ...each,
              selected: false
            }));
            setAllCategoryValues(data1)
          }
          // }
        }
      }

    });
  }
  const GetProductAtrribute2 = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let data1 = res.data.map((each) => ({
                ...each,
                selected: false
              }));
              setAllSubcategoryValues(data1)
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute3 = (method) => {
    let key = "Cat-III"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute3Values(res.data)
              setAttribute3ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category3']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute4 = (method) => {
    let key = "Cat-IV"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute4Values(res.data)
              setAttribute4ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category4']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute5 = (method) => {
    let key = "Cat-V"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute5Values(res.data)
              setAttribute5ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category5']: res.data })
            }
          }
        }
      }

    });
  }
  const GetSettlFreqData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setSettlFreqValues(res.data)
            }
          }
        }
      }

    });
  }

  const GetRebateTypeData = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setRebateTypeValues(res.data)
            }
          }
        }
      }

    });
  }

  console.log("25/1", ExceptionValueList)
  console.log("25/1", exceptionValues)

  const onChangeTargetSelection = (e) => {

    setShowTargetSelection(e.name)

    setSubdivideTargetid(e.value);


    if (e.value === "Select") {
      setShowTargetTable(false)
    }
    else {
      setShowTargetTable(true)
      setShowTargetSelection(e.name)
    }
    setExceptionPolicyData({ ...exceptionPolicyData, ['SubTierTargetTypeId']: e.value, ['SubDivideTargetType']: e.name });
  }

  const [subdividefield, setSubdividefield] = useState(false);
  const [FYThresholdfield, setFYThresholdfield] = useState(false);

  const add = (flag) => {
    setExceptionValues(initExceptionValues);
    if (ExceptionValueList.length == 0) {
      setExceptionValueList(initial_value)
    }
    setaddCategory(true);
  }

  const getSegmentByFiscalYear = (year) => {
    Service.getSegementDetailByFiscalYear('Categoryminimumachievementpolicy/GetSegmentDetailByFiscalYear', year).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            console.log(res.data, "Segment datass")
            setSegmentList(res.data)
            const labels = res.data.map((each) => each.label).concat("Entire TOD");
            setEffectedSegment(labels);

          }
        }
      }
    });

  }

  const AddVolume = (index) => {
    // console.log(VolumeRebateList, 'v-r-l');
    // if (VolumeRebateList[index].Segment === null || VolumeRebateList[index].Segment === '' || VolumeRebateList[index].Segment === 'Select') {
    //   setErrorMessage(`${t(' Please Select ')}${t('Segment')}`);
    //   setErrorStatus("Error");
    //   setShowErrorModal(true);
    //   return;
    // }



    const _item = {
      SegmentId: "",
      MpgId: "",
      VehicletypeId: "",
      Productvalue: [],
      Eligiblity: 0,
      EligiblityGrowthRevenue: 0,
      EligiblityGrowthVolume: 0,
      TargetMeasure: null,
      Vehicletypetm: null,
      Effectedtod: null,
      Effectedsegments: "",
      Addredincentive: "",
      Incenentivevaluepercent: 0,
      Incenentivevalue: "",
      Lumpsumvalue: 0,
    };
    setVolumeRebateList([...VolumeRebateList, _item]);
    /*if (VolumeRebateList[index].SubCategory === null || VolumeRebateList[index].SubCategory === '' || VolumeRebateList[index].SubCategory === 'Select') {
      // alert('Please select Subcategory');
      setErrorMessage(`${t(' Please Select ')}${t('CategoryMinimumAchievementPolicy.SubCat')}`);
      setErrorStatus("Error");
      setShowErrorModal(true);
      return;
    }
    if (VolumeRebateList[index].VehicleType === null || VolumeRebateList[index].VehicleType === '' || VolumeRebateList[index].VehicleType === 'Select') {
      // alert('Please select vehicletype');
      setErrorMessage(`${t(' Please Select ')}${t('CategoryMinimumAchievementPolicy.VT')}`);
      setErrorStatus("Error");
      setShowErrorModal(true);
      return;
    }*/

    // if (VolumeRebateList[index].Eligiblity === null || VolumeRebateList[index].Eligiblity === '') {
    //   // alert('Please enter eligibility');
    //   setErrorMessage(`${t(' Please Enter ')}${t('CategoryMinimumAchievementPolicy.E%')}`);
    //   setErrorStatus("Error");
    //   setShowErrorModal(true);
    //   return;
    // }



    // if (VolumeRebateList[index].Segment !== null && VolumeRebateList[index].Eligiblity !== null
    //   && VolumeRebateList[index].EligiblityGrowthRevenue !== null && VolumeRebateList[index].EligiblityGrowthVolume !== null &&
    //   (VolumeRebateList[index].Segment != null && VolumeRebateList[index].Segment != '' && VolumeRebateList[index].Segment != 'Select')) {

    //   if ((VolumeRebateList[index].Eligiblity !== null
    //     || VolumeRebateList[index].EligiblityGrowthRevenue !== null || VolumeRebateList[index].EligiblityGrowthVolume !== null)

    //     && (VolumeRebateList[index].Segment != null && VolumeRebateList[index].Segment != '' && VolumeRebateList[index].Segment != 'Select')) {


    // else {
    //   setErrorMessage(`${t('PleaseSelect')}${t('Rebates.Rebate')} ${'₹ / % / LS'}`);
    //   setErrorStatus("Error")
    //   setShowErrorModal(true)
    // }
    // }
  }

  const RemoveVolume = (idx) => {
    setVolumeRebateList(VolumeRebateList.filter((item, index) => index !== idx));
    setChangeHandle(true)
  }




  const AddVolumeCat = (index) => {

    let volCheck = false

    if (ExceptionValueList.length >= 0) {
      ExceptionValueList.forEach((each, index) => {
        let idx = parseInt(ExceptionValueList.indexOf(each)) + 1

        if (each.Category == '') {
          alert("Please Select Category in Row " + idx)
          volCheck = true
        }
        else if (each.SelectedItemSubCategory == '') {
          alert("Please Select SubCategory in Row " + idx)
          volCheck = true
        }
        else if (each.Operator == '') {
          alert("Please Select Operator in Row " + idx)
          volCheck = true
        }
        else if (each.ValueListString.length == 0) {
          alert("Please Select Value in Row " + idx)
          volCheck = true
        }
      })
    }
    if (volCheck == false) {
      const _item = {
        id: 0,
        Category: "",
        Operator: "",
        Value: "",
        Condition: "",
        SubCategory: [],
        ValueListString: [],
        SelectedItemSubCategory: '',
      }
      const item2 = {
        id: 0,
        DataId: 0,
        Operator: "",
        ValueListString: [],
        Condition: "",
      }
      setExceptionValueList([...ExceptionValueList, _item]);
      setExceptionValues([...exceptionValues, item2]);
    }
  }
  const RemoveVolumeCat = (idx) => {
    let list = [...ExceptionValueList]

    if (list[idx] && list[idx].SelectedItemSubCategory) {
      switch (list[idx].SelectedItemSubCategory) {
        case "Zone":
          zoneOptions.map((each) => { each.selected = false });
          break;
        case "Station":
          stationOptions.map((each) => { each.selected = false });
          break;
        case "State":
          stateOptions.map((each) => { each.selected = false });
          break;
        case "Stockist":
          stockistOptions.map((each) => { each.selected = false });
          break;
        // case "ECC":
        //   [].map((each) => { each.selected = false });
        //   break;
        case "Label":
          settlFreqValues.map((each) => { each.Selected = false });
          break;
        case "Rebate Type":
          rebateTypValues.map((each) => { each.selected = false });
          break;
        case "Individual Customer":
          CustomerValues.map((each) => { each.selected = false });
          break;
        case "Group Customer":
          CustomerGroupValues.map((each) => { each.selected = false });
          break;
        case "Category":
          AllCategoryValues.map((each) => { each.selected = false });
          break;
        case "SubCategory":
          AllSubCategoryValues.map((each) => { each.selected = false });
          break;
          list[idx]['SelectedItemSubCategory'] = '';
          list[idx]['DataId'] = '';
          list[idx]['ValueListString'] = [];
          list[idx]['Operator'] = "";
      }
    } else {
      list[idx]['SelectedItemSubCategory'] = '';
      list[idx]['DataId'] = '';
      list[idx]['ValueListString'] = [];
      list[idx]['Operator'] = "";
    }

    setExceptionValueList(list);
    setExceptionValueList(ExceptionValueList.filter((item, index) => index !== idx));
    setExceptionValues(exceptionValues.filter((item, index) => index !== idx));

    setChangeHandle(true)
  }

  const ClickValueCategoryPopup = (index) => {

    let categoryOptions = [...CategoryValues]
    let list = [...ExceptionValueList]
    if (list[index]?.Category === '') {
      categoryOptions.forEach((each) => each['selected'] = false)
      setCategoryList(categoryOptions)
    }
    else {
      categoryOptions.forEach((each) => {
        if (each.Name === list[index].Category) {
          each['selected'] = true
        }
      })
      setCategoryList(categoryOptions)
    }
    setCategoryIndex(index)
    setCategoryPopup(true)
    setsidebarwidth("60vw")
  }

  const CloseCategory = () => {
    setCategoryPopup(false)
    setCategoryList([])
  }

  const ClickValueSubCategoryPopup = (subcatData, index) => {
    setSubCategoryList(subcatData)
    setSubCategoryIndex(index)
    setShowSubCategoryPopup(true)
    setsidebarwidth("60vw")
  }

  const CloseSubCategory = () => {
    setShowSubCategoryPopup(false)
  }

  const handleSubCatChanges = (event, data) => {
    //debugger
    const list = [...ExceptionValueList];
    const subcatarr = [...list[SubCategoryIndex].SubCategory];

    subcatarr.forEach((each) => {
      if (event.target.checked && each.label === data.label && data !== null) {
        list[SubCategoryIndex]['SelectedItemSubCategory'] = data.label;
        list[SubCategoryIndex]['DataId'] = data.value;
      }
      else if (!event.target.checked) {
        switch (list[SubCategoryIndex]?.SelectedItemSubCategory) {
          case "Zone":
            zoneOptions.forEach((each) => { each.selected = false });
            break;
          case "Station":
            stationOptions.forEach((each) => { each.selected = false });
            break;
          case "State":
            stateOptions.forEach((each) => { each.selected = false });
            break;
          case "Stockist":
            stockistOptions.forEach((each) => { each.selected = false });
            break;
          // case "ECC":
          //   [].forEach((each) => { each.selected = false });
          //   break;
          case "Label":
            settlFreqValues.forEach((each) => { each.Selected = false });
            break;
          case "Rebate Type":
            rebateTypValues.forEach((each) => { each.selected = false });
            break;
          case "Individual Customer":
            CustomerValues.forEach((each) => { each.selected = false });
            break;
          case "Group Customer":
            CustomerGroupValues.forEach((each) => { each.selected = false });
            break;
          case "Category":
            AllCategoryValues.forEach((each) => { each.selected = false });
            break;
          case "SubCategory":
            AllSubCategoryValues.forEach((each) => { each.selected = false });
            break;
        }
        list[SubCategoryIndex]['SelectedItemSubCategory'] = '';
        list[SubCategoryIndex]['DataId'] = '';
        list[SubCategoryIndex]['ValueListString'] = [];
        list[SubCategoryIndex]['Operator'] = "";
      }
    });
    setSubCategoryList(subcatarr);

    let currentCategory = list[SubCategoryIndex]['Category'];



    if (list[SubCategoryIndex]?.Category == 'Product') {
      if (data.label == 'Label') {

        setSelectedSubCategory("Product");
      }
      else if (data.label == 'Category1') {

        setSelectedSubCategory("Category1");
      }
      else if (data.label == 'Category2') {

        setSelectedSubCategory("Category2");
      }
      else if (data.label == 'Category3') {

        setSelectedSubCategory("Category3");
      }
      else if (data.label == 'Category4') {

        setSelectedSubCategory("Category4");
      }
      else if (data.label == 'Category5') {

        setSelectedSubCategory("Category5");
      }
    }
    setExceptionValueList(list);
  }



  const OnCategoryChange = (e, data) => {
    const list = [...ExceptionValueList];
    const catarr = [...CategoryList];

    catarr.forEach((each) => {
      if (e.target.checked) {
        if (each.label === data.label) {
          each.selected = true;
          list[CategoryIndex]['Category'] = data.Name;
          let SubCatData = [];
          dropDownValues.map((i) => {
            if (i.Category === data.label) {
              list[CategoryIndex]['SubCategory'] = i.SubCategory;
            }
          });
        }
      }
      else {
        list[CategoryIndex]['Category'] = '';
        list[CategoryIndex]['SubCategory'] = []
      }
    });

    if (list[CategoryIndex]?.Category === "") {
      switch (list[CategoryIndex]?.SelectedItemSubCategory) {
        case "Zone":
          zoneOptions.map((each) => { each.selected = false });
          break;
        case "Station":
          stationOptions.map((each) => { each.selected = false });
          break;
        case "State":
          stateOptions.map((each) => { each.selected = false });
          break;
        case "Stockist":
          stockistOptions.map((each) => { each.selected = false });
          break;
        // case "ECC":
        //   [].map((each) => { each.selected = false });
        //   break;
        case "Label":
          settlFreqValues.map((each) => { each.Selected = false });
          break;
        case "Rebate Type":
          rebateTypValues.map((each) => { each.selected = false });
          break;
        case "Individual Customer":
          CustomerValues.map((each) => { each.selected = false });
          break;
        case "Group Customer":
          CustomerGroupValues.map((each) => { each.selected = false });
          break;
        case "Category":
          AllCategoryValues.map((each) => { each.selected = false });
          break;
        case "SubCategory":
          AllSubCategoryValues.map((each) => { each.selected = false });
          break;

          list[CategoryIndex]['SelectedItemSubCategory'] = '';
          list[CategoryIndex]['DataId'] = '';
          list[CategoryIndex]['ValueListString'] = [];
          list[CategoryIndex]['Operator'] = "";
      }
    } else {
      list[CategoryIndex]['SelectedItemSubCategory'] = '';
      list[CategoryIndex]['DataId'] = '';
      list[CategoryIndex]['ValueListString'] = [];
      list[CategoryIndex]['Operator'] = "";
    }

    setCategoryList(catarr)
    setExceptionValueList(list)
  }




  const OnSubCategoryChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    let currentCategory = list[index]['Category'];

    list[index].SubCategory.map((each) => {
      if (each.dropdownState.selected == true && each.dropdownState.row - 1 == index) {
        each.dropdownState = {
          selected: false, row: index + 1
        }
        list[index]['ValueListString'] = [];
        list[index]['Operator'] = "";
        if (list[index].SelectedItemSubCategory.toLowerCase() == 'label' && (list[index].Category.toLowerCase == 'product')) {
          SetFilteredProducts([])
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'label') && (list[index].Category.toLowerCase() == 'category')) {
          AllCategoryValues.map((each) => { each.selected = false })
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'label') && (list[index].Category.toLowerCase() == 'subcategory')) {
          AllSubCategoryValues.map((each) => { each.selected = false })
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'zone') && (list[index].Category.toLowerCase() == 'customer')) {
          zoneOptions.map((each) => { each.selected = false })
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'station') && (list[index].Category.toLowerCase() == 'customer')) {
          stationOptions.map((each) => { each.selected = false })
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'state') && (list[index].Category.toLowerCase() == 'customer')) {
          stateOptions.map((each) => { each.selected = false })
        }
        else if ((list[index].SelectedItemSubCategory.toLowerCase() == 'stockist') && (list[index].Category.toLowerCase() == 'customer')) {
          stockistOptions.map((each) => { each.selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'settlement frequency') {
          settlFreqValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'individual customer') {
          CustomerValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'customer group') {
          CustomerGroupValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'rebate type') {
          rebateTypValues.map((each) => { each.Selected = false })
        }
        list[index]['SelectedItemSubCategory'] = "";
      }

      if (each.label == data.label) {

        each.dropdownState = {
          selected: true, row: index + 1
        }
      }
    })
    if (data.Id !== 0) {

      list[index]['SelectedItemSubCategory'] = data.label;
      list[index]['DataId'] = data.value;
      list2[index]['DataId'] = data.value;
    }
    setExceptionValueList(list);
    setExceptionValues(list2);
    if (currentCategory.label == 'Product') {
      if (data.label == 'Label') {

        setSelectedSubCategory("Product");
      }
      else if (data.label == 'Category1') {

        setSelectedSubCategory("Category1");
      }
      else if (data.label == 'Category2') {

        setSelectedSubCategory("Category2");
      }
      else if (data.label == 'Category3') {

        setSelectedSubCategory("Category3");
      }
      else if (data.label == 'Category4') {

        setSelectedSubCategory("Category4");
      }
      else if (data.label == 'Category5') {

        setSelectedSubCategory("Category5");
      }
    }

  }

  const OnOperatorChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[index]['Operator'] = data;
    list2[index]['Operator'] = data;
    setExceptionValueList(list);
    setExceptionValues(list2);

  }


  const UpdateProducts = (_SelectedProducts, saveStatus) => {

    setChangeHandle(true)

    setPrevSelectedTypeState(selectedType);

    let ids = []
    SetFilteredProducts(_SelectedProducts)
    //setShowProductPopup(false);
    if (saveStatus) {
      setShowProductPopup(false);
    }

    _SelectedProducts.forEach((each) => {
      const pieces = each.split("_")
      const last = pieces[pieces.length - 1]
      ids.push(last)
    })
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
    const list3 = [...VolumeRebateList];
    
 
    // list[itemNo]['ValueListString'] = ids;
    // list2[itemNo]['ValueListString'] = ids;
    list3[productIndex]['Productvalue'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
    setVolumeRebateList(list3)
  }

  const SelectedProductTypeHandler = (event) => {
    setChangeHandle(true)
    setSelectedType(event.target.value);

    if (event.target.value === 'Heirarchy') {
      setPrevSelectedTypeState("Attribute");
    } else {
      setPrevSelectedTypeState("Heirarchy");
    }
  }

  const CloseProductPicker = () => {


  }

  const ClickValueCountPopup = (item, index) => {
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Product').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedData = []
        let productData = [...ProductValues]
        let filteredData = []
        if (selectedValue != null && selectedValue.length > 0) {
          selectedValue.forEach((selectedpro, index) => {
            ProductValues.forEach((eachpro) => {
              if (selectedpro == eachpro.Id) {
                eachpro.Selected = true
                let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                filteredData.push(z)
              }
            })
          })
        }
        setdualListBoxOptionsByAttribute(productData)
        setShowSelectedProductpopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category1').toLowerCase()) {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute1Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('zone') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of zoneOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedZonepopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('state') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stateOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStatepopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('station') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stationOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStationpopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('stockist') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stockistOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStockistpopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category2').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute2Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category3').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute3Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category4').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute4Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category5').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute5Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerpopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group Name').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerGrouppopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerGroupPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Settlementfrequency').toLowerCase()) {
        setShowSelectedSettlFreq(true);
        clickShowSelectedSettlFreq()
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of rebateTypValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedRebateType(true);
        clickShowSelectedRebateType()
        setsidebarwidth("70vw")
      }
    }
    else {
      alert('Please Select Category & SubCategory')
    }
  }

  const checkAllRegionchange = (mycheckbox) => {
    let Ids = [];
    var checkboxes = document.querySelectorAll("input[name = 'region-check']");
    if (mycheckbox.target.checked == true) {
      setRegioncheckAll(true);

      let olddata = [...SalesLevelData];
      olddata.map((i) => {
        if (i.avalRegion == true) {
          i.selected = true;
        }

      })
      setSalesLevelData(olddata);

    }
    else {
      setRegioncheckAll(false);


      let olddata = [...SalesLevelData];
      olddata.map((i) => {
        i.selected = false;
      })
      setSalesLevelData(olddata);
    }
  }
  const handleSegmentChange = async (event, index) => {
    debugger;
    const value = event.target.value;
    const updatedList = [...VolumeRebateList];
  
    // Update the SegmentId in the VolumeRebateList
    updatedList[index] = {
      ...updatedList[index],
      SegmentId: value === 'Select' ? "" : value,
    };
    
  
    // Find the segment details and call GetProductPopUpSegemtValues if the segment exists
    const segment = segmentList.find(item => parseInt(item.SegmentId, 10) === parseInt(value, 10));
    if (segment) {
      const { Mpg, AttributeName, Value } = segment;
      GetProductPopUpSegemtValues(Mpg, AttributeName, Value,index,updatedList);
    }
    else{
      updatedList[index] = {
        ...updatedList[index],
        Productvalue: []
      };
      setVolumeRebateList(updatedList)
    }
  };

  const handleMPGChange = async (event, index) => {

    const value = event.target.value
    const updatedList = [...VolumeRebateList];
    updatedList[index] = {
      ...updatedList[index],
      MpgId: value === 'Select' ? "" : value,
    };
    setVolumeRebateList(updatedList);
  };

  const handleVehicleTypeDialog = (action) => {
    debugger
    if (action === 'yes') {
      
      const updatedList = [...VolumeRebateList];
      updatedList[productIndex] = {
        ...updatedList[productIndex],
        VehicletypeId:vehicleTypeChange,
        ProductValue:[]
      }
      setVolumeRebateList(updatedList)

      
      // const vehicleTypeId = parseInt(VolumeRebateList[productIndex].VehicletypeId, 10);
      // console.log(VolumeRebateList.VehicletypeId, "Vehicle Type Id")
      // // Find the name associated with the vehicle type ID in vehicleTypeData
      // const vehicleTypeName = vehicleTypeData.find(item => item.Id === vehicleTypeId)?.Name;

      // console.log(vehicleTypeName, "Vehicle Name")
      // // Filter productValueTable based on the vehicle type name
      // const filteredProductValues = ProductValues.filter(
      //   item => item.Vehicletype === vehicleTypeName
      // );
      // console.log(filteredProductValues, "Filter Product")
      // // Update the specific index in productValueTable with filteredProductValues
      // setProductValueTable(prevTable => {
      //   const newTable = [...prevTable];
      //   newTable[productIndex] = filteredProductValues;
      //   return newTable;
      // });

      // setSelectedProductValuableTable(prevState =>
      //   prevState.map((item, index) => index === productIndex ? [] : item)
      // )
    }

    setshowVehicleTypeValidation(false);

  };
  const handleregionChanges = (e, label, id, flag) => {
    let selected = [];
    let updatedData = [];
    let olddata = [];
    let list = [...ExceptionValueList];

    let obj = [
      { name: 'Zone', data: zoneOptions, setter: setZoneOptions },
      { name: 'State', data: stateOptions, setter: setStateOptions },
      { name: 'Station', data: stationOptions, setter: setStationOptions },
      { name: 'Stockist', data: stockistOptions, setter: setStockistOptions },
    ];

    obj.forEach((each) => {
      if (flag === each.name) {
        olddata = [...each.data];
        updatedData = olddata.map((i) => {
          if (i.value === id) {
            i.selected = e.target.checked;
          }
          if (i.selected) {
            selected.push(i.value.toString());
          }
          return i;
        });

        each.setter(updatedData);
      }
    });

    list[itemNo]['ValueListString'] = selected;

  }

  const onClickOk = (popupOpen) => {
    if (popupOpen == 'category') {
      setCategoryPopup(false)
    }
    else if (popupOpen == 'subCategory') {
      setShowSubCategoryPopup(false)
    }
    else if (popupOpen == 'zone') {
      setShowZonePopup(false);
    }
    else if (popupOpen == 'state') {
      setShowStatePopup(false);
    }
    else if (popupOpen == 'station') {
      setShowStationPopup(false);
    }
    else if (popupOpen == 'stockist') {
      setShowStockistPopup(false);
    }
  }



  const onClickClose = (popupClose) => {
    if (popupClose == 'zone') {
      setShowZonePopup(false);
    }
    else if (popupClose == 'state') {
      setShowStatePopup(false);
    }
    else if (popupClose == 'station') {
      setShowStationPopup(false);
    }
    else if (popupClose == 'stockist') {
      setShowStockistPopup(false);
    }
  }

  const onClickCloseSelectedPopup = (popupClose) => {
    if (popupClose == 'zone') {
      setShowSelectedZonepopup(false);
    }
    else if (popupClose == 'state') {
      setShowSelectedStatepopup(false);
    }
    else if (popupClose == 'station') {
      setShowSelectedStationpopup(false);
    }
    else if (popupClose == 'stockist') {
      setShowSelectedStockistpopup(false);
    }
  }


  const clickSelectedShowPopup = (val) => {
    if (val == 'zone') {
      setShowZonePopup(false);
      setShowSelectedZonepopup(true);
    }
    else if (val == 'state') {
      setShowStatePopup(false);
      setShowSelectedStatepopup(true);
    }
    else if (val == 'station') {
      setShowStationPopup(false);
      setShowSelectedStationpopup(true);
    }
    else if (val == 'stockist') {
      setShowStockistPopup(false);
      setShowSelectedStockistpopup(true);
    }
  }

  const ClickValuePopupClose = (flag, value, popup) => {
    if (flag == 'Close' && value == 'Product' && popup == 'All') {
      setShowProductPopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Product' && popup == 'Selected') {
      setShowSelectedProductpopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Category' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'SubCategory' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'SubCategory' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }

    if (flag == 'Close' && value == 'Customer' && popup == 'All') {
      setshowCustomerPopup(false);
    }
    if (flag == 'Close' && value == 'Customer' && popup == 'Selected') {
      setShowSelectedCustomerpopup(false);
    }
    if (flag == 'Close' && value == 'CustomerGroup' && popup == 'All') {
      setshowCustomerGroupPopup(false);
    }
    if (flag == 'Close' && value == 'GroupCustomer' && popup == 'Selected') {
      setShowSelectedCustomerGrouppopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'All') {
      setshowSetlFreqPopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'Selected') {
      setShowSelectedSettlFreq(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'All') {
      setshowRebateTypePopup(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'Selected') {
      setShowSelectedRebateType(false);
    }
  }






  const ClickValuePopupCancel = () => {
    setshowCategoryPopup(false);
  }

  const clickShowWorkflow = (val, id) => {
    setsidebarwidth("70vw");
    if (val === "Workflow")
      setshowExceptionWorkflow(true);
    setshowWorkflow(false);
  }
  const _ = require('lodash');



  const getExceptionWorkflowApprovers = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setWorkflowApprovers(res.data)
          setWorkflowLevelCount(res.data.length)
          const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
          setworkflowchart(sortdata);

        }

      }

    });
  }

  const getExceptionWorkflowDetails = (id, pagesize, pagenumber) => {
    Service.getdatabyId('Categoryminimumachievementpolicy/GetCMAWorkflowById', id, pagesize, pagenumber).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {

              wData = res.data.DataList;
              setWorkflowData(wData);
              setshowspinnerworkflow(false)
              setWoData('get data');
            }
          }
        }
      }
    });

  }



  const changeIcon = (e, id) => {
    setExpandAccruals(id)

    if (icon == 'fa fa-plus') {
      setIcon('fa fa-minus');
    }
    if (icon == 'fa fa-minus') {
      setIcon('fa fa-plus');
    }

  }


  const ClickValuePopup = (item, index) => {

    setItemNo(index);
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && item.Category == 'Product') {
        if (props.id != 0) {
          let selectedData = []
          let productData = [...ProductValues]
          let filteredData = []

          let selectedValue = item.ValueListString;
          if (selectedValue != null && selectedValue.length > 0) {
            selectedValue.forEach((selectedpro, index) => {
              ProductValues.forEach((eachpro) => {
                if (selectedpro == eachpro.Id) {
                  eachpro.Selected = true
                  let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                  filteredData.push(z)
                }
              })
            })
          }
          setdualListBoxOptionsByAttribute(productData)
          SetFilteredProducts(filteredData)
        }

        setdualListBoxOptionsByAttribute(ProductValues)
        setclickProduct(true)
        setShowModal(true)
        setSelectedType('Attribute')
        setShowProductPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('label') && item.Category == 'Category') {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of AllCategoryValues) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        SetKeyValue('Category')
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('label') && item.Category.toLowerCase() == 'subcategory') {
        // //debugger
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of AllSubCategoryValues) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        SetKeyValue('SubCategory')
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerPopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerGroupPopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ("Settlementfrequency").toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of settlFreqValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowSetlFreqPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('zone') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of zoneOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowZonePopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('station') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stationOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStationPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('state') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stateOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStatePopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('stockist') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stockistOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStockistPopup(true);
        setsidebarwidth("70vw")
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of rebateTypValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowRebateTypePopup(true);
        setsidebarwidth("70vw")
      }
    }
    else if (item.Category == '') {
      let idx = itemNo + 1
      alert("Please Select Category in Row " + idx)
    }
    else if (item.SelectedItemSubCategory == '') {
      let idx = itemNo + 1
      alert("Please Select SubCategory in Row " + idx)
    }
    else if (item.Operator == '') {
      let idx = itemNo + 1
      alert("Please Select Operator in Row " + idx)
    }

  }

  const clickShowProductPopup = (e, index) => {
    let list = [...VolumeRebateList];
    let selectedProducts = [];
   
   if(VolumeRebateList[index].VehicletypeId !== "")
   {
     const vehicleTypeId = parseInt(VolumeRebateList[index].VehicletypeId, 10);
      console.log(VolumeRebateList.VehicletypeId, "Vehicle Type Id")
      // Find the name associated with the vehicle type ID in vehicleTypeData
      const vehicleTypeName = vehicleTypeData.find(item => item.Id === vehicleTypeId)?.Name;

      console.log(vehicleTypeName, "Vehicle Name")
      // Filter productValueTable based on the vehicle type name
      const filteredProductValues = ProductValues.filter(
        item => item.Vehicletype === vehicleTypeName
      );
      console.log(filteredProductValues, "Filter Product")
      // Update the specific index in productValueTable with filteredProductValues
      setdualListBoxOptionsByAttribute(filteredProductValues);
   }

    let selectedIdsAcrossOtherIndexes = [];
    list.forEach((part, idx) => {
      if (idx !== index) {
        selectedIdsAcrossOtherIndexes = [
          ...selectedIdsAcrossOtherIndexes,
          ...part.Productvalue
        ];
      }
    });

    dualListBoxOptionsByAttribute.forEach((each) => {
      each.Selected = false;
    });


    dualListBoxOptionsByAttribute.forEach((each) => {
      if (list[index].Productvalue.includes(each.Id.toString())) {
        each.Selected = true;
        selectedProducts.push(`${each.Label}_${each.Id}`);
      }
      each.Hidden = selectedIdsAcrossOtherIndexes.includes(each.Id.toString());
    });
    console.log("30-9s", selectedProducts)
   
    SetFilteredProducts(selectedProducts);
    setProductIndex(index);
    setShowModal(true);
    setSelectedType('Attribute');
    setShowProductPopup(true);
    setclickProduct(true);
    setsidebarwidth("70vw"); 
    
}

// const clickSelectedShowProductPopup = (e, index) => {
//   let lstcopy = FiltedProducts

//   let lst1 = [];
//   setProductDataSelected([...lstcopy])
   
//   setShowSelectedProductpopup(true);
//   setsidebarwidth("55vw");
//   setProductIndex(index)

//   let list = [...ProductTargetList]
//   let Products = [...list[index].Products]
//   ////debugger
//   const newArray = Products.map(item => parseInt(item.split('_').pop()));
//   dualListBoxOptionsByAttribute.forEach((each) => {
//       each.Selected = false
//   })
//   dualListBoxOptionsByAttribute.forEach((each) => {
//       newArray.forEach((selectedProducts) => {
//           if (each.Id === selectedProducts) {
//               each.Selected = true;
//           }
//       });
//   });

// }

  // const clickShowProductPopup = (index) => {
  //   //  debugger
  //   setProductValueTable((prevTable) => {
  //     // Check if there's already a value at the specified index
  //     if (!prevTable[index] || prevTable[index].length === 0) {
  //       const updatedTable = [...prevTable];
  //       updatedTable[index] = ProductValues;  // Insert ProductValues at the index
  //       return updatedTable;
  //     }
  //     return prevTable;  // If there's already a value, return the existing array
  //   });
  //   setsidebarwidth("70vw");
  //   setProductIndex(index)
  //   setShowProductPopupTable(true)
  // }


  const clickSelectedShowProductPopup = (e,index) => {

      const productIds = VolumeRebateList[index]?.Productvalue || [];
      console.log("Product Ids", productIds, "Index", index)
      const filteredOptions = dualListBoxOptionsByAttribute.filter(option => 
        (productIds.toString()).includes(option.Id.toString())
      );
      setSelectedParts(filteredOptions);
    
    setsidebarwidth("70vw");
    setProductIndex(index)
    setShowSelectedProductPopupTable(true)

  }
  const SearchProduct = (event) => {
    let list = [];
    const currentProductIndexData = productValueTable[productIndex] || []; // Default to an empty array if undefined

    let lstSelected = currentProductIndexData.filter((e) => e.Selected === true).map(item => {
      return { ...item };
    });

    setProductSearch(event.target.value);

    if (productDataFilter.length === 0) {
      list = currentProductIndexData; // Use the data for the current index
      setProductDataFilter(list);
    } else {
      list = productDataFilter;
    }

    if (event.target.value !== '') {
      let searchResults = list.filter((each) => {
        return each.Label.toLowerCase().includes(event.target.value.toLowerCase()) ||
          each.Name.toLowerCase().includes(event.target.value.toLowerCase());
      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];

      newlist.forEach((item) => {
        if (!uniquelst.some((x) => x.Id === item.Id)) {
          uniquelst.push(item);
        }
      });

      // Update the specific index in productValueTable
      setProductValueTable((prevTable) => {
        const updatedTable = [...prevTable];
        updatedTable[productIndex] = uniquelst; // Only update the data at the current productIndex
        return updatedTable;
      });

    } else {
      // If there's no search input, reset to the full list for the current index
      setProductValueTable((prevTable) => {
        const updatedTable = [...prevTable];
        updatedTable[productIndex] = list; // Update only the current index
        return updatedTable;
      });
    }
  };

  const okProduct = () => {
    setShowProductPopupTable(false)
  }
  const clickCloseProductListing = () => {
    setShowSelectedProductPopupTable(false)
  }

  const handleProductChanges = (event, id) => {
    const { value, checked } = event.target;
    const isChecked = event.target.checked;

    const updatedProductData = [...productValueTable];

    // Update only the specific index for productIndex
    updatedProductData[productIndex] = updatedProductData[productIndex].map((cslist) => {
      if (cslist.Id === id) {
        return { ...cslist, Selected: isChecked };
      }
      return cslist;
    });

    setProductValueTable(updatedProductData);

    //updating data at selectedProductPopup
    const selectedValuesForCurrentIndex = updatedProductData[productIndex].filter(
      (cslist) => cslist.Selected
    );

    setSelectedProductValuableTable((prevSelectedValues) => {
      const updatedSelectedValues = [...prevSelectedValues];
      updatedSelectedValues[productIndex] = selectedValuesForCurrentIndex;
      return updatedSelectedValues;
    });

    //updating data at volumeRebateList 
    const updatedVolumeRebateList = [...VolumeRebateList];
    let productValues = updatedVolumeRebateList[productIndex].Productvalue || [];

    if (checked) {

      productValues = [...productValues, value];
    } else {

      productValues = productValues.filter((item) => item !== value);
    }
    updatedVolumeRebateList[productIndex] = {
      ...updatedVolumeRebateList[productIndex],
      Productvalue: productValues,
    };

    // Set the updated state
    setVolumeRebateList(updatedVolumeRebateList);
    setProductCheckAll(false)
  };


  const checkAllProduct = (isChecked) => {

    if (isChecked)
      setProductCheckAll(true)
    else
      setProductCheckAll(false)
    // Step 1: Update all products in productValueTable for the current productIndex
    const updatedProductData = [...productValueTable];
    updatedProductData[productIndex] = updatedProductData[productIndex].map((product) => ({
      ...product,
      Selected: isChecked,
    }));
    setProductValueTable(updatedProductData);

    // Step 2: Update selectedProductValuableTable with all selected products for the current productIndex
    const selectedValuesForCurrentIndex = isChecked
      ? updatedProductData[productIndex]
      : []; // If unchecked, set to an empty array
    setSelectedProductValuableTable((prevSelectedValues) => {
      const updatedSelectedValues = [...prevSelectedValues];
      updatedSelectedValues[productIndex] = selectedValuesForCurrentIndex;
      return updatedSelectedValues;
    });

    // Step 3: Update VolumeRebateList with all product values for the current productIndex
    const updatedVolumeRebateList = [...VolumeRebateList];
    const productValues = isChecked
      ? updatedProductData[productIndex].map((product) => product.Id) // Collect all product Ids if checked
      : []; // If unchecked, set to an empty array

    updatedVolumeRebateList[productIndex] = {
      ...updatedVolumeRebateList[productIndex],
      Productvalue: productValues,
    };

    setVolumeRebateList(updatedVolumeRebateList);
  };


  const OnNameChange = (e) => {
    let value = e.target.value;
    setExceptionPolicyData({ ...exceptionPolicyData, ['PolicyName']: value })
    console.log(VolumeRebateList, "Volume Rebate List")
    console.log(productValueTable, "Prduct Value Table")
    console.log(vehicleTypeData, "Veh")
    console.log(exceptionPolicyData, "Exception Policy data")
    console.log(rebatePayoutCondData, "payout condition")
    console.log(dualListBoxOptionsByAttribute,"dual list")
    console.log(selectedPart,"selected Product")
    
    // getSegmentByFiscalYear("FY 2024-2025")
    // console.log(rebateMeasureData, "Rebate Measure Data")
   
  }

  console.log("16-3", VolumeRebateList);

  const validateField = () => {
    let isValid = true;
    if (selectedOptionsDateRange == "" || selectedOptionsDateRange == null) {
      setErrorMessage("Please select Fiscal Year in the header")
      isValid = false;

    }
    else if (exceptionPolicyData.RebateTypeId == "" || exceptionPolicyData.RebateTypeId == null) {
      setErrorMessage("Please select Rebate Type in the header")
      isValid = false;

    }
    else if (exceptionPolicyData.RebatePayoutCondId == "" || exceptionPolicyData.RebatePayoutCondId == null) {
      setErrorMessage("Please select Payout Condition in the header")
      isValid = false;

    }
    // else if (ExceptionValueList.some(item => item.Operator === "" || item.Operator === null || item.Operator === "Select")) {
    //   setErrorMessage("Please Select Operator For All Rows")
    //   isValid = false;
    // } 
    if (isValid) {
      VolumeRebateList.forEach((each, index) => {
        if (each.Category === "" || each.Category === "Select" || each.Category === null) {
          setErrorMessage("Please Select Product Category in row " + (index + 1))
          isValid = false;
        }
        // else if (each.SubCategory === "" || each.SubCategory === "Select" || each.SubCategory === null) {
        //   setErrorMessage("Please Select Material Pricing Group in row" + (index + 1))
        //   isValid = false;
        // }
        else if ((each.Eligiblity === 0 || each.Eligiblity === null) && (each.EligiblityGrowthRevenue === 0 || each.EligiblityGrowthRevenue === null) && (each.EligiblityGrowthVolume === 0 || each.EligiblityGrowthVolume === null)) {
          setErrorMessage("Please Enter value for atleast one Eligibility in row " + (index + 1))
          isValid = false;
        }
        else if (each.Eligiblity === 0 || each.Eligiblity === null) {
          // debugger
          if ((each.TargetMeasure === "" || each.TargetMeasure === "Select" || each.TargetMeasure === null)) {
            setErrorMessage("Please Select Target Measure in row " + (index + 1))
            isValid = false;
          }
        }
        // else if (each.TargetMeasure === "" || each.TargetMeasure === "Select" || each.TargetMeasure === null) {
        //   setErrorMessage("Please Select Target Measure in row " + (index + 1))
        //   isValid = false;
        // }

      })
    }

    if (isValid) {
      ExceptionValueList.forEach((item, index) => {
        if (item.Category !== '' && item.Category !== null) {
          if (item.SelectedItemSubCategory === "" || item.SelectedItemSubCategory === null || item.SelectedItemSubCategory === "Select") {
            // setErrorMessage(`Please select Sub-Category for row ${index + 1}`);
            setErrorMessage("Please select Sub-Category for all rows")
            isValid = false;
          } else if (item.ValueListString.length === 0 && !['ECC', 'SubCategory', 'Category', 'Vehicletype'].includes(item.SelectedItemSubCategory)) {
            setErrorMessage("Please Select Values for All Rows");
            isValid = false;
          }
        }
      });
    }
    console.log("16-3 2", VolumeRebateList);
    return isValid
  }

  const SaveHandler = (e, action) => {

    let isValid = validateField()


    if (isValid == true) {
      let inputData = [];

      let list = VolumeRebateList;
      console.log("before: ", list);

      VolumeRebateList.forEach((each) => {
        if (each.Eligiblity === "") {
          each.Eligiblity = 0
        }
      })

      VolumeRebateList.forEach((each) => {
        if (each.VehicletypeId === "Select" || each.VehicletypeId === "") {
          each.VehicletypeId = null
        }
        if (each.SubCategory === "Select" || each.SubCategory === "") {
          each.SubCategory = null
        }
      });



      console.log("after: ", list);

      inputData = {
        Id: exceptionPolicyData.Id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        RebateTypeId: exceptionPolicyData.RebateTypeId,
        FiscalYear: selectedOptionsDateRange,
        RebateMeasureId: exceptionPolicyData.RebateMeasureId,
        RebatePayoutCondId: exceptionPolicyData.RebatePayoutCondId,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        rebatevalue: rebateIcon,
        ValueList: ExceptionValueList,
        EligiblityList: VolumeRebateList,
        StartDate: exceptionPolicyData.StartDate,
        EndDate: exceptionPolicyData.EndDate
      };

      console.log("13/2", inputData);

      Service.createorupdate('Categoryminimumachievementpolicy/CreateOrUpdate', inputData).then((res) => {
        // debugger;
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            let rowData = res.data;
            let id = parseInt(res.data);
            if (action !== "Submit") {
              toast.success('Saved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (action === 'SaveClose') {
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "CMAPolicy", id, loggedInUser).then((res) => {
              });
              navigate("/CategoryMinimumAchievement-Policy/List");
            } else {
              navigate("/CategoryMinimumAchievementPolicyList/edit/" + id + "/fromPolicy");
              getExceptionPolicyById("Categoryminimumachievementpolicy/GetById", id);
            }
          }
        }
      });
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  };

  console.log("4-9", props)

  const getExceptionPolicyById = (method, id) => {
    let apiData = []
    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          // debugger;
          if (res.status === 200) {
            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let rData = res.data[0];
              console.log("8/2", rData);
              setExceptionPolicyData({
                Id: rData.Id,
                PolicyId: rData.PolicyId,
                PolicyName: rData.PolicyName,
                StartDate: moment(rData.StartDate).toDate(), //rData.StartDate,
                EndDate: moment(rData.EndDate).toDate(), //rData.EndDate
                LastModifiedUserId: rData.LastModifiedUserId,
                CreatedUserId: rData.CreatedUserId,
                StatusLabel: rData.StatusLabel,
                CreatedUser: rData.CreatedUser,
                CreatedDate: moment(rData.CreatedDate).format(SessionManage.getcurrentdateformat()),
                FiscalYear: rData.FiscalYear,
                RebatePayoutCondId: rData.RebatePayoutCondId,
                RebateTypeId: rData.RebateTypeId,
                RebateMeasureId: rData.RebateMeasureId,
              })

              if ((rData.Locked == true && rData.LockedById.toString() !== loggedInUser)) {
                $("#libread").html(rData.PolicyId + " " + `Locked By ${rData.LockedByUserName}`);
              }
              else {
                $("#libread").html(rData.PolicyId);
              }
              // getRebateMeasureData("RebateMeasure/GetAll/", rData.RebateTypeId, 'rpl');
              Service.getdatabyId('RebateMeasure/GetAll/', rData.RebateTypeId).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                      if (res.data !== 'null' && res.data !== 'undefined') {
                        setRebateMeasureData(res.data)
                      }
                    }
                  }
                }
              })
              getRebatePayoutData("RebatePayout/GetAll", rData.RebateTypeId)
              setselectedOptionsDateRange(rData.FiscalYear);
              setSelectedOptionsPayout(rData.RebatePayoutCondId);
              setSelectedOptionsRebateType(rData.RebateTypeId);
              setSelectedOptionsRebateMeasure(rData.RebateMeasureId);
              // setRebateIcon(rData.policyTiervalueView[0].Rebate_Value)

              if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && rData.StatusLabel == 'InReview' && rData.Locked == false) || (rData.StatusLabel == 'New' && rData.Locked == false)) {
                Service.LockUnLockEntity('Configurationprop/LockEntity', "CMAPolicy", id, loggedInUser).then((res1) => {
                  if (typeof res1 !== 'undefined') {
                    setLockedBy(loggedInUser);
                    setIsLocked(true);
                  }
                });
              }
              else {
                setLockedBy(rData.LockedById);
                setIsLocked(rData.Locked);
              }

              if (props.name == 'fromPendingApproval') {
                $("#headertxt").html('Pending Category Minimum Achievement Policy List');
                setUpdateNav("/CategoryMinimumAchievementPolicyList/pending")
              }
              else if (props.name == 'fromapprovalcompleted') {
                $("#headertxt").html('Completed Category Minimum Achievement Policy List');
                setUpdateNav("/CategoryMinimumAchievementPolicyList/archive")
              }
              else {
                $("#headertxt").html('Category Minimum Achievement Policy List');
                setUpdateNav("/CategoryMinimumAchievement-Policy/List")
              }

              // rData.ValueList.map((each, index) => {
              //   each.SubCategory.map((eachsub) => {
              //     if (each.SelectedItemSubCategory == eachsub.label) {
              //       eachsub["dropdownState"] = {
              //         'selected': true,
              //         'row': index + 1
              //       }
              //     }
              //     else {
              //       eachsub["dropdownState"] = {
              //         'selected': false,
              //         'row': -1
              //       }
              //     }
              //   })
              // })

              // let values = dropDownValues


              // rData.ValueList.map((each, index) => {
              //   rData.dropDownData.map((val) => {
              //     val.SubCategory.map((sub) => {
              //       if (each.SelectedItemSubCategory == sub.label) {
              //         sub["dropdownState"] = {
              //           'selected': true,
              //           'row': index + 1
              //         }
              //       }
              //       else {
              //         sub["dropdownState"] = {
              //           'selected': false,
              //           'row': -1
              //         }
              //       }
              //     })
              //   })
              // })

              // rData.ValueList?.forEach((each, index) => {
              //   each.SubCategory?.forEach((eachsub) => {
              //     if (each.SelectedItemSubCategory == eachsub.label) {
              //       eachsub['selected'] = true
              //     }
              //     else {
              //       eachsub['selected'] = false
              //     }
              //   })
              // });

              // rData.dropDownData?.map((val) => {
              //   val.SubCategory?.map((sub) => {
              //     sub['selected'] = false
              //   })
              // })


              setExceptionValueList(rData.ValueList);
              setDropDownValues(rData.dropDownData);
              let updatedList = saveInitialSubCategories(rData.EligiblityList);
              debugger
              console.log(updatedList, 'upd-list');
              setVolumeRebateList(updatedList);
              setaddCategory(true);

              let CatData = [];
              let SubCatData = [];
              rData.dropDownData.map((i) => {
                const data = {
                  id: i.Id,
                  Id: i.Id,
                  Name: i.Category,
                  label: i.Category,
                  value: i.Category,
                }

                CatData.push(data);
                i.SubCategory.map((s) => {
                  const subdata = {
                    id: s.Id,
                    Id: s.value,
                    Name: s.label,
                    label: s.label,
                    value: s.value,
                  }
                  SubCatData.push(subdata);
                })

              });

              setCategoryValues(CatData);
              setSubCategoryValues(SubCatData);

              setUpdatedCategoryValues(list => ([...CatData]));
              catValues = CatData;

              setModifiedLst(rData.dropDownData)


              rData.ValueList.map((i) => {

                let row = 0;

                let item = {
                  Id: i.Id,
                  DataId: i.DataId,
                  Operator: i.Operator,
                  ValueListString: i.ValueListString
                };

                setExceptionValues((prevData) => {
                  // Remove the default value before adding items from the list
                  const newData = prevData.filter((item) => item.id !== 0);
                  return [...newData, item];
                });
              });

              // setsubdivideList(rData.subdivideMinThresholdValue);
              // if (rData.subdivideMinThresholdValue != null) {
              //   if (rData.subdivideMinThresholdValue.Period != null) {
              //     setSubdividefield(true)
              //     setShowTargetTable(true)
              //     setShowTargetSelection(rData.SubDivideTargetType)
              //     if (rData.ExceptionValueList.length > 0) {
              //       setaddCategory(true);
              //     } else {
              //       setShowAddButton(true)
              //     }
              //   }
              // }
              // setfyThresholdList(rData.fYThresholdValue)
              // if (rData.fYThresholdValue != null) {
              //   setFYThresholdfield(true)
              //   if (rData.ExceptionValueList.length > 0) {
              //     setaddCategory(true);
              //   } else {
              //     setShowAddButton(true)
              //   }
              // }
              // if (rData.ExceptionTypeName == "FYThreshold" || rData.ExceptionTypeName == "SubDivideThreshold") {

              //   UpdateCategoryValues();


              // }


              if (rData.StatusLabel == 'New') {
                setrebatelinestatus('New')
              }
              else {
                setrebatelinestatus('')
                setShowAddButton(false)
              }
              if (rData.StatusLabel == 'New') {
                insertWorkflowDetails(id, loggedInUser)
              }
              setTimeout(() => {
                getExceptionWorkflowDetails(id, paging.pageSize, paging.pageNumber)
                getExceptionWorkflowApprovers('Categoryminimumachievementpolicy/GetCMAWorkflowApprovers', id);
              }, 500);
            }
          }
        }
      }
    });
  }

  const updatedropdownValues = () => {

    ExceptionValueList.map((each, index) => {
      dropDownValues.map((val) => {
        each.SubCategory.map((sub) => {
          if (each.SelectedItemSubCategory == sub.label) {

            sub.dropdownState.selected = true
            sub.dropdownState.row = index + 1
          }
        })
      })
    })
  }

  const GetDateRangeAndProducts = (method) => {
    Service.getdata(method).then((res) => {
      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          console.log(res.data.lstDateRange, "DateRange");
          setDateRange(res.data.lstDateRange);
        }
      }
    });
  };

  const insertWorkflowDetails = (id, userId) => {
    Service.workflowDataExcep('Categoryminimumachievementpolicy/InsertWorkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

            }
          }
        }
      }
    })
  }



  const attributeSearch = (event) => {

    let names = [
      { key: "Category1", data: attribute1ValuesHold, setter: setAttribute1Values },
      { key: "Category2", data: attribute2ValuesHold, setter: setAttribute2Values },
      { key: "Category3", data: attribute3ValuesHold, setter: setAttribute3Values },
      { key: "Category4", data: attribute4ValuesHold, setter: setAttribute4Values },
      { key: "Category5", data: attribute5ValuesHold, setter: setAttribute5Values }
    ]
    let list = []

    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data
        if (event.target.value != '') {
          let searchResults = list.filter((each) => {

            return each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
          });
          each.setter(searchResults)
        }
        else {
          each.setter(each.data)
        }
      }
    })
  }

  const checkAllAttribute = (mycheckbox) => {
    let names = [
      { key: "Cat-I", data: attribute1Values, setter: setAttribute1Values, cat: 'lstcat1' },
      { key: "Cat-II", data: attribute2Values, setter: setAttribute2Values, cat: 'lstcat2' },
      { key: "Cat-III", data: attribute3Values, setter: setAttribute3Values, cat: 'lstcat3' },
      { key: "Cat-IV", data: attribute4Values, setter: setAttribute4Values, cat: 'lstcat4' },
      { key: "Cat-V", data: attribute5Values, setter: setAttribute5Values, cat: 'lstcat5' },
    ]
    let list = []
    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data

        var checkboxes = document.querySelectorAll("input[name = 'attribute-check']");
        if (mycheckbox.target.checked == true) {
          setattbCheckAll(true);

        }
        else {
          setattbCheckAll(false);

        }
      }
    })
  }


  const OnChangeHandlerAttribute = (e, label, id, flag) => {
    let names = [
      { key: "Category", data: AllCategoryValues, setter: setAllCategoryValues, cat: 'lstcat' },
      { key: "SubCategory", data: AllSubCategoryValues, setter: setAllSubcategoryValues, cat: 'lstsubcat' },
    ]

    let temp = []
    let ids = [];
    names.forEach((each) => {  //iterating names array
      if (each.key == flag) {
        each.data.forEach((cat) => {  // iterating attribute data
          if (cat.value === id) {
            e.target.checked ? cat.selected = true : cat.selected = false

          }
        })
        temp = each //storing selected obj in var
      }
    })
    switch (flag) {
      case "Category":
        setAllCategoryValues(temp.data)
        ids = formatcat(temp.data)
        break;

      case "SubCategory":
        setAllSubcategoryValues(temp.data)
        ids = formatcat(temp.data)
        break;

    }
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
    console.log("6/2i", ids)
    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }

  const formatcat = (data) => {
    let temp = []
    data.forEach((i) => {
      if (i.selected == true) {
        temp.push(i.value.toString())
      }
    })

    return temp
  }

  const formatAttributes = (data) => {
    let temp = []
    data.forEach((i) => {
      if (i.Selected == true) {
        temp.push(i.Id.toString())
      }
    })

    return temp
  }


  const okSelectedCustomer = () => {


  }
  const okCustomer = () => {
    setshowCustomerPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);


  }



  const FilterColumnCustomer = (name) => {
    var divtop1 = document.getElementById("Customer");
    var cardtop1 = document.getElementById("Customer");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerFilterCol(name)
    setshowCustomerfilterpopup(true)

    if (name === 'Name') {
      setfiltertextCust("Customer Code")
      var testDiv = document.getElementById("Name");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'Label') {
      setfiltertextCust("Customer Name")
      var testDiv = document.getElementById("Label");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }

    if (name === 'Station') {
      setfiltertextCust("Station")
      var testDiv = document.getElementById("Station");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'State') {
      setfiltertextCust("State")
      var testDiv = document.getElementById("State");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'Zone') {
      setfiltertextCust("Zone")
      var testDiv = document.getElementById("Zone");
      setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
    }
    DropdownHandlerCus(name);
  }
  const DropdownHandlerCus = (key) => {

    Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {

            addnewfieldCustomer(res.data, key)
          }
        }
      }

    });

  }
  const addnewfieldCustomer = (data, key) => {

    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueCustomer[key];

    if (selectedKeys != '') {

      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCusDropDownData(currentdata);
    setfilterCustData(currentdata)
  }


  const OnCusdropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...filterCusDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Name == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCusDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });

      setFilterCusDropDownData(currentdata);
      setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
      let data1 = filterValueCust.filter(data => data.Name !== name);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: data1,
      }));
    }
  }

  const UserfilterHandlerCustomer = (e) => {

    setFilterSearchCust(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCusDropDownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCusDropDownData(searchResults);
    }
    else {

      setFilterSearchCust("")
      setFilterCusDropDownData(filtercustData)
    }
  }

  const checkAllFilterCustomer = (mycheckbox) => {
    var lastfilter = [...filterCusDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });

      setFilterCusDropDownData(currentdata)
      filterCusDropDownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValueCust([])
      setFilterValueCustomer([])

    }

  }

  const CustFilterHandler = (flag) => {
    let list = [];

    var selectedCus = data;
    for (var i = 0; i < CustomerValues.length; i++) {
      if (CustomerValues[i].Selected == true) {
        for (var k = 0; k < selectedCus.length; k++) {
          if (CustomerValues[i].Id === selectedCus[k].Id) {
            selectedCus[k].Selected = true;
          }
        }
      }
    }
    setData(selectedCus);
    setshowCustomerfilterpopup(false)
    if (flag === 'Apply') {

      for (var key in filterValueCustomer) {
        if (filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });

    }
    else {
      setshowCustomerfilterpopup(false)
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: '',
      }));

      for (var key in filterValueCustomer) {
        if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });
      setFilterValueCust([])
      setfilterCustData([])
      setFilterCusDropDownData([])

      setFilterSearchCust('')
    }

  }
  const SearchCustomer = (event) => {
    var list = []
    let lstSelected = CustomerValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })
    setCustomerSearch(event.target.value)
    if (customerDataFilter.length === 0) {
      list = CustomerValues;

      setCustomerDataFilter(list);
    }
    else {
      list = customerDataFilter
    }
    if (event.target.value != '') {
      let searchResults = list.filter((each) => {

        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });

      setCustomerValues(uniquelst)


    }
    else {
      setCustomerValues(list)
    }
  }

  const checkAllCustomer = (mycheckbox) => {

    let Ids = [];
    var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
    if (mycheckbox.target.checked == true) {
      setCustomercheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = true;
        Ids.push(i.Id);
      })


    }
    else {
      setCustomercheckAll(false);
      settotalselectedshow(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = false;

      })

    }

  }

  const OnChangeHandlerRebateType = (data) => {
    setChangeHandle(true)
    setRebateTypeValue(data.Name);
    setSelectedOptionsRebateType(data.Id)
    exceptionPolicyData.RebateTypeId = data.Id
    setSelectedOptionsPayout('')
    getRebateMeasureData("RebateMeasure/GetAll/", data.Id, 'RebateType');
    getRebatePayoutData("RebatePayout/GetAll", data.Id)
  }

  const OnChangeHandlerRebateMeasure = (data) => {
    setChangeHandle(true)
    setSelectedOptionsRebateMeasure(data.Id)
    exceptionPolicyData.RebateMeasureId = data.Id
    setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateMeasureId']: data.Id })

    if (data.Name === 'Quantity' || data.Name === 'Growth in Qty (Abs)') {
      setmeasureValueLabel('Unit')
    }
    else if (data.Name === 'Revenue' || data.Name === 'Growth in Rev (Abs)') {
      setmeasureValueLabel('Amount')
    }
    else if (data.Name === 'Growth in Qty (%)' || data.Name === 'Growth in Rev (%)') {
      setmeasureValueLabel('%')
    }
    else {
      setmeasureValueLabel('')
    }
  }




  const OnChangeHandlerPayout = (data) => {
    // debugger;
    setSelectedOptionsPayout(data.Id)
    exceptionPolicyData.RebatePayoutCondId = data.Id
    setChangeHandle(true)
    setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['Rebate_Payout_Cond_Id']: data.Id })
    setRebatePayoutCond(data.Name)
    if (data.Name === 'Stepped' || 'Targeted') {
      setTargetPayCond(false)
      setVolumeRebateList([{
        Category: "",
        SubCategory: "",
        VehicleType: "",
        Eligiblity: 0,
        EligiblityGrowthVolume: 0,
        EligiblityGrowthRevenue: 0,
        TargetMeasure: null,
        VehicletypeId: "",
      }])
      setRebateIcon("")
    }
    if (data.label === 'Lumpsum') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        TargetMeasure: "",
        Rebate: "",
        Rebate_Value: "LS",
        Eligiblity: 0,
        Category: "",
        SubCategory: "",
        VehicletypeId: "",
        EligiblityGrowthVolume: 0,
        EligiblityGrowthRevenue: 0,
      }])
      setRebateIcon("LS")
    }
    if (data.label === 'Unit') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        TargetMeasure: "",
        Rebate: "",
        Rebate_Value: "₹",
        Eligiblity: 0,
        Category: "",
        SubCategory: "",
        VehicletypeId: "",
        EligiblityGrowthVolume: 0,
        EligiblityGrowthRevenue: 0,
      }])
      setRebateIcon("₹")
    }
    if (data.label === 'Percent') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Target_Measure: "",
        Rebate: "",
        Rebate_Value: "%",
        Eligiblity: 0,
        Category: "",
        SubCategory: "",
        VehicletypeId: "",
        EligiblityGrowthVolume: 0,
        EligiblityGrowthRevenue: 0,
      }])
      setRebateIcon("%")
    }
    if (data.label === 'Select') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Target_Measure: "",
        Rebate: "",
        Rebate_Value: "",
        Eligiblity: 0,
        Category: "",
        SubCategory: "",
        VehicletypeId: "",
        EligiblityGrowthVolume: 0,
        EligiblityGrowthRevenue: 0,
      }])
      setRebateIcon("")
    }
  }

  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [vehicleTypeData, setVehicleTypeData] = useState([]);

  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedSubCat, setselectedSubCat] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [givenEligibiligty, setGivenEligibility] = useState(null);

  const [targetMeasure, setTargetMeasure] = useState([]);


  const loadTargetMeasure = (event) => {
    Service.getAllDropdownData("SparesTargetPolicy/GetTargetMeasure")
      .then((data) => {
        console.log(data, "dataT");
        setTargetMeasure(data);
      })
      .catch((error) => {
        console.log("Error fetching Target Measure data:", error);
      })
  }

  const handleTargetMeasureChange = (event, index) => {
    const updatedList = [...VolumeRebateList]; // Create a copy of the original list
    updatedList[index].TargetMeasure = event.target.value
    setVolumeRebateList(updatedList); // Update the state with the modified list
  };



  const loadSubCategory = async (categoryId) => {
    try {
      const res = await Service.getdatabyId('Categoryminimumachievementpolicy/GetSubCategories', categoryId);
      console.log("service file", res);
      return res.data;
    } catch (error) {
      console.error('Error fetching category data:', error);
      throw error;
    }
  };



  const saveInitialSubCategories = (volumeList) => {
    volumeList.forEach((obj) => {
      loadSubCategory(obj.Category, 'update-list')
        .then((data) => {
          obj['Initial_SubCategories'] = data;
        })
        .catch((error) => {
          console.error('Error fetching subcategory data:', error);
        });
    });

    return volumeList;
  };



  const loadCategoryData = () => {
    Service.getAllDropdownData("Categoryminimumachievementpolicy/GetCategories")
      .then((data) => {
        console.log('cat-data', data);
        setCategoryData(data);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching category data:", error);
      });
  }


  const handleCategoryChange = async (event, index) => {
    let categoryId = event.target.value.split('-')[0];
    let list = [...VolumeRebateList];
    list[index].Category = event.target.value;
    // {
    //   id: event.target.value,
    // };

    try {
      const data = await loadSubCategory(categoryId);
      list[index]['Initial_SubCategories'] = data;
      console.log(data, '@@');
      setSubCategoryData(data); // Update subCategoryData state here
    } catch (error) {
      console.error('Error fetching subcategory data:', error);
    }

    console.log(list, 'i-lst');
    setVolumeRebateList(list);
  };



  const handleSubCategoryChange = (event, index) => {
    // Service.getAllDropdownData("Categoryminimumachievementpolicy/GetSubCategories")
    //   .then((data) => setSubCategoryData(data))
    //   .catch((error) => {
    //     // Handle error
    //     console.error("Error fetching category data:", error);
    //   });
    let subcategoryId = event.target.value;
    // setselectedSubCat(subcategoryId);

    let list = VolumeRebateList

    list[index].SubCategory = event.target.value

    // Name: event.target.value
    // }
  }


  const loadVehicle = (event) => {
    // let vehicleTypeId = event.target.value;
    // setSelectedVehicle(vehicleTypeId);


    Service.getAllDropdownData("Categoryminimumachievementpolicy/GetVehicleType")
      .then((data) => {
        console.log(data, "vd");
        setVehicleTypeData(data);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching category data:", error);
      });



  };



  const onVehicleChange = (event, index) => {

        
    const value = event.target.value

    // if (selecteProductValuetable[index]) {
    //   if (selecteProductValuetable[index].length !== 0) {
    //     setProductIndex(index)
    //     setshowVehicleTypeValidation(true)
    //     return
    //   }
    // }
    if (VolumeRebateList[index].VehicletypeId !== ""  && VolumeRebateList[index].Productvalue.length !==0)
      {
        setVehicleTypeChange(value)
        setProductIndex(index)
        setshowVehicleTypeValidation(true)
      }
 



    // const vehicleTypeId = parseInt(event.target.value, 10);

    // // Find the name associated with the vehicle type ID in vehicleTypeData
    // const vehicleTypeName = vehicleTypeData.find(item => item.Id === vehicleTypeId)?.Name;

    // console.log(vehicleTypeName, "Vehicle Name")
    // // Filter productValueTable based on the vehicle type name
    // const filteredProductValues = ProductValues.filter(
    //   item => item.Vehicletype === vehicleTypeName
    // );
    // console.log(filteredProductValues, "Filter Product")
    // // Update the specific index in productValueTable with filteredProductValues
    // setProductValueTable(prevTable => {
    //   const newTable = [...prevTable];
    //   newTable[index] = filteredProductValues;
    //   return newTable;
    // });
    
    const updatedList = [...VolumeRebateList];
    updatedList[index] = {
      ...updatedList[index],
      VehicletypeId:value
    };
    setVolumeRebateList(updatedList);
    // Update the VolumeRebateList with the modified list

  }
  const onVehicleTMChange = (event, index) => {
    let list = VolumeRebateList;
    list[index].Vehicletypetm = event.target.value

    // list[index].VehicleTypeTM = {
    //   id: event.target.value,
    //   // Name: event.target.value.split('-')[1]
    // }
    setVolumeRebateList(list);
  }


  const onEffectedTodChange = (event, index) => {
    let list = VolumeRebateList;
    list[index].Effectedtod = event.target.value

    // list[index].EffectedTod = {
    //   id: event.target.value,
    //   // Name: event.target.value.split('-')[1]
    // }
    setVolumeRebateList(list)
  }

  const onEffectedSegmentChange = (event, index) => {
    let list = VolumeRebateList;
    list[index].Effectedsegments = event.target.value

    // list[index].EffectedSegments = {
    //   id: event.target.value,
    //   // Name: event.target.value.split('-')[1]
    // }
    setVolumeRebateList(list)
  }

  const onChangeAddRedIncentive = (event, index) => {
    let list = VolumeRebateList;
    list[index].Addredincentive = event.target.value

    // list[index].AddRedIncentive = {
    //   id: event.target.value,
    //   // Name: event.target.value.split('-')[1]
    // }
    setVolumeRebateList(list)
  }
  const onChangeIncentivePercent = (event, index) => {
    const value = parseFloat(event.target.value) || 0; // Parse the value as a number

    setVolumeRebateList((prevList) =>
      prevList.map((item, i) =>
        i === index
          ? { ...item, Incenentivevaluepercent: value } // Update with numeric value
          : item
      )
    );
  };

  const onChangeIncentiveValue = (event, index) => {
    const updatedList = [...VolumeRebateList];
    updatedList[index] = {
      ...updatedList[index],
      Incenentivevalue: event.target.value,
    };
    setVolumeRebateList(updatedList);
  };

  const onChangeIncentiveLumpSum = (event, index) => {
    const value = parseFloat(event.target.value) || 0; // Parse the value as a number

    setVolumeRebateList((prevList) =>
      prevList.map((item, i) =>
        i === index
          ? {
            ...item,
            Lumpsumvalue: value,
            IncenentiveValue: value !== 0 ? incenticevalue[1] : "" // Set IncentiveValue conditionally
          }
          : item
      )
    );
  };





  const eligibilityOnChange = (event, index, name) => {
    let currentVolumeRebates = VolumeRebateList;

    switch (name) {
      case "Eligiblity":
        currentVolumeRebates[index].Eligiblity = event.target.value;
        break;
      case "EligiblityGrowthRevenue":
        currentVolumeRebates[index].EligiblityGrowthRevenue = event.target.value;
        break;
      case "EligiblityGrowthVolume":
        currentVolumeRebates[index].EligiblityGrowthVolume = event.target.value;
        break;
      default:
        break;
    }

    setVolumeRebateList(currentVolumeRebates, 'new-lst');
  }





  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setChangeHandle(true);
    console.log("23-1", name, index);


    let list = [...VolumeRebateList];

    if (name === 'Rebate_Value') {

      if (value === '%') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Invoice Price';
      } else if (value === 'Select') {
        setRebateIcon('');
        list[index]['Target_Measure'] = '';
      } else if (value === '$' || value === '₹') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Quantity';
      } else if (value === 'LS') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Lumpsum';
      }

      list[index][name] = value;


      if (VolumeRebateList.length > 1) {
        let currentRebateValue = VolumeRebateList[0].Rebate_Value;
        let currentTargetMeasure = VolumeRebateList[0].Target_Measure;

        VolumeRebateList.forEach((item) => {
          if (item['Rebate_Value'] !== currentRebateValue) {
            item['Rebate_Value'] = currentRebateValue;
          }
          if (item['Target_Measure'] !== currentTargetMeasure) {
            item['Target_Measure'] = currentTargetMeasure;
          }
        });
      }
    }

    else if (name === 'Targetcondition') {

      for (let i = 0; i <= list.length - 1; i++) {
        list[i].Targetcondition = e.target.value;
      }
    }
    else {

      list[index][name] = value;
    }


    setVolumeRebateList(list);
  };


  const getRebatePayoutData = (method, Id) => {
    Service.getdatabyId(method, Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setRebatePayoutCondData(res.data)
              setRebatePayoutCond("Targeted")
            }
          }
        }
      }

    });
  }

  const getRebateMeasureData = (method, Id, flag) => {
    Service.getdatabyId(method, Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              console.log("dataaa", res.data);
              setRebateMeasureData(res.data)
              if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Revenue') {
                setSelectedOptionsRebateMeasure(res.data[0].Id)

                //Set-policy function commented
                setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: res.data[0].Id, ['RebateTypeId']: Id })

              }
              if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Growth in Qty (Abs)') {
                setmeasureValueLabel('Unit')
              }
              else if (res.data[0].Name === 'Revenue' || res.data[0].Name === 'Growth in Rev (Abs)') {
                setmeasureValueLabel('Amount')

              }
              // else if (res.data[0].Name === 'Growth in Qty (%)' || res.data[0].Name === 'Growth in Rev (%)') {
              //   setmeasureValueLabel('%')
              // }
              if (flag == 'RebateType') {
                // res.data.map((i) => {
                //   if (i.Name === 'Growth in Qty (%)' || i.Name === 'Growth in Qty (Abs)') {
                //     if (i.Name === 'Growth in Qty (%)') {
                //       setSelectedOptionsRebateMeasure(i.Id)
                //       setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: i.Id })
                //       setmeasureValueLabel("%")
                //     }
                //   }
                //   if (i.Name === 'Growth in Rev (%)' || i.Name === 'Growth in Rev (Abs)') {
                //     if (i.Name === 'Growth in Rev (%)') {
                //       setSelectedOptionsRebateMeasure(i.Id)
                //       setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: i.Id })
                //       setmeasureValueLabel("%")
                //     }
                //   }
                // })
              }
            }
          }
        }
      }
    });
  }



  const getRebateTypeData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setRebateTypeData(res.data)
              let revenueId = res.data.find((x) => x.Name === 'Revenue')?.Id
              setSelectedOptionsRebateType(revenueId);
              if (props.id === 0 || props.id === '0') {
                getRebateMeasureData("RebateMeasure/GetAll/", revenueId, 'RebateType');
                getRebatePayoutData("RebatePayout/GetAll", revenueId)
              }
            }
          }
        }
      }

    });
  }


  const handleCustSelection = (e, label, id, State) => {


    let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");
    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];

    if (checkboxes.length >= 1) {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(data)
      setCustomerData(olddata)

      setCustomerValues(olddata);

    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(data)

      setCustomerValues(olddata);
    }
    setCustomerChanged(true)
  }




  const handleCustomerChanges = (e, label, id, State) => {

    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(customerData)


      setCustomerValues(customerData);

    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(customerData)


      setCustomerValues(customerData);
    }

  }

  const SearchGroupCustomer = (event) => {
    var list = []
    let lstSelected = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })

    if (event.target.value != '') {
      let searchResults = list.filter((each) => {

        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())


      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });


    }
    else {

    }
  }


  const checkAllGroupCustomer = (mycheckbox) => {


  }

  const OnChangeDateRange = (e) => {
    // Extract label and value from selected fiscal year range

    VolumeRebateList.map(volume =>{
      volume.Productvalue = []
      volume.SegmentId = ""
    })

    getSegmentByFiscalYear(e.label)
    setselectedOptionsDateRange(e.label);
    let dateArray = e.value.split("~");
    let startDate = new Date(dateArray[0]);
    let endDate = new Date(dateArray[1]);
    const fyStartDate = startDate; // April 1st
    const fyEndDate = endDate; // March 31st of the next year
    // Set the state for the StartDate and EndDate fields with the selected fiscal year range
    setExceptionPolicyData({ ...exceptionPolicyData, ['StartDate']: fyStartDate, ['EndDate']: fyEndDate });
    // Set minimum and maximum selectable dates in the date picker
    setminDate(fyStartDate); // Set minimum date as April 1st of the current fiscal year
    setmaxDate(fyEndDate);   // Set maximum date as March 31st of the next fiscal year
    // Ensure fiscal year label is set in the state
    state.FiscalYear = e.label;
    // If endDate is a string, handle parsing (for safety, you might not need this part)
    if (typeof endDate === 'string') {
      const [day, month, year] = endDate.split('/');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      setmaxDate(parsedDate ? new Date(parsedDate) : new Date());
    } else {
      console.error('endDate is not a string:', endDate);
    }
    state.FiscalYear = e.label;
  };

  console.log("25/1", exceptionPolicyData);
  const FilterColumnGroupCustomer = (name) => {
    var divtop1 = document.getElementById("CustomerGroup");
    var cardtop1 = document.getElementById("CustomerGroup");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerGroupFilterCol(name)
    setshowCustomerGroupfilterpopup(true)

    if (name === 'label') {
      setfiltertextCustGroup("Customer Group Label")
      var testDiv = document.getElementById("GroupLabel");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    DropdownHandlerCustGroup(name);
  }

  const DropdownHandlerCustGroup = (key) => {

    setcurrentCustGroupFilterKey(key)
    Service.getfilterDropdownByGroup('CustomerGroups/GetFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {

            addnewfieldCustomerGroup(res.data, key)
          }
        }
      }

    });

  }

  const addnewfieldCustomerGroup = (data, key) => {

    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueGroupCustomer[key];

    if (selectedKeys != '') {

      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Value == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCustGroupDropDownData(currentdata);
    setfilterCustGroupData(currentdata)
  }




  const OnChangeHandlerGroupCustomer = (e, label, id, State) => {
    let Selected = [];
    let update = [];
    const getallData = [];
    let olddata = [...CustomerGroupValues];

    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      getallData.push(id);
      setCustomerGroupValues(olddata);
    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      setCustomerGroupValues(olddata);
    }
  }

  const okCustomerGroup = () => {
    setshowCustomerGroupPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerGroupValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);


  }
  const UserfilterHandlerCustomerGroup = (e) => {

    setFilterSearchCustGroup(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCustGroupDropDownData.filter((each) => {
        return each.Value.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCustGroupDropDownData(searchResults);
    }
    else {
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData(filtercustGroupData)
    }
  }
  const checkAllFilterCustomerGroup = (mycheckbox) => {
    var lastfilter = [...filterCustGroupDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });

      setFilterCustGroupDropDownData(currentdata)
      filterCustGroupDropDownData.map((each) => {
        const getallData = {
          Name: each.label,
        }
        data1.push(getallData)
      })
      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValuegroupCustomer([])
      setFilterValueCustGroup([])

    }

  }
  const OnCustGroupdropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...filterCustGroupDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.label == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCustGroupDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)

      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });

      setFilterCustGroupDropDownData(currentdata);
      setFilterValueCustGroup(filterValueCustGroup => filterValueCustGroup.filter(data => data.label !== name))
      let data1 = filterValueCustGroup.filter(data => data.label !== name);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: data1,
      }));
    }
  }
  const filterHandlerCustomerGroup = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowCustomerGroupfilterpopup(false)
      let list = [];

      for (var key in filterValueGroupCustomer) {
        if (filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }



      if (list.length > 0) {
        setpaging(paging);
        GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
      }


    }
    else {
      setshowCustomerGroupfilterpopup(false)

      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: '',
      }));


      let list = [];
      for (var key in filterValueGroupCustomer) {
        if (key !== custGroupFilterCol && filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }


      setpaging(paging);

      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)

      setCustomerGroupFilterCol('')
      setFilterValueCustGroup([])
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData([])
      setfilterCustGroupData([])
    }
  }



  const checkAllSettlFreqChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setsettlFreqcheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });


      settlFreqValues.map((i) => {
        i.Selected = true

      })


    }
    else {
      setsettlFreqcheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settlFreqValues.map((i) => {
        i.Selected = false;
      })


    }
  }

  const checkAllRebateTypeChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setRebateTypecheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });


      rebateTypValues.map((i) => {
        i.Selected = true

      })


    }
    else {
      setRebateTypecheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      rebateTypValues.map((i) => {
        i.Selected = false;
      })


    }
  }

  const handleSettlFreqChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...settlFreqValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })


      setSettlFreqValues(olddata)


    }
    else {


      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })



      setSettlFreqValues(olddata)


    }

  }
  const handleRebateTypeChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...rebateTypValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })

      setRebateTypeValues(olddata)


    }
    else {


      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })



      setRebateTypeValues(olddata)


    }

  }

  const oksettlFreq = () => {
    setshowSetlFreqPopup(false);
    let ids = [];
    ids = formatAttributes(settlFreqValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }
  const okRebateType = () => {
    setshowRebateTypePopup(false);
    let ids = [];
    ids = formatAttributes(rebateTypValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }


  const clickShowSelectedSettlFreq = (val) => {

    let lstcopy = settlFreqValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setSettlFreqDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }
  const clickShowSelectedRebateType = (val) => {

    let lstcopy = rebateTypValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setRebateTypeDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }

  const clickSelectedShowCustomerPopup = (val) => {

    let lstcopy = CustomerValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerDataSelected([...lstcopy])
  }

  const okSelectedCustomerGroup = () => {
    setShowSelectedCustomerGrouppopup(false);
  }

  const clickSelectedShowCustomerGroupPopup = (val) => {

    let lstcopy = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerGroupDataSelected([...lstcopy])
  }

  const CloseHandler = (e) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "CMAPolicy", exceptionPolicyData.Id, loggedInUser).then((res) => {

    });
    if (loadedFrom === 'fromPendingApproval') {
      navigate("/CategoryMinimumAchievementPolicyList/pending");
    }
    else if (loadedFrom === 'fromCompletedWorkflow') {
      navigate("/CategoryMinimumAchievementPolicyList/archive");
    }
    else if (loadedFrom == "fromPolicy") {
      navigate("/CategoryMinimumAchievement-Policy/List")
    }

  }
  const OnFYThresholdOperatorChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['Operator']: value })
  }
  const OnFYThresholdValueChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['ThresholdValue']: value })
  }

  const OnFYThresholdPayoutChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['PayoutValue']: value })
  }




  const workflowAlert = (e, flag) => {
    let isValid = validateField()
    if (isValid) {

      if (flag == 'open') {
        if (workflowApprovers.length > 0) {
          setShowWorkflowAlert(true)
        }
        else {
          showSubmitDialog(e, 'showSubmitDialog')
        }
      }
      else if (flag == 'close') {
        setShowWorkflowAlert(false)
        showSubmitDialog(e, 'showSubmitDialog')
      }
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }



  const showSubmitDialog = (e, flag) => {

    if (flag === 'showSubmitDialog') {
      setShowSubmitModal(!showSubmitModal)

    }
    else if (flag === 'proceed') {
      setShowSubmitModal(false)
      SubmitHandler()

    }
  }
  const SubmitHandler = (e) => {

    let isValid = validateField()

    if (isValid == true) {

      let inputData = [];

      inputData = {
        Id: exceptionPolicyData.Id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        RebateTypeId: exceptionPolicyData.RebateTypeId,
        FiscalYear: selectedOptionsDateRange,
        RebateMeasureId: exceptionPolicyData.RebateMeasureId,
        RebatePayoutCondId: exceptionPolicyData.RebatePayoutCondId,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        Rebate_Value: rebateIcon,
        ValueList: ExceptionValueList,
        EligiblityList: VolumeRebateList,
        StartDate: exceptionPolicyData.StartDate,
        EndDate: exceptionPolicyData.EndDate
      }

      VolumeRebateList.forEach((each) => {
        if (each.VehicletypeId === "Select" || each.VehicletypeId === "") {
          each.VehicletypeId = null
        }
        if (each.SubCategory === "Select" || each.SubCategory === "") {
          each.SubCategory = null
        }
      });
      // }
      setLoading(true);
      Service.SubmitExceptionPolicy("Categoryminimumachievementpolicy/SubmitCMA", props.id, loggedInUser, commentsForSubmit, inputData).then((res) => {
        //debugger
        if (typeof res !== 'undefined') {

          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let rData = res.data;
            if (res.data.Code == 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {
                if (res.data.Status != 'Failure') {

                  let id = parseInt(res.data.Id)
                  setCommentsForSubmit('')
                  setShowSubmitModal(!showSubmitModal)
                  toast.success('Submitted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                    // onClose: () => navigate("/CategoryMinimumAchievementPolicyList")
                  });
                  setTimeout(() => {
                    CloseHandler('close');
                  }, 200)
                  console.log(res.data.Id, "IDC");
                  getExceptionPolicyById("Categoryminimumachievementpolicy/GetById", res.data.Id)
                }
                else {
                  toast.success('Error in submission !', {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false)
                  setErrorMessage(res.data.ErrorMessage);
                  setErrorStatus("Error")
                  setShowErrorModal(true)
                  setShowSubmitModal(!showSubmitModal)
                  getExceptionPolicyById("Categoryminimumachievementpolicy/GetById", res.data.Id)
                }
              }
            }
          }
        }
      });
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }

  const ApproveException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('Categoryminimumachievementpolicy/ApproveCMA', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "CMAPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Approved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/CategoryMinimumAchievementPolicyList/pending")
              });

            }

          }
          else if (res.status !== 200) {
            toast.success('Error in approval !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }

  const DenyException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('Categoryminimumachievementpolicy/DenyCMA', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "CMAPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Denied Successfully', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/CategoryMinimumAchievementPolicyList/pending")
              });

            }

          }
          else if (res.status !== 200) {
            toast.success('Error in deny !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }
  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveException()
      setShowApprovedModal(!showApprovedModal)

    }
  }


  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      DenyException()
      setShowDeniedModal(!showDeniedModal)

    }
  }
  const OnConditionChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[index]['Condition'] = data;
    list2[index]['Condition'] = data;
    setExceptionValueList(list);
    setExceptionValues(list2);

  }

  const UpdateCategoryValues = () => {
    const updatedList = catValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])

  }
  const UpdateCategoryValuesFromType = () => {
    const updatedList = CategoryValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])

  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  // if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
  //   if (exceptionPolicyData.StatusLabel == null || exceptionPolicyData.StatusLabel == "New") {
  //     disableField = false;
  //   }
  //   else if (exceptionPolicyData.StatusLabel != "New" || exceptionPolicyData.StatusLabel == "Approved" || exceptionPolicyData.StatusLabel == "InReview") {
  //     disableField = true;
  //   }

  // }
  // else {
  //   disableField = true;
  // }

  if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
    if ((exceptionPolicyData.StatusLabel == null || exceptionPolicyData.StatusLabel == "New") && (Service.editPerms(userPerms) != false)) {
      disableField = false;
    }
    else if (exceptionPolicyData.StatusLabel != "New" || exceptionPolicyData.StatusLabel == "Approved" || exceptionPolicyData.StatusLabel == "InReview") {
      disableField = true;
    }
    else {
      disableField = true;
    }
  }
  else {
    disableField = true;
  }


  const [inputsDisabled, setInputsDisabled] = useState({
    Eligiblity: false,
    EligiblityGrowthRevenue: false,
    EligiblityGrowthVolume: false
  });
  const [dropdownDisabled, setDropdownDisabled] = useState(false);


  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm A";

  //---------------------------------------------------------------------
  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
            {/* <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Menu.Policies')}</Link></li> */}
            <li className="breadcrumb-item active" aria-current="page"><Link to={props.name === "fromPendingApproval" ? "/CategoryMinimumAchievementPolicyList/pending" : props.name === "fromCompletedWorkflow" ? "/CategoryMinimumAchievementPolicyList/archive" : "/CategoryMinimumAchievement-Policy/List"}>{props.name === "fromPendingApproval" ? "Pending Category Minimum Achievement Policy List" : props.name === "fromCompletedWorkflow" ? "Completed Category Minimum Achievement Policy List" : "Catergory Minimum Achievement Policy List"}</Link></li>
            <li className="breadcrumb-item active" id="libread" aria-current="page">{props.name === "fromPolicy" ? t('CategoryMinimumAchievementPolicy.CreateNewCategoryPolicy') : exceptionPolicyData.PolicyId}</li>
          </ol>
        </nav>
        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
          onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        {loading ? <><div className="loader-container"><div className="spinner"></div></div> </> : ''}
        <ToastContainer autoClose={1000} />
        <div className="row">
          <div className="col-lg-12">
            <div className="card text-center card_border btn_res">
              <div className="card-header chart-grid__header align-items-center mt-1" style={{ height: "35px" }}>
                <div className="row">
                  <div className="col-md-6 text-left">
                    {t('CategoryMinimumAchievementPolicy.CategoryPolicyHeader')}
                  </div>

                  <div className="col-md-6 btn_margin">

                    {Service.editPerms(userPerms) == false ? "" : disableField === false ?
                      <>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'Save')} >{t('Save')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'SaveClose')}>{t('Save & Close')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "55px", float: "right" }} type="submit" onClick={(event) => workflowAlert(event, 'open')}>{t('Submit')}</button>
                      </>
                      : ''}
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={CloseHandler}>{t('Cancel')}</button>
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "65px", float: "right" }} type="submit" onClick={() => { clickShowWorkflow("Workflow", exceptionPolicyData.Id) }}>{t('Workflow')}</button>

                    {Service.editPerms(userPerms) == false ? "" :
                      (loadedFrom == 'fromApproval' && exceptionPolicyData.StatusLabel == 'InReview') ?
                        (isLocked == true && lockedBy.toString() == loggedInUser) ?
                          <>
                            <button type="button" style={{ float: "right", width: "60px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                            <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                          </>
                          : ""
                        : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-14 pr-lg-2 mt-1">
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left l_width">{t('Policy ID')}</label>
                        <input disabled={true} name="CategoryMinimumAchievementPolicyId" type="text" className="col-md-6 mb-2 textbox-padding form-control m_width" value={exceptionPolicyData.PolicyId} />


                        <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('Accruals.fiscalYear')}<span className={classes.mandatory}>*</span></label>
                        <Select styles={customStyles}
                          className={`col-md-6  mb-2 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding m_width ${disabledClass}`}
                          options={dateRange}
                          placeholder="Select"
                          isSearchable={true}
                          value={dateRange.find(option => option.label === selectedOptionsDateRange)}
                          onChange={OnChangeDateRange}
                          isDisabled={disableField}
                        />

                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('Rebates.StartDate')}</label>
                        <div className='col-md-6 mt-2 mb-2 textbox-padding m_width'>
                          <DatePicker name="startDate" value={exceptionPolicyData.StartDate}
                            className={`drodownwidth form-control form-class ${disabledClass}`}
                            disabled={disableField}
                            dateFormat={SessionManage.getdateformat()}
                            selected={exceptionPolicyData.StartDate}
                            onChange={(event) => OnChangeHandlerStartDate(event)}
                            minDate={minDate || exceptionPolicyData.StartDate}  // January 1, 2023
                            maxDate={maxDate || exceptionPolicyData.EndDate} // March 31, 2024
                            filterDate={(date) => date.getDate()}
                          />
                        </div>

                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('Rebates.EndDate')}</label>
                        <div className='col-md-6 mb-2 mt-2 textbox-padding m_width'>
                          <DatePicker name="EndDate" className={`drodownwidth form-control form-class ${disabledClass}`} disabled={true}
                            dateFormat={SessionManage.getdateformat()} selected={exceptionPolicyData.EndDate} onChange={(event) => OnChangeHandlerEndDate(event)} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">

                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">

                        <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('Policy Name')}</label>
                        <input name="CategoryMinimumAchievementPolicyName" type="text" className="col-md-6 mb-2 mt-2 textbox-padding form-control m_width" value={exceptionPolicyData.PolicyName} disabled={disableField} onChange={(e) => OnNameChange(e)} />

                        <label className="col-md-6 mb-2 mt-2 text-left l_width" style={{ paddingBottom: "10px" }}>{t('Rebates.RebateType')}<span className={classes.mandatory}>*</span></label>

                        <Select
                          className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown fontsizedropdown m_width ${disabledClass}`}
                          options={rebateTypeData}
                          placeholder="Select"
                          value={rebateTypeData.filter(function (option) {
                            return option.value === `${selectedOptionsRebateType}`;
                          })}

                          onChange={OnChangeHandlerRebateType}
                          isSearchable={true}
                          styles={customStyles}
                          isDisabled={true}
                        />

                        <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('Rebates.RebateMeasure')}</label>
                        {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ?
                          <Select
                            className={`col-md-6 mb-2  mb-2 mt-2 textbox-padding-dropdown fontsizedropdown ${disabledClass}`}
                            options={rebateMeasureData}
                            placeholder="Select"

                            value={rebateMeasureData.filter((option) => {
                              return option.value === `${selectedOptionsRebateMeasure}`;
                            })}
                            onChange={OnChangeHandlerRebateMeasure}
                            isSearchable={true}
                            styles={customStyles}
                            isDisabled={disableField}
                          />
                          :
                          <Select
                            className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                            options={rebateMeasureData}
                            placeholder="Select"
                            value={rebateMeasureData.filter(function (option) {
                              return option.value === `${selectedOptionsRebateMeasure}`;
                            })}
                            isSearchable={true}
                            styles={customStyles}
                            isDisabled={true}
                          />
                        }
                        {/* <label className="col-md-6  mb-2 mt-2 text-left l_width" >{t('Rebates.PayoutCond')}<span className={classes.mandatory}>*</span></label>
                        <Select
                          className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                          options={rebatePayoutCondData}
                          placeholder="Select"
                          value={rebatePayoutCondData.filter(function (option) {
                            return option.value === `${selectedOptionsPayout}`
                          })}
                          onChange={OnChangeHandlerPayout}
                          isSearchable={true}
                          styles={customStyles}
                           
                          name="Rebate_Payout_Cond_Id"
                        /> */}
                        <label className="col-md-6 mb-2 mt-2 text-left l_width">
                          {t('Rebates.PayoutCond')}
                          <span className={classes.mandatory}>*</span>
                        </label>
                        <Select
                          className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                          options={rebatePayoutCondData}
                          placeholder="Select"
                          value={rebatePayoutCondData.find(option => option.label === "Tiered")}
                          onChange={OnChangeHandlerPayout}
                          isSearchable={true}
                          isDisabled={true} // Disable the Select component
                          styles={customStyles}
                          name="Rebate_Payout_Cond_Id"
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "175px" }} >
                    <div className="container">
                      <div className="row">


                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('CategoryMinimumAchievementPolicy.Status')}</label>

                        <input disabled={true} name="Status" type="text" className="col-md-6 mb-2 mt-2 textbox-padding form-control m_width" value={exceptionPolicyData.StatusLabel} />

                        <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('DataImport.CreatedBy')}</label>
                        <input disabled={true} name="CreatedBy" type="text" className="col-md-6 mb-2 mt-2 textbox-padding form-control m_width" value={exceptionPolicyData.CreatedUser} />

                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('DataImport.CreatedDate')}</label>
                        <input type="text" className="col-md-6  mb-2 mt-2 textbox-padding datetimepicker-input form-control m_width" data-target="#reservationdate" disabled={true}
                          name="createdDate" value={exceptionPolicyData.CreatedDate} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="col-lg-14 mt-3">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-8 text-left">
                  {t('CategoryMinimumAchievementPolicy.CategoryPolicyDefinition')}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <div className="container">
                <div className="table-responsive">
                  <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "700px" }}>
                    <thead className="thead-light">
                      <tr className='text-left'>

                        <th>{t('Segement')}</th>
                        <th>{t('MPG')}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.VT')}</th>
                        <th>{"Product"}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.E%')}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.EGR%')}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.EGV%')}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.TM')}</th>
                        <th>{t('CategoryMinimumAchievementPolicy.VT')}</th>
                        <th>{t('Effected TOD')}</th>
                        <th>{t('Effected Segments')}</th>
                        <th>{t('Additional/Reduced Incentive')}</th>
                        <th>{t('Percent')}</th>
                        <th>{t('Lump Sum')}</th>
                        <th>{t('Value')}</th>

                        {disableField == false && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                          <th></th>
                          :
                          ''
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        VolumeRebateList.map((item, index) => (
                          <tr >

                            <td>
                              <Form.Control
                                style={{ width: "100px" }}
                                onChange={(event) => handleSegmentChange(event, index)}
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                as="select"
                                // disabled={index !== 0 || disableField}
                                // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                disabled={(item.MpgId) !== "" || (exceptionPolicyData.FiscalYear !== "") || disableField}
                                name="Segment"

                              >
                                <option>Select</option>
                                {segmentList.map((segment) => (
                                  <option key={segment.Id} value={segment.SegmentId} selected={segment.SegmentId == item.SegmentId} >
                                    {segment.SegmentName}
                                  </option>
                                ))}
                              </Form.Control>
                            </td>

                            <td>
                              <Form.Control
                                style={{ width: "100px" }}
                                onChange={(event) => handleMPGChange(event, index)}
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                as="select"
                                disabled={(item.SegmentId) !== "" || disableField}
                                // disabled={index !== 0 || disableField}
                                // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                name="MPG"

                              >
                                <option>Select</option>
                                {MPGData.map((mpg) => (
                                  <option key={mpg.Id} value={mpg.Id} selected={mpg.Id == item.MpgId} >
                                    {mpg.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </td>
                            <td>

                              <Form.Control
                                style={{ width: "100px" }}
                                onChange={(event) => onVehicleChange(event, index)}
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                disabled={(item.SegmentId) !== "" || disableField}
                                value={(item.SegmentId) !== "" ? "" : item.VehicletypeId}
                                as="select"
                                // disabled={index !== 0 || disableField}
                                // value={!item.VehicleType ? item.VehicleType : `${item.VehicleType.id}-${item.VehicleType.Name}`}
                                name="VehicleType"
                              >
                                <option>Select</option>
                                {vehicleTypeData.map((VehicleType) => (
                                  <option key={VehicleType.Id} value={VehicleType.Id} selected={VehicleType.Id == item.VehicletypeId}>
                                    {VehicleType.Name}
                                  </option>
                                ))}
                              </Form.Control>

                            </td>
                            <td>


                              <div className="col-md-2 mt-2 text-left">
                                <button className="btn btn-outline-dark  btn-sm ml-2"  onClick={(e) => clickShowProductPopup(e, index)} style={{ width: 50 + "px" }} type="submit"  disabled={item.SegmentId !== ''}
                                >{t('Select')}</button>
                                <br />
                                <a href='javascript:void(0)' onClick={(e) => clickSelectedShowProductPopup(e, index)}><label style={{ paddingTop: "5px" }}>({item.Productvalue?.length}) {t('Rebates.ProductsAdded')}</label></a>
                              </div>
                             
                              </td>
                              


                              <td>
                                <input
                                  style={{ width: "100px" }}
                                  onChange={(event) => {
                                    const value = event.target.value.replace(/\D/, '');
                                    eligibilityOnChange({ target: { value } }, index, "Eligiblity");
                                    setInputsDisabled(prevState => ({ ...prevState, EligiblityGrowthRevenue: value.length > 0, EligiblityGrowthVolume: value.length > 0 }));
                                  }}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  value={item.Eligiblity}
                                  name="Eligiblity"
                                  type="text"
                                  inputMode="numeric"
                                  disabled={(item.EligiblityGrowthRevenue != 0) || (item.EligiblityGrowthVolume != 0) || disableField}
                                />
                              </td>

                              <td>
                                <input
                                  style={{ width: "160px" }}
                                  onChange={(event) => {
                                    const value = event.target.value.replace(/\D/, '');
                                    eligibilityOnChange({ target: { value } }, index, "EligiblityGrowthRevenue");
                                    setInputsDisabled(prevState => ({ ...prevState, Eligiblity: value.length > 0, EligiblityGrowthVolume: value.length > 0 }));
                                  }}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  value={item.EligiblityGrowthRevenue}
                                  name="EligiblityGrowthRevenue"
                                  type="text"
                                  inputMode="numeric"
                                  disabled={(item.Eligiblity != 0) || (item.EligiblityGrowthVolume != 0) || disableField}
                                />
                              </td>

                              <td>
                                <input
                                  style={{ width: "160px" }}
                                  onChange={(event) => {
                                    const value = event.target.value.replace(/\D/, '');
                                    eligibilityOnChange({ target: { value } }, index, "EligiblityGrowthVolume");
                                    setInputsDisabled(prevState => ({ ...prevState, Eligiblity: value.length > 0, EligiblityGrowthRevenue: value.length > 0 }));
                                  }}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  value={item.EligiblityGrowthVolume}
                                  name="EligiblityGrowthVolume"
                                  type="text"
                                  inputMode="numeric"
                                  disabled={(item.Eligiblity != 0) || (item.EligiblityGrowthRevenue != 0) || disableField}
                                />
                              </td>




                              <td style={{ width: "180px" }}>
                                <Form.Control
                                  style={{ width: "160px" }}
                                  onChange={(event) => handleTargetMeasureChange(event, index)}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  // value={item.TargetmeasureId}
                                  disabled={disableField}
                                  as="select"
                                  name="Target_Measure"
                                >
                                  <option>Select</option>
                                  {
                                    targetMeasure.map((measure) => (
                                      <option key={measure.Id} value={measure.Label} selected={measure.Label == item.TargetMeasure}>
                                        {measure.Label}
                                      </option>
                                    ))
                                  }
                                </Form.Control>
                              </td>
                              <Form.Control
                                style={{ width: "100px" }}
                                onChange={(event) => onVehicleTMChange(event, index)}
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                disabled={item.TargetMeasure !== "Vehicle Type"}
                                as="select"
                                value={item.TargetMeasure === "Vehicle Type" ? item.Vehicletypetm : ""}
                                name="VehicleTypeTM"
                              >
                                <option>Select</option>
                                {vehicleTypeData.map((VehicleType) => (
                                  <option key={VehicleType.Id} value={VehicleType.Id}>
                                    {VehicleType.Name}
                                  </option>
                                ))}
                              </Form.Control>
                              <td style={{ width: "180px" }}>
                                <Form.Control
                                  style={{ width: "160px" }}
                                  onChange={(event) => onEffectedTodChange(event, index)}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  as="select"
                                  // disabled={index !== 0 || disableField}
                                  // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                  name="Effected_TOD"

                                >
                                  <option>Select</option>
                                  {effectedTod.map((effect) => (
                                    <option key={effect.value} value={effect.value} selected={effect.value == item.EffectedTod} >
                                      {effect.label}
                                    </option>
                                  ))}
                                </Form.Control>
                              </td>
                              <td style={{ width: "180px" }}>
                                <Form.Control
                                  style={{ width: "160px" }}
                                  onChange={(event) => onEffectedSegmentChange(event, index)}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  as="select"
                                  // disabled={index !== 0 || disableField}
                                  // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                  name="Effected_Segment"

                                >
                                  <option>Select</option>
                                  {effectedSegement.map((effect) => (
                                    <option key={effect} value={effect} selected={effect == item.EffectedSegments} >
                                      {effect}
                                    </option>
                                  ))}
                                </Form.Control>
                              </td>
                              {/* <td style={{ width: "180px" }}>
                              <Form.Control
                                style={{ width: "160px" }}
                                onChange={(event) => onEffectedTodChange(event, index)}  
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                as="select"
                                // disabled={index !== 0 || disableField}
                                // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                name="Effected_TOD"

                              >
                                <option>Select</option>
                                {effectedTod.map((effect) => (
                                  <option key={effect.value} value={effect.value} selected={effect.value == item.EffectedTod} >
                                    {effect.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </td> */}

                              <td style={{ width: "180px" }}>
                                <Form.Control
                                  style={{ width: "160px" }}
                                  onChange={(event) => onChangeAddRedIncentive(event, index)}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  as="select"
                                  // disabled={index !== 0 || disableField}
                                  // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                  name="Aditional/Reduced_Inc"

                                >
                                  <option>Select</option>
                                  {incentiveList.map((list) => (
                                    <option key={list} value={list} selected={list == item.AddRedIncentive} >
                                      {list}
                                    </option>
                                  ))}
                                </Form.Control>
                              </td>

                              <td>
                                <input
                                  style={{ width: "100px" }}
                                  onChange={(event) => { onChangeIncentivePercent(event, index) }}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  value={item.Incenentivevaluepercent}
                                  name="IncentiveValuePercent"
                                  type="text"
                                  inputMode="numeric"
                                  disabled={(item.Lumpsumvalue) !== 0 || disableField}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: "100px" }}
                                  onChange={(event) => { onChangeIncentiveLumpSum(event, index) }}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  value={item.Lumpsumvalue}
                                  name="IncentiveValueLumpSum"
                                  type="text"
                                  inputMode="numeric"
                                  disabled={(item.Incenentivevaluepercent) !== 0 || disableField}
                                />
                              </td>

                              <td style={{ width: "180px" }}>
                                <select
                                  style={{ width: "160px" }}
                                  onChange={(event) => onChangeIncentiveValue(event, index)}
                                  className={`${classes['Form-Input-control']} ${disabledClass}`}
                                  name="IncentiveValue"
                                  disabled={(item.Lumpsumvalue) !== 0 || disableField}
                                  value={(item.Lumpsumvalue) !== 0 ? incenticevalue[1] : item.Incenentivevalue || ""}
                                >
                                  <option value="">Select</option>
                                  {incenticevalue.map((list) => (
                                    <option key={list} value={list}>
                                      {list}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              {/* <td style={{ width: "180px" }}>
                              <Form.Control
                                style={{ width: "160px" }}
                                onChange={(event) => onChangeIncentiveValue(event, index)}
                                className={`${classes['Form-Input-control']} ${disabledClass}`}
                                as="select"
                                // disabled={index !== 0 || disableField}
                                // value={!item.Category ? item.Category : `${item.Category.id}-${item.Category.Name}`}
                                value={(item.IncentiveValueLumpSum) !== 0 ? incenticevalue[1] : (item.IncenentiveValue || "")}
                                name="IncentiveValue"
                                disabled={(item.IncentiveValueLumpSum) !== 0||disableField}

                              >
                                <option>Select</option>
                                {incenticevalue.map((list) => (
                                  <option key={list} value={list} selected={list == item.IncenentiveValue} >
                                    {list}
                                  </option>
                                ))}
                              </Form.Control>
                            </td> */}


                              {(disableField == false) && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                                <td>
                                  <div className={classes['gridwidth']} style={{ margin: "5px" }}>

                                    {Service.editPerms(userPerms) == false ? "" : VolumeRebateList.length - 1 === index ? <a onClick={() => AddVolume(index)} className="btn btn-sm btn-update">Add</a> : null}
                                    {Service.editPerms(userPerms) == false ? "" : index !== 0 && VolumeRebateList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-sm btn-update">Remove</a> : null}

                                  </div>
                                </td>
                                : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: '60px', paddingBottom: '10px' }} hidden={disableField}>
              {ExceptionValueList.length == 0 ?
                <button className="btn btn-sm btn-update" onClick={() => add('ADD')} style={{ width: "50px" }}>{t('ExceptionPolicy.ADD')}</button>
                : ''}
            </div>
            {addCategory === true && ExceptionValueList.length != 0 ? <>
              <div >
                <div className="container">
                  <div className="table-responsive">
                    <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm" style={{ width: "977px", marginBottom: "170px" }}>
                      <thead className="thead-light">
                        <tr className='text-left'>
                          <th>{t('ExceptionPolicy.Category')}</th>
                          <th>{t('ExceptionPolicy.SubCategory')}</th>
                          <th>{t('ExceptionPolicy.Operator')}</th>
                          <th>{t('ExceptionPolicy.Value')}</th>
                          <th>{t('ExceptionPolicy.And/Or')}</th>
                          {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ? <th>{t('')}</th> : ''}
                        </tr>
                      </thead>
                      <tbody>
                        {console.log('24-1', ExceptionValueList.length)}
                        {console.log('24-1', ExceptionValueList)}
                        {
                          ExceptionValueList.map((item, index) => (
                            <tr >
                              <td>
                                {/* <Select
                                options={CategoryValues}
                                placeholder={t('Select')}
                                value={CategoryValues.filter(function (option) {
                                  return option.Name == `${item.Category}`;
                                })}

                                onChange={(event) => OnCategoryChange(event, index)}
                                isSearchable={true}
                                isDisabled={index == 0 ? true : disableField}
                                styles={customStyles}
                              /> */}
                                <button className="btn  btn-update btn-sm ml-2 mt-2" onClick={(event) => ClickValueCategoryPopup(index)} style={{ width: "50px" }}>{t('Select')}</button>
                                <span style={{ marginLeft: "20px" }}><a><label style={{ paddingTop: "5px" }}>({item.Category?.length == 0 ? 0 : 1})</label></a></span>
                              </td>
                              <td>
                                {/* <Select
                                options={item?.SubCategory?.filter((x) => x.dropdownState?.selected == false)}
                                placeholder={t('Select')}
                                value={SubCategoryValues.filter(function (option) {
                                  return option.value == `${item.TargetSettingDataId}`;
                                })}
                                onChange={(event) => OnSubCategoryChange(event, index)}
                                isSearchable={true}
                                isDisabled={disableField}
                                styles={customStyles}
                              /> */}
                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValueSubCategoryPopup(item.SubCategory, index)} style={{ width: "50px" }}>{t('Select')}</button>
                                <span style={{ marginLeft: "20px" }}><a><label style={{ paddingTop: "5px" }}>({item.SelectedItemSubCategory?.length == 0 ? 0 : 1})</label></a></span>
                              </td>
                              <td>
                                {/* <Form.Control style={{ width: "100px" }}
                                onChange={(event) => OnOperatorChange(event.target.value, index)}
                                className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} value={item.Operator} name="Operator"
                              > */}
                                <Form.Control style={{ width: "100px" }}
                                  onChange={(event) => OnOperatorChange(event.target.value, index)}
                                  className={`${classes['Form-Input-control']}`} as="select" disabled value={item.Operator = 'Equals'} name="Operator"
                                >
                                  <option>{t('Select')}</option>
                                  <option>Equals</option>
                                  <option>Does not equal</option>
                                </Form.Control></td>
                              <td>
                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValuePopup(item, index)} style={{ width: "50px" }}>{t('Select')}</button>
                                <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => ClickValueCountPopup(item, index)}>
                                  <label style={{ paddingTop: "5px" }}>({(item.ValueListString?.length)})</label></a></span>
                              </td>
                              <td>
                                {/* {(index != ExceptionValueList.length - 1) ? */}

                                <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                  <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={item.Condition}
                                    onChange={(event) => OnConditionChange(event.target.value, index)} >
                                    <option value="AND">{t('ExceptionPolicy.And')}</option>
                                    <option value="OR">{t('ExceptionPolicy.OR')}</option>
                                  </Form.Control>
                                </div>
                                {/* :
                                    ''
                                  } */}
                              </td>
                              {disableField == false ?
                                <td>
                                  <div className={classes['gridwidth']} disabled={disableField} style={{ margin: "5px" }}>
                                    {ExceptionValueList.length - 1 === index ? <a onClick={() => AddVolumeCat(index)} className="btn btn-sm btn-update">Add</a> : null}
                                    {ExceptionValueList.length !== 0 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolumeCat(index)} className="btn btn-sm btn-update">Remove</a> : null}
                                  </div>
                                </td>
                                : null
                              }

                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </> :
              <></>}
          </div>
        </div>

        {
          showProductPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ProductListing')}
                          </div>
                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "All")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div style={{ minHeight: "66vh" }}>
                            <div className="container">
                              <div className='col-md-14'>
                                {clickProduct === true ?
                                  <ProductTreeDialog
                                    show={showModal}
                                    onOkClick={UpdateProducts}
                                    onHide={() => CloseProductPicker}
                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                    rebatelinestatus={rebatelinestatus}
                                    dialogType="Product"
                                    SelectedData={FilteredProducts}

                                    selectedType={selectedType}
                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                  >
                                    <div className={classes['Radiobutton-header']}>
                                      <div className={classes['Radiobutton-internal-div']} >
                                        <input type="radio" checked={selectedType === 'Attribute'}
                                          value="Attribute" onChange={SelectedProductTypeHandler}
                                          name="product" className={classes['radio-button-Right form-control']} /> {t('Rebates.Attribute')}


                                      </div>
                                    </div>
                                  </ProductTreeDialog>
                                  : ''
                                }

                              </div>

                            </div>

                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showCategoryPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}


                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupCancel()} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')} </button>
                            <button className="btn btn-sm btn-update ml-1" onClick={(event) => ClickValuePopupClose('Close', keyValue, "All")} type="submit" style={{ float: "right", width: "40px" }}>Add</button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div style={{ paddingTop: "5px" }}>
                                <input type="text" className='form-control form-control-user' placeholder={`${t('SearchFor')} ${"Categories"}`} onChange={(event) => attributeSearch(event)} />
                              </div>
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input checked={attbCheckAll} type="checkbox" name="attribute-check" id="option-all-customer" onChange={checkAllAttribute} /><label></label></th>
                                      <th id="Attribute1" >
                                        {keyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {keyValue === "Category" ?
                                    <tbody>
                                      {AllCategoryValues.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.selected == null || i.selected == false ? false : true} value={i.value} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.value, "Category")} disabled={disableField}></input></td>
                                          <td>
                                            {i.label}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {keyValue === "SubCategory" ?
                                    <tbody>
                                      {AllSubCategoryValues.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.selected == null || i.selected == false ? false : true} value={i.value} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.value, "SubCategory")} disabled={disableField}></input></td>
                                          <td>
                                            {i.label}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showProductPopupTable === true ? (
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row align-items-center my-2" style={{ marginLeft: "-20px" }}>
                          <div className="col-md-9 text-left">
                            {t('Product Listing')}
                          </div>
                          <div style={{ marginLeft: "25px" }}>
                            <button
                              style={{ width: "80px" }}
                              className="btn btn-outline-dark btn-sm ml-4"
                              onClick={okProduct}
                            >
                              {t('Save&Close')}
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-dark btn-sm ml-1"
                              onClick={clickCloseAccuralWorkflow}
                              type="submit"
                              style={{ width: "50px" }}
                            >
                              {t('Cancel')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="Customer">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header"></div>

                      <div className="scrollbar-class">
                        <div className="container">
                          <div>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              value={productSearch}
                              onChange={(event) => SearchProduct(event)}
                              placeholder={`${t('Search')} ${t('Products')}`}
                            />
                          </div>

                          <table className="table table-bordered text-left mt-2" id="Customer">
                            <thead
                              className="text-white is-sticky-thead-product"
                              style={{ backgroundColor: "var(--primary)" }}
                            >
                              <tr>
                                <th>
                                  <input
                                    checked={productCheckAll}
                                    type="checkbox"

                                    id="option-all-product"
                                    onChange={(event) => { checkAllProduct(event.target.checked) }}
                                  />
                                </th>
                                <th
                                  id="Name"
                                  style={{
                                    backgroundColor: filterValueCustomer.Name ? '#6cae55' : '',
                                    width: "110px",
                                  }}
                                >
                                  {t('Material No')}
                                  <span
                                    className="fa fa-filter"
                                    style={{
                                      float: "right",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => FilterColumnCustomer('Name')}
                                  ></span>
                                </th>
                                <th
                                  id="Label"
                                  style={{
                                    backgroundColor: filterValueCustomer.Label ? '#6cae55' : '',
                                    maxWidth: "110px",
                                  }}
                                >
                                  {t('Product')}
                                  <span
                                    className="fa fa-filter"
                                    style={{
                                      float: "right",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => FilterColumnCustomer('Label')}
                                  ></span>
                                </th>
                                <th
                                  id="Oem"
                                  style={{
                                    backgroundColor: filterValueCustomer.Label ? '#6cae55' : '',
                                    maxWidth: "110px",
                                  }}
                                >
                                  {t('Oem')}
                                  <span
                                    className="fa fa-filter"
                                    style={{
                                      float: "right",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => FilterColumnCustomer('Label')}
                                  ></span>
                                </th>
                                <th
                                  id="Vehicle Type"
                                  style={{
                                    backgroundColor: filterValueCustomer.Label ? '#6cae55' : '',
                                    maxWidth: "110px",
                                  }}
                                >
                                  {t('Vehhicle Type')}
                                  <span
                                    className="fa fa-filter"
                                    style={{
                                      float: "right",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => FilterColumnCustomer('Label')}
                                  ></span>
                                </th>

                              </tr>
                            </thead>

                            <tbody>
                              {productValueTable[productIndex].map((cslist, index) => (
                                <tr
                                  key={cslist.Id}

                                >
                                  <td style={{ width: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className={disabledClass}
                                      // disabled={disableHeaderField}
                                      checked={cslist.Selected}
                                      name="product-check"
                                      value={cslist.Id}
                                      id={cslist.Id}
                                      onChange={(event) =>
                                        handleProductChanges(event, cslist.Id)
                                      }

                                    />
                                  </td>
                                  <td style={{ width: "90px" }}>
                                    <div
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        height: "20px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={cslist.Name}
                                    >
                                      {cslist.Name}
                                    </div>
                                  </td>
                                  <td style={{ width: "260px" }}>
                                    <div
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        height: "20px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={cslist.Label}
                                    >
                                      {cslist.Label}
                                    </div>
                                  </td>
                                  <td style={{ width: "260px" }}>
                                    <div
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        height: "20px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={cslist.Oem}
                                    >
                                      {cslist.Oem}
                                    </div>
                                  </td>
                                  <td style={{ width: "260px" }}>
                                    <div
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        height: "20px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={cslist.Vehicletype
                                      }
                                    >
                                      {cslist.Vehicletype}
                                    </div>
                                  </td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          showSelectedProductPopupTable === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row" style={{ "margin-left": "-20px" }} >
                          <div className="col-md-9 text-left">
                            {t('Products Listing')}
                          </div>
                          {/* <div style={{  backgroundColor: "#fff","margin-left":"55px" }}>
                                                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                 </div> */}
                          <div className="" style={{ marginLeft: "135px" }}>
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseProductListing} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Material No')}</th>
                                <th>{t('Product')}</th>
                                <th>{t('Oem')}</th>
                                <th>{t('Vehicle Type')}</th>

                              </tr>
                            </thead>

                            <tbody>

                              {
                                selectedPart.map((cslist, index) => (
                                  <tr key={index}>

                                    <td style={{ width: "30px" }}>
                                      {/* Commented this */}
                                      {/* {disableHeaderField == true ? <> */}
                                      <input type="checkbox"
                                        disabled={true}
                                        className={disabledClass}
                                        checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      {/* </> : <>
                                                                            <input type="checkbox"
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                        </>
                                                                        } */}
                                    </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Label}>{cslist.Label}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Oem}>{cslist.Oem}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Vehicletype}>{cslist.Vehicletype}</div>


                                    </td>
                                  </tr>


                                ))


                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>

                  {/* <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                            </div> */}
                </div>
              </div>
            </div>
            :
            ""
        }
        {

          showCustomerPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.CustomerListing')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "All")} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')}</button>
                            <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okCustomer} >{t('Save&Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="Customer">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                            <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.Customer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} type="checkbox" disabled={disableField} id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                </th>
                                <th id="Name" style={{ backgroundColor: (filterValueCustomer.Name ? '#6cae55' : ''), width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Name')}></span></th>
                                <th id="Label" style={{ backgroundColor: (filterValueCustomer.Label ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Label')}></span></th>
                                <th id="Station" style={{ backgroundColor: (filterValueCustomer.Station ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Station')}></span></th>
                                <th id="State" style={{ backgroundColor: (filterValueCustomer.State ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('State')}></span></th>
                                <th id="Zone" style={{ backgroundColor: (filterValueCustomer.Zone ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Zone')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerValues.map((cslist, index) => (

                                  <tr onClick={(event) => handleCustSelection(event, cslist.label, cslist.Id, cslist.State)}>
                                    <td style={{ width: "30px" }}>

                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                          name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </>

                                      }</td>
                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>
                                  </tr>
                                ))
                              }
                            </thead>
                          </table>


                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>
              {
                showCustomerfilterpopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?


                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                          </div>

                        </div>

                        <div className="container mt-2">
                          <div className="row">
                            <input type="text" placeholder="Search" value={filterSearchCust} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2 form-control' />
                          </div>

                          <div className="row contentoverflow mt-2">
                            <table className="filtertable table-responsive-sm">
                              <tbody>
                                <tr>
                                  <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                  <td>Select All</td>
                                </tr>
                                {
                                  filterCusDropDownData.map((item, index) => (

                                    <tr key={index}>

                                      <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCusdropdownChange(event, item.Id, item.Name)} /></td>

                                      <td>{item.Name}</td>

                                    </tr>


                                  ))
                                }

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </>
                      :
                      <></>

                    }
                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                      <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                      <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                    </div>
                    <div>
                    </div>
                  </div>
                  :
                  <></>
              }
            </div>
            :
            ""
        }


        {
          showCustomerGroupPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "CustomerGroup", "All")} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')}</button>
                            <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okCustomerGroup}>{t('Save&Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="CustomerGroup">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                            <input type="text" className='form-control form-control-user' value={customerGroupSearch} onChange={(event) => SearchGroupCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.GroupCustomer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="CustomerGroup">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} disabled={disableField} type="checkbox" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                </th>

                                <th id="GroupLabel" style={{ backgroundColor: (filterValueGroupCustomer.label ? '#6cae55' : ''), maxWidth: "110px" }}>{`${t('Rebates.CustomerGroup')} ${t('Policies.Label')}`}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnGroupCustomer('label')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerGroupValues.map((cslist, index) => (

                                  <tr>
                                    <td style={{ width: "30px" }}>


                                      <input type="checkbox"
                                        disabled={disableField}

                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                        name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                    </td>


                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>

                                ))
                              }


                            </thead>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                  {
                    showCustomerGroupfilterpopup === true ?
                      <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                        {custGroupFilterCol === 'label' ?
                          <>
                            <div className="container">
                              <div className="row">
                                <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCustGroup}</b>

                              </div>

                            </div>

                            <div className="container mt-2">
                              <div className="row">
                                <input type="text" placeholder="Search" value={filterSearchCustGroup} onChange={(event) => UserfilterHandlerCustomerGroup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                              </div>

                              <div className="row contentoverflow mt-2">
                                <table className="filtertable table-responsive-sm">
                                  <tbody>
                                    <tr>
                                      <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomerGroup} /></td>
                                      <td>Select All</td>
                                    </tr>
                                    {
                                      CustomerGroupValues.map((item, index) => (

                                        <tr key={index}>

                                          <td><input id={item.Id + item.label} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCustGroupdropdownChange(event, item.Id, item.label)} /></td>

                                          <td>{item.label}</td>

                                        </tr>


                                      ))
                                    }

                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </>
                          :
                          <></>

                        }
                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                          <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandlerCustomerGroup('Apply')}>{t('Apply')}</button>
                          <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerCustomerGroup('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                      </div>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSetlFreqPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.SettlementsFreq')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "All")} type="submit" style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-sm btn-update ml-1' onClick={oksettlFreq} style={{ float: "right", width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={settlFreqcheckAll} disabled={disableField} onChange={checkAllSettlFreqChange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('MassAction.SettlementsFreq')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">
                            {
                              settlFreqValues.map((settlFreq) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={settlFreq.Selected}
                                    name="settlfreq-check" value={settlFreq.Id} id={settlFreq.Id} onChange={(event) => handleSettlFreqChanges(event, settlFreq.label, settlFreq.Id)} />
                                  &nbsp;<label>{settlFreq.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            ""
        }

        {
          showRebateTypePopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.RebateType')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "All")} type="submit" style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-sm btn-update ml-1' onClick={okRebateType} style={{ float: "right", width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>


                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">
                            {
                              rebateTypValues.map((rebateType) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={rebateType.Selected}
                                    name="rebateType-check" value={rebateType.Id} id={rebateType.Id} onChange={(event) => handleRebateTypeChanges(event, rebateType.label, rebateType.Id)} />
                                  &nbsp;<label>{rebateType.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            ""
        }

        {
          showSelectedProductpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ProductListing')}
                          </div>
                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">

                          </div>

                          <div className='scrollbar-class'>

                            <div className="container">
                              <div className='col-md-14'>

                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader">

                                      </th>
                                      <th id="ProductId" >
                                        {t('Rebates.MaterialNo')}
                                      </th>
                                      <th id="Product" >
                                        {t('Rebates.ItemName')}
                                      </th>
                                      <th id="Oem" >
                                        Oem
                                      </th>
                                      <th id="Vehicletype" >
                                        Vehicle Type
                                      </th>



                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dualListBoxOptionsByAttribute.filter((e) => e.Selected == true).map((listVal) =>
                                      <tr key={listVal.Id} >

                                        <td style={{ width: "30px" }}>

                                          {

                                            <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                          }

                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Oem}>{listVal.Oem}</div>

                                        </td>
                                        <td style={{ width: "100px" }}>

                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Vehicletype}>{listVal.Vehicletype}</div>

                                        </td>

                                      </tr>
                                    )}
                                  </tbody>
                                </table>


                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCategorypopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => ClickValuePopupClose('Close', countKeyValue, "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input type="checkbox" disabled="true" ></input></th>
                                      <th id="Attribute1" >
                                        {countKeyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {countKeyValue === "Category1" ?
                                    <tbody>
                                      {attribute1Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category2" ?
                                    <tbody>
                                      {attribute2Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category3" ?
                                    <tbody>
                                      {attribute3Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category4" ?
                                    <tbody>
                                      {attribute4Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                  {countKeyValue === "Category5" ?
                                    <tbody>
                                      {attribute5Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                </table>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedRebateType === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.RebateType')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.RebateType')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                RebateTypeDataSelected.map((cslist, index) => (
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedSettlFreq === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.SettlementFreq')}
                          </div>
                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.SettlementFreq')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                SettlFreqDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.CustomerListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>
                                <th>{t('Rebates.Station')}</th>
                                <th>{t('Rebates.State')}</th>
                                <th>{t('Rebates.Zone')}</th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>



                                  </tr>


                                ))


                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerGrouppopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}
                          </div>
                          <div className="col-md-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "GroupCustomer", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>

                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerGroupDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showExceptionWorkflow === true ?
            <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

              {showWorkflow === false ?
                <div className="container" id="workflow">
                  <div className="row mt-3">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                          <div className="row">
                            <div className="col-md-11 text-left">
                              {t('Workflow')}
                            </div>
                            <div className="ml-4">
                              <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body" style={{ minHeight: "55vh" }}>


                          {workflowchart.length > 0 ?
                            <div className='col-md-12'>
                              <table className="table table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                    <th className='font-center workflowchart-font'>Users</th>
                                    <th className='font-center workflowchart-font'>Approval</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    workflowchart.map((i) =>
                                      <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                          <ul >
                                            {
                                              i.lstworkflowapprover.map((j, index) => {
                                                return <>

                                                  {
                                                    index < 2 ?
                                                      j.IsApproved === true ?
                                                        <li className='workflowchartperson'>
                                                          <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}</span>
                                                        </li>
                                                        :
                                                        <>
                                                          <li className='workflowchartperson'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}
                                                          </li>
                                                        </>
                                                      :
                                                      ''
                                                  }
                                                </>

                                              })
                                            }

                                            {
                                              i.lstworkflowapprover.length > 2 ?
                                                <li className='workflowchartperson tooltip_workflow'>
                                                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                  <br></br> {t('MoreUsers')}
                                                  <span class="tooltiptext">

                                                    {
                                                      i.lstworkflowapprover.map((k, index) => {
                                                        return <span>
                                                          {
                                                            index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                              &nbsp; {k.FullName}
                                                              <hr></hr></> : null
                                                          }


                                                        </span>
                                                      })
                                                    }

                                                  </span>
                                                </li>
                                                :
                                                ''
                                            }


                                          </ul>

                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            i.IsApproved === true ?
                                              <span className='workflowrightcolor'>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                              </span>

                                              : i.IsDenied == true ?
                                                <span className='workflowrightcolor'>
                                                  <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                </span>
                                                : ''
                                          }
                                        </td>
                                      </tr>)

                                  }
                                </tbody>

                              </table>

                            </div>
                            :
                            <div className={classes.flow}>

                            </div>
                          }


                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered text-left mt-2 table-responsive-sm">
                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                  <tr>

                                    <th>{t('Rebates.Event')}</th>
                                    <th>{t('Rebates.CreatedDate')}</th>
                                    <th>{t('Rebates.User')}</th>
                                    <th>{t('Rebates.Comments')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinnerworkflow === true ?

                                      <>
                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                      </>
                                      :
                                      workflowData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {t('NoDataAvailable')}
                                          </td> <td></td></tr>
                                        :
                                        woData === '' || woData === 'undefined' ?
                                          <tr></tr> :
                                          workflowData.map((i) =>
                                            <tr key={i.id}>
                                              <td>{i.EventName}</td>
                                              {i.CreatedDate === null ? <td></td> : <td>{`${moment(i.CreatedDate).format(dateFormat)} ${moment(i.CreatedDate).format(timeFormat)}`}</td>}
                                              <td>{i.CreateUserName}</td>
                                              <td>{i.Comments}</td>
                                            </tr>)

                                  }

                                </tbody>
                              </table>
                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                :
                ''
              }

            </div>
            :
            ""
        }

        {
          showZonePopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.ZoneListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('zone')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Zone')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              zoneOptions.map((zonelist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={zonelist.selected} name="region-check" value={zonelist.value} id={zonelist.Id} onChange={(event) => handleregionChanges(event, zonelist.label, zonelist.value, "Zone")} />
                                  &nbsp;<label>{zonelist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }
        {
          showSelectedZonepopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ZoneListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('zone')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Zone')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                zoneOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.value} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStatePopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StateListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('state')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.State')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stateOptions.map((statelist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={statelist.selected} name="region-check" value={statelist.value} id={statelist.Id} onChange={(event) => handleregionChanges(event, statelist.label, statelist.value, "State")} />
                                  &nbsp;<label>{statelist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""
        }
        {
          showSelectedStatepopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StateListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('state')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.State')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stateOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStationPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StationListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('station')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Station')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stationOptions.map((stationlist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={stationlist.selected} name="region-check" value={stationlist.value} id={stationlist.Id} onChange={(event) => handleregionChanges(event, stationlist.label, stationlist.value, "Station")} />
                                  &nbsp;<label>{stationlist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""
        }
        {
          showSelectedStationpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StationListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('station')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Station')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stationOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStockistPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StockistListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('stockist')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('stockist')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Stockist')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          <div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stockistOptions.map((stockistlist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={stockistlist.selected} name="region-check" value={stockistlist.value} id={stockistlist.Id} onChange={(event) => handleregionChanges(event, stockistlist.label, stockistlist.value, "Stockist")} />
                                  &nbsp;<label>{stockistlist.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('stockist')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }
        {
          showSelectedStockistpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StockistListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('stockist')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Stockist')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stockistOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.value} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          CategoryPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "60vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('ExceptionPolicy.Category')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('category')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => CloseCategory()} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "60vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                {/* <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th> */}

                                <th id="Product">
                                  {t('ExceptionPolicy.Category')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">
                            {
                              CategoryList.map((each, index) => (
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      disableField ||
                                      // CategoryIndex === 0 ||
                                      (ExceptionValueList[CategoryIndex]?.Category && ExceptionValueList[CategoryIndex]?.Category !== each.label)
                                    }
                                    checked={
                                      ExceptionValueList[CategoryIndex]?.Category === each.label
                                      //||
                                      // (index !== CategoryIndex)
                                    }
                                    name="region-check"
                                    value={each.label}
                                    id={each.Id}
                                    onChange={(event) => OnCategoryChange(event, each)}
                                  />
                                  &nbsp;<label>{each.label}</label>
                                </div>
                              ))
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }




        {
          showSubCategoryPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "60vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('ExceptionPolicy.SubCategory')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('subCategory')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => CloseSubCategory()} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "60vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                {/* <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th> */}

                                <th id="Product">
                                  {t('ExceptionPolicy.SubCategory')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "0px" }} id="div_customer">
                            {
                              SubCategoryList.map((each) => {


                                const isSelectedInException = ExceptionValueList.some(obj => obj.SelectedItemSubCategory === each.label);
                                const isSelectedInCurrentException = ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory === each.label;
                                const shouldDisplay = !isSelectedInException || isSelectedInCurrentException;

                                return shouldDisplay ? (
                                  <div className='col-md-3' style={{ padding: "5px" }} key={each.Id}>
                                    <input
                                      type="checkbox"
                                      disabled={
                                        disableField ||
                                        (ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory &&
                                          ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory !== each.label)
                                      }
                                      checked={isSelectedInCurrentException}
                                      name="region-check"
                                      value={each.label}
                                      id={each.Id}
                                      onChange={(event) => handleSubCatChanges(event, each)}
                                    />
                                    &nbsp;
                                    <label>
                                      {each.label}
                                    </label>
                                  </div>
                                )
                                  : null;
                              })
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }

        <Modal show={showApprovedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
              onChange={(e) => { setCommentsForApproved(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showWorkflowAlert}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
              {t('Ok')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeniedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
              onChange={(e) => { setCommentsForDenied(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSubmitModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
              onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showVehicleTypeValidation} size="bg" centered>
          <Modal.Header>
            <Modal.Title>{t('Vehicle Type Validation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            Are you sure you want to select a vehicle type? This will deselect all the selected options.
          </Modal.Body>
          <Modal.Footer>
            <Button
              size='sm'
              style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }}
              onClick={() => handleVehicleTypeDialog('No')}
            >
              {t('No')}
            </Button>
            <Button
              size='sm'
              style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }}
              onClick={() => handleVehicleTypeDialog('yes')}
            >
              {t('Yes')}
            </Button>
          </Modal.Footer>
        </Modal>


      </div >
    </>
  )
}
export default NewCategoryMinimumAchievementPolicy