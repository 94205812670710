import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
// import '../index.css';
import SchemeListComponent from '../../../Components/SchemeComponent/SchemeListComponent/SchemeListComponent';



function SchemeListing(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <SchemeListComponent {...props}  navigate={navigate} id={params.val} />
        </div>
    );  
}
export default SchemeListing