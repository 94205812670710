import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal, Alert } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Link, useLocation, useSearchParams } from "react-router-dom"
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Service from '../../../Services/Service.js';
import classes from '../../../Components/RebateProgramComponent/CreateRebateComponent/Form.module.css'
import dateFormat from 'dateformat';
import $, { each, event, type } from 'jquery';
import CustomErrorPopup from '../../../UI/CustomErrorPopup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomListbox from '../../RebateProgramComponent/CreateRebateComponent/CustomListbox.js';
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog.js';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../Helper/utils.js';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Color } from 'ag-grid-community';
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import axios from 'axios';
// import { setAriaRowIndex } from 'ag-grid-community/dist/lib/utils/aria.js';

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 28,
        maxHeight: 100,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2,
        fontSize: "0.75rem",
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        fontSize: "0.75rem",
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 2px',
        fontSize: "0.75rem",
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,

    })
};


function CreateProductSchemeComponent(props) {

    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [regioncheckAll, setRegioncheckAll] = useState(false);
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Rebate Program ID", key: "RebateProgramLineId" },

        { label: "Rebate Program Desc", key: "RebateProgram_Desc" },

        { label: "Rebate Type", key: "Rebate_Type" },

        { label: "Rebate Category", key: "RebateCategory" },

        { label: "Valid From", key: "Validity_From" },

        { label: "Valid To", key: "Validity_To" },

        { label: "Settlement Frequency", key: "Settlement_Freq" },

        { label: "Status", key: "Status" },

        { label: "Is Active", key: "ActiveLabel" },

    ]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
    const [custChngModal, setCustChngModal] = useState(false);
    const [showCustomerpopup, setshowCustomerpopup] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('')
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [showProdModal, setShowProdModal] = useState(true);
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)
    const [filterSearchCust, setFilterSearchCust] = useState("");
    const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
    const [filtertext, setfiltertext] = useState('');
    const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
    const [filtertextCust, setfiltertextCust] = useState('');
    const [filtercustData, setfilterCustData] = useState([]);
    const [filtercustGroupData, setfilterCustGroupData] = useState([]);
    const [currentFilterKey, setcurrentFilterKey] = useState('');
    const [currentCustFilterKey, setcurrentCustFilterKey] = useState('');
    const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
    const [updateNav, setUpdateNav] = useState("/rebate-program/0")


    const [customerDataFilters, setCustomerDataFilters] = useState([]);
    const [customerDataSelected, setCustomerDataSelected] = useState([]);
    const [customerDataFilter, setCustomerDataFilter] = useState([]);
    const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [currentFiscalYear, setCurrentFiscalYear] = useState('')
    const [rpl_ID, setRPL_ID] = useState(false);
    const [salesOrgData, setSalesOrgData] = useState({ id: 0, name: "" });
    const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
    const [currencyData, setCurrencyData] = useState([]);
    const [setlFreqData, setSetlFreqData] = useState([]);
    const [setlMethodData, setSetlMethodData] = useState([]);
    const [rebateMeasureData, setRebateMeasureData] = useState([]);
    const [rebatePayoutCondData, setRebatePayoutCondData] = useState([]);
    const [rebateCategoryData, setRebateCategoryData] = useState([]);

    const [selectedRebateCategory, setSelectedRebateCategory] = useState('');
    const [dropdownChange, setDropdownChange] = useState([]);
    const [selectedRegionData, setSelectedRegionData] = useState([]);
    const [totalCountlst, settotalCountlst] = useState(0);
    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    const [showSelectedRegionpopup, setShowSelectedRegionpopup] = useState(false);
    const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
    const [rplSelectedProducts, setrplSelectedProducts] = useState(false);
    const [regionDataSelected, setRegionDataSelected] = useState([]);
    const [productDataSelected, setProductDataSelected] = useState([]);


    const [state, setState] = useState({ id: 0, rebateTypeID: 0, customerID: 0, salesOrgID: 0, agreementName: "", currencyID: 0, setlMethodID: 0, Customer_Ids: [], setlFreqID: 4, startDate: new Date(), endDate: new Date(SessionManage.getFiscalEndDate()), autoPostAccruals: false, analysis: false, status: 'draft', agreementId: '0', created_by: '', is_active: false, createdDate: moment(new Date()).format(SessionManage.getcurrentdateformat()), CurrencySymbol: '$', LockedByUserName: '', CustomerGroup: false })
    const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0, PaymentTermsId: '', rebateProgramLineId: 0, rebateProgramDesc: "", rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, validityFrom: defaultDate.from_date, validityTo: defaultDate.to_date, settlementFreqId: 0, settlementFreq: "", active: false, deleted: false, statusId: 0, status: "", geography: [], Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", comparisonEndDate: '', comparisonStartDate: '', comparisondateformatId: 0, Justification: "" })
    const navigate = useNavigate();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [commentsForSubmit, setCommentsForSubmit] = useState("")
    const [definitionData, setDefinitionData] = useState({})
    let wData = [0]
    const [workflowData, setWorkflowData] = useState([]);
    const [woData, setWoData] = useState('');
    const [Products, SetProducts] = React.useState([]);
    const [ProductList, SetProductList] = useState([]);
    const [OldProductList, SetOldProductList] = useState([]);
    const [FilteredProductList, SetFilteredProductList] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [showDeleteModal, toggleModal] = useState(false)
    const [rebatePayoutCond, setRebatePayoutCond] = useState('')
    const [targetmeasureData, setTargetMeasureData] = useState('');
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [productsModal, setProductsModal] = useState(false);
    const [targetedPayCond, setTargetPayCond] = useState(false);
    const [showrebateProgramLine, setshowrebateProgramLine] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [clickProduct, setclickProduct] = useState('');
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueCust, setFilterValueCust] = useState([]);
    const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);

    const [hasPaymentTerms, setHasPaymentTerms] = useState(false);
    const justificationRef = useRef(null);
    const [minDate, setminDate] = useState(null);
    const [maxDate, setmaxDate] = useState(null);

    const [workflowchart, setworkflowchart] = useState([]);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [closeModal, setCloseModal] = useState(false);
    const [closeHeaderModal, setCloseHeaderModal] = useState(false);
    const [changeHandle, setChangeHandle] = useState(false);
    const [changeHandleHeader, setChangeHandleHeader] = useState(false);
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [ToggleStatusModal1, setToggleStatusModal1] = useState(false);
    const [ToggleStatusModal2, setToggleStatusModal2] = useState(false);
    const [activeData, setActiveData] = useState([]);
    const [message, setMessage] = useState("");
    const [activeStatus, setactiveStatus] = useState('');
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [custChngHandler, setCustChngHandler] = useState(false);

    const [showtargettable, setShowTargetTable] = useState(false);
    const [showtargetselection, setShowTargetSelection] = useState('');
    const [targetselectionLabel, setTargetSelectionLabel] = useState('');
    const [open, setOpen] = useState(true);
    const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [SelectedProducts, SetSelectedProducts] = useState([]);
    const [ExcludedSelectedProducts, SetExcludedSelectedProducts] = useState([]);
    const [products, setProducts] = useState([0])
    const [color, setColor] = useState('green');
    const [icon, setIcon] = useState('fa fa-plus');
    const [expandAccurals, setExpandAccruals] = useState('')
    const [filteringvalue, setfilteringvalue] = useState('');
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const [customercheckAll, setCustomercheckAll] = useState(false);
    const [customerSearch, setCustomerSearch] = useState('');
    const [customerGroupSearch, setCustomerGroupSearch] = useState('');


    const [accrualPeriodValue, setAccrualPeriodValue] = useState([]);
    const [accrualCustomerValue, setAccrualCustomerValue] = useState([]);
    const [customerInvoiceValue, setCustomerInvoiceValue] = useState([]);
    const [invoicePeriodValue, setInvoicePeriodValue] = useState([]);

    const [includeAchievementPolicy, setIncludeAchievementPolicy] = useState(false);
    const [selectedAchievementPolicy, setSelectedAchievementPolicy] = useState(false);
    const [achivementpolicyData, setachivementpolicyData] = useState([]);

    const [custFilterCol, setCustomerFilterCol] = useState('');
    const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
    const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
    const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
    const [data, setData] = useState([]);
    let includeSubDivideTarget = SessionManage.getIncludeSubDivideTarget();
    let includeOutstandingAmount = SessionManage.getIncludeOutstandingAmount();
    const [selectedSettlementFreq, setSelectedSettlementFreq] = useState('');
    const [rebateIcon, setRebateIcon] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [jsonList, setJsonList] = useState({});
    const [displayDownloadError, setDisplayDownloadError] = useState(false);
    const [filePath, setFilePath] = useState('');
    const location = useLocation();
    const [revised, setRevised] = useState(false);
    const [validityFromEditable, setValidityFromEditable] = useState(false);
    const [validityToEditable, setValidityToEditable] = useState(false);
    const [showAccrualDialogForRevise, setShowAccrualDialogForRevise] = useState(false);
    const columnList = [

        { "label": "Rebates.RebateProgramId", "key": "RebateProgramLineId", "value": true },

        { "label": "Rebates.RebateProgramDesc", "key": "RebateProgram_Desc", "value": true },

        { "label": "Rebates.RebateType", "key": "Rebate_Type", "value": true },

        { "label": "Rebate Category", "key": "RebateCategory", "value": true },

        { "label": "Rebates.ValidFrom", "key": "Validity_From", "value": true },

        { "label": "Rebates.ValidTo", "key": "Validity_To", "value": true },

        { "label": "Rebates.SettlementFreq", "key": "Settlement_Freq", "value": true },

        { "label": "Rebates.Status", "key": "Status", "value": true },

        { "label": "Rebates.IsActive", "key": "Active", "value": true }
    ]

    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);
    const [showProductLoadSpinner, setShowProductLoadSpinner] = useState(false);
    const [subDivideTargetCurrentPeriod, setSubDivideTargetCurrentPeriod] = useState();
    const [subDivideCurrentPeriodNo, setSubDivideTargetCurrentPeriodNo] = useState(0);

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    // New States For Customer Popup Checkbox
    const [customerCheckAll, setCustomerCheckAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);

    const [filterValueDict, setFilterValueDict] = useState({
        RebateProgramLineId: '',
        RebateProgram_Desc: '',
        Validity_From: null,
        Validity_To: null,
        filterCol: null,

    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        RebateCategory: '',
        Settlement_Freq: '',
        Rebate_Type: '',
        Active: '',
        Status: ''

    });

    const [filterValueCustomer, setFilterValueCustomer] = useState({
        Name: '',
        Label: '',
        Station: '',
        State: '',
        Zone: '',

    });
    const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
        label: '',
    });



    const activeOption = [
        { Name: "Active", Id: "1", ischecked: false },
        { Name: "In-Active", Id: "0", ischecked: false },

    ]
    const [daysList, setDayList] = useState([10, 20, 25, 30]);



    let initial_value = [
        { id: 0, product: "", Targetcondition: "", Measure_Value: "", Target_Measure: "", Rebate: "", Rebate_Value: "", NoOfDays: daysList[0] },
    ]

    const [qtPayoutList, setQtPayoutList] = useState([])
    const [hfPayoutList, setHfPayoutList] = useState([])
    const [monthlyPayoutList, setMonthlyPayoutList] = useState([])

    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [VolumeRebateList, setVolumeRebateList] = useState([{
        Targetcondition: "",
        measureValues: [],
        Slab: "Slab1",
        Condition: ""
    },]);

    const [ProductTargetList, setProductTargetList] = useState([
        {
            ProductTargets: [{
                Slab: "Slab1",
                Rebate: '',
                Rebatevalue: '',
                Target_Measure: 'InvoicePrice',
                IncludeEligibilityCheck: false,
                IncludeAccrualDistribution: false,
            }],
            Products: [],
            Pdtset: 1

        }
    ])
    const [isNew, setIsNew] = useState(true)
    const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
    const [selectedType, setSelectedType] = useState('Attribute');
    const [ExcludedProducts, SetExcludedProducts] = useState([]);
    const [ExcludedProductList, SetExcludedProductList] = useState([]);
    const [FiltedProducts, SetFiltedProducts] = useState([]);
    const [selectedVolumeRebateProduct, setSelectedVolumeRebateProduct] = useState([]);
    const [FetchedProd, setFetchedProd] = useState([]);
    const [accrualHistoryData, setAccrualHistoryData] = useState([])
    const [showspinner, setshowspinner] = useState('');
    const [showspinneraccural, setshowspinneraccural] = useState('');
    const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
    const [defaultCurrency, setdefaultCurrency] = useState('');
    const [disableSubDivideTarget, setdisableSubDivideTarget] = useState(false);
    let rebateDefinitionDatail = []
    let rebateApproved = 0;
    let disableHeaderField = false;
    let disableProgramLineField = false;
    let copyID = '';

    const [showExcludeModal, setShowExcludeModal] = useState(false);
    const [inputField, setInputField] = useState({
        addproductdata: true,
        excludeproductdata: false
    })

    const [showpopupcontnent, setshowpopupcontnent] = useState({
        value: 1
    })
    const [showCreateEditRebatePgm, setShowCreateEditRebatePgm] = useState(true)
    const [date, setDate] = useState([new Date(), new Date(year + 1, month, day)]);
    const [activeRebate, setActiveRebate] = useState(true)
    const [rebateNumber, setRebateNumber] = useState(0)
    const [rebateProgramLine, setrebateProgramLine] = useState([])
    const [hasConfirmProgram, setHasConfirmProgram] = useState([])
    const [isProgramLineConfirmed, setIsProgramLineConfirmed] = useState(0)
    const [showApprovedModal, setShowApprovedModal] = useState(false)
    const [commentsForApproved, setCommentsForApproved] = useState("")
    const [showDeniedModal, setShowDeniedModal] = useState(false)
    const [commentsForDenied, setCommentsForDenied] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [SalesLevelData, setSalesLevelData] = useState([])
    let loggedInUser = SessionManage.getuserid();
    const [activerebateProgramLine, setActiveRebateProgramLine] = useState([])
    const [testdata, setTestData] = useState([]);
    const [testdataAccruals, setTestDataAccruals] = useState([]);
    const [loadedFrom, setLoadedFrom] = useState('');
    const [hideRpl, setHideRpl] = useState(false);
    const [showrebateprogram, setshowrebateprogram] = useState('');
    const [showproductpopup, setshowproductpopup] = useState('');
    const [showWorkflow, setshowWorkflow] = useState('');
    const [showAccuralWorkflow, setshowAccuralWorkflow] = useState('');
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showAccuralHistory, setshowAccuralHistory] = useState('');
    const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
    const [showLoadMore, setshowLoadMore] = useState('');
    const [productname, setProductName] = useState('');
    const [workflowApprovers, setWorkflowApprovers] = useState([])
    const [workflowLevelCount, setWorkflowLevelCount] = useState('');
    const [firstApprover, setFirstApprover] = useState([]);
    const [secondApprover, setSecondApprover] = useState([]);
    const [thirdApprover, setThirdApprover] = useState([]);
    const [CurrentWorkflowLevel, setCurrentWorkflowLevel] = useState('');
    const [currentLevel1, setCurrentLevel1] = useState("{`${classes['square-levels']} `}");
    const [currentLevel2, setCurrentLevel2] = useState("{`${classes['square-levels']}  `}");
    const [currentLevel3, setCurrentLevel3] = useState("{`${classes['square-levels']} `}");
    const [saveProgramLine, setSaveProgramLine] = useState(false);
    const [accrualCalcTypeData, setAccrualCalcTypeData] = useState([]);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [customerChangesValue, setCustomerChangesValue] = useState([]);
    const [regionchangesvalue, setregionchangesvalue] = useState([]);
    const [whoCalled, setWhoCalled] = useState("");
    const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
    const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
    const [conditionValue, setConditionValue] = useState('AND');


    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedOptionsSetlMethod, setSelectedOptionsSetlMethod] = useState("");
    const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState('');
    const [selectedOptionsCurrency, setSelectedOptionsCurrency] = useState('INR');
    const [selectedOptionsautoPostAccruals, setSelectedOptionsautoPostAccruals] = useState('No');
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
    const [selectedOptionsSettlFreq, setSelectedOptionsSettlFreq] = useState('');
    const [selectedOptionsRebateMeasure, setSelectedOptionsRebateMeasure] = useState('');
    const [selectedOptionsRebateMeasureDisabled, setSelectedOptionsRebateMeasureDisabled] = useState('');
    const [selectedOptionsAccrualCal, setSelectedOptionsAccrualCal] = useState('');
    const [selectedOptionsPayout, setSelectedOptionsPayout] = useState();
    const [selectedOptionsRegion, setSelectedOptionsRegion] = useState([]);
    const [selectedOptionsCustomer, setSelectedOptionsCustomer] = useState([]);
    const [selectedOptionsCustomerInvoice, setSelectedOptionsCustomerInvoice] = useState([]);

    const [paymentTermsId, setPaymentTermsId] = useState()
    const [option, setOption] = useState([]);
    const [rebateTypeValue, setRebateTypeValue] = useState('')
    const [comparisonStartDate, setComparisonStartDate] = useState('')
    const [comparisonEndDate, setComparisonEndDate] = useState('')
    const [comparisonPeriod, setComparisonPeriod] = useState('')
    const [comparisonFormatData, setComparisonFormatData] = useState([])
    const [comparisonDateEditable, setComparisonDateEditable] = useState(false)
    const [selectedOptionsCompDateFormat, setSelectedOptionsCompDateFormat] = useState();
    const [comparisonDateFormatName, setComparisonDateFormatName] = useState();
    const [measureValueLabel, setmeasureValueLabel] = useState("");

    const [defineMinThreshold, setDefineMinThreshold] = useState(false);
    const [subdivideTargetid, setSubdivideTargetid] = useState();
    const [hasSubdivideTarget, setHasSubdivideTarget] = useState(false);
    const [minThresholdValue, setMinThresholdValue] = useState(0);
    const [minThresholdPayout, setMinThresholdPayout] = useState(0);
    const [showregionpopup, setshowregionpopup] = useState('');
    const [paymentTermsList, sePaymentTermsList] = useState([]);
    const [noRegion, setNoRegion] = useState(false);
    const [checkCustomerGroup, setcheckCustomerGroup] = useState(false);
    const [groupCustomerData, setGroupCustomerData] = useState([]);
    const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState('');
    const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
    const [showSelectedCustomerGroupPopup, setShowSelectedCustomerGroupPopup] = useState(false);
    const [groupCustomerChanged, setGroupCustomerChanged] = useState(false);
    const [customerChanged, setCustomerChanged] = useState(false);
    const fileReader = new FileReader();



    const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();

    const [dateRange, setDateRange] = useState([]);
    const [fyStartDate, setFyStartDate] = useState(new Date());
    const [fyEndDate, setFyEndDate] = useState(new Date());

    const [zoneOptions, setZoneOptions] = useState([]);
    const [measureOptions, setMeasureOptions] = useState([]);
    const [SelectedRebateMeasureOptions, setSelectedRebateMeasureOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [stationOptions, setStationOptions] = useState([]);

    const [showZonePopup, setShowZonePopup] = useState(false);
    const [showStatePopup, setShowStatePopup] = useState(false);
    const [showStationPopup, setShowStationPopup] = useState(false);

    const [showSelectedZonepopup, setShowSelectedZonepopup] = useState(false);
    const [showSelectedStatepopup, setShowSelectedStatepopup] = useState(false);
    const [showSelectedStationpopup, setShowSelectedStationpopup] = useState(false);

    const [zoneDataSelected, setZoneDataSelected] = useState([]);
    const [stateDataSelected, setStateDataSelected] = useState([]);
    const [stationDataSelected, setStationDataSelected] = useState([]);

    const [zoneIDs, setZoneIDs] = useState('');
    const [stateIDs, setStateIDs] = useState('');
    const [stationIDs, setStationIDs] = useState('');

    const [zoneNames, setZoneNames] = useState('');
    const [stateNames, setStateNames] = useState('');
    const [stationNames, setStationNames] = useState('');

    const [zonecheckAll, setZonecheckAll] = useState(false);
    const [statecheckAll, setStatecheckAll] = useState(false);
    const [stationcheckAll, setStationcheckAll] = useState(false);

    const [fileSizeExceeded, setFileSizeExceeded] = React.useState(false);
    const [file, setFile] = useState([]);
    const maxFileSize = 2 * 1024 * 1024; // 2MB Max File Size
    const [selectedAttachment, setSelectedAttachment] = useState();

    const [showRebateMeasurePopup, setShowRebateMeasurePopup] = useState(false);


    const [slabIndex, SetSlabIndex] = useState(-1);




    let disableField = false;


    const clickShowPopup = (val) => {
        setsidebarwidth("70vw");

        if (val == 'zone') {
            setShowZonePopup(true);
        }
        else if (val == 'state') {
            setShowStatePopup(true);
        }
        else if (val == 'station') {
            setShowStationPopup(true);
        }
        else if (val == 'rebatemeasure') {
            setShowRebateMeasurePopup(true);
        }


    }


    const onClickOk = (popupOpen) => {
        if (popupOpen == 'zone') {
            setShowZonePopup(false);
            OtherDataBasedOnSelectedId(zoneIDs, 'Zone')
            CustomerDataBasedOnSelectedId(zoneNames, 'Zone')
        }
        else if (popupOpen == 'state') {
            setShowStatePopup(false);
            OtherDataBasedOnSelectedId(stateIDs, 'State')
            CustomerDataBasedOnSelectedId(stateNames, 'State')
        }
        else if (popupOpen == 'station') {
            setShowStationPopup(false);
            OtherDataBasedOnSelectedId(stationIDs, 'Station')
            CustomerDataBasedOnSelectedId(stationNames, 'Station')
        }
        else if (popupOpen == 'rebatemeasure') {
            setShowRebateMeasurePopup(false);
            UpDateTierTable()
        }

    }


    const onClickClose = (popupClose) => {
        if (popupClose == 'zone') {
            setShowZonePopup(false);
        }
        else if (popupClose == 'state') {
            setShowStatePopup(false);
        }
        else if (popupClose == 'station') {
            setShowStationPopup(false);
        }
        else if (popupClose == 'rebatemeasure') {
            setShowRebateMeasurePopup(false);
        }
    }

    const onClickCloseSelectedPopup = (popupClose) => {
        if (popupClose == 'zone') {
            setShowSelectedZonepopup(false);
        }
        else if (popupClose == 'state') {
            setShowSelectedStatepopup(false);
        }
        else if (popupClose == 'station') {
            setShowSelectedStationpopup(false);
        }
    }


    const clickSelectedShowPopup = (val) => {
        if (val == 'zone') {
            let lstcopy = zoneOptions.filter((e) => e.Selected === true).map(item => {
                return { ...item }
            })
            setZoneDataSelected([...lstcopy]);
            setShowZonePopup(false);
            setShowSelectedZonepopup(true);
            setsidebarwidth("70vw");
        }
        else if (val == 'state') {
            let lstcopy = stateOptions.filter((e) => e.Selected === true).map(item => {
                return { ...item }
            })
            setStateDataSelected([...lstcopy]);
            setShowStatePopup(false);
            setShowSelectedStatepopup(true);
            setsidebarwidth("70vw");
        }
        else if (val == 'station') {
            let lstcopy = stationOptions.filter((e) => e.Selected === true).map(item => {
                return { ...item }
            })
            setStationDataSelected([...lstcopy]);
            setShowStationPopup(false);
            setShowSelectedStationpopup(true);
            setsidebarwidth("70vw");
        }
    }

    const AttachmentFile = (event) => {
        const file1 = event.target.files[0];

        console.log('Selected file:', file1);
        console.log('File size:', file1?.size);
        console.log('Max file size:', maxFileSize);

        setSelectedAttachment(file1);
        setIsFilePicked(true);

        if (file1 !== undefined && file1 !== null) {
            if (file1.size > maxFileSize) {
                console.log('File size exceeds the limit');
                event.target.value = null;
                setFileSizeExceeded(true);
                return;
            } else {
                console.log('File size is within the limit');
                setFileSizeExceeded(false);
                setFile([...file, ...event.target.files]);
            }
        }
    };


    const manageSelectedPopupData = (event, label, value, type) => {

        if (type === 'zone') {
            let updatedZones = zoneOptions.map((zone) =>
                zone.value === value ? { ...zone, Selected: event.target.checked } : zone
            );

            let selectedZoneIDs = updatedZones
                .filter((zone) => zone.Selected === true)
                .map((zone) => zone.value.toString())
                .join(',');
            let selectedZoneNames = updatedZones
                .filter((zone) => zone.Selected === true)
                .map((zone) => zone.label.toString())
                .join(',');
            let selectedIDsList = selectedZoneIDs.split(',');
            setState({ ...state, ['zoneIDs']: selectedIDsList })
            setZoneIDs(selectedZoneIDs);
            setZoneNames(selectedZoneNames);
            setZoneOptions(updatedZones);
        }

        if (type === 'state') {
            let updatedStates = stateOptions.map((state) =>
                state.value === value ? { ...state, Selected: event.target.checked } : state
            );
            let selectedstateIDs = updatedStates
                .filter((state) => state.Selected === true)
                .map((state) => state.value.toString())
                .join(',');
            let selectedstateNames = updatedStates
                .filter((state) => state.Selected === true)
                .map((state) => state.label.toString())
                .join(',');
            let selectedIDsList = selectedstateIDs.split(',');
            setState({ ...state, ['stateIDs']: selectedIDsList })
            setStateIDs(selectedstateIDs);
            setStateNames(selectedstateNames);
            setStateOptions(updatedStates);
        }
        if (type === 'station') {
            let updatedStations = stationOptions.map((station) =>
                station.value === value ? { ...station, Selected: event.target.checked } : station
            );
            let selectedstationIDs = updatedStations
                .filter((station) => station.Selected === true)
                .map((station) => station.value.toString())
                .join(',');
            let selectedstationNames = updatedStations
                .filter((station) => station.Selected === true)
                .map((station) => station.label.toString())
                .join(',');
            let selectedIDsList = selectedstationIDs.split(',');
            setState({ ...state, ['stationIDs']: selectedIDsList })
            setStationIDs(selectedstationIDs);
            setStationNames(selectedstationNames);
            setStationOptions(updatedStations);
        }


    }

    console.log("19/3", noRegion, isLocked, lockedBy)


    const manageSelectedMeasurePopupData = (event, zone, type) => {
        const selectedOption = event.target.value;
        const isChecked = event.target.checked;

        // Update the measureOptions array based on the selected option
        const updatedOptions = measureOptions.map(zone => {
            if (zone.Name === selectedOption) {

                return {
                    ...zone,
                    Selected: !zone.Selected // Toggle the Selected property of the selected option
                };
            } else if (selectedOption === 'measurevalue_days') {

                return {
                    ...zone,
                    Selected: false // Unselect other options if "days" is selected
                };
            } else if (zone.Name === 'measurevalue_days') {
                setSelectedRebateMeasureOptions(SelectedRebateMeasureOptions.filter(zone => zone.label !== selectedOption));
                return {
                    ...zone,
                    Selected: false // Unselect "days" if any other option is selected
                };
            } else {

                return zone; // Leave other options unchanged
            }
        });


        if (isChecked) {
            let updatedZone = { ...zone };
            if (zone.Name === "measurevalue_qtygrowth" || zone.Name === "measurevalue_qty") {
                updatedZone.symbol = "Unit";
            } else if (zone.Name === "measurevalue_contribution" || zone.Name === "measurevalue_revgrowth") {
                updatedZone.symbol = "%";
            } else if (zone.Name === "measurevalue_revenue") {
                updatedZone.symbol = "Amount";
            } else {
                updatedZone.symbol = "";
            }

            if (zone.Name !== 'measurevalue_days') {
                setSelectedRebateMeasureOptions([...SelectedRebateMeasureOptions, updatedZone]);
            } else {
                setSelectedRebateMeasureOptions([updatedZone]);
                setmeasureValueLabel("");
            }
        } else {
            setSelectedRebateMeasureOptions(SelectedRebateMeasureOptions.filter(option => option.Name !== selectedOption));
        }
        // Update the state with the new measureOptions array
        setMeasureOptions(updatedOptions);

    };


    const checkAllForPopups = (type, event) => {
        let checkAllValue = event.target.checked;

        if (type === 'zone') {
            setZonecheckAll(checkAllValue);

            let updatedOptions = zoneOptions.map((option) => ({
                ...option,
                Selected: checkAllValue,
            }));
            setZoneOptions(updatedOptions);
        } else if (type === 'state') {
            setStatecheckAll(checkAllValue);

            let updatedOptions = stateOptions.map((option) => ({
                ...option,
                Selected: checkAllValue,
            }));
            setStateOptions(updatedOptions);
        } else if (type === 'station') {
            setStationcheckAll(checkAllValue);

            let updatedOptions = stationOptions.map((option) => ({
                ...option,
                Selected: checkAllValue,
            }));
            setStationOptions(updatedOptions);
        }
        else if (type === 'rebatemeasure') {
            // setStationcheckAll(checkAllValue);

            // let updatedOptions = stationOptions.map((option) => ({
            //     ...option,
            //     Selected: checkAllValue,
            // }));
            // setStationOptions(updatedOptions);
        }
    };


    const managePolicyPopup = () => {
        setshowrebateprogram(true);
    }


    const GetDateRangeAndProducts = (method) => {
        Service.getdata(method).then((res) => {
            if (Service.checkUndefined(res) === true) {
                if (Service.checkDataUndefined(res.data) == true) {
                    setDateRange(res.data.lstDateRange);
                    // setAllProducts(res.data.lstProduct);
                    // setLstMonth(res.data.lstMonth);


                    var defDateRange = res.data.lstDateRange.find(x => x.defaultValue === true)
                    if (typeof defDateRange !== 'undefined' && defDateRange !== null) {
                        setselectedOptionsDateRange(defDateRange.value);

                    }
                }
            }
        });
    };

    console.log("5/3v", VolumeRebateList)
    console.log("5/3s", SelectedRebateMeasureOptions)
    console.log("5/3m", measureOptions)



    const OnChangeDateRange = (e) => {
        let dateRangeArray = e.value.split('~');

        const date1 = moment(dateRangeArray[0]).format("DD/MM/YYYY");
        const date2 = moment(dateRangeArray[1]).format("DD/MM/YYYY");
        setFyStartDate(new Date(moment(dateRangeArray[0])));
        setFyEndDate(new Date(moment(dateRangeArray[1])));

        setminDate(date1 ? new Date(date1) : new Date()); // Convert fyStartDate to Date object

        //the day to be in the range of 1-31, and the year to be a full year number. When it encounters '31' as the day value, it tries to create a date for March 31. However, March only has 31 days in some years (such as 2022), not all years (like 2023), leading to an invalid date.

        const [day, month, year] = date2.split('/'); // Split the string by '/'
        const parsedDate = new Date(`${year}-${month}-${day}`);
        setmaxDate(parsedDate ? new Date(parsedDate) : new Date());

        setselectedOptionsDateRange(e.value);
        state.FiscalYear = e.label;
    };

    const OtherDataBasedOnSelectedId = (id, type) => {
        Service.getDataBasedOnId('SDCommon/GetRegionById', id, type).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                if (type == "Zone") {
                    console.log('8/2', res.data)
                    setStateOptions(res.data.State);
                    setStationOptions(res.data.Station)
                }
                if (type == "State") {
                    console.log('8/2', res.data)
                    setZoneOptions(res.data.Zone);
                    setStationOptions(res.data.Station)
                }
                if (type == "Station") {
                    console.log('8/2', res.data)
                    setStateOptions(res.data.State);
                    setZoneOptions(res.data.Zone)
                }
            }
        });
    }

    const CustomerDataBasedOnSelectedId = (id, type) => {
        Service.getDataBasedOnId('SDCommon/GetAllCustomer', id, type).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setCustomerData(res.data)
            }
        });
    }

    {
        useEffect(() => {
            //debugger
            sessionStorage.setItem('ids', props.id);

            if (props.name.toLowerCase() == 'fromscheme') {
                if (props.id == '0') {
                    getUserPermission('/scheme-program/0');
                } else {
                    sessionStorage.setItem('ids', props.id);
                    getUserPermission('/new-scheme/0/fromScheme/0');
                }
            }
            if (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted') {
                getUserPermission('/SchemeList/pending');
            }
            GetSubdivideTargetType();
            setshowrebateprogram(false);
            setshowproductpopup(false);
            setshowAccuralWorkflow(false);
            setshowWorkflow(false);
            setshowAccuralHistory(false);
            setshowspinner(true)
            setclickProduct(false);
            setshowspinneraccural(true)
            setshowspinnerworkflow(true)
            setshowrebateProgramLine(false)
            getRebateTypeData("rebatetype")
            getCurrencyData("currency")
            getCustomerData("Customer/GetIndividualCustomersWithoutGroup")
            getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue)
            getSetlFreqData("settlementfrequency")
            getSetlMethodData("settlementmethod")
            getAccrualCalcType("AccrualCalcType")
            getmeasuretype();
            AddProductHandler()
            getComparisonFormatData("ComparisonDateFormat")
            getColumnVisibilityList()
            getColVisibilityListData()
            getAllAchivementPolicy("SDCommon/GetAllAchivementPolicy");

            //Fiscal Year 
            GetDateRangeAndProducts("DashBoard/GetFiscalYear");


            //API Call - Get Distinct Zones
            Service.getAllZones().then(result => {

                setZoneOptions(result);
            }).catch(error => {
                // Handle errors here
                console.error(error);
            });


            //API Call - Get Distinct States
            Service.getAllStates().then(result => {
                setStateOptions(result);
            }).catch(error => {
                // Handle errors here
                console.error(error);
            });


            //API Call - Get Distinct Stations
            Service.getAllStations().then(result => {
                setStationOptions(result);
            }).catch(error => {
                // Handle errors here
                console.error(error);
            });

            setColumnAscending(false);
            setSortColName("Id");

            if (props.name == "fromApproval" || props.name == "fromApprovalCompleted") {
                setHideRpl(true)
                setLoadedFrom(props.name);
                setShowCreateEditRebatePgm(false)
                getRebateProgramLineDetails("GetSchemeProgramLinesDetails", props.value, "Product Scheme")
                getdataProductSelectorByAttribute(props.value)
                setshowrebateprogram(true);
            }
            if (props.id != '0') {
                getRebateHeaderDetails("Scheme/GetSchemeHeader", props.id)
                getRebatePrgramLines("GetSchemeProgramLines/rebateid", props.id, paging.pageSize, paging.pageNumber, "Descending", "Id", [], null, "")
                getActiveRebateProgramlines('Scheme/GetApprovedActiveSchemeProgramLine', props.id)
                // getTotalAccrualAmount("Rebate/GetAccrualAmountByRebateId", props.id);
                setLoadedFrom(props.name);
                setCustChngHandler(true)
            }
            else {
                // setState({
                //     id: 0,
                //     rebateTypeID: 0,
                //     customerID: 0,
                //     salesOrgID: 0,
                //     agreementName: "",
                //     agreementId: '0',
                //     currencyID: 0,
                //     setlMethodID: 0,
                //     setlFreqID: 0,
                //     autoPostAccruals: false,
                //     analysis: false,
                //     status: 'draft',
                //     created_by: sessionStorage.getItem('name'),
                //     createdDate: moment(new Date()).format(SessionManage.getdateformat()),
                //     CurrencySymbol: '$',
                //     Customer_Ids: []
                // })
                state.id = 0;
                state.rebateTypeID = 0;
                state.customerID = 0;
                state.salesOrgID = 0;
                state.agreementName = "";
                state.agreementId = '0';
                state.currencyID = 0;
                state.setlMethodID = 0;
                state.setlFreqID = 0;
                state.autoPostAccruals = false;
                state.analysis = false;
                state.status = 'draft';
                state.created_by = SessionManage.getusername();
                debugger
                state.createdDate = moment(new Date()).format(SessionManage.getcurrentdateformat());
                state.CurrencySymbol = '$';
                state.Customer_Ids = [];
                state.FiscalYear = 0;
                disableHeaderField = false;
                setrebateProgramLine([]);
                $("#libread").html(t('Create New Product Scheme Agreement'));
                settotalCountlst(0)
                setWorkflowData([0])
                setVolumeRebateList(initial_value);
                setHasConfirmProgram([])
                setWorkflowData([0])
                SetProducts([])
                SetProductList([])
                setshowspinner(false);
                setLockedBy(loggedInUser);
                setIsLocked(true);


            }
            // window.addEventListener("scroll", isSticky);
            // return () => {
            //   window.removeEventListener("scroll", isSticky);
            // };

            window.addEventListener('beforeunload', alertUser)
            window.addEventListener('unload', handleEndConcert)
            return () => {
                window.removeEventListener('beforeunload', alertUser)
                window.removeEventListener('unload', handleEndConcert)
            }

        }, [props.id])
    }


    const getdataProductSelectorByAttribute = (programId) => {
        Service.getdataWithPagingandattributeSearchingByGroup('Scheme/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], programId).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
                console.log("15-3api", res.data.DataList)
                let prod = res.data.DataList.filter((each) => each.Selected !== undefined && each?.Selected === true)
                console.log("15-3s", prod)
            }
        });
    };

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }
    const handleEndConcert = async () => {
    }
    const fetchAllData = () => {
        getRebateHeaderDetails("Scheme/GetSchemeHeader", props.id)
    }
    const GetSubdivideTargetType = () => {
        Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setSubdivideTargetTypeList(res.data);
                }
            }
        });

        Service.getdata("PaymentTerms/GetALLPaymentTerms").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    sePaymentTermsList(res.data);
                }
            }
        });
    }

    const getUserPermission = (pathname) => {

        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                console.log("27-6", res.data)
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        //debugger
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const handleregionChanges = (e, label, id) => {
        let selected = [];
        let updateregion = [];
        const getallData = {
            Id: id,
            label: label
        }

        let olddata = [...SalesLevelData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.selected = true;
                }
                updateregion.push(i);
            })

            selected.push(getallData);
            setregionchangesvalue(selected);
            setSelectedOptionsRegion(selected);
            setSalesLevelData(updateregion);
            const geography1 = [...rebateprogrmlinedetail.geography];
            geography1.push(getallData);
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['geography']: geography1 })

        }
        else {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.selected = false;
                }
                updateregion.push(i);
            })

            selected.push(getallData);
            var index = regionchangesvalue.indexOf(selected);
            setregionchangesvalue(regionchangesvalue.filter((_, i) => i !== index))

            setSalesLevelData(updateregion);

            const geography = [...rebateprogrmlinedetail.geography];

            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['geography']: geography.filter(i => i.Id !== id) });

        }

    }


    const EligibleInvoicePopup = (e, flag) => {

        let value = e
        setRPL_ID(value);
        //  getRebateProgramLineDetails("GetSchemeProgramLinesDetails", value, "Product Scheme")
        let invoicePeriod = [];
        invoicePeriod.push({ "MeasureValue": "0", "RebatePeriod": "End Of Period", "RebateValue": "End Of Period", });
        setInvoicePeriodValue(invoicePeriod)
        setShowInvoiceModal(true)
        if (flag === "EligibleInvoice") {
            setWhoCalled("EligibleInvoice")
        }
        if (flag === "AnalyzeRebate") {
            setWhoCalled("AnalyzeRebate")
        }
    }

    const okregion = () => {
        setshowregionpopup(false);
    }

    const okCustomer = () => {
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
       if (customerChanged && state.id != 0) {
            UpdateRegionOnCustomerChange();
        }
    }
    const okSelectedCustomer = () => {
        var customerLst = customerDataSelected.filter((e) => e.Selected === false);
        let olddata = [...customerData];
        olddata.map((i) => {
            var customers = customerDataSelected.filter((e) => e.Selected === false && i.Id === e.Id);
            if (customers.length > 0) {
                i.Selected = false;
            }
        })

        setCustomerData(olddata);
        const getselected = [];
        customerDataSelected.filter((e) => e.Selected === true).map((i) => {
            getselected.push(i.Id);
        });
        setState({ ...state, ['Customer_Ids']: getselected })
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
    }



    const handleCustSelection = (e, label, id, State) => {
        let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");
        var rowData = SalesLevelData
        for (var i = 0; i < rowData.length; i++) {

            if (rowData[i].label.toLowerCase() == State.toLowerCase())
                if (checkboxes.length >= 1) {
                    rowData[i].selected = true;
                    rowData[i].avalRegion = true
                }
                else {
                    rowData[i].selected = false;
                    rowData[i].avalRegion = false
                }


        }
        setSalesLevelData(rowData)

        let selected = [];
        let update = [];
        const getallData = [...state.Customer_Ids];
        let olddata = [...customerData];
        if (checkboxes.length < 1) {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })

            getallData.push(id);
            setCustomerData(olddata);
            setState({ ...state, ['Customer_Ids']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            setState({ ...state, ['Customer_Ids']: getallData.filter(i => i !== id) })
            setCustomerData(olddata);

        }
        setCustomerChanged(true)

    }

    // const handleCustomerChanges = (e, label, id, State) => {

    //     var rowData = SalesLevelData
    //     for (var i = 0; i < rowData.length; i++) {

    //         if (rowData[i].label.toLowerCase() == State.toLowerCase())
    //             if (e.target.checked == true) {
    //                 rowData[i].selected = true;
    //                 rowData[i].avalRegion = true
    //             }
    //             else {
    //                 rowData[i].selected = false;
    //                 rowData[i].avalRegion = false
    //             }

    //     }
    //     setSalesLevelData(rowData)

    //     let selected = [];
    //     let update = [];
    //     const getallData = [...state.Customer_Ids];

    //     let olddata = [...customerData];
    //     if (e.target.checked === true) {

    //         olddata.map((i) => {
    //             if (i.Id === id) {
    //                 i.Selected = true;
    //             }
    //         })

    //         getallData.push(id);
    //         setCustomerData(olddata);
    //         setState({ ...state, ['Customer_Ids']: getallData })
    //     }
    //     else {
    //         olddata.map((i) => {
    //             if (i.Id === id) {
    //                 i.Selected = false;
    //             }
    //             update.push(i);
    //         })

    //         setState({ ...state, ['Customer_Ids']: getallData.filter(i => i !== id) })
    //         setCustomerData(olddata);
    //     }
    // }


    const handleCustomerChanges = (e, label, id, State) => {
        const isChecked = e.target.checked;
        const updatedCustomerData = customerData.map((cslist) => {
            if (cslist.Id === id) {
                return { ...cslist, Selected: isChecked };
            }
            return cslist;
        });

        setCustomerData(updatedCustomerData);

        let selectedIds = [...selectedCustomers];
        if (isChecked) {
            selectedIds.push(id);
        } else {
            selectedIds = selectedIds.filter((customerId) => customerId !== id);
        }
        setSelectedCustomers(selectedIds);

        setState({ ...state, ['Customer_Ids']: selectedIds })

        const allChecked = updatedCustomerData.every((cslist) => cslist.Selected);
        setCustomerCheckAll(allChecked);

        // Update other states as needed
        var rowData = SalesLevelData;
        for (var i = 0; i < rowData.length; i++) {
            if (rowData[i].label.toLowerCase() === State.toLowerCase()) {
                rowData[i].selected = isChecked;
                rowData[i].avalRegion = isChecked;
            }
        }
        setSalesLevelData(rowData);
        setCustomerChanged(true);
    };


    const handleCustomerSelectedChanges = (e, label, id, State) => {

        var rowData = SalesLevelData
        for (var i = 0; i < rowData.length; i++) {

            if (rowData[i].label.toLowerCase() == State.toLowerCase())
                if (e.target.checked == true) {
                    rowData[i].selected = true;
                    rowData[i].avalRegion = true;
                }
                else {
                    rowData[i].selected = false;
                    rowData[i].avalRegion = false;
                }

        }
        setSalesLevelData(rowData)

        let lists = [];

        let olddata = customerDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })
            //   setCustomerDataSelected(lists)

        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setCustomerDataSelected(lists)

    }
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    // const isSticky = () => {
    //   try {
    //     const scrollTop = window.scrollY;
    //     const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    //     setSticky(stickyClass);
    //     const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    //     var divtop1 = document.getElementById("table-RPL");
    //     setdivtop(divtop1.offsetTop + scrollTopActual)
    //   } catch { }
    // };

    const class_thead = `text-white rt-thead  ${sticky}`;
    const ExporttoCSV = () => {

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        Service.getPostdatabySchemeIdSearch("GetSchemeProgramLines/rebateid", props.id, 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            console.log(res, 'api-resp');
            if (res !== undefined && res.data !== null && res.data !== undefined) {
                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }

                let filteredCsvList = operatedList.map(item => {
                    let newItem = {};
                    Object.keys(item).forEach(key => {
                        if (!hideColumn.includes(key)) {
                            if (key === 'Validity_From' || key === 'Validity_To') {
                                newItem[key] = moment(item[key]).format(dateFormat);
                            } else if (key === 'ActiveLabel') {
                                // Check if Status is Approved
                                if (item.Status === 'Approved') {
                                    // Set ActiveLabel based on your logic
                                    newItem[key] = item.ActiveLabel === 'Active' ? 'Active' : 'In-Active';
                                } else {
                                    // If Status is not Approved, set ActiveLabel to an empty value
                                    newItem[key] = '';
                                }
                            } else {
                                newItem[key] = item[key];
                            }
                        }
                    });
                    return newItem;
                });

                let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                console.log(filteredCsvList, "filtered");

                setCsvList(filteredCsvList);
                setHeaders(filteredHeaders);

                setTimeout(() => {
                    toast.success('Exported Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    }



    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getCustomerData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setCustomerData(res.data)
                            setData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getSalesOfficeDataNew = (method) => {
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data
                            if (rowData.length > 0) {
                                for (var i = 0; i < rowData.length; i++) {
                                    for (var j = 0; j < customerData.length; j++) {
                                        try {
                                            if (rowData[i].label.toLowerCase() == customerData[j].State.toLowerCase()) {
                                                if (customerData[j].Selected == true) {
                                                    rowData[i].selected = true;
                                                }
                                            }
                                        } catch { }
                                    }
                                }
                                rowData.map((each) => {
                                    if (each.selected == true) {
                                        each['avalRegion'] = true
                                    }
                                    else {
                                        each['avalRegion'] = false
                                    }
                                })
                                setSalesLevelData(rowData)
                            }

                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }

    const getSalesOfficeDatabyGroup = (method, id) => {
        let rowData = [];
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                            setCustomerData(rowData)
                        }
                    }
                }
            }
        })

        if (SalesLevelData.length == 0) {
            Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {
                                let sData = res.data
                                if (sData.length > 0) {
                                    for (var i = 0; i < sData.length; i++) {
                                        for (var j = 0; j < rowData.length; j++) {
                                            try {
                                                if (sData[i].label.toLowerCase() == rowData[j].State.toLowerCase())
                                                    if (rowData[j].Selected == true) {
                                                        sData[i].selected = true;
                                                    }
                                            } catch { }
                                        }
                                    }
                                    sData.map((each) => {
                                        if (each.selected == true) {
                                            each['avalRegion'] = true
                                        }
                                        else {
                                            each['avalRegion'] = false
                                        }
                                    })
                                    setSalesLevelData(sData)
                                }
                                else {
                                    setSalesLevelData([])
                                }
                            }
                        }
                    }
                }
            });
        }
        else {
            setSalesLevelData(SalesLevelData)
        }
    }


    const getCurrencyData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            let currencyObj = res.data.find((obj) => obj.label === "INR");
                            setCurrencyData(res.data)
                            console.log(res.data, 'currency-d')
                            if (currencyObj) {
                                let currentState = state;
                                currentState.currencyID = parseInt(currencyObj.value);
                                setState(currentState);
                            }
                            console.log('5/2', state);
                        }
                    }
                }
            }

        });
    }

    const getSetlMethodData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        let creditNoteObj = res.data.find((obj) => obj.label === "Credit Note");
                        console.log("21/3", res.data)
                        setSetlMethodData(res.data);
                        if (creditNoteObj) {
                            let currentState = state;
                            currentState.setlMethodID = parseInt(creditNoteObj.value);
                            setSelectedOptionsSetlMethod(creditNoteObj.label)
                            setState({ ...state, ['setlMethodID']: creditNoteObj.value })
                        }

                    }
                }
            }

        });
    }

    console.log('20-3', state)
    const getSetlFreqData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            console.log("19/3", res.data)
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].Name === "End Of Period") {
                                    setSelectedOptionsSetlFreq(res.data[i].Id)
                                    setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['settlementFreqId']: res.data[i].Id })

                                    setState({ ...state, ['setlFreqID']: res.data[i].Id })
                                }
                            }
                        }
                    }
                }
            }

        });
    }

    const getAllAchivementPolicy = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setachivementpolicyData(res.data)
                            console.log(res.data, "Data achivementpolicyData")
                        }
                    }
                }
            }

        });
    }


    const getRebateMeasureData = (method, Id, flag, selectedpolicytype = null) => {
        Service.getdatabyId(method, Id).then((res) => {
            if (selectedpolicytype === 'Engine Oil Scheme') {
                if (res.data[0].Name === 'Quantity') {
                    setmeasureValueLabel('Liters');
                    return;
                }
            }
            console.log("I am here getrebate measure adata")
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateMeasureData(res.data)
                            // if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Revenue') {
                            //     setSelectedOptionsRebateMeasure(res.data[0].Id)
                            // }
                            if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Growth in Qty (Abs)') {
                                setmeasureValueLabel('Unit');
                            } else if (res.data[0].Name === 'Revenue' || res.data[0].Name === 'Growth in Rev (Abs)') {
                                setmeasureValueLabel('Amount')
                            } else if (res.data[0].Name === 'Growth in Qty (%)' || res.data[0].Name === 'Growth in Rev (%)') {
                                setmeasureValueLabel('%')
                            }
                            // if (flag === 'RebateType' && selectedPolicyType !== "Engine Oil Scheme") {
                            //     res.data.map((i) => {
                            //         if (i.Name === 'Growth in Qty (%)' || i.Name === 'Growth in Qty (Abs)') {
                            //             if (i.Name === 'Growth in Qty (%)') {
                            //                 setSelectedOptionsRebateMeasure(i.Id)
                            //                 setmeasureValueLabel("%")
                            //             }
                            //         }
                            //         if (i.Name === 'Growth in Rev (%)' || i.Name === 'Growth in Rev (Abs)') {
                            //             if (i.Name === 'Growth in Rev (%)') {
                            //                 setSelectedOptionsRebateMeasure(i.Id)
                            //                 setmeasureValueLabel("%")
                            //             }
                            //         }
                            //     })
                            // }
                        }
                    }
                }
            }
        });
    }

    const getRebatePayoutData = (method, Id) => {
        Service.getdatabyId(method, Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebatePayoutCondData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getrebateCategoryData = () => {

        Service.getdatabyId('Rebate/GetRebateCategory', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    if (res.data !== 'null' && res.data !== 'undefined') {
                        setRebateCategoryData(res.data)
                    }

                }
            }

        });
    }

    console.log("20-3rpl", rebateprogrmlinedetail)
    console.log("20-3sf", selectedSettlementFreq)
    console.log("20-3sf", selectedOptionsSetlMethod)

    const getRebateHeaderDetails = (method, id) => {

        Service.LockUnLockEntity('Configurationprop/LockEntity', "Product Scheme", id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {

                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                                    // ////debugger
                                    let rData = res.data[0];
                                    console.log("19-3header", rData)
                                    setCurrentFiscalYear(rData.FiscalYear);
                                    //  if (rData.CustomerGroup == true) {
                                    // /    getSalesOfficeDatabyGroup("Rebate/GetCustomerBasedOnGroupId", rData.Id)
                                    //  }
                                    setState({
                                        id: rData.Id,
                                        customerID: rData.Customer_Id,
                                        agreementId: rData.Rebate_id,
                                        agreementName: rData.RebateName,
                                        currencyID: rData.Currency_Id,
                                        setlMethodID: rData.Settlement_method_Id,
                                        setlFreqID: rData.Settlement_Frequency_Id,
                                        startDate: moment(rData.Validity_From).toDate(),
                                        endDate: moment(rData.Validity_To).toDate(),
                                        autoPostAccruals: rData.Auto_Post_Accruals,
                                        analysis: rData.analysis,
                                        status: rData.Status,
                                        created_by: rData.CreatedBy,
                                        createdDate: moment(rData.CreatedDate).format(SessionManage.getcurrentdateformat()),
                                        CurrencySymbol: rData.CurrencySymbol,
                                        LockedByUserName: rData.LockedByUserName,
                                        Customer_Ids: rData.Customer_Ids,
                                        CustomerGroup: rData.CustomerGroup,
                                        FiscalYear: rData.FiscalYear,
                                        'Agreementtype': 'Product Scheme',
                                    })
                                    setLockedBy(rData.LockedBy);
                                    setIsLocked(rData.IsLocked);
                                    setselectedOptionsDateRange(rData.FiscalYear)
                                    setFyStartDate(moment(rData.Validity_From).toDate());
                                    setFyEndDate(moment(rData.Validity_To).toDate());
                                    setSelectedSettlementFreq(rData.Settlement_Frequency_Id)?.toString()
                                    setSelectedCustomers(rData.Customer_Ids)

                                    AutoPostAccrualData.map((item) => {
                                        if (item.value == rData.Auto_Post_Accruals) {
                                            setSelectedOptionsautoPostAccruals(item.label);
                                        }

                                    });
                                    if ((rData.IsLocked === true && rData.LockedBy.toString() !== loggedInUser)) {
                                        $("#libread").html(rData.Rebate_id + " " + `Locked By ${rData.LockedByUserName}`);
                                    }
                                    else {
                                        $("#libread").html(rData.Rebate_id);
                                    }
                                    setSalesOrgData({
                                        id: rData.Sales_org_Id,
                                        name: rData.Sales_org_Label
                                    })

                                    if (props.name != '' && props.name != undefined) {
                                        if (props.name.toLocaleLowerCase() == 'fromapproval') {
                                            $("#headertxt").html('Scheme Approval- Pending Action');
                                            setUpdateNav("/SchemeList/pending")
                                        }
                                        else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                                            setUpdateNav("/SchemeList/archive")
                                            $("#headertxt").html('Scheme Approval- Workflow Completed');
                                        }
                                        else {
                                            $("#headertxt").html(t('Schemes.SchemeList'));
                                            setUpdateNav("/scheme-program/0")
                                        }
                                    }


                                    setSelectedOptions(rData.Customer_Id);
                                    setSelectedOptionsSetlMethod(rData.Settlement_method);
                                    // setSelectedOptionsSetlFreq(rData.Settlement_Frequency_Id);
                                    console.log(rData, "header");
                                    setSelectedOptionsCurrency(rData.Currency);

                                    const inputString = rData.Status;
                                    const regex = /[0-9]+-([A-Za-z]+)/g;

                                    const matches = [...inputString.matchAll(regex)];
                                    const result = matches.map(match => match[1]);


                                    let statusCheck = false

                                    if (result.includes("New")) {
                                        statusCheck = true
                                    }
                                    if (rData.CustomerGroup == true) {
                                        setcheckCustomerGroup(true)
                                        setGroupCustomerData(rData.CustomerList)
                                    }
                                    else {
                                        setCustomerData(rData.CustomerList)
                                    }
                                    if (rData.ZoneList != null) {
                                        setZoneOptions(rData.ZoneList)
                                    }
                                    if (rData.StateList != null) {
                                        setStateOptions(rData.StateList)
                                    }
                                    if (rData.StationList != null) {
                                        setStationOptions(rData.StationList)
                                    }
                                    // setSelectedSettlementFreq(rData.SettlementFreqName)
                                    setCustomerInvoiceValue(rData.SelectedCustomers)
                                }
                            }
                        }
                    }

                });
            }
        })
    }

    const getRebatePrgramLines = (method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag) => {

        Service.getPostdatabySchemeIdSearch(method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data.DataList;
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            console.log("19-3table", res.data.DataList)
                            setrebateProgramLine(res.data.DataList)
                            setshowspinner(false);
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                        }
                    }
                    rData.map((item) => {
                        if (item.Status == "Approved" || item.Status == "InReview" || item.Status == "Denied") {
                            setHasConfirmProgram([item])
                        }
                    })
                }
            }

        });

    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getPostdatabyRebateIdSearch("getrebateprogramlines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {


                    let rData = res.data.DataList
                    if (res.data.DataList.length === 0) {
                        toast.success(`${t('NoMoreDataAvailable')}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            setrebateProgramLine(rebateProgramLine => [...rebateProgramLine, ...res.data.DataList])
                            const currentlength = rebateProgramLine.length;
                            LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                            const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                            console.log(allCheckBoxes, "21-6");

                            if (!checkAllChecked && totalselectedcount != 0) {
                                allCheckBoxes.forEach((each) => {
                                    const checkboxId = parseInt(each.id, 10);
                                    if (!isNaN(checkboxId)) {
                                        if (uncheckedBoxesHistory.includes(checkboxId)) {
                                            each.checked = false;
                                        } else {
                                            // each.checked = true;
                                            each.checked = checkedMultiValueid.includes(checkboxId);
                                        }
                                    }
                                });
                            } else {
                                allCheckBoxes.forEach((each) => {
                                    each.checked = checkAllChecked;
                                });
                            }
                        }

                    }
                    rData.map((item) => {
                        if (item.Status == "Approved" || item.Status == "InReview" || item.Status == "Denied") {
                            setHasConfirmProgram(hasConfirmProgram => [...hasConfirmProgram, ...[item]])
                        }
                    })
                }
            }
        });
    };



    if (hasConfirmProgram.length === 0) {
        if (isLocked === false || (isLocked === true && lockedBy?.toString() === loggedInUser)) {
            disableHeaderField = false;
        }
        else {
            disableHeaderField = true;
        }
    }
    else {
        disableHeaderField = true;
    }




    const getRebateWorkflowDetails = (id, pagesize, pagenumber) => {
        Service.getdatabyId('Scheme/GetSchemeWorkflowById', id, pagesize, pagenumber).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            wData = res.data.DataList;
                            setWorkflowData(wData);
                            setshowspinnerworkflow(false)
                            setWoData('get data');
                        }
                    }
                }
            }
        });
    }


    const insertWorkflowDetails = (rplId, userId,) => {
        Service.workflowDataRebate('Scheme/insertWorkflow', rplId, userId).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status == 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                        }
                    }
                }
            }
        })
    }





    if (state.status == 'approved') {
        rebateApproved = 1
    } else if (state.status == 'submitted') {
        rebateApproved = 2
    } else {
        rebateApproved = 0
    }


    const OnChangeHandler = (e) => {

        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked
        }
        setChangeHandleHeader(true)
        setState({ ...state, [name]: value });

        if (e.target.name == "autoPostAccruals") {
            if (e.target.value == "true") {
                setState({ ...state, ["autoPostAccruals"]: true });
            } else {
                setState({ ...state, ["autoPostAccruals"]: false });
            }
        }



        // get salesOrg by customer ID
        if (name == "customerID") {
            Service.getSalesOrgbyCustId("GetSalesOrg", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {

                            let rData = res.data

                            if (rData !== 'null' && rData !== 'undefined') {
                                setSalesOrgData({ id: rData[0]['Id'], name: rData[0]['Name'] });
                            }
                        }
                    }
                }

            })
        }
    };

    const OnRegionChange = (e) => {
        setChangeHandle(true)
        let selected = [];
        const selectedId = [];
        let selectedOption = (e);
        for (let i = 0; i < selectedOption.length; i++) {
            const getallData = {
                id: 0,
                Id: selectedOption[i].Id,
                Name: selectedOption[i].Name,
                label: selectedOption[i].label
            }
            selected.push(getallData);
        }
        if (selected.length > 0) {
            selected = selected.filter((e) => e.label !== 'All')
        }
        if (selected.length == 0) {
            selected = [{ 'value': 'All', 'label': "All" }]
        }
        setSelectedOptionsRegion(selected)
    }

    const OnChangeProgramDetail = (e) => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked
        }
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, [name]: value });


    }

    const OnSelectHandler = (e) => {
        setShowCreateEditRebatePgm(false)
        if (e.length <= 0) {

            setErrorMessage(`${t('PleaseSelect')} ${t('Rebates.Validations.ProgramLineYouWantToOpen')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)


        }
        else {
            setshowrebateProgramLine(true);
            navigate("/new-product-scheme/" + state.id + "/fromScheme/" + e)
            setshowrebateprogram(true);
            getRebateProgramLineDetails("GetSchemeProgramLinesDetails", e, "Product Scheme")
        }
    };

    const OnChangeHandlerStartDate = (e) => {

        setChangeHandleHeader(true)
        let today = moment(new Date()).format(SessionManage.getdateformat());
        setState({ ...state, ['startDate']: e });


        if (moment(e).isBefore(today)) {

            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityFrom: today })
        } else {
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityFrom: e })
        }
    };

    const OnChangeHandlerEndDate = (e) => {
        let today = moment(new Date()).format(SessionManage.getdateformat());
        setState({ ...state, ['endDate']: e });
        setChangeHandleHeader(true)

        if (e) {
            setrebateProgramLinedetail({ ...rebateprogrmlinedetail, validityTo: e })
        }
    };


    const getRebateDefinitionDetails = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        rebateDefinitionDatail = res.data
                        if (rebateDefinitionDatail['is_new'] == true) {
                            setIsNew(true)
                            setVolumeRebateList(initial_value);
                            SetProducts([])
                            SetProductList([])
                        } else {
                            setIsNew(false)
                            SetProducts(rebateDefinitionDatail['product_list'])
                            SetOldProductList(rebateDefinitionDatail['product_list'])
                            SetFilteredProductList(FiltedProducts)
                            selectedVolumeRebateProduct.length = 0; // clear local stored value
                            Products.length = 0
                            UpdateProducts(rebateDefinitionDatail['product_list'])
                            setVolumeRebateList(rebateDefinitionDatail['volume_formula'])
                            FilterProductList();
                        }

                    }
                }
            }

        });
    }

    const setregionbyId = (selectedregion) => {

        let updateregion = [];
        let olddata = [...SalesLevelData];
        olddata.map((i) => {
            selectedregion.map((j) => {
                if (j.label === i.label) {
                    i.selected = true;
                }

            })

            updateregion.push(i);
        })

        setSalesLevelData(updateregion);
    }


    const getRebateProgramLineDetails = (method, id, type) => {
        Service.getdatabyschemeProgramId(method, id, type).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            let rData = res.data[0];
                            console.log("19-3rpldata", rData)
                            setrebateProgramLinedetail({
                                id: rData.Id,
                                rebateProgramLineId: rData.RebateProgramLineId,
                                rebateProgramDesc: rData.RebateProgram_Desc,
                                rebateTypeId: rData.Rebate_Type_Id,
                                rebateType: rData.Rebate_Type,
                                rebateDefinition: rData.Rebate_Definition,
                                rebateMeasureId: rData.Rebate_Measure_Id,
                                validityFrom: moment(rData.Validity_From).toDate(),
                                validityTo: moment(rData.Validity_To).toDate(),
                                settlementFreqId: rData.Settlement_Freq_Id,
                                settlementFreq: rData.Settlement_Freq,
                                active: rData.Active,
                                deleted: rData.Deleted,
                                statusId: rData.status_Id,
                                status: rData.Status,
                                geography: rData.salesOfficeView,
                                Rebate_Payout_Cond_Id: rData.Rebate_Payout_Cond_Id,
                                Rebate_Payout_Cond: rData.Rebate_Payout_Cond,
                                AccrualCalcTypeId: rData.AccrualCalcTypeId,
                                comparisondateformatId: rData.ComparisondateformatId,
                                comparisonStartDate: moment(rData.ComparisonStartDate).toDate(),
                                comparisonEndDate: moment(rData.ComparisonEndDate).toDate(),
                                AccrualCalcType: rData.AccrualCalcType,
                                RebateMeasureLabel: rData.RebateMeasureLabel,

                            })
                            setSubdivideTargetid(rData.SubdivideTargetid);
                            setHasSubdivideTarget(rData.HasSubdivideTarget);
                            setDefineMinThreshold(rData.DefineMinThreshold);
                            setMinThresholdValue(rData.MinThresholdValue);
                            setMinThresholdPayout(rData.MinThresholdPayout);
                            setHasPaymentTerms(rData.HasPaymentTerms);
                            setPaymentTermsId(rData.PaymentTermsId);
                            setShowTargetSelection(rData.SubdivideTargetName);
                            setSelectedOptionsRebateType(rData.Rebate_Type_Id)
                            setSelectedSettlementFreq((rData.Settlement_Freq_Id)?.toString())
                            setSelectedAchievementPolicy(rData.AchievementPolicyId);
                            setIncludeAchievementPolicy(rData.IsIncludeAchievementpolicy);
                            let selectedCustomers = customerData.filter((x) => x.Selected == true)

                            const ds = rData.apiSubdivideTargetTieritem;
                            setHfPayoutList(rData.apiSubdivideTargetTieritem);
                            setShowTargetTable(rData.HasSubdivideTarget);

                            setrebatelinestatus(rData.Status);
                            SetProducts(rData.Product_list);
                            // SetFiltedProducts(rData.Product_list); // don't set the products 


                            setclickProduct(false);
                            let SelectedVolume = rData.Volume_Formula.map((each) => {
                                each.measureValues = each.measureValues.filter((mv) => mv.Value !== '0' && mv.Value !== null);
                                return each;
                            });
                            console.log(SelectedVolume);
                            console.log("19-3vol", SelectedVolume)

                            if (measureOptions.length === 0) {
                                Service.getAllGetAllMeasureType().then(result => {
                                    result.forEach((each) => {
                                        each['Selected'] = false;
                                    })
                                    console.log("19-3apidata", result)
                                    result.forEach((eachOption) => {
                                        SelectedVolume.forEach((each) => {
                                            each.measureValues.forEach((eachMeasure) => {
                                                if (eachMeasure.Name === eachOption.Name) {
                                                    eachOption.Selected = true;
                                                    if (eachOption.Name === "measurevalue_qtygrowth" || eachOption.Name === "measurevalue_qty") {
                                                        eachMeasure.symbol = "Unit";
                                                    } else if (eachOption.Name === "measurevalue_contribution" || eachOption.Name === "measurevalue_revgrowth") {
                                                        eachMeasure.symbol = "%";
                                                    } else if (eachOption.Name === "measurevalue_revenue") {
                                                        eachMeasure.symbol = "Amount";
                                                    } else {
                                                        eachMeasure.symbol = "";
                                                    }
                                                }
                                            });
                                        });
                                    });
                                    setMeasureOptions(result)
                                    SelectedRebateMeasureOptions.push(...result.filter((each) => each.Selected === true));
                                }).catch(error => {
                                    console.error(error);
                                });
                            } else {
                                //setting the selected attribute for all the values to null before loading the selected data
                                measureOptions.forEach((eachoption) => {
                                    eachoption.Selected = null;
                                })

                                measureOptions.forEach((eachOption) => {
                                    SelectedVolume.forEach((each) => {
                                        each.measureValues.forEach((eachMeasure) => {
                                            if (eachMeasure.Name === eachOption.Name) {
                                                eachOption.Selected = true;
                                                if (eachOption.Name === "measurevalue_qtygrowth" || eachOption.Name === "measurevalue_qty") {
                                                    eachMeasure.symbol = "Unit";
                                                } else if (eachOption.Name === "measurevalue_contribution" || eachOption.Name === "measurevalue_revgrowth") {
                                                    eachMeasure.symbol = "%";
                                                } else if (eachOption.Name === "measurevalue_revenue") {
                                                    eachMeasure.symbol = "Amount";
                                                } else {
                                                    eachMeasure.symbol = "";
                                                }
                                            }
                                        });
                                    });
                                });
                            }
                            console.log("19-3measure", measureOptions)
                            if (measureOptions.length > 0) {
                                setSelectedRebateMeasureOptions(measureOptions.filter((each) => each.Selected === true))
                            }

                            setVolumeRebateList(SelectedVolume)
                            rData.VolumetierProductList.forEach((each) => {
                                each.ProductTargets.forEach((innereach) => {
                                    innereach['Target_Measure'] = "InvoicePrice"
                                })
                            })
                            setProductTargetList(rData.VolumetierProductList)
                            // setRebateIcon(rData.Volume_Formula[0].measureValues[0].Rebate)
                            setCurrentWorkflowLevel(rData.CurrentWorkflowLevel)
                            setRebateTypeValue(rData.Rebate_Type_Name);
                            setSelectedOptionsRebateType(rData.Rebate_Type_Id)
                            setSelectedOptionsSettlFreq(rData.Settlement_Freq_Id)
                            // setSelectedOptionsRebateMeasure(rData.Rebate_Measure_Id)
                            setSelectedOptionsRebateMeasureDisabled(rData.Rebate_Measure_Id)
                            setSelectedOptionsPayout(rData.Rebate_Payout_Cond_Id)
                            setSelectedOptionsAccrualCal(rData.AccrualCalcTypeId)
                            setSelectedOptionsRegion(rData.Geography)
                            setComparisonDateFormatName(rData.Comparisondateformat);
                            setSelectedOptionsCompDateFormat(rData.ComparisondateformatId)
                            if (rData.Status == 'New') {
                                insertWorkflowDetails(rData.Id, loggedInUser)
                            }
                            setTimeout(() => {
                                getRebateWorkflowDetails(rData.Id, paging.pageSize, paging.pageNumber)
                                getRebateWorkflowApprovers('Scheme/GetSchemeWorkflowApprovers', rData.Id)
                            })
                            setDate([rData.Validity_From, rData.Validity_To])
                            setComparisonEndDate(moment(rData.ComparisonEndDate).toDate())
                            setComparisonStartDate(moment(rData.ComparisonStartDate).toDate())
                            getRebateMeasureData("RebateMeasure/GetAll/", rData.Rebate_Type_Id, 'rpl');
                            getRebatePayoutData("RebatePayout/GetAll", rData.Rebate_Type_Id)
                            getrebateCategoryData();
                            setSelectedRebateCategory(rData.RebateCategoryId);
                            if (rData.Comparisondateformat == 'customperiod') {
                                setComparisonDateEditable(true)
                            }
                            if (rData.Status == "Approved" || rData.Status == "InReview" || rData.Status == "Denied" || rData.Status == "Revising")
                                setIsProgramLineConfirmed(1);
                            else
                                setIsProgramLineConfirmed(0);
                            if (rData.Status == 'Revising') {
                                setRevised(true);
                                let todayDate = new Date();
                                let vFrom = new Date(rData.Validity_From);
                                let vTo = new Date(rData.Validity_To);
                                if (vFrom > todayDate) {
                                    setValidityFromEditable(true);
                                }
                                if (vTo > todayDate) {
                                    setValidityToEditable(true);
                                }
                            }
                            else {
                                setRevised(false);
                            }

                            if (rData.Rebate_Payout_Cond === 'Targeted' || 'Stepped') {
                                setRebatePayoutCond(rData.Rebate_Payout_Cond)
                            }
                            if (rData.Rebate_Definition === 'Quantity' || rData.Rebate_Definition === 'Growth in Qty (Abs)') {
                                setmeasureValueLabel('Unit')
                            }
                            else if (rData.Rebate_Definition === 'Revenue' || rData.Rebate_Definition === 'Growth in Rev (Abs)') {
                                setmeasureValueLabel('Amount')
                            }
                            else if (rData.Rebate_Definition === 'Growth in Qty (%)' || rData.Rebate_Definition === 'Growth in Rev (%)') {
                                setmeasureValueLabel('%')
                            }
                            else {
                                setmeasureValueLabel('')
                            }
                            setSaveProgramLine(true);
                        }
                    }
                }
            }

        });
    }

    console.log("19-3selectedoption", SelectedRebateMeasureOptions)




    if (isProgramLineConfirmed == 0) {
        if (isLocked === false || (isLocked === true && lockedBy?.toString() === loggedInUser)) {
            disableProgramLineField = false;
        }
        else
            disableProgramLineField = true
    }
    else
        disableProgramLineField = true

    const UpdateProducts = (_SelectedProducts,saveStatus) => {
        console.log(_SelectedProducts,"19-2",saveStatus)
        setChangeHandle(true)
        setPrevSelectedTypeState(selectedType);

        if (rebateprogrmlinedetail.status === "New" || rebateprogrmlinedetail.status == "Revising" || rebateprogrmlinedetail.status === undefined || rebateprogrmlinedetail.status === "") {
            SetFiltedProducts(_SelectedProducts);
            FilterProductList(_SelectedProducts);

        }
        let temp = false;
        inputField.addproductdata = temp
        setInputField(inputField);
        showpopupcontnent.value = 2;
        setshowpopupcontnent(showpopupcontnent);
        if(saveStatus){
           setshowproductpopup(false);
        }
        

        // Update Products for each slab 

        let list = [...ProductTargetList]
        let UpdatedProducts = [...list[slabIndex].Products]
        UpdatedProducts = _SelectedProducts
        list[slabIndex].Products = UpdatedProducts
        setProductTargetList(list)
    }


    const removeById = (arr, id) => {

        const requiredIndex = arr.findIndex(el => {

            return el.key === id;
        });
        if (requiredIndex === -1) {
            return false;
        };
        return !!arr.splice(requiredIndex, 1);
    };

    const FilterProductList = (selectedProducts) => {
        let res = []
        let res2 = []
        let temp_prodList = []
        let temp_excludeProdList = []


        if (ProductList == undefined) {
            SetProductList([]);
        } else {
            ProductList.length = 0;
        }
        ExcludedProductList.length = 0;
        temp_prodList.length = 0
        temp_excludeProdList.length = 0

        if (selectedProducts) {
            Service.postDataWithValue("Rebate/ProductSelectorByhierarchyUpdated", selectedProducts).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            let rData = res.data
                            let result = []
                            if (rData !== 'null' && rData !== 'undefined') {

                                rData.forEach((item) => {
                                    if (item) {
                                        temp_prodList.push(item)
                                    }
                                })
                            }
                            SetFiltedProducts(rData)

                        }
                    }
                }

            })
        }

    }






    const FetchProductList = (SelectedItem) => {
        let prod_list = []
        let temp = []
        SelectedItem.forEach((item) => {

            prod_list.push({ "title": item['title'], key: item['id'] })
        })
        return temp
    }


    const DeleteRebateProduct = (index, id, product) => {

        let data = {
            'proudct': product,
            'index': index,
            'header': state.id
        }

        if (id !== 0) {
            setLoading(true);
            Service.deletewithType("deleterebateproduct", id, data).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setLoading(false);
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else
                        setLoading(false);
                }

            });
        }

    }

    const DeleteVolumeTierItems = (id, type) => {

        let data = {
            'type': type,
            'header': state.id
        }
        if (id !== 0) {
            setLoading(true);
            Service.deletewithType("deletevolumetieritems", id, data).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setLoading(false);
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,

                        });

                    }
                } else
                    setLoading(false);

            });
        }

    }


    const RemoveProductFromListHandler = (index) => {
        if (disableProgramLineField == false) {
            let new_arr = Products.filter((i, idx) => idx !== index)
            DeleteRebateProduct(index, Products[index]['key'], Products[index]['title'])
            Products.length = 0
            UpdateProducts(new_arr)
            if (index == 0) {
                VolumeRebateList.length = 0
                setVolumeRebateList(initial_value);
            }
        } else {
            setErrorMessage(`${t('Rebates.Validations.CannotRemoveTheProductFromSubmittedDocument')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
    }



    const handleInputChange = (e, index) => {
        //////debugger
        const { name, value } = e.target;
        // alert(index);
        // alert(name);
        // alert(value)
        setChangeHandle(true);
        console.log("23-1", name, index);


        let list = [...VolumeRebateList];

        if (name === 'Rebate_Value') {

            if (value === '%') {
                setRebateIcon(value);
                list[index]['Target_Measure'] = 'Invoice Price';
            } else if (value === 'Select') {
                setRebateIcon('');
                list[index]['Target_Measure'] = '';
            } else if (value === '$' || value === '₹') {
                setRebateIcon(value);
                list[index]['Target_Measure'] = 'Quantity';
            } else if (value === 'LS') {
                setRebateIcon(value);
                list[index]['Target_Measure'] = 'Lumpsum';
            }

            list[index][name] = value;


            if (VolumeRebateList.length > 1) {
                let currentRebateValue = VolumeRebateList[0].Rebate_Value;
                let currentTargetMeasure = VolumeRebateList[0].Target_Measure;

                for (let i = 0; i <= list.length - 1; i++) {
                    if (i >= index && i < index + 4) {
                        list[i].Rebate_Value = e.target.value;
                        if (e.target.value == "%") {
                            list[i].Target_Measure = "InvoicePeriod";
                        }
                        else if (e.target.value == "LS") {
                            list[i].Target_Measure = "LumpSum";
                        }
                        else if (e.target.value == "₹") {
                            list[i].Target_Measure = "Quantity";
                        }
                    }
                }
            }


        }

        else if (name === 'Targetcondition') {

            for (let i = 0; i <= list.length - 1; i++) {
                if (i >= index && i < index + 4)
                    list[i].Targetcondition = e.target.value;
            }
        }
        else if (name === "measureValues") {
            for (let i = 0; i <= list.length - 1; i++) {
                if (i >= index && i < index + 4)
                    list[i].measureValues = e.target.value;
            }

        }
        else {

            list[index][name] = value;
        }


        setVolumeRebateList(list);
    };

    const AddprodHandler = () => {
        setOpen(!open);
        setShowModal(true);
    }


    console.log("23-1", VolumeRebateList)

    const AddProductHandler = () => {

        if (dualListBoxOptionsByHeirarchy.length == 0) {
            Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {
                                setdualListBoxOptionsByHeirarchy(res.data)

                            }
                        }
                    }
                }

            });
        }


        setOpen(!open);
        setShowModal(true);
    }


    const UpDateTierTable = () => {
        let list1 = [...VolumeRebateList]
        //debugger;
        let updatedMeasureValues = list1[0].measureValues
        let list2 = [...measureOptions]
        let list3 = [...ProductTargetList]
        let updatedTargets = [...list3[0].ProductTargets]

        //////debugger
        if (updatedMeasureValues.length > 0) {
            list2.forEach((eachoption) => {
                if (!eachoption.Selected) {  // Remove element if Selected is false
                    updatedMeasureValues = updatedMeasureValues.filter((each) => each.Name !== eachoption.Name);
                }
            })
        }

        list2.filter((x) => x.Selected === true).forEach((eachoption) => { // Add element if Selected is true
            if (!updatedMeasureValues.some((item) => item.Name === eachoption.Name)) {
                let item = {
                    Name: eachoption.Name,
                    Label: eachoption.label,
                    Value: '',
                    symbol: (() => {
                        if (eachoption.Name === "measurevalue_qty" || eachoption.Name === "measurevalue_qtygrowth") {
                            return "Unit";
                        } else if (eachoption.Name === "measurevalue_contribution" || eachoption.Name === "measurevalue_revgrowth") {
                            return "%";
                        } else if (eachoption.Name === "measurevalue_revenue") {
                            return "Amount";
                        } else {
                            return "";
                        }
                    })()
                };
                updatedMeasureValues.push(item);
            }
        });

        if (list1.length > 1) {
            list1 = list1.slice(0, 1);
        }
        if (list3.length === 1) {
            if (updatedTargets.length > 1) {
                updatedTargets = updatedTargets.slice(0, 1);
            }
        } else if (list3.length > 1) {
            list3 = list3.slice(0, 1);
            if (updatedTargets.length > 1) {
                updatedTargets = updatedTargets.slice(0, 1);
            }
        }
        list1[0].measureValues = updatedMeasureValues;
        list3[0].ProductTargets = updatedTargets;
        setVolumeRebateList(list1);
        setProductTargetList(list3);

    }


    const checkVolume = (index) => {
        let volumeCheck = false

        if (VolumeRebateList[index].Targetcondition == null || VolumeRebateList[index].Targetcondition == '' || VolumeRebateList[index].Targetcondition === 'Select') {
            setErrorMessage(`${t('Pleaseselect')}${t('Rebates.TargetCond')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
            volumeCheck = true
        }
        else if (VolumeRebateList[index].Condition === null && VolumeRebateList[index].Condition === '' && VolumeRebateList[index].Condition === 'Select') {
            setErrorMessage(`${t('Pleaseselect')}${t('Schemes.Condition')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
            volumeCheck = true
        }
        else if (VolumeRebateList[index].measureValues.length === 0) {
            setErrorMessage(`${t('Please Enter')}${t('Rebates.MeasureValue')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
            volumeCheck = true

        }

        return volumeCheck
    }


    const AddVolume = (index) => {
        ////debugger
        let check = checkVolume(index);
        let list = [...VolumeRebateList];
        let list1 = [...ProductTargetList]
        let updatedTargets = [...list1[0].ProductTargets]

        if (check === false) {
            let MV = [];
            list[index].measureValues.forEach((each) => {
                let item = {
                    Name: each.Name,
                    Label: each.Label,
                    Value: '',
                    symbol: each.symbol
                };
                MV.push(item);
            });

            const initialRow = {
                Targetcondition: list[0].Targetcondition,
                measureValues: MV,
                Slab: "Slab" + (list?.length + 1),
                Condition: list[0].Condition
            };

            setVolumeRebateList((prevList) => [...prevList, initialRow]);

            let defaultRebate = updatedTargets.length > 0 ? updatedTargets[0].Rebate !== '' ? updatedTargets[0].Rebate : '' : '';
            if (list1.length === 1) {
                const Addobj = {
                    Slab: "Slab" + (list?.length + 1),
                    Rebate: defaultRebate,
                    Rebatevalue: '',
                    Target_Measure: 'InvoicePrice',
                    IncludeEligibilityCheck: false,
                    IncludeAccrualDistribution: false,
                };
                updatedTargets = [...updatedTargets, Addobj];
                list1[0].ProductTargets = updatedTargets;
            } else if (list1.length > 1) {
                list1.forEach((each) => {
                    const Addobj = {
                        Slab: "Slab" + (list?.length + 1),
                        Rebate: defaultRebate,
                        Rebatevalue: '',
                        Target_Measure: 'InvoicePrice',
                        IncludeEligibilityCheck: false,
                        IncludeAccrualDistribution: false
                    };
                    each.ProductTargets = [...updatedTargets, Addobj];
                });
            }
            setProductTargetList(list1);
        }
    };

    console.log("14-3vol", VolumeRebateList)
    console.log("14-3vol", VolumeRebateList.length)
    console.log("14-3Pr", ProductTargetList)
    console.log("14-3SI", slabIndex)
    console.log("14-3FP", FiltedProducts)
    console.log("14-3cond", rebatePayoutCond)

    const RemoveVolume = (idx) => {
        setVolumeRebateList(VolumeRebateList.filter((item, index) => index !== idx));
        setChangeHandle(true)


        let list = [...ProductTargetList];
        if (list.length === 1) {
            let updatedTargets = [...list[0].ProductTargets];
            updatedTargets = updatedTargets.filter((item, index) => index !== idx);
            list[0].ProductTargets = updatedTargets;
        }
        else if (list.length > 1) {
            list.forEach((each) => {
                let updatedTargets = [...each.ProductTargets]; // Move the declaration inside the loop
                each.ProductTargets = updatedTargets.filter((item, index) => index !== idx);
            });
        }
        setProductTargetList(list);
    }



    const AddProductVolume = (idx) => {
        let list = [...ProductTargetList];
        let ExistingPT = list[0].ProductTargets;
        let newPt = []
        let defaultRebate = ExistingPT.length > 0 ? ExistingPT[0].Rebate : '';

        ExistingPT.forEach((each, idx) => {
            newPt.push({
                Slab: each.Slab,
                Rebate: defaultRebate != '' ? defaultRebate : '',
                Rebatevalue: '',
                Target_Measure: 'InvoicePrice',
                IncludeEligibilityCheck: false,
                IncludeAccrualDistribution: false
            })
        })

        let initialRow = {
            ProductTargets: newPt,
            Products: [],
            Pdtset: list[idx].Pdtset + 1
        }
        setProductTargetList((prevList) => [...prevList, initialRow]);
    }


    const RemoveProductVolume = (idx) => {
        // let list = [...ProductTargetList];
        setProductTargetList(ProductTargetList.filter((item, index) => index !== idx))
    }

    const handleSlabInputChange = (e, index, InnerIdx, name) => {
        // ////debugger
        let updatedProductList = [...ProductTargetList]; //entire ProductTargetList array
        let currentProduct = { ...updatedProductList[index] }; //object at the specified index
        let updatedTargets = [...currentProduct.ProductTargets]; //inner ProductTargets array

        if (name === 'Rebate') {
            let value = e.target.value;
            updatedProductList.forEach((product) => {
                product.ProductTargets.forEach((target) => {
                    target.Rebate = value;
                });
            });
        } else if (name === 'IncludeEligibilityCheck' || name === 'IncludeAccrualDistribution') {
            currentProduct.ProductTargets.forEach((target) => {
                target[name] = e.target.checked;
            });
        } else {
            updatedTargets[InnerIdx][name] = e.target.value;
        }

        currentProduct.ProductTargets = [...updatedTargets];
        console.log("14-3obj", currentProduct);
        console.log("14-3list", updatedProductList);
        updatedProductList[index] = currentProduct;
        setProductTargetList(updatedProductList);

    }

    const handleSlabProducts = (index) => {

    }

    console.log("15-3", FiltedProducts)
    console.log("15-3", dualListBoxOptionsByAttribute)
    console.log("15-3", dualListBoxOptionsByHeirarchy)



    const headerHeight = 50;
    const defaultColDef = {
        width: 130,
        editable: true
    };

    const SaveAndCloseHandler = () => {
        SaveRebateHeaderHandler('save');
        CloseHandler();
    }

    const CloseHandler = () => {
        navigate('/scheme-program/0')
    }

    const validateField = () => {
        let isValid = true;
        let productList = []
        let startDate = new Date(rebateprogrmlinedetail.validityFrom)
        let endDate = new Date(rebateprogrmlinedetail.validityTo)

        if (state.Customer_Ids == 0 || state.Customer_Ids == null) {
            setErrorMessage((`${t('Pleaseselect')}  ${t('Rebates.Customer')}  ${t('Rebates.Validations.InTheHeader')}`))
            isValid = false;
        }
        else if (state.currencyID == 0 || state.currencyID == null) {
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.Currency')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (state.setlMethodID == 0 || state.setlMethodID == null) {
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.SettlementMethod')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (state.FiscalYear == "" || state.FiscalYear == null) {
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.FiscalPeriod')} ${t('Rebates.Validations.InTheHeader')}`)
            isValid = false;
        }
        else if (endDate.getTime() < startDate.getTime()) {
            setErrorMessage(`${t('Rebates.Validations.HeaderValidityFromIsGreaterThanTheValidityTo')}`)
            isValid = false;
        }

        return isValid
    }


    //save and submit function
    const SaveRebateHeaderHandler = (val) => {
        ////debugger
        let isValid = true
        //validateField()
        let chk = validateField()
        if (chk == true) {
            if (isValid == true) {

                if (state.analysis == false)
                    state.analysis = 'False';
                else
                    state.analysis = 'True';

                // let validFrom = moment(state.startDate).toDate()
                // let validTo = moment(state.endDate).toDate()

                if (state.currencyID == '0')
                    state.currencyID = null;
                if (state.setlFreqID == '0')
                    state.setlFreqID = null;
                let rebateData = {
                    'Id': state.id,
                    'Rebate_id': state.agreementId,
                    'RebateName': state.agreementName,
                    'Customer_Id': null,
                    'Sales_org_Id': null,
                    'Settlement_method_Id': parseInt(state.setlMethodID),
                    'Settlement_Frequency_Id': state.setlFreqID,
                    'Status': state.status,
                    'Validity_From': moment(fyStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    'Validity_To': moment(fyEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    'Auto_Post_Accruals': state.autoPostAccruals,
                    'Currency_Id': state.currencyID,
                    'CreatedUserId': parseInt(loggedInUser),
                    "LastModifiedUserId": parseInt(loggedInUser),
                    "Customer_Ids": state.Customer_Ids,
                    'CustomerGroup': state.CustomerGroup,
                    'Agreementtype': 'Scheme',
                    "zoneIDs": state.zoneIDs,
                    "stationIDs": state.stationIDs,
                    "stateIDs": state.stateIDs,
                    'FiscalYear': state.FiscalYear,
                    'Agreementtype': 'Product Scheme',
                }

                setLoading(true);
                console.log("21-3", rebateData)
                Service.createorupdate("Scheme/CreateOrUpdateSchemeHeader", rebateData).then((res) => {

                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let rData = res.data
                            setLoading(false);
                            if (res.data.Status == 'Warning') {
                                setErrorMessage(t('Rebates.Validations.RebateHeaderForTheSameCustomerAlreadyExistsWithSubmitted/ApprovedProgramlines'))
                                setErrorStatus(res.data.Status)
                                setShowErrorModal(true);
                            } else {
                                if (saveProgramLine === false) {
                                    toast.success('Rebate Header Saved Successfully !', {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                                getRebateHeaderDetails("Scheme/GetSchemeHeader", rData.Id)
                            }
                        }
                    } else
                        setLoading(false);
                });
                return true;
            }
            else {

                setLoading(false);
            }

        }
        else {
            setErrorStatus("Error")
            setShowErrorModal(true);
            return false;
        }
    }



    const CopyHandler = () => {
        let rowData = {}

        Service.PostWithIdAndUser('Rebate/CopyRebate', props.id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && res.data !== 'undefined') {
                            copyID = res.data
                            navigate('/new-scheme/' + copyID + '/fromScheme/0')
                        }
                    }
                }
            }


        });
    }

    const CloseProductPicker = () => {

        setOpen(false);
        setShowProdModal(false);
        setShowExcludeModal(false)
    }

    const SelectedProductTypeHandler = (event) => {
        setChangeHandle(true)
        setSelectedType(event.target.value);

        if (event.target.value === 'Heirarchy') {
            setPrevSelectedTypeState("Attribute");
        } else {
            setPrevSelectedTypeState("Heirarchy");
        }
    }





    const showDeleteDialog = (flag) => {
        if (props.id == 0) {
            setErrorMessage(`${t('Rebates.Validations.PleaseSaveTheRecordBeforeDeleting')}`)
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        else {
            if (flag === 'open') {
                toggleModal(true)
            }
            if (flag === 'close') {
                toggleModal(false)
                let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
                for (let checkbox of checkedBoxes) {
                    checkbox.checked = false;
                }
                setMultiValueIds([])
                setDeleteshowbutton(false)
            }
            if (flag === 'proceed') {
                OnDeleteHandler(multiValueids)
                let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
                for (let checkbox of checkedBoxes) {
                    checkbox.checked = false;
                }
                setDeleteshowbutton(false)
            }
        }
    }




    const AccrualEligibleInvoice = (RebateProgramLineId) => {
        let RebateId = state.id;
        let CreatedUserId = loggedInUser;
        let createdFromScheme = true;
        let values = []
        if (accrualCustomerValue.length == 0) {
            for (let i = 0; i < customerInvoiceValue.length; i++) {
                const getallData = {
                    CustomerId: customerInvoiceValue[i].Id,
                    CustomerName: customerInvoiceValue[i].label,
                }
                values.push(getallData)
            }
        }
        else {
            values = accrualCustomerValue;
        }
        let selectedCustomers = values;
        let accrualPeriod = accrualPeriodValue;
        let IsProvisionalRebate = false;
        let accrualType = "Product Scheme";
        setLoading(true);
        setLoadingMsg("EligibleInvoice");
        let data = { RebateId, RebateProgramLineId, CreatedUserId, createdFromScheme, selectedCustomers, IsProvisionalRebate, accrualPeriod, accrualType }
        Service.createorupdate('Accurals/GenerateEligibleInvoice', data).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                setLoadingMsg("");
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data.status !== "Failure") {
                        toast.success('Generate Eligible Invoice Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate('/accruals/new/' + res.data.AccuralId + '/fromScheme')
                        });

                    }
                    else {
                        setErrorStatus("Error")
                        setErrorMessage(t('Rebates.Validations.FailedToCreateaccruals'))
                        setShowErrorModal(true)
                    }
                }
            }
            else {
                setLoading(false);
                setLoadingMsg("");
                setErrorStatus("Error")
                setErrorMessage(`${t('Rebates.Validations.FailedToCreateaccruals')}`)
                setShowErrorModal(true)
            }
        });

    }

    const AccrualAnalyzeRebate = (RebateProgramLineId) => {


        let RebateId = state.id;
        let CreatedUserId = loggedInUser;
        let createdFromScheme = true;
        let values = []
        if (accrualCustomerValue.length == 0) {
            for (let i = 0; i < customerInvoiceValue.length; i++) {
                const getallData = {
                    CustomerId: customerInvoiceValue[i].Id,
                    CustomerName: customerInvoiceValue[i].label,
                }
                values.push(getallData)
            }
        }
        else {
            values = accrualCustomerValue;
        }
        let selectedCustomers = values;
        let accrualPeriod = accrualPeriodValue;
        let IsAnalyzeRebate = true;
        setLoading(true);
        let data = { RebateId, RebateProgramLineId, CreatedUserId, createdFromScheme, selectedCustomers, accrualPeriod, IsAnalyzeRebate }
        Service.createorupdate('Accurals/GenerateEligibleInvoice', data).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data.status !== "Failure") {

                        toast.success('Analyze Rebate Created Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate('/accruals/new/' + res.data.AccuralId + '/fromScheme/Analyze')
                        });


                    }
                    else {
                        setErrorStatus("Error")
                        setErrorMessage(t('Rebates.Validations.FailedToCreateaccruals'))
                        setShowErrorModal(true)
                    }
                }
                else {
                    setLoading(false);
                    setErrorStatus("Error")
                    setErrorMessage(`${t('Rebates.Validations.FailedToCreateaccruals')}`)
                    setShowErrorModal(true)
                }
            } else {
                setLoading(false);
            }


        });

    }

    const workflowAlert = (flag) => {
        if (flag == 'open') {
            if (workflowApprovers.length > 0) {
                setShowWorkflowAlert(true)
            }
            else {
                showSubmitDialog('showSubmitDialog')
            }
        }
        else if (flag == 'close') {
            setShowWorkflowAlert(false)
            showSubmitDialog('showSubmitDialog')
        }
    }



    const showSubmitDialog = (flag) => {
        if (flag === 'showSubmitDialog') {
            setShowSubmitModal(!showSubmitModal)
            let optList = SalesLevelData
        }
        else if (flag === 'proceed') {
            let checkVal = ValidateProgramLine()
            if (checkVal === true) {
                setShowSubmitModal(false)
                setshowrebateProgramLine(false);
                setChangeHandle(false)
                submitRebateProgramLine()
            }
            else {
                setErrorStatus("Error")
                setShowErrorModal(true)
                setLoading(false);
            }
        }
    }

    const getAccrualHistoryDetails = (method, id) => {

        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let historyData = res.data
                    setAccrualHistoryData(historyData);
                }
                else {
                    setAccrualHistoryData([0])
                }
            }


        });

    }

    // const checkAll = (mycheckbox) => {
    //     var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
    //     settotalselectedshow(true);
    //     if (mycheckbox.target.checked == true) {
    //         checkboxes.forEach(function (checkbox) {
    //             checkbox.checked = true;
    //         });
    //         settotalselectedcount(checkboxes.length)
    //     }
    //     else {
    //         checkboxes.forEach(function (checkbox) {
    //             checkbox.checked = false;
    //         });
    //         settotalselectedcount(0)
    //     }

    // }


    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
            setshowbutton(false)
            setDeleteshowbutton(true)
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
            setshowbutton(false)
            setDeleteshowbutton(false)
        }
    };



    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked, false if unchecked
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let allCheckboxes = document.querySelectorAll('input[name=chkBox]');

        let count = totalselectedcount;


        // Update total selected count and visibility
        // settotalselectedcount(checkedBoxes.length);
        // settotalselectedshow(checkedBoxes.length > 0);

        // Update state for the current checkbox
        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - status
            setCheckedValueid(name); // check box value - RA-0000
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - status
            setCheckedValueid([]); // check box value - RA-0000
            count -= 1;

        }

        // Update check all checkbox state
        setCheckAllChecked(checkedBoxes.length === allCheckboxes.length);

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        if (checkedBoxes.length === 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id);  // check box value - id
            setCheckedValueStatus(Recordstatus); // check box value - Active status
            setCheckedValueid(Recordname); // check box value - RA-0000
            setshowbutton(true);
            setDeleteshowbutton(false);
        } else if (checkedBoxes.length > 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id);  // check box value - id
            setCheckedValueStatus(Recordstatus); // check box value - Active status
            setCheckedValueid(Recordname); // check box value - RA-0000
            setshowbutton(false);
            setDeleteshowbutton(true);
        } else {
            setshowbutton(false);
            setDeleteshowbutton(false);
        }
    };


    const saveRebateProgramLine = () => {
        ValidateProgramLine()
        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        let validFrom = ""
        let validTo = ""
        let programlineId = "0"

        if (rebateprogrmlinedetail.id == 0) {
            validFrom = valid_from
            validTo = valid_to
        } else {

            validFrom = valid_from;
            validTo = valid_to;
            programlineId = rebateprogrmlinedetail.rebateProgramLineId
        }
       // if (rebateprogrmlinedetail.comparisondateformatId == 0 || rebateprogrmlinedetail.comparisondateformatId == null || rebateprogrmlinedetail.comparisondateformatId == undefined) {
       //     rebateprogrmlinedetail.comparisondateformatId = selectedOptionsCompDateFormat === null ? 0 : selectedOptionsCompDateFormat;
      //  }
        if (rebateprogrmlinedetail.AccrualCalcTypeId == 0 || rebateprogrmlinedetail.AccrualCalcTypeId == null || rebateprogrmlinedetail.AccrualCalcTypeId == undefined) {
            rebateprogrmlinedetail.AccrualCalcTypeId = selectedOptionsAccrualCal;
        }
        let isValidProgramLine = ValidateProgramLine();
        if (isValidProgramLine == true) {


           // if (rebateprogrmlinedetail.comparisondateformatId == 0) {
          //      rebateprogrmlinedetail.comparisondateformatId = null;
          //  }
            // let csd = null;
            // let ced = null;
            // if (comparisonStartDate == null || comparisonStartDate == "" || comparisonStartDate == undefined) {
            //     csd = null;
            // }
            // else {
            //     csd = comparisonStartDate;
            // }
            // if (comparisonEndDate == null || comparisonEndDate == "" || comparisonEndDate == undefined || isNaN(comparisonEndDate)) {
            //     ced = new Date();
            // }
            // else {
            //     ced = comparisonEndDate;
            // }
            const VolumeRebateListSorted = VolumeRebateList.sort((a, b) => a.Slab.localeCompare(b.Slab));
            setVolumeRebateList(VolumeRebateListSorted)
            let programLinedata = {
                "RebateProgramLinesViewDetails": {
                    "Id": rebateprogrmlinedetail.id,
                    "RebateProgramLineId": programlineId,
                    "RebateProgram_Desc": rebateprogrmlinedetail.rebateProgramDesc,
                    "Rebate_Type_Id": selectedOptionsRebateType,
                    "Rebate_Type": rebateprogrmlinedetail.rebateType,
                    "Rebate_Definition": rebateprogrmlinedetail.rebateDefinition,
                    // "Rebate_Measure_Id": selectedOptionsRebateMeasure,
                    "Validity_From": moment(validFrom).format("YYYY-MM-DD"),
                    "Validity_To": moment(validTo).format("YYYY-MM-DD"),
                    "Settlement_Freq_Id": selectedOptionsSetlFreq,
                    "Settlement_Freq": rebateprogrmlinedetail.settlementFreq,
                    "Active": rebateprogrmlinedetail.active,
                    "Deleted": false,
                    "status_Id": rebateprogrmlinedetail.statusId,
                    "Status": rebateprogrmlinedetail.status,
                    "Rebate_Payout_Cond_Id": rebateprogrmlinedetail.Rebate_Payout_Cond_Id,
                    "Rebate_Payout_Cond": rebateprogrmlinedetail.Rebate_Payout_Cond,
                    "SalesLevel": "",
                    "CreatedBy": loggedInUser,
                    "LastModifiedUserId": loggedInUser,
                    "AccrualCalcTypeId": 1,
                   // "ComparisondateformatId": rebateprogrmlinedetail.comparisondateformatId,
                    // "ComparisonStartDate": moment(csd).format("YYYY-MM-DD"),
                    // "ComparisonEndDate": moment(ced).format("YYYY-MM-DD"),
                    "DefineMinThreshold": defineMinThreshold,
                    "SubdivideTargetid": subdivideTargetid,
                    "HasSubdivideTarget": hasSubdivideTarget,
                    "MinThresholdValue": minThresholdValue === "" ? null : minThresholdValue,
                    "MinThresholdPayout": minThresholdPayout === "" ? null : minThresholdPayout,
                    "HasPaymentTerms": hasPaymentTerms,
                    "PaymentTermsId": paymentTermsId,
                    "RebateCategoryId": selectedRebateCategory == "" ? null : selectedRebateCategory,
                    "IsIncludeAchievementpolicy": selectedAchievementPolicy,
                    "Justification": rebateprogrmlinedetail.Justification,
                    "AchievementPolicyId": rebateprogrmlinedetail.AchievementPolicyId

                },
                "VolumeFormula": VolumeRebateList,
                "VolumetierProductList": ProductTargetList,
                // "Products": FiltedProducts,
                // "Excludedproducts": ExcludedProductList,
                // "Geography": SalesLevelData.filter((e) => e.selected === true),
                "apiSubdivideTargetTieritem": hfPayoutList
            }
            console.log(programLinedata,"19-2 product")
            let SendData = new FormData();
            SendData.append("data", JSON.stringify(programLinedata));
            for (let f of file) {
                SendData.append('AttachPOS', f);
            }
            if (rebateprogrmlinedetail.id == 0) {
                setLoading(true);
                console.log("19-3api", "CreateProgramLine", state.id, programLinedata, "productscheme")
                Service.create_or_updateScheme("CreateProgramLine", state.id, SendData, "productscheme").then((res) => {

                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {
                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                //  getRebatePrgramLines("getrebateprogramlines/rebateid", props.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                setLoading(false);
                                let rData = res.data
                                console.log("2-4", rData)
                                toast.success('Record saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });

                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                                // getRebatePrgramLines("getrebateprogramlines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                setCustChngHandler(true)
                                setLoading(false);
                                navigate("/new-product-scheme/" + state.id + "/fromScheme/" + rData.Id)
                                getRebateProgramLineDetails("GetSchemeProgramLinesDetails", rData.Id, "productscheme")


                            } else {
                                setErrorMessage(res.data.ErrorMessage || res.data.ErrorProductList)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setLoading(false);
                            }
                        }
                        else {
                            setLoading(false);
                            setErrorMessage(`${t('Rebates.validations.ErrorWhileSavingTheRecord')}`)
                            setErrorStatus("Error")
                            setShowErrorModal(true)
                        }
                    } else {
                        setLoading(false);
                    }
                });
            } else {
                setLoading(true);
                Service.create_or_updateScheme("UpdateProgramLine", state.id, SendData, "productscheme").then((res) => {
                    if (typeof res !== 'undefined') {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {
                                toast.success('Record saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                setLoading(false);
                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                            } else {

                                setLoading(false);
                            }
                        } else {
                            toast.success('Record saved successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            setLoading(false);
                        }
                    } else
                        setLoading(false);

                });
            }
        }
        else {
            setErrorStatus("Error")
            setShowErrorModal(true)
            setLoading(false);
        }

    }



    const submitRebateProgramLine = () => {
        ////////debugger
        let isValid = true;
        //validateField()

        if (isValid == true) {
            if (state.analysis == false)
                state.analysis = 'False';
            else
                state.analysis = 'True';

            if (state.currencyID == '0')
                state.currencyID = null;
            if (state.setlFreqID == '0')
                state.setlFreqID = null;
            let rebateData = {
                'Id': state.id,
                'Rebate_id': state.agreementId,
                'RebateName': state.agreementName,
                'Customer_Id': null,
                'Sales_org_Id': null,
                'Settlement_method_Id': state.setlMethodID,
                // 'Settlement_Frequency_Id': state.setlFreqID,
                'Status': state.status,
                'Validity_From': moment(fyStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                'Validity_To': moment(fyEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                'Auto_Post_Accruals': state.autoPostAccruals,
                'Currency_Id': state.currencyID,
                'CreatedUserId': parseInt(loggedInUser),
                "LastModifiedUserId": parseInt(loggedInUser),
                "Customer_Ids": state.Customer_Ids,
                "CustomerGroup": state.CustomerGroup,
                'Agreementtype': 'Scheme',
            }

            let valid_from = rebateprogrmlinedetail.validityFrom
            let valid_to = rebateprogrmlinedetail.validityTo
            let validFrom = ""
            let validTo = ""
            let programlineId = "0"
            if (rebateprogrmlinedetail.id == 0) {
                validFrom = valid_from
                validTo = valid_to
            } else {
                validFrom = valid_from;
                validTo = valid_to;
                programlineId = rebateprogrmlinedetail.rebateProgramLineId
            }

          //  if (rebateprogrmlinedetail.comparisondateformatId == 0 || rebateprogrmlinedetail.comparisondateformatId == null || rebateprogrmlinedetail.comparisondateformatId == undefined) {
           //     rebateprogrmlinedetail.comparisondateformatId = selectedOptionsCompDateFormat === null ? 0 : selectedOptionsCompDateFormat;
          //  }
            if (rebateprogrmlinedetail.AccrualCalcTypeId == 0 || rebateprogrmlinedetail.AccrualCalcTypeId == null || rebateprogrmlinedetail.AccrualCalcTypeId == undefined) {
                rebateprogrmlinedetail.AccrualCalcTypeId = selectedOptionsAccrualCal;
            }
            let isValidProgramLine = true;
            //ValidateProgramLine();
            if (isValidProgramLine == true) {
             //   if (rebateprogrmlinedetail.comparisondateformatId == 0) {
              //      rebateprogrmlinedetail.comparisondateformatId = null;
              //  }
                let csd = new Date();
                let ced = new Date();
                // let csd = null;
                // let ced = null;
                if (comparisonStartDate == null || comparisonStartDate == "" || comparisonStartDate == undefined) {
                    //  csd = null;
                    csd = new Date();
                }
                else {
                    // csd = comparisonStartDate;
                    csd = new Date();
                }
                if (comparisonEndDate == null || comparisonEndDate == "" || comparisonEndDate == undefined || isNaN(comparisonEndDate)) {
                    ced = new Date();
                }
                else {
                    // ced = comparisonEndDate;
                    ced = new Date();
                }

                let headerAndProgramLineData = {

                    "RebateProgramLinesViewDetails": {
                        "Id": rebateprogrmlinedetail.id,
                        "RebateProgramLineId": programlineId,
                        "RebateProgram_Desc": rebateprogrmlinedetail.rebateProgramDesc,
                        "Rebate_Type_Id": selectedOptionsRebateType,
                        "Rebate_Type": rebateprogrmlinedetail.rebateType,
                        "Rebate_Definition": rebateprogrmlinedetail.rebateDefinition,
                        // "Rebate_Measure_Id": selectedOptionsRebateMeasure,
                        "Validity_From": moment(validFrom).format("YYYY-MM-DD"),
                        "Validity_To": moment(validTo).format("YYYY-MM-DD"),
                        "Settlement_Freq_Id": selectedSettlementFreq?.toString(),
                        "Settlement_Freq": rebateprogrmlinedetail.settlementFreq,
                        "Active": rebateprogrmlinedetail.active,
                        "Deleted": false,
                        "status_Id": rebateprogrmlinedetail.statusId,
                        "Status": rebateprogrmlinedetail.status,

                        "Rebate_Payout_Cond_Id": rebateprogrmlinedetail.Rebate_Payout_Cond_Id,
                        "Rebate_Payout_Cond": rebateprogrmlinedetail.Rebate_Payout_Cond,
                        "CreatedBy": loggedInUser,
                        "LastModifiedUserId": loggedInUser,
                        "Excluded_product_list": [],
                        "AccrualCalcTypeId": 1,
                        "ComparisondateformatId": rebateprogrmlinedetail.comparisondateformatId,
                        "ComparisonStartDate": moment(csd).format("YYYY-MM-DD"),
                        "ComparisonEndDate": moment(ced).format("YYYY-MM-DD"),
                        "DefineMinThreshold": defineMinThreshold,
                        "SubdivideTargetid": subdivideTargetid,
                        "HasSubdivideTarget": hasSubdivideTarget,
                        "MinThresholdValue": minThresholdValue === "" ? null : minThresholdValue,
                        "MinThresholdPayout": minThresholdPayout === "" ? null : minThresholdPayout,
                        "HasPaymentTerms": hasPaymentTerms,
                        "PaymentTermsId": paymentTermsId,
                        "RebateCategoryId": selectedRebateCategory,
                        'Agreementtype': 'Scheme',
                    },
                    "VolumeFormula": VolumeRebateList,
                    "VolumetierProductList": ProductTargetList,
                    // "Products": FiltedProducts,
                    // "Excluded_product_list": ExcludedProductList,
                    "RebateHeader": rebateData,
                    // "Geography": SalesLevelData.filter((e) => e.selected === true),
                    "apiSubdivideTargetTieritem": hfPayoutList
                }
                let SendData = new FormData();
                SendData.append("data", JSON.stringify(headerAndProgramLineData));
                setLoading(true);


                Service.SubmitRebateProgramLine('Scheme/SubmitSchemeProgramLine', rebateData.Id, "inreview", loggedInUser, commentsForSubmit, "Product Scheme", SendData).then((res) => {

                    if (typeof res !== 'undefined') {
                        setLoading(false)
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.data.Status != 'Failure') {

                                setShowSubmitModal(!showSubmitModal)

                                toast.success('Submitted Successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,

                                });

                                var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")


                                setShowCreateEditRebatePgm(true)
                                setshowrebateprogram(false);
                                setCommentsForSubmit('')
                                setSaveProgramLine(false);
                            } else {
                                setShowSubmitModal(!showSubmitModal)
                                setErrorStatus("Error")
                                setErrorMessage(res.data.ErrorMessage || res.data.ErrorProductList)
                                setShowErrorModal(true)
                                setCommentsForSubmit('')
                            }

                        }
                    }
                    else {
                        setLoading(false)
                        setShowSubmitModal(!showSubmitModal)
                        setErrorStatus("Error")
                        setErrorMessage(`${t('Rebates.Validations.ErrorWhileSavingTheRecord')}`)
                        setShowErrorModal(true)
                        setCommentsForSubmit('')
                    }
                });
            }
            else {
                setLoading(false)
                setErrorStatus("Error")
                setShowSubmitModal(!showSubmitModal)
                setShowErrorModal(true);
                setCommentsForSubmit('')
            }

        }
        else {
            setLoading(false)
            setErrorStatus("Error")
            setShowSubmitModal(!showSubmitModal)
            setShowErrorModal(true);
        }


    }


    const ApproveRebateProgramLine = () => {
        let programlineid = rebateprogrmlinedetail.id;
        let userid = loggedInUser;// hardcoded right now
        setLoading(true);
        Service.UpdateProgramlineStatus('Scheme/UpdateProgramlineStatus', programlineid, "approved", userid, commentsForApproved).then((res) => {
            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {


                    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", props.id, loggedInUser).then((res) => {
                    });
                    toast.success('Approved Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/SchemeList/pending")
                    });
                } else
                    setLoading(false);
            }
            else {
                setLoading(false);
            }

        });

    }

    const DenyRebateProgramLine = () => {
        let programlineid = rebateprogrmlinedetail.id;
        let userid = loggedInUser;// hardcoded right now
        setLoading(true);
        Service.UpdateProgramlineStatus('Scheme/UpdateProgramlineStatus', programlineid, "denied", userid, commentsForDenied).then((res) => {

            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", props.id, loggedInUser).then((res) => {
                    });


                    toast.success('Denied Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/SchemeList/pending")
                    });
                } else
                    setLoading(false);
            } else {
                setLoading(false);
            }

        });

    }
    const showApprovedDialog = (flag) => {
        if (flag === 'showApprovedDialog') {
            setCommentsForApproved("")
            setShowApprovedModal(!showApprovedModal)
        }
        else if (flag === 'proceed') {
            ApproveRebateProgramLine()
            setShowApprovedModal(!showApprovedModal)

        }
    }


    const showDeniedDialog = (flag) => {
        if (flag === 'showDeniedDialog') {
            setCommentsForDenied("")
            setShowDeniedModal(!showDeniedModal)
        }
        else if (flag === 'proceed') {
            DenyRebateProgramLine()
            setShowDeniedModal(!showDeniedModal)

        }
    }


    const DeleteGeneratedAccruals = (id, user, actionType) => {
        setToggleStatusModal1(false)
        setLoading(true)
        Service.postWithlineIdanduserId('Scheme/deleteGeneratedAccruals', id, user).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data == 'Deleted Successfully') {
                            setLoading(false)
                            if (actionType == "Revise") {
                                setShowAccrualDialogForRevise(false)
                                ReviseProgramLine();
                            }
                            else {
                                ToggleProgramLineActiveStatus(activeData, activeStatus)
                            }

                        }
                    }
                }
            }
        })
    };

    const checkActiveStatus = (val, flag) => {
        console.log(val, "Val");
        setActiveData(val);
        setactiveStatus(flag)
        Service.postWithData('Scheme/CheckActiveStatus', val.Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data == "Accrual generated is in new state. Do you want to delete generated accruals for this scheme ?") {
                            setMessage(res.data)
                            setToggleStatusModal1(true)
                        }
                        else if (res.data == "Accrual generated is in submitted state.Unable to deactivate scheme") {
                            setMessage(res.data)
                            setToggleStatusModal2(true)
                        }
                        else if (res.data == "Success") {
                            ToggleProgramLineActiveStatus(val, flag)
                        }
                        else if (res.data == "") {
                            ToggleProgramLineActiveStatus(val, flag)
                        }
                    }
                }
            }
        });
    }

    const ToggleProgramLineActiveStatus = (val, flag) => {
        setToggleId(val)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            if (val.Status != "New") {
                Service.activateRebateProgram('Scheme/ToggleProgramLineActiveStatus', state.id, toggleId.Id, loggedInUser).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (res.data.Status == "Success") {
                                    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                    getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")


                                    setToggleStatusModal(false)
                                }
                                else {
                                    setErrorMessage(t('Rebates.Validations.CannotActivateTheProgramLine.ActiveProgramLineForTheSameSalesLevel,ProductAndValidityAlreadyExists'));
                                    setShowErrorModal(true)
                                }
                            }
                        }
                    }

                });
            }
            else {
                setErrorMessage(`${t('Rebates.Validations.YouCannotActivateThisProgramLine')}`)
                setErrorStatus("Error")
                setShowErrorModal(true)

            }
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }

    }



    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {
            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {
                setMultiValueIds(checkedValue)
                showDeleteDialog('open')

                //changes here - settotalselectedcount to zero and  settotalselectedshow to false -- by dheeraj S
                settotalselectedcount(0)
                settotalselectedshow(false)

                setshowbutton(false)
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.id)
            }
            checkedMultiValueid.map(i => {
                if (i.includes('Approved') || i.includes('InReview') || i.includes('Denied')) {
                    setErrorDeleteModal(true)
                }
                else {
                    var z = i.split(",");
                    multiValueids.push(z[0])
                    showDeleteDialog('open')
                }
            })
            // const matchingObjects = rebateprogrmlinedetail.filter(obj => checkedMultiValueid.includes(obj.Id.toString()));
            // let hasApprovedInReviewOrDenied = false;

            // matchingObjects.forEach(i => {
            //     if (i.Status?.includes('Approved') || i.Status?.includes('InReview') || i.Status?.includes('Denied')) {
            //         hasApprovedInReviewOrDenied = true;
            //     } else {
            //         var idAsString = i.Id.toString();
            //         multiValueids.push(idAsString);
            //         showDeleteDialog('open');
            //     }
            // });

            // if (hasApprovedInReviewOrDenied) {
            //     setErrorDeleteModal(true);
            // }

            //changes here - settotalselectedcount to zero and  settotalselectedshow to false -- by dheeraj S
            settotalselectedcount(0)
            settotalselectedshow(false)
        }
    }

    const OnDeleteHandler = (id) => {
        setLoading(true);
        Service.updatedatabyRebateProgramId('Scheme/DeleteProgramLine', id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                setLoading(false);
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        showDeleteDialog('close')
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,

                        });

                        setMultiValueIds([])
                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                        getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

                    }
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }

        });
    }


    const setAccuralCaldefaultValue = (id) => {
        Service.getdatabyId("AccrualCalcType/GetById", id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setSelectedOptionsAccrualCal(res.data.value)
                }
            }

        });
    }

    const setComparisiondefaultValue = (id) => {
        Service.getdatabyId("ComparisonDateFormat/GetById", id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    setSelectedOptionsCompDateFormat(res.data.value)

                    setChangeHandle(true)
                    let label = res.data.Name;
                    let value = res.data.Id;

                    setComparisonDateFormatName(label);
                    let valid_from = rebateprogrmlinedetail.validityFrom
                    let valid_to = rebateprogrmlinedetail.validityTo
                    if (label === 'lastfiscalyear' || label === 'last12months') {
                        getComparisonDate(label, valid_from);
                    }
                    else {
                        setComparisonStartDate('')
                        setComparisonEndDate('')
                        setComparisonDateEditable(true)
                    }
                }
            }

        });
    }

    const ClearAndOpen = () => {
        setrebateProgramLinedetail({
            id: 0, rebateProgramLineId: 0, rebateProgramDesc: "",
            rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, settlementFreqId: selectedSettlementFreq,
            settlementFreq: "", active: true, deleted: false, statusId: 0, status: "", geography: [],
            Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", AchievementPolicyId: 0,
            validityFrom: moment(state.startDate).toDate(), validityTo: moment(state.endDate).toDate(),
            comparisondateformatId: 0
        })
        clearRplData()
        OnClickCreateRebateProgram()
    }

    const OnClickCreateRebateProgram = () => {
        getdataProductSelectorByAttribute(0);
        let valid = SaveRebateHeaderHandler('save');
        setSelectedRebateCategory('')
        // setrebateProgramLinedetail({ Justification: "" })
        if (justificationRef.current) {
            justificationRef.current.value = "";
        }

        if (valid === true) {

            let today = moment(new Date());
            setshowrebateprogram(true);
            setShowCreateEditRebatePgm(false)
            setCustChngHandler(true)
            SetProducts([]);
            SetFiltedProducts([]);
            setIsProgramLineConfirmed(0);
            getrebateCategoryData();
            setAccuralCaldefaultValue(SessionManage.getAccrualCalc())
            setComparisiondefaultValue(SessionManage.getComparisonPeriod())
            setSelectedOptionsPayout();
            // setSelectedOptionsRebateMeasure();
            setSelectedOptionsRebateMeasureDisabled();
            setSelectedOptionsRebateType();
            setmeasureValueLabel("");
            // if (selectedOptionsSetlFreq?.length !== 0) {
            //     setlFreqData.map((i) => {
            //         if (selectedOptionsSetlFreq.label == i.label) {
            //             setSelectedOptionsSettlFreq(i);
            //         }
            //     })
            // }
            // setlFreqData.forEach((each) => {
            //     if (state.setlFreqID == each.Id && each.Name == "SubDivideTarget") {
            //         setHasSubdivideTarget(true)
            //         setdisableSubDivideTarget(true)
            //         setSelectedSettlementFreq('SubDivideTarget')
            //     }
            // })

            // if (moment(state.startDate).toDate() > moment(today).toDate()) {
            //     let updatedSetlFreq = 0
            // if (selectedSettlementFreq === null || selectedSettlementFreq === 0 || selectedSettlementFreq === undefined || selectedSettlementFreq === '') {
            //     setlFreqData?.forEach((each) =>{
            //         if(each.Name === "EndOfPeriod" || each.Name === "End Of Period"){
            //             updatedSetlFreq = each.value
            //         }
            //     })
            // }
            setDate([fyStartDate, fyEndDate])
            // }
            // else {
            //     setDate([new Date(), state.endDate])
            //     setrebateProgramLinedetail({
            //         id: 0, rebateProgramLineId: 0, rebateProgramDesc: "",
            //         rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, settlementFreqId: selectedSettlementFreq,
            //         settlementFreq: "", active: true, deleted: false, statusId: 0, status: "", geography: [],
            //         Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", validityFrom: moment(today).toDate(), validityTo: moment(state.endDate).toDate(),
            //         comparisondateformatId: 0

            //     })
            // }
        }
        setrebatelinestatus('New');
        setSaveProgramLine(true);

        setSubdivideTargetid(0);
        setDefineMinThreshold(false);
        setHasPaymentTerms(false);
        setMinThresholdValue('');
        setMinThresholdPayout('');
        setPaymentTermsId(0);
        setShowTargetSelection('');

        setShowTargetTable(false)
        setHfPayoutList([])
        setSalesLevelData([])
        if (state.CustomerGroup == false) {
            getSalesOfficeDataNew("SalesOffice/GetBySalesOrgID")
        }
        else {
            getSalesOfficeDatabyGroup("Rebate/GetCustomerBasedOnGroupId", props.id)
        }
    }

    const CloseRebateProgrmLine = (flag) => {

        if (flag === "RPLOpen" && disableProgramLineField == false && changeHandle == true) {

            setCloseModal(true)
        }
        else if (flag === "RPLClose" && disableProgramLineField == false && changeHandle == true) {

            paging.pageSize = 20
            paging.pageNumber = 1
            setpaging(paging)

            setshowrebateProgramLine(false);
            navigate("/new-product-scheme/" + state.id + "/fromScheme/0")
            getRebateHeaderDetails("Scheme/GetSchemeHeader", state.id)
            var sortType = columnAscending === true ? 'Ascending' : 'Descending';
            getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

            setshowrebateprogram(false);
            setShowCreateEditRebatePgm(true);
            setLoadedFrom('fromScheme');
            setProductName('');
            setshowbutton(false);
            setCloseModal(false)
            setChangeHandle(false)
        }
        else if (flag === "RPLCancel" && disableProgramLineField == false && changeHandle == true) {

            setCloseModal(false)
        }
        else if (flag === "RPLOpen" && changeHandle == false) {

            paging.pageSize = 20
            paging.pageNumber = 1
            setpaging(paging)
            setshowrebateProgramLine(false);
            navigate("/new-product-scheme/" + state.id + "/fromScheme/0")
            getRebateHeaderDetails("Scheme/GetSchemeHeader", state.id)

            var sortType = columnAscending === true ? 'Ascending' : 'Descending';
            getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")

            setshowrebateprogram(false);
            setShowCreateEditRebatePgm(true);
            setLoadedFrom('fromScheme');
            setProductName('');
            setshowbutton(false);
            setCloseModal(false)
            setChangeHandle(false)

        }
        clearRplData()
    }

    const OnConditionChange = (e, index) => {
        if (e.target.value !== 'Select') {
            let list = [...VolumeRebateList]
            list[index]['Condition'] = e.target.value
            if (list.length > 1) {
                list.forEach((each, idx) => {
                    if (idx > 0) {
                        each.Condition = e.target.value
                    }
                })
            }
            setVolumeRebateList(list)
            setConditionValue(e.target.value);
        }
    };

    const clearRplData = () => {
        setProductTargetList([{
            ProductTargets: [{
                Slab: "Slab1",
                Rebate: '',
                Rebatevalue: '',
                Target_Measure: 'InvoicePrice',
                IncludeEligibilityCheck: false,
                IncludeAccrualDistribution: false,
            }],
            Products: [],
            Pdtset: 1

        }])
        setVolumeRebateList([{
            Targetcondition: "",
            measureValues: [],
            Slab: "Slab1",
            Condition: ""
        }])
        const updatedMeasureOptions = measureOptions.map(each => ({
            ...each,
            Selected: false
        }));
        setMeasureOptions(updatedMeasureOptions)
        setSelectedRebateMeasureOptions([])
        setSelectedRebateCategory("")
    }
    const onTargetChange = (e, index) => {
        if (e.target.value !== 'Select') {
            let list = [...VolumeRebateList]
            list[index]['Targetcondition'] = e.target.value

            if (list.length > 1) {
                list.forEach((each, idx) => {
                    if (idx > 0) {
                        each.Targetcondition = e.target.value
                    }

                })
            }
            setVolumeRebateList(list)
        }
    }

    const onMeasureValueChange = (e, index, dataIndex, name) => {
        let list = [...VolumeRebateList]
        let updatedMeasureValue = [...list[index].measureValues]

        updatedMeasureValue[dataIndex][name] = e.target.value
        list[index].measureValues = updatedMeasureValue
        setVolumeRebateList(list)
    }

    const CloseLoadedFromApproval = () => {
        setshowrebateProgramLine(false);
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Product Scheme", props.id, loggedInUser).then((res) => {

        });
        if (loadedFrom === 'fromApproval') {
            navigate("/SchemeList/pending");
        }
        else {
            navigate("/SchemeList/archive");
        }
    }

    const ValidateProgramLine = () => {

        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        let validFrom = ""
        let validTo = ""

        if (rebateprogrmlinedetail.id == 0) {
            validFrom = valid_from
            validTo = valid_to
        } else {
            validFrom = valid_from;
            validTo = valid_to;
        }



        let isValidProgramLine = true;

        let optList = SalesLevelData.filter((e) => e.selected === true)

        if (moment(validFrom).isBefore(state.startDate)) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
            isValidProgramLine = false;
        }
        else if (moment(validTo).isAfter(state.endDate)) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
            isValidProgramLine = false;
        }
        else if (moment(validTo).isBefore(moment(validFrom))) {
            setErrorMessage(t('Rebates.Validations.TheProgramValidityToShouldBeGreaterThanProgramValidityFrom'))
            isValidProgramLine = false;
        }

        // else if (VolumeRebateList.length == 0) {
        //     setErrorMessage(`${t('Please Enter')} ${t('Rebates.Validations.VolumeRebateDetails')}`)
        //     isValidProgramLine = false;
        // }
        // else if (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') {
        //     if (VolumeRebateList.length == 1) {
        //         setErrorMessage(t('Rebates.Validations.TargettedPayoutConditionShouldHaveMoreThan1Tier'))
        //         isValidProgramLine = false;
        //     }
        // }
        // VolumeRebateList.forEach(item => {
        //     let idx = parseInt(VolumeRebateList.indexOf(item)) + 1
        //     if (item.Targetcondition == '' || item.Targetcondition == null || item.Targetcondition == 'Select') {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.TargetCond')} ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (item.Measure_Value == '' || item.Measure_Value == null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.MeasureValue')} ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (item.Rebate == '' || item.Rebate == 'USD' || item.Rebate == null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.Rebate')} ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (item.Rebate_Value == '' || item.Rebate_Value == null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.RebateValue')} ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (item.Target_Measure == '' || item.Target_Measure == null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.TargetMeasure')} ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (item.Rebate_Value == "%" && item.Rebate > 100) {
        //         setErrorMessage(`${t('Rebates.Validations.RebateValueCannotExceed100')}  ${t('Rebates.Validations.InRow')} ${idx}`)
        //         isValidProgramLine = false;
        //     }


        // })
        // if (FiltedProducts.length == 0) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.Products')}`)
        //     isValidProgramLine = false;
        // }
        if(showrebateprogram === true){
        if (rebateprogrmlinedetail.rebateProgramDesc == null || rebateprogrmlinedetail.rebateProgramDesc == '') {
            setErrorMessage(`${t('Rebates.PleaseenterDescription')}`)
            isValidProgramLine = false;
        }
        else if (rebatePayoutCondData.length === 0) {
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.RebateType')}`)
            isValidProgramLine = false;
        }
        else if (rebateprogrmlinedetail.Rebate_Payout_Cond_Id == 0 || rebateprogrmlinedetail.Rebate_Payout_Cond_Id == null) {
            // setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.RebateType')} and ${t('Rebates.PayoutCond')}`)
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.PayoutCond')}`)
            isValidProgramLine = false;
        }
        else if (SelectedRebateMeasureOptions.length == 0 && SelectedRebateMeasureOptions.length == 0) {
            setErrorMessage(`${t('Pleaseselect')} ${t('Rebates.RebateMeasure')}`)
            isValidProgramLine = false;
        }
        
        // else if (rebateprogrmlinedetail.settlementFreqId == 0 || rebateprogrmlinedetail.settlementFreqId == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.AccrualFreq')}`)
        //     isValidProgramLine = false;
        // }
        // else if (selectedOptionsRebateMeasure == 0 || selectedOptionsRebateMeasure == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.RebateMeasure')}`)
        //     isValidProgramLine = false;
        // }
        // else if (rebateprogrmlinedetail.settlementFreqId == 0 || rebateprogrmlinedetail.settlementFreqId == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.AccrualFreq')}`)
        //     isValidProgramLine = false;
        // }

        // else if (rebateprogrmlinedetail.rebateTypeId == 0 || rebateprogrmlinedetail.rebateTypeId == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.RebateType')}${t(rebateprogrmlinedetail.rebateTypeId)}`)
        //     isValidProgramLine = false;
        // }
        // else if (rebateprogrmlinedetail.Rebate_Payout_Cond_Id == 0 || rebateprogrmlinedetail.Rebate_Payout_Cond_Id == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.Rebate')} ${t('Rebates.PayoutCond')}`)
        //     isValidProgramLine = false;
        // }

        // else if (optList == 0 || optList == null) {
        //     setErrorMessage(`${t('Please Select')} ${t('Rebates.Region')}`)
        //     isValidProgramLine = false;
        // }

        // else if (rebateTypeValue == 'Growth in Rev' || rebateTypeValue == 'Growth in Vol') {
        //     if (comparisonEndDate < comparisonStartDate) {
        //         setErrorMessage(t('Rebates.Validations.ComparisonEndDateShouldBeGreaterThenStartDate'));
        //         isValidProgramLine = false;
        //     }
        //     else if (moment(comparisonEndDate).isAfter(moment(validFrom))) {
        //         setErrorMessage(t('Rebates.Validations.ComparisonEndDateShouldBeLessThanProgramLineStartDate'));
        //         isValidProgramLine = false;
        //     }

        //     else if (moment(comparisonStartDate).isAfter(moment(validFrom))) {
        //         setErrorMessage(t('Rebates.Validations.ComparisonStartDateShouldBeLessThanProgramLineStartDate'));
        //         isValidProgramLine = false;
        //     }
        //     else if (comparisonStartDate == null || comparisonStartDate == '' || comparisonStartDate == undefined) {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.GrowthComparisonStartDate')}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (comparisonEndDate == null || comparisonEndDate == '' || comparisonEndDate == undefined) {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.GrowthComparisonEndDate')}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (rebateprogrmlinedetail.comparisondateformatId == 0 || rebateprogrmlinedetail.comparisondateformatId == null) {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.GrowthComparisonDateFormat')}`)
        //         isValidProgramLine = false;
        //     }

        //     else if (rebateprogrmlinedetail.comparisondateformatId == 0 || rebateprogrmlinedetail.comparisondateformatId == null) {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.GrowthComparisonDateFormat')}`)
        //         isValidProgramLine = false;
        //     }



        // else if (hasSubdivideTarget === true) {
        //     if (showtargetselection === '' || showtargetselection === null) {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.DefineTargetBy')}`)
        //         isValidProgramLine = false;
        //     } else {
        //         hfPayoutList.forEach(item => {
        //             var Targets = item.MeasureValue;
        //             var Payout = item.RebateValue;
        //             if (item.MeasureValue === '' || item.MeasureValue === null) {
        //                 setErrorMessage(`${t('Please Enter')} ${showtargetselection} ${t('Rebates.TargetValue')}`)
        //                 isValidProgramLine = false;
        //             }

        //             else if (item.RebateValue === '' || item.RebateValue === null) {
        //                 setErrorMessage(`${t('Please Enter')} ${showtargetselection} ${'%'} ${t('Rebates.PayoutValue')}`)
        //                 isValidProgramLine = false;
        //             }
        //             else if (item.RebateValue !== '' && item.RebateValue !== null) {
        //                 if (VolumeRebateList.length > 0) {
        //                     if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
        //                         if (parseFloat(item.RebateValue) >= parseFloat(VolumeRebateList[0].Rebate)) {
        //                             setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${showtargetselection} ${t('Rebates.Validations.Payout%SetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
        //                             isValidProgramLine = false;
        //                         }
        //                     }
        //                 }
        //             }

        //         });

        //         var total = 0;
        //         for (var i = 0; i < hfPayoutList.length; i++) {
        //             if (hfPayoutList[i]['MeasureValue'] != '' && hfPayoutList[i]['MeasureValue'] != null)
        //                 total += parseFloat(hfPayoutList[i]['MeasureValue'])
        //         }
        //         if (total != 100) {
        //             setErrorMessage(t('Rebates.Validations.SubdivideTargetValueCannotExceedOrBeLessThan100%'));
        //             setErrorStatus("Error")
        //             setShowErrorModal(true)
        //             isValidProgramLine = false;
        //         }
        //     }
        // }
        // else if (hasPaymentTerms === true) {
        //     if (paymentTermsId === '' || paymentTermsId === null || paymentTermsId == '0') {
        //         setErrorMessage(`${t('Please Select')} ${t('Rebates.PaymentTerms')}`)
        //         isValidProgramLine = false;
        //     }
        // }
        // else if (defineMinThreshold === true) {
        //     if (minThresholdValue === '' || minThresholdValue === null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.ThresholdValue')}`)
        //         isValidProgramLine = false;
        //     }
        //     else if (minThresholdValue !== '' && parseFloat(minThresholdValue) > 100) {

        //         setErrorMessage(t('Rebates.Validations.PleaseMakeSureThatThresholdValueSetShouldAlwaysBeLessThan100'))
        //         isValidProgramLine = false;
        //     }
        //     else if (minThresholdPayout === '' || minThresholdPayout === null) {
        //         setErrorMessage(`${t('Please Enter')} ${t('Rebates.PayoutPercent')}`)
        //         isValidProgramLine = false;
        //     }

        //     else if (minThresholdPayout !== '' && minThresholdPayout !== null) {
        //         if (VolumeRebateList.length > 0) {
        //             if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
        //                 if (parseFloat(minThresholdPayout) >= parseFloat(VolumeRebateList[0].Rebate)) {
        //                     setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutPercentSetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
        //                     isValidProgramLine = false;
        //                 }
        //             }
        //         }
        //     }
        // }
        if (isValidProgramLine) {
            VolumeRebateList.forEach((each, index) => {
                if (!each.Targetcondition || each.Targetcondition === 'Select') {
                    setErrorMessage("Please select Target Condition");
                    isValidProgramLine = false;
                } else {
                    each.measureValues.forEach((each1) => {
                        if (!each1.Value) {
                            setErrorMessage(`Please enter all the required Measure Values in row ${index + 1}`);
                            isValidProgramLine = false;
                        }
                    })
                }
            });
        }
        }

        return isValidProgramLine
    }


    const getActiveRebateProgramlines = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setActiveRebateProgramLine(res.data)
                    setshowspinneraccural(false);
                }
            }

        });
    }

    const changeIcon = (e, id) => {
        setExpandAccruals(id)
        if (icon == 'fa fa-plus') {
            setIcon('fa fa-minus');
        }
        if (icon == 'fa fa-minus') {
            setIcon('fa fa-plus');
        }

    }

    const getTotalAccrualAmount = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    let rData = res.data;
                    setTotalAccrualMoney(res.data);
                }
            }
        });
    }

    const clickShowAccuralWorkflow = (val) => {
        let pageSize = 20
        let pageNumber = 1
        setshowproductpopup(false);
        setshowAccuralWorkflow(true);
        setsidebarwidth("70vw");
        if (val === "Accural") {
            setshowWorkflow(true);
        }
        else {
            setshowWorkflow(false);
        }
    }

    const clickShowWorkflow = (val, id) => {
        setshowproductpopup(false);
        setsidebarwidth("70vw");
        getRebateProgramLineDetails("GetSchemeProgramLinesDetails", id, "productscheme");
        // setTimeout(() => {
        //     getRebateWorkflowApprovers('Scheme/GetSchemeWorkflowApprovers', id);
        // }, 500);
        if (val === "Accural") {
            setshowWorkflow(true);
        }
        else if (val === "Workflow")
            setshowAccuralWorkflow(true);
        setshowWorkflow(false);
        getRebateWorkflowDetails(id, paging.pageSize, paging.pageNumber)
    }


    const clickCloseAccuralWorkflow = () => {
        setshowproductpopup(false);
        setshowAccuralWorkflow(false);
        setsidebarwidth("0vw");
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
    }
    const clickShowregionPopup = (val) => {
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(true);
        setsidebarwidth("60vw");
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);

    }
    const clickShowProductPopup = (e, index) => {
        
        console.log(dualListBoxOptionsByAttribute,"Product List 10-15")
        inputField.addproductdata = true;
        setshowAccuralWorkflow(false);
        setshowproductpopup(true);
        setsidebarwidth("70vw");
        SetSlabIndex(index)

        let list = [...ProductTargetList]
        let updatedProducts = [...list[index].Products]
        if (updatedProducts.length > 0) {
            SetFiltedProducts(updatedProducts)
        }
        else {
            SetFiltedProducts([])
        }
    }
    const clickShowCustomerPopup = (val) => {
        setshowAccuralWorkflow(false);
        setCustomercheckAll(false);

        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(true);
        setShowSelectedCustomerpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setsidebarwidth("63vw");

    }
    const clickSelectedShowCustomerPopup = (val) => {
        let lstcopy = customerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setCustomerDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(true);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setsidebarwidth("63vw");
    }

    const clickSelectedShowRegionPopup = (val) => {
        let lstcopy = SalesLevelData.filter((e) => e.selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setRegionDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setShowSelectedRegionpopup(true);
        setShowSelectedProductpopup(false);
        setsidebarwidth("55vw");
    }
    const clickSelectedShowProductPopup = (e, index) => {
        let lstcopy = FiltedProducts

        let lst1 = [];
        setProductDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(true);
        setsidebarwidth("55vw");
        SetSlabIndex(index)

        let list = [...ProductTargetList]
        let Products = [...list[index].Products]
        ////debugger
        const newArray = Products.map(item => parseInt(item.split('_').pop()));
        dualListBoxOptionsByAttribute.forEach((each) => {
            each.Selected = false
        })
        dualListBoxOptionsByAttribute.forEach((each) => {
            newArray.forEach((selectedProducts) => {
                if (each.Id === selectedProducts) {
                    each.Selected = true;
                }
            });
        });

    }



    const getSelectedProduct = (programId) => {
        Service.delete('Scheme/GetSelectedProducts', programId).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                console.log("15-3api", res.data.DataList)
                console.log("15-3api", res.data)
                SetFiltedProducts(res.data)

            }
        });
    };

    const showproducts = (id, status) => {
        setShowProductLoadSpinner(true)
        getSelectedProduct(id);
        setrebatelinestatus(status);
        setsidebarwidth("55vw");
        setclickProduct(true);
        setTimeout(() => {
            setrplSelectedProducts(true);
            setShowProductLoadSpinner(false)
        }, 1000);
        console.log("31-7", status)
    }


    const OnCopyHandler = (id, status) => {
        setLoading(true);
        let pagesize = 20 * paging.pageNumber
        let pagenumber = 1
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (id != 0 && status != 0) {
            Service.copybyRebateProgramId('Scheme/CopyProgramLine', state.id, id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    setLoading(false);
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        toast.success('Copy Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                        getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")


                        //changes here - settotalselectedcount to zero and  settotalselectedshow to false -- by dheeraj 
                        checkedBoxes.forEach(box => box.checked = false);
                        settotalselectedcount(0)
                        settotalselectedshow(false)

                        setshowbutton(false)
                    }
                    else {
                        setLoading(false);
                    }

                } else {
                    setLoading(false);
                }

            });

            paging.pageSize = 20
            paging.pageNumber = pagenumber
            setpaging(paging)


        }
        else {
            setErrorMessage(t('Rebates.Validations.PleaseSelectTheProgramLineYouWantToCopy'))
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
    }


    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getRebatePrgramLines("GetSchemeProgramLines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value, "");
        }
        else {
            setSearchValue(null);
            getRebatePrgramLines("GetSchemeProgramLines/rebateid", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null, "");
        }
    }


    const CloseAgreement = (flag) => {

        if (flag == "CloseHeader" && changeHandleHeader == true) {
            setCloseHeaderModal(true)
        }
        if (flag == "HeaderClose" && changeHandleHeader == true) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Product Scheme", state.id, loggedInUser).then((res) => {
            });
            navigate("/scheme-program/0")
            setCloseHeaderModal(false)
        }
        if (flag == "HeaderCancel" && changeHandleHeader == true) {
            setCloseHeaderModal(false)
        }
        if (flag == "CloseHeader" && changeHandleHeader == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Product Scheme", state.id, loggedInUser).then((res) => {
            });
            if (props.name.toLowerCase() == 'frommassactionrenew') {
                navigate('/MassActionList/')
            }
            else if (props.name.toLowerCase() == 'frommassactionsubmit') {
                navigate('/MassActionSubmitList/')
            }
            else {
                navigate("/scheme-program/0")
            }
        }
    }
    const SaveHandler = () => {
        SaveRebateHeaderHandler('save');
        if (state.id !== '0') {
            if (saveProgramLine === true) {
                if(showrebateprogram === true){
                    saveRebateProgramLine();
                }
            }

        }
    }


    console.log("27-3A", workflowApprovers)
    console.log("27-3L", workflowLevelCount)
    console.log("27-3C", workflowchart)
    console.log("27-3D", workflowData)

    const getRebateWorkflowApprovers = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setWorkflowApprovers(res.data)
                    setWorkflowLevelCount(res.data.length)
                    setworkflowchart(res.data);
                }

            }

        });
    }
    const getAccrualCalcType = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setAccrualCalcTypeData(res.data)
                }
            }
        });
    }



    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue, "");

    }

    const OnChangeHandlercustomer = (data, flag) => {
        let lists = [];
        for (var i = 0; i <= data.length - 1; i++) {
            lists.push(parseInt(data[i].value));
        }
        setSelectedOptions(data)
        if (custChngHandler === true && flag === "Ok") {
            setCustChngModal(false)
            setChangeHandleHeader(true)
            setState({ ...state, ['customerID']: data[0].value })
            setState({ ...state, ['Customer_Ids']: lists })

        }
        if (custChngHandler === false && flag === "change") {
            setChangeHandleHeader(true)
            setSelectedOptions(data)
            setState({ ...state, ['customerID']: data[0].value })
            setState({ ...state, ['Customer_Ids']: lists })
            if (data.value > 0) {

            }
        }
        setCustomerChanged(true);
    }

    const OnChangeHandlerSetlMethod = (data) => {

        setChangeHandleHeader(true)
        setSelectedOptionsSetlMethod(data.label)
        setState({ ...state, ['setlMethodID']: data.value })
    }

    const OnChangeHandlerSetlFreq = (data) => {
        // console.log(data, "setlfre");
        // alert('hi');
        if (data.Name == 'SubDivideTarget') {
            setHasSubdivideTarget(true)
            setdisableSubDivideTarget(true)
        }
        setSelectedOptionsSetlFreq(data.Id)
        setState({ ...state, ['setlFreqID']: data.Id })
    }


    const OnChangeHandlerCurrency = (data) => {
        setChangeHandleHeader(true)
        console.log(data, "cd");
        setSelectedOptionsCurrency(data.label)
        setState({ ...state, ['currencyID']: data.value })
    }

    const OnChangeHandlerautoPostAccruals = (data) => {

        setSelectedOptionsautoPostAccruals(data.label)
        setState({ ...state, ['autoPostAccruals']: data.value })
        setChangeHandleHeader(true)
    }

    console.log("daterpl", rebateprogrmlinedetail.validityFrom, rebateprogrmlinedetail.validityTo)
    console.log("dateheader", state.startDate, state.endDate)
    console.log("datefy", fyStartDate, fyEndDate)

    const OnChangeHandlerLineStartDate = (e) => {

        //    ////debugger
        //     if (!fyEndDate || e > fyEndDate) {
        //         this.setState({ fyEndDate: null });
        //     }

        // handleEndDateChange = (date) => {
        //     const { fyStartDate } = this.state;
        //     this.setState({ fyEndDate: date });
        //     if (!fyStartDate || fyStartDate > date) {
        //         this.setState({ fyStartDate: null });
        //     }
        // };
        setFyStartDate(new Date(moment(e)));
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['validityFrom']: e });
        if (rebateTypeValue == 'Growth in Rev' || rebateTypeValue == 'Growth in Vol') {
            if (comparisonDateFormatName == 'last12months' || comparisonDateFormatName === 'lastfiscalyear') {
                getComparisonDate(comparisonDateFormatName, e);
            }
        }
    };
    const OnChangeHandlerLineEndDate = (e) => {
        setFyEndDate(new Date(moment(e)));
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['validityTo']: e });
        let validFrom = rebateprogrmlinedetail.validityFrom;

    };
    const filterLastDaysOfMonth = (date) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Get the last day of the current month
        return date.getDate() === nextMonth.getDate(); // Check if the current date is the last day of the month
    };

    const OnChangeHandlerRebateType = (data) => {
        setChangeHandle(true)
        setRebateIcon("")
        setmeasureValueLabel("");
        // setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateTypeId']: data.Id, ['rebateMeasureId']: 0, ['Rebate_Payout_Cond_Id']: 0, ['comparisondateformatId']: 0 })
        setRebateTypeValue(data.Name);
        setSelectedOptionsRebateType(data.Id)
        setSelectedOptionsRebateMeasure('')
        setSelectedOptionsRebateMeasureDisabled('')
        setSelectedOptionsPayout('')
        getRebateMeasureData("RebateMeasure/GetAll/", data.Id, 'RebateType');
        getRebatePayoutData("RebatePayout/GetAll", data.Id)
    }

    const OnChangeHandlerSettlFreqmethod = (data) => {
        setChangeHandle(true)
        setSelectedOptionsSettlFreq(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['settlementFreqId']: data.Id })
    }

    const OnChangeHandlerRebateMeasure = (data) => {
        setChangeHandle(true)
        //setSelectedOptionsRebateMeasure(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateMeasureId']: data.Id })

        if (data.Name === 'Quantity' || data.Name === 'Growth in Qty (Abs)') {
            setmeasureValueLabel('Unit')
        }
        else if (data.Name === 'Revenue' || data.Name === 'Growth in Rev (Abs)') {
            setmeasureValueLabel('Amount')
        }
        else if (data.Name === 'Growth in Qty (%)' || data.Name === 'Growth in Rev (%)') {
            setmeasureValueLabel('%')
        }
        else {
            setmeasureValueLabel('')
        }
    }

    const OnChangeHandlerCategory = (data) => {

        setSelectedRebateCategory(data.Id);
    }

    const OnChangeHandlerPayout = (data) => {
        setSelectedOptionsPayout(data.Id)
        setChangeHandle(true)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['Rebate_Payout_Cond_Id']: data.Id })

        setRebatePayoutCond(data.Name)
        if (data.Name === 'Stepped' || 'Targeted') {
            setTargetPayCond(false)
            setRebateIcon("")
        }
        if (data.label === 'Lumpsum') {
            setTargetPayCond(true)

            setRebateIcon("LS")
        }
        if (data.label === 'Unit') {
            setTargetPayCond(true)

            setRebateIcon("₹")
        }
        if (data.label === 'Percent') {
            setTargetPayCond(true)

            setRebateIcon("%")
        }

        if (data.label === 'Select') {
            setTargetPayCond(true)

            setRebateIcon("")
        }

        setVolumeRebateList([{
            Targetcondition: "",
            measureValues: SelectedRebateMeasureOptions?.map((each) => {
                return {
                    Name: each.Name,
                    Label: each.label,
                    Value: '',
                    symbol: (() => {
                        if (each.Name === "measurevalue_qty" || each.Name === "measurevalue_qtygrowth") {
                            return "Unit";
                        } else if (each.Name === "measurevalue_contribution" || each.Name === "measurevalue_revgrowth") {
                            return "%";
                        } else if (each.Name === "measurevalue_revenue") {
                            return "Amount";
                        } else {
                            return "";
                        }
                    })()
                };
            }),
            Slab: "Slab1",
            Condition: ""
        }]);


        setProductTargetList([
            {
                ProductTargets: [{
                    Slab: "Slab1",
                    Rebate: data.label === 'Lumpsum' ? "LS" : data.label === 'Percent' ? "%" : "",
                    Rebatevalue: '',
                    Target_Measure: 'InvoicePrice',
                    IncludeEligibilityCheck: false,
                    IncludeAccrualDistribution: false,
                }],
                Products: [],
                Pdtset: 1
            }
        ])
    }

    const OnChangeHandlerAccrualCal = (data) => {
        setChangeHandle(true)
        setSelectedOptionsAccrualCal(data.Id)
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['AccrualCalcTypeId']: data.Id })

    }



    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }




    const OnPopupPeriodChange = (e) => {
        let selectedOption = (e);
        let data = ""
        for (let i = 0; i < selectedOption.length; i++) {
            data = selectedOption[i].RebatePeriod
        }
        setAccrualPeriodValue(data)
    }



    const OnPopupCustomerChange = (e) => {
        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {
            const getallData = {
                CustomerId: selectedOption[i].Id,
                CustomerName: selectedOption[i].Cust_label,
            }
            data.push(getallData)
        }

        setAccrualCustomerValue(data)
    }

    const filterInput = (e) => {
        let inputValue = []
        setfilteringvalue(e.target.value)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const filterInputCustomer = (e) => {
        setFilterValueCustomer(() => ({
            ...filterValueCustomer, [custFilterCol]: e.target.value,
        }));
    }




    const FilterColumn = (name) => {
        var divtop1 = document.getElementById("table-RPL");
        var cardtop1 = document.getElementById("card-RPL");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setFilterCol(name)
        setshowfilterpopup(true)

        if (name === 'RebateCategory') {
            var testDiv = document.getElementById("RebateCategory");
            setfiltertext(t('Rebates.RebateCat'))
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }

        if (name === 'Settlement_Freq') {
            setfiltertext(t('Rebates.AccrualFreq'))
            var testDiv = document.getElementById("Settlement_Freq");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }

        if (name === 'RebateProgramLineId') {
            setfiltertext(t('Rebates.RebateProgramId'))
            var testDiv = document.getElementById("RebateProgramLineId");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");

        }
        if (name === 'RebateProgram_Desc') {
            setfiltertext(t('Rebates.RebateProgramDesc'))
            var testDiv = document.getElementById("RebateProgram_Desc");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");

        }
        if (name === 'Validity_From') {
            setfiltertext(t('Rebates.ValidFrom'))
            var testDiv = document.getElementById("Validity_From");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
        }

        if (name === 'Validity_To') {
            setfiltertext(t('Rebates.ValidTo'))
            var testDiv = document.getElementById("Validity_To");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
        }
        if (name === 'Rebate_Type') {
            setfiltertext(t('Rebates.RebateType'))
            var testDiv = document.getElementById("Rebate_Type");
            setdivleft(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px"));
            DropdownHandler(name)
        }
        if (name === 'Status') {
            setfiltertext(t('Rebates.Status'))
            var testDiv = document.getElementById("Status");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
            DropdownHandler(name);
        }
        if (name === 'Active') {
            setfiltertext(t('Rebates.IsActive'))
            var testDiv = document.getElementById("Active");
            setdivleft(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
            addnewfield(activeOption, name)
        }
        setshowfilterpopup(true)
    }



    const DropdownHandler = (key) => {
        setcurrentFilterKey(key)
        Service.getfilterDropdown('Scheme/GetSchemeProgramlineFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }


    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false; if (key != 'active') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }


    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];
            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getRebatePrgramLines("GetSchemeProgramLines/RebateID", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, "");
            }

            let tempDropdownData = dropdownData;
            tempDropdownData[0]["ischecked"] = true;
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getRebatePrgramLines("GetSchemeProgramLines/RebateID", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setfilteringvalue([])
            setDropdownData([])
            setDropdownChange([])
            setFilterDropdownData([])
            setFilterSearch("")
            setcurrentFilterKey("")
        }
    }


    const OpenRebate = (event, id, line) => {
        console.log(line, "my-line")
        if (event.detail === 2) {
            let list = { ...rebateprogrmlinedetail }
            list.Justification = ""
            setrebateProgramLinedetail(list)
            navigate("/new-product-scheme/" + props.id + "/fromScheme/" + id)
            setshowrebateprogram(true);
            setSubdivideTargetid(0);
            setHasSubdivideTarget(false);
            setDefineMinThreshold(false);
            setHasPaymentTerms(false);
            setPaymentTermsId(0);
            setShowTargetSelection('');
            setRegioncheckAll(false);
            setShowTargetTable(false)
            setVolumeRebateList([])
            getRebateProgramLineDetails("GetSchemeProgramLinesDetails", id, "Product Scheme")
            getdataProductSelectorByAttribute(id);
            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    const getComparisonFormatData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setComparisonFormatData(res.data)
                        }
                    }
                }
            }

        });
    }

    const OnChangeComparisonPeriod = (e) => {

        setChangeHandle(true)
        let label = e.Name;
        let value = e.Id;
        setSelectedOptionsCompDateFormat(value);
        setComparisonDateFormatName(label);
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, comparisondateformatId: value });
        let valid_from = rebateprogrmlinedetail.validityFrom
        let valid_to = rebateprogrmlinedetail.validityTo
        if (label === 'lastfiscalyear' || label === 'last12months') {
            getComparisonDate(label, valid_from);
        }
        else {
            setComparisonStartDate('')
            setComparisonEndDate('')
            setComparisonDateEditable(true)
        }
    }
    const OnChangeHandlerComparisonStartDate = (e) => {
        setComparisonStartDate(e);
        setChangeHandle(true)

    };

    const OnChangeHandlerComparisonEndDate = (e) => {
        setComparisonEndDate(e);
        setChangeHandle(true)
    };

    const getComparisonDate = (label, valid_from) => {
        Service.getComparisonDate("Rebate/GetComparisonPeriod", label, moment(valid_from).format("yyyy-MM-DD")).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setComparisonPeriod(res.data);
                            let a = res.data.split("&&");
                            setComparisonStartDate(moment(a[0]).toDate());
                            setComparisonEndDate(moment(a[1]).toDate());
                            setComparisonDateEditable(false);
                        }
                    }
                }
            }

        });
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const CustomerStyles = {

        searchBox: {
            height: "150px",
            overflowY: "scroll"
        },

        chips: {
            fontSize: "12px",
            lineHeight: "13px",
            display: "block",
            marginTop: "5px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        },

    }

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Rebate", state.id, loggedInUser).then((res) => {

        });

    }


    const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const onTargetChecked = (mycheckbox) => {
        setShowTargetSelection('');
        setHfPayoutList([]);
        setHasSubdivideTarget(mycheckbox.target.checked);
        if (mycheckbox.target.checked == true) {

        }
        else {
            setShowTargetTable(false)
        }
    }

    const onDefineMinThresholdChecked = (mycheckbox) => {
        setDefineMinThreshold(mycheckbox.target.checked);
        setMinThresholdValue('');
        setMinThresholdPayout('');
    }
    const onEnforcePaymentTermsChecked = (mycheckbox) => {
        setHasPaymentTerms(mycheckbox.target.checked);
        setPaymentTermsId(0);
        if (mycheckbox.target.checked == true) {

        }


    }

    const inputsMinimumThresholdHandler = (e, name) => {
        if (name === 'minThresholdValue')
            setMinThresholdValue(e.target.value)
        else
            setMinThresholdPayout(e.target.value)
    }


    const inputTargetHandler = (e, index, name, type) => {
        var newIndex = (index) + 1;
        let list = [];
        list = [...hfPayoutList];

        if (list.length >= newIndex) {
            list[index][name] = e.target.value;
        } else {
            let NewList = { MeasureValue: "", RebateValue: "" };
            list.push(NewList);
            list[index][name] = e.target.value;
        }

        if (name === 'RebateValue') {
            for (var i = 0; i < list.length; i++) {
                list[i][name] = e.target.value;
            }
        }
        setHfPayoutList(list);


    }

    const calculateMeasureValue = (e, index) => {
        const list = [...hfPayoutList];
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            if (list[i]['MeasureValue'] != '' && list[i]['MeasureValue'] != null)
                total += parseFloat(list[i]['MeasureValue'])
        }
        if (total > 100) {
            list[index]['MeasureValue'] = 0;
            setErrorMessage(t('Rebates.Validations.SubdivideTargetValueCannotExceedOrBeLessThan100%'));
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        setHfPayoutList(list);
    }



    const calculatePayout = (e, index) => {
        const list = [...hfPayoutList];

        if (list.length > 0) {
            var RebateValue = list[0]['RebateValue']
            if (VolumeRebateList.length > 0 && (RebateValue !== '' && RebateValue !== null)) {
                if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                    if (parseFloat(RebateValue) >= parseFloat(VolumeRebateList[0].Rebate)) {
                        setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${targetselectionLabel.replace(/%/g, '')} ${t('Rebates.Validations.Payout')} ${(VolumeRebateList[0].Rebate_Value)} ${t('Rebates.Validations.SetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                        setShowErrorModal(true)
                        for (var i = 0; i < list.length; i++) {
                            list[i]['RebateValue'] = 0;
                        }
                        setHfPayoutList(list);
                    }
                    if (VolumeRebateList[0].Rebate_Value === 'LS') {
                        let totalsum = 0
                        for (var i = 0; i < list.length; i++) {
                            totalsum += parseFloat(list[i]['RebateValue'])
                        }

                        if (parseFloat(totalsum) >= parseFloat(VolumeRebateList[0].Rebate)) {
                            setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThat')} ${targetselectionLabel.replace(/%/g, '')} ${t('Rebates.Validations.Payout')} ${(VolumeRebateList[0].Rebate_Value)} ${t('Rebates.Validations.SetShouldAlwaysBeLessThanTheRebateValue')} ${VolumeRebateList[0].Rebate} ${t('Rebates.Validations.setAgainstTier1')}`)
                            setShowErrorModal(true)
                            for (var i = 0; i < list.length; i++) {
                                list[i]['RebateValue'] = 0;
                            }
                            setHfPayoutList(list);
                        }
                    }
                }
            }
        }
    }

    const calculateThreshold = (e, name) => {

        if (name === 'Value') {
            if (minThresholdValue !== '' && minThresholdValue !== null && parseFloat(minThresholdValue) > 100) {
                setErrorMessage(t('Rebates.Validations.PleaseMakeSureThatThresholdValueSetShouldAlwaysBeLessThan100') + '%')
                setErrorStatus("Error")
                setShowErrorModal(true)
                setMinThresholdValue(0)
            }
        } else if (name === 'Payout') {
            if (minThresholdPayout !== '' && minThresholdPayout !== null) {
                if (VolumeRebateList.length > 0) {
                    if (VolumeRebateList[0].Rebate != '' && VolumeRebateList[0].Rebate != null) {
                        if (parseFloat(minThresholdPayout) >= parseFloat(VolumeRebateList[0].Rebate)) {
                            if (rebateIcon == '%') {
                                setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutPercentSetShouldAlwaysBeLessThanTheRebateValue')} ${t('Rebates.Validations.setAgainstTier')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setMinThresholdPayout(0);
                            }
                            else {
                                setErrorMessage(`${t('Rebates.Validations.PleaseMakeSureThatPayoutSetShouldAlwaysBeLessThanTheRebateValue')} ${t('Rebates.Validations.setAgainstTier')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                setMinThresholdPayout(0);
                            }
                        }
                    }
                }
            }
        }

    }

    const OnCusdropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        let allCheckedCustomer = false;

        let allCheckboxesCustomer = document.getElementsByName("filter-option-all-customer");
        let totalCheckedCustomer = 0;
        allCheckboxesCustomer.forEach((checkbox) => {
            if (checkbox.checked)
                totalCheckedCustomer += 1;
        });

        let tempData = [...filterCusDropDownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalCheckedCustomer) {
            tempData[0].selected = isChecked;
            allCheckedCustomer = true;
        }

        setFilterCusDropDownData(tempData);

        console.log(tempData, "DATA");

        var lastfilter = [...filterCusDropDownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i.selected = true;
                }
                return i;
            });
            setFilterCusDropDownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
            let updateddata = ([...filterValueCust, ...data1]);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: updateddata,
            }));


            if (allCheckedCustomer) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i.selected = false;
                }
                return i;
            });

            setFilterCusDropDownData(currentdata);
            setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
            let data1 = filterValueCust.filter(data => data.Name !== name);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: data1,
            }));
        }
    }

    const OnCustGroupdropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...filterCustGroupDropDownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i.selected = true;
                }
                return i;
            });
            setFilterCustGroupDropDownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
            let updateddata = ([...filterValueCustGroup, ...data1]);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i.selected = false;
                }
                return i;
            });
            setFilterCustGroupDropDownData(currentdata);
            setFilterValueCustGroup(filterValueCustGroup => filterValueCustGroup.filter(data => data.Name !== name))
            let data1 = filterValueCustGroup.filter(data => data.Name !== name);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: data1,
            }));
        }
    }



    const onChangeTargetSelection = (e) => {
        setHfPayoutList(e.subdivideTargetTieritemnew);
        setShowTargetSelection(e.name)
        setTargetSelectionLabel(e.label)
        setSubdivideTargetid(e.value);

        if (e.value === "Select") {
            setShowTargetTable(false)
        } else {
            setShowTargetTable(true)
            setShowTargetSelection(e.name)
        }
    }

    const onChangePaymentTermSelection = (e) => {
        setPaymentTermsId(e.value);
    };
    const checkAllRegionchange = (mycheckbox) => {
        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'region-check']");
        if (mycheckbox.target.checked == true) {
            setRegioncheckAll(true);

            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                if (i.avalRegion == true) {
                    i.selected = true;
                }

            })
            setSalesLevelData(olddata);

        }
        else {
            setRegioncheckAll(false);


            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                i.selected = false;
            })
            setSalesLevelData(olddata);
        }
    }


    // const checkAllCustomer = (mycheckbox) => {
    //     let Ids = [];
    //     var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
    //     if (mycheckbox.target.checked == true) {
    //         setCustomercheckAll(true);
    //         checkboxes.forEach(function (checkbox) {
    //             checkbox.checked = true;
    //         });
    //         let olddata = [...customerData];
    //         olddata.map((i) => {
    //             i.Selected = true;
    //             Ids.push(i.Id);
    //         })

    //         setState({ ...state, ['Customer_Ids']: Ids })
    //     }
    //     else {
    //         setCustomercheckAll(false);
    //         settotalselectedshow(false);
    //         checkboxes.forEach(function (checkbox) {
    //             checkbox.checked = false;
    //         });
    //         let olddata = [...customerData];
    //         olddata.map((i) => {
    //             i.Selected = false;

    //         })
    //         setState({ ...state, ['Customer_Ids']: [] })
    //     }

    // }

    const checkAllCustomer = (mycheckbox) => {
        const isChecked = mycheckbox.target.checked;
        const updatedCustomerData = customerData.map((cslist) => ({
            ...cslist,
            Selected: isChecked,
        }));

        setCustomerData(updatedCustomerData);
        setCustomerCheckAll(isChecked);
        setSelectedCustomers(isChecked ? updatedCustomerData.map((cslist) => cslist.Id) : []);

        const customerIds = isChecked ? updatedCustomerData.map((cslist) => cslist.Id) : [];
        setState({ ...state, ['Customer_Ids']: customerIds });

    };

    const checkAllGroupCustomer = (mycheckbox) => {
        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Id);
            })

            setState({ ...state, ['Customer_Ids']: Ids })
        }
        else {
            setCustomercheckAll(false);
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = false;

            })
            setState({ ...state, ['Customer_Ids']: [] })
        }

    }







    const SearchCustomer = (event) => {
        var list = []
        let lstSelected = customerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerSearch(event.target.value)
        if (customerDataFilter.length === 0) {
            list = customerData;

            setCustomerDataFilter(list);
        }
        else {
            list = customerDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {

                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.Id === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setCustomerData(uniquelst)
        }
        else {
            setCustomerData(list)
        }
    }

    const SearchGroupCustomer = (event) => {
        var list = []
        let lstSelected = groupCustomerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerGroupSearch(event.target.value)
        if (customerGroupDataFilter.length === 0) {
            list = groupCustomerData;

            setCustomerGroupDataFilter(list);
        }
        else {
            list = customerGroupDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.Id === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setGroupCustomerData(uniquelst)
        }
        else {
            setGroupCustomerData(list)
        }
    }

    const FilterColumnCustomer = (name) => {
        var divtop1 = document.getElementById("Customer");
        var cardtop1 = document.getElementById("Customer");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setCustomerFilterCol(name)
        setshowCustomerfilterpopup(true)

        if (name === 'Name') {
            setfiltertextCust("Customer Code")
            var testDiv = document.getElementById("Name");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'Label') {
            setfiltertextCust("Customer Name")
            var testDiv = document.getElementById("Label");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }

        if (name === 'Station') {
            setfiltertextCust("Station")
            var testDiv = document.getElementById("Station");
            setdivleft((testDiv.offsetLeft + 30) + "px");

        }
        if (name === 'State') {
            setfiltertextCust("State")
            var testDiv = document.getElementById("State");
            setdivleft((testDiv.offsetLeft + 30) + "px");

        }
        if (name === 'Zone') {
            setfiltertextCust("Zone")
            var testDiv = document.getElementById("Zone");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }
        DropdownHandlerCus(name);
    }


    const FilterColumnGroupCustomer = (name) => {
        var divtop1 = document.getElementById("CustomerGroup");
        var cardtop1 = document.getElementById("CustomerGroup");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setCustomerGroupFilterCol(name)
        setshowCustomerGroupfilterpopup(true)

        if (name === 'label') {
            setfiltertextCustGroup("Customer Group Label")
            var testDiv = document.getElementById("GroupLabel");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        DropdownHandlerCustGroup(name);
    }

    const DropdownHandlerCustGroup = (key) => {
        setcurrentCustGroupFilterKey(key)
        Service.getfilterDropdownByGroup('CustomerGroups/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        addnewfieldCustomerGroup(res.data, key)
                    }
                }
            }

        });
    }



    const addnewfieldCustomerGroup = (data, key) => {

        let currentdata = data.map((i) => {
            i.selected = false;
            return i;
        });
        let selectedKeys = filterValueGroupCustomer[key];

        if (selectedKeys != '') {
            currentdata.forEach((each) => {
                selectedKeys.forEach((eachSelected) => {
                    if (each.Value == eachSelected.Name) {
                        each.selected = true
                    }
                })
            })
        }
        setFilterCustGroupDropDownData(currentdata);
        setfilterCustGroupData(currentdata)
    }


    const filterHandlerCustomerGroup = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowCustomerGroupfilterpopup(false)
            let list = [];

            for (var key in filterValueGroupCustomer) {
                if (filterValueGroupCustomer[key].length > 0) {
                    let values = ""
                    filterValueGroupCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
            }
        }
        else {
            setshowCustomerGroupfilterpopup(false)

            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: '',
            }));
            let list = [];
            for (var key in filterValueGroupCustomer) {
                if (key !== custGroupFilterCol && filterValueGroupCustomer[key].length > 0) {
                    let values = ""
                    filterValueGroupCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            setpaging(paging);
            getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
            setCustomerGroupFilterCol('')
            setFilterValueCustGroup([])
            setFilterSearchCustGroup("")
            setFilterCustGroupDropDownData([])
            setfilterCustGroupData([])
        }
    }


    const DropdownHandlerCus = (key) => {
        Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        addnewfieldCustomer(res.data, key)
                    }
                }
            }

        });
        // }
    }

    const AutoPostAccrualData = [
        { key: '0', value: true, label: 'Yes' },
        { key: '1', value: false, label: 'No' },
    ];
    const addnewfieldCustomer = (data, key) => {
        let currentdata = data.map((i) => {
            i.selected = false;
            return i;
        });
        let selectedKeys = filterValueCustomer[key] ? filterValueCustomer[key] : [];
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

        if (selectedKeys != '') {
            currentdata.forEach((each) => {
                selectedKeys.forEach((eachSelected) => {
                    if (each.Name == eachSelected.Name) {
                        each.selected = true
                    }
                })
            })
        }

        let result = addSeletAll(currentdata, alreadySelected);
        setFilterCusDropDownData(currentdata);
        setfilterCustData(currentdata)
    }

    const UserfilterHandlerCustomer = (e) => {
        setFilterSearchCust(e.target.value)
        if (e.target.value !== "") {
            let searchResults = filterCusDropDownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setFilterCusDropDownData(searchResults);
        }
        else {

            setFilterSearchCust("")
            setFilterCusDropDownData(filtercustData)
        }
    }

    const UserfilterHandlerCustomerGroup = (e) => {
        setFilterSearchCustGroup(e.target.value)
        if (e.target.value !== "") {
            let searchResults = filterCustGroupDropDownData.filter((each) => {
                return each.Value.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setFilterCustGroupDropDownData(searchResults);
        }
        else {
            setFilterSearchCustGroup("")
            setFilterCustGroupDropDownData(filtercustGroupData)
        }
    }


    const UserfilterHandler = (e) => {
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            dropdownData.map((each) => {
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

    const checkAllFilterCustomer = (mycheckbox) => {
        var lastfilter = [...filterCusDropDownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            let currentdata = lastfilter.map((i) => {
                i.selected = true;
                return i;
            });

            setFilterCusDropDownData(currentdata)
            filterCusDropDownData.map((each) => {
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
            let updateddata = ([...filterValueCust, ...data1]);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                i.selected = false;
                return i;
            });
            setFilterValueCust([])
            setFilterValueCustomer([])
        }

    }

    const checkAllFilterCustomerGroup = (mycheckbox) => {
        var lastfilter = [...filterCustGroupDropDownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            let currentdata = lastfilter.map((i) => {
                i.selected = true;
                return i;
            });
            setFilterCustGroupDropDownData(currentdata)
            filterCustGroupDropDownData.map((each) => {
                const getallData = {
                    Name: each.Value,
                }
                data1.push(getallData)
            })
            setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
            let updateddata = ([...filterValueCustGroup, ...data1]);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                i.selected = false;
                return i;
            });
            setFilterValuegroupCustomer([])
            setFilterValueCustGroup([])
        }

    }

    const CustFilterHandler = (flag) => {
        let list = [];
        var selectedCus = data;
        for (var i = 0; i < customerData.length; i++) {
            if (customerData[i].Selected == true) {
                for (var k = 0; k < selectedCus.length; k++) {
                    if (customerData[i].Id === selectedCus[k].Id) {
                        selectedCus[k].Selected = true;
                    }
                }
            }
        }
        setData(selectedCus);
        setshowCustomerfilterpopup(false)

        if (flag === 'Apply') {
            for (var key in filterValueCustomer) {
                if (filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });

        }
        else {
            setshowCustomerfilterpopup(false)
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: '',
            }));
            for (var key in filterValueCustomer) {
                if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });
            setFilterValueCust([])
            setfilterCustData([])
            setFilterCusDropDownData([])
            setFilterSearchCust('')
        }

    }

    const handleGroupCust = (e) => {
        if (e.target.checked == true) {
            setcheckCustomerGroup(true)
            setState({ ...state, ['CustomerGroup']: true, ['CustomerList']: [], ['Customer_Ids']: [] })
        }
        else {
            setcheckCustomerGroup(false)
            setState({ ...state, ['CustomerGroup']: false, ['CustomerList']: [], ['Customer_Ids']: [] })
        }
    }



    const handleCustomerGroupSelectedChanges = (e, label, id, State) => {

        var rowData = SalesLevelData
        for (var i = 0; i < rowData.length; i++) {

            if (rowData[i].label.toLowerCase() == State.toLowerCase())
                if (e.target.checked == true) {
                    rowData[i].selected = true;
                    rowData[i].avalRegion = true;
                }
                else {

                    rowData[i].selected = false;
                    rowData[i].avalRegion = false;

                }

        }
        setSalesLevelData(rowData)

        let lists = [];

        let olddata = customerGroupDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setCustomerGroupDataSelected(lists)

    }

    const OnChangeHandlerGroupCustomer = (e, label, id, State) => {
        var rowData = SalesLevelData
        let selected = [];
        let update = [];
        const getallData = [...state.Customer_Ids];

        let olddata = [...groupCustomerData];

        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })

            getallData.push(id);
            setGroupCustomerData(olddata);

            setState({ ...state, ['Customer_Ids']: getallData })
            setGroupCustomerChanged(true);
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            setState({ ...state, ['Customer_Ids']: getallData.filter(i => i !== id) })
            setGroupCustomerData(olddata);
        }



    }


    const getGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setGroupCustomerData(res.data.DataList)
                        }
                    }
                }
            }

        });
    }

    const clickGroupCustomerPopup = (val) => {
        setshowAccuralWorkflow(false);
        setCustomercheckAll(false);

        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(true)
        setShowSelectedCustomerpopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setsidebarwidth("63vw");

    }

    const clickSelectedShowCustomerGroupPopup = (val) => {

        let lstcopy = groupCustomerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setCustomerGroupDataSelected([...lstcopy])

        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(false);
        setShowSelectedCustomerGroupPopup(true);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setsidebarwidth("63vw");
    }

    const okCustomerGroup = () => {
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        if (groupCustomerChanged && state.id != 0) {
            UpdateRegionOnCustomerChange();
        }
    }


    const okSelectedCustomerGroup = () => {
        var customerLst = customerGroupDataSelected.filter((e) => e.Selected === false);
        let olddata = [...groupCustomerData];
        olddata.map((i) => {
            var customers = customerGroupDataSelected.filter((e) => e.Selected === false && i.Id === e.Id);
            if (customers.length > 0) {
                i.Selected = false;
            }

        })

        setGroupCustomerData(olddata);
        const getselected = [];
        customerGroupDataSelected.filter((e) => e.Selected === true).map((i) => {
            getselected.push(i.Id);
        });
        setState({ ...state, ['Customer_Ids']: getselected })
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);

    }

    const UpdateRegionOnCustomerChange = () => {
        if (state.analysis == false)
            state.analysis = 'False';
        else
            state.analysis = 'True';

        let validFrom = moment(state.startDate).toDate()
        let validTo = moment(state.endDate).toDate()

        if (state.currencyID == '0')
            state.currencyID = null;
        if (state.setlFreqID == '0')
            state.setlFreqID = null;
        let rebateData = {
            'Id': state.id,
            'Rebate_id': state.agreementId,
            'RebateName': state.agreementName,
            'Customer_Id': null,
            'Sales_org_Id': null,
            'Settlement_method_Id': state.setlMethodID,
            'Settlement_Frequency_Id': state.setlFreqID,
            'Status': state.status,
            'Validity_From': moment(validFrom).format("YYYY-MM-DD"),
            'Validity_To': moment(validTo).format("YYYY-MM-DD"),
            'Auto_Post_Accruals': state.autoPostAccruals,
            'Currency_Id': state.currencyID,
            'CreatedUserId': parseInt(loggedInUser),
            "LastModifiedUserId": parseInt(loggedInUser),
            "Customer_Ids": state.Customer_Ids,
            'CustomerGroup': state.CustomerGroup,
            'FiscalYear': state.FiscalYear,
            'Agreementtype': 'Product Scheme',
        }

        setLoading(true);
        Service.createorupdate("Scheme/CreateOrUpdateSchemeHeader", rebateData).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data
                    setLoading(false);
                    if (res.data.Status == 'Warning') {
                        setErrorMessage(t('Rebates.Validations.RebateHeaderForTheSameCustomerAlreadyExistsWithSubmitted/ApprovedProgramlines'))
                        setErrorStatus(res.data.Status)
                        setShowErrorModal(true);
                    } else {
                        if (saveProgramLine === false) {
                            toast.success('Rebate Region Updated successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                                //   onClose: () => navigate("/shipanddebit")
                            });
                        }
                        getRebateHeaderDetails("Scheme/GetSchemeHeader", rData.Id)
                    }
                }
            } else
                setLoading(false);
        });
    }


    // -----------------------------------------------------------------

    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)

        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 300)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 360)
        setdivleft((divtop1.offsetLeft) + 472 + "px");
        getColumnVisibilityList()
    }

    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }

    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }

    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'ProductSchemeAgreementProgramLine', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'ProductSchemeAgreementProgramLine', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }


    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'ProductSchemeAgreementProgramLine', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }

    const requiredLabels = [
        'measurevalue_ltrs',
        'measurevalue_signedtarget',
      ] ;
    const getmeasuretype = () => {
        Service.getAllGetAllMeasureType().then(result => {
            const filteredModules = result.filter(module => !requiredLabels.includes(module.Name));
            filteredModules.forEach((each) => {
              each['Selected'] = false;
            })
            setMeasureOptions(filteredModules);
            // result.forEach((each) => {
            //     each['Selected'] = false;
            // })
            // setMeasureOptions(result);
        }).catch(error => {
            // Handle errors here
            console.error(error);
        });
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'ProductSchemeAgreementProgramLine', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }

    const jsonDataString = JSON.stringify(jsonList);

    const openFileInput = () => {
        document.getElementById('fileInput').click();
    }

    const ImportProgramLine = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        // setLoading(true)
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const result = {};

                workbook.SheetNames.forEach((sheetName) => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { raw: false });
                    result[sheetName] = sheetData;
                });

                setJsonList(result);

                try {
                    Service.create_or_updateScheme("ImportProgramLine", state.id, result, type).then((res) => {

                        if (typeof res !== 'undefined') {
                            if (typeof res.data !== 'undefined' && res.data !== 'null') {

                                if (res.data.Status != 'Failure') {
                                    if (res.data.Status != 'FailureFile') {
                                        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
                                        getRebatePrgramLines("GetSchemeProgramLines/rebateid", props.id, paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue, "")
                                        setLoading(false);
                                        let rData = res.data
                                        toast.success('Record Imported Successfully !', {
                                            position: toast.POSITION.TOP_RIGHT,
                                            // onClose: () => navigate("/shipanddebit")
                                        });
                                        getRebateProgramLineDetails("GetSchemeProgramLinesDetails", rData.Id, "Product Scheme")
                                    }
                                    else {
                                        setErrorMessage(res.data.ErrorMessage);
                                        setErrorStatus("Error")
                                        setShowErrorModal(true)
                                        setLoading(false);
                                    }
                                } else {
                                    setErrorMessage("Error while import");
                                    setErrorStatus("Error")
                                    setShowErrorModal(true)
                                    setLoading(false);

                                    var path = res.data.ErrorMessage;
                                    window.location = Service.getBaseUrl() + path;
                                }
                            }
                            else {
                                setLoading(false);
                                setErrorMessage(`${t('Rebates.validations.ErrorWhileSavingTheRecord')}`)
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                            }
                        } else {
                            setLoading(false);
                        }
                    });
                    navigate("/new-scheme/" + state.id + "/fromScheme/0")
                }
                catch (error) {
                    // Handle any errors that occur during the service call

                }
            };

            reader.readAsBinaryString(file);


        }


    };

    const DownloadErrorFile = () => {
    }
    const ValidateRevise = () => {

        Service.postWithData('Rebate/CheckActiveStatus', rebateprogrmlinedetail.id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data == "Accrual generated is in new state. Do you want to delete generated accruals for this rebate ?") {
                            setMessage(res.data)
                            setShowAccrualDialogForRevise(true)
                        }
                        else if (res.data == "Accrual generated is in submitted state.Unable to deactivate rebate") {
                            setMessage("")
                            setToggleStatusModal2(true)
                        }
                        else if (res.data == "Success") {
                            ReviseProgramLine()
                        }
                        else if (res.data == "") {
                            ReviseProgramLine()
                        }
                    }
                }
            }
        });
    }

    const ReviseProgramLine = () => {
        setLoading(true);
        Service.UpdateProgramlineStatus('Rebate/UpdateProgramlineStatus', rebateprogrmlinedetail.id, "revising", loggedInUser, 'Revised').then((res) => {
            if (res.status === 200) {

                toast.success('Revised Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });

                setLoading(false);
                getRebatePrgramLines("GetSchemeProgramLines/rebateid", state.id, paging.pageSize, paging.pageNumber, 'Descending', 'Id', filterLst(), searchValue, "")
                setShowCreateEditRebatePgm(true)
                setshowrebateprogram(false);
                setCommentsForSubmit('')
                setSaveProgramLine(false);
            }
        })

    }

    const getMonthNumber = (monthString) => {
        const numericPart = monthString ? parseInt(monthString.slice(1), 10) : NaN;
        return isNaN(numericPart) ? 0 : numericPart;
    };

    const inputsHandler = (e) => {
        let list = { ...rebateprogrmlinedetail }
        list.Justification = e.target.value
        setrebateProgramLinedetail(list)
    }

    const OnChangeHandlerAchivePolicy = (data) => {
        setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['AchievementPolicyId']: data.value })
        setSelectedAchievementPolicy(data.value);

    }
    const OnChangeHandlerAchivePolicycheckbox = (event) => {

        setIncludeAchievementPolicy(event.target.checked)
    }


    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";

    // ------------------------------------------------------------



    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to={updateNav} id="headertxt">{t('Schemes.SchemeList')}</Link></li>
                    <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Create New Product Scheme Agreement')}</li>
                </ol>
            </nav>

            {loading && loadingMsg == "EligibleInvoice" ? <><div className="loader-container"><div className="spinner"></div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "20vh", width: "100vw", fontWeight: "bold" }} className="spinnerMessage">{t('Rebates.Validations.AccrualCalculationInProgressPleaseWait')}</div>
            </div> </> : loading ? <><div className="loader-container"><div className="spinner"></div>

            </div> </> : ''}
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="new-rebate.csv" > </CSVLink>
            </div>
            <ToastContainer autoClose={1000} />
            {
                showAccuralWorkflow === true ?
                    <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

                        {showWorkflow === false ?
                            <div className="container" id="workflow">
                                <div className="row mt-3">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-11 text-left">
                                                        {t('Workflow')}
                                                    </div>
                                                    <div className="ml-4">
                                                        <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body" style={{ minHeight: "55vh" }}>
                                                {workflowchart.length > 0 ?
                                                    <div className='col-md-12'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                                                    <th className='font-center workflowchart-font'>Users</th>
                                                                    <th className='font-center workflowchart-font'>Approval</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    workflowchart.map((i) =>
                                                                        <tr key={i.Id}>
                                                                            <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                                                            <td className='font-center'>
                                                                                <ul >
                                                                                    {
                                                                                        i.lstworkflowapprover.map((j, index) => {
                                                                                            return <>

                                                                                                {
                                                                                                    index < 2 ?
                                                                                                        j.IsApproved === true ?
                                                                                                            <li className='workflowchartperson'>
                                                                                                                <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                    <br></br> {j.FullName}</span>
                                                                                                            </li>
                                                                                                            :
                                                                                                            <>
                                                                                                                <li className='workflowchartperson'>
                                                                                                                    <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                    <br></br> {j.FullName}
                                                                                                                </li>
                                                                                                            </>
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </>

                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        i.lstworkflowapprover.length > 2 ?
                                                                                            <li className='workflowchartperson tooltip_workflow'>
                                                                                                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                <br></br> {t('MoreUsers')}
                                                                                                <span class="tooltiptext">

                                                                                                    {
                                                                                                        i.lstworkflowapprover.map((k, index) => {
                                                                                                            return <span>
                                                                                                                {
                                                                                                                    index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                                                        &nbsp; {k.FullName}
                                                                                                                        <hr></hr></> : null
                                                                                                                }


                                                                                                            </span>
                                                                                                        })
                                                                                                    }

                                                                                                </span>
                                                                                            </li>
                                                                                            :
                                                                                            ''
                                                                                    }


                                                                                </ul>

                                                                            </td>

                                                                            <td className='font-center'>
                                                                                {
                                                                                    i.IsApproved === true ?
                                                                                        <span className='workflowrightcolor'>
                                                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                                                        </span>
                                                                                        : i.IsDenied == true ?
                                                                                            <span className='workflowrightcolor'>
                                                                                                <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                                                            </span>
                                                                                            : ''
                                                                                }
                                                                            </td>
                                                                        </tr>)

                                                                }
                                                            </tbody>

                                                        </table>

                                                    </div>
                                                    :
                                                    <div className={classes.flow}>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table table-bordered text-left mt-2">
                                                            <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>

                                                                    <th>{t('Rebates.Event')}</th>
                                                                    <th>{t('Rebates.CreatedDate')}</th>
                                                                    <th>{t('Rebates.User')}</th>
                                                                    <th>{t('Rebates.Comments')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    showspinnerworkflow === true ?

                                                                        <>
                                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                        </>
                                                                        :
                                                                        workflowData.length === 0 ?
                                                                            <tr><td></td>
                                                                                <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                                    {t('NoDataAvailable')}
                                                                                </td>
                                                                            </tr>
                                                                            :
                                                                            woData === '' || woData === 'undefined' ?
                                                                                <tr></tr> :
                                                                                workflowData.map((i) =>
                                                                                    <tr key={i.id}>
                                                                                        <td>{i.EventName}</td>
                                                                                        {i.CreatedDate === null ? <td></td> : <td>{`${moment(i.CreatedDate).format(dateFormat)} ${moment(i.CreatedDate).format(timeFormat)}`}</td>}
                                                                                        <td>{i.CreateUserName}</td>
                                                                                        <td>{i.Comments}</td>
                                                                                    </tr>)

                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            :
                            <div className="container" id="history">
                                <div className="row mt-3">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-8 text-left">
                                                        {t('Rebates.AccrualHistory')}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button className="btn btn-outline-dark  btn-sm" style={{ float: "right", width: "45px" }} onClick={clickCloseAccuralWorkflow} type="submit">{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ minHeight: "50vh" }}>
                                                <div className="container">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <span className="rebateheaderstyle"><i className="fa fa-globe globe"></i><span style={{ marginLeft: "12px" }}><label>{state.agreementId}</label></span></span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <span className="accuredamountlabel"><label>{t('Rebates.TotalAccruedAmount')}</label></span>
                                                            <span className="accuredamount"><label>{state.CurrencySymbol} {parseFloat(totalAccrualMoney).toFixed(2)}</label></span>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <table className="table table-bordered text-left" >
                                                            <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{t('Rebates.RebateProgram')}</th>
                                                                    <th>{t('Rebates.RebateProgramDesc')}</th>
                                                                    <th>{t('Rebates.AccruedAmount')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="table-body">

                                                                {
                                                                    showspinneraccural === true ?

                                                                        <>
                                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                        </>
                                                                        :
                                                                        activerebateProgramLine.length === 0 ?
                                                                            <tr><td></td>
                                                                                <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                                    {t('NoDataAvailable')}
                                                                                </td>
                                                                            </tr>
                                                                            :
                                                                            activerebateProgramLine.map((i, index) =>
                                                                                <>

                                                                                    <tr className="cell-1" key={index} data-toggle="collapse" data-target="#demo">
                                                                                        <td data-toggle="collapse" data-target="#demo">
                                                                                            {expandAccurals === index ?
                                                                                                <i className={icon} id={index} aria-controls="demo" aria-hidden="true" onClick={(event) => changeIcon(event, (index))}></i>
                                                                                                :
                                                                                                <i className="fa fa-plus" id={index} aria-controls="demo" aria-hidden="true" onClick={(event) => changeIcon(event, (index))}></i>
                                                                                            }
                                                                                        </td>
                                                                                        <td>{i.RebateProgramLineIdStr}</td>
                                                                                        <td>{i.RebateProgramLineDesc}</td>
                                                                                        <td>{i.Currency} {parseFloat(i.AccrualMoney).toFixed(2)}</td>
                                                                                    </tr>

                                                                                    {expandAccurals === index
                                                                                        ?
                                                                                        <>
                                                                                            <tr id="demo" key={index} className="collapse cell-1 row-child" style={{ backgroundColor: "light blue" }}>
                                                                                                <th></th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.AccrualId')}</th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.Customer')}</th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.ApprovedDate')}</th>
                                                                                                <th colspan="1" className='tableheader' style={{ backgroundColor: "#808080", color: "white" }}>{t('Rebates.AccruedAmount')}</th>
                                                                                            </tr>
                                                                                            {i.AccrualHistory.length !== 0 ?
                                                                                                i.AccrualHistory.map((item) =>
                                                                                                    <tr id="demo" key={index} className="collapse cell-1 row-child">
                                                                                                        <td></td>

                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}><Link to={`/accruals/new/${item.AccrualsId}/fromScheme`}>{item.AccrualsIdStr}&nbsp;</Link></td>
                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}>{item.Customer}</td>
                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}>{moment(item.ApprovedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                                                        <td colspan="1" style={{ backgroundColor: "lightblue" }}>{i.Currency} {parseFloat(item.AccrualMoney).toFixed(2)}</td>

                                                                                                    </tr>) :
                                                                                                <tr></tr>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </>)

                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                    :
                    ""
            }
            {
                showproductpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>
                                                <div style={{ minHeight: "66vh" }}>
                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            {clickProduct === false ?
                                                                <ProductTreeDialog
                                                                    show={showModal}
                                                                    onOkClick={UpdateProducts}
                                                                    onHide={() => CloseProductPicker}
                                                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                                                    rebatelinestatus={rebatelinestatus}
                                                                    dialogType="Product"
                                                                    SelectedData={FiltedProducts}
                                                                    dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                                                    selectedType={selectedType}
                                                                    programLineStatus={rebateprogrmlinedetail.status}
                                                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                >
                                                                    <div className={classes['Radiobutton-header']}>
                                                                        <div className={classes['Radiobutton-internal-div']} >
                                                                            <input type="radio" checked={selectedType === 'Attribute'}
                                                                                value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                            <input type="radio" checked={selectedType === 'Heirarchy'}
                                                                                onChange={SelectedProductTypeHandler}
                                                                                className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                                        </div>
                                                                    </div>
                                                                </ProductTreeDialog>
                                                                : ''
                                                            }

                                                        </div>

                                                    </div>




                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedProductpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>

                                                <div className='scrollbar-class'>

                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                    <tr>
                                                                        <th className="tableheader">

                                                                        </th>
                                                                        <th id="ProductId" >
                                                                            {t('Rebates.MaterialNo')}
                                                                        </th>
                                                                        <th id="Product" >
                                                                            {t('Rebates.ItemName')}
                                                                        </th>
                                                                        <th id="Oem" >
                                                                            Oem
                                                                        </th>
                                                                        <th id="Vehicletype" >
                                                                            Vehicle Type
                                                                        </th>
                                                                         
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {dualListBoxOptionsByAttribute.filter((e) => e.Selected === true).map((listVal) =>
                                                                        <tr key={listVal.Id} >

                                                                            <td style={{ width: "30px" }}>

                                                                                {

                                                                                    <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                                                                }

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                                                            </td>
                                                                            <td style={{ width: "200px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Oem}>{listVal.Oem}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>

                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Vehicletype}>{listVal.Vehicletype}</div>

                                                                            </td>
                                                                            
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }

            {
                rplSelectedProducts === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => setrplSelectedProducts(false)} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>

                                                <div className='scrollbar-class'>

                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                    <tr>
                                                                        <th className="tableheader">
                                                                        </th>

                                                                        <th id="Product" >
                                                                            {t('Rebates.ItemName')}
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {FiltedProducts.map((listVal) =>
                                                                        <tr key={listVal}>
                                                                            <td style={{ width: "10px" }}>
                                                                                <ul>
                                                                                    <li>
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                            <td >
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal}>
                                                                                    {listVal}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }


            <div className="row">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-12 text-left">
                                    {t('Schemes.SchemeHeader')}
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                    {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ? '' : noRegion === false ? <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} onClick={ClearAndOpen} type="submit">{t('NewProgram')}</button> : ''}
                                    {/* {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ? '' : noRegion === false ? (isLocked === true && lockedBy == loggedInUser) ? <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} onClick={OnClickCreateRebateProgram} type="submit">{t('NewProgram')}</button> : '' : ''} */}
                                    {/* {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ? '' : noRegion === false ? (isLocked === true && lockedBy == loggedInUser) ? <button className="btn  btn-update btn-sm ml-1" style={{ width: "130px", float: "right" }} onClick={OnClickCreateRebateProgram} type="submit">{t('Rebates.NewProgramS')}</button> : '' : ''} */}
                                    <button className="btn  btn-update btn-sm ml-1" onClick={() => { clickShowAccuralWorkflow("Accural") }} style={{ width: "95px", float: "right" }} type="submit">{t('AccrualHistory')}</button>
                                    {loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ?
                                        <button style={{ float: "right", width: "45px" }} className="btn  btn-update btn-sm ml-1" onClick={CloseLoadedFromApproval} type="submit">{t('Close')}</button>
                                        :
                                        <button className="btn  btn-update btn-sm ml-1" onClick={() => { CloseAgreement("CloseHeader") }} style={{ float: "right", width: "45px" }} type="submit">{t('Close')}</button>
                                    }
                                    {Service.editPerms(userPerms) == false ? "" : (disableProgramLineField === false || revised == true) ? noRegion === false ? <button className="btn  btn-update btn-sm ml-1" onClick={SaveHandler} style={{ float: "right", width: "40px" }} type="submit">{t('Save')}</button> : '' : ''}
                                    {/* {Service.editPerms(userPerms) == false ? "" : (disableProgramLineField === false || revised == true) ? noRegion === false ? (isLocked === true && lockedBy == loggedInUser) ? <button className="btn  btn-update btn-sm ml-1" onClick={SaveHandler} style={{ float: "right", width: "40px" }} type="submit">{t('Save')}</button> : '' : '' : ''} */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row mt-1">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">


                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left l_width">{t('Rebates.AgreementId')}</label>
                                            <input disabled={true} value={state.agreementId} name="rebateProgramLineId" type="text" className="col-md-6 textbox-padding form-control m_width" />

                                            <label className="col-md-6 mt-2 text-left l_width">{t('Rebates.AgreementName')}</label>
                                            <input name="agreementName" disabled={disableHeaderField} type="text" onChange={(event) => OnChangeHandler(event)} value={state.agreementName} autoComplete="off" className={`col-md-6 textbox-padding form-control mt-2 m_width ${disabledClass}`} />

                                            <label className="col-md-6 mt-2 text-left l_width">{t('Rebates.FiscalPeriod')}<span className={classes.mandatory}>*</span></label>
                                            <Select styles={customStyles}
                                                className={`col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                options={dateRange}
                                                placeholder="Select"
                                                isSearchable={true}
                                                onChange={OnChangeDateRange}
                                                value={dateRange.filter(function (option) {
                                                    return option.label === state.FiscalYear;
                                                })}
                                                isDisabled={disableHeaderField}
                                            />

                                            <label className="col-md-6 mt-2 text-left l_width">{t('Rebates.SettlementMethod')}<span className={classes.mandatory}>*</span></label>

                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                options={setlMethodData}
                                                placeholder="Select"

                                                value={setlMethodData.filter(function (option) {
                                                    return option.label === selectedOptionsSetlMethod;
                                                })}
                                                onChange={OnChangeHandlerSetlMethod}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            // isMulti
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">

                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">



                                            <label className="col-md-4 mt-2 text-left l_width">{t('Rebates.Zone')}</label>

                                            <div className={`col-md-8 textbox-padding-dropdown text-right  mt-2 fontsizedropdown m_width ${true}`}>
                                                <div className='row'>
                                                    <div className='col' style={{ paddingRight: '0px' }}>
                                                        <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={() => clickShowPopup('zone')} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    </div>
                                                    <div className='col' style={{ paddingLeft: '0px' }}>
                                                        <a href='javascript:void(0)' onClick={() => clickSelectedShowPopup('zone')}><p style={{ fontSize: "10px" }}>({zoneOptions.filter(i => i.Selected == true).length}) {t('Rebates.ZonesAdded')}</p></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <label className="col-md-4 mt-2 text-left l_width">{t('Rebates.State')}</label>
                                            <div className={`col-md-8 textbox-padding-dropdown text-right  mt-2 fontsizedropdown m_width ${true}`}>
                                                <div className='row'>
                                                    <div className='col' style={{ paddingRight: '0px' }}>
                                                        <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={() => clickShowPopup('state')} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    </div>
                                                    <div className='col' style={{ paddingLeft: '0px' }}>
                                                        <a href='javascript:void(0)' onClick={() => clickSelectedShowPopup('state')}><p style={{ fontSize: "10px" }}>({stateOptions.filter(i => i.Selected == true).length}) {t('Rebates.StatesAdded')}</p></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <label className="col-md-4 mt-2 text-left l_width">{t('Rebates.Station')}</label>

                                            <div className={`col-md-8 textbox-padding-dropdown text-right  mt-2 fontsizedropdown m_width ${true}`}>
                                                <div className='row'>
                                                    <div className='col' style={{ paddingRight: '8px' }}>
                                                        <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={() => clickShowPopup('station')} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    </div>
                                                    <div className='col' style={{ paddingLeft: '0px', paddingRight: '6px' }}>
                                                        <a href='javascript:void(0)' onClick={() => clickSelectedShowPopup('station')}><p style={{ fontSize: "10px" }}>({stationOptions.filter(i => i.Selected == true).length}) {t('Rebates.StationsAdded')}</p></a>
                                                    </div>
                                                </div>
                                            </div>


                                            {checkCustomerGroup === false ? (
                                                <>
                                                    <label className="col-md-4 mt-2 mb-2 text-left l_width">{t('Rebates.Customer')}<span className={classes.mandatory}>*</span></label>

                                                    <div className={`col-md-8 textbox-padding-dropdown text-right  mt-2 mb-2 fontsizedropdown m_width ${true}`}>
                                                        <div className='row'>
                                                            <div className='col' style={{ paddingRight: '8px' }}>
                                                                <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={clickShowCustomerPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                            </div>
                                                            <div className='col' style={{ paddingLeft: '0px', paddingRight: '6px' }}>
                                                                <a href='javascript:void(0)' onClick={clickSelectedShowCustomerPopup}><p style={{ fontSize: "10px" }}>({customerData.filter(i => i.Selected == true).length}) {t('Rebates.CustomersAdded')}</p></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <label className="col-md-4 mt-2 mb-2 text-left l_width">{t('Rebates.GroupCustomer')}<span className={classes.mandatory}>*</span></label>
                                                    <div className={`col-md-8 textbox-padding-dropdown text-right  mt-2  mb-2 fontsizedropdown m_width ${true}`}>
                                                        <div className='row'>
                                                            <div className='col' style={{ paddingRight: '8px' }}>
                                                                <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={clickGroupCustomerPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                            </div>
                                                            <div className='col' style={{ paddingLeft: '0px', paddingRight: '6px' }}>
                                                                <a href='javascript:void(0)' onClick={clickSelectedShowCustomerGroupPopup}><p style={{ fontSize: "10px" }}>({groupCustomerData.filter(i => i.Selected == true).length}) {t('Rebates.CustomersAdded')}</p></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">

                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left l_width">{t('Rebates.Currency')}<span className={classes.mandatory}>*</span></label>
                                            {

                                                state.currencyID === 0 || state.currencyID === null || state.currencyID === undefined ?
                                                    state.currencyID = defaultCurrency.Id
                                                    :
                                                    ''
                                            }
                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown fontsizedropdown m_width ${disabledClass}`}
                                                options={currencyData}
                                                placeholder="Select"

                                                value={currencyData.filter(function (option) {
                                                    return option.label === `${selectedOptionsCurrency}`;
                                                })}
                                                onChange={OnChangeHandlerCurrency}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            // isMulti
                                            />

                                            <label className="col-md-6 mt-2 text-left l_width">{t('Rebates.AutoPostAccrual')}</label>


                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown  mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                options={AutoPostAccrualData}
                                                placeholder="Select"
                                                name="autoPostAccruals"
                                                value={AutoPostAccrualData.filter(function (option) {
                                                    return option.label === `${selectedOptionsautoPostAccruals}`;
                                                })}
                                                onChange={OnChangeHandlerautoPostAccruals}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            // isMulti
                                            />
                                            <input type="text" className={`col-md-6 form-control textbox-padding mt-2 m_width ${disabledClass}`} style={{ display: 'none' }} disabled={true} value={state.status} />


                                            <label className="col-md-6 mt-2 text-left l_width">{t('CreatedBy')}</label>
                                            <input type="text" disabled="true" value={state.created_by} className="col-md-6 form-control textbox-padding mt-2 m_width" />

                                            <label className="col-md-6 mt-2 text-left l_width">{t('CreatedDate')}</label>
                                            <input type="text" className="col-md-6 form-control textbox-padding mt-2 datetimepicker-input m_width" data-target="#reservationdate" disabled={true}
                                                name="createdDate" placeholder="CreatedDate" value={state.createdDate} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {

                showrebateprogram === true ?
                    <div className="row mt-3" id="rebateProgramdiv">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                    <div className="row">
                                        <div className="col-md-7 text-left">
                                            {t('Rebates.RebateProgram')}
                                        </div>
                                        <div className="col-md-5">
                                            {Service.editPerms(userPerms) == false ? "" : (disableProgramLineField === false || revised == true) ?
                                                <button type="button" style={{ float: "right", width: "50px" }} className="btn btn-sm btn-update ml-1" onClick={(event) => workflowAlert('open')}>{t('Submit')}</button>
                                                : ''
                                            }
                                            {Service.editPerms(userPerms) && rebateprogrmlinedetail.status !== 'Approved' ? (
                                                <button
                                                    type="button"
                                                    style={{ float: "right", width: "50px" }}
                                                    className="btn btn-sm btn-update ml-1"
                                                    onClick={(event) => ValidateRevise()}
                                                >
                                                    {t('Revise')}
                                                </button>
                                            ) : null}
                                            {loadedFrom === 'fromApproval' || loadedFrom == "fromApprovalCompleted" ?
                                                <button type="button" style={{ float: "right", width: "45px" }} className="btn btn-sm btn-update ml-1" onClick={CloseLoadedFromApproval}>{t('Close')}</button>
                                                :
                                                <button type="button" style={{ float: "right", width: "45px" }} className="btn btn-sm btn-update ml-1" onClick={() => CloseRebateProgrmLine("RPLOpen")}>{t('Close')}</button>}
                                            {Service.editPerms(userPerms) == false ? "" :
                                                (loadedFrom === 'fromApproval' && rebateprogrmlinedetail.status === 'InReview') ?
                                                    // (isLocked === true && lockedBy.toString() === loggedInUser) ?
                                                    <>
                                                        <button type="button" style={{ float: "right", width: "57px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                                                        <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                                                    </>
                                                    : ""
                                                //         :
                                                // <></>
                                            }

                                            <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "65px" }} onClick={() => { clickShowAccuralWorkflow("Workflow") }} type="submit">{t('Workflow')}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-2 text-left l_width" style={{ paddingTop: "5px" }}>{t('Rebates.ProgramId')}</label>
                                            <input id="aid" disabled={true} value={rebateprogrmlinedetail.rebateProgramLineId} type="text" name="rebateProgramLineId" className="col-md-2 textbox-padding mt-2 m_width" />


                                            <label className="col-md-2 mt-2 text-left l_width" style={{ paddingBottom: "10px" }}>{t('Rebates.RebateType')}<span className={classes.mandatory}>*</span></label>

                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                options={rebateTypeData}
                                                placeholder="Select"
                                                value={rebateTypeData.filter(function (option) {
                                                    return option.value === `${selectedOptionsRebateType}`;
                                                })}

                                                onChange={OnChangeHandlerRebateType}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}
                                            />
                                            <label className="col-md-2  mt-2 text-left l_width">{t('Rebates.StartDate')}</label>

                                            <div className='col-md-2 mt-2 text-left textbox-padding m_width'>
                                                <DatePicker name="validityFrom" value={fyStartDate}
                                                    className={`drodownwidth ${disabledClass}`}
                                                    selected={fyStartDate}
                                                    disabled={disableProgramLineField && (validityFromEditable == false)}
                                                    onChange={(event) => OnChangeHandlerLineStartDate(event)}
                                                    minDate={minDate}  // January 1, 2023
                                                    maxDate={maxDate} // March 31, 2024
                                                    filterDate={(date) => date.getDate() === 1}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>

                                            <label className="col-md-2  text-left l_width" style={{ paddingTop: "5px" }}>{t('Rebates.Description')}<span className={classes.mandatory}>*</span></label>
                                            <input value={rebateprogrmlinedetail.rebateProgramDesc} autoComplete="no" name="rebateProgramDesc" disabled={disableProgramLineField} type="text"
                                                className={`col-md-2 textbox-padding mt-2 m_width ${disabledClass}`} onChange={(event) => OnChangeProgramDetail(event)} />
                                            <div style={{ display: "none" }}>
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width${disabledClass}`}
                                                    options={accrualCalcTypeData}
                                                    placeholder="Select"

                                                    value={accrualCalcTypeData.filter(function (option) {
                                                        return option.value === `${selectedOptionsAccrualCal}`
                                                    })}
                                                    onChange={OnChangeHandlerAccrualCal}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableProgramLineField}
                                                />
                                            </div>
                                            <label className="col-md-2 mt-2 text-left l_width" style={{ paddingBottom: '7px' }}>{t('Schemes.RebateMeasure')}<span className={classes.mandatory}>*</span></label>

                                            <div className={`col-md-2 textbox-padding  mt-2 fontsizedropdown m_width ${true}`}>
                                                <div className='row'>
                                                    <div className='col' style={{ paddingRight: '0px' }}>
                                                        <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={() => clickShowPopup('rebatemeasure')} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    </div>

                                                </div>
                                                <div className='col' style={{ paddingLeft: '0px' }}>
                                                    <a href='javascript:void(0)' onClick={() => clickSelectedShowPopup('rebatemeasure')}><p style={{ fontSize: "10px" }}>({measureOptions.filter(i => i.Selected == true).length}) {t('Schemes.RebateMesaureSelected')}</p></a>
                                                </div>
                                            </div>
                                            {/* <label className="col-md-2 mt-2 text-left" style={{ paddingBottom: '7px' }}>{t('Rebates.RebateMeasure')}<span className={classes.mandatory}>*</span></label>
                                            {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ?
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                    options={rebateMeasureData}
                                                    placeholder="Select"

                                                    value={rebateMeasureData.filter(function (option) {
                                                        return option.value === `${selectedOptionsRebateMeasure}`
                                                    })}
                                                    onChange={OnChangeHandlerRebateMeasure}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableProgramLineField}
                                                />
                                                :
                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                    options={rebateMeasureData}
                                                    placeholder="Select"
                                                    value={rebateMeasureData.filter(function (option) {
                                                        return option.value === `${selectedOptionsRebateMeasure}`
                                                    })}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={true}
                                                />
                                            } */}

                                            <label className="col-md-2  mt-2 text-left l_width">{t('Rebates.EndDate')}</label>

                                            <div className='col-md-2 mt-2 text-left textbox-padding m_width'>
                                                <DatePicker name="validityTo" value={fyEndDate} className={`drodownwidth ${disabledClass}`}
                                                    selected={fyEndDate}
                                                    disabled={disableProgramLineField && (validityToEditable == false)}
                                                    onChange={(event) => OnChangeHandlerLineEndDate(event)}

                                                    minDate={minDate}  // January 1, 2023
                                                    maxDate={maxDate} // March 31, 2024
                                                    filterDate={filterLastDaysOfMonth}
                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>


                                            {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ? <><label className="col-md-2 mt-2 text-left" >{t('Rebates.GrowthComparisonPeriod')}<span className={classes.mandatory}>*</span></label>

                                                <Select
                                                    className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                    options={comparisonFormatData}
                                                    placeholder="Select"


                                                    value={comparisonFormatData.filter(function (option) {
                                                        return option.value === `${selectedOptionsCompDateFormat}`;
                                                    })}
                                                    onChange={OnChangeComparisonPeriod}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableProgramLineField}
                                                />

                                                <label className="col-md-2  mt-2 text-left l_width">{t('Rebates.ComparisonStartDate')}<span className={classes.mandatory}>*</span></label>

                                                <div className='col-md-2 mt-2 text-left textbox-padding m_width'>
                                                    <DatePicker name="comparisonStartDate" className={`drodownwidth ${disabledClass}`} placeholder="Comparison Start Date" selected={comparisonStartDate} disabled={disableProgramLineField === true || comparisonDateEditable === false} onChange={(event) => OnChangeHandlerComparisonStartDate(event)}
                                                        dateFormat={SessionManage.getdateformat()} />
                                                </div>

                                                <label className="col-md-2  mt-2 text-left l_width">{t('Rebates.ComparisonEndDate')}<span className={classes.mandatory}>*</span></label>
                                                <div className='col-md-2 mt-2 text-left textbox-padding m_width'>
                                                    <DatePicker name="comparisonEndDate" className={`drodownwidth ${disabledClass}`} placeholder="Comparison End Date" selected={comparisonEndDate} disabled={disableProgramLineField === true || comparisonDateEditable === false} onChange={(event) => OnChangeHandlerComparisonEndDate(event)}
                                                        dateFormat={SessionManage.getdateformat()} />
                                                </div>
                                            </> : ''}

                                            <label className="col-md-2 mt-2 text-left l_width" >{t('Rebates.PayoutCond')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width ${disabledClass}`}
                                                options={rebatePayoutCondData}
                                                placeholder="Select"


                                                value={rebatePayoutCondData.filter(function (option) {
                                                    return option.value === `${selectedOptionsPayout}`
                                                })}
                                                onChange={OnChangeHandlerPayout}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}
                                                name="Rebate_Payout_Cond_Id"
                                            />


                                            <label className="col-md-2 mt-2 text-left l_width" >{t('Rebates.RebateCat')}</label>
                                            <Select
                                                className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width${disabledClass}`}
                                                options={rebateCategoryData}
                                                placeholder="Select"


                                                value={rebateCategoryData.filter(function (option) {
                                                    return option.value === `${selectedRebateCategory}`
                                                })}
                                                onChange={OnChangeHandlerCategory}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableProgramLineField}
                                                name="Rebate_Payout_Cond_Id"
                                            />


                                            <label className="col-md-2 mt-3 text-left l_width">{t('Rebates.AccrualFreq')}</label>
                                            {/* <Select
                                                className={`col-md-2 textbox-padding mt-3 fontsizedropdown ${disabledClass}`}
                                                // options={setlFreqData}
                                                value="End Of Period"
                                                selected={"End Of Period"}
                                                placeholder={t('Select')}
                                                onChange={OnChangeHandlerSetlFreq}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={true}
                                            /> */}
                                            <input name="agreementName" disabled="true" type="text" value={"End Of Period"} autoComplete="off" className={`col-md-2 textbox-padding mt-2 fontsizedropdown m_width ${disabledClass}`} />
                                        </div>

                                        <div className='row mt-2'>
                                            <label className="col-md-2 mt-2 text-left l_width">{t('Attachment')}</label>
                                            <div className="col-md-2 mt-2 text-right col-sm-12 m_width" style={{ paddingLeft: "0px" }}>
                                                <Form.Control
                                                    className={classes['Form-Input-control'] + disabledClass}
                                                    style={{ width: "190px", border: 'none' }}
                                                    disabled={disableProgramLineField}
                                                    type="file"
                                                    name="AttachPOS"
                                                    onChange={AttachmentFile}
                                                />
                                            </div>
                                            <div className="col-md-4 mt-3 col-sm-12 d-flex align-items-center">
                                                <span className="mr-2">
                                                    <p
                                                        style={{
                                                            fontSize: "10px",
                                                            color: fileSizeExceeded ? "red" : "#4755AB",
                                                            cursor: fileSizeExceeded ? "pointer" : "inherit",
                                                        }}
                                                        href={rebateprogrmlinedetail.filePath == null || rebateprogrmlinedetail.filePath == '' ? '#' : rebateprogrmlinedetail.filePath}
                                                        target='_blank'
                                                    >
                                                        {fileSizeExceeded ? '(File size should not be greater than 2MB)' : '(Max file size: 2MB)'}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border mt-2">
                                                <div className="card-body" >
                                                    <div className="row">
                                                        <label className="col-sm-12 col-md-12 col-lg-2 pl-4 text-left mt-2">{t('Rebates.Justification')}</label>

                                                        <textarea ref={justificationRef} style={{ marginRight: "0.938rem", marginLeft: "0.938rem" }} className="col-md-11 col-lg-8 col-sm-12 form-control mt-2" onChange={(e) => inputsHandler(e)} disabled={disableProgramLineField} name="Justification" rows="4" value={rebateprogrmlinedetail.Justification}></textarea>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Rebate Definition Code */}

                                    <div className="col-lg-12 pr-lg-2 mt-4">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-8 text-left">
                                                        {t('Rebates.Rebate-Definition')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body" style={{ minHeight: "27vh" }}>
                                                <div className="container" style={{ marginLeft: "0px" }}>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "50vw" }}>
                                                            <thead className="thead-light">
                                                                <tr className='text-left'>
                                                                    <th>{t('Rebates.TargetCond')}</th>
                                                                    {SelectedRebateMeasureOptions.map((zone, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <th>{zone.label}</th>
                                                                            {index !== SelectedRebateMeasureOptions.length - 1 &&
                                                                                <th key={`condition_${index}`} colSpan="1">{t('Schemes.Condition')}</th>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}

                                                                    <th>{t('Schemes.Slab')}</th>
                                                                    {disableProgramLineField == false && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                                                                        <th></th>
                                                                        :
                                                                        ''
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    VolumeRebateList.map((item, index) => (
                                                                        <tr >
                                                                            <td><Form.Control style={{ width: "100px" }} onChange={(Event) => onTargetChange(Event, index)} className={`${classes['Form-Input-control']}  ${disabledClass}`} as="select" disabled={index !== 0 || (disableProgramLineField && revised == false)} value={item.Targetcondition} name="Targetcondition">
                                                                                <option>Select</option>
                                                                                <option>&gt;</option>
                                                                                <option>&ge;</option>
                                                                            </Form.Control>
                                                                            </td>
                                                                            {item?.measureValues && Array.isArray(item.measureValues) && item.measureValues.map((innerArray, dataIndex) => (
                                                                                <React.Fragment key={dataIndex}>
                                                                                    <td>
                                                                                        <NumericFormat
                                                                                            type="text"
                                                                                            onChange={(Event) => onMeasureValueChange(Event, index, dataIndex, 'Value')}
                                                                                            disabled={disableProgramLineField}
                                                                                            value={innerArray.Value}
                                                                                            name={innerArray.name}
                                                                                        />
                                                                                        {innerArray?.symbol}
                                                                                    </td>
                                                                                    {dataIndex < item.measureValues.length - 1 &&
                                                                                        <td key={`condition_${dataIndex}`}>
                                                                                            <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                                                                                <Form.Control
                                                                                                    className={`${classes['Form-Input-control']}`}
                                                                                                    as="select"
                                                                                                    disabled={disableProgramLineField || (index > 0 ? true : false || dataIndex === 0 ? false : true)}
                                                                                                    autoComplete="off"
                                                                                                    style={{ width: "80px", margin: "auto" }}
                                                                                                    value={item.Condition}
                                                                                                    onChange={(event) => OnConditionChange(event, index)}
                                                                                                >
                                                                                                    <option value="Select">{t('Select')}</option>
                                                                                                    <option value="AND">{t('ExceptionPolicy.And')}</option>
                                                                                                    <option value="OR">{t('ExceptionPolicy.OR')}</option>
                                                                                                </Form.Control>
                                                                                            </div>
                                                                                        </td>
                                                                                    }
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <td>
                                                                                <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled="true" value={`${item.Slab}`} name="Slab"
                                                                                    onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />
                                                                            </td>
                                                                            {(disableProgramLineField == false) && (rebatePayoutCond == 'Stepped' || rebatePayoutCond == 'Targeted') ?
                                                                                <td>
                                                                                    <div className={classes['gridwidth']} style={{ margin: "5px" }}>

                                                                                        {Service.editPerms(userPerms) == false ? "" : VolumeRebateList.length - 1 === index ? <a onClick={() => AddVolume(index)} className="btn btn-outline-dark  btn-sm">Add</a> : null}
                                                                                        {Service.editPerms(userPerms) == false ? "" : index !== 0 && VolumeRebateList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-outline-dark  btn-sm">Remove</a> : null}

                                                                                    </div>
                                                                                </td>
                                                                                : null}
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body" style={{ minHeight: "27vh" }}>
                                                <div className="container" style={{ marginLeft: "0px" }}>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "50vw" }}>
                                                            <thead className="thead-light">
                                                                <tr className='text-left'>
                                                                    <th>{t('Schemes.Slab')}</th>
                                                                    <th>{t('Rebates.Rebate')} ₹/%/LS</th>
                                                                    <th>{t('Rebates.RebateValue')}</th>
                                                                    <th>{t('Rebates.TargetMeasure')}</th>
                                                                    <th>{t('Schemes.IncludeForEligibilityCheck')}</th>
                                                                    <th>{t('Schemes.IncludeForAccrualDistribution')}</th>
                                                                    <th>{t('Schemes.ProductSelector')}</th>
                                                                    {(disableProgramLineField == false) ? <th></th> : ''}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {console.log(ProductTargetList)}
                                                                {
                                                                    ProductTargetList.map((item, index) => (
                                                                        <>
                                                                            {item?.ProductTargets && Array.isArray(item.ProductTargets) && item.ProductTargets.map((innerArray, dataIndex) => (
                                                                                <React.Fragment key={dataIndex}>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled="true" value={`${innerArray.Slab}`} name="Slab"
                                                                                                onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Form.Control style={{ width: "100px" }} className={`${classes['Form-Input-control']}  ${disabledClass}`} as="select" disabled={(disableProgramLineField) || (rebatePayoutCond !== 'Targeted' && rebatePayoutCond !== 'Stepped')} value={innerArray.Rebate} defaultValue={innerArray.Rebate} name="Rebate"
                                                                                                onChange={(Event) => handleSlabInputChange(Event, index, dataIndex, "Rebate")}>
                                                                                                <option>Select</option>
                                                                                                <option>%</option>
                                                                                                {rebateTypeValue == 'Growth in Rev' || rebateTypeValue == 'Revenue' ? '' :
                                                                                                    <option>₹</option>}
                                                                                                <option>LS</option>
                                                                                            </Form.Control>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled={disableProgramLineField} value={innerArray.Rebatevalue} name="Rebate_Value"
                                                                                                onChange={(Event) => handleSlabInputChange(Event, index, dataIndex, "Rebatevalue")} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Form.Control className={`${classes['Form-Input-control']}  ${disabledClass}`} autoComplete="off" style={{ width: "100px" }} disabled={true || (disableProgramLineField)} value={innerArray.Target_Measure} name="Target_Measure"
                                                                                                onChange={(Event) => handleSlabInputChange(Event, index, dataIndex, "Target_Measure")} />
                                                                                        </td>
                                                                                        {dataIndex === 0 ?
                                                                                            <>
                                                                                                <td rowSpan={item.ProductTargets.length}>
                                                                                                    <input
                                                                                                        type="checkbox"

                                                                                                        style={{ width: "100px" }}
                                                                                                        disabled={(disableProgramLineField || (dataIndex === 0 ? false : true))}
                                                                                                        onChange={(Event) => handleSlabInputChange(Event, index, dataIndex, "IncludeEligibilityCheck")}
                                                                                                        checked={innerArray.IncludeEligibilityCheck}

                                                                                                    />
                                                                                                </td>
                                                                                                <td rowSpan={item.ProductTargets.length}>
                                                                                                    <input
                                                                                                        type="checkbox"

                                                                                                        style={{ width: "100px" }}
                                                                                                        disabled={(disableProgramLineField || (dataIndex === 0 ? false : true))}
                                                                                                        onChange={(Event) => handleSlabInputChange(Event, index, dataIndex, "IncludeAccrualDistribution")}
                                                                                                        checked={innerArray.IncludeAccrualDistribution}

                                                                                                    />
                                                                                                </td>
                                                                                                <td rowSpan={item.ProductTargets.length}>
                                                                                                    <div className="col-md-2 mt-2 text-left">
                                                                                                        <button className="btn btn-outline-dark  btn-sm ml-2" onClick={(e) => clickShowProductPopup(e, index)} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                                                                        <br />
                                                                                                        <a href='javascript:void(0)' onClick={(e) => clickSelectedShowProductPopup(e, index)}><label style={{ paddingTop: "5px" }}>({item.Products?.length}) {t('Rebates.ProductsAdded')}</label></a>
                                                                                                    </div>
                                                                                                </td>

                                                                                                {(disableProgramLineField == false) ?
                                                                                                    <td rowSpan={item.ProductTargets.length}>
                                                                                                        <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                                                                                            {Service.editPerms(userPerms) == false ? "" : ProductTargetList.length - 1 === index ? <a onClick={() => AddProductVolume(index)} className="btn btn-outline-dark  btn-sm">Add</a> : null}
                                                                                                            {Service.editPerms(userPerms) == false ? "" : index !== 0 && ProductTargetList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveProductVolume(index)} className="btn btn-outline-dark  btn-sm">Remove</a> : null}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    : ''}
                                                                                            </>
                                                                                            :
                                                                                            ''}
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div style={{ display: 'flex', alignItems: 'center' }} class="col-md-2">
                                                    <label htmlFor="includePolicyCheckbox" style={{ fontSize: '14px' }}>Include Achievement Policy</label>
                                                </div>
                                                <div class="col-md-1">
                                                    <input id="includePolicyCheckbox" checked={includeAchievementPolicy} disabled={disableProgramLineField}
                                                        type="checkbox" onChange={(event) => OnChangeHandlerAchivePolicycheckbox(event)} />
                                                </div>
                                                {includeAchievementPolicy == true || selectedAchievementPolicy != null ?
                                                    <Select
                                                        className={`col-md-2 textbox-padding mt-2 fontsizedropdown ${disabledClass}`}
                                                        options={achivementpolicyData.filter(item => item.Parent === currentFiscalYear)}
                                                        placeholder="Select"

                                                        value={achivementpolicyData.filter(function (option) {
                                                            return option.value == `${selectedAchievementPolicy}`
                                                        })}
                                                        onChange={OnChangeHandlerAchivePolicy}
                                                        isSearchable={true}
                                                        isDisabled={disableProgramLineField}
                                                        styles={customStyles}
                                                    // isDisabled={includeAchievementPolicy}
                                                    />
                                                    : ''
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }


            {/* Rebate Program Line table list  */}

            {

                showrebateProgramLine === false && hideRpl == false ?
                    <div className="row mt-3" id="card-RPL">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            {t('Rebates.RebateProgramLine')}
                                        </div>
                                        <div class="col-md-3">
                                        </div>
                                        <div class="col-md-3">
                                            {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-1" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                            {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' ? '' : <>
                                            </>}

                                            {showbutton === true ?
                                                <>
                                                    {loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" type="submit" style={{ float: "right", width: "40px" }} onClick={(event) => OnSelectHandler(checkedValue, event)}>{t('Open')}</button>}
                                                    {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "40px" }} type="submit" onClick={(event) => OnCopyHandler(checkedValue)}>{t('Copy')}</button>}
                                                    {loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "65px" }} onClick={() => { clickShowWorkflow("Workflow", checkedValue) }} type="submit">{t('Workflow')}</button>}
                                                    {Service.editPerms(userPerms) == false ? "" : loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>}
                                                </>
                                                :
                                                deleteshowbutton === true ?
                                                    loadedFrom === 'fromApproval' ? '' : <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* changes made here */}
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="col-md-5 col-sm-12" style={{ marginTop: '10px' }}>
                                        <input type="text" className="form-control form-control-user col-md-12" id="firstName" name="firstName" autoComplete='off' placeholder={` ${t('Search for Program Lines By Product')}`} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-1 col-sm-12" style={{ marginTop: '10px', textAlign: 'right', "margin-right": "-30px" }}>
                                        <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                            style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                            onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                    </div>
                                </div>



                                <div className="card-body" style={{ minHeight: "35vh", overflowX: "auto" }}>
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="table-RPL">
                                        <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                            <tr>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={checkAll}
                                                        checked={checkAllChecked}
                                                        id="option-all"
                                                    />
                                                </th>
                                                <th id='RebateProgramLineId' style={{ backgroundColor: (filterValueDict.RebateProgramLineId ? '#6cae55' : ''), maxWidth: "135px" }} hidden={hideColumn.includes('RebateProgramLineId') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.RebateProgramId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineId')}></span>
                                                    </div>
                                                </th>

                                                <th id='RebateProgram_Desc' style={{ backgroundColor: (filterValueDict.RebateProgram_Desc ? '#6cae55' : ''), maxWidth: "150px" }} hidden={hideColumn.includes('RebateProgram_Desc') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.RebateProgramDesc')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgram_Desc')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgram_Desc')}></span>
                                                    </div>
                                                </th>
                                                <th id='Rebate_Type' style={{ backgroundColor: (multiFilterValue.Rebate_Type ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('Rebate_Type') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.RebateType')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_Type')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Rebate_Type')}></span>
                                                    </div>
                                                </th>
                                                <th id='RebateCategory' style={{ backgroundColor: (multiFilterValue.RebateCategory ? '#6cae55' : ''), maxWidth: "115px" }} hidden={hideColumn.includes('RebateCategory') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.RebateCat')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateCategory')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateCategory')}></span>
                                                    </div>
                                                </th>
                                                <th id='Validity_From' style={{ backgroundColor: (filterValueDict.Validity_From ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('Validity_From') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.ValidFrom')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_From')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Validity_From')}></span>
                                                    </div>
                                                </th>
                                                <th id='Validity_To' style={{ backgroundColor: (filterValueDict.Validity_To ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('Validity_To') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.ValidTo')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_To')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Validity_To')}></span>
                                                    </div>
                                                </th>
                                                <th id='Settlement_Freq' style={{ backgroundColor: (multiFilterValue.Settlement_Freq ? '#6cae55' : ''), maxWidth: "155px" }} hidden={hideColumn.includes('Settlement_Freq') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.AccrualFreq')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Settlement_Freq')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Settlement_Freq')}></span>
                                                    </div>
                                                </th>
                                                <th id="Status" style={{ backgroundColor: (multiFilterValue.Status ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('Status') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.Status')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
                                                    </div>
                                                </th>
                                                <th id="Active" className="tableheader" style={{ backgroundColor: (multiFilterValue.Active ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('Active') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Rebates.IsActive')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Active')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Active')}></span>
                                                    </div>
                                                </th>
                                                <th>{t('Rebates.Actions')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    rebateProgramLine.length === 0 ?
                                                        <tr><td></td>
                                                            <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        rebateProgramLine.map((line, index) =>
                                                            <tr onDoubleClick={(event) => OpenRebate(event, line.Id, line)}>
                                                                <td >

                                                                    <input id={line.Id} type="checkbox" name='chkBox' value={`${line.rebateprogramline_id},${line.Status}`} onChange={(event) => handleChange(event, line.Id, line.Status, line.RebateProgramLineId)} /></td>
                                                                <td hidden={hideColumn.includes('RebateProgramLineId') ? true : false}>{line.RebateProgramLineId}</td>
                                                                <td hidden={hideColumn.includes('RebateProgram_Desc') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={line.RebateProgram_Desc}>{line.RebateProgram_Desc}</div></td>
                                                                <td hidden={hideColumn.includes('Rebate_Type') ? true : false}>{line.Rebate_Type}</td>
                                                                {/* <td hidden={hideColumn.includes('defn') ? true : false}>{line.Rebate_Definition}</td> */}
                                                                <td hidden={hideColumn.includes('RebateCategory') ? true : false}>{line.RebateCategory}</td>
                                                                <td hidden={hideColumn.includes('Validity_From') ? true : false}>{moment(line.Validity_From).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('Validity_To') ? true : false}>{moment(line.Validity_To).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('Settlement_Freq') ? true : false}>{"End Of Period"}</td>
                                                                <td hidden={hideColumn.includes('Status') ? true : false}>{line.Status}</td>
                                                                <td align="center" hidden={hideColumn.includes('Active') ? true : false}>
                                                                    {line.Status === "Approved"
                                                                        ?
                                                                        <>
                                                                            {Service.submitRebatePerms(userPerms) == false ? (line.Active === true ? "Active" : "In-Active") : line.Active === true ?
                                                                                <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => checkActiveStatus(line, "Open-A")}>Active</a>
                                                                                :
                                                                                <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => checkActiveStatus(line, "Open-D")}>In-Active</a>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </td>

                                                                <td>

                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        <i className='fa fa-product-hunt' onClick={(event) => showproducts(line.Id, line.Status)} title="Show Products"></i>
                                                                    </div>

                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>

                                                                        {Service.submitRebatePerms(userPerms) == false ? "" : (line.Status === "New" || line.Status === "InReview" || line.Status == "Revising") ? <img src='/NewAssets/images/analysis.png' style={{ width: '14px', height: "14px", cursor: "pointer" }} title="Analyze Rebate" onClick={(event) => EligibleInvoicePopup(line.Id, "AnalyzeRebate")}></img> : <></>}
                                                                        {Service.submitRebatePerms(userPerms) == false ? "" : line.Status === "Approved" && line.Active === true ? <img src='/NewAssets/images/invoice.ico' style={{ width: '19px', cursor: "pointer" }} title="Eligible Invoice" onClick={(event) => EligibleInvoicePopup(line.Id, "EligibleInvoice")}></img> : <></>}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )

                                            }


                                        </tbody>


                                    </table>
                                    

                                    <div className='row'>
                                        {
                                            totalselectedshow === true ?

                                                <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                    {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                                </div>
                                                :
                                                <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                                </div>
                                        }
                                          
                                        
                                        <div style={{flex: "0 0 64%",maxWidth: "64%"}}></div>
                                        <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" ,marginLeft: "0.1rem"}}>
                                            {t('TotalRecords')} - {totalCountlst}
                                        </div>
                                    </div>
                                    {
                                        showLoadMore === true ?
                                            <div className='text-center container row mb-1'>
                                                <div className='col-md-5'></div>
                                                <div className='col-md-2'>
                                                    <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                                </div>
                                                <div className='col-md-5'></div>

                                            </div>
                                            :
                                            ""
                                    }

                                </div>

                            </div>
                        </div>

                    </div>
                    :
                    ''
            }



            {/* changes made here */}
            {showAddColumnPopup === true ?
                // <Modal show={showAddColumnPopup} size="sm"
                //     centered>
                //     <Modal.Body style={{ "textAlign": 'center' }} >
                <div className='pop-up' style={{ right: "50px", top: divtop, width: "250px" }}>
                    <div className="container">
                        <div className="row">
                            <Select
                                className='col-md-10 textbox-padding fontsizedropdown'
                                options={colVisibilityOption}
                                placeholder="Select"
                                value={colvisiOptionVal}
                                onChange={OnChangeColVisibilityList}
                                styles={customStyles}
                            />
                            <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-md-10" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                            </div>
                        </div>
                        <div className="row ml-1">
                            <table className='ml-2' style={{ fontSize: '0.80rem', textAlign: 'justify' }}>
                                {
                                    addRemoveCol.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} className="form-check-input" data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                            /> <label className="form-check-label text-left" htmlFor={item.label}>
                                                    {t(item.label)}
                                                </label></td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                //   </Modal.Body>
                // </Modal>
                : <></>
            }



            {showCreateColumnListPopup === true ?
                // <Modal show={showAddColumnPopup} size="sm"
                //     centered>

                //     <Modal.Body style={{ "textAlign": 'center' }} >
                // removed modal and introduced below pop-up class with div --by dheeraj S

                <div className='pop-up' style={{ left: "1030px", top: "404px", width: "190px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                            </div>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                            <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                            </div>
                        </div>
                        <div className="row">
                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                {
                                    createColumnListOption.map((item, index) => (
                                        <tr>
                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                            /> {t(item.label)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                            </div>
                        </div>
                    </div>


                </div>

                //     {/* </Modal.Body>

                // </Modal> */}

                : <></>
            }

            {
                showregionpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.RegionListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>
                                                                <input checked={regioncheckAll} disabled={disableProgramLineField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Region')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer" style={{ marginLeft: "0px" }}>
                                                    {
                                                        SalesLevelData.map((regionlist) =>
                                                            <div className='col-md-3' style={{ padding: "5px;" }}>
                                                                <input type="checkbox"
                                                                    disabled={(disableProgramLineField && revised == false) || (regionlist.avalRegion == false)}
                                                                    className={disabledClass}
                                                                    checked={regionlist.selected} name="region-check" value={regionlist.Id} id={regionlist.Id} onChange={(event) => handleregionChanges(event, regionlist.label, regionlist.Id)} />
                                                                &nbsp;<label>{regionlist.label}</label>
                                                            </div>
                                                        )
                                                    }
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okregion} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedRegionpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.RegionListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input disabled="true" type="checkbox" name="option-all-region" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Region')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            regionDataSelected.map((i) =>
                                                                <tr key={i.Id}>
                                                                    <td><input type="checkbox"
                                                                        disabled={true}
                                                                        className={disabledClass}
                                                                        checked={i.selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                                                    <td>{i.label}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showCustomerpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className='row align-items-center my-2' style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-9 text-left ">
                                                    {t('Rebates.CustomerListing')}
                                                </div>
                                                <div className='' style={{ marginLeft: "25px" }} >
                                                    <button style={{ width: "80px" }} className='btn btn-outline-dark  btn-sm ml-4' onClick={okCustomer}>{t('Save&Close')}</button>
                                                </div>
                                                <div className="">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1" id="Customer">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>

                                        <div className='scrollbar-class'>

                                            <div className="container">
                                                <div>

                                                    <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.Customer')}`} />
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="Customer">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    checked={customerCheckAll}
                                                                    type="checkbox"
                                                                    disabled={disableHeaderField}
                                                                    id="option-all-customer"
                                                                    onChange={checkAllCustomer}
                                                                />
                                                            </th>
                                                            <th id="Name" style={{ backgroundColor: (filterValueCustomer.Name ? '#6cae55' : ''), width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Name')}></span></th>
                                                            <th id="Label" style={{ backgroundColor: (filterValueCustomer.Label ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Label')}></span></th>
                                                            <th id="Station" style={{ backgroundColor: (filterValueCustomer.Station ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Station')}></span></th>
                                                            <th id="State" style={{ backgroundColor: (filterValueCustomer.State ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('State')}></span></th>
                                                            <th id="Zone" style={{ backgroundColor: (filterValueCustomer.Zone ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Zone')}></span></th>
                                                        </tr>
                                                    </thead>
                                                    <thead>

                                                        {
                                                            customerData.map((cslist, index) => (

                                                                <tr onClick={disableHeaderField == false ? (event) => handleCustSelection(event, cslist.label, cslist.Id, cslist.State) : null}>
                                                                    <td key={cslist.Id} style={{ width: "30px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={disabledClass}
                                                                            disabled={disableHeaderField}
                                                                            checked={cslist.Selected}
                                                                            name="customer-check"
                                                                            value={cslist.Id}
                                                                            id={cslist.Id}
                                                                            onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: "90px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Name}>{cslist.Name}</div>


                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.label}>{cslist.label}</div>


                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Station}>{cslist.Station}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.State}>{cslist.State}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Zone}>{cslist.Zone}</div>

                                                                    </td>

                                                                </tr>





                                                            ))


                                                        }


                                                    </thead>
                                                </table>


                                            </div>

                                        </div>
                                        {/* <div>
                                            <button style={{ float: "right", marginTop: "10px", marginRight: "50px", width: "30px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomer}>{t('Ok')}</button>
                                        </div> */}
                                    </div>
                                </div>

                            </div>

                        </div>
                        {
                            showCustomerfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder="Search" value={filterSearchCust} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            {/* <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                                                <td>Select All</td>
                                                            </tr> */}
                                                            {
                                                                filterCusDropDownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all-customer" checked={item.selected} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilterCustomer : (event) => OnCusdropdownChange(event, item.Id, item.Name, custFilterCol)} /></td>

                                                                        <td>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>

                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    :
                    ""
            }
            {
                showSelectedCustomerpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row" style={{ "margin-left": "-20px" }} >
                                                <div className="col-md-9 text-left">
                                                    {t('Rebates.CustomerListing')}
                                                </div>
                                                {/* <div style={{  backgroundColor: "#fff","margin-left":"55px" }}>
                                                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                 </div> */}
                                                <div className="" style={{ marginLeft: "135px" }}>
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div className='scrollbar-class'>

                                            <div className="container">
                                                <div>
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>

                                                            </th>
                                                            <th>{t('Rebates.CustomerCode')}</th>
                                                            <th>{t('Rebates.CustomerName')}</th>
                                                            <th>{t('Rebates.Station')}</th>
                                                            <th>{t('Rebates.State')}</th>
                                                            <th>{t('Rebates.Zone')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {
                                                            customerDataSelected.map((cslist, index) => (
                                                                <tr key={index}>

                                                                    <td style={{ width: "30px" }}>
                                                                        {/* Commented this */}
                                                                        {/* {disableHeaderField == true ? <> */}
                                                                        <input type="checkbox"
                                                                            disabled={true}
                                                                            className={disabledClass}
                                                                            checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                        {/* </> : <>
                                                                            <input type="checkbox"
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                        </>
                                                                        } */}
                                                                    </td>

                                                                    <td style={{ width: "90px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Name}>{cslist.Name}</div>


                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.label}>{cslist.label}</div>


                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Station}>{cslist.Station}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.State}>{cslist.State}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Zone}>{cslist.Zone}</div>

                                                                    </td>



                                                                </tr>


                                                            ))


                                                        }

                                                    </tbody> </table>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                            </div> */}
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showCustomerGroupPopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.GroupCustomerListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1" id="CustomerGroup">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>

                                        <div className='scrollbar-class'>

                                            <div className="container">
                                                <div>
                                                    <input type="text" className='form-control form-control-user' value={customerGroupSearch} onChange={(event) => SearchGroupCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.GroupCustomer')}`} />
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="CustomerGroup">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input checked={customercheckAll} type="checkbox" disabled={disableHeaderField} id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                                            </th>
                                                            <th id="GroupLabel" style={{ backgroundColor: (filterValueGroupCustomer.label ? '#6cae55' : ''), maxWidth: "110px" }}>{`${t('Rebates.CustomerGroup')} ${t('Policies.Label')}`}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnGroupCustomer('label')}></span></th>
                                                        </tr>
                                                    </thead>
                                                    <thead>

                                                        {
                                                            groupCustomerData.map((cslist, index) => (

                                                                <tr>
                                                                    <td style={{ width: "30px" }}>

                                                                        {disableHeaderField == true ? <>
                                                                            <input type="checkbox"
                                                                                disabled={true}
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                                                                name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                                                        </> : <>
                                                                            <input type="checkbox"
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                                                        </>
                                                                        }</td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.label}>{cslist.label}</div>
                                                                    </td>

                                                                </tr>

                                                            ))
                                                        }


                                                    </thead>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <button style={{ float: "right", marginTop: "10px", marginRight: "50px", width: "30px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomerGroup}>{t('Ok')}</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    showCustomerGroupfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                                            {custGroupFilterCol === 'label' ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCustGroup}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder="Search" value={filterSearchCustGroup} onChange={(event) => UserfilterHandlerCustomerGroup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomerGroup} /></td>
                                                                        <td>Select All</td>
                                                                    </tr>
                                                                    {
                                                                        filterCustGroupDropDownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.selected} type="checkbox" onChange={(event) => OnCustGroupdropdownChange(event, item.Id, item.Value)} /></td>

                                                                                <td>{item.Value}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>

                                            }
                                            <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandlerCustomerGroup('Apply')}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerCustomerGroup('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>

                        </div>
                        {
                            showCustomerfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                                                <td>Select All</td>
                                                            </tr>
                                                            {
                                                                filterCusDropDownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.selected} type="checkbox" onChange={(event) => OnCusdropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>

                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    :
                    ""
            }

            {
                showSelectedCustomerGroupPopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.CustomerListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div className='scrollbar-class'>

                                            <div className="container">
                                                <div>
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>

                                                            </th>
                                                            <th>{t('Rebates.CustomerCode')}</th>
                                                            <th>{t('Rebates.CustomerName')}</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {
                                                            customerGroupDataSelected.map((cslist, index) => (
                                                                //  <>   {index% 3 == 0?"<tr>":""}
                                                                <tr>

                                                                    <td style={{ width: "30px" }}>
                                                                        {disableHeaderField == true ? <>
                                                                            <input type="checkbox"
                                                                                disabled={true}
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerGroupSelectedChanges(event, cslist.label, cslist.Id)} />

                                                                        </> : <>
                                                                            <input type="checkbox"
                                                                                className={disabledClass}
                                                                                checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerGroupSelectedChanges(event, cslist.label, cslist.Id)} />

                                                                        </>

                                                                        } </td>

                                                                    <td style={{ width: "90px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Name}>{cslist.Name}</div>


                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.label}>{cslist.label}</div>
                                                                    </td>

                                                                </tr>


                                                            ))
                                                        }

                                                    </tbody> </table>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomerGroup} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showZonePopup === true ?
                    < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className='d-flex align-items-center my-2' style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ZoneListing')}
                                                </div>
                                                <div className='ml-3'>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                </div>
                                                <div className="">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickClose('zone')} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>
                                                                <input
                                                                    checked={zonecheckAll}
                                                                    disabled={disableHeaderField}
                                                                    onChange={(event) => checkAllForPopups('zone', event)}
                                                                    type="checkbox"
                                                                    name="option-all-zone"
                                                                />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Zone')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer" style={{ marginLeft: "0px" }}>

                                                    {
                                                        zoneOptions.map((zone) => (
                                                            <div className='col-md-3' style={{ padding: "5px" }} key={zone.value}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={disableHeaderField}
                                                                    checked={zone.Selected}
                                                                    name="zone-check"
                                                                    value={zone.label}
                                                                    id={zone.value}
                                                                    onChange={(event) => manageSelectedPopupData(event, zone.label, zone.value, 'zone')}
                                                                />
                                                                &nbsp;<label>{zone.label}</label>
                                                            </div>
                                                        ))
                                                    }

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div> */}
                        </div>
                    </div>
                    :
                    ""

            }
            {
                showSelectedZonepopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row" style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ZoneListing')}
                                                </div>
                                                <div className="" style={{ "margin-left": "90px" }}>

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('zone')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input disabled="true" type="checkbox" name="option-all-zone" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Zone')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            zoneDataSelected.map((i) =>
                                                                <tr key={i.value}>
                                                                    <td><input type="checkbox"
                                                                        disabled={true}
                                                                        className={disabledClass}
                                                                        checked={i.Selected} name="zone-check" value={i.value} id={i.value} /></td>
                                                                    <td>{i.label}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showStatePopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className='d-flex align-items-center my-2' style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.StateListing')}
                                                </div>
                                                <div className='ml-3'>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                </div>
                                                <div className="">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickClose('state')} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>

                                                            <th style={{ width: "20px" }}>
                                                                <input
                                                                    checked={statecheckAll}
                                                                    disabled={disableHeaderField}
                                                                    onChange={(event) => checkAllForPopups('state', event)}
                                                                    type="checkbox"
                                                                    name="option-all-state"
                                                                />
                                                            </th>


                                                            <th id="Product">
                                                                {t('Rebates.State')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer" style={{ marginLeft: "0px" }}>

                                                    {
                                                        stateOptions.map((state) => (
                                                            <div className='col-md-3' style={{ padding: "5px" }} key={state.value}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={disableHeaderField}
                                                                    checked={state.Selected}
                                                                    name="state-check"
                                                                    value={state.label}
                                                                    id={state.value}
                                                                    onChange={(event) => manageSelectedPopupData(event, state.label, state.value, 'state')}
                                                                />
                                                                &nbsp;<label>{state.label}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div> */}
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedStatepopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row" style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-11 text-left">
                                                    {t('Rebates.StateListing')}
                                                </div>
                                                <div className="ml-3">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('state')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input disabled="true" type="checkbox" name="option-all-state" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.State')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            stateDataSelected.map((i) =>
                                                                <tr key={i.value}>
                                                                    <td><input type="checkbox"
                                                                        disabled={true}
                                                                        className={disabledClass}
                                                                        checked={i.Selected} name="state-check" value={i.value} id={i.value} /></td>
                                                                    <td>{i.label}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showStationPopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className='d-flex align-items-center my-2' style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.StationListing')}
                                                </div>
                                                <div className='ml-3'>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                </div>
                                                <div className="">
                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickClose('station')} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>
                                                                <input
                                                                    checked={stationcheckAll}
                                                                    disabled={disableHeaderField}
                                                                    onChange={(event) => checkAllForPopups('station', event)}
                                                                    type="checkbox"
                                                                    name="option-all-station"
                                                                />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Station')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer" style={{ marginLeft: "0px" }}>

                                                    {
                                                        stationOptions.map((station) => (
                                                            <div className='col-md-3' style={{ padding: "5px" }} key={station.value}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={disableHeaderField}
                                                                    checked={station.Selected}
                                                                    name="station-check"
                                                                    value={station.label}
                                                                    id={station.value}
                                                                    onChange={(event) => manageSelectedPopupData(event, station.label, station.value, 'station')}
                                                                />
                                                                &nbsp;<label>{station.label}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "30px" }}>{t('Ok')}</button>
                            </div> */}
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showSelectedStationpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row" style={{ "margin-left": "-20px" }}>
                                                <div className="col-md-11 text-left">
                                                    {t('Rebates.StationListing')}
                                                </div>
                                                <div className="ml-3">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('station')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input disabled="true" type="checkbox" name="option-all-station" />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Rebates.Station')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            stationDataSelected.map((i) =>
                                                                <tr key={i.value}>
                                                                    <td><input type="checkbox"
                                                                        disabled={true}
                                                                        className={disabledClass}
                                                                        checked={i.Selected} name="station-check" value={i.value} id={i.value} /></td>
                                                                    <td>{i.label}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }

            {
                showRebateMeasurePopup === true ?
                    < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh", "margin-right": "5vw" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="chart-grid__header">
                                            <div className='row align-items-center' style={{ "margin-left": "1px" }}>

                                                <div className="col-md-9 text-left">
                                                    {t('Schemes.RebateMeasureListing')}
                                                </div>
                                                <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff", "margin-left": "24px" }}>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('rebatemeasure')} style={{ width: "80px" }}>{t('Save & Close')}</button>
                                                </div>
                                                <div className="ml-1">
                                                    <button className="btn btn-outline-dark  btn-sm" onClick={() => onClickClose('rebatemeasure')} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>
                                        <div style={{ minHeight: "58vh" }}>
                                            <div className="container">
                                                <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>
                                                                <input
                                                                    checked={zonecheckAll}
                                                                    disabled={disableField}
                                                                    onChange={(event) => checkAllForPopups('rebatemeasure', event)}
                                                                    type="checkbox"
                                                                    name="option-all-zone"
                                                                />
                                                            </th>

                                                            <th id="Product">
                                                                {t('Schemes.RebateMeasure')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                                < div className='row' id="div_customer" style={{ "margin": "0px  1px" }}>

                                                    {
                                                        measureOptions.map((zone) => (
                                                            <div className='col-md-4' style={{ padding: "5px" }} key={zone.value}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={disableProgramLineField || (zone.Name !== 'measurevalue_days' && measureOptions.find(option => option.Name === 'measurevalue_days').Selected)}
                                                                    checked={zone.Selected}
                                                                    name="zone-check"
                                                                    value={zone.Name}
                                                                    id={zone.value}
                                                                    onChange={(event) => manageSelectedMeasurePopupData(event, zone, 'rebatemeasure')}
                                                                />
                                                                &nbsp;<label>{zone.label}</label>
                                                            </div>
                                                        ))
                                                    }

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    ""

            }
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

            <Modal show={showSubmitModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                        onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('showSubmitDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showInvoiceModal}
                centered size="bg">
                <div class="modal-body">
                    <div class="container-fluid">
                        <h6 style={{ "textAlign": 'center', marginBottom: "10px" }}>{t('Rebates.SelectAccrualPeriod&Customer')}</h6>
                        <hr></hr>
                        <div style={{ marginTop: "5px" }}>
                            <div className="row">
                                <label className="col-md-3 text-left">{t('Rebates.AccrualPeriod')}</label>
                                <Multiselect
                                    onSelect={(Event) => OnPopupPeriodChange(Event)}
                                    onRemove={(Event) => OnPopupPeriodChange(Event)}
                                    options={invoicePeriodValue}
                                    displayValue="RebatePeriod"
                                    className='col-md-9 textbox-padding fontsizedropdown'
                                    style={regionStyles}
                                    singleSelect="true"
                                />
                            </div>
                            <br></br>
                            <div className="row">
                                <label className="col-md-3 text-left">{t('Rebates.Customer')}</label>
                                <Multiselect
                                    onSelect={(Event) => OnPopupCustomerChange(Event)}
                                    onRemove={(Event) => OnPopupCustomerChange(Event)}
                                    options={customerInvoiceValue}
                                    displayValue="label"
                                    selectedValues={customerInvoiceValue}
                                    className='col-md-9 textbox-padding fontsizedropdown'
                                    style={CustomerStyles}
                                />
                            </div>
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button size='sm' onClick={(event) => setShowInvoiceModal(false)} style={{ width: "45px" }}>
                                {t('Close')}
                            </Button>
                            {whoCalled === "EligibleInvoice" ?
                                <Button size='sm' style={{ marginLeft: "5px", width: "30px" }} onClick={(event) => AccrualEligibleInvoice(rpl_ID)}>
                                    {t('Ok')}
                                </Button>
                                :
                                ""
                            }
                            {whoCalled === "AnalyzeRebate" ?
                                <Button size='sm' style={{ marginLeft: "5px", width: "30px" }} onClick={(event) => AccrualAnalyzeRebate(rpl_ID)}>
                                    {t('Ok')}
                                </Button>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                        {t('Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprovedModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
                        onChange={(e) => { setCommentsForApproved(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeniedModal} size="bg"
                centered>
                <Modal.Header>
                    <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                    <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
                        onChange={(e) => { setCommentsForDenied(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
                        {t('Cancel')}
                    </Button>
                    <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
                        {t('Submit')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                    Rebate Programs in New status only can be deleted
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => CloseRebateProgrmLine("RPLClose")}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => CloseRebateProgrmLine("RPLCancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={closeHeaderModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => CloseAgreement("HeaderClose")}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => CloseAgreement("HeaderCancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Rebates.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleProgramLineActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleProgramLineActiveStatus(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal1}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={() => DeleteGeneratedAccruals(activeData.Id, loggedInUser, 'Active')}>
                        {t('Proceed')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => setToggleStatusModal1(false)}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ToggleStatusModal2}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => setToggleStatusModal2(false)}>
                        {t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={custChngModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.ChangingTheCustomerWillChangeTheExistingRegionData')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "30px" }} size='sm' onClick={() => OnChangeHandlercustomer(selectedOptions, "Ok")}>
                        {t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={productsModal}
                centered size="sm">
                <div class="modal-body">
                    <div class="container-fluid">
                        <h5 style={{ "textAlign": 'center' }}>{t('Rebates.SelectedProducts')}</h5>
                        <hr></hr>
                        <div style={{ "textAlign": 'center', marginTop: "5px" }}>
                            <label></label>
                        </div>
                        <div style={{ marginLeft: "160px", marginTop: "8px" }}>
                            <Button size='sm' onClick={() => setProductsModal(false)} style={{ width: "45px" }}>
                                {t('Close')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={showWorkflowAlert}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
                <Modal.Footer>
                    <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => workflowAlert('close')}>
                        {t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAccrualDialogForRevise}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{message}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={() => DeleteGeneratedAccruals(rebateprogrmlinedetail.id, loggedInUser, 'Revise')}>
                        {t('Proceed')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => setShowAccrualDialogForRevise(false)}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showfilterpopup === true ?
                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                        {filterCol === 'RebateProgramLineId' || filterCol === 'RebateProgram_Desc' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">
                                        <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        {filterCol === 'Validity_From' || filterCol === 'Validity_To' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">
                                        <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        {filterCol === 'Settlement_Freq' || filterCol === 'Rebate_Type' || filterCol === 'RebateCategory' || filterCol === 'Active' || filterCol === 'Status' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                    </div>

                                </div>

                                <div className="container mt-2">
                                    <div className="row">
                                        <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                    </div>

                                    <div className="row contentoverflow mt-2">
                                        <table className="filtertable">
                                            <tbody>
                                                {
                                                    dropdownData.map((item, index) => (

                                                        <tr key={index}>

                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                            <td>{item.Name}</td>

                                                        </tr>


                                                    ))
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }
                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                showProductLoadSpinner === true ?
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{
                        position: 'absolute',
                        left: '50%', top: '55%', zIndex: 1, width: '60px', height: '60px', margin: '-75px 0 0 -75px',
                        border: '7px solid #f3f3f3', borderRadius: '50%', borderTop: '7px solid #3498db',
                        animation: 'spin 1s linear infinite', borderColor: 'rgb(10 173 216) rgb(92 187 72) rgb(10 173 216)'
                    }}></span>
                    : <></>
            }




            {/* {
                showproductpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Rebates.ProductListing')}
                                                </div>
                                                <div className="col-md-2">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">
                                                <div className="card-header chart-grid__header">

                                                </div>
                                                <div style={{ minHeight: "66vh" }}>
                                                    <div className="container">
                                                        <div className='col-md-12'>

                                                            {clickProduct === false ?
                                                                <ProductTreeDialog
                                                                    show={showModal}

                                                                    onOkClick={UpdateProducts}
                                                                    onHide={() => CloseProductPicker}
                                                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                                                    rebatelinestatus={rebatelinestatus}
                                                                    dialogType="Product"
                                                                    SelectedData={FiltedProducts}
                                                                    dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                                                    selectedType={selectedType}
                                                                    programLineStatus={rebateprogrmlinedetail.status}
                                                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                >
                                                                    <div className={classes['Radiobutton-header']}>
                                                                        <div className={classes['Radiobutton-internal-div']} >
                                                                            <input type="radio" checked={selectedType === 'Attribute'}
                                                                                value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                            <input type="radio" checked={selectedType === 'Heirarchy'}
                                                                                onChange={SelectedProductTypeHandler}
                                                                                className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                                        </div>
                                                                    </div>
                                                                </ProductTreeDialog>
                                                                : ''
                                                            }

                                                        </div>

                                                    </div>




                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            } */}


        </div>
    )

} export default CreateProductSchemeComponent