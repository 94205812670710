import CreateProductSchemeComponent from "../../../Components/SchemeComponent/CreateProductScheme/CreateProductScheme"
import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
// import '../index.css';



function CreateProductScheme(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <CreateProductSchemeComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}
export default CreateProductScheme