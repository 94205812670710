import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AccuralsListComponent() {

  let loggedInUser = SessionManage.getuserid()
  const csvInstance = useRef();
  const [t, i18n] = useTranslation('translation');
  const [csvList, setCsvList] = useState([]);
  const [headers, setHeaders] = useState([
    { label: "Rebate Agreement ID", key: "RebateHeaderId" },
    { label: "Customer ", key: "CustomerName" },
    { label: "Rebate Program ID", key: "RebateProgramLineId" },
    { label: "Accrual ID", key: "AccrualId" },
    { label: "Currency", key: "Currency" },
    { label: "Incentive Amount", key: "AccrualsAggregate" },
    { label: "Accrual Status ", key: "AccuralStatus" },
    { label: "Rebate Period", key: "FiscalYearPeriod" },
    { label: "Last Modified By", key: "LastUserName" },
    { label: "Last Modified Date", key: "LastModifiedDate" },
    { label: "Created By", key: "CreateUserName" },
    { label: "Created Date", key: "CreatedDate" },
  ]);
  const [sortColName, setSortColName] = useState("");
  const [sticky, setSticky] = useState("");
  const [userPerms, setuserPermission] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [showbutton, setshowbutton] = useState('');
  const [showspinner, setshowspinner] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [filterCol, setFilterCol] = useState('');
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [searchValue, setSearchValue] = useState(null)
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [filtertext, setfiltertext] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterValueDict, setFilterValueDict] = useState({
    RebateHeaderId: '',
    RebateProgramLineId: '',
    RebateProgramDesc: '',
    AccrualId: '',
    Currency: '',
    AccuralStatus: '',
    FiscalYearPeriod: '',
    CreatedDate: null,
    LastModifiedDate: '',

  });
  const [multiFilterValue, setMultiFilterValue] = useState({
    CustomerName: '',
    CreateUserName: '',
    LastUserName: ''
  });

  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [totalCountlst, settotalCountlst] = useState('');

  let rowData = [];

  const [listViewData, setListViewData] = useState(rowData)
  const [filteredArray, setFilteredArray] = useState(rowData)
  const [columnAscending, setColumnAscending] = useState(true);

  // New state to manage the Check All checkbox
  const [checkAllChecked, setCheckAllChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState([]);
  const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
  const [checkedValueid, setCheckedValueid] = useState([]);
  const [checkedValueStatus, setCheckedValueStatus] = useState([]);
  const [allCheckedHistory, setAllCheckedHistory] = useState(false);
  const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);



  const [multiValueids, setMultiValueIds] = useState([]);
  const [errorDeleteModal, setErrorDeleteModal] = useState(false);
  const [showDeleteModal, toggleModal] = useState(false)
  const [deleteshowbutton, setDeleteshowbutton] = useState('');
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  // removed default value -- By Dheeraj S
  // const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })
  const [operatorVal, setOperatorValue] = useState()
  const [operatorValOption, setOperatorValueOption] = useState([
    { value: "=", label: "equals" },
    { value: "<", label: "less then" },
    { value: ">", label: "greater then" },
    { value: "<=", label: "less or equal" },
    { value: ">=", label: "greater or equal" }
  ])

  const [showLoadMore, setshowLoadMore] = useState('');

  useEffect(() => {
    getUserPermission(location.pathname);
    setColumnAscending(false);
    setSortColName("Id");
    settotalselectedshow(false);
    setshowspinner(true);
    getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue);
    getColumnVisibilityList();
    getColVisibilityListData();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [])
  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (typeof res.data.DataList !== 'undefined') {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };


  const getAccrualListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

    Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              rowData = res.data.DataList

              setListViewData(rowData)
              setFilteredArray(rowData)

              settotalCountlst(res.data.Totalcount)
              setshowspinner(false);
              LoadMore(rowData.length, res.data.Totalcount)
            }
          }
        }
      }

    });
  }

  const checkAll = (mycheckbox) => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");

    if (mycheckbox.target.checked) {
      settotalselectedshow(true);
      setAllCheckedHistory(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      settotalselectedcount(totalCountlst);
      setCheckAllChecked(true); // Set state to manage Check All checkbox
    } else {
      settotalselectedshow(false);
      setAllCheckedHistory(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalselectedcount(0);
      setCheckAllChecked(false); // Set state to manage Check All checkbox
    }
  };





  const searchHandler = (event) => {
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);
    if (event.target.value != '') {
      setSearchValue(String(event.target.value).toLowerCase())
      getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
    }
    else {

      setSearchValue(null);
      getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
    }
  }

  const handleLoadMore = () => {

    let pagenumber = paging.pageNumber + 1;
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    paging.pageNumber = pagenumber
    setpaging(paging);
    Service.getdataWithPagingByGroupSearch("Accurals/GetAlllst", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let rowData = res.data.DataList;

              if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                const currentlength = listViewData.length;
                setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                LoadMore(currentlength + rowData.length, res.data.Totalcount)

                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                console.log(allCheckBoxes, "21-6");

                if (!checkAllChecked && totalselectedcount != 0) {
                  allCheckBoxes.forEach((each) => {
                    const checkboxId = parseInt(each.id, 10);
                    if (!isNaN(checkboxId)) {
                      if (uncheckedBoxesHistory.includes(checkboxId)) {
                        each.checked = false;
                      } else {
                        // each.checked = true;
                        each.checked = checkedMultiValueid.includes(checkboxId);
                      }
                    }
                  });
                } else {
                  allCheckBoxes.forEach((each) => {
                    each.checked = checkAllChecked;
                  });
                }
              }
            }
          }
        }
      }
    });
  };




  const handleChange = (e, id, name, status) => {
    const isChecked = e.target.checked;
    const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let count = totalselectedcount;

    if (isChecked) {
      setCheckedValue(id);  // check box value - id
      setCheckedValueStatus(status); // check box value - Active status
      setCheckedValueid(name); // check box value - RA-0000
      setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
      count += 1;

      let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
      const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
      if (index !== -1) {
          tempUncheckedBoxesHistory.splice(index, 1);
      }
      setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

    } else {
      setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
      setCheckedValue([]);  // check box value - id
      setCheckedValueStatus([]); // check box value - Active status
      setCheckedValueid([]); // check box value - RA-0000
      setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
      count -= 1;
    }

    settotalselectedcount(count);
    settotalselectedshow(count > 0);

    // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
    if (!isChecked) {
      setCheckAllChecked(false);
    } else if (count === totalCountlst) {
      setCheckAllChecked(true);
    }

    if (checkedBoxes.length == 1) {
      const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
      setCheckedValue(checkedBoxes[0].id)  // check box value - id
      setCheckedValueStatus(Recordstatus) // check box value - Active status
      setCheckedValueid(Recordname) //check box value - RA-0000
    }
  };

  console.log("21-6", uncheckedBoxesHistory)


  const OpenHandler = (id) => {
    if (id == null || id == 0) {
      alert('Please select the Accrual to view')
    }
    else {
      navigate("/accruals/new/" + id + "/fromAccrual");
    }
  }


  const filterInput = (e) => {

    let inputValue = e.target.value
    setFilterValue(inputValue)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }

  const OndropdownChange = (e, id, name) => {
    let isChecked = e.target.checked;
    let allChecked = false;

    let allCheckboxes = document.getElementsByName("filter-option-all");
    let totalChecked = 0;
    allCheckboxes.forEach((checkbox) => {
      if (checkbox.checked)
        totalChecked += 1;
    });

    let tempData = [...dropdownData];
    //If all checkboxes are checked
    if (tempData.length - 1 === totalChecked) {
      tempData[0].ischecked = isChecked;
      allChecked = true;
    }

    setDropdownData(tempData);

    var lastfilter = [...dropdownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);

      if (allChecked) {
        const selectAllObj = {
          "Name": "Select All"
        };
        updateddata.splice(0, 0, selectAllObj);
      }

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      console.log(currentdata, "c-data");

      setDropdownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      // let data1 = filterValue.filter(data => data.Name !== name);

      let data1 = multiFilterValue[filterCol] || [];
      data1 = data1.filter((data) => data.Name !== name);

      if (tempData.length - 1 >= totalChecked) {
        data1 = data1.filter(data => data.Name !== "Select All");
      }

      console.log(data1, "data");

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }
  }

  const OnChangeOperatorValue = (data) => {
    setOperatorValue({ value: data.value, label: data.label });
  }


  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');

  const FilterColumn = (name) => {

    const scrollTop = window.scrollY;
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual + 30)


    setFilterCol(name)
    setshowfilterpopup(true)
    if (name === 'RebateHeaderId') {
      setfiltertext(t('Accruals.AgreementId'))
      var testDiv = document.getElementById("RebateHeaderId");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'CustomerName') {
      setfiltertext(t('Accruals.Customer'))
      var testDiv = document.getElementById("CustomerName");
      setdivleft((testDiv.offsetLeft + 30) + "px");
      DropdownHandler(name)

    }
    if (name === 'RebateProgramLineId') {
      setfiltertext(t('Accruals.RebateProgramId'))
      var testDiv = document.getElementById("RebateProgramLineId");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'AccrualId') {
      setfiltertext(t('Accruals.AccrualId'))
      var testDiv = document.getElementById("AccrualId");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'Currency') {
      setfiltertext(t('Accruals.AccrualAmount'))
      var testDiv = document.getElementById("Currency");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'AccuralStatus') {
      setfiltertext(t('Accruals.AccrualStatus'))
      var testDiv = document.getElementById("AccuralStatus");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'FiscalYearPeriod') {
      setfiltertext(t('Accruals.RebatePeriod'))
      var testDiv = document.getElementById("FiscalYearPeriod");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'LastUserName') {
      setfiltertext(t('ModifiedBy'))
      var testDiv = document.getElementById("LastUserName");
      setdivleft((testDiv.offsetLeft + 30) + "px");
      DropdownHandler(name);
    }
    if (name === 'LastModifiedDate') {
      setfiltertext(t('ModifiedDate'))
      var testDiv = document.getElementById("LastModifiedDate");
      setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
    }
    if (name === 'CreateUserName') {
      setfiltertext("Created By")
      var testDiv = document.getElementById("CreateUserName");
      setdivleft(((testDiv.offsetLeft + 5) - 150) + "px");
      DropdownHandler(name)
    }
    if (name === 'CreatedDate') {
      setfiltertext("Created Date")
      var testDiv = document.getElementById("CreatedDate");
      setdivleft(((testDiv.offsetLeft + 10) - 150) + "px");
    }
    if (name === 'version') {
      setfiltertext("Version")
      var testDiv = document.getElementById("version");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }

    setshowfilterpopup(true)
  }


  const DropdownHandler = (key) => {
    Service.getfilterDropdownByGroup('Accurals/GetAccrualsFilterValuelst', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              addnewfield(res.data, key)
            }
          }
        }
      }

    });
  }

  const UserfilterHandler = (e) => {

    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData(filterdropdownData)
    }
  }

  const addSeletAll = (optionsArray, alreadySelected) => {

    let result = optionsArray;
    let selectAllObj = {
      "Name": "Select All",
      "Value": "Select All",
      "ischecked": alreadySelected
    }

    result.splice(0, 0, selectAllObj);
    console.log(result, "9999")
    return result;
  }

  const addnewfield = (data, key) => {

    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

    console.log(currentdata, "test-data");
    let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

    if (key != 'active') {
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }

          })
        })
      }


      let result = addSeletAll(currentdata, alreadySelected);
      setDropdownData(result);
      setFilterDropdownData(result)
    }
    else {
      let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }

  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {
      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      dropdownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue([])
    }
  }



  const filterHandler = (flag) => {
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
          if (key === 'Currency') {
            list.push({
              filterKey: key,
              FilterValue: filterValueDict[key],
              operatorValue: operatorVal.value
            })
          }
          else {
            list.push({
              filterKey: key,
              FilterValue: filterValueDict[key]
            })
          }
        }
      }
      if (list.length > 0) {
        let pagesize = 20;
        let pagenumber = 1;
        paging.pageSize = pagesize;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (list.length > 0) {
          setpaging(paging);
          getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
        }

      }

      let tempDropdownData = dropdownData;
      tempDropdownData[0]["ischecked"] = true;
      setDropdownData(tempDropdownData);
    }
    else {
      setshowfilterpopup(false)
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      setpaging(paging);
      getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
      setFilterCol('')
      setFilterValue('')
      setDropdownData([])
      setFilterDropdownData([])
      setFilterSearch("")

      if (filterCol === 'Currency') {
        setOperatorValue({ value: "=", label: "equals" });
      }
    }
  }


  const OpenAccrual = (event, id) => {
    if (event.detail === 2) {

      navigate("/accruals/new/" + id + "/fromAccrual");
    }
  }



  const SortColumn = (colName) => {
    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);

    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);
    getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
  }

  const filterLst = () => {
    let list = [];

    for (var key in multiFilterValue) {
      if (multiFilterValue[key].length > 0) {
        let values = ""
        multiFilterValue[key].map((i) => {
          values += i.Name + ";";
        })
        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }
    for (var key in filterValueDict) {
      if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
        if (key === 'Currency') {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key],
            operatorValue: operatorVal.value
          })
        }
        else {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }
    }
    return list;
  }

  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    }
    else {
      setshowLoadMore(false);
    }

  }
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };




  const ExporttoCSV = () => {
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let values = "";
    if (checkedBoxes.length >= 1) {
      for (let checkbox of checkedBoxes) {
        values = values === "" ? checkbox.id : values + ";" + checkbox.id;
      }
    }

    let objFilter = [
      {
        filterKey: "id",
        FilterValue: values,
      },
    ];

    console.log(objFilter, "obj");
    //alert(checkAllChecked);


    Service.getdataWithPagingByGroupSearch("Accurals/GetAlllst", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
      console.log(res, 'api-resp');
      if (res !== undefined && res.data !== null && res.data !== undefined) {
        let csvLst = res.data.DataList;

        let selectedIds = values.split(';');

        let operatedList = csvLst
        //If checkall box is not checked
        if(!checkAllChecked){
          //If it has not been checked in history/past
          if(!allCheckedHistory)
              operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
          //If it has been checked in history/past
          else
          operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
        }
        operatedList = operatedList.map(({ Currency, ...rest }) => rest);
        let filteredCsvList = operatedList.map(item => {
          let newItem = {};
          Object.keys(item).forEach(key => {
            if (!hideColumn.includes(key)) {
              if (key === 'LastModifiedDate') {
                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
              } else if (key === 'CreatedDate') {
                newItem[key] = moment(item[key]).format(dateFormat);
              } else {
                newItem[key] = item[key];
              }
            }
          });
          return newItem;
        });

        
        
        //let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));
        let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key) && header.key !== 'Currency');

        console.log(filteredCsvList, "filtered");

        setCsvList(filteredCsvList);
        setHeaders(filteredHeaders);

        setTimeout(() => {
          toast.success('Exported Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
          csvInstance.current.link.click();
        });
      }
    });
  }




  // delete modal handler
  const showDeleteDialog = (flag) => {
    if (flag === 'open') {
      toggleModal(true);
    }
    if (flag === 'close') {
      toggleModal(false);
    }
    if (flag === 'proceed') {
      // Handle deletion logic here
      handleDelete(multiValueids);


      // Update state after deletion is confirmed
      let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
      for (let checkbox of checkedBoxes) {
        checkbox.checked = false;
      }

      // Clear the multi-value ids and reset the total selected count and visibility
      setMultiValueIds([]);
      setCheckedMultiValueid([]);
      settotalselectedcount(0);
      settotalselectedshow(false);
      setDeleteshowbutton(false);
      toggleModal(false);
    }
  };



  const handleDelete = (id) => {
    setLoading(true)
    let pagesize = paging.pageSize * paging.pageNumber;
    let pagenumber = 1;
    Service.deleteDataByPolicyID('Accurals/DeleteAccrualById', id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          setLoading(false)
          toast.error('Deleted Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });

          showDeleteDialog('close')
          getAccrualListViewData("Accurals/GetAlllst", pagesize, pagenumber, "Descending", "Id", filterLst(), null);
          setMultiValueIds([])
        }
      }
    });
  }


  const checkDelete = () => {
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    if (checkedBoxes.length == 1) {
      if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
        setErrorDeleteModal(true)
      }
      else {

        setMultiValueIds(checkedValue)
        showDeleteDialog('open')
      }
    }
    if (checkedBoxes.length > 1) {
      setCheckedMultiValueid([])
      for (let checkbox of checkedBoxes) {
        checkedMultiValueid.push(checkbox.id)
      }
      const matchingObjects = filteredArray.filter(obj => checkedMultiValueid.includes(obj.Id.toString()));
      let hasApprovedInReviewOrDenied = false;

      matchingObjects.forEach(i => {
        if (i.AccuralStatus?.includes('Approved') || i.AccuralStatus?.includes('InReview') || i.AccuralStatus?.includes('Denied')) {
          hasApprovedInReviewOrDenied = true;
        } else {
          var idAsString = i.Id.toString();
          multiValueids.push(idAsString);
          showDeleteDialog('open');
        }
      });

      if (hasApprovedInReviewOrDenied) {
        setErrorDeleteModal(true);
      }
    }
  }

  // -------------------------Column Manager---------------

  const columnList = [
    { "label": "Accruals.AgreementId", "key": "RebateHeaderId", "value": true },
    { "label": "Accruals.Customer", "key": "CustomerName", "value": true },
    { "label": "Accruals.RebateProgramId", "key": "RebateProgramLineId", "value": true },
    { "label": "Accruals.AccrualId", "key": "AccrualId", "value": true },
    { "label": "Accruals.AccrualAmount", "key": "Currency", "value": true },
    { "label": "Accruals.AccrualStatus", "key": "AccuralStatus", "value": true },
    { "label": "Accruals.RebatePeriod", "key": "FiscalYearPeriod", "value": true },
    { "label": "ModifiedBy", "key": "LastUserName", "value": true },
    { "label": "ModifiedDate", "key": "LastModifiedDate", "value": true },
    { "label": "CreatedBy", "key": "CreateUserName", "value": true },
    { "label": "CreatedDate", "key": "CreatedDate", "value": true }
  ]
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);


  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }


  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 30)
    setdivleft1((divtop1.offsetLeft) + 100 + "px");
  }


  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 45)
    setdivleft((divtop1.offsetLeft) + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }

    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }

  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }
  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'accrualList', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }

  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'accrualList', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'accrualList', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }
              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }

    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'accrualList', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              console.log(jsonData, "json");
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }

  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)
        toast.error('Deleted Successfully !', {
          position: toast.POSITION.TOP_RIGHT,
        });

      }
    })
  }

  const CopyHandler = (id) => {
    setLoading(true)
    let rowData = {}
    let pagesize = 20 * paging.pageNumber
    let pagenumber = 1
    Service.PostWithIdAndUser('Accruals/Copy', id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          // copyID = res.data
          paging.pageSize = 20
          paging.pageNumber = pagenumber
          setpaging(paging);
          getAccrualListViewData("accrualList", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
          navigate('/accruals')
          setLoading(false)
          toast.success('Copied Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      else
        setLoading(false);
    });
  }

  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm:ss A";

  // ---------------------------------------

  return (
    <div className="container-fluid content-top-gap">


      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">
          <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('Accruals.AccrualList')}</li>
        </ol>
      </nav>

      <div className="">
        <div className="row">
          <div className="col-lg-12 pr-lg-2">
            <div className="card text-center card_border overflow-auto ">

              <div className="card-body" style={{ minHeight: "70vh", width: "100%" }}>
                {/* <div className="row ml-1" style={{ position: "sticky" }}>

                  <div className="col-md-8">
                    <div className="row">
                      <input type="text" className="form-control form-control-user col-md-10" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('Accruals.AgreementId')} , ${t('Accruals.Customer')} ,${t('Accruals.RebateProgramId')} ,${t('Accruals.AccrualId')} ,${t('LastModifiedBy')} `} />
                      <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                        onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {showbutton === 'show' && (
                      <>
                        <a
                          className="btn btn-update btn-sm ml-2"
                          style={{ backgroundColor: "buttonface", float: "right", width: "40px" }}
                          onClick={() => OpenHandler(checkedMultiValueid)}
                        >
                          {t('Open')}
                        </a>
                        <button
                          className="btn btn-update btn-sm ml-2"
                          type="submit"
                          style={{ float: "right", width: "50px" }}
                          onClick={(event) => checkDelete()}
                        >
                          {t('Delete')}
                        </button>
                      </>
                    )}
                    {deleteshowbutton === 'show' && (
                      <button
                        className="btn btn-update btn-sm ml-2"
                        style={{ float: "right", width: "50px" }}
                        type="submit"
                        onClick={(event) => checkDelete()}
                      >
                        {t('Delete')}
                      </button>
                    )}
                    {totalselectedcount > 0 && (
                      <button
                        className="btn btn-update btn-sm ml-2"
                        type="submit"
                        style={{ float: "right", width: "50px" }}
                        onClick={(event) => ExporttoCSV()}
                      >
                        {t('Export')}
                      </button>
                    )}
                  </div>
                </div> */}

                <div className="row ml-1">
                  <div className="col-md-6">
                    <div className='d-flex align-items-center mb-1' style={{marginLeft:"-20px"}}>
                      <input type="text" className="form-control form-control-user col-md-11" id="firstName" name="firstName" autoComplete='off' placeholder={t('Accruals.SearchHeader')} onChange={(event) => searchHandler(event)} />
                      <button id="addRemoveCol" icon className="btn btn-update ml-2  pa-0" type="submit"
                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                        onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='row'>
                      <div className="col-md-12 d-flex justify-content-end align-items-center">
                        <div style={{ display: 'inline-block', marginRight: '10px' }}>
                          {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                            <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                          )}
                          {totalselectedcount === 1 && (
                            <>
                              <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</a>
                              {/* {Service.editPerms(userPerms) !== false && (
                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>
                              )} */}
                            </>
                          )}
                          {totalselectedcount > 0 && (
                            <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px", backgroundColor: "buttonface" }} onClick={() => checkDelete()}>{t('Delete')}</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ display: "none" }}>
                  <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="accruals.csv" > </CSVLink>
                </div>
                <div className="scrollbar-class">
                  <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                    <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                      <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>

                        <th className="tableheader" style={{ width: '30px' }}>
                          <input
                            type="checkbox"
                            onChange={checkAll}
                            checked={checkAllChecked}
                            id="option-all"
                          />
                        </th>

                        <th id="RebateHeaderId" style={{ backgroundColor: (filterValueDict.RebateHeaderId ? '#6cae55' : ''), maxWidth: "160px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('RebateHeaderId') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.AgreementId')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateHeaderId')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateHeaderId')}></span>
                          </div>
                        </th>
                        <th id="CustomerName" style={{ backgroundColor: (multiFilterValue.CustomerName ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('CustomerName') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.Customer')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CustomerName')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CustomerName')}></span>
                          </div>
                        </th>
                        <th id="RebateProgramLineId" style={{ backgroundColor: (filterValueDict.RebateProgramLineId ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('RebateProgramLineId') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.RebateProgramId')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineId')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineId')}></span>
                          </div>
                        </th>
                        <th id="AccrualId" style={{ backgroundColor: (filterValueDict.AccrualId ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('AccrualId') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.AccrualId')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualId')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualId')}></span>
                          </div>
                        </th>

                        <th id="Currency" style={{ backgroundColor: (filterValueDict.Currency ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('Currency') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.AccrualAmount')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Currency')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Currency')}></span>
                          </div>
                        </th>
                        <th id="AccuralStatus" style={{ backgroundColor: (filterValueDict.AccuralStatus ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('AccuralStatus') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.AccrualStatus')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccuralStatus')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccuralStatus')}></span>
                          </div>
                        </th>
                        <th id="FiscalYearPeriod" style={{ backgroundColor: (filterValueDict.FiscalYearPeriod ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('FiscalYearPeriod') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.RebatePeriod')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('FiscalYearPeriod')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('FiscalYearPeriod')}></span>
                          </div>
                        </th>
                        <th id="LastUserName" style={{ backgroundColor: (multiFilterValue.LastUserName ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('LastUserName') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span> {t('ModifiedBy')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastUserName')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastUserName')}></span>
                          </div>
                        </th>
                        <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: "180px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('LastModifiedDate') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('ModifiedDate')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                          </div>
                        </th>
                        <th id="CreateUserName" style={{ backgroundColor: (multiFilterValue.CreateUserName ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('CreateUserName') ? true : false}>
                          <div className="d-flex text-nowrap">
                            <span>{t('CreatedBy')} </span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreateUserName')}></span>
                            <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreateUserName')}></span>
                          </div>
                        </th>
                        <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                          hidden={hideColumn.includes('CreatedDate') ? true : false}>
                          <div className="d-flex text-nowrap">
                            <span>{t('CreatedDate')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                            <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        showspinner === true ?

                          <>
                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                          </>
                          :
                          filteredArray.length === 0 ?
                            <tr><td></td>
                              <td colSpan="9" style={{ textAlign: 'center' }}>
                                {t('NoDataAvailable')}
                              </td>
                            </tr>
                            :
                            filteredArray.map((listVal, index) =>
                              <tr key={listVal.Rebate_id} onDoubleClick={(event) => OpenAccrual(event, listVal.Id)}>
                                <td><input id={listVal.Id} type="checkbox" name="chkBox" value={`${listVal.AccrualId},${listVal.AccuralStatus}`} onChange={(event) => handleChange(event, listVal.Id, listVal.AccuralStatus, listVal.AccrualId)} /></td>


                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('RebateHeaderId') ? true : false}>{listVal.RebateHeaderId}</td>

                                <td style={{ width: "145px" }} hidden={hideColumn.includes('CustomerName') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "15px" }} data-toggle="tooltip" data-placement="right" title={listVal.CustomerName}>{listVal.CustomerName}</div></td>
                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('RebateProgramLineId') ? true : false}>{listVal.RebateProgramLineId}</td>
                                <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('AccrualId') ? true : false}>{listVal.AccrualId}</td>
                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('Currency') ? true : false}>{listVal.Currency} {Service.currencyFormat(parseFloat(listVal.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision()))}</td>
                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('AccuralStatus') ? true : false}>{listVal.AccuralStatus}</td>
                                <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('FiscalYearPeriod') ? true : false}>{listVal.FiscalYearPeriod}</td>
                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('LastUserName') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "15px",maxWidth:"145px" }} data-toggle="tooltip" data-placement="right" title={listVal.LastUserName}>{listVal.LastUserName}</div></td>
                                <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('LastModifiedDate') ? true : false}>{moment(listVal.LastModifiedDate).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                <td hidden={hideColumn.includes('CreateUserName') ? true : false}>{listVal.CreateUserName}</td>
                                <td hidden={hideColumn.includes('CreatedDate') ? true : false}>{moment(listVal.CreatedDate).format(SessionManage.extractDateFromTimestamp(listVal.CreatedDate))}</td>
                              </tr>
                            )

                      }

                    </tbody>
                  </table>
                </div>
                
                <div className='row mt-1'>

                  {
                    totalselectedshow === true ?

                      <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                        {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                      </div>

                      :
                      <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                      </div>
                  }



                  <div style={{flex: `0 0 ${totalCountlst > 14 ? "62.5%" : "63%"}`,maxWidth: totalCountlst > 14 ? "62.5%" : "63%"}}></div>
                  <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                    {t('TotalRecords')} - {totalCountlst}
                  </div>
                </div>
              </div>
              {
                  showLoadMore === true ?
                    <div className='text-center container row mb-1'>
                      <div className='col-md-5'></div>
                      <div className='col-md-2'>
                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "4.375rem" }}>{t('LoadMore')}</button>
                      </div>
                      <div className='col-md-5'></div>

                    </div>
                    :
                    ""
                }

              <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                  <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                    Cancel
                  </Button>
                  <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={errorDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                  Accrual in New status only can be deleted
                </Modal.Body>
                <Modal.Footer>
                  <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>


              {showfilterpopup === true ?
                <div className='pop-up' style={{ left: divleft, top: divtop, width: "220px" }}>
                  {filterCol === 'RebateHeaderId' || filterCol === 'RebateProgramLineId' || filterCol === 'AccrualId' || filterCol === 'AccuralStatus' || filterCol === 'FiscalYearPeriod' || filterCol === 'version' ?

                    <>
                      <div className="container">
                        <div className="row">
                          <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                        </div>
                      </div>
                      <div className="container mt-2">
                        <div className="row">
                          <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  {filterCol === 'CreatedDate' || filterCol === 'LastModifiedDate' ?
                    <>
                      <div className="container">
                        <div className="row">
                          <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                        </div>
                      </div>
                      <div className="container mt-2">
                        <div className="row">
                          <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  {filterCol === 'CustomerName' || filterCol === 'CreateUserName' || filterCol === 'LastUserName' ?
                    <>
                      <div className="container">
                        <div className="row">
                          <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                        </div>

                      </div>

                      <div className="container mt-2">
                        <div className="row">
                          <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                        </div>

                        <div className="row contentoverflow mt-2">
                          <table className="filtertable">
                            <tbody>
                              {/* <tr>
                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                <td>Select All</td>
                              </tr> */}
                              {
                                dropdownData.map((item, index) => (

                                  <tr key={index}>

                                    <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                    <td className="hovr" style={{ overflow: "hidden", display: "block", maxWidth: "183px" }}>{item.Name}</td>

                                  </tr>

                                ))
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  {filterCol === 'Currency' ?
                    <div>
                      <div className="row">
                        <div className="row" style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                          <b>{filtertext}</b>
                        </div>
                        <Select
                          className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                          options={operatorValOption}
                          placeholder="Select"
                          value={operatorVal}
                          onChange={OnChangeOperatorValue}
                          // added menu list -- AccuralsListComponent.js -- By Dheeraj S
                          //styles={customStyles}
                          styles={{
                            ...customStyles,
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                      </div>
                      <div className="mt-2">

                        {/* added style here -- By Dheeraj S */}
                        <input type='text' className='col-md-10 filtertextboxbackground ' style={{ "margin-left": "12px", "padding-left": "1px" }} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                          value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                      </div>
                    </div>
                    :
                    <></>
                  }
                  <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                    <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                    <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                  </div>
                  <div>
                  </div>
                </div>
                :
                <></>
              }
              {showAddColumnPopup === true ?
                <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                  <div className="container">
                    <div className="row">
                      <Select
                        className='col-md-10 textbox-padding fontsizedropdown'
                        options={colVisibilityOption}
                        placeholder="Select"
                        value={colvisiOptionVal}
                        onChange={OnChangeColVisibilityList}
                        styles={customStyles}
                      />
                      <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                        onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                    </div>
                    <div className="row mb-2" style={{ textAlign: 'right' }}>
                      <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                        <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                          onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                      </div>
                    </div>
                    <div className="row">
                      <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                        {
                          addRemoveCol.map((item, index) => (
                            <tr>
                              <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                              /> {t(item.label)}</td>
                            </tr>
                          ))
                        }
                      </table>
                    </div>
                    <div className="row mr-2" style={{ float: "right" }}>
                      <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                        <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                        <button className="btn btn-sm btn-update ml-1" style={{ width: "55px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                        <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>
              }


              {showCreateColumnListPopup === true ?
                <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 ml-2" style={{ padding: '0px' }}>
                        <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                          value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                      </div>
                    </div>
                    <div className="row mb-2" style={{ textAlign: 'right' }}>
                      <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                        <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                          onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                      </div>
                    </div>
                    <div className="row">
                      <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                        {
                          createColumnListOption.map((item, index) => (
                            <tr>
                              <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                              /> {t(item.label)}</td>
                            </tr>
                          ))
                        }
                      </table>
                    </div>
                    <div className="row mr-2" style={{ float: "right" }}>
                      <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                        <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                        <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                      </div>
                    </div>
                  </div>


                </div>
                : <></>
              }
            </div>
          </div>
          <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
          <ToastContainer autoClose={1000} />
        </div>
      </div>



    </div>
  )
}
export default AccuralsListComponent;
