import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewReports from '../../../src/Components/DataAdministration/Reports/CreateReports';

function CreateReports(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewReports {...props}  navigate={navigate} id={params.id} name={params.name}/>
         </div>
     );  

}
export default CreateReports