import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Service from '../../../Services/Service';
import SessionManage from "../../../SessionManagement/SessionManage";

import { useTranslation } from "react-i18next";
function Navbar() {
    const [t, i18n] = useTranslation('translation');


    const [filteredArray, setFilteredArray] = useState([]);
    useEffect(() => {
        // var roleid = sessionStorage.getItem("roleid");
        var roleid = SessionManage.getroleid() || 0;
        var navData = SessionManage.getNavBar();
        // var navData = sessionStorage.getItem("navData");

        if (navData === null) {
            getData("Permission/GetAllMenu", roleid);

        } else {
            var itm = JSON.parse(navData);
            //  getData("Permission/GetAllMenu", roleid);
            setFilteredArray(itm);
        }

    }, [0])

    const getData = (method, id) => {
        Service.GetAllMenu(method, id).then((res) => {

            if (typeof res !== undefined) {
                if (res.data !== 'null' && typeof res.data !== undefined) {

                    setFilteredArray(res.data.DataList);
                    // sessionStorage.setItem('navData', JSON.stringify(res.data.DataList));
                    SessionManage.setNavBar(JSON.stringify(res.data.DataList))
                }
            }

        });
    }
 

    return (
        <>
            
           
            <div className="sidebar-menu sticky-sidebar-menu">
               
                 <a className="toggle-btn">
                        <i className="fa fa-bars menu-collapsed__left"></i>
                        <i className="fa fa-angle-double-left menu-collapsed__right"></i>
                    </a>
                <div className="sidebar-menu-inner">
                   
                    <div class="navigation">
                        <ul>
                            {filteredArray.map((root) => (
                                <>
                                    <li class="has-sub">
                                        <Link to={root.Url}>
                                            <img style={{ maxWidth : "100%"}} src={"/NewAssets/images/" + root.Icon} alt="logo-icon" /><h6 style={{ fontSize: "9px" , width:"55px" , textAlign:"center" , marginLeft:"-4px" , marginTop:"3px" }}>
                                            {t('Menu.'+root.StyleName)} 
                                              
                                                </h6></Link>
                                        <ul id={(root.StyleName).replace(" ","_")} style={(filteredArray.length <= 3 && root.StyleName === 'Approvals') ? {top: "1%"}:{}}>
                                            {root.SubMenuList.map((submenu) => (                                                        <>
                                                    <li className={submenu.IsSubmenu === true ? "has-sub" : ""}>
                                                        {submenu.SubMenuList.length!== 0 ? <Link to={submenu.Url} style={{width:"280px"}}>   {t('Menu.'+submenu.Name)}    <i class="fa fa-chevron-right" style={{marginLeft:"5px"}}></i></Link> : <Link to={submenu.Url}>
                                                        {t('Menu.'+submenu.Name)}   
                                                        </Link>}
                                                        <ul style={{left:'196%'}}>
                                                            {submenu.SubMenuList.map((lastmenu) => (
                                                                <>
                                                                    <li>
                                                                        <Link to={lastmenu.Url} style={{width:"260px"}}>
                                                                        {t('Menu.'+lastmenu.Name)}   
                                                                            </Link>
                                                                    </li>
                                                                </>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                </>
                                            ))}
                                                {/* </ul>
                                            </li> */}
                                        </ul>
                                    </li>
                                </>
                            ))}
                        </ul>

                    </div>
                    {/* <a className="toggle-btn">
                        <i className="fa fa-angle-double-left menu-collapsed__left"><span>Collapse Sidebar</span></i>
                        <i className="fa fa-angle-double-right menu-collapsed__right"></i>
                    </a> */}
                </div>
            </div>
        </>)
}
export default Navbar;