import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';
import { NumericFormat } from "react-number-format";



function NewReports(props) {
  const navigate = useNavigate();
  let loggedInUser = SessionManage.getuserid();
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);

  const [dateRange, setDateRange] = useState([]);
  const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();


  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })

  {
    useEffect(() => {
      getUserPermission("/Annual-Policy/List")
      getFiscalYear("Reports")
      getCustomerData("Reports/GetActiveCustomers")
    }, [props.id])
  }

  // const validateField = () => {
  //   let isValid = true;
  //   let startDate = new Date(exceptionPolicyData.StartDate)
  //   let endDate = new Date(exceptionPolicyData.EndDate)

  //   if (selectedOptionsDateRange == "" || selectedOptionsDateRange == null) {
  //     setErrorMessage("Please select Fiscal Year in the header")
  //     isValid = false;
  //   }
  //   else if (moment(startDate).isBefore(exceptionPolicyData.StartDate)) {
  //     //setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
  //     setErrorMessage("The Start Date Should Be Lies Between Agreement Validity")
  //     isValid = false;
  //   }
  //   else if (moment(endDate).isAfter(exceptionPolicyData.EndDate)) {
  //     // setErrorMessage(t('Rebates.Validations.TheProgramValidityShouldBeLiesBetweenAgreementValidity'))
  //     setErrorMessage("The Start Date Should Be Lies Between Agreement Validity")
  //     isValid = false;
  //   }
  //   else if (exceptionPolicyData.RebateTypeId == "" || exceptionPolicyData.RebateTypeId == null) {
  //     setErrorMessage("Please select Rebate Type in the header")
  //     isValid = false;
  //   }
  //   else if (exceptionPolicyData.RebatePayoutCondId == "" || exceptionPolicyData.RebatePayoutCondId == null) {
  //     setErrorMessage("Please select Payout Condition in the header")
  //     isValid = false;
  //   }
  //   else if (ExceptionValueList.some(item => item.Operator === "" || item.Operator === null || item.Operator === "Select")) {
  //     setErrorMessage("Please Select Operator For All Rows")
  //     isValid = false;
  //   }
  //   else if (exceptionPolicyData.Allowedmissedperiods === "" || exceptionPolicyData.Allowedmissedperiods == null ||
  //     selectedOptionsSetlFreq === "" || selectedOptionsSetlFreq == null || selectedOptionsSetlFreq === "Select") {
  //     setErrorMessage("Please Enter The Value Of Allowed Periods For Missed Targets");
  //     isValid = false;
  //   } else if (VolumeRebateList[0].Targetcondition === '' || VolumeRebateList[0].Targetcondition === null) {
  //     setErrorMessage("Please Select Target Condition")
  //     isValid = false
  //   }
  //   else if (VolumeRebateList[0].Measure_Value === '' || VolumeRebateList[0].Measure_Value === null) {
  //     setErrorMessage("Please Enter Measure Value")
  //     isValid = false
  //   } else if (VolumeRebateList[0].Rebate === '' || VolumeRebateList[0].Rebate === null) {
  //     setErrorMessage("Please Select Rebate ₹/%/LS")
  //     isValid = false
  //   } else if (VolumeRebateList[0].Rebate_Value === '' || VolumeRebateList[0].Rebate_Value === null) {
  //     setErrorMessage("Please Enter Rebate Value For All Rows")
  //     isValid = false
  //   } else if (VolumeRebateList[0].Target_Measure === '' || VolumeRebateList[0].Target_Measure === null) {
  //     setErrorMessage("Please Select Target Measure")
  //     isValid = false
  //   }
  //   // else if (ExceptionValueList.some(item => item.SelectedItemSubCategory === "" || item.SelectedItemSubCategory === null || item.SelectedItemSubCategory === "Select")) {
  //   //   setErrorMessage("Please select Sub-Category for all rows")
  //   //   isValid = false;

  //   // }
  //   // else if (ExceptionValueList.some(item => (item.ValueListString.length === 0 && item.SelectedItemSubCategory != 'ECC' && item.SelectedItemSubCategory != 'SubCategory' && item.SelectedItemSubCategory != 'Category' && item.SelectedItemSubCategory != 'Vehicletype' ))) {
  //   //   setErrorMessage("Please Select Values For All Rows")
  //   //   isValid = false;
  //   // }
  //   //   else if (ExceptionValueList.some(item => item.ValueListString.length === 0 && !['ECC', 'SubCategory', 'Category','Vehicletype'].includes(item.SelectedItemSubCategory))) {
  //   //     setErrorMessage("Please Select Values For All Rows");
  //   //     isValid = false;
  //   // }
  //   // else if (VolumeRebateList.some(item => item.Rebate === "" || item.Rebate === null)) {
  //   //   setErrorMessage("Please Enter Rebate Value For All Rows")
  //   //   isValid = false;
  //   // }

  //   if (isValid) {
  //     ExceptionValueList.forEach((item, index) => {
  //       if (item.Category !== '' && item.Category !== null) {
  //         if (item.SelectedItemSubCategory === "" || item.SelectedItemSubCategory === null || item.SelectedItemSubCategory === "Select") {
  //           // setErrorMessage(`Please select Sub-Category for row ${index + 1}`);
  //           setErrorMessage("Please select Sub-Category for all rows")
  //           isValid = false;
  //         } else if (item.ValueListString.length === 0 && !['ECC', 'SubCategory', 'Category', 'Vehicletype'].includes(item.SelectedItemSubCategory)) {
  //           setErrorMessage("Please Select Values for All Rows");
  //           isValid = false;
  //         }
  //       }
  //     });
  //   }

  //   return isValid
  // }

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            console.log(res.data.DataList, "peram")
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }

  const regionStyles = {
    chips: {
      fontSize: "12px",
      lineHeight: "13px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    }

  }

  const CustomerStyles = {

    searchBox: {
      height: "80px",
      overflowY: "scroll"
    },

    chips: {
      fontSize: "12px",
      lineHeight: "13px",
      display: "block",
      marginTop: "5px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    },

  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    }),
  };


  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [sticky, setSticky] = useState("");

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };

  const class_thead = `text-white rt-thead  ${sticky}`;

  let rowData = [];
  const [fiscalYearData, setFiscalYearData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [loadData, setLoadData] = useState("");
  const [listViewData, setListViewData] = useState(rowData)
  const [filePath, setFilePath] = useState('');
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showspinner, setshowspinner] = useState('');
  const [filteredArray, setFilteredArray] = useState(rowData)
  const [showLoadMore, setshowLoadMore] = useState('');
  const [totalCountlst, settotalCountlst] = useState('');
  const [userReportData, setuserReportData] = useState({
    "Users": [],
    "Roles": [],
    "Permissions": [],
    "Modules": []
  });
  const [reportsData, setReportsData] = useState({
    "rebate_period": "",
    "fiscalYear": "",
    "customergroupname": [],
    "customer_Name": [],
    "report_type": "",
    "user_name": [],
    "roles": [],
    "permissions": [],
    "modules": [],
  });
  const [invoicePeriodValue, setInvoicePeriodValue] = useState([
    { "id": 0, "Name": "Q1", "label": "Q1" },
    { "id": 1, "Name": "Q2", "label": "Q2" },
    { "id": 2, "Name": "Q3", "label": "Q3" },
    { "id": 3, "Name": "Q4", "label": "Q4" },
    { "id": 4, "Name": "H1", "label": "H1" },
    { "id": 5, "Name": "H2", "label": "H2" },
    { "id": 6, "Name": "FY", "label": "FY" },
  ]);

  const getReportsListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

    Service.getAllReportsdataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              rowData = res.data;
              setListViewData(rowData)
              console.log(rowData, "rowdata");
              settotalCountlst(res.data.Totalcount)
              setFilteredArray(rowData)
              setshowspinner(false)
              setdata(rowData)
              LoadMore(rowData.length, res.data.Totalcount)
            }
          }
        }
      }

    })
  }

  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }

  const getFiscalYear = (method) => {

    Service.getFiscalYear(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setFiscalYearData(res.data)

            }
          }
        }
      }

    });
  }

  const OnPopupPeriodChange = (e) => {
    let selectedOption = (e);

    let data = String(selectedOption[0].Name)

    setReportsData({ ...reportsData, ['rebate_period']: data })
  }


  const OnPopupFiscalYearChange = (e) => {
    let selectedOption = (e);

    let data = String(selectedOption[0].value)

    setReportsData({ ...reportsData, ['fiscalYear']: data })
  }



  const OnPopupCustomerChange = (e) => {
    let selectedOption = (e);
    let data = []
    for (let i = 0; i < selectedOption.length; i++) {

      data.push(selectedOption[i].Name)
    }

    setReportsData({ ...reportsData, ['customer_Name']: data, ['report_type']: 'Report By Customer' })

  }

  const getCustomerData = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setCustomerData(res.data)

            }
          }
        }
      }

    });
  }

  const ConvertToPDF = (e) => {
    // setShowInvoiceModal(false)
    setLoading(true);
    let data = reportsData

    if (loadData !== 'User Entitlement') {
      if (reportsData.report_type === "Report By Customer") {
        if (reportsData.customer_Name.length === 0) {

          let data = []
          for (let i = 0; i < customerData.length; i++) {

            data.push(customerData[i].Name)
          }

          reportsData.customer_Name = data;

        }
      }
      // else if (reportsData.report_type === "Report By Customer Group") {
      //   if (reportsData.customergroupname.length === 0) {
      //     let data = []
      //     for (let i = 0; i < groupCustomerData.length; i++) {

      //       data.push(groupCustomerData[i].Name)
      //     }

      //     reportsData.customergroupname = data;

      //   }
      // }
      // else if (reportsData.report_type === "Customer Invoice Payment Details Report" || reportsData.report_type === "Report By Customer/CustomerGroup") {
      //   if (reportsData.customer_Name.length === 0) {
      //     let data1 = []
      //     let data2 = []
      //     for (let i = 0; i < customerData.length; i++) {

      //       data1.push(customerData[i].Name)
      //     }
      //     for (let i = 0; i < groupCustomerData.length; i++) {

      //       data2.push(groupCustomerData[i].Name)
      //     }

      //     reportsData.customer_Name = data1;
      //     reportsData.customergroupname = data2;
      //   }
      // }
      Service.getAllReportData("Reports", data).then(res => {

        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {

            debugger
            var path = res.data.Path
            setLoading(false);

            window.location = Service.getBaseUrl() + path;
            setFilePath(path);
            getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);

            setReportsData({ ...reportsData, ['rebate_period']: "", ['customergroupname']: [], ['customer_Name']: [], ['report_type']: "", ['fiscalYear']: "" })
          }
        }
      });
    }
    else {
      Service.getAllUserReportData("Reports", userReportData).then(res => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            console.log(res.data)
            debugger
            var path = res.data
            setLoading(false);
            window.location = Service.getBaseUrl() + path;
            setFilePath(path);
            getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
          }
        }
      });
    }
  }


  //------------------------------------------------------------ 

  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t('Home.title')}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to="/reports">{t('Reports.ReportsList')}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to="/reports/byCustomer">{t('Create Reports By Customer')}</Link>
            </li>
          </ol>
        </nav>
        {loading && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer autoClose={1000} />
        <div className="row" id="row">
          <div className="col-lg-12">
            <div className="card text-center card_border btn_res">
              <div className="card-header chart-grid__header align-items-center mt-1" style={{ height: "35px" }}>
                <div className="row">
                  <div className="col-lg-7 col-md-6 col-sm-12 text-left">
                    {t('Reports Header')}
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 text-right">
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }}>
                      Generate Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 pr-lg-2 mt-1">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "180px" }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Reports.DateRange')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupFiscalYearChange(Event)}
                            onRemove={(Event) => OnPopupFiscalYearChange(Event)}
                            options={fiscalYearData}
                            displayValue="label"
                            className='textbox-padding fontsizedropdown'
                            style={regionStyles}
                            singleSelect={true}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Rebates.AccrualPeriod')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupPeriodChange(Event)}
                            onRemove={(Event) => OnPopupPeriodChange(Event)}
                            options={invoicePeriodValue}
                            displayValue="Name"
                            className='textbox-padding fontsizedropdown'
                            style={regionStyles}
                            singleSelect={true}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Rebates.Customer')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupCustomerChange(Event)}
                            onRemove={(Event) => OnPopupCustomerChange(Event)}
                            options={customerData}
                            displayValue="label"
                            className='textbox-padding fontsizedropdown'
                            style={CustomerStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-3">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-8 text-left">
                  {t('Reports Definition')}
                </div>
                <div className="col-md-4 text-right">
                  <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }} onClick={(e) => ConvertToPDF()}>Convert to PDF</button>
                  <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }}>Convert to CSV</button>
                </div>
              </div>
            </div>
            <div>
              <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA" >
                <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                  <tr>

                    <th id="name" style={{ backgroundColor: '#4755ab' }}>
                      {t('Reports.Name')}
                    </th>
                    <th id="label" style={{ backgroundColor: '#4755ab', minWidth: '120px' }}>
                      {t('Reports.PrimaryModule')}
                    </th>
                    <th id="last_run" style={{ backgroundColor: '#4755ab', minWidth: '100px' }}>
                      {t('Reports.LastRun')}
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    showspinner === true ?
                      <tr>
                        <>
                          <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                        </>
                      </tr>

                      :
                      filteredArray.length === 0 ?
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>

                        </tr>
                        :
                        filteredArray.map((listVal, index) =>
                          <tr>

                            <td >{listVal.Name}</td>
                            <td>{listVal.Primarymodule}</td>
                            <td>{moment(listVal.Lastrun).format(SessionManage.getcurrentdateformat())}  {moment(listVal.Lastrun).format('hh:mm:ss')}</td>

                          </tr>
                        )

                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default NewReports