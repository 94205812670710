import React from "react";
import Content from "./Pages/Layout/Content/Content";
import Login from "./Pages/Login/Login";
import SessionManage from "./SessionManagement/SessionManage"
import "./customStyle.css";

let name = SessionManage.getusername();
let correctuserid = SessionManage.getuserid();
console.log("Test", correctuserid);

const App = () => {
  return (
    <>  
    {
      correctuserid === null ? 
      <Login></Login>
      :
      <Content />
    }    

    </>
    // <React.Fragment>
    //   <Switch>
    //     {/* <Route path='/' exact> <Redirect to="/Home" /></Route> */}
    //     <Route path='/home' exact>
    //       <Header />
    //       <Menu />
    //       <Home />
    //       <Footer />
    //     </Route>


    //     <Route path='/' exact >

    //       <Login />

    //     </Route>

    //     <Route path='/rebate-program' exact >
    //       <Header />
    //       <Menu />
    //       <RebateListing />
    //       <Footer />
    //     </Route>

    //     <Route path='/new-rebate' exact >
    //       <Header />
    //       <Menu />
    //       <NewRebate />
    //       <Footer />
    //     </Route>

    //     <Route path='/accruals' exact >
    //       <Header />
    //       <Menu />
    //       <AccrualsListing />
    //       <Footer />
    //     </Route>

    //     <Route path='/new-accruals' exact >
    //       <Header />
    //       <Menu />
    //       <NewAccruals />
    //       <Footer />
    //     </Route>


    //   </Switch>
    // </React.Fragment>
  )
}

export default App;