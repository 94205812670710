import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

function RebateCategoryPoliciesList(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        name: '',
        label: '',
        last_modified_date: null,

    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        last_modified_user: '',
        IsActive: '',
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getRebateCategoryListViewData("RebateCategory", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [props.id])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        Service.getAllPolicydata("PaymentTerms", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]


    let headers = [
        { label: "Name", key: "Name" },
        { label: "Label", key: "label" },
        { label: "Rebate Type", key: "RebateType" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Is Active", key: "ISActive" },

    ];

    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getRebateCategoryListViewData("RebateCategory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getRebateCategoryListViewData("RebateCategory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getAllPolicydataSearch("RebateCategory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = data.length;
                                setdata(data => [...data, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                                LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };


    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, status) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        }
        else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([])  // check box value - id 
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
            count -= 1;

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);




        if (checkedBoxes.length === 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
            setshowbutton(true);
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Policy to view')
        }
        else {
            navigate("/RebateCategoryPolicy/new/" + id);
        }
    }



    const DeleteHandler = (id, status) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        var chk = false
        if (status === false) {
            chk = true
        }
        if (id > 0 && chk === true) {
            Service.deleteDataByPolicyID("RebateCategory/Delete", id, loggedInUser).then(res => {
                if (res.status === 200) {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        alert('Deleted Successfully')
                        getRebateCategoryListViewData("RebateCategory", paging.pageSize, paging.pageNumber, "Ascending", "Id", [], searchValue);
                        getRebateCategoryListViewData("RebateCategory", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                    }
                }
            })
        }
        else {
            setShowErrorModal(true)
            setErrorMessage("Active Policy cannot be deleted")
        }
    }

    const getRebateCategoryListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getAllPolicydataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }




    const TogglePolicyActiveStatus = (id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.TogglePolicyActiveStatus('RebateCategory/ToggleRebateCategoryActiveStatus', toggleId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getRebateCategoryListViewData("RebateCategory", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                    }
                }

            });
            setToggleStatusModal(false)
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('RebateCategory/GetRebateCategoryFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setshowfilterpopup(true)
        setFilterCol(name)


        if (name === 'last_modified_user') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("last_modified_user");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'name') {
            setfiltertext(t('Policies.Name'))
            var testDiv = document.getElementById("name");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'label') {
            setfiltertext(t('Policies.Label'))
            var testDiv = document.getElementById("label");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_modified_date') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("last_modified_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'IsActive') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("IsActive");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            addnewfield(activeOption, name)
        }
    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
            navigate("/RebateCategoryPolicy/new/" + id);
        }
    }
    const SortColumn = (colName) => {


        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getRebateCategoryListViewData("RebateCategory", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue.filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'IsActive') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getRebateCategoryListViewData("RebateCategory", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getRebateCategoryListViewData("RebateCategory", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            setFilterCol('')
            setFilterValue([])

            setFilterSearch("")
            setDropdownData([])
            setFilterDropdownData([])
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };


    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";

    //-------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{`${t('Policies.RebateCategory')}${t('Policies.PolicyList')}`}</li>
                </ol>
            </nav>
            <div style={{ display: "none" }}>

            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">

                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" id="firstName" name="firstName" autoComplete='off' placeholder={'Search for Name, Label & Last Modified By'} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-5">


                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right" }} onClick={() => OpenHandler(checkedValue)} >{t('Open')}</button>


                                            </>
                                            :
                                            ''
                                        } </div>
                                </div>


                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th className="tableheader" style={{ width: '30px' }}>
                                                <input
                                                    type="checkbox"
                                                    onChange={checkAll}
                                                    checked={checkAllChecked}
                                                    id="option-all"
                                                />
                                            </th>
                                            <th id="name" style={{ backgroundColor: (filterValueDict.name ? '#6cae55' : ''), minWidth: '200px' }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.Name')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Name')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('name')}></span>
                                                </div>
                                            </th>
                                            <th id="label" style={{ backgroundColor: (filterValueDict.label ? '#6cae55' : ''), minWidth: '200px' }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.Label')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('label')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('label')}></span>
                                                </div>
                                            </th>
                                            <th id="last_modified_date" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '250px' }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedDate')} </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')}></span>
                                                </div>
                                            </th>
                                            <th id="last_modified_user" style={{ backgroundColor: (multiFilterValue.last_modified_user ? '#6cae55' : ''), width: '300px' }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_user')}></span>
                                                </div>
                                            </th>
                                            <th id="IsActive" style={{ backgroundColor: (multiFilterValue.IsActive ? '#6cae55' : ''), width: '200px' }}  >
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('IsActive')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActive')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('IsActive')}></span>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {t('NoDataAvailable')}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>
                                                            <td ><input id={listVal.Id} type="checkbox" value={`${listVal.Name},${listVal.ISActive}`} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive)} name="chkBox" /></td>

                                                            <td>{listVal.Name}</td>
                                                            <td>{listVal.label}</td>
                                                            <td>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                            <td>{listVal.LastModifiedUser}</td>
                                                            <td >
                                                                <div className="btn-group">
                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        {Service.editPerms(userPerms) == false ? (listVal.IsActive === true ? "Active" : "In-Active") : listVal.IsActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-A")}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePolicyActiveStatus(listVal.Id, "Open-D")}>In-Active</a>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                        }

                                    </tbody>
                                </table>
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                        {filterCol === 'name' || filterCol === 'label' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>
                                        }

                                        {filterCol === 'last_modified_date' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'last_modified_user' || filterCol === 'IsActive' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div >
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div >

    )

}
export default RebateCategoryPoliciesList