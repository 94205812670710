import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import SegemntPoliciesList from '../../../Components/EndurancePolicies/SegmentPolicyComponent/SegmentPoliciesList';

function SegemntPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <SegemntPoliciesList {...props}  navigate={navigate} id={params.id} />
         </div>
     );  

}

export default SegemntPolicy