import React, { useState, forwardRef, useEffect } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Service from '../../../Services/Service';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Select from "react-select";
import $ from 'jquery';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Multiselect from 'multiselect-react-dropdown';
import { event } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionManage from "../../../SessionManagement/SessionManage";


function NewWorkflowRules(props) {
    const [editPerms, setEditPerms] = useState([]);
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);

    const [t, i18n] = useTranslation('translation');
    let rowData = [];
    let loggedInUser = SessionManage.getuserid();
    const navigate = useNavigate();
    const [rebateTypeData, setRebateTypeData] = useState([])
    const [BusinessUnitData, setBusinessUnitData] = useState([])
    const [SalesLevelData, setSalesLevelData] = useState([])
    const [WorkflowItemTypeData, setWorkflowItemTypeData] = useState([])
    const [WorkflowCategoryData, setWorkflowCategoryData] = useState([])
    const [usersData, setUsersData] = useState([])
    const [rolesData, setRolesData] = useState([])
    const [workflowLevels, setWorkflowLevels] = useState([])
    const [state, setState] = useState({ Id: 0, RebateTypeId: 0, BusinessUnitId: 0, WorkflowItemTypeId: 0, SalesLevel: 'All', WorkflowLevelCount: 0, WorkflowItemTypeName: "", IsActive: false, WorkflowRuleId: "" })
    const [errorMessage, setErrorMessage] = useState("");
    let initial_value = [
        { Id: 0, Levels: "Level 1", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
    ]
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let disabled = false;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [WorkflowlevelsUsers, setUsers] = useState([]);
    const [WorkflowlevelsRoles, setRoles] = useState([]);
    const [workflowCategoryName, setWorkflowCategoryName] = useState('');
    const [dropdownOptionsLevels, setdropdownOptionsLevels] = useState([{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }])
    const [selectedOptionsItem, setSelectedOptionsItem] = useState()
    const [selectedOptionsLevels, setSelectedOptionsLevels] = useState();
    const [selectedOptionsBusUnit, setSelectedOptionsBusUnit] = useState();
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    const [levelChange, setLevelChange] = useState();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');

    if (props.name == 'View')
        disabled = true;
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            getRebateTypeData("RebateType/GetAll")
            getBusinessUnitData("BusinessUnit/GetAllByGrp")
            getWorkflowItemTypeData("WorkflowItemType/GetAll")
            getSalesOfficeData("SalesOffice/GetAllByGroup")
            getWorkflowCategoryData("WorkflowCategory/GetAll")

            getRolesData("Roles/GetAll")

            if (props.id !== '0') {

                getWorkflowRuleData("WorkflowRule/GetById", props.id)
            }
            else {

                setState({ RebateTypeId: 0, BusinessUnitId: 0, WorkflowItemTypeId: 0, SalesLevel: "All", IsActive: false, WorkflowLevels: workflowLevels })
                setWorkflowLevels(initial_value)

                getBusinessUnitData("BusinessUnit/GetAllByGrp")
                getWorkflowItemTypeData("WorkflowItemType/GetAll")
                setLockedBy(loggedInUser);
                setIsLocked(true);
            }

        }, [props.id])
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 15,
            maxHeight: 100,
            borderRadius: 6
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0,

        })
    };

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getRebateTypeData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    rowData = res.data
                    setRebateTypeData(rowData)
                }
            }
        });
    }

    const getBusinessUnitData = (method) => {
        Service.getdataByGroup(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    rowData = res.data
                    setBusinessUnitData(rowData)

                }
            }
        });
    }


    const getSalesOfficeData = (method) => {
        Service.getdataByGroup(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    rowData = res.data
                    setSalesLevelData(rowData)
                }
            }
        });
    }

    const getWorkflowItemTypeData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    rowData = res.data
                    setWorkflowItemTypeData(rowData)
                }
            }
        });
    }

    const getWorkflowCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    rowData = res.data
                    setWorkflowCategoryData(rowData)
                }
            }
        });
    }

    const getUsersData = (method, Id) => {
        Service.getUserBasedOnBU(method, Id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data.DataList !== 'null' && typeof res.data.DataList !== 'undefined') {
                    rowData = res.data.DataList
                    setUsersData(rowData)

                }
            }
        });
    }
    const getRolesData = (method) => {
        Service.getAll(method, 50, 1, 'Ascending', 'Name', []).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data.DataList !== 'null' && typeof res.data.DataList !== 'undefined') {
                    rowData = res.data.DataList
                    setRolesData(rowData)

                }
            }
        });
    }
    const getWorkflowRuleData = (method, id) => {
        var BU = 0;
        var WI = 0;

        Service.LockUnLockEntity('Configurationprop/LockEntity', "WorkflowRule", id, loggedInUser).then((res) => {
        });
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    let rData = res.data;

                    setState({
                        Id: rData.Id, RebateTypeId: rData.RebateTypeId, BusinessUnitId: rData.BusinessUnitId,
                        WorkflowItemTypeId: rData.WorkflowItemTypeId,
                        SalesLevel: rData.SalesLevel, WorkflowLevelCount: rData.WorkflowLevelCount, WorkflowLevels: rData.WorkflowLevels,
                        WorkflowItemTypeName: rData.WorkflowItemTypeName,
                        IsActive: rData.IsActive,
                        WorkflowRuleId: rData.WorkflowRuleId,
                    })
                    if ((rData.Locked === true && rData.LockedBy.toString() !== loggedInUser)) {
                        $("#libread").html(`Locked By ${rData.LockedByUserName}`);
                    }
                    else {
                        $("#libread").html(rData.WorkflowRuleId);
                    }
                    getUsersData("Users/GetAllByBUGrp", rData.BusinessUnitName);
                    Service.getdataByGroup("BusinessUnit/GetAllByGrp").then((res) => {

                        if (typeof res !== 'undefined') {

                            if (res.data !== 'null' && typeof res.data !== 'undefined') {

                                res.data.map((i) => {
                                    if (rData.BusinessUnitId == i.Id) {

                                        setSelectedOptionsBusUnit(i);
                                    }
                                })
                            }
                        }
                    });
                    Service.getdataByGroup("WorkflowItemType/GetAll").then((res) => {

                        if (typeof res !== 'undefined') {

                            if (res.data !== 'null' && typeof res.data !== 'undefined') {

                                res.data.map((i) => {
                                    if (rData.WorkflowItemTypeId == i.Id) {


                                        setSelectedOptionsItem(i);
                                    }
                                })
                            }
                        }
                    });
                    setWorkflowLevels(rData.WorkflowLevels)
                    var count = parseInt(rData.WorkflowLevelCount)
                    dropdownOptionsLevels.map((i) => {
                        if (i.value === count) {
                            setSelectedOptionsLevels(i)
                        }
                    })
                    setLockedBy(rData.LockedBy);
                    setIsLocked(rData.Locked);
                }
            }
        });

    }





    const OnChangeHandler = (e) => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked
        }

        setState({ ...state, [name]: value });

    };

    const SaveWorkflowRule = () => {
        let isValid = validateField();
        let inputData = []

        if (isValid == true) {
            if (state.BusinessUnitId == 0 || state.BusinessUnitId == null) {
                state.BusinessUnitId = null;
            }
            inputData = {
                Id: state.Id,
                WorkflowItemTypeId: state.WorkflowItemTypeId,
                BusinessUnitId: state.BusinessUnitId,
                RebateTypeId: state.RebateTypeId,
                SalesLevel: state.SalesLevel,
                LastModifiedUserId: loggedInUser,
                CreatedUserId: loggedInUser,
                WorkflowLevelCount: state.WorkflowLevelCount,
                WorkflowLevels: workflowLevels
            }

            Service.createorupdate('WorkflowRule/CreateOrUpdate', inputData).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {

                        if (res.data.Status === "Success") {

                            rowData = res.data
                            toast.success('Saved Successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            navigate("/workflow/edit/" + res.data.Id);
                        }
                    }
                }

            });
        }
        else {
            setShowErrorModal(true);
        }
    }
    const validateField = () => {
        let isValid = true;

        if (isValid && (state.WorkflowItemTypeId == 0 || state.WorkflowItemTypeId == null)) {
            setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.WorkflowItem')}`)
            isValid = false;
        }
        else if (isValid && (state.WorkflowLevelCount == 0 || state.WorkflowLevelCount == null)) {

            setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.WorkflowLevels')}`)
            isValid = false;
        }
        else if (isValid && (state.BusinessUnitId == 0 || state.BusinessUnitId == null)) {
            setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.BusinessUnit')}`)
            isValid = false;
        }

        if (isValid) {
            workflowLevels.forEach(item => {
                if ((item.WorkflowCategoryId == 0)) {
                    setErrorMessage('Please select Workflow Category')
                    isValid = false;
                }
                if (item.WorkflowCategoryName === 'Role') {
                    if (item.WorkflowlevelsRoles.length > 0) {
                        item.WorkflowlevelsRoles.forEach(i => {
                            if ((i.Name == 0 || i.Name == null)) {
                                setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.Role')}`)
                                isValid = false;
                            }
                        })
                    }
                    else if (item.WorkflowlevelsRoles.length === 0) {
                        setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.Role')}`)
                        isValid = false;

                    }
                }
                else if (item.WorkflowCategoryName === 'User') {

                    if (item.WorkflowlevelsUsers.length > 0) {
                        item.WorkflowlevelsUsers.forEach(i => {
                            if ((i.UserId == 0 || i.UserId == null)) {
                                setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.User')}`)
                                isValid = false;
                            }
                        })
                    }
                    else if (item.WorkflowlevelsUsers.length === 0) {
                        setErrorMessage(`${t('Please Select ')}${t('WorkflowRules.User')}`)
                        isValid = false;
                    }
                }

            })
        }

        return isValid
    }

    const handleInputChange = (e, index) => {
        setHandleChangeValue(true)
        const {
            name,
            value
        } = e.target;
        const list = [...workflowLevels];

        list[index][name] = value;
        if (name == 'UserValueId')
            list[index]['RoleValueId'] = null;
        else if (name == 'RoleValueId') {
            list[index]['UserValueId'] = null;
            list[index]['WorkflowlevelsUsers'] = [];
            list[index]['WorkflowlevelsRoles'] = [];
        }
        if (name == 'WorkflowCategoryId') {
            let i = e.nativeEvent.target.selectedIndex;
            let label = e.nativeEvent.target[i].text;
            list[index]['WorkflowCategoryName'] = label;
            list[index]['UserValueId'] = 0
            list[index]['RoleValueId'] = 0
            if (label === 'Select') {
                list[index]['RoleValueId'] = 0
                list[index]['WorkflowlevelsUsers'] = [];
                list[index]['WorkflowlevelsRoles'] = [];
            }
            setWorkflowCategoryName(label);
        }

        setWorkflowLevels(list);


    }

    const CloseHandler = (flag) => {
        debugger;
        var id = props.id === 'undefined' ? 0 : props.id;
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        else if (flag == "Open" && handleChangeValue == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "WorkflowRule", id, loggedInUser).then((res) => {});
            navigate('/Workflow/0')
        }
        else if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "WorkflowRule", id, loggedInUser).then((res) => {});
            navigate('/Workflow/0')
        }
        else if (flag == "Cancel") {
            setCloseModal(false)
        }
        else {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "WorkflowRule", id, loggedInUser).then((res) => {});
            navigate('/Workflow/0')
        }

    }

    const SaveCloseWorkflowRule = () => {
        let isValid = validateField();
        let inputData = []

        if (isValid == true) {
            if (state.BusinessUnitId == 0 || state.BusinessUnitId == null) {
                state.BusinessUnitId = null;
            }
            inputData = {
                Id: state.Id,
                WorkflowItemTypeId: state.WorkflowItemTypeId,
                BusinessUnitId: state.BusinessUnitId,
                RebateTypeId: state.RebateTypeId,
                SalesLevel: state.SalesLevel,
                LastModifiedUserId: loggedInUser,
                CreatedUserId: loggedInUser,
                WorkflowLevelCount: state.WorkflowLevelCount,
                WorkflowLevels: workflowLevels
            }

            Service.createorupdate('WorkflowRule/CreateOrUpdate', inputData).then((res) => {
                debugger;
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        rowData = res.data
                        toast.success("Saved Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "WorkflowRule", rowData.Id, loggedInUser).then((res) => {

                        });
                        navigate('/Workflow/1');
                    }
                }
            });
        }
        else {
            setShowErrorModal(true);
        }

    }
    const DeleteHandler = () => {
        Service.delete('WorkflowRule/DeleteWorkflowRule', props.id).then((res) => {

            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.Status == "Success") {
                        toast.error('Deleted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClick: () => navigate("/Workflow/2")
                        });

                    }
                    else {

                        setErrorMessage(res.data.Status);
                        showErrorMsg = true;
                        setShowErrorModal(true);
                        navigate("/Workflow/2")
                    }
                }
            }
        });
    }

    const handleUserChange = (event, index) => {
        setHandleChangeValue(true)

        const list = [...workflowLevels];

        const selected = [];
        const selectedId = [];
        setUsers([]);
        let selectedOption = (event);

        for (let i = 0; i < selectedOption.length; i++) {

            selected.push(selectedOption[i].Id);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: 0,
                Id: selectedOption[i].Id,
                UserId: selectedOption[i].UserId,
                FullName: selectedOption[i].FullName


            }
            WorkflowlevelsUsers.push(getallData);
        }
        list[index]['WorkflowlevelsUsers'] = WorkflowlevelsUsers;

        setWorkflowLevels(list)

    }




    const OnItemChange = (data) => {

        setSelectedOptionsItem(data)
        setHandleChangeValue(true)

        setState({ ...state, ['WorkflowItemTypeId']: data.Id });

    }

    const OnBusUnitChange = (data) => {

        setSelectedOptionsBusUnit(data)
        setHandleChangeValue(true)
        setState({ ...state, ['BusinessUnitId']: data.Id });

        if (data.Id !== 0) {
            getUsersData("Users/GetAllByBUGrp", data.Name);
        }
    }



    const onLevelsChange = (data) => {

        setHandleChangeValue(true)
        setSelectedOptionsLevels(data)
        var LC = data.label
        setLevelChange(LC)

        if (data.value !== 0) {
            if (data.label === 1) {
                setWorkflowLevels(initial_value)
            }
            else if (data.label === 2) {
                let initLevel2 = [
                    { Id: 0, Levels: "Level 1", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
                    { Id: 0, Levels: "Level 2", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
                ];
                setWorkflowLevels(initLevel2)
            }
            else if (data.label === 3) {
                let initLevel3 = [
                    { Id: 0, Levels: "Level 1", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
                    { Id: 0, Levels: "Level 2", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
                    { Id: 0, Levels: "Level 3", WorkflowCategoryId: 0, UserValueId: 0, RoleValueId: 0, WorkflowCategoryName: '', WorkflowlevelsUsers: [], WorkflowlevelsRoles: [], SelectedOptionCategory: 0 },
                ];
                setWorkflowLevels(initLevel3)
            }


        }
        let value = ""
        value = data.value
        setState({ ...state, ['WorkflowLevelCount']: value.toString() });
    }



    const OnCategoryChange = (data, index) => {
        const list = [...workflowLevels];
        setHandleChangeValue(true)


        if (data.Id !== 0) {

            list[index]['WorkflowCategoryName'] = data.label;
            WorkflowCategoryData.map((i) => {
                if (data.label == i.label) {

                    list[index]['SelectedOptionCategory'] = i;
                    list[index]['WorkflowCategoryId'] = data.value;
                }
            })

        }

        setWorkflowCategoryName(data.label);

        setWorkflowLevels(list);

    }



    const handleRoleChange = (event, index) => {
        setHandleChangeValue(true)
        const list = [...workflowLevels];

        setRoles([]);
        let selectedOption = (event);

        const getallData = {
            id: 0,
            Id: selectedOption.Id,
            Name: selectedOption.Name,
            label: selectedOption.label
        }

        WorkflowlevelsRoles.push(getallData);


        list[index]['RoleValueId'] = WorkflowlevelsRoles.Id;
        list[index]['WorkflowlevelsRoles'] = WorkflowlevelsRoles;

        setWorkflowLevels(list)
    }


    if (disabled === false) {
        if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
            disabled = false;
        }

        else {
            disabled = true;
        }
    }
    window.onbeforeunload = function () {
        unlockEntity();

    };
    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "WorkflowRule", props.id, loggedInUser).then((res) => {

        });

    }

    const class_select = ` custom-multiselect ${editPerms == false ? "disabledClass" : ""}`;

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/Workflow/0">{t('WorkflowRules.WorkflowRulesList')}</Link></li>
                    <li className="breadcrumb-item active" id="libread" aria-current="page">{t('WorkflowRules.NewWorkflowRule')}</li>
                </ol>
            </nav>
            <ToastContainer autoClose={1000} />
            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('WorkflowRules.WorkflowRule')}
                                </div>
                                <div className="col-md-5">

                                    <button className="btn btn-sm btn-update ml-2" onClick={CloseHandler} type="submit" style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                                    {editPerms == false ? "" : (isLocked === true && lockedBy.toString() === loggedInUser) ? <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={DeleteHandler} type="submit">{t('Delete')}</button> : ''}
                                    {editPerms == false ? "" : state.IsActive === true ? "" : (isLocked === true && lockedBy.toString() === loggedInUser) ? <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={SaveWorkflowRule} type="submit">{t('Save')}</button> : ''}
                                    {editPerms == false ? "" : state.IsActive === true ? "" : (isLocked === true && lockedBy.toString() === loggedInUser) ? <button className="btn btn-sm btn-update ml-2" style={{ width: "80px", float: "right" }} onClick={SaveCloseWorkflowRule} type="submit">{t('Save&Close')}</button> : ''}

                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            {showErrorMsg === true ?
                                <div class="alert alert-danger" role="alert">
                                    {showDetails}
                                </div>
                                : null}
                            {
                                showSuccessMsg === true ?
                                    <div class="alert alert-success" role="alert">
                                        {showDetails}
                                    </div>
                                    : null}

                            <div className='row'>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">

                                                <div className="card-body" >
                                                    <div className="container">
                                                        <div className="row">
                                                            <label className="col-md-6 mt-2 text-left l_width">{t('WorkflowRules.WorkflowRuleId')}<span className={classes.mandatory}>*</span></label>
                                                            <input disabled={true} name="WorkflowRuleId" type="text" className="col-md-6 mt-2 form-control m_width" value={state.WorkflowRuleId} />


                                                        </div>
                                                        <div class="row">
                                                            <label className="col-md-6 mt-2 text-left l_width">{t('WorkflowRules.WorkflowItem')}<span className={classes.mandatory}>*</span></label>

                                                            <Select className={`col-md-6 textbox-padding-dropdown form-select mt-2 fontsizedropdown m_width`}
                                                                options={WorkflowItemTypeData}
                                                                placeholder={t('Select')}
                                                                value={selectedOptionsItem}
                                                                onChange={OnItemChange}
                                                                isSearchable={true}
                                                                styles={customStyles}
                                                                isDisabled={disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 pr-lg-2">
                                            <div className="card text-center card_border">

                                                <div className="card-body" >
                                                    <div className="container">
                                                        <div className="row">


                                                            <label className="col-md-6 mt-2 text-left l_width">{t('WorkflowRules.WorkflowLevels')}<span className={classes.mandatory}>*</span></label>
                                                            <div className="col-md-6 mt-2 m_width">
                                                                <Select className="form-select "
                                                                    options={dropdownOptionsLevels}
                                                                    placeholder={t('Select')}
                                                                    value={selectedOptionsLevels}
                                                                    onChange={onLevelsChange}
                                                                    isSearchable={true}
                                                                    styles={customStyles}
                                                                    isDisabled={disabled}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label className="col-md-6 mt-2 text-left l_width">{t('WorkflowRules.BusinessUnit')}<span className={classes.mandatory}>*</span></label>

                                                            <div className="col-md-6 mt-2 m_width">
                                                                <Select className="form-select"
                                                                    options={BusinessUnitData}
                                                                    placeholder={t('Select')}
                                                                    value={selectedOptionsBusUnit}
                                                                    onChange={OnBusUnitChange}
                                                                    isSearchable={true}
                                                                    styles={customStyles}
                                                                    isDisabled={disabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="container">

                                <br>

                                </br>
                                <div className="row mt-2 ">
                                    <label className="text-center col-md-2 w_width" style={{textAlign: "center"}}>{t('WorkflowRules.Workflow')}</label>
                                    <label className="text-center col-md-2 ww_width"></label>
                                    <label className="text-center col-md-3 www_width" style={{textAlign: "center"}}>{t('WorkflowRules.By')}</label>
                                    <label className="text-center col-md-2 ww_width"></label>
                                    <label className="text-center  col-md-3 www_width" style={{textAlign: "center"}}>{t('WorkflowRules.Values')}</label>
                                </div>
                                {

                                    workflowLevels.map((item, index) => (

                                        <div className='row mt-2'>

                                            <div className='col-md-2 w_width'>
                                                <input type="text" autoComplete='off' className='col-md-10 form-control' name="workflow" id="Workflow"
                                                    placeholder={t('Level1')} value={item.Levels}
                                                    Name="Levels" disabled onChange={(Event) => handleInputChange(Event, index)}>
                                                </input>
                                            </div>

                                            <div className='col-md-2 ww_width' style={{ textAlign: "center" }} >
                                                <label  >
                                                    {t('WorkflowRules.Where')}
                                                </label>
                                            </div>
                                            <div className='col-md-3 www_width'>


                                                <Select className="form-select"
                                                    options={WorkflowCategoryData}
                                                    placeholder={t('Select')}
                                                    value={item.SelectedOptionCategory}

                                                    onChange={(event) => OnCategoryChange(event, index)}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disabled}

                                                />

                                            </div>
                                            <div className='col-md-2 ww_width'>
                                                <label  >
                                                    =
                                                </label>
                                            </div>

                                            {item.WorkflowCategoryName === 'User' ?
                                                <div className='col-md-3 www_width'>
                                                    <Multiselect className={class_select}
                                                        onSelect={(Event) => handleUserChange(Event, index)}
                                                        onRemove={(Event) => handleUserChange(Event, index)}
                                                        options={usersData}
                                                        displayValue="FullName"
                                                        showCheckbox={true}
                                                        selectedValues={item.WorkflowlevelsUsers}
                                                        disable={disabled}
                                                        style={customStyles}
                                                    />


                                                </div>
                                                :

                                                item.WorkflowCategoryName === 'Role' ?
                                                    <div className='col-md-3 www_width'>

                                                        <Select className={class_select}
                                                            options={rolesData}
                                                            placeholder="Select"
                                                            value={item.WorkflowlevelsRoles}
                                                            onChange={(Event) => handleRoleChange(Event, index)}
                                                            isSearchable={true}
                                                            styles={customStyles}
                                                            isDisabled={disabled}
                                                        />



                                                    </div>
                                                    :
                                                    <div className='col-md-3 www_width'>

                                                        <Select className={class_select}
                                                            singleSelect={true}
                                                            placeholder={t('Select')}
                                                            disable={disabled}
                                                            styles={customStyles}
                                                        />
                                                    </div>

                                            }
                                        </div>
                                    ))}


                            </div>


                        </div>

                    </div>
                </div>
            </div>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('workflowRules.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => CloseHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => CloseHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    )

}
export default NewWorkflowRules