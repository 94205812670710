import $ from 'jquery';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Service from '../../Services/Service';
import SessionManage from '../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";


function DistributorCustomerListView(props) {


    let rowData = [];
    const [t, i18n] = useTranslation('translation');
    let loggedInUser = SessionManage.getuserid();
    const navigate = useNavigate();
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [showspinner, setshowspinner] = useState('');
    const [totalCountlst, settotalCountlst] = useState('');
    const [actionsDone, setActionsDone] = useState(props.id)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [userPerms, setuserPermission] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterValue, setFilterValue] = useState([]);
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [filtertext, setfiltertext] = useState('');
    const [dropdownChange, setDropdownChange] = useState([{ filterKey: "", FilterValue: "" }]);
    const location = useLocation();
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        DistributorName: '',
        EndCustomer: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
    });



    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        getUserPermission(location.pathname);
        settotalselectedshow(false);
        setshowspinner(true)
        getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue);
    }, [0]);






    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const searchHandler = (event) => {
        console.log("8/12", event.target.value)
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const getDCListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterVal, search) => {
        console.log('8/12', method, pageSize, pageNumber, sortType, SortProperty, filterVal)
        Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search).then((res) => {
            console.log("8/12", res)
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList
                    setListViewData(rowData)
                    setFilteredArray(rowData)
                    setshowspinner(false)
                    settotalCountlst(res.data.Totalcount)
                    setActionsDone(0)
                    LoadMore(rowData.length, res.data.Totalcount)
                }
            }
        });
    }
    console.log("8/12", filteredArray)

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                //debugger;
                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)
        setshowfilterpopup(true)
        setFilterCol(name)
        if (name === 'DistributorName') {
            setfiltertext(t('DistributorCustomer.DistributorName'))
        }
        if (name === 'EndCustomer') {
            setfiltertext(t('DistributorCustomer.EndCustomer'))
        }
        //setfiltertext(name)
        var testDiv = document.getElementById(name);
        setdivleft((testDiv.offsetLeft + 30) + "px");
        setshowfilterpopup(true)
    }

    const filterInput = (e) => {
        console.log('9/7', e.target.value)
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
        }
        else {
            setshowfilterpopup(false)

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue([])
            setDropdownData([])
            setDropdownChange("")
        }
    }
    console.log('16/9', dropdownChange)

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getDCListViewData("SDAgreement/GetDistributorCustomerData", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getdataWithPagingByGroupSearch("SDAgreement/GetDistributorCustomerData", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList;
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = listViewData.length;
                        setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                        setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])

                        setActionsDone(0)
                        console.log(listViewData)
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });
    }


    // const handleChange = (e, id, status, name) => {
    //     let isChecked = e.target.checked;   // true if it is checked false if unchecked
    //     console.log('checked', isChecked)
    //     console.log('checked', id)
    //     let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    //     if (isChecked === true) {
    //         settotalselectedshow(true);
    //         settotalselectedcount(checkedBoxes.length);
    //         setCheckedValue(id)  // check box value - id
    //         setCheckedValueStatus(status) // check box value - status
    //         setCheckedValueid(name) //check box value - RA-0000
    //     }
    //     else {
    //         if (checkedBoxes.length === 0) {
    //             settotalselectedshow(false);
    //         }

    //         settotalselectedcount(checkedBoxes.length);
    //         setCheckedValue([])  // check box value - id
    //         setCheckedValueStatus([]) // check box value - status
    //         setCheckedValueid([]) //check box value - RA-0000
    //     }


    //     if (checkedBoxes.length == 1) {
    //         setshowbutton(true);
    //         setDeleteshowbutton(false)
    //     }
    //     else if (checkedBoxes.length > 1) {
    //         setshowbutton(false);
    //         setDeleteshowbutton(true)
    //     }
    //     else {
    //         setshowbutton(false);
    //         setDeleteshowbutton(false)
    //     }
    // }
    // console.log('checked value', checkedValue)
    // console.log('checked value status', checkedValueStatus)
    // console.log('checked value status', checkedValueid)


    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/NewShipAndDebit/" + id);
        }
    }

    // const ExporttoCSV = () => {
    //     let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    //     let values = "";
    //     if (checkedBoxes.length >= 1) {
    //         for (let checkbox of checkedBoxes) {
    //             values = values + ';' + checkbox.id;
    //             //   console.log('ajay', checkedMultiValueid);
    //         }
    //     }
    //     let objFilter = [];
    //     let objFilterValue = [{
    //         filterKey: "rebate_number",
    //         FilterValue: values,
    //     }]
    //     if (checkedBoxes.length >= 20) {
    //     } else {
    //         objFilter = objFilterValue;
    //     }
    //     Service.getdataWithPagingByGroupSearch("rebate/GetByRebateStatuslst/status", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
    //         console.log("1406 . resp", res)
    //         if (typeof res !== 'undefined') {
    //             if (res.data !== 'null' && res.data !== 'undefined') {
    //                 setCsvList(res.data.DataList);
    //                 setTimeout(() => {
    //                     csvInstance.current.link.click();
    //                 });

    //             }
    //         }
    //     });
    // }





    return (
        <div className="container-fluid content-top-gap">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><a href="/">{t('Home.title')}</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('DistributorCustomer.Distributor&CustomerData')}</li>
                </ol>
            </nav>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" autoComplete='off' id="firstName" name="firstName" placeholder={`${t('SearchFor')} ${t('DistributorCustomer.DistributorName')} , ${t('DistributorCustomer.EndCustomer')}`} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-5">
                                        <a className="btn btn-update btn-sm ml-2" id="create" style={{ width: "40px", backgroundColor: "buttonface", float: "right" }} href="#">{t('View')}</a>
                                        {/* { Service.editPerms(userPerms) == false & totalselectedcount <= 0 ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right" }} onClick={(event) => ExporttoCSV()}>Export</button>} */}
                                    </div>
                                </div>
                                <table className="table table-bordered text-left mt-2" id="tableA">
                                    <thead className="text-white rt-thead" style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th className="tableheader" style={{ width: '30px' }}>
                                                <input type="checkbox" id="option-all" onChange={checkAll} />
                                            </th>
                                            <th id="DistributorName" style={{ backgroundColor: (filterValueDict.DistributorName ? '#6cae55' : '') }} >
                                                {t('DistributorCustomer.DistributorName')} <span className='fa fa-sort' onClick={() => SortColumn('DistributorName')} ></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorName')} ></span>
                                            </th>
                                            <th id="EndCustomer" style={{ backgroundColor: (filterValueDict.EndCustomer ? '#6cae55' : '') }}>
                                                {t('DistributorCustomer.EndCustomer')} <span className='fa fa-sort' onClick={() => SortColumn('EndCustomerName')} ></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('EndCustomer')} ></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {console.log("6/12", filteredArray.length)}
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>
                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td>{t('NoDataAvailable')}</td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr key={listVal.AgreementId}>
                                                            <td><input id={listVal.AgreementId} type="checkbox" name="chkBox" value={[listVal.Id, listVal.Status]} /></td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.DistributorName}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.EndCustomerName}</td>
                                                        </tr>
                                                    )
                                        }
                                    </tbody>
                                </table>
                                

                                <div className='row mt-1'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row mb-1'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{width: "70px"}}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                        {filterCol === 'DistributorName' || filterCol === 'EndCustomer' ?

                                            // <div>
                                            //     <span className='fa fa-filter' /><input type='textbox' placeholder={t('Search')} className='col-md-10 ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            // </div>
                                            // :
                                            // <></>
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>

                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>

                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{width: "45px"}}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DistributorCustomerListView