import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionManage from "../../../SessionManagement/SessionManage";

function CreateNewCustomerGroupsComponent(props) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation('translation');
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [showCustomerpopup, setshowCustomerpopup] = useState('');
    const [loading, setLoading] = useState(false);
    const [sidebarwidth, setsidebarwidth] = useState('63vw');
    const [customerSearch, setCustomerSearch] = useState('');
    const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
    const [filterSearchCust, setFilterSearchCust] = useState("");
    const [filtercustData, setfilterCustData] = useState([]);
    const [filtertext, setfiltertext] = useState('');

    const [customercheckAll, setCustomercheckAll] = useState(false);
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '25px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const [filterValueCustomer, setFilterValueCustomer] = useState({
        Name: '',
        Label: '',
        Station: '',
        State: '',
        Zone: '',

    });
    const [custFilterCol, setCustomerFilterCol] = useState('');
    const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const [customerDataFilters, setCustomerDataFilters] = useState([]);
    const [customerDataSelected, setCustomerDataSelected] = useState([]);
    const [filterValueCust, setFilterValueCust] = useState([]);

    const [customerDataSelectedLst, setCustomerDataSelectedLst] = useState([]);

    const [customerDataFilter, setCustomerDataFilter] = useState([]);

    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState();
    let loggedInUser = SessionManage.getuserid();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const [data, setData] = useState([]);

    let disable = false;
    const [inputField, setInputField] = useState({
        Name: '',
        label: '',
        Id: 0,
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser,
        CustomerLst: []
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (typeof props.id != 'undefined' && props.id !== 0) {
            getdata("CustomerGroups/GetById", props.id);

        }

        Service.getdata('Customer/GetIndividualCustomersWithoutGroup').then((res) => {
      
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
             
                    var datasss = res.data;
                    setCustomerData(res.data)
                    setData(res.data)
                }
            }
        });
  
    }, [])


    const SearchCustomer = (event) => {
        var list = []
        let lstSelected = customerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerSearch(event.target.value)
        if (customerDataFilter.length === 0) {
            list = customerData;

            setCustomerDataFilter(list);
        }
        else {
            list = customerDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
           
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.Id === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setCustomerData(uniquelst)

          
        }
        else {
            setCustomerData(list)
        }
    }



    const FilterColumnCustomer = (name) => {

        var divtop1 = document.getElementById("Customer");
        var cardtop1 = document.getElementById("Customer");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setCustomerFilterCol(name)
        setshowCustomerfilterpopup(true)

        if (name === 'Name') {
            setfiltertext(t('Rebates.CustomerCode'))
            var testDiv = document.getElementById("Name");
            setdivleft((testDiv.offsetLeft + 120) + "px");
        }
        if (name === 'Label') {
            setfiltertext(t('Rebates.CustomerName'))
            var testDiv = document.getElementById("Label");
            setdivleft((testDiv.offsetLeft + 285) + "px");
        }

        if (name === 'Station') {
            setfiltertext(t('Rebates.Station'))
            var testDiv = document.getElementById("Station");
            setdivleft((testDiv.offsetLeft - 80) + "px");

        }
        if (name === 'State') {
            setfiltertext(t('Rebates.State'))
            var testDiv = document.getElementById("State");
            setdivleft((testDiv.offsetLeft - 80 ) + "px");

        }
        if (name === 'Zone') {
            setfiltertext(t('Rebates.Zone'))
            var testDiv = document.getElementById("Zone");
            setdivleft((testDiv.offsetLeft -84)  + "px");
        }

        DropdownHandlerCus(name);
    }

    const DropdownHandlerCus = (key) => {
        Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data !== 'null' && res.data !== 'undefined') {
                        setFilterCusDropDownData(res.data)
                        addnewfieldCustomer(res.data, key)
                    }
                }
            }

        });
    }
    const CustFilterHandler = (flag) => {
        let list = [];
        //data
        var selectedCus = data;
        for (var i = 0; i < customerData.length; i++) {
            if (customerData[i].Selected == true) {
                for (var k = 0; k < selectedCus.length; k++) {
                    if (customerData[i].Id === selectedCus[k].Id) {
                        selectedCus[k].Selected = true;
                    }
                }
            }
        }
        setData(selectedCus);
        setshowCustomerfilterpopup(false)

        if (flag === 'Apply') {
          
            for (var key in filterValueCustomer) {
                if (filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });

        }
        else {
            setshowCustomerfilterpopup(false)
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: '',
            }));
          
            for (var key in filterValueCustomer) {
                if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });
            setFilterValueCust([])
            setfilterCustData([])
            setFilterSearchCust('')


        }
        
    }



    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res !== undefined) {
                        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                            if (res.data.DataList !== undefined) {
                                setuserPermission(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }
    const getdata = (method, id) => {
     
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setInputField(res.data);
                            setPolicyStatus(true)
                            setLockedBy(res.data.LockedById);
                            setIsLocked(res.data.Locked);
                           
                            setCustomerDataSelected(res.data.CustomerLst)
                        }
                    }
                }
            }
       
        })
        
    }

    const checkAllFilterCustomer = (mycheckbox) => {
        var lastfilter = [...filterCusDropDownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
          let currentdata = lastfilter.map((i) => {
            i.selected = true;
            return i;
          });
    
          setFilterCusDropDownData(currentdata)
          filterCusDropDownData.map((each) => {
            const getallData = {
              Name: each.Name,
            }
            data1.push(getallData)
          })
          setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
          let updateddata = ([...filterValueCust, ...data1]);
          setFilterValueCustomer(() => ({
            ...filterValueCustomer, [custFilterCol]: updateddata,
          }));
        }
        else {
          let currentdata = lastfilter.map((i) => {
            i.selected = false;
            return i;
          });
          setFilterValueCust([])
          setFilterValueCustomer([])
        }
    
      }


    const inputsHandler = (e) => {
        setHandleChange(true)
      
        if (policyStatus === false) {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
    }

    const checkValidation = () => {
       
        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage('Customer Group Label')
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (customerDataSelected.length <= 0) {
            setErrorMessage('Please Select Customers')
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {
            saveHandler(true)
        }
    }


    const saveHandler = (isValid) => {
        let data = inputField
        data['LastModifiedUserId'] = loggedInUser;
        data['CustomerLst'] = customerDataSelected;
    
        if (isValid === true) {
            Service.createorupdate("CustomerGroups/CreateOrUpdate", data).then(res => {
     
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data != '0') {
                        toast.success('saved successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate("/policies/customergroups")
                        });
                    }
                    else {
                        setErrorMessage('Same Customer Groups already exists')
                        setShowErrorModal(true)
                        
                    }
                }
            })

        };
    }

    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        }
                    }
                }
            }

        });
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const OnChangeHandlerRebateType = (data) => {
        setSelectedOptionsRebateType(data);
        setInputField(() => ({
            ...inputField, ['RebateTypeId']: data.value, ['RebateType']: data.label,
        }));
    };

    const handleCustomerChanges = (e, label, id, State) => {



        let selected = [];
        let update = [];
        const getallData = []; 

        let olddata = [...customerData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
             
            })

            getallData.push(id);
              
            setCustomerData(olddata);
           
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

          
            setCustomerData(olddata);
        }

    }

    const OnCusdropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        let allCheckedCustomer = false;
    
        let allCheckboxesCustomer = document.getElementsByName("filter-option-all-customer");
        console.log(allCheckboxesCustomer, "all-boxes");
        let totalCheckedCustomer = 0;
        allCheckboxesCustomer.forEach((checkbox) => {
            if (checkbox.checked)
                  totalCheckedCustomer += 1;
        });
    
        let tempData = [...filterCusDropDownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalCheckedCustomer) {
            tempData[0].selected = isChecked;
            allCheckedCustomer = true;
        }
        setFilterCusDropDownData(tempData);
    
    
        var lastfilter = [...filterCusDropDownData]
        if (isChecked === true) {
          let currentdata = lastfilter.map((i) => {
            if (i.Name == name) {
              i.selected = true;
            }
            return i;
          });
          setFilterCusDropDownData(currentdata);
          let data1 = []
          const getallData = {
            Name: name,
          }
          data1.push(getallData)
          setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
          let updateddata = ([...filterValueCust, ...data1]);
          setFilterValueCustomer(() => ({
            ...filterValueCustomer, [custFilterCol]: updateddata,
          }));
    
          if (allCheckedCustomer) {
            const selectAllObj = {
                "Name": "Select All"
            };
            updateddata.splice(0, 0, selectAllObj);
        }
    
        setFilterValueCustomer(() => ({
            ...filterValueCustomer, [custFilterCol]: updateddata,
        }));
        }
        else {
          let currentdata = lastfilter.map((i) => {
            if (i.Name == name) {
              i.selected = false;
            }
            return i;
          });
    
          setFilterCusDropDownData(currentdata);
          setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
          let data1 = filterValueCust.filter(data => data.Name !== name);
          setFilterValueCustomer(() => ({
            ...filterValueCustomer, [custFilterCol]: data1,
          }));
        }
      }
    const selectCustomer = () => {

        let olddata = [...customerData];
        olddata.map((i) => {
            let lstSelected = customerDataSelected.filter((e) => e.Selected === true && e.Id == i.Id);
            if (lstSelected.length > 0) {
                i.Selected = true;
            } else {
                i.Selected = false;
            }
        })
        setShowSelectedCustomerpopup(false);
        setshowCustomerpopup(true);

    }
    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
          
            props.navigate("/policies/customergroups")
        }
        if (flag == "Close") {
          
            props.navigate("/policies/customergroups")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
        
    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "RebateMeasure", props.id, loggedInUser).then((res) => {

        });

    }
    const handleCustSelection = (e, label, id, State, Isgroupcustomer, CustomergroupId) => {
       
        if (inputField.Id === 0 && Isgroupcustomer === true) {

        }
        else if (CustomergroupId !== inputField.Id && Isgroupcustomer === true) {

        } else {


            let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");

            let selected = [];
            let update = [];
            const getallData = [];
            let olddata = [...customerData];
           
            if (checkboxes.length < 1) {
                olddata.map((i) => {
                    if (i.Id === id) {
                        i.Selected = true;
                    }
                })

                getallData.push(id);
                setCustomerData(olddata);
                
            }
            else {
                olddata.map((i) => {
                    if (i.Id === id) {
                        i.Selected = false;
                    }
                    update.push(i);
                })

                
                setCustomerData(olddata);
            }

        }
    }

    const UserfilterHandlerCustomer = (e) => {
        setFilterSearchCust(e.target.value)
        if (e.target.value !== "") {
          let searchResults = filterCusDropDownData.filter((each) => {
            return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
          });
          setFilterCusDropDownData(searchResults);
        }
        else {
    
          setFilterSearchCust("")
          setFilterCusDropDownData(filtercustData)
        }
      }

      const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
          "Name": "Select All",
          "Value": "Select All",
          "ischecked": alreadySelected
        }
    
        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
      }

      const addnewfieldCustomer = (data, key) => {
        let currentdata = data.map((i) => {
          i.selected = false;
          return i;
        });
    
        let selectedKeys = filterValueCustomer[key] ? filterValueCustomer[key] : [];
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
    
        if (selectedKeys != '') {
          currentdata.forEach((each) => {
            selectedKeys.forEach((eachSelected) => {
              if (each.Name == eachSelected.Name) {
                each.selected = true
              }
            })
          })
        }
    
        let result = addSeletAll(currentdata, alreadySelected);
        setFilterCusDropDownData(currentdata);
        setfilterCustData(currentdata)
      }

    const checkAllCustomer = (mycheckbox) => {

        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Id);
            })

            setCustomerDataSelected(olddata)
        }
        else {
            setCustomercheckAll(false);
          
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setCustomerDataSelected(olddata)
        }

    }
    

    const okCustomer = () => {
        let olddata = customerData.filter((e) => e.Selected === true);
        let selected = []
        olddata.map((i) => {
            selected.push(i);
        })

        setCustomerDataSelected(selected);
        setshowCustomerpopup(false);
      
    }
    const clickCloseCustomer = () => {
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
    }
    const clickSelectedShowCustomerPopup = () => {
        setshowCustomerpopup(false);
        //
        let lstcopy = customerDataSelected.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerDataSelectedLst(lstcopy);


        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(true);
    }

    const handleCustomerSelectedChanges = (e, label, id, index) => {
   

        let result = [...customerDataSelectedLst];
        if (e.target.checked == true) {
            result[index].Selected = true;
        } else {
            result[index].Selected = false;
        }
        setCustomerDataSelectedLst(result);

    }
    const okSelectedCustomer = () => {
        let result = [...customerDataSelectedLst];

        setCustomerDataSelected(result.filter((e) => e.Selected === true));
        setShowSelectedCustomerpopup(false);
    }

    const disabledClass = `col-md-2 mt-2 textbox-padding  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    const class_select = ` col-md-2 textbox-padding mt-2 fontsizedropdown  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/policies/customergroups">{t('Policies.CustomerGroupsList')}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Policies.CreateCustomerGroups')}</li>
                    </ol>
                </nav>
                {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
                <ToastContainer autoClose={1000} />
                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                    {t('Policies.CreateCustomerGroups')}
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" type="submit" style={{ float: "right",width: "40px" }} onClick={checkValidation}>{t('Save')}</button>}
                                        <button className="btn  btn-update btn-sm ml-2 " id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left l_width">{t('Policies.CustomerGroupLabel')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" autoComplete='off' onChange={inputsHandler} className="col-md-2 mt-2 textbox-padding form-control m_width" value={inputField.Name} disabled={policyStatus} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-md-2 mt-2 text-left l_width">{t('Rebates.Customer')}<span className={classes.mandatory}>*</span></label>

                                        <div className="d-flex align-items-center ">
                                            <button className="btn btn-outline-dark  btn-sm m_width" type="submit" onClick={selectCustomer} style={{ width: "50px" }} >{t('Select')}</button>
                                            <br></br>

                                            <a href='javascript:void(0)' onClick={clickSelectedShowCustomerPopup} className="ml-2"  ><label style={{ paddingTop: "5px" }}>({customerDataSelected.filter(i => i.Selected == true).length}) {t('Rebates.CustomersAdded')}</label></a>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            Close
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>


            {
                showCustomerpopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="d-flex align-items-center">
                                                <div className="col-md-10 text-left" style={{marginLeft:"-20px"}}>
                                                    {t('Rebates.CustomerListing')}
                                                </div>
                                                <div className="" style={{marginLeft:"10px"}}>
                                                 <button style={{ width: "80px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomer}>{t('Save&Close')}</button>
                                                </div>
                                                <div className="">

                                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseCustomer} type="submit" style={{width: "50px"}}>{t('Cancel')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1" id="Customer">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">

                                        </div>

                                        <div className='scrollbar-class'>

                                            <div className="container">
                                                <div>

                                                    <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.Customer')}`} />
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="Customer">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input checked={customercheckAll} type="checkbox" name="customer-check" id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                                            </th>
                                                            <th id="Name" style={{ backgroundColor: (filterValueCustomer.Name ? '#6cae55' : ''), width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Name')}></span></th>
                                                            <th id="Label" style={{ backgroundColor: (filterValueCustomer.Label ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Label')}></span></th>
                                                            <th id="Station" style={{ backgroundColor: (filterValueCustomer.Station ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Station')}></span></th>
                                                            <th id="State" style={{ backgroundColor: (filterValueCustomer.State ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('State')}></span></th>
                                                            <th id="Zone" style={{ backgroundColor: (filterValueCustomer.Zone ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Zone')}></span></th>
                                                        </tr>
                                                    </thead>
                                                    <thead>

                                                        {
                                                            customerData.sort((a, b) =>
                                                                a.Selected > b.Selected ? -1 : 1,
                                                            ).map((cslist, index) => (

                                                                <tr onClick={(event) => handleCustSelection(event, cslist.label, cslist.Id, cslist.State, cslist.Isgroupcustomer, cslist.CustomergroupId)}>
                                                                    <td style={{ width: "30px" }}>

                                                                        {inputField.Id === 0 && cslist.Isgroupcustomer === true ?
                                                                            <>
                                                                                <input disabled={true} type="checkbox" checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} /></>
                                                                            : inputField.Id === 0 && (cslist.Isgroupcustomer === false || cslist.Isgroupcustomer === null) ? <>

                                                                                <input type="checkbox" checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />
                                                                            </>

                                                                                : cslist.Isgroupcustomer === true && cslist.CustomergroupId !== inputField.Id ? <>

                                                                                    <input type="checkbox" disabled={true} checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                                </> :
                                                                                    <>
                                                                                        <input type="checkbox" checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                                    </>


                                                                        }



                                                                    </td>
                                                                    <td style={{ width: "90px" }}>

                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Name}>{cslist.Name}</div>


                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.label}>{cslist.label}</div>


                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Station}>{cslist.Station}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.State}>{cslist.State}</div>

                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={cslist.Zone}>{cslist.Zone}</div>

                                                                    </td>

                                                                </tr>





                                                            ))


                                                        }


                                                    </thead>
                                                </table>


                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>

                            </div>

                        </div>
                        {
                            showCustomerfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px"}}>

                                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?

                                        // <div className="row">
                                        //     <Multiselect
                                        //         onSelect={(Event) => OnCusdropdownChange(Event)}
                                        //         onRemove={(Event) => OnCusdropdownChange(Event)}
                                        //         options={filterCusDropDownData}
                                        //         displayValue="Name"
                                           
                                        //         showCheckbox={true}
                                        //         selectedValues={filterValueCustomer[custFilterCol]}
                                        //         className='col-md-9 textbox-padding fontsizedropdown'
                                        //         style={customMultiStyles}
                                        //     />
                                        // </div>
                                        <>
                                        <div className="container">
                                               <div className="row">
                                                   <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                               </div>
                                            </div> 
                                         <div className="container mt-2">
                                         <div className="row">
                                           <input type="text" placeholder="Search" value={filterSearchCust} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                         </div>
                 
                                         <div className="row contentoverflow mt-2">
                                           <table className="filtertable">
                                             <tbody>
                                               {
                                                 filterCusDropDownData.map((item, index) => (
                 
                                                   <tr key={index} className="align-middle">
                 
                                                     <td className="pr-2"><input id={item.Id} data-index="1" name="filter-option-all-customer" checked={item.selected} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilterCustomer : (event) => OnCusdropdownChange(event, item.Id, item.Name, custFilterCol)} /></td>
                 
                                                     <td>{item.Name}</td>
                 
                                                   </tr>
                 
                 
                                                 ))
                                               }
                 
                                             </tbody>
                                           </table>
                 
                                         </div>
                                       </div>
                                       </>
                                        :
                                        <></>

                                    }
                                    <div style={{ paddingTop: '10px', paddingBottom: '7px' }} className="col-md-12 mt-3 d-flex justify-content-center">

                                        <button className="btn btn-sm btn-update" style={{ width: "50px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "50px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    :
                    ""
            }

            {showSelectedCustomerpopup === true ?
                <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                    <div className="container" style={{ padding: "0" }}>
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">
                                        <div className="d-flex align-items-center">
                                            <div className="col-md-10 text-left" style={{marginLeft:"-20px"}}>
                                                {t('Rebates.CustomerListing')}
                                            </div>
                                            <div style={{  backgroundColor: "#fff",marginLeft:"10px" }} className="">
                                                 <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{width: "80px"}}>{t('Save&Close')}</button>
                                            </div>
                                            <div className="">

                                                <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseCustomer} type="submit" style={{width: "50px"}}>{t('Cancel')}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">

                                    </div>
                                    <div className='scrollbar-class'>

                                        <div className="container">
                                            <div>

                                            </div>
                                            <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                    <tr>
                                                        <th>

                                                        </th>
                                                        <th>{t('Rebates.CustomerCode')}</th>
                                                        <th>{t('Rebates.CustomerName')}</th>
                                                        <th>{t('Rebates.Station')}</th>
                                                        <th>{t('Rebates.State')}</th>
                                                        <th>{t('Rebates.Zone')}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        customerDataSelectedLst.map((cslist, index) => (
                                                            
                                                            <tr>

                                                                <td style={{ width: "30px" }}>
                                                                    <input type="checkbox"
                                                                        checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, index)} />
                                                                </td>

                                                                <td style={{ width: "90px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Name}>{cslist.Name}</div>


                                                                </td>
                                                                <td style={{ width: "260px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.label}>{cslist.label}</div>


                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Station}>{cslist.Station}</div>

                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.State}>{cslist.State}</div>

                                                                </td>
                                                                <td style={{ width: "100px" }}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                        title={cslist.Zone}>{cslist.Zone}</div>

                                                                </td>



                                                            </tr>


                                                        ))


                                                    }

                                                </tbody> </table>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                       
                    </div>
                </div>
                :
                ""
            }

        </>
    )
}

export default CreateNewCustomerGroupsComponent