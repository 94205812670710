
import React, { useEffect, useState } from 'react'
import BarChart from './Charts/Barchart'
import { ArcElement } from 'chart.js'
import Chart from 'chart.js/auto'
import ActivateRebateProgram from './Charts/ActivateRebateProgram';
import AccuredvsProjected from './Charts/AccuredvsProjected';
import AccuredvsForecast from './Charts/AccuredvsForecast';
import TopBenficiaries from './Charts/Topbenficiaries';
import TopProduct from './Charts/TopProduct';
import { DashBoard } from './DashBoard.css';
import Service from '../../Services/Service';
import { Bar, Line } from 'react-chartjs-2';
import Select from "react-select";
import { Link, useLocation } from 'react-router-dom';
import SessionManage from "../../SessionManagement/SessionManage";

function ExecutiveDashboard() {
    const location = useLocation();
    const [currencyData, setCurrencyData] = useState([]);
    const [selectedOptionsCurrency, setSelectedOptionsCurrency] = useState();
    const [selectedOptionsMonths, setSelectedOptionsMonths] = useState();
    const [userPerms, setuserPermission] = useState([]);

    const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();
    const [selectedOptionsProduct, setselectedOptionsProduct] = useState();
    const [dateRange, setDateRange] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [lstMonth, setLstMonth] = useState([]);
    const [prefixCurrency, setPrefixCurrency] = useState('');
    const [defaultCurrency, setdefaultCurrency] = useState('');
    const [loading, setLoading] = useState(false);
 const [selectedOptionsRegion, setselectedOptionsRegion] = useState();
  const [selectedOptionsDistributor, setselectedOptionsDistributor] = useState();
  const [allDistributor, setAllDistributor] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
    const [DataSet, setDataSet] = useState('');
    let name = SessionManage.getusername();
    let chartReference = React.createRef();
    const BudgetedvsApprovedOptions = {
        plugins: { title: { display: true, text: 'Budgeted vs Approved Claims', } },
        scales: { yAxes: { beginAtZero: true, min: 0, ticks: { callback: function (value) { return prefixCurrency + "" + Service.numberFormat(value) }, }, }, }
    }
    const ApprovedvsRaisedNoOptions = {

        plugins: { title: { display: true, text: 'Number of Claims Raised vs Claims Approved', } },
        scales: { yAxes: { beginAtZero: true, ticks: { callback: function (value) { return Service.numberFormat(value) }, }, }, }
    }
    const ApprovedvsRaisedAmountOptions = {
    indexAxis: 'x',
        plugins: { title: { display: true, text: 'Amount Claims Raised vs Claims Approved', } },
        scales: { yAxes: { beginAtZero: true, ticks: { callback: function (value) { return prefixCurrency + "" + Service.numberFormat(value) } }, }, }
    }
    const Top5DistributorOptions = {
        indexAxis: 'y',
        plugins: { title: { display: true, text: 'Top 5 Distributors by ApprovedClaims', } },
        scales: { xAxes: { beginAtZero: true, ticks: { callback: function (value) { return prefixCurrency + "" + Service.numberFormat(value) } }, }, }
    }


    useEffect(() => {
        var year = new Date().getFullYear();
        getCurrencyData("currency");
        GetDateRangeAndProducts("DashBoard/GetDateRangeAndProductsSD");
        setPrefixCurrency("₹");
       
        getUserPermission('/sd-dashboard');
        }, [0])

    const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    console.log("21/7", disabledClass)


    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                //debugger;
                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                        console.log("21/7", res.data.DataList)
                    }
                }
            }
        });
    }


    const getCurrencyData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {

                    setCurrencyData(res.data)
                    var defCurrency = res.data.find(x => x.isoCode === 'INR')
                    if (defCurrency !== 'undefined' && defCurrency !== null) {
                        setSelectedOptionsCurrency(defCurrency.value);


                    }
                }
            }
        });
    }
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (Service.checkUndefined(res) === true) {
                if (Service.checkDataUndefined(res.data) == true) {
                    
                    setDataSet(res.data);
                    setLoading(false);
                }
            }
        });
    };
    const GetDateRangeAndProducts = (method) => {
        Service.getdata(method).then((res) => {
            if (Service.checkUndefined(res) === true) {
                if (Service.checkDataUndefined(res.data) == true) {
                console.log("247",res.data)
                    setDateRange(res.data.lstDateRange);
                    debugger
                   
                    setAllProducts(res.data.lstProduct);
                    setLstMonth(res.data.lstMonth);
                    setAllDistributor(res.data.lstDistributor)
                    setAllRegion(res.data.lstRegion)
                    var defProd = res.data.lstProduct.find(x => x.label === 'All')
                    if (defProd !== 'undefined' && defProd !== null) {
                        setselectedOptionsProduct(defProd.value);
                      }
                      debugger;
                    var defDistributor = res.data.lstDistributor.find((x) => x.label === "All");
                        if (defDistributor !== "undefined" && defDistributor !== null) {
                          setselectedOptionsDistributor(defDistributor.value);
                        }
                  var defRegion = res.data.lstRegion.find((x) => x.label === "All");
                         if (defRegion !== "undefined" && defRegion !== null) {
                            setselectedOptionsRegion(defRegion.value);
                     }
                   var months = res.data.lstMonth.find((x) => x.label === "All");
                             if (months !== "undefined" && months !== null) {
                               setSelectedOptionsMonths(months.value);
                             }

                             //var year=new Date().getFullYear();
                             var date = res.data.lstDateRange.find(x => x.label === 'FY 2023-2024')
                             if (date !== 'undefined' && date !== null)
                              {
                                 setselectedOptionsDateRange(date.value);
                              }
                            
                           
                    getData("DashBoard/GetSDDashBoard?DateRange=" + date.value + "&Currency=8&Products=0&Months=0&Region=0&Distributor=0");
                }
            }
        });
    };

    const OnChangeDateRange = (e) => {


        setselectedOptionsDateRange(e.value);
        getData("DashBoard/GetSDDashBoard?DateRange=" + e.value + "&Currency=" + selectedOptionsCurrency + "&Products=" + selectedOptionsProduct
        + "&Months=" + selectedOptionsMonths+
          "&Region=" +
          selectedOptionsRegion +
          "&Distributor=" +
          selectedOptionsDistributor);
        setLoading(true);

    };
    const OnChangeCurrency = (e) => {
        setPrefixCurrency(e.Prefix);
        setSelectedOptionsCurrency(e.value);
        getData("DashBoard/GetSDDashBoard?DateRange=" + selectedOptionsDateRange + "&Currency=" + e.value + "&Products=" + selectedOptionsProduct
        + "&Months=" + selectedOptionsMonths+
          "&Region=" +
          selectedOptionsRegion +
          "&Distributor=" +
          selectedOptionsDistributor);
        setLoading(true);
    };
    const OnChangeMonths = (e) => {
        setSelectedOptionsMonths(e.value);
        getData("DashBoard/GetSDDashBoard?DateRange=" + selectedOptionsDateRange + "&Currency=" + selectedOptionsCurrency + "&Products=" + selectedOptionsProduct
        + "&Months=" + e.value+
            "&Region=" +
            selectedOptionsRegion +
            "&Distributor=" +
            selectedOptionsDistributor);
        setLoading(true);
    };

    const OnChangeProduct = (e) => {
        setselectedOptionsProduct(e.value);
        getData("DashBoard/GetSDDashBoard?DateRange=" +
        selectedOptionsDateRange
        + "&Currency="
        + selectedOptionsCurrency
        + "&Products=" + e.label
        + "&Months=" + selectedOptionsMonths+
      "&Region=" +
      selectedOptionsRegion +
      "&Distributor=" +
      selectedOptionsDistributor

        );
        setLoading(true);
    };
 const OnChangeRegion = (e) => {
    setselectedOptionsRegion(e.value);
    getData(
      "DashBoard/GetSDDashBoard?DateRange=" +
      selectedOptionsDateRange +
      "&Currency=" +
      selectedOptionsCurrency +
      "&Products=" +
      selectedOptionsProduct +
      "&Months=" +
      selectedOptionsMonths +
       "&Region=" +
       e.label +
      "&Distributor=" +
       selectedOptionsDistributor
    );
    setLoading(true);
  };

  const OnChangeDistributor = (e) => {
      setselectedOptionsDistributor(e.value);
      getData(
        "DashBoard/GetSDDashBoard?DateRange=" +
        selectedOptionsDateRange +
        "&Currency=" +
        selectedOptionsCurrency +
        "&Products=" +
        selectedOptionsProduct +
        "&Months=" +
        selectedOptionsMonths +
         "&Region=" +
         selectedOptionsRegion +
        "&Distributor=" +
         e.label
      );
        setLoading(true);
    };


    const targetHeight = 25;

    const styles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    };
    const Options = [
        { label: "Q1 Jan-Mar", value: "0" },
        { label: "Q2 Apr-Jun", Id: "0" },
        { label: "Q3 July-Sep", Id: "0" },
        { label: "Q4 Oct-Dec", Id: "0" },

        { label: "Jan", Id: "1" },
        { label: "Feb", Id: "0" },
        { label: "Mar", Id: "0" },
        { label: "Apr", Id: "0" },
        { label: "May", Id: "0" },
        { label: "Jun", Id: "0" },
        { label: "July", Id: "0" },
        { label: "Aug", Id: "0" },
        { label: "Sep", Id: "0" },
        { label: "Oct", Id: "0" },
        { label: "Nov", Id: "0" },
        { label: "Dec", Id: "0" },


    ]

    Chart.register(ArcElement);
    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Claim Summary Dashboard</li>
                </ol>
            </nav>
            <div className="text_align_center">
                <h4>Claim Summary Dashboard</h4>

            </div>

            {loading ? <>
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            </> : ""}

            {<>
                <div className="statistics">
                    <div className="row">
                        <div className="col-xl-6 pr-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">

                                    <div className="card card_border border-primary-top p4 ">
                                        <div class="row" >
                                            <label class="col-md-5 text-left">Date Range</label>
                                             <Select styles={styles}
                                                 className={`col-md-5 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                                 options={dateRange}
                                                 placeholder="Select"
                                                 isSearchable={true}

                                                 value={dateRange.filter(function (option) {
                                                     return option.value === `${selectedOptionsDateRange}`;
                                                 })}

                                                 onChange={OnChangeDateRange}

                                             />
                                              <div class="col-md-1"></div>
                                              <label class="col-md-5 mt-2 text-left">
                                                Quarter/Month
                                              </label>

                                              <Select
                                                styles={styles}
                                                className={`col-md-5 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                                options={lstMonth}
                                                placeholder="Select"
                                                isSearchable={true}
                                                onChange={OnChangeMonths}
                                                value={lstMonth.filter(function (option) {
                                                    return option.value === `${selectedOptionsMonths}`;
                                                })}
                                              />

                                              <div class="col-md-1"></div>
                                              <label class="col-md-5 mt-2 text-left">
                                                Currency
                                              </label>

                                              <Select
                                                styles={styles}
                                                className={`col-md-5 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                                options={currencyData}
                                                placeholder="Select"
                                                isSearchable={true}
                                                onChange={OnChangeCurrency}
                                                value={currencyData.filter(function (option) {
                                                        return option.value === selectedOptionsCurrency;
                                                    })}
                                              />

                                              <div class="col-md-1"></div>
                                              <label class="col-md-5 mt-2 text-left">
                                                Products
                                              </label>
                                              <Select
                                                styles={styles}
                                                className={`col-md-5 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                                options={allProducts}
                                                placeholder="Select"
                                                isSearchable={true}
                                                value={allProducts.filter(function (option) {
                                                   return option.value === `${selectedOptionsProduct}`;
                                               })}
                                                onChange={OnChangeProduct}
                                              />
                                              <div class="col-md-1"></div>
                                            <label class="col-md-5 mt-2 text-left">
                                              Region
                                            </label>
                                            <Select
                                              styles={styles}
                                              className={`col-md-5 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                              options={allRegion}
                                              placeholder="Select"
                                              isSearchable={true}
                                               value={allRegion.filter(function (option) {
                                                     return option.value === `${selectedOptionsRegion}`;
                                                 })}
                                              onChange={OnChangeRegion}
                                            />
                                            <div class="col-md-1"></div>
                                            <label class="col-md-5 mt-2 text-left">
                                              Distributor
                                            </label>
                                            <Select
                                              styles={styles}
                                              className={`col-md-5 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                                              options={allDistributor}
                                              placeholder="Select"
                                              isSearchable={true}
                                                 value={allDistributor.filter(function (option) {
                                                   return option.value === `${selectedOptionsDistributor}`;
                                               })}
                                              onChange={OnChangeDistributor}
                                            />
                                            <div class="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">

                                        <h3 className="text-danger number">{prefixCurrency}{Service.numberFormat(DataSet.totalAllocatedAmount)}</h3>
                                        <p style={{ height: "66px" }} className="stat-text">Total Allocated Amount</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">

                                        <h3 className="text-secondary number">
                                            {prefixCurrency}{Service.numberFormat(DataSet.totalApprovedAmount)}


                                        </h3>
                                        <p style={{ height: "66px" }} className="stat-text">Total Approved Claim Amount</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">

                                        <h3 className="text-danger number">{prefixCurrency}{Service.numberFormat(DataSet.totalAmountLeft)}</h3>
                                        <p style={{ height: "66px" }} className="stat-text">Amount left after Claim</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <section className="col-lg-6">
                        <div className="card-dashboard1">

                            <div className="card-body">
                                {DataSet.length === 0 ? "" : <Bar data={DataSet.budgetedVsApprovedLst} options={BudgetedvsApprovedOptions} ></Bar>}

                            </div>{/* /.card-body */}
                        </div>
                        <div className="card-dashboard">

                            <div className="card-body">
                                {DataSet.length === 0 ? "" : <Bar data={DataSet.approvedVsRaisedNoLst} options={ApprovedvsRaisedNoOptions} ></Bar>}

                            </div>{/* /.card-body */}
                        </div>
                        <div className="">

                            <div className="">


                            </div>{/* /.card-body */}
                        </div>
                    </section>
                    <section className="col-lg-6">
                        <div className="card-dashboard1">

                            <div className="card-body">
                                {DataSet.length === 0 ? "" : <Line data={DataSet.approvedVsRaisedAmountLst} options={ApprovedvsRaisedAmountOptions} ></Line>}

                            </div>{/* /.card-body */}
                        </div>
                        <div className="card-dashboard">

                            <div className="card-body">
                                {DataSet.length === 0 ? "" : <Bar data={DataSet.topFiveDistributorsLst} options={Top5DistributorOptions} ></Bar>}

                            </div>{/* /.card-body */}
                        </div>
                        <div className="">

                            <div className="">

                            </div>{/* /.card-body */}
                        </div>
                    </section>
                </div>
            </>}

        </div>
    </>);
}
export default ExecutiveDashboard