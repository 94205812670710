import React, { useState, forwardRef, useEffect } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import $ from 'jquery';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from './WorkflowEditTemplate.css';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Service from '../../../Services/Service';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import htmlToDraft from 'html-to-draftjs';

import Multiselect from 'multiselect-react-dropdown';
import draftToHtml from 'draftjs-to-html';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function NewEmailTemplateComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const [editPerms, setEditPerms] = useState([]);
    const [NameAvailable, setNameAvailable] = useState('');
    const [IsSubmit, setIsSubmit] = useState(false);

    const [data_select, setdata] = useState([]);
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [selecteperm, setselecteperm] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const navigate = useNavigate();

    const [inputField, setInputField] = useState({
        Id: 0,
        Name: '',
        Subject: '',
        Content: '',
        CreatedUserId: '',
        LastModifiedUserId: '',
        ContentJosn: '',

    });


    useEffect(() => {
        getUserPermission(location.pathname);
        getData("ApiWorkflowEmailTemplate/GetAll", 1000, 1, "Descending", "Id", [], null);
        if (props.id != 0) {
            getDatabyId("ApiWorkflowEmailTemplate/GetById/", props.id);
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

    }, [0])

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            debugger;
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined' && res.data != "") {

                const { contentBlocks, entityMap } = convertFromHTML(res.data.Content);

                setInputField(res.data);
                console.log(res.data, "abcd");

                const content = convertFromRaw(JSON.parse(res.data.ContentJosn));

                setEditorState(EditorState.createWithContent(content));
                setselecteperm(res.data);
            }
        });
    }

    const inputsHandler = (e) => {

        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setHandleChange(true)
    }
    const checkName = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== null && value != '') {
            Service.getcheckIfExists("ApiWorkflowEmailTemplate/checkIfExists", value, props.id).then((res) => {

                if (typeof res !== 'undefined' && typeof res.data != 'undefined') {
                    setNameAvailable(res.data);

                }
            });
        }
    }

    const checkValidation = () => {
        var has_errors = false;
        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage(t('Validation-Createuser.Name'))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Subject == '' || inputField.Subject == null) {
            setErrorMessage(t('Validation-Createuser.Subject'))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Content == '' || inputField.Content == null) {
            setErrorMessage(t('Validation-Createuser.Content'))
            has_errors = true;
            setShowErrorModal(true)
        }

        return has_errors;

    }


    const handleEditorChange = (state) => {
        setEditorState(state);
        setHandleChange(true)

    }
    const submitButton = (e) => {

        setIsSubmit(true);

        e.preventDefault();

        let id = Service.getUserId();
        inputField.CreatedUserId = id;
        inputField.LastModifiedUserId = id;
        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);

        let currentContentAsHTML = draftToHtml(messageBody);
        inputField.ContentJosn = jsonData;

        inputField.Content = currentContentAsHTML;

        let apiTemplateView = inputField;

        var has_errors = checkValidation();
        if (has_errors === false) {


            if (NameAvailable == 'Email Template is available' || isEdit == true) {
                Service.createorupdate("ApiWorkflowEmailTemplate/CreateOrUpdate", apiTemplateView).then(res => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setHandleChange(false)
                            navigate("/emailtemplates");
                        }
                    }


                });
            }
            else setIsSubmit(false);
        } else
            setIsSubmit(false);

    }
    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getDatawithMultiFilterSearch(method, 1000, 1, "Ascending", "Id", [], null).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data != "") {
                    setdata(res.data.DataList);
                }
            }
        });


    }
    const refresh_page = (event) => {

        window.location.href = "/emailtemplates/edit/" + event.target.value;
    }
    const closeHandler = (flag) => {

        
        let id = Service.getUserId();
        inputField.CreatedUserId = id;
        inputField.LastModifiedUserId = id;
        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);

        let currentContentAsHTML = draftToHtml(messageBody);
        inputField.ContentJosn = jsonData;

        inputField.Content = currentContentAsHTML;

        let apiTemplateView = inputField;

        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            navigate("/emailtemplates")
        }
        if (flag == "Close") {
            navigate("/emailtemplates")
        }
        if (flag == "Cancel") {
            Service.createorupdate("ApiWorkflowEmailTemplate/CreateOrUpdate", apiTemplateView).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setHandleChange(false)
                        navigate("/emailtemplates");
                    }
                }


            });
            setCloseModal(false)
        }
    }



    const class_textarea = `col-md-3 textbox-padding form-control m_width ${editPerms == false ? "disabledClass" : ""}`;
    const class_edi = `  ${editPerms == false ? "disabledClass" : ""}`;
    return (
        <div class="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page"><Link to="/emailtemplates">{t('UserList.EmailTemplates')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('Edit')} </li>
                </ol>
            </nav>
            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-4 text-left">
                                    {`${t('')} ${t('UserList.EmailTemplates')}`}
                                </div>
                                <div className="col-md-4">

                                    <select value={inputField.Id} className='col-md-12' disabled="true" 
                                        onChange={(event) => refresh_page(event)} >

                                        {data_select.map((i) => <option key={i.Id} value={i.Id} >{i.Name}</option>)}
                                    </select>

                                </div>
                                <div className="col-md-4 mt-1 d-flex justify-content-end ">
                                    {editPerms == false ? "" : <button disabled={IsSubmit} className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">{t('Save')}</button>}


                                    <button type="button" className="btn btn-sm btn-update ml-2" onClick={(event) => closeHandler("Open")} style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            <div className="container">
                                <div class="row mt-3">
                                    <label className="col-md-2 text-left l_width">{t('UserList.TemplateName')}</label>
                                    <input id="name" disabled={isEdit} onBlur={checkName} onChange={checkName} value={inputField.Name} name="Name" type="text" className="col-md-3 textbox-padding form-control m_width" />
                                    <span className='errormessage'>{NameAvailable}</span>
                                </div>
                                <div class="row mt-3">
                                    <label className="col-md-2 text-left l_width">{t('UserList.Subject')}</label>
                                    <textarea id="aid" onChange={inputsHandler} value={inputField.Subject} style={{ fontSize: "12px" }} name="Subject" className={class_textarea} />

                                </div>
                                <div class="row mt-3">
                                    <label className="col-md-2 text-left">{t('UserList.Content')}</label>
                                    {editPerms == false ?
                                        <Editor
                                            readOnly
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={handleEditorChange}
                                        />
                                        :
                                        <Editor

                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={handleEditorChange}
                                        />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Your changes will be lost! Are you sure, you want to close?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "40px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Save')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>   </div>

    )

}
export default NewEmailTemplateComponent