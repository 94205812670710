import { Link, Navigate, useLocation } from "react-router-dom"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionManage from "../../../SessionManagement/SessionManage";

function CreateNewSegmentPolicy(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    let loggedInUser = SessionManage.getuserid();
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disabled = false;
    const [inputField, setInputField] = useState({
        Name: '',
        label: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0 && props.id !== '0') {
            getdata("Segment/GetById", props.id);
        }
        else {
            setLockedBy(loggedInUser);
            setIsLocked(true);
        }
    }, [props.id])

    const getUserPermission = (pathname) => {
        console.log("10-9", pathname, props, loggedInUser)
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        console.log("10-9", res.data.DataList)
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const getdata = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "Segment", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {

                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (res.data !== 'null' && res.data !== 'undefined') {
                                    console.log(res.data)
                                    setInputField(res.data);
                                    setPolicyStatus(true)
                                    if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                        $("#libread").html(res.data.Name+" " + `Locked By ${res.data.LockedByUserName}`);
                                    }else{
                                        $("#libread").html(res.data.Name)
                                    }
                                    setLockedBy(res.data.LockedBy);
                                    setIsLocked(res.data.Locked);
                                }
                            }
                        }
                    }

                });
            }
        });


    }


    const inputsHandler = (e) => {
        setHandleChange(true)

        if (policyStatus === false) {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
    }


    const checkValidation = () => {
        setIsValid(true)

        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage(`${t('Please Enter')}${t('Policies.Name')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.label == '' || inputField.label == null) {
            setErrorMessage(`${t('Please Enter')}${t('Policies.Label')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {

            saveHandler(true)
        }
    }


    const saveHandler = (isValid) => {
        let data = inputField
        data['LastModifiedUserId'] = loggedInUser

        if (isValid === true) {

            Service.createorupdate("Segment/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        console.log("10-9", res.data)
                        if (res.data.Id > 0) {

                            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Segment", props.id, loggedInUser).then((res) => {
                            });
                            toast.success('Saved Successfully !', {
                                onClose: () => props.navigate('/policies/segment'),
                                position: toast.POSITION.TOP_RIGHT,
                            });


                        }
                        else {
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                    }
                }

            });
        }
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Segment", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/policies/segment")
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Segment", props.id, loggedInUser).then((res) => {

            });
            props.navigate("/policies/segment")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disabled = false;
    }
    else {
        disabled = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();

    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Segment", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-2 mt-2 textbox-padding form-control m_width  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;


    console.log("10-9", Service.editPerms(userPerms), isLocked, lockedBy)
    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/policies/segment">{`${t('Segment.Segment')}${t('Policies.PolicyList')}`}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{`${t('Policies.CreateNew')}${t('Segment.Segment')}${t('Policies.Policy')}`}</li>
                    </ol>
                </nav>
                <ToastContainer autoClose={1000} />
                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {`${t('Policies.CreateNew')}${t('Segment.Segment')}${t('Policies.Policy')}`}
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? ""
                                            : (isLocked === true && lockedBy.toString() === loggedInUser)
                                                ?
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} onClick={checkValidation}>{t('Save')}
                                                </button>
                                                :
                                                ''}
                                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} id="close" onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left l_width">{t('Policies.Name')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" autoComplete='no' onChange={inputsHandler} className="col-md-2 mt-2 textbox-padding form-control m_width" value={inputField.Name} disabled={policyStatus} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left l_width">{t('Policies.Label')}<span className={classes.mandatory}>*</span></label>
                                        <input disabled={disabled} onKeyPress={(e) => { e.key === ' ' && e.preventDefault(); }} name="label" type="text" autoComplete='off' onChange={inputsHandler} className={class_textbox} value={inputField.label} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}
export default CreateNewSegmentPolicy